import { AnyAction } from 'redux';
import {
    CHANGE_BUSINESS_CONSUMPTION_AMOUNT,
    CHANGE_BUSINESS_DESCRIPTION,
    CHANGE_BUSINESS_DISTANCE,
    CHANGE_BUSINESS_EQUIVALENT_FACTOR,
    CHANGE_BUSINESS_MAIN_FACILITY,
    CHANGE_BUSINESS_PER_NIGHT,
    CHANGE_BUSINESS_SUB_FACILITY,
    CHANGE_BUSINESS_WEIGHT,
    CHANGE_BUSINESS_YEAR,
    CLEAR_BUSINESS_CONSUMPTION_AMOUNT,
    CLEAR_BUSINESS_INPUT,
    SELECT_BUSINESS_ACTIVITY,
    SELECT_BUSINESS_ACTIVITY_DATA,
    SELECT_BUSINESS_ACTIVITY_TYPE,
    SELECT_BUSINESS_DATABASE,
    SELECT_BUSINESS_FUEL_TYPE,
    SELECT_BUSINESS_LADEN_PERCENTAGE,
    SELECT_BUSINESS_SIZE,
    SELECT_BUSINESS_TIER,
    SELECT_BUSINESS_UNIT,
    SET_BUSINESS_ACTIVITIES,
    SET_BUSINESS_ACTIVITY_TYPES,
    SET_BUSINESS_DATABASES,
    SET_BUSINESS_FUEL_TYPES,
    SET_BUSINESS_LADEN_PERCENTAGES,
    SET_BUSINESS_RESULT,
    SET_BUSINESS_SIZES,
    SET_BUSINESS_TIERS,
    SET_BUSINESS_UNITS,
} from '../../actions/emission/business.action';
import { clearInput } from './base.reducer';

const initialState = {
    mainFacility: '',
    subFacility: '',
    tiers: [],
    tier: '',
    sectorList: [],
    sector: '',
    emissionSource: '',
    sourceList: [],
    source: '',
    databases: [],
    database: '',
    activityList: [],
    activity: '',
    activityTypeList: [],
    activityType: '',
    activityData: '',
    unitList: [],
    unit: '',
    perNight: '',
    weight: '',
    distance: '',
    year: '',
    equivalentFactor: '',
    consumptions: {
        0: { tonne: null, km: null },
        1: { tonne: null, km: null },
        2: { tonne: null, km: null },
        3: { tonne: null, km: null },
        4: { tonne: null, km: null },
        5: { tonne: null, km: null },
        6: { tonne: null, km: null },
        7: { tonne: null, km: null },
        8: { tonne: null, km: null },
        9: { tonne: null, km: null },
        10: { tonne: null, km: null },
        11: { tonne: null, km: null },
        12: { tonne: null, km: null },
    },
    calculationDate: '',
    description: '',
    result: [],
};

export const emissionBusinessReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case CLEAR_BUSINESS_INPUT:
            const data = clearInput(action, initialState)
            return {
                ...state,
                ...data
            }
        case CHANGE_BUSINESS_MAIN_FACILITY:
            return {
                ...state,
                mainFacility: action.payload,
            };
        case CHANGE_BUSINESS_SUB_FACILITY:
            return {
                ...state,
                subFacility: action.payload,
            };
        case SET_BUSINESS_TIERS:
            return {
                ...state,
                tiers: action.payload,
            };
        case SELECT_BUSINESS_TIER:
            return {
                ...state,
                tier: action.payload,
            };
        case SET_BUSINESS_DATABASES:
            return {
                ...state,
                databases: action.payload,
            };
        case SELECT_BUSINESS_DATABASE:
            return {
                ...state,
                database: action.payload,
            };
        case CHANGE_BUSINESS_EQUIVALENT_FACTOR:
            return {
                ...state,
                equivalentFactor: action.payload,
            };
        case SET_BUSINESS_ACTIVITIES:
            return {
                ...state,
                activityList: action.payload,
            };
        case SELECT_BUSINESS_ACTIVITY:
            return {
                ...state,
                activity: action.payload,
            };
        case SET_BUSINESS_FUEL_TYPES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_BUSINESS_FUEL_TYPE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_BUSINESS_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypeList: action.payload,
            };
        case SELECT_BUSINESS_ACTIVITY_TYPE:
            return {
                ...state,
                activityType: action.payload,
            };
        case SET_BUSINESS_UNITS:
            return {
                ...state,
                unitList: action.payload,
            };
        case SELECT_BUSINESS_UNIT:
            return {
                ...state,
                unit: action.payload,
            };
        case CHANGE_BUSINESS_PER_NIGHT:
            return {
                ...state,
                perNight: action.payload,
            };
        case CHANGE_BUSINESS_WEIGHT:
            return {
                ...state,
                weight: action.payload,
            };
        case CHANGE_BUSINESS_DISTANCE:
            return {
                ...state,
                distance: action.payload,
            };
        case SET_BUSINESS_LADEN_PERCENTAGES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_BUSINESS_LADEN_PERCENTAGE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_BUSINESS_SIZES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_BUSINESS_SIZE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_BUSINESS_RESULT:
            return {
                ...state,
                result: action.payload,
            };
        case SELECT_BUSINESS_ACTIVITY_DATA:
            return {
                ...state,
                activityData: action.payload,
            };
        case CHANGE_BUSINESS_YEAR:
            return {
                ...state,
                year: action.payload,
            };
        case CHANGE_BUSINESS_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };

        case CHANGE_BUSINESS_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: {
                    ...state.consumptions,
                    [action.payload.month]: {
                        ...state.consumptions[action.payload.month as keyof typeof state.consumptions],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case CLEAR_BUSINESS_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: initialState.consumptions,
            };
        default:
            return state;
    }
};
