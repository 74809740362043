export const AUTH = '[AUTH]';

export const CHANGE_AUTH_USERNAME = `${AUTH} Change Username`;
export const CHANGE_AUTH_PASSWORD = `${AUTH} Change Password`;
export const SET_AUTH_IS_LOGIN = `${AUTH} Set Is Login`;
export const SET_AUTH_LOGOUT = `${AUTH} Submit Auth Logout`;
export const SUBMIT_AUTH_LOGIN = `${AUTH} Submit Auth Login`;
export const SET_AUTH_RESULT = `${AUTH} Set Result`;

export const GET_AUTH_USER_INFO = `${AUTH} Get User Info`;
export const SET_AUTH_USER_INFO = `${AUTH} Set User Info`;

export const SET_LOADING = `${AUTH} Set Loading`;
/// AUTH
export type ISubmit = {
    onSuccess: () => void;
};

export const getAuthUserInfo = (onSuccess: () => void) => ({
    type: GET_AUTH_USER_INFO,
    meta: {
        onSuccess,
    },
});

export const setAuthUserInfo = (data: any) => ({
    type: SET_AUTH_USER_INFO,
    payload: data,
});

export const changeAuthUsername = (username: string) => ({
    type: CHANGE_AUTH_USERNAME,
    payload: username,
});

export const changeAuthPassword = (password: string) => ({
    type: CHANGE_AUTH_PASSWORD,
    payload: password,
});

export const setAuthIsLogin = (isLogged: boolean) => ({
    type: SET_AUTH_IS_LOGIN,
    payload: isLogged,
});

export const submitAuthLogin = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_AUTH_LOGIN,
    meta: {
        onSuccess,
    },
});

export const setAuthLogout = () => ({
    type: SET_AUTH_LOGOUT,
});

export const setAuthResult = (data: any) => ({
    type: SET_AUTH_RESULT,
    payload: data,
});

export const setLoading = () => ({
    type: SET_LOADING,
});

/// END AUTH
