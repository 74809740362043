import { AnyAction } from 'redux';
import {
    CHANGE_KPI_PROJECT_BASE_YEAR,
    CHANGE_KPI_PROJECT_CATEGORY,
    CHANGE_KPI_PROJECT_DESCRIPTION,
    CHANGE_KPI_PROJECT_TARGET_YEAR,
    CHANGE_KPI_PROJECT_TITLE,
    CLEAR_KPI_PROJECT_INPUTS,
    GET_KPI_PROJECT_BY_ID,
    GET_KPI_PROJECT_LIST,
    SELECT_KPI_PROJECT_GOALS,
    SELECT_KPI_PROJECT_STATUS,
    SET_KPI_PROJECT_BY_ID,
    SET_KPI_PROJECT_DASHBOARD,
    SET_KPI_PROJECT_GOALS,
    SET_KPI_PROJECT_LIST,
    SET_KPI_PROJECT_PAGE,
    SET_KPI_PROJECT_STATUS,
} from '../../actions/kpi/project.action';
import { IKpiProject, IKpiProjectDetail, IStatus } from '../../../entities/kpi/kpiProjectCombustion';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';

const initialState = {
    dashboard: {
        data: {},
        isLoading: true,
    },
    projectList: {
        data: [] as Array<IKpiProject>,
        isLoading: true,
    },
    selectedProject: {
        data: {} as IKpiProjectDetail,
        isLoading: true,
    },
    globalStatusList: {
        data: [],
        isLoading: true,
    },
    globalGoalList: {
        data: [],
        isLoading: true,
    },
    updateProject: {
        title: '',
        description: '',
        category: '',
        baseYear: '2019',
        targetYear: '2023',
        selectedGoals: [] as Array<any>,
        status: '',
    },
    title: '',
    description: '',
    category: '',
    baseYear: '2019',
    targetYear: '2023',
    selectedGoals: [] as Array<any>,
    selectedStatus: {} as IStatus,
    status: '',
};

export const projectReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_KPI_PROJECT_BY_ID:
            return initialState;
        case SET_KPI_PROJECT_BY_ID:
            return {
                ...state,
                selectedProject: {
                    data: action.payload,
                    isLoading: false,
                },
                title: action.payload?.title,
                description: action.payload?.description,
                category: action.payload?.category,
                baseYear: action.payload?.baseYear,
                targetYear: action.payload?.targetYear,
                selectedGoals: action.payload?.sustainableDevelopmentGoalSet,
                status: action.payload?.status,
            };
        case SET_KPI_PROJECT_PAGE:
            return {
                ...state,
            };
        case GET_KPI_PROJECT_LIST:
            return {
                ...state,
                projectList: {
                    data: [] as Array<IKpiProject>,
                    isLoading: true,
                },
            };
        case SET_KPI_PROJECT_LIST:
            return {
                ...state,
                projectList: {
                    data: action.payload,
                    isLoading: false,
                },
            };
        case SET_KPI_PROJECT_DASHBOARD:
            return {
                ...state,
                dashboard: {
                    data: action.payload,
                    isLoading: false,
                },
            };
        case SET_KPI_PROJECT_STATUS:
            return {
                ...state,
                globalStatusList: {
                    data: action.payload,
                    isLoading: false,
                },
            };
        case SET_KPI_PROJECT_GOALS:
            return {
                ...state,
                globalGoalList: {
                    data: action.payload,
                    isLoading: false,
                },
            };
        case CHANGE_KPI_PROJECT_TITLE:
            return {
                ...state,
                title: action.payload,
            };
        case CHANGE_KPI_PROJECT_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };
        case CHANGE_KPI_PROJECT_CATEGORY:
            return {
                ...state,
                category: action.payload,
            };
        case CHANGE_KPI_PROJECT_BASE_YEAR:
            return {
                ...state,
                baseYear: action.payload,
            };
        case CHANGE_KPI_PROJECT_TARGET_YEAR:
            return {
                ...state,
                targetYear: action.payload,
            };
        case SELECT_KPI_PROJECT_GOALS:
            let arr = [];
            if (state.selectedGoals?.includes(action.payload)) {
                arr = state.selectedGoals?.filter((a) => a != action.payload);
            } else {
                arr = [...state.selectedGoals, action.payload];
            }
            return {
                ...state,
                selectedGoals: arr,
            };
        case SELECT_KPI_PROJECT_STATUS:
            return {
                ...state,
                selectedStatus: action.payload,
            };
        case CLEAR_KPI_PROJECT_INPUTS:
            return initialState;
        case SET_AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
};
