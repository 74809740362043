import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IProcessActivity,
    IProcessActivityType,
    IProcessAdditional,
    IProcessDatabase,
    IProcessFuelType,
    IProcessResult,
    IProcessSize,
    IProcessUnit,
} from '../../../entities/emissionTypes/processCombustion';
import { EMISSION } from './base.action';
import { IStaticUnit } from '../../../entities/emissionTypes/staticCombustion';

export const PROCESS = 'PROCESS';

////-- Base
export const CHANGE_PROCESS_MAIN_FACILITY = `${EMISSION} ${PROCESS} Change Main Facility`;
export const CHANGE_PROCESS_SUB_FACILITY = `${EMISSION} ${PROCESS} Change Sub Facility`;

export const GET_PROCESS_TIERS = `${EMISSION} ${PROCESS} Get Tiers`;
export const SET_PROCESS_TIERS = `${EMISSION} ${PROCESS} Set Tiers`;
export const SELECT_PROCESS_TIER = `${EMISSION} ${PROCESS} Select Tier`;

export const GET_PROCESS_DATABASES = `${EMISSION} ${PROCESS} Get Databases`;
export const SET_PROCESS_DATABASES = `${EMISSION} ${PROCESS} Set Databases`;
export const SELECT_PROCESS_DATABASE = `${EMISSION} ${PROCESS} Select Database`;

export const CHANGE_PROCESS_EQUIVALENT_FACTOR = `${EMISSION} ${PROCESS} Change Equivalent Factor`;

export const GET_PROCESS_ACTIVITIES = `${EMISSION} ${PROCESS} Get Activities`;
export const SET_PROCESS_ACTIVITIES = `${EMISSION} ${PROCESS} Set Activities`;
export const SELECT_PROCESS_ACTIVITY = `${EMISSION} ${PROCESS} Select Activity`;
/////--- End base

export const GET_PROCESS_FUEL_TYPES = `${EMISSION} ${PROCESS} Get Fuel Types`;
export const SET_PROCESS_FUEL_TYPES = `${EMISSION} ${PROCESS} Set Fuel Types`;
export const SELECT_PROCESS_FUEL_TYPE = `${EMISSION} ${PROCESS} Select Fuel Type`;

export const GET_PROCESS_ACTIVITY_TYPES = `${EMISSION} ${PROCESS} Get Activity Types`;
export const SET_PROCESS_ACTIVITY_TYPES = `${EMISSION} ${PROCESS} Set Activity Types`;
export const SELECT_PROCESS_ACTIVITY_TYPE = `${EMISSION} ${PROCESS} Select Activity Type`;

export const GET_PROCESS_UNITS = `${EMISSION} ${PROCESS} Get Units`;
export const SET_PROCESS_UNITS = `${EMISSION} ${PROCESS} Set Units`;
export const SELECT_PROCESS_UNIT = `${EMISSION} ${PROCESS} Select Unit`;

export const GET_PROCESS_ADDITIONAL_CONSTANTS = `${EMISSION} ${PROCESS} Get Additional Constants`;
export const SET_PROCESS_ADDITIONAL_CONSTANTS = `${EMISSION} ${PROCESS} Set Additional Constants`;

export const CHANGE_PROCESS_WEIGHT = `${EMISSION} ${PROCESS} Change Weight`;
export const CHANGE_PROCESS_DISTANCE = `${EMISSION} ${PROCESS} Change Distance`;

export const GET_PROCESS_LADEN_PERCENTAGES = `${EMISSION} ${PROCESS} Get Laden Percentages`;
export const SET_PROCESS_LADEN_PERCENTAGES = `${EMISSION} ${PROCESS} Set Laden Percentages`;
export const SELECT_PROCESS_LADEN_PERCENTAGE = `${EMISSION} ${PROCESS} Select Laden percentage`;

export const GET_PROCESS_SIZES = `${EMISSION} ${PROCESS} Get Sizes`;
export const SET_PROCESS_SIZES = `${EMISSION} ${PROCESS} Set Sizes`;
export const SELECT_PROCESS_SIZE = `${EMISSION} ${PROCESS} Select Size`;

////-- Base
export const CHANGE_PROCESS_YEAR = `${EMISSION} ${PROCESS} Change Year`;
export const SELECT_PROCESS_ACTIVITY_DATA = `${EMISSION} ${PROCESS} Select Activity Data`;
export const CHANGE_PROCESS_CONSUMPTION_AMOUNT = `${EMISSION} ${PROCESS} Change Consumption Amount`;
export const CHANGE_PROCESS_DESCRIPTION = `${EMISSION} ${PROCESS} Change Description`;

export const SUBMIT_PROCESS_FORM = `${EMISSION} ${PROCESS} Submit Form`;
export const SET_PROCESS_RESULT = `${EMISSION} ${PROCESS} Set Result`;
/////-- End Base
export const CLEAR_PROCESS_CONSUMPTION_AMOUNT = `${EMISSION} ${PROCESS} Clear Consumption Amount`;
export const CLEAR_PROCESS_INPUT = `${EMISSION} ${PROCESS} Clear Process Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearProcessInput = (list: Array<string>) => ({
    type: CLEAR_PROCESS_INPUT,
    payload: list,
});

/// Region Process
export const changeProcessMainFacility = (text: string) => ({
    type: CHANGE_PROCESS_MAIN_FACILITY,
    payload: text,
});

export const changeProcessSubFacility = (text: string) => {
    return {
        type: CHANGE_PROCESS_SUB_FACILITY,
        payload: text,
    };
};

export const getProcessTiers = () => ({
    type: GET_PROCESS_TIERS,
});

export const setProcessTiers = (list: Array<ITier>) => ({
    type: SET_PROCESS_TIERS,
    payload: list,
});

export const selectProcessTier = (tier: ITier) => ({
    type: SELECT_PROCESS_TIER,
    payload: tier,
});

export const getProcessDatabases = (tier: ITier) => ({
    type: GET_PROCESS_DATABASES,
    payload: tier,
});

export const setProcessDatabases = (list: Array<IProcessDatabase>) => ({
    type: SET_PROCESS_DATABASES,
    payload: list,
});

export const selectProcessDatabase = (database: IProcessDatabase) => ({
    type: SELECT_PROCESS_DATABASE,
    payload: database,
});

export const getProcessActivities = (tier: ITier) => ({
    type: GET_PROCESS_ACTIVITIES,
    payload: tier,
});

export const setProcessActivities = (activities: Array<IProcessActivity>) => ({
    type: SET_PROCESS_ACTIVITIES,
    payload: activities,
});

export const selectProcessActivity = (activity: IProcessActivity) => ({
    type: SELECT_PROCESS_ACTIVITY,
    payload: activity,
});

///////
export const getProcessFuelTypes = (tier: ITier) => ({
    type: GET_PROCESS_FUEL_TYPES,
    payload: tier,
});

export const setProcessFuelTypes = (list: Array<IProcessFuelType>) => ({
    type: SET_PROCESS_FUEL_TYPES,
    payload: list,
});

export const selectProcessFuelType = (database: IProcessFuelType) => ({
    type: SELECT_PROCESS_FUEL_TYPE,
    payload: database,
});

export const getProcessActivityTypes = (value: any) => ({
    type: GET_PROCESS_ACTIVITY_TYPES,
    payload: value,
});

export const setProcessActivityTypes = (activities: Array<IProcessActivityType>) => ({
    type: SET_PROCESS_ACTIVITY_TYPES,
    payload: activities,
});

export const selectProcessActivityType = (activity: IProcessActivityType) => ({
    type: SELECT_PROCESS_ACTIVITY_TYPE,
    payload: activity,
});

export const getProcessUnits = (units: IProcessActivityType) => ({
    type: GET_PROCESS_UNITS,
    payload: units,
});

export const getProcessAdditionalConstants = (additional: IStaticUnit) => ({
    type: GET_PROCESS_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setProcessAdditionalConstants = (additional: Array<IProcessAdditional>) => ({
    type: SET_PROCESS_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setProcessUnits = (units: Array<IProcessUnit>) => ({
    type: SET_PROCESS_UNITS,
    payload: units,
});

export const selectProcessUnit = (unit: IProcessUnit) => ({
    type: SELECT_PROCESS_UNIT,
    payload: unit,
});

export const changeProcessWeight = (value: number) => ({
    type: CHANGE_PROCESS_WEIGHT,
    payload: value,
});

export const changeProcessDistance = (value: number) => ({
    type: CHANGE_PROCESS_DISTANCE,
    payload: value,
});

export const getProcessLadenPercentages = (ladens: any) => ({
    type: GET_PROCESS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setProcessLadenPercentages = (ladens: Array<any>) => ({
    type: SET_PROCESS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectProcessLadenPercentage = (laden: any) => ({
    type: SELECT_PROCESS_LADEN_PERCENTAGE,
    payload: laden,
});

export const getProcessSizes = (sizes: IProcessActivityType) => ({
    type: GET_PROCESS_SIZES,
    payload: sizes,
});

export const setProcessSizes = (sizes: Array<IProcessSize>) => ({
    type: SET_PROCESS_SIZES,
    payload: sizes,
});

export const selectProcessSize = (size: IProcessSize) => ({
    type: SELECT_PROCESS_SIZE,
    payload: size,
});

///////
export const submitProcessForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_PROCESS_FORM,
    meta: {
        onSuccess,
    },
});

export const setProcessResult = (data: Array<IProcessResult>) => ({
    type: SET_PROCESS_RESULT,
    payload: data,
});

export const selectProcessActivityData = (activity: string) => ({
    type: SELECT_PROCESS_ACTIVITY_DATA,
    payload: activity,
});

export const changeProcessYear = (value: number) => ({
    type: CHANGE_PROCESS_YEAR,
    payload: value,
});

export const changeProcessDescription = (text: string) => ({
    type: CHANGE_PROCESS_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_PROCESS_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_PROCESS_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_PROCESS_CONSUMPTION_AMOUNT,
});
/// End Region Process
