import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import { createNotification } from '../../../actions/ui.action';
import {
    clearKpiActionInputs,
    GET_KPI_ACTION_BY_ID,
    GET_KPI_ACTION_LIST,
    GET_KPI_ACTION_PAGE,
    getKpiActionList,
    KPI_ACTION_CREATE,
    KPI_ACTION_DELETE,
    KPI_ACTION_UPDATE,
    setKpiActionById,
    setKpiActionList,
} from '../../../actions/kpi/action.action';
import i18next from 'i18next';

export const actionMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case KPI_ACTION_CREATE: {
                interface IBody {
                    kpiId: number;
                    title: string;
                    description: string;
                    endDate: string;
                    progress: number;
                }
                const body: IBody = {
                    kpiId: state?.kpi?.kpi?.selectedKpi?.data?.id,
                    title: state?.kpi?.action?.title,
                    description: state?.kpi?.action?.description,
                    endDate: state?.kpi?.action?.endDate,
                    progress: state?.kpi?.action?.progress,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/kpiAction',
                        feature: KPI_ACTION_CREATE,
                        body,
                    })
                );
                break;
            }
            case `${KPI_ACTION_CREATE} ${API_SUCCESS}`: {
                dispatch(getKpiActionList());
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'success',
                        message: action.payload?.message || i18next.t('notification.successCreated'),
                    })
                );
                dispatch(clearKpiActionInputs());
                break;
            }
            case `${KPI_ACTION_CREATE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_ACTION_UPDATE: {
                interface IBody {
                    kpiActionId: number;
                    title: string;
                    description: string;
                    endDate: string;
                    progress: number;
                }
                const body: IBody = {
                    kpiActionId: action.payload,
                    title: state?.kpi?.action?.title,
                    description: state?.kpi?.action?.description,
                    endDate: state?.kpi?.action?.endDate,
                    progress: state?.kpi?.action?.progress,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/kpiAction',
                        feature: KPI_ACTION_UPDATE,
                        body,
                    })
                );
                break;
            }
            case `${KPI_ACTION_UPDATE} ${API_SUCCESS}`: {
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'success',
                        message: action.payload?.message || i18next.t('notification.successCreated'),
                    })
                );
                dispatch(getKpiActionList());
                break;
            }
            case `${KPI_ACTION_UPDATE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_ACTION_DELETE: {
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: '/kpiAction',
                        feature: KPI_ACTION_DELETE,
                        query: {
                            id: action.payload,
                        },
                    })
                );
                break;
            }
            case `${KPI_ACTION_DELETE} ${API_SUCCESS}`: {
                dispatch(getKpiActionList());
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'success',
                        message: action.payload?.message || i18next.t('notification.successDeleted'),
                    })
                );
                break;
            }
            case `${KPI_ACTION_DELETE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_ACTION_BY_ID: {
                const id = action.payload;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/kpiAction/${id}`,
                        feature: GET_KPI_ACTION_BY_ID,
                    })
                );
                break;
            }
            case `${GET_KPI_ACTION_BY_ID} ${API_SUCCESS}`: {
                const data = action.payload?.kpiActionDto;
                dispatch(setKpiActionById(data));
                break;
            }
            case `${GET_KPI_ACTION_BY_ID} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_ACTION_PAGE: {
                interface IQuery {
                    kpiId: number;
                    page: number;
                    size: number;
                    sort: string;
                }

                const query: IQuery = {
                    kpiId: -1,
                    page: 0,
                    size: 50,
                    sort: 'createdAt,desc',
                };
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/kpiAction/page',
                        feature: GET_KPI_ACTION_PAGE,
                        query,
                    })
                );
                break;
            }
            case `${GET_KPI_ACTION_PAGE} ${API_SUCCESS}`: {
                break;
            }
            case `${GET_KPI_ACTION_PAGE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_ACTION_LIST: {
                interface IQuery {
                    kpiId: number;
                }

                const query: IQuery = {
                    kpiId: action.payload || state?.kpi?.kpi?.selectedKpi?.data?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/kpiAction/list',
                        feature: GET_KPI_ACTION_LIST,
                        query,
                    })
                );
                break;
            }
            case `${GET_KPI_ACTION_LIST} ${API_SUCCESS}`: {
                const data = action.payload?.kpiActionDtoList;
                dispatch(setKpiActionList(data));
                break;
            }
            case `${GET_KPI_ACTION_LIST} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }
        }
    };
