import { AnyAction } from 'redux';
import { CHANGE_APPENDICES_FILE_NAME, SET_APPENDICES_LIST } from '../../actions/company/appendices.action';
import { IAppendicesItem } from '../../../entities/emissionTypes/appendicesCombustion';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';

const initialState = {
    fileList: [] as Array<IAppendicesItem>,
    fileName: '',
};

export const appendicesCommonReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case SET_AUTH_LOGOUT:
            return initialState;
        case CHANGE_APPENDICES_FILE_NAME:
            return {
                ...state,
                fileName: action.payload,
            };
        case SET_APPENDICES_LIST:
            return {
                ...state,
                fileList: action.payload,
            };
        default:
            return state;
    }
};
