import { IFacility } from "../../../entities/company/facilityCombustion";
import { IOrganizational } from "../../../entities/company/organizationalCombustion";

export const ORGANIZATIONAL = '[ORGANIZATIONAL]';

export const GET_ORGANIZATIONAL_RESULT = `${ORGANIZATIONAL} Get Result`;
export const SET_ORGANIZATIONAL_RESULT = `${ORGANIZATIONAL} Set Result`;

export const GET_ORGANIZATIONAL_APPRACHES = `${ORGANIZATIONAL} Get Appraches`;
export const SET_ORGANIZATIONAL_APPRACHES = `${ORGANIZATIONAL} Set Appraches`;
export const SELECT_ORGANIZATIONAL_APPRACHES = `${ORGANIZATIONAL} Select Appraches`;

export const GET_ORGANIZATIONAL_SUB_APPRACHES = `${ORGANIZATIONAL} Get Sub Appraches`;
export const SET_ORGANIZATIONAL_SUB_APPRACHES = `${ORGANIZATIONAL} Set Sub Appraches`;
export const SELECT_ORGANIZATIONAL_SUB_APPRACHES = `${ORGANIZATIONAL} Select Sub Appraches`;

export const GET_ORGANIZATIONAL_FACILITY_LIST = `${ORGANIZATIONAL} Get Facility List`;
export const SET_ORGANIZATIONAL_FACILITY_LIST = `${ORGANIZATIONAL} Set Facility List`;
export const SELECT_ORGANIZATIONAL_FACILITY = `${ORGANIZATIONAL} Select Facility`;

export const CHANGE_ORGANIZATIONAL_REASON = `${ORGANIZATIONAL} Change Reason`;
export const CHANGE_ORGANIZATIONAL_STRUCTURE = `${ORGANIZATIONAL} Change Structure`;
export const SUBMIT_ORGANIZATIONAL_FORM = `${ORGANIZATIONAL} Submit Organizational Form`;

export type ISubmit = {
    onSuccess: () => void;
};

export const submitOrganizationalForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_ORGANIZATIONAL_FORM,
    meta: {
        onSuccess,
    },
});

export const getOrganizationalResult = (value: number) => ({
    type: GET_ORGANIZATIONAL_RESULT,
    payload: value
});

export const setOrganizationalResult = (value: number) => ({
    type: SET_ORGANIZATIONAL_RESULT,
    payload: value
});

export const getOrganizationalAppraches = () => ({
    type: GET_ORGANIZATIONAL_APPRACHES,
});

export const setOrganizationalAppraches = (value: IOrganizational) => ({
    type: SET_ORGANIZATIONAL_APPRACHES,
    payload: value
});

export const selectOrganizationalAppraches = (value: number) => ({
    type: SELECT_ORGANIZATIONAL_APPRACHES,
    payload: value
});

export const getOrganizationalSubAppraches = (value: number) => ({
    type: GET_ORGANIZATIONAL_SUB_APPRACHES,
    payload: value
});

export const setOrganizationalSubAppraches = (value: IOrganizational) => ({
    type: SET_ORGANIZATIONAL_SUB_APPRACHES,
    payload: value
});

export const selectOrganizationalSubAppraches = (value: number) => ({
    type: SELECT_ORGANIZATIONAL_SUB_APPRACHES,
    payload: value
});

export const getOrganizationalFacilityList = () => ({
    type: GET_ORGANIZATIONAL_FACILITY_LIST,
});

export const setOrganizationalFacilityList = (value: IFacility) => ({
    type: SET_ORGANIZATIONAL_FACILITY_LIST,
    payload: value
});

export const selectOrganizationalFacility = (value: Array<number>) => ({
    type: SELECT_ORGANIZATIONAL_FACILITY,
    payload: value
});

export const changeOrganizationalReason = (value: string) => ({
    type: CHANGE_ORGANIZATIONAL_REASON,
    payload: value
});

export const changeOrganizationalStructure = (value: string) => ({
    type: CHANGE_ORGANIZATIONAL_STRUCTURE,
    payload: value
});