import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface IInputProps {
    text?: string;
}

const Loader: FunctionComponent<IInputProps> = ({ text = '' }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '150px',
                backgroundColor: 'inherit',
            }}
        >
            <Typography variant="h6">{text}</Typography>
            <div
                style={{
                    marginTop: '15px',
                    border: '5px solid #f3f3f3',
                    borderTop: '5px solid #00C292',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    animation: 'spin 1s linear infinite',
                }}
            >
                <style>
                    {`
                     @keyframes spin {
                        0% {
                          transform: rotate(0deg);
                        }
                        100% {
                          transform: rotate(360deg);
                        }
                      } 
                    `}
                </style>
            </div>
        </div>
    );
};

export default Loader;
