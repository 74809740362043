import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IBiomassActivity,
    IBiomassActivityType,
    IBiomassAdditional,
    IBiomassDatabase,
    IBiomassFuelType,
    IBiomassResult,
    IBiomassSize,
    IBiomassUnit,
} from '../../../entities/emissionTypes/biomassCombustion';
import { EMISSION } from './base.action';

export const BIOMASS = 'BIOMASS';

export const CHANGE_BIOMASS_MAIN_FACILITY = `${EMISSION} ${BIOMASS} Change Main Facility`;
export const CHANGE_BIOMASS_SUB_FACILITY = `${EMISSION} ${BIOMASS} Change Sub Facility`;

export const GET_BIOMASS_TIERS = `${EMISSION} ${BIOMASS} Get Tiers`;
export const SET_BIOMASS_TIERS = `${EMISSION} ${BIOMASS} Set Tiers`;
export const SELECT_BIOMASS_TIER = `${EMISSION} ${BIOMASS} Select Tier`;

export const GET_BIOMASS_DATABASES = `${EMISSION} ${BIOMASS} Get Databases`;
export const SET_BIOMASS_DATABASES = `${EMISSION} ${BIOMASS} Set Databases`;
export const SELECT_BIOMASS_DATABASE = `${EMISSION} ${BIOMASS} Select Database`;

export const CHANGE_BIOMASS_EQUIVALENT_FACTOR = `${EMISSION} ${BIOMASS} Change Equivalent Factor`;

export const GET_BIOMASS_ACTIVITIES = `${EMISSION} ${BIOMASS} Get Activities`;
export const SET_BIOMASS_ACTIVITIES = `${EMISSION} ${BIOMASS} Set Activities`;
export const SELECT_BIOMASS_ACTIVITY = `${EMISSION} ${BIOMASS} Select Activity`;

export const GET_BIOMASS_FUEL_TYPES = `${EMISSION} ${BIOMASS} Get Fuel Types`;
export const SET_BIOMASS_FUEL_TYPES = `${EMISSION} ${BIOMASS} Set Fuel Types`;
export const SELECT_BIOMASS_FUEL_TYPE = `${EMISSION} ${BIOMASS} Select Fuel Type`;

export const GET_BIOMASS_ACTIVITY_TYPES = `${EMISSION} ${BIOMASS} Get Activity Types`;
export const SET_BIOMASS_ACTIVITY_TYPES = `${EMISSION} ${BIOMASS} Set Activity Types`;
export const SELECT_BIOMASS_ACTIVITY_TYPE = `${EMISSION} ${BIOMASS} Select Activity Type`;

export const GET_BIOMASS_UNITS = `${EMISSION} ${BIOMASS} Get Units`;
export const SET_BIOMASS_UNITS = `${EMISSION} ${BIOMASS} Set Units`;
export const SELECT_BIOMASS_UNIT = `${EMISSION} ${BIOMASS} Select Unit`;

export const CHANGE_BIOMASS_WEIGHT = `${EMISSION} ${BIOMASS} Change Weight`;
export const CHANGE_BIOMASS_DISTANCE = `${EMISSION} ${BIOMASS} Change Distance`;

export const GET_BIOMASS_LADEN_PERCENTAGES = `${EMISSION} ${BIOMASS} Get Laden Percentages`;
export const SET_BIOMASS_LADEN_PERCENTAGES = `${EMISSION} ${BIOMASS} Set Laden Percentages`;
export const SELECT_BIOMASS_LADEN_PERCENTAGE = `${EMISSION} ${BIOMASS} Select Laden percentage`;

export const GET_BIOMASS_SIZES = `${EMISSION} ${BIOMASS} Get Sizes`;
export const SET_BIOMASS_SIZES = `${EMISSION} ${BIOMASS} Set Sizes`;
export const SELECT_BIOMASS_SIZE = `${EMISSION} ${BIOMASS} Select Size`;

export const CHANGE_BIOMASS_YEAR = `${EMISSION} ${BIOMASS} Change Year`;
export const CHANGE_BIOMASS_TAGS = `${EMISSION} ${BIOMASS} Change Tags`;
export const SELECT_BIOMASS_ACTIVITY_DATA = `${EMISSION} ${BIOMASS} Select Activity Data`;
export const CHANGE_BIOMASS_CONSUMPTION_AMOUNT = `${EMISSION} ${BIOMASS} Change Consumption Amount`;
export const CHANGE_BIOMASS_DESCRIPTION = `${EMISSION} ${BIOMASS} Change Description`;

export const GET_BIOMASS_ADDITIONAL_CONSTANTS = `${EMISSION} ${BIOMASS} Get Additional Constants`;
export const SET_BIOMASS_ADDITIONAL_CONSTANTS = `${EMISSION} ${BIOMASS} Set Additional Constants`;
export const CHANGE_BIOMASS_NKD = `${EMISSION} ${BIOMASS} Change NKD`;
export const CHANGE_BIOMASS_NKD_REF = `${EMISSION} ${BIOMASS} Change NKD Reference`;
export const CHANGE_BIOMASS_DENSITY = `${EMISSION} ${BIOMASS} Change Density`;
export const CHANGE_BIOMASS_DENSITY_REF = `${EMISSION} ${BIOMASS} Change Density Reference`;

export const SUBMIT_BIOMASS_FORM = `${EMISSION} ${BIOMASS} Submit Form`;
export const SET_BIOMASS_RESULT = `${EMISSION} ${BIOMASS} Set Result`;
export const CLEAR_BIOMASS_INPUT = `${EMISSION} ${BIOMASS} Clear Biomass Input`;
export const CLEAR_BIOMASS_CONSUMPTION_AMOUNT = `${EMISSION} ${BIOMASS} Clear Consumption Amount`;

export type ISubmit = {
    onSuccess: () => void;
};
export const clearBiomassInput = (list: Array<string>) => ({
    type: CLEAR_BIOMASS_INPUT,
    payload: list,
});

export const changeBiomassMainFacility = (text: string) => ({
    type: CHANGE_BIOMASS_MAIN_FACILITY,
    payload: text,
});

export const changeBiomassSubFacility = (text: string) => {
    return {
        type: CHANGE_BIOMASS_SUB_FACILITY,
        payload: text,
    };
};

export const getBiomassTiers = () => ({
    type: GET_BIOMASS_TIERS,
});

export const setBiomassTiers = (list: Array<ITier>) => ({
    type: SET_BIOMASS_TIERS,
    payload: list,
});

export const selectBiomassTier = (tier: ITier) => ({
    type: SELECT_BIOMASS_TIER,
    payload: tier,
});

export const getBiomassDatabases = (tier: ITier) => ({
    type: GET_BIOMASS_DATABASES,
    payload: tier,
});

export const setBiomassDatabases = (list: Array<IBiomassDatabase>) => ({
    type: SET_BIOMASS_DATABASES,
    payload: list,
});

export const selectBiomassDatabase = (database: IBiomassDatabase) => ({
    type: SELECT_BIOMASS_DATABASE,
    payload: database,
});

export const getBiomassActivities = (tier: IBiomassDatabase) => ({
    type: GET_BIOMASS_ACTIVITIES,
    payload: tier,
});

export const setBiomassActivities = (activities: Array<IBiomassActivity>) => ({
    type: SET_BIOMASS_ACTIVITIES,
    payload: activities,
});

export const selectBiomassActivity = (activity: IBiomassActivity) => ({
    type: SELECT_BIOMASS_ACTIVITY,
    payload: activity,
});

///////
export const getBiomassFuelTypes = (tier: ITier) => ({
    type: GET_BIOMASS_FUEL_TYPES,
    payload: tier,
});

export const setBiomassFuelTypes = (list: Array<IBiomassFuelType>) => ({
    type: SET_BIOMASS_FUEL_TYPES,
    payload: list,
});

export const selectBiomassFuelType = (database: IBiomassFuelType) => ({
    type: SELECT_BIOMASS_FUEL_TYPE,
    payload: database,
});

export const getBiomassActivityTypes = (value: any) => ({
    type: GET_BIOMASS_ACTIVITY_TYPES,
    payload: value,
});

export const setBiomassActivityTypes = (activities: Array<IBiomassActivityType>) => ({
    type: SET_BIOMASS_ACTIVITY_TYPES,
    payload: activities,
});

export const selectBiomassActivityType = (activity: IBiomassActivityType) => ({
    type: SELECT_BIOMASS_ACTIVITY_TYPE,
    payload: activity,
});

export const getBiomassUnits = (units: IBiomassActivityType) => ({
    type: GET_BIOMASS_UNITS,
    payload: units,
});

export const setBiomassUnits = (units: Array<IBiomassUnit>) => ({
    type: SET_BIOMASS_UNITS,
    payload: units,
});

export const selectBiomassUnit = (unit: IBiomassUnit) => ({
    type: SELECT_BIOMASS_UNIT,
    payload: unit,
});

export const getBiomassAdditionalConstants = (additional: IBiomassUnit) => ({
    type: GET_BIOMASS_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setBiomassAdditionalConstants = (additional: Array<IBiomassAdditional>) => ({
    type: SET_BIOMASS_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeBiomassNKD = (value: string) => ({
    type: CHANGE_BIOMASS_NKD,
    payload: value,
});
export const changeBiomassNKDReference = (value: string) => ({
    type: CHANGE_BIOMASS_NKD_REF,
    payload: value,
});
export const changeBiomassDensity = (value: string) => ({
    type: CHANGE_BIOMASS_DENSITY,
    payload: value,
});
export const changeBiomassDensityReference = (value: string) => ({
    type: CHANGE_BIOMASS_DENSITY_REF,
    payload: value,
});

export const changeBiomassWeight = (value: number) => ({
    type: CHANGE_BIOMASS_WEIGHT,
    payload: value,
});

export const changeBiomassDistance = (value: number) => ({
    type: CHANGE_BIOMASS_DISTANCE,
    payload: value,
});

export const getBiomassLadenPercentages = (ladens: any) => ({
    type: GET_BIOMASS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setBiomassLadenPercentages = (ladens: Array<any>) => ({
    type: SET_BIOMASS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectBiomassLadenPercentage = (laden: any) => ({
    type: SELECT_BIOMASS_LADEN_PERCENTAGE,
    payload: laden,
});

export const getBiomassSizes = (sizes: IBiomassActivityType) => ({
    type: GET_BIOMASS_SIZES,
    payload: sizes,
});

export const setBiomassSizes = (sizes: Array<IBiomassSize>) => ({
    type: SET_BIOMASS_SIZES,
    payload: sizes,
});

export const selectBiomassSize = (size: IBiomassSize) => ({
    type: SELECT_BIOMASS_SIZE,
    payload: size,
});

///////
export const submitBiomassForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_BIOMASS_FORM,
    meta: {
        onSuccess,
    },
});

export const setBiomassResult = (data: Array<IBiomassResult>) => ({
    type: SET_BIOMASS_RESULT,
    payload: data,
});

export const selectBiomassActivityData = (activity: string) => ({
    type: SELECT_BIOMASS_ACTIVITY_DATA,
    payload: activity,
});

export const changeBiomassYear = (value: number) => ({
    type: CHANGE_BIOMASS_YEAR,
    payload: value,
});

export const changeBiomassTags = (value: string) => ({
    type: CHANGE_BIOMASS_TAGS,
    payload: value,
});

export const changeBiomassDescription = (text: string) => ({
    type: CHANGE_BIOMASS_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_BIOMASS_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number }) => ({
    type: CHANGE_BIOMASS_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_BIOMASS_CONSUMPTION_AMOUNT,
});
