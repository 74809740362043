import { AnyAction } from 'redux';
import { initialCategoryData } from '../../../data/initialCategory.data';
import { initialTypeData } from '../../../data/initialType.data';
import {
    CHANGE_BASE_EQUIVALENT_TAGS,
    CHANGE_BASE_TAGS,
    CLEAR_BASE_INPUTS,
    CLEAR_BASE_SPECIAL_INPUTS,
    SELECT_BASE_CALCULATION_YEAR,
    SELECT_BASE_COMPANY,
    SELECT_BASE_FACILITY,
    SELECT_BASE_TAGS,
    SELECT_TYPE,
    SET_BASE_COMPANIES,
    SET_BASE_COMPANIES_LEVEL_COMPANY,
    SET_BASE_FACILITIES,
    SET_BASE_FACILITY_LEVEL_FACILITY,
    SET_BASE_TAGS,
    SET_FORM_LOADER,
} from '../../actions/emission/base.action';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';
import { CHANGE_UPDATE_INVENTORY, SET_FORM_WITH_INVENTORY_DETAIL } from '../../actions/emission/update.action';
import { SUBMIT_BIOMASS_FORM } from '../../actions/emission/biomass.action';
import { SUBMIT_BUSINESS_FORM } from '../../actions/emission/business.action';
import { API_ERROR } from '../../actions/api.action';
import { SUBMIT_BOUGHT_ASSETS_FORM } from '../../actions/emission/boughtAssets.action';
import { SUBMIT_DOWNSTREAM_FORM } from '../../actions/emission/downstream.action';
import { SUBMIT_DOWNSTREAM_LEASED_FORM } from '../../actions/emission/downstreamLeased.action';
import { SUBMIT_ELECTRIC_FORM } from '../../actions/emission/electric.action';
import { SUBMIT_EMPLOYEE_FORM } from '../../actions/emission/employee.action';
import { SUBMIT_FUGITIVE_FORM } from '../../actions/emission/fugitive.action';
import { SUBMIT_LULUCF_FORM } from '../../actions/emission/lulucf.action';
import { SUBMIT_MOBILE_FORM, SUBMIT_STEAM_FORM } from '../../actions/emission.action';
import { SUBMIT_PROCESS_FORM } from '../../actions/emission/process.action';
import { SUBMIT_PRODUCT_LIFETIME_FORM } from '../../actions/emission/productLifetime.action';
import { SUBMIT_SERVICE_USAGE_FORM } from '../../actions/emission/serviceUsage.action';
import { SUBMIT_SOLD_PRODUCTS_FORM } from '../../actions/emission/soldProducts.action';
import { SUBMIT_STATIC_FORM } from '../../actions/emission/static.action';
import { SUBMIT_UPSTREAM_FORM } from '../../actions/emission/upstream.action';
import { SUBMIT_UPSTREAM_LEASED_FORM } from '../../actions/emission/upstreamLeased.action';
import { SUBMIT_VISITOR_FORM } from '../../actions/emission/visitor.action';
import { SUBMIT_WASTE_FORM } from '../../actions/emission/waste.action';

const initialState = {
    tagList: [],
    tags: '',
    categories: initialCategoryData,
    types: initialTypeData,
    selectedType: '',
    equivalentTags: '',
    fileName: '',
    companies: [],
    selectedCompany: '',
    facilities: [],
    selectedFacility: '',
    calculationYear: '2023',
    isUpdateForm: false,
    formLoader: false,
};

export const emissionBaseReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_FORM_WITH_INVENTORY_DETAIL: {
            const data = action.payload;
            return {
                ...state,
                selectedFacility: {
                    id: data?.facilityId,
                    key: data?.facilityName,
                    label: data?.facilityName,
                },
            };
        }
        case CHANGE_UPDATE_INVENTORY:
            return {
                ...state,
                isUpdateForm: action.payload,
            };
        case SET_AUTH_LOGOUT:
            return initialState;
        case CLEAR_BASE_INPUTS:
            return {
                ...initialState,
            };
        case SET_BASE_TAGS:
            return { ...state, tagList: action.payload };
        case SELECT_BASE_TAGS:
            return { ...state, tags: action.payload.key };
        case SELECT_TYPE:
            return { ...state, selectedType: action.payload };
        case CHANGE_BASE_TAGS:
            return { ...state, tags: action.payload };
        case CHANGE_BASE_EQUIVALENT_TAGS:
            return { ...state, equivalentTags: action.payload };
        case SET_BASE_COMPANIES:
            return { ...state, companies: action.payload?.sort((a: any, b: any) => a.id - b.id) };
        case SET_BASE_COMPANIES_LEVEL_COMPANY:
            return {
                ...state,
                selectedCompany: {
                    id: action.payload?.companyId || action.payload?.id,
                    key: action.payload?.companyName,
                    label: action.payload?.companyName,
                },
            };
        case SELECT_BASE_COMPANY:
            return { ...state, selectedCompany: action.payload };
        case SET_BASE_FACILITIES:
            return { ...state, facilities: action.payload?.sort((a: any, b: any) => a.id - b.id) };
        case SET_BASE_FACILITY_LEVEL_FACILITY:
            return {
                ...state,
                selectedFacility: {
                    id: action.payload?.id,
                    key: action.payload?.facilityName,
                    label: action.payload?.facilityName,
                },
            };
        case SELECT_BASE_FACILITY:
            return { ...state, selectedFacility: action.payload };
        case SELECT_BASE_CALCULATION_YEAR:
            return { ...state, calculationYear: action.payload + '' };
        case CLEAR_BASE_SPECIAL_INPUTS:
            const data = clearInput(action, initialState);
            return {
                ...state,
                ...data,
            };
        case SET_FORM_LOADER: {
            return {
                ...state,
                formLoader: action.payload,
            };
        }

        case `${SUBMIT_STATIC_FORM} ${API_ERROR}`:
        case `${SUBMIT_MOBILE_FORM} ${API_ERROR}`:
        case `${SUBMIT_PROCESS_FORM} ${API_ERROR}`:
        case `${SUBMIT_FUGITIVE_FORM} ${API_ERROR}`:
        case `${SUBMIT_BIOMASS_FORM} ${API_ERROR}`:
        case `${SUBMIT_LULUCF_FORM} ${API_ERROR}`:
        case `${SUBMIT_ELECTRIC_FORM} ${API_ERROR}`:
        case `${SUBMIT_STEAM_FORM} ${API_ERROR}`:
        case `${SUBMIT_UPSTREAM_FORM} ${API_ERROR}`:
        case `${SUBMIT_DOWNSTREAM_FORM} ${API_ERROR}`:
        case `${SUBMIT_EMPLOYEE_FORM} ${API_ERROR}`:
        case `${SUBMIT_VISITOR_FORM} ${API_ERROR}`:
        case `${SUBMIT_BUSINESS_FORM} ${API_ERROR}`:
        case `${SUBMIT_BOUGHT_ASSETS_FORM} ${API_ERROR}`:
        case `${SUBMIT_SOLD_PRODUCTS_FORM} ${API_ERROR}`:
        case `${SUBMIT_WASTE_FORM} ${API_ERROR}`:
        case `${SUBMIT_UPSTREAM_LEASED_FORM} ${API_ERROR}`:
        case `${SUBMIT_SERVICE_USAGE_FORM} ${API_ERROR}`:
        case `${SUBMIT_DOWNSTREAM_LEASED_FORM} ${API_ERROR}`:
        case `${SUBMIT_PRODUCT_LIFETIME_FORM} ${API_ERROR}`:
            return { ...state, formLoader: false };

        default:
            return state;
    }
};

export const clearInput = (action: AnyAction, initialState: {}) => {
    const list: Array<keyof typeof initialState> = action.payload;
    const data = list.reduce((acc: {}, i: keyof typeof initialState) => {
        acc[i] = initialState[i];
        return acc;
    }, {});

    return data;
};
