export const USER_GROUP = '[USER_GROUP]';

export const PUT_USER_GROUP_BY_USER_ID = `${USER_GROUP} Put User Group By User Id`;
export const DELETE_USER_GROUP_BY_USER_ID = `${USER_GROUP} Delete User Group By User Id`;

export const PUT_USER_GROUP_BY_PRIVILEGE_GROUP_ID = `${USER_GROUP} Put User Group By Privilege Group Id`;
export const DELETE_USER_GROUP_BY_PRIVILEGE_GROUP_ID = `${USER_GROUP} Delete User Group By Privilege Group Id`;

export const POST_USER_GROUP = `${USER_GROUP} Post User Group`;
export const POST_USER_GROUP_NAMES = `${USER_GROUP} Post User Group Name`;

export const GET_USER_GROUP_BY_ID = `${USER_GROUP} Get User Group By Id`;
export const DELETE_USER_GROUP_BY_ID = `${USER_GROUP} Delete User Group By Id`;

export type ISubmit = {
    onSuccess: () => void;
};

export const putUserGroupByUserId = () => ({
    type: PUT_USER_GROUP_BY_USER_ID,
});
export const deleteUserGroupByUserId = () => ({
    type: DELETE_USER_GROUP_BY_USER_ID,
});
export const putUserGroupByPrivilegeGroupId = () => ({
    type: PUT_USER_GROUP_BY_PRIVILEGE_GROUP_ID,
});
export const deleteUserGroupByPrivilegeGroupId = () => ({
    type: DELETE_USER_GROUP_BY_PRIVILEGE_GROUP_ID,
});
export const postUserGroup = () => ({
    type: POST_USER_GROUP,
});
export const postUserGroupNames = () => ({
    type: POST_USER_GROUP_NAMES,
});
export const getUserGroupById = () => ({
    type: GET_USER_GROUP_BY_ID,
});
export const deleteUserGroupById = () => ({
    type: DELETE_USER_GROUP_BY_ID,
});
