import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IWasteActivity,
    IWasteActivityType,
    IWasteAdditional,
    IWasteDatabase,
    IWasteFuelType,
    IWasteResult,
    IWasteSize,
    IWasteUnit,
} from '../../../entities/emissionTypes/wasteCombustion';
import { EMISSION } from './base.action';

export const WASTE = 'WASTE';

////-- Base
export const CHANGE_WASTE_MAIN_FACILITY = `${EMISSION} ${WASTE} Change Main Facility`;
export const CHANGE_WASTE_SUB_FACILITY = `${EMISSION} ${WASTE} Change Sub Facility`;

export const GET_WASTE_TIERS = `${EMISSION} ${WASTE} Get Tiers`;
export const SET_WASTE_TIERS = `${EMISSION} ${WASTE} Set Tiers`;
export const SELECT_WASTE_TIER = `${EMISSION} ${WASTE} Select Tier`;

export const GET_WASTE_DATABASES = `${EMISSION} ${WASTE} Get Databases`;
export const SET_WASTE_DATABASES = `${EMISSION} ${WASTE} Set Databases`;
export const SELECT_WASTE_DATABASE = `${EMISSION} ${WASTE} Select Database`;

export const CHANGE_WASTE_EQUIVALENT_FACTOR = `${EMISSION} ${WASTE} Change Equivalent Factor`;

export const GET_WASTE_ACTIVITIES = `${EMISSION} ${WASTE} Get Activities`;
export const SET_WASTE_ACTIVITIES = `${EMISSION} ${WASTE} Set Activities`;
export const SELECT_WASTE_ACTIVITY = `${EMISSION} ${WASTE} Select Activity`;
/////--- End base

export const GET_WASTE_FUEL_TYPES = `${EMISSION} ${WASTE} Get Fuel Types`;
export const SET_WASTE_FUEL_TYPES = `${EMISSION} ${WASTE} Set Fuel Types`;
export const SELECT_WASTE_FUEL_TYPE = `${EMISSION} ${WASTE} Select Fuel Type`;

export const GET_WASTE_ACTIVITY_TYPES = `${EMISSION} ${WASTE} Get Activity Types`;
export const SET_WASTE_ACTIVITY_TYPES = `${EMISSION} ${WASTE} Set Activity Types`;
export const SELECT_WASTE_ACTIVITY_TYPE = `${EMISSION} ${WASTE} Select Activity Type`;

export const GET_WASTE_UNITS = `${EMISSION} ${WASTE} Get Units`;
export const SET_WASTE_UNITS = `${EMISSION} ${WASTE} Set Units`;
export const SELECT_WASTE_UNIT = `${EMISSION} ${WASTE} Select Unit`;

export const GET_WASTE_ADDITIONAL_CONSTANTS = `${EMISSION} ${WASTE} Get Additional Constants`;
export const SET_WASTE_ADDITIONAL_CONSTANTS = `${EMISSION} ${WASTE} Set Additional Constants`;

export const CHANGE_WASTE_WEIGHT = `${EMISSION} ${WASTE} Change Weight`;
export const CHANGE_WASTE_DISTANCE = `${EMISSION} ${WASTE} Change Distance`;

export const GET_WASTE_LADEN_PERCENTAGES = `${EMISSION} ${WASTE} Get Laden Percentages`;
export const SET_WASTE_LADEN_PERCENTAGES = `${EMISSION} ${WASTE} Set Laden Percentages`;
export const SELECT_WASTE_LADEN_PERCENTAGE = `${EMISSION} ${WASTE} Select Laden percentage`;

export const GET_WASTE_SIZES = `${EMISSION} ${WASTE} Get Sizes`;
export const SET_WASTE_SIZES = `${EMISSION} ${WASTE} Set Sizes`;
export const SELECT_WASTE_SIZE = `${EMISSION} ${WASTE} Select Size`;

////-- Base
export const CHANGE_WASTE_YEAR = `${EMISSION} ${WASTE} Change Year`;
export const SELECT_WASTE_ACTIVITY_DATA = `${EMISSION} ${WASTE} Select Activity Data`;
export const CHANGE_WASTE_CONSUMPTION_AMOUNT = `${EMISSION} ${WASTE} Change Consumption Amount`;
export const CHANGE_WASTE_DESCRIPTION = `${EMISSION} ${WASTE} Change Description`;

export const SUBMIT_WASTE_FORM = `${EMISSION} ${WASTE} Submit Form`;
export const SET_WASTE_RESULT = `${EMISSION} ${WASTE} Set Result`;
/////-- End Base
export const CLEAR_WASTE_CONSUMPTION_AMOUNT = `${EMISSION} ${WASTE} Clear Consumption Amount`;
export const CLEAR_WASTE_INPUT = `${EMISSION} ${WASTE} Clear Waste Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearWasteInput = (list: Array<string>) => ({
    type: CLEAR_WASTE_INPUT,
    payload: list,
});

/// Region Waste
export const changeWasteMainFacility = (text: string) => ({
    type: CHANGE_WASTE_MAIN_FACILITY,
    payload: text,
});

export const changeWasteSubFacility = (text: string) => {
    return {
        type: CHANGE_WASTE_SUB_FACILITY,
        payload: text,
    };
};

export const getWasteTiers = () => ({
    type: GET_WASTE_TIERS,
});

export const setWasteTiers = (list: Array<ITier>) => ({
    type: SET_WASTE_TIERS,
    payload: list,
});

export const selectWasteTier = (tier: ITier) => ({
    type: SELECT_WASTE_TIER,
    payload: tier,
});

export const getWasteDatabases = (tier: ITier) => ({
    type: GET_WASTE_DATABASES,
    payload: tier,
});

export const setWasteDatabases = (list: Array<IWasteDatabase>) => ({
    type: SET_WASTE_DATABASES,
    payload: list,
});

export const selectWasteDatabase = (database: IWasteDatabase) => ({
    type: SELECT_WASTE_DATABASE,
    payload: database,
});

export const getWasteActivities = (tier: ITier) => ({
    type: GET_WASTE_ACTIVITIES,
    payload: tier,
});

export const setWasteActivities = (activities: Array<IWasteActivity>) => ({
    type: SET_WASTE_ACTIVITIES,
    payload: activities,
});

export const selectWasteActivity = (activity: IWasteActivity) => ({
    type: SELECT_WASTE_ACTIVITY,
    payload: activity,
});

///////
export const getWasteFuelTypes = (tier: ITier) => ({
    type: GET_WASTE_FUEL_TYPES,
    payload: tier,
});

export const setWasteFuelTypes = (list: Array<IWasteFuelType>) => ({
    type: SET_WASTE_FUEL_TYPES,
    payload: list,
});

export const selectWasteFuelType = (database: IWasteFuelType) => ({
    type: SELECT_WASTE_FUEL_TYPE,
    payload: database,
});

export const getWasteActivityTypes = (value: any) => ({
    type: GET_WASTE_ACTIVITY_TYPES,
    payload: value,
});

export const setWasteActivityTypes = (activities: Array<IWasteActivityType>) => ({
    type: SET_WASTE_ACTIVITY_TYPES,
    payload: activities,
});

export const selectWasteActivityType = (activity: IWasteActivityType) => ({
    type: SELECT_WASTE_ACTIVITY_TYPE,
    payload: activity,
});

export const getWasteUnits = (units: IWasteActivityType) => ({
    type: GET_WASTE_UNITS,
    payload: units,
});

export const setWasteUnits = (units: Array<IWasteUnit>) => ({
    type: SET_WASTE_UNITS,
    payload: units,
});

export const selectWasteUnit = (unit: IWasteUnit) => ({
    type: SELECT_WASTE_UNIT,
    payload: unit,
});

export const getWasteAdditionalConstants = (additional: IWasteUnit) => ({
    type: GET_WASTE_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setWasteAdditionalConstants = (additional: Array<IWasteAdditional>) => ({
    type: SET_WASTE_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeWasteWeight = (value: number) => ({
    type: CHANGE_WASTE_WEIGHT,
    payload: value,
});

export const changeWasteDistance = (value: number) => ({
    type: CHANGE_WASTE_DISTANCE,
    payload: value,
});

export const getWasteLadenPercentages = (ladens: any) => ({
    type: GET_WASTE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setWasteLadenPercentages = (ladens: Array<any>) => ({
    type: SET_WASTE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectWasteLadenPercentage = (laden: any) => ({
    type: SELECT_WASTE_LADEN_PERCENTAGE,
    payload: laden,
});

export const getWasteSizes = (sizes: IWasteActivityType) => ({
    type: GET_WASTE_SIZES,
    payload: sizes,
});

export const setWasteSizes = (sizes: Array<IWasteSize>) => ({
    type: SET_WASTE_SIZES,
    payload: sizes,
});

export const selectWasteSize = (size: IWasteSize) => ({
    type: SELECT_WASTE_SIZE,
    payload: size,
});

///////
export const submitWasteForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_WASTE_FORM,
    meta: {
        onSuccess,
    },
});

export const setWasteResult = (data: Array<IWasteResult>) => ({
    type: SET_WASTE_RESULT,
    payload: data,
});

export const selectWasteActivityData = (activity: string) => ({
    type: SELECT_WASTE_ACTIVITY_DATA,
    payload: activity,
});

export const changeWasteYear = (value: number) => ({
    type: CHANGE_WASTE_YEAR,
    payload: value,
});

export const changeWasteDescription = (text: string) => ({
    type: CHANGE_WASTE_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_WASTE_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_WASTE_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_WASTE_CONSUMPTION_AMOUNT,
});
/// End Region Waste
