import { Middleware } from 'redux';
import { ITier } from '../../../../entities/emissionTypes/baseType';
import {
    IDownstreamLeasedActivity,
    IDownstreamLeasedActivityType,
    IDownstreamLeasedAdditional,
    IDownstreamLeasedDatabase,
    IDownstreamLeasedFuelType,
    IDownstreamLeasedResult,
    IDownstreamLeasedUnit,
} from '../../../../entities/emissionTypes/downstreamLeasedCombustion';

import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_DOWNSTREAM_LEASED_ACTIVITIES,
    GET_DOWNSTREAM_LEASED_ACTIVITY_TYPES,
    GET_DOWNSTREAM_LEASED_ADDITIONAL_CONSTANTS,
    GET_DOWNSTREAM_LEASED_DATABASES,
    GET_DOWNSTREAM_LEASED_FUEL_TYPES,
    GET_DOWNSTREAM_LEASED_TIERS,
    GET_DOWNSTREAM_LEASED_UNITS,
    setDownstreamLeasedActivities,
    setDownstreamLeasedActivityTypes,
    setDownstreamLeasedAdditionalConstants,
    setDownstreamLeasedDatabases,
    setDownstreamLeasedFuelTypes,
    setDownstreamLeasedResult,
    setDownstreamLeasedTiers,
    setDownstreamLeasedUnits,
    SUBMIT_DOWNSTREAM_LEASED_FORM,
} from '../../../actions/emission/downstreamLeased.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const emissionDownstreamLeasedMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            // region DOWNSTREAM_LEASED
            case GET_DOWNSTREAM_LEASED_TIERS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/common/data/tiers',
                        feature: GET_DOWNSTREAM_LEASED_TIERS,
                        query: {
                            emissionCategoryId: 22,
                        },
                    })
                );
                break;
            case `${GET_DOWNSTREAM_LEASED_TIERS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<ITier>,
                        v: {
                            id: number;
                            language: string;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setDownstreamLeasedTiers(list));
                break;
            }

            case GET_DOWNSTREAM_LEASED_DATABASES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/emissionFactorDatabases',
                        feature: GET_DOWNSTREAM_LEASED_DATABASES,
                        query: {
                            tierId: action.payload.id,
                            emissionCategoryId: 22,
                        },
                    })
                );
                break;

            case `${GET_DOWNSTREAM_LEASED_DATABASES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IDownstreamLeasedDatabase>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setDownstreamLeasedDatabases(list));
                break;
            }

            case GET_DOWNSTREAM_LEASED_ACTIVITIES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'downstreamLeadAssetsEmission/data/emissionSources',
                        feature: GET_DOWNSTREAM_LEASED_ACTIVITIES,
                        query: {
                            emissionFactorDatabaseId:
                                action.payload.id || state.emission.downstreamLeased.databases[0].id,
                        },
                    })
                );
                break;

            case `${GET_DOWNSTREAM_LEASED_ACTIVITIES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IDownstreamLeasedActivity>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            title: v.title,
                        });
                        return acc;
                    },
                    []
                );
                next(setDownstreamLeasedActivities(list));
                break;
            }

            case GET_DOWNSTREAM_LEASED_ACTIVITY_TYPES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'downstreamLeadAssetsEmission/data/emissionActivities',
                        feature: GET_DOWNSTREAM_LEASED_ACTIVITY_TYPES,
                        query: {
                            emissionSourceId: action.payload.id,
                        },
                    })
                );
                break;

            case `${GET_DOWNSTREAM_LEASED_ACTIVITY_TYPES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IDownstreamLeasedActivityType>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setDownstreamLeasedActivityTypes(list));
                break;
            }

            case GET_DOWNSTREAM_LEASED_FUEL_TYPES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'downstreamLeadAssetsEmission/data/emissionActivityTypes',
                        feature: GET_DOWNSTREAM_LEASED_FUEL_TYPES,
                        query: {
                            emissionActivityId: action.payload.id,
                        },
                    })
                );
                break;

            case `${GET_DOWNSTREAM_LEASED_FUEL_TYPES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IDownstreamLeasedFuelType>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setDownstreamLeasedFuelTypes(list));
                break;
            }

            case GET_DOWNSTREAM_LEASED_UNITS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'downstreamLeadAssetsEmission/data/units',
                        feature: GET_DOWNSTREAM_LEASED_UNITS,
                        query: {
                            emissionActivityTypeId:
                                action.payload?.id || state.emission.downstreamLeased.sourceList[0]?.id,
                        },
                    })
                );
                break;

            case `${GET_DOWNSTREAM_LEASED_UNITS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IDownstreamLeasedUnit>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                            arg0: string;
                            arg1?: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            arg0: v.arg0,
                            arg1: v?.arg1,
                        });
                        return acc;
                    },
                    []
                );
                next(setDownstreamLeasedUnits(list));
                break;
            }

            case GET_DOWNSTREAM_LEASED_ADDITIONAL_CONSTANTS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'downstreamLeadAssetsEmission/data/additionalConstants',
                        feature: GET_DOWNSTREAM_LEASED_ADDITIONAL_CONSTANTS,
                        query: {
                            emissionUnitId: action.payload?.id,
                        },
                    })
                );
                break;

            case `${GET_DOWNSTREAM_LEASED_ADDITIONAL_CONSTANTS} ${API_SUCCESS}`: {
                const list: Array<IDownstreamLeasedAdditional> = action.payload.emissionCalculationArgumentDtoSet;
                next(setDownstreamLeasedAdditionalConstants(list));
                break;
            }

            case SUBMIT_DOWNSTREAM_LEASED_FORM: {
                interface IArgumentSet {
                    identifier: string;
                    value: number | null;
                    reference: string | null;
                }

                interface IBody {
                    organizationId?: number;
                    companyId: number;
                    facilityId: number;
                    consumptionList: Array<{ arg0: number; arg1: number; period: string }>;
                    tierId: number;
                    emissionFactorDatabaseId: number;
                    emissionSourceId: number;
                    emissionActivityId: number;
                    emissionActivityTypeId: number;
                    emissionCategoryId: number;
                    unitId: number;
                    equivalentFactor?: number;
                    tags: string;
                    equivalentTags: string;
                    description: string;
                    activityData: string;
                }

                const body: IBody = {
                    organizationId:
                        state?.auth?.userInfo?.organization?.id ||
                        state?.auth?.userInfo?.company?.organizationId ||
                        //state?.auth?.userInfo?.user?.organizationId ||
                        null,
                    companyId: state?.emission?.base?.selectedCompany?.id,
                    facilityId: state?.emission?.base?.selectedFacility?.id,
                    consumptionList: [],
                    tierId: state.emission.downstreamLeased.tier.id,
                    emissionFactorDatabaseId:
                        state.emission.downstreamLeased.database.id || state.emission.downstreamLeased.databases[0]?.id,
                    emissionSourceId: state.emission.downstreamLeased.activity.id,
                    emissionActivityId: state.emission.downstreamLeased.activityType.id,
                    emissionActivityTypeId:
                        state.emission.downstreamLeased.source.id || state.emission.downstreamLeased.sourceList[0]?.id,
                    emissionCategoryId: 22,
                    unitId: state.emission.downstreamLeased.unit?.id,
                    equivalentFactor: state.emission.downstreamLeased.equivalentFactor || null,
                    tags: state.emission.base.tags || '',
                    equivalentTags: state.emission.base.equivalentTags || '',
                    description: state.emission.downstreamLeased.description,
                    activityData: state.emission.downstreamLeased.activityData,
                };
                //const nkd =
                //    state.emission.downstreamLeased.nkd &&
                //    ({
                //        identifier: 'NKD',
                //        value: +state.emission.downstreamLeased.nkd.value || null,
                //        reference: state.emission.downstreamLeased.nkd.references || null,
                //    } as IArgumentSet);
                //
                //const density =
                //    state.emission.downstreamLeased.density &&
                //    ({
                //        identifier: 'DENSITY',
                //        value: +state.emission.downstreamLeased.density.value || null,
                //        reference: state.emission.downstreamLeased.density.reference,
                //    } as IArgumentSet);

                const equivalent =
                    state.emission.downstreamLeased.equivalentFactorUnit &&
                    ({
                        identifier: 'CO2E',
                        value: +state.emission.downstreamLeased.equivalentFactor || null,
                        reference: state.emission.base.equivalentTags || null,
                    } as IArgumentSet);

                let argSetArray: any[] | IArgumentSet[] = [];
                //typeof nkd.value === 'number' && argSetArray.push(nkd);
                //typeof density.value === 'number' && argSetArray.push(density);
                typeof equivalent.value === 'number' && argSetArray.push(equivalent);

                const consumptions = state.emission.downstreamLeased.consumptions;
                const consumptionKeys: Array<string> = Object.keys(consumptions as { [key: number]: number | null });
                const consumptionData = consumptionKeys.reduce(
                    (
                        acc: Array<{
                            arg0: number;
                            arg1: number;
                            period: string;
                            emissionCalculationArgumentSet: Array<IArgumentSet> | [];
                        }>,
                        key: string
                    ) => {
                        const numberKey = Number(key);
                        if (consumptions[key]['km'] && numberKey !== 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['km']),
                                arg1: Number(consumptions[key]['tonne']),
                                period: `${state?.emission?.base?.calculationYear}-${numberKey + 1 < 10 ? '0' : ''}${
                                    numberKey + 1
                                }`,
                                emissionCalculationArgumentSet: argSetArray,
                            });
                        }
                        if (consumptions[key]['km'] && numberKey === 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['km']),
                                arg1: Number(consumptions[key]['tonne']),
                                period: state?.emission?.base?.calculationYear,
                                emissionCalculationArgumentSet: argSetArray,
                            });
                        }
                        return acc;
                    },
                    []
                );
                body.consumptionList = consumptionData;
                next(
                    apiRequest({
                        method: 'POST',
                        url: 'downstreamLeadAssetsEmission/calculate',
                        feature: SUBMIT_DOWNSTREAM_LEASED_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_DOWNSTREAM_LEASED_FORM} ${API_SUCCESS}`: {
                const data = action.payload.corporateEmissionDto.emissionList;
                const onSuccess = action.meta.onSuccess;
                const savedData: Array<IDownstreamLeasedResult> = [...data];
                next(setDownstreamLeasedResult(savedData));
                onSuccess();
                break;
            }

            case `${SUBMIT_DOWNSTREAM_LEASED_FORM} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }

            // endregion DOWNSTREAM_LEASED
        }
    };
