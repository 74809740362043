import { IAppendicesItem } from '../../../entities/emissionTypes/appendicesCombustion';

export const APPENDICES = '[APPENDICES]';

export const CHANGE_APPENDICES_FILE_NAME = `${APPENDICES} Change Appendices File Name`;
export const UPLOAD_APPENDICES_FILE = `${APPENDICES} Upload Appendices File`;
export const DOWNLOAD_APPENDICES_FILE = `${APPENDICES} Download Appendices File`;
export const DELETE_APPENDICES_FILE = `${APPENDICES} Delete Appendices File`;

export const GET_APPENDICES_LIST = `${APPENDICES} Get Appendices List`;
export const SET_APPENDICES_LIST = `${APPENDICES} Set Appendices List`;

export type ISubmit = {
    onSuccess: () => void;
};

export const changeAppendicesFileName = (fileName: string) => ({
    type: CHANGE_APPENDICES_FILE_NAME,
    payload: fileName,
});

export const uploadAppendicesFile = ({ onSuccess }: ISubmit, document: any) => ({
    type: UPLOAD_APPENDICES_FILE,
    payload: document,
    meta: {
        onSuccess,
    },
});

export const downloadAppendicesFile = (item: any) => ({
    type: DOWNLOAD_APPENDICES_FILE,
    payload: item,
});

export const deleteAppendicesFile = ({ onSuccess }: ISubmit, id: number) => ({
    type: DELETE_APPENDICES_FILE,
    meta: {
        onSuccess,
    },
    payload: id,
});

export const getAppendicesList = () => ({
    type: GET_APPENDICES_LIST,
});

export const setAppendicesList = (data: Array<IAppendicesItem>) => ({
    type: SET_APPENDICES_LIST,
    payload: data,
});
