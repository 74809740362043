import { Middleware } from 'redux';
import { IOrganizational } from '../../../../entities/company/organizationalCombustion';
import { API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_ORGANIZATIONAL_APPRACHES,
    GET_ORGANIZATIONAL_FACILITY_LIST,
    GET_ORGANIZATIONAL_RESULT,
    GET_ORGANIZATIONAL_SUB_APPRACHES,
    setOrganizationalAppraches,
    setOrganizationalFacilityList,
    setOrganizationalResult,
    setOrganizationalSubAppraches,
    SUBMIT_ORGANIZATIONAL_FORM,
} from '../../../actions/company/organizational.action';

export const organizationalMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_ORGANIZATIONAL_RESULT: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/boundry/get',
                        feature: GET_ORGANIZATIONAL_RESULT,
                        query: {
                            companyId: action.payload,
                        },
                    })
                );
                break;
            }

            case `${GET_ORGANIZATIONAL_RESULT} ${API_SUCCESS}`: {
                const data = action.payload.companyBoundry;
                next(setOrganizationalResult(data));
                break;
            }

            case GET_ORGANIZATIONAL_APPRACHES: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/boundry/consolidation/appraches',
                        feature: GET_ORGANIZATIONAL_APPRACHES,
                    })
                );
                break;
            }

            case `${GET_ORGANIZATIONAL_APPRACHES} ${API_SUCCESS}`: {
                const data = action.payload.consolidationApproaches;
                next(setOrganizationalAppraches(data));
                break;
            }

            case GET_ORGANIZATIONAL_SUB_APPRACHES: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/boundry/consolidation/sub/appraches',
                        feature: GET_ORGANIZATIONAL_SUB_APPRACHES,
                        query: {
                            consolidationApproachId: action.payload,
                        },
                    })
                );
                break;
            }

            case `${GET_ORGANIZATIONAL_SUB_APPRACHES} ${API_SUCCESS}`: {
                const data = action.payload.consolidationApproaches;
                next(setOrganizationalSubAppraches(data));
                break;
            }

            case GET_ORGANIZATIONAL_FACILITY_LIST: {
                const companyId = state.auth.userInfo?.company?.id;
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/facility/list',
                        feature: GET_ORGANIZATIONAL_FACILITY_LIST,
                        query: {
                            companyId,
                        },
                    })
                );
                break;
            }

            case `${GET_ORGANIZATIONAL_FACILITY_LIST} ${API_SUCCESS}`: {
                const data = action.payload.facilityList.reduce(
                    (
                        acc: Array<IOrganizational>,
                        v: {
                            id: number;
                            facilityName: string;
                            email: string;
                            address: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id || 0,
                            facilityName: v.facilityName,
                            email: v.email,
                            address: v.address,
                        });
                        return acc;
                    },
                    []
                );
                next(setOrganizationalFacilityList(data));
                break;
            }

            case SUBMIT_ORGANIZATIONAL_FORM: {
                const companyId = state.auth.userInfo?.company?.id;

                interface IBody {
                    companyId: number;
                    reason: string;
                    organization_structure: string;
                    consolidationApproachId: number;
                    consolidationSubApproachId: number;
                    facilityList: Array<number>;
                }

                const body: IBody = {
                    companyId,
                    reason: state.company.organizational.reason || '',
                    organization_structure: state.company.organizational.structure || '',
                    consolidationApproachId: state.company.organizational.appraches.id || null,
                    consolidationSubApproachId:
                        state.company.organizational.appraches.id === 1
                            ? state.company.organizational.subAppraches.id
                            : null,
                    facilityList: state.company.organizational.selectedFacilities || [],
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/company/boundry/create',
                        feature: SUBMIT_ORGANIZATIONAL_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_ORGANIZATIONAL_FORM} ${API_SUCCESS}`: {
                const data = action.payload.companyBoundry;
                const onSuccess = action.meta.onSuccess;
                dispatch(setOrganizationalResult(data));
                onSuccess();
                //window.location.reload();
                break;
            }
        }
    };
