import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IMobileActivity,
    IMobileActivityType,
    IMobileAdditional,
    IMobileDatabase,
    IMobileFuelType,
    IMobileResult,
    IMobileSize,
    IMobileUnit,
} from '../../../entities/emissionTypes/mobileCombustion';
import { EMISSION } from './base.action';

export const MOBILE = 'MOBILE';

////-- Base
export const CHANGE_MOBILE_MAIN_FACILITY = `${EMISSION} ${MOBILE} Change Main Facility`;
export const CHANGE_MOBILE_SUB_FACILITY = `${EMISSION} ${MOBILE} Change Sub Facility`;

export const GET_MOBILE_TIERS = `${EMISSION} ${MOBILE} Get Tiers`;
export const SET_MOBILE_TIERS = `${EMISSION} ${MOBILE} Set Tiers`;
export const SELECT_MOBILE_TIER = `${EMISSION} ${MOBILE} Select Tier`;

export const GET_MOBILE_DATABASES = `${EMISSION} ${MOBILE} Get Databases`;
export const SET_MOBILE_DATABASES = `${EMISSION} ${MOBILE} Set Databases`;
export const SELECT_MOBILE_DATABASE = `${EMISSION} ${MOBILE} Select Database`;

export const CHANGE_MOBILE_EQUIVALENT_FACTOR = `${EMISSION} ${MOBILE} Change Equivalent Factor`;

export const GET_MOBILE_ACTIVITIES = `${EMISSION} ${MOBILE} Get Activities`;
export const SET_MOBILE_ACTIVITIES = `${EMISSION} ${MOBILE} Set Activities`;
export const SELECT_MOBILE_ACTIVITY = `${EMISSION} ${MOBILE} Select Activity`;
/////--- End base

export const GET_MOBILE_FUEL_TYPES = `${EMISSION} ${MOBILE} Get Fuel Types`;
export const SET_MOBILE_FUEL_TYPES = `${EMISSION} ${MOBILE} Set Fuel Types`;
export const SELECT_MOBILE_FUEL_TYPE = `${EMISSION} ${MOBILE} Select Fuel Type`;

export const GET_MOBILE_ACTIVITY_TYPES = `${EMISSION} ${MOBILE} Get Activity Types`;
export const SET_MOBILE_ACTIVITY_TYPES = `${EMISSION} ${MOBILE} Set Activity Types`;
export const SELECT_MOBILE_ACTIVITY_TYPE = `${EMISSION} ${MOBILE} Select Activity Type`;

export const GET_MOBILE_UNITS = `${EMISSION} ${MOBILE} Get Units`;
export const SET_MOBILE_UNITS = `${EMISSION} ${MOBILE} Set Units`;
export const SELECT_MOBILE_UNIT = `${EMISSION} ${MOBILE} Select Unit`;

export const GET_MOBILE_ADDITIONAL_CONSTANTS = `${EMISSION} ${MOBILE} Get Additional Constants`;
export const SET_MOBILE_ADDITIONAL_CONSTANTS = `${EMISSION} ${MOBILE} Set Additional Constants`;

export const CHANGE_MOBILE_WEIGHT = `${EMISSION} ${MOBILE} Change Weight`;
export const CHANGE_MOBILE_DISTANCE = `${EMISSION} ${MOBILE} Change Distance`;

export const GET_MOBILE_LADEN_PERCENTAGES = `${EMISSION} ${MOBILE} Get Laden Percentages`;
export const SET_MOBILE_LADEN_PERCENTAGES = `${EMISSION} ${MOBILE} Set Laden Percentages`;
export const SELECT_MOBILE_LADEN_PERCENTAGE = `${EMISSION} ${MOBILE} Select Laden percentage`;

export const GET_MOBILE_SIZES = `${EMISSION} ${MOBILE} Get Sizes`;
export const SET_MOBILE_SIZES = `${EMISSION} ${MOBILE} Set Sizes`;
export const SELECT_MOBILE_SIZE = `${EMISSION} ${MOBILE} Select Size`;

////-- Base
export const CHANGE_MOBILE_YEAR = `${EMISSION} ${MOBILE} Change Year`;
export const CHANGE_MOBILE_TAGS = `${EMISSION} ${MOBILE} Change Tags`;
export const SELECT_MOBILE_ACTIVITY_DATA = `${EMISSION} ${MOBILE} Select Activity Data`;
export const CHANGE_MOBILE_CONSUMPTION_AMOUNT = `${EMISSION} ${MOBILE} Change Consumption Amount`;
export const CHANGE_MOBILE_DESCRIPTION = `${EMISSION} ${MOBILE} Change Description`;

export const SUBMIT_MOBILE_FORM = `${EMISSION} ${MOBILE} Submit Form`;
export const SET_MOBILE_RESULT = `${EMISSION} ${MOBILE} Set Result`;
/////-- End Base
export const CLEAR_MOBILE_CONSUMPTION_AMOUNT = `${EMISSION} ${MOBILE} Clear Consumption Amount`;
export const CLEAR_MOBILE_INPUT = `${EMISSION} ${MOBILE} Clear Mobile Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearMobileInput = (list: Array<string>) => ({
    type: CLEAR_MOBILE_INPUT,
    payload: list,
});
/// Region Mobile
export const changeMobileMainFacility = (text: string) => ({
    type: CHANGE_MOBILE_MAIN_FACILITY,
    payload: text,
});

export const changeMobileSubFacility = (text: string) => {
    return {
        type: CHANGE_MOBILE_SUB_FACILITY,
        payload: text,
    };
};

export const getMobileTiers = () => ({
    type: GET_MOBILE_TIERS,
});

export const setMobileTiers = (list: Array<ITier>) => ({
    type: SET_MOBILE_TIERS,
    payload: list,
});

export const selectMobileTier = (tier: ITier) => ({
    type: SELECT_MOBILE_TIER,
    payload: tier,
});

export const getMobileDatabases = (tier: ITier) => ({
    type: GET_MOBILE_DATABASES,
    payload: tier,
});

export const setMobileDatabases = (list: Array<IMobileDatabase>) => ({
    type: SET_MOBILE_DATABASES,
    payload: list,
});

export const selectMobileDatabase = (database: IMobileDatabase) => ({
    type: SELECT_MOBILE_DATABASE,
    payload: database,
});

export const getMobileActivities = (tier: ITier) => ({
    type: GET_MOBILE_ACTIVITIES,
    payload: tier,
});

export const setMobileActivities = (activities: Array<IMobileActivity>) => ({
    type: SET_MOBILE_ACTIVITIES,
    payload: activities,
});

export const selectMobileActivity = (activity: IMobileActivity) => ({
    type: SELECT_MOBILE_ACTIVITY,
    payload: activity,
});

///////
export const getMobileFuelTypes = (tier: ITier) => ({
    type: GET_MOBILE_FUEL_TYPES,
    payload: tier,
});

export const setMobileFuelTypes = (list: Array<IMobileFuelType>) => ({
    type: SET_MOBILE_FUEL_TYPES,
    payload: list,
});

export const selectMobileFuelType = (database: IMobileFuelType) => ({
    type: SELECT_MOBILE_FUEL_TYPE,
    payload: database,
});

export const getMobileActivityTypes = (value: any) => ({
    type: GET_MOBILE_ACTIVITY_TYPES,
    payload: value,
});

export const setMobileActivityTypes = (activities: Array<IMobileActivityType>) => ({
    type: SET_MOBILE_ACTIVITY_TYPES,
    payload: activities,
});

export const selectMobileActivityType = (activity: IMobileActivityType) => ({
    type: SELECT_MOBILE_ACTIVITY_TYPE,
    payload: activity,
});

export const getMobileUnits = (units: IMobileActivityType) => ({
    type: GET_MOBILE_UNITS,
    payload: units,
});

export const setMobileUnits = (units: Array<IMobileUnit>) => ({
    type: SET_MOBILE_UNITS,
    payload: units,
});

export const selectMobileUnit = (unit: IMobileUnit) => ({
    type: SELECT_MOBILE_UNIT,
    payload: unit,
});

export const getMobileAdditionalConstants = (additional: IMobileUnit) => ({
    type: GET_MOBILE_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setMobileAdditionalConstants = (additional: Array<IMobileAdditional>) => ({
    type: SET_MOBILE_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeMobileWeight = (value: number) => ({
    type: CHANGE_MOBILE_WEIGHT,
    payload: value,
});

export const changeMobileDistance = (value: number) => ({
    type: CHANGE_MOBILE_DISTANCE,
    payload: value,
});

export const getMobileLadenPercentages = (ladens: any) => ({
    type: GET_MOBILE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setMobileLadenPercentages = (ladens: Array<any>) => ({
    type: SET_MOBILE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectMobileLadenPercentage = (laden: any) => ({
    type: SELECT_MOBILE_LADEN_PERCENTAGE,
    payload: laden,
});

export const getMobileSizes = (sizes: IMobileActivityType) => ({
    type: GET_MOBILE_SIZES,
    payload: sizes,
});

export const setMobileSizes = (sizes: Array<IMobileSize>) => ({
    type: SET_MOBILE_SIZES,
    payload: sizes,
});

export const selectMobileSize = (size: IMobileSize) => ({
    type: SELECT_MOBILE_SIZE,
    payload: size,
});

///////
export const submitMobileForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_MOBILE_FORM,
    meta: {
        onSuccess,
    },
});

export const setMobileResult = (data: Array<IMobileResult>) => ({
    type: SET_MOBILE_RESULT,
    payload: data,
});

export const selectMobileActivityData = (activity: string) => ({
    type: SELECT_MOBILE_ACTIVITY_DATA,
    payload: activity,
});

export const changeMobileYear = (value: number) => ({
    type: CHANGE_MOBILE_YEAR,
    payload: value,
});

export const changeMobileTags = (value: string) => ({
    type: CHANGE_MOBILE_TAGS,
    payload: value,
});

export const changeMobileDescription = (text: string) => ({
    type: CHANGE_MOBILE_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_MOBILE_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_MOBILE_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_MOBILE_CONSUMPTION_AMOUNT,
});
/// End Region Mobile
