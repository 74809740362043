import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IStaticActivity,
    IStaticActivityType,
    IStaticAdditional,
    IStaticDatabase,
    IStaticFuelType,
    IStaticResult,
    IStaticSize,
    IStaticUnit,
} from '../../../entities/emissionTypes/staticCombustion';
import { EMISSION } from './base.action';

export const STATIC = 'STATIC';

////-- Base
export const CHANGE_STATIC_MAIN_FACILITY = `${EMISSION} ${STATIC} Change Main Facility`;
export const CHANGE_STATIC_SUB_FACILITY = `${EMISSION} ${STATIC} Change Sub Facility`;

export const GET_STATIC_TIERS = `${EMISSION} ${STATIC} Get Tiers`;
export const SET_STATIC_TIERS = `${EMISSION} ${STATIC} Set Tiers`;
export const SELECT_STATIC_TIER = `${EMISSION} ${STATIC} Select Tier`;

export const GET_STATIC_DATABASES = `${EMISSION} ${STATIC} Get Databases`;
export const SET_STATIC_DATABASES = `${EMISSION} ${STATIC} Set Databases`;
export const SELECT_STATIC_DATABASE = `${EMISSION} ${STATIC} Select Database`;

export const CHANGE_STATIC_EQUIVALENT_FACTOR = `${EMISSION} ${STATIC} Change Equivalent Factor`;

export const GET_STATIC_ACTIVITIES = `${EMISSION} ${STATIC} Get Activities`;
export const SET_STATIC_ACTIVITIES = `${EMISSION} ${STATIC} Set Activities`;
export const SELECT_STATIC_ACTIVITY = `${EMISSION} ${STATIC} Select Activity`;
/////--- End base

export const GET_STATIC_FUEL_TYPES = `${EMISSION} ${STATIC} Get Fuel Types`;
export const SET_STATIC_FUEL_TYPES = `${EMISSION} ${STATIC} Set Fuel Types`;
export const SELECT_STATIC_FUEL_TYPE = `${EMISSION} ${STATIC} Select Fuel Type`;

export const GET_STATIC_ACTIVITY_TYPES = `${EMISSION} ${STATIC} Get Activity Types`;
export const SET_STATIC_ACTIVITY_TYPES = `${EMISSION} ${STATIC} Set Activity Types`;
export const SELECT_STATIC_ACTIVITY_TYPE = `${EMISSION} ${STATIC} Select Activity Type`;

export const GET_STATIC_UNITS = `${EMISSION} ${STATIC} Get Units`;
export const SET_STATIC_UNITS = `${EMISSION} ${STATIC} Set Units`;
export const SELECT_STATIC_UNIT = `${EMISSION} ${STATIC} Select Unit`;

export const CHANGE_STATIC_WEIGHT = `${EMISSION} ${STATIC} Change Weight`;
export const CHANGE_STATIC_DISTANCE = `${EMISSION} ${STATIC} Change Distance`;

export const GET_STATIC_LADEN_PERCENTAGES = `${EMISSION} ${STATIC} Get Laden Percentages`;
export const SET_STATIC_LADEN_PERCENTAGES = `${EMISSION} ${STATIC} Set Laden Percentages`;
export const SELECT_STATIC_LADEN_PERCENTAGE = `${EMISSION} ${STATIC} Select Laden percentage`;

export const GET_STATIC_SIZES = `${EMISSION} ${STATIC} Get Sizes`;
export const SET_STATIC_SIZES = `${EMISSION} ${STATIC} Set Sizes`;
export const SELECT_STATIC_SIZE = `${EMISSION} ${STATIC} Select Size`;

////-- Base
export const CHANGE_STATIC_YEAR = `${EMISSION} ${STATIC} Change Year`;
export const CHANGE_STATIC_TAGS = `${EMISSION} ${STATIC} Change Tags`;
export const SELECT_STATIC_ACTIVITY_DATA = `${EMISSION} ${STATIC} Select Activity Data`;
export const CHANGE_STATIC_CONSUMPTION_AMOUNT = `${EMISSION} ${STATIC} Change Consumption Amount`;
export const CHANGE_STATIC_DESCRIPTION = `${EMISSION} ${STATIC} Change Description`;

export const GET_STATIC_ADDITIONAL_CONSTANTS = `${EMISSION} ${STATIC} Get Additional Constants`;
export const SET_STATIC_ADDITIONAL_CONSTANTS = `${EMISSION} ${STATIC} Set Additional Constants`;
export const CHANGE_STATIC_NKD = `${EMISSION} ${STATIC} Change NKD`;
export const CHANGE_STATIC_NKD_REF = `${EMISSION} ${STATIC} Change NKD Reference`;
export const CHANGE_STATIC_DENSITY = `${EMISSION} ${STATIC} Change Density`;
export const CHANGE_STATIC_DENSITY_REF = `${EMISSION} ${STATIC} Change Density Reference`;

export const SUBMIT_STATIC_FORM = `${EMISSION} ${STATIC} Submit Form`;
export const SET_STATIC_RESULT = `${EMISSION} ${STATIC} Set Result`;
/////-- End Base
export const CLEAR_STATIC_INPUT = `${EMISSION} ${STATIC} Clear Static Input`;
export const CLEAR_STATIC_CONSUMPTION_AMOUNT = `${EMISSION} ${STATIC} Clear Consumption Amount`;

export type ISubmit = {
    onSuccess: () => void;
};
/// Region Static
export const clearStaticInput = (list: Array<string>) => ({
    type: CLEAR_STATIC_INPUT,
    payload: list,
});

export const changeStaticMainFacility = (text: string) => ({
    type: CHANGE_STATIC_MAIN_FACILITY,
    payload: text,
});

export const changeStaticSubFacility = (text: string) => {
    return {
        type: CHANGE_STATIC_SUB_FACILITY,
        payload: text,
    };
};

export const getStaticTiers = () => ({
    type: GET_STATIC_TIERS,
});

export const setStaticTiers = (list: Array<ITier>) => ({
    type: SET_STATIC_TIERS,
    payload: list,
});

export const selectStaticTier = (tier: ITier) => ({
    type: SELECT_STATIC_TIER,
    payload: tier,
});

export const getStaticDatabases = (tier: ITier) => ({
    type: GET_STATIC_DATABASES,
    payload: tier,
});

export const setStaticDatabases = (list: Array<IStaticDatabase>) => ({
    type: SET_STATIC_DATABASES,
    payload: list,
});

export const selectStaticDatabase = (database: IStaticDatabase) => ({
    type: SELECT_STATIC_DATABASE,
    payload: database,
});

export const getStaticActivities = (tier: IStaticDatabase) => ({
    type: GET_STATIC_ACTIVITIES,
    payload: tier,
});

export const setStaticActivities = (activities: Array<IStaticActivity>) => ({
    type: SET_STATIC_ACTIVITIES,
    payload: activities,
});

export const selectStaticActivity = (activity: IStaticActivity) => ({
    type: SELECT_STATIC_ACTIVITY,
    payload: activity,
});

///////
export const getStaticFuelTypes = (tier: ITier) => ({
    type: GET_STATIC_FUEL_TYPES,
    payload: tier,
});

export const setStaticFuelTypes = (list: Array<IStaticFuelType>) => ({
    type: SET_STATIC_FUEL_TYPES,
    payload: list,
});

export const selectStaticFuelType = (database: IStaticFuelType) => ({
    type: SELECT_STATIC_FUEL_TYPE,
    payload: database,
});

export const getStaticActivityTypes = (value: any) => ({
    type: GET_STATIC_ACTIVITY_TYPES,
    payload: value,
});

export const setStaticActivityTypes = (activities: Array<IStaticActivityType>) => ({
    type: SET_STATIC_ACTIVITY_TYPES,
    payload: activities,
});

export const selectStaticActivityType = (activity: IStaticActivityType) => ({
    type: SELECT_STATIC_ACTIVITY_TYPE,
    payload: activity,
});

export const getStaticUnits = (units: IStaticActivityType) => ({
    type: GET_STATIC_UNITS,
    payload: units,
});

export const setStaticUnits = (units: Array<IStaticUnit>) => ({
    type: SET_STATIC_UNITS,
    payload: units,
});

export const selectStaticUnit = (unit: IStaticUnit) => ({
    type: SELECT_STATIC_UNIT,
    payload: unit,
});

export const getStaticAdditionalConstants = (additional: IStaticUnit) => ({
    type: GET_STATIC_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setStaticAdditionalConstants = (additional: Array<IStaticAdditional>) => ({
    type: SET_STATIC_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeStaticNKD = (value: string) => ({
    type: CHANGE_STATIC_NKD,
    payload: value,
});
export const changeStaticNKDReference = (value: string) => ({
    type: CHANGE_STATIC_NKD_REF,
    payload: value,
});
export const changeStaticDensity = (value: string) => ({
    type: CHANGE_STATIC_DENSITY,
    payload: value,
});
export const changeStaticDensityReference = (value: string) => ({
    type: CHANGE_STATIC_DENSITY_REF,
    payload: value,
});

export const changeStaticWeight = (value: number) => ({
    type: CHANGE_STATIC_WEIGHT,
    payload: value,
});

export const changeStaticDistance = (value: number) => ({
    type: CHANGE_STATIC_DISTANCE,
    payload: value,
});

export const getStaticLadenPercentages = (ladens: any) => ({
    type: GET_STATIC_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setStaticLadenPercentages = (ladens: Array<any>) => ({
    type: SET_STATIC_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectStaticLadenPercentage = (laden: any) => ({
    type: SELECT_STATIC_LADEN_PERCENTAGE,
    payload: laden,
});

export const getStaticSizes = (sizes: IStaticActivityType) => ({
    type: GET_STATIC_SIZES,
    payload: sizes,
});

export const setStaticSizes = (sizes: Array<IStaticSize>) => ({
    type: SET_STATIC_SIZES,
    payload: sizes,
});

export const selectStaticSize = (size: IStaticSize) => ({
    type: SELECT_STATIC_SIZE,
    payload: size,
});

///////
export const submitStaticForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_STATIC_FORM,
    meta: {
        onSuccess,
    },
});

export const setStaticResult = (data: Array<IStaticResult>) => ({
    type: SET_STATIC_RESULT,
    payload: data,
});

export const selectStaticActivityData = (activity: string) => ({
    type: SELECT_STATIC_ACTIVITY_DATA,
    payload: activity,
});

export const changeStaticYear = (value: number) => ({
    type: CHANGE_STATIC_YEAR,
    payload: value,
});

export const changeStaticTags = (value: string) => ({
    type: CHANGE_STATIC_TAGS,
    payload: value,
});

export const changeStaticDescription = (text: string) => ({
    type: CHANGE_STATIC_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_STATIC_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number }) => ({
    type: CHANGE_STATIC_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_STATIC_CONSUMPTION_AMOUNT,
});
/// End Region Static
