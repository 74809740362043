export const KPI = '[KPI]';

// API
export const KPI_CREATE = `${KPI} KPI Create`;
export const KPI_UPDATE = `${KPI} KPI Update`;
export const KPI_DELETE = `${KPI} KPI Delete`;
export const KPI_UNASSIGN_USER = `${KPI} KPI Unassign User`;
export const KPI_ASSIGN_USER = `${KPI} KPI Assign User`;
export const GET_KPI_BY_ID = `${KPI} Get KPI By ID`;
export const SET_KPI_BY_ID = `${KPI} Set KPI By ID`;
export const GET_KPI_PAGE = `${KPI} Get KPI Page`;
export const SET_KPI_PAGE = `${KPI} Set KPI Page`;
export const GET_KPI_LIST = `${KPI} Get KPI List`;
export const SET_KPI_LIST = `${KPI} Set KPI List`;
export const CLEAR_KPI_INPUTS = `${KPI} Clear KPI Inputs`;
// END API

// INPUT
export const CHANGE_KPI_TITLE = `${KPI} Change KPI Title`;
export const CHANGE_KPI_DESCRIPTION = `${KPI} Change KPI Description`;
export const CHANGE_KPI_START_DATE = `${KPI} Change KPI Start Date`;
export const CHANGE_KPI_END_DATE = `${KPI} Change KPI End Date`;
export const CHANGE_KPI_STARTING_AMOUNT = `${KPI} Change KPI Starting Amount`;
export const CHANGE_KPI_GOAL_AMOUNT = `${KPI} Change KPI Goal Amount`;
// END INPUT

export type ISubmit = {
    onSuccess: () => void;
};
export const submit = ({ onSuccess }: ISubmit) => ({
    meta: {
        onSuccess,
    },
});

export const kpiCreate = (data?: any) => ({
    type: KPI_CREATE,
    payload: data,
});
export const kpiUpdate = (data?: any) => ({
    type: KPI_UPDATE,
    payload: data,
});
export const kpiDelete = (data?: any) => ({
    type: KPI_DELETE,
    payload: data,
});
export const kpiUnassignUser = (data?: any) => ({
    type: KPI_UNASSIGN_USER,
    payload: data,
});
export const kpiAssignUser = (data?: any) => ({
    type: KPI_ASSIGN_USER,
    payload: data,
});
export const getKpiById = (data?: any) => ({
    type: GET_KPI_BY_ID,
    payload: data,
});
export const setKpiById = (data?: any) => ({
    type: SET_KPI_BY_ID,
    payload: data,
});
export const getKpiPage = (data?: any) => ({
    type: GET_KPI_PAGE,
    payload: data,
});
export const setKpiPage = (data?: any) => ({
    type: SET_KPI_PAGE,
    payload: data,
});
export const getKpiList = (data?: any) => ({
    type: GET_KPI_LIST,
    payload: data,
});
export const setKpiList = (data?: any) => ({
    type: SET_KPI_LIST,
    payload: data,
});
export const clearKPIInputs = (data?: any) => ({
    type: CLEAR_KPI_INPUTS,
    payload: data,
});

// INPUTS
export const changeKpiTitle = (data?: any) => ({
    type: CHANGE_KPI_TITLE,
    payload: data,
});
export const changeKpiDescription = (data?: any) => ({
    type: CHANGE_KPI_DESCRIPTION,
    payload: data,
});
export const changeKpiStartDate = (data?: any) => ({
    type: CHANGE_KPI_START_DATE,
    payload: data,
});
export const changeKpiEndDate = (data?: any) => ({
    type: CHANGE_KPI_END_DATE,
    payload: data,
});

export const changeKpiStartingAmount = (data?: any) => ({
    type: CHANGE_KPI_STARTING_AMOUNT,
    payload: data,
});

export const changeKpiGoalAmount = (data?: any) => ({
    type: CHANGE_KPI_GOAL_AMOUNT,
    payload: data,
});
