import { RootState } from '../../store';

export const getAuthUsername = (state: RootState) => state.auth.username;
export const getAuthPassword = (state: RootState) => state.auth.password;
export const getAuthIsLogin = (state: RootState) => state.auth.isLogin;
export const getAuthCompany = (state: RootState) => state.auth.userInfo?.company;
export const getAuthFacility = (state: RootState) => state.auth.userInfo?.facility;
export const getAuthCompanyId = (state: RootState) => state.auth.userInfo?.company?.id;
export const getAuthCompanyName = (state: RootState) => state.auth.userInfo?.company?.companyName;
export const getAuthCompanyLogo = (state: RootState) => state.auth.userInfo?.company?.logoUrl;
export const getAuthFacilityLength = (state: RootState) => state.auth.userInfo?.company?.numberOfFacilities;
export const getAuthCompanyFacilities = (state: RootState) => state.auth.userInfo?.company?.facilityList;
export const getAuthPrivileges = (state: RootState) => state.auth.userInfo?.user?.privileges;
export const getAuthRoles = (state: RootState) => state.auth.userInfo?.user?.roles;
export const getAuthRoleTypes = (state: RootState) => state.auth.userInfo?.user?.roleTypes;
export const getAuthLevel = (state: RootState) => state.auth.userInfo?.user?.userLevel;
export const getAuthState = (state: RootState) => state.auth;
