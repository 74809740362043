import React, { useState, createContext, ReactNode, FC, useMemo } from 'react';

interface LayoutContextInterface {
    title: string;
    setTitle: React.Dispatch<any>;
    sectionFacilities: ReactNode;
    setSectionFacilities: React.Dispatch<any>;
    sectionAddButton: ReactNode;
    setSectionAddButton: React.Dispatch<any>;
    sectionRight: ReactNode;
    setSectionRight: React.Dispatch<any>;
}
const LayoutContext = createContext<LayoutContextInterface>({
    title: '',
    setTitle: () => {},
    sectionAddButton: true,
    setSectionAddButton: () => {},
    sectionFacilities: true,
    setSectionFacilities: () => {},
    sectionRight: '',
    setSectionRight: () => {},
});

export { LayoutContext };

interface IProps {
    children: ReactNode;
}

const LayoutContextProvider: FC<IProps> = ({ children }) => {
    const [title, setTitle] = useState('');
    const [sectionAddButton, setSectionAddButton] = useState(true);
    const [sectionFacilities, setSectionFacilities] = useState(true);
    const [sectionRight, setSectionRight] = useState(null);

    const contextValue = useMemo(
        () => ({
            title,
            setTitle,
            sectionAddButton,
            setSectionAddButton,
            sectionFacilities,
            setSectionFacilities,
            sectionRight,
            setSectionRight,
        }),
        [title, sectionRight]
    );

    return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
};

export default LayoutContextProvider;
