import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    DELETE_APPENDICES_FILE,
    DOWNLOAD_APPENDICES_FILE,
    GET_APPENDICES_LIST,
    setAppendicesList,
    UPLOAD_APPENDICES_FILE,
} from '../../../actions/company/appendices.action';
import { IAppendicesItem } from '../../../../entities/emissionTypes/appendicesCombustion';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const appendicesMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_APPENDICES_LIST: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/document/list',
                        feature: GET_APPENDICES_LIST,
                        query: {
                            companyId: state.auth.userInfo?.company?.id,
                            calculationFile: false,
                        },
                    })
                );
                break;
            }
            case `${GET_APPENDICES_LIST} ${API_SUCCESS}`: {
                const data = action.payload.documentList.reduce(
                    (
                        acc: Array<IAppendicesItem>,
                        v: {
                            id: number;
                            documentContentType: string;
                            documentFileName: string;
                            documentPath: string;
                            extension: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            documentContentType: v.documentContentType,
                            documentFileName: v.documentFileName,
                            documentPath: v.documentPath,
                            extension: v.extension,
                        });
                        return acc;
                    },
                    []
                );
                next(setAppendicesList(data));
                break;
            }

            case UPLOAD_APPENDICES_FILE: {
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/company/document/add',
                        feature: UPLOAD_APPENDICES_FILE,
                        onSuccess: action.meta.onSuccess,
                        query: {
                            companyId: state.auth.userInfo?.company?.id,
                            fileName: state.company.appendices.fileName || '',
                            corporateEmissionId: null,
                            isCalculationFile: false,
                        },
                        body: action.payload,
                    })
                );
                break;
            }
            case `${UPLOAD_APPENDICES_FILE} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                window.location.reload();
                break;
            }

            case `${UPLOAD_APPENDICES_FILE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case DOWNLOAD_APPENDICES_FILE: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/document/get',
                        feature: DOWNLOAD_APPENDICES_FILE,
                        query: {
                            companyId: state.auth.userInfo?.company?.id,
                            documentId: action.payload.id,
                        },
                        responseType: 'blob',
                        onSuccess: () => {
                            return action.payload;
                        },
                    })
                );
                break;
            }
            case `${DOWNLOAD_APPENDICES_FILE} ${API_SUCCESS}`: {
                const downloadedFile = action.meta.onSuccess();
                const href = URL.createObjectURL(action.payload);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${downloadedFile.documentFileName}.${downloadedFile.extension}`); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                break;
            }

            case DELETE_APPENDICES_FILE: {
                const companyId = state.auth.userInfo?.company?.id;

                next(
                    apiRequest({
                        method: 'DELETE',
                        url: '/company/document/delete',
                        feature: DELETE_APPENDICES_FILE,
                        onSuccess: action.meta.onSuccess,
                        query: {
                            companyId,
                            documentId: action.payload,
                        },
                    })
                );
                break;
            }
            case `${DELETE_APPENDICES_FILE} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                window.location.reload();
                break;
            }
        }
    };
