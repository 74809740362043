import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { changeAuthPassword, changeAuthUsername, submitAuthLogin } from '../../../redux/actions/auth/auth.action';
import { useAppSelector } from '../../../redux/hooks';
import { getAuthPassword, getAuthUsername } from '../../../redux/selectors/auth/auth.selector';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Logo from './logo-4.png';
import Background from './background.png';
import Ecofoni from './ecofoni.png';
import MextLight from './mext_light.png';
import Loading from '../../components/Loading/Loading';
import { Grid } from '@mui/material';
import { getAuthLoginLoading } from '../../../redux/selectors/auth/login.selector';

type ILoginProps = {
    //onSubmit: (state: boolean) => void;
};

const Wrapper = styled('div')(() => ({
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${Background})`,
}));

const Login: React.FC<ILoginProps> = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'pages.login' });
    const [searchParams] = useSearchParams();
    const username = useAppSelector(getAuthUsername);
    const password = useAppSelector(getAuthPassword);
    const isLoading = useAppSelector(getAuthLoginLoading);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        let redirectUrl = '/';
        if (searchParams.get('redirectUrl') !== '/login') {
            redirectUrl = searchParams.get('redirectUrl') || '/';
        }
        event.preventDefault();
        dispatch(
            submitAuthLogin({
                onSuccess: () => {
                    navigate(redirectUrl);
                },
            })
        );
    };

    return (
        <Wrapper>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Container
                        component="main"
                        maxWidth="xs"
                        sx={{
                            padding: '30px',
                            borderRadius: '20px',
                            //backgroundColor: '#fff',
                        }}
                    >
                        <CssBaseline />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src={MextLight} height="50" style={{ objectFit: 'contain' }} />
                            <Typography
                                component="h1"
                                variant="h1"
                                sx={{
                                    color: '#308045',
                                }}
                            >
                                KARBON PLATFORMU
                            </Typography>
                            <Typography component="h1" variant="h2" sx={{ color: '#fff' }}>
                                {t('title')}
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    variant={'filled'}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label={t('username')}
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    value={username}
                                    onChange={(e) => dispatch(changeAuthUsername(e.target.value))}
                                    sx={{
                                        '& .MuiInputLabel-root': { color: '#9f9f9f' },
                                        '& .MuiInputBase-root': { color: '#9f9f9f' },
                                        '& .MuiFormLabel-root': { color: '#9f9f9f' },
                                        '& .Mui-focused': { color: '#fff' },
                                    }}
                                />
                                <TextField
                                    variant={'filled'}
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t('password')}
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) => dispatch(changeAuthPassword(e.target.value))}
                                    sx={{
                                        '& .MuiInputLabel-root': { color: '#9f9f9f' },
                                        '& .MuiInputBase-root': { color: '#9f9f9f' },
                                        '& .MuiFormLabel-root': { color: '#9f9f9f' },
                                        '& .Mui-focused': { color: '#fff' },
                                    }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color={'success'}
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {t('login')}
                                </Button>
                                {/*<Grid container>
                                    <Grid item xs>
                                        <Link
                                            to={'/reset'}
                                            style={{ textDecoration: 'none', color: '#03c9d7', textAlign: 'end' }}
                                        >
                                            {t('forgetPassword')}
                                        </Link>
                                    </Grid>
                                </Grid>*/}
                            </Box>
                        </Box>
                    </Container>
                    <div
                        style={{
                            height: '120px',
                            width: 'min-content',
                            position: 'absolute',
                            bottom: 10,
                            right: 10,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#0DA283',
                        }}
                    >
                        <img src={Ecofoni} alt={'Support'} style={{ height: 'inherit', width: 'auto' }} />
                        <div style={{ textAlign: 'center' }}>tarafından desteklenmektedir.</div>
                    </div>
                </>
            )}
        </Wrapper>
    );
};

export default Login;
