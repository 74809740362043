import { useAppSelector } from '../redux/hooks';
import { getAuthPrivileges, getAuthRoles } from '../redux/selectors/auth/auth.selector';
import { useMemo, useState } from 'react';

function usePermission(
    permissionType: string,
    permissionTypeSet: Array<string>,
    roles?: Array<string>,
    id?: number
): boolean {
    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    const authRoles = useAppSelector(getAuthRoles);
    const privileges = useAppSelector(getAuthPrivileges);

    useMemo(() => {
        if (authRoles?.some((r: any) => roles?.includes(r.title))) {
            setIsAllowed(true);
        } else {
            privileges?.map((permission: any) => {
                if (permission?.privilegedApiType === permissionType) {
                    const result = permissionTypeSet?.every((p: string) => permission?.permissionTypeSet?.includes(p));
                    setIsAllowed(result);
                }
            });
        }
    }, [authRoles, privileges]);

    return isAllowed;
}

export default usePermission;
