import { ICompanyAnalytic } from '../../../entities/company/analyticCombustion';

export const ANALYTIC = '[ANALYTIC]';

export const GET_ANALYTIC_COMPANY = `${ANALYTIC} Get Company Analtyic`;
export const SET_ANALYTIC_COMPANY = `${ANALYTIC} Set Company Analtyic`;

export const GET_ANALYTIC_FACILITY = `${ANALYTIC} Get Company Facility`;
export const SET_ANALYTIC_FACILITY = `${ANALYTIC} Set Company Facility`;

export type ISubmit = {
    onSuccess: () => void;
};

export const getAnalyticCompany = () => ({
    type: GET_ANALYTIC_COMPANY,
});

export const setAnalyticCompany = (value: ICompanyAnalytic) => ({
    type: SET_ANALYTIC_COMPANY,
    payload: value,
});

export const getAnalyticFacility = () => ({
    type: GET_ANALYTIC_FACILITY,
});

export const setAnalyticFacility = (value: any) => ({
    type: SET_ANALYTIC_FACILITY,
    payload: value,
});
