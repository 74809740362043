import { AnyAction } from 'redux';
import {
    SET_CREATE_USER_BACKOFFICE,
    SET_CREATE_USER_COMPANY,
    SET_CREATE_USER_COMPANY_BACKOFFICE,
    SET_CREATE_USER_FACILITY,
    SET_CREATE_USER_ORGANIZATION,
    SET_CREATE_USER_ORGANIZATION_BACKOFFICE,
} from '../../actions/system/createUser.action';
import {
    CHANGE_USER_CONFIRM_PASSWORD,
    CHANGE_USER_EMAIL,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_USERNAME,
    CLEAR_USER_CREATE_USER,
    CLEAR_USER_INFO,
    GET_USER_BY_ID,
    GET_USER_CONNECTION_ROLES,
    GET_USER_LIST,
    SELECT_USER_CONNECTION_ROLE,
    SELECT_USER_PRIVILEGE_GROUP,
    SELECT_USER_ROLE,
    SET_USER_BY_ID,
    SET_USER_CONNECTION_ROLES,
    SET_USER_LIST,
    SET_USER_LIST_BACKOFFICE,
    SET_USER_PRIVILEGE_GROUPS,
    SET_USER_ROLES,
} from '../../actions/system/user.action';
import {
    CHANGE_PRIVILEGE_GROUP_DESCRIPTION,
    CHANGE_PRIVILEGE_GROUP_NAME,
    CLEAR_PRIVILEGE_GROUP_BY_ID,
    CLEAR_PRIVILEGE_GROUP_INPUTS,
    GET_PRIVILEGE_GROUP_BY_ID,
    SET_PRIVILEGE_GROUP_BY_ID,
    SET_PRIVILEGE_GROUP_NAMES,
} from '../../actions/system/privilegeGroup.action';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';

const initialState = {
    userList: {
        data: [],
        isLoading: true,
    },
    userInfo: {
        data: {},
        isLoading: true,
    },
    privilegeGroupList: {
        data: [],
        isLoading: true,
    },
    privilegeGroupInfo: {
        data: {},
        isLoading: true,
    },
    createPrivilegeGroup: {
        name: '',
        description: '',
    },
    userListBackoffice: [],
    createUser: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        userRoles: [],
        connectionRoles: [],
        privilegeGroups: [],
        selectedUserRole: '',
        selectedConnectionRole: '',
        selectedPrivilegeGroup: '',
    },
};

export const systemReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_AUTH_LOGOUT:
            return initialState;
        case SET_USER_LIST_BACKOFFICE:
            return {
                ...state,
                userListBackoffice: action.payload,
            };
        case GET_USER_LIST:
            return {
                ...state,
                userList: {
                    data: [],
                    isLoading: true,
                },
            };
        case SET_USER_LIST:
            return {
                ...state,
                userList: {
                    ...state.userList,
                    data: action.payload,
                    isLoading: false,
                },
            };
        case GET_USER_BY_ID:
            return {
                ...state,
                userInfo: {
                    data: {},
                    isLoading: true,
                },
                createUser: {},
            };
        case SET_USER_BY_ID:
            const data = action.payload;
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    data,
                    isLoading: false,
                },
                createUser: {
                    username: data.user.username,
                    email: data.user.email,
                    password: '',
                    selectedUserRole: data.user.roles[0]?.name,
                    selectedConnectionRole: data.company?.companyName,
                    selectedPrivilegeGroup: '',
                },
            };
        case CLEAR_USER_INFO:
            return {
                ...state,
                userInfo: {
                    data: {},
                    isLoading: true,
                },
                createUser: {
                    username: '',
                    email: '',
                    userRoles: [],
                    connectionRoles: [],
                    privilegeGroups: [],
                    selectedUserRole: '',
                    selectedConnectionRole: '',
                    selectedPrivilegeGroup: '',
                },
            };
        case SET_PRIVILEGE_GROUP_NAMES:
            return {
                ...state,
                privilegeGroupList: {
                    ...state.privilegeGroupList,
                    data: action.payload,
                    isLoading: false,
                },
            };
        case GET_PRIVILEGE_GROUP_BY_ID:
            return {
                ...state,
                privilegeGroupInfo: {
                    data: {},
                    isLoading: true,
                },
            };
        case SET_PRIVILEGE_GROUP_BY_ID: {
            const data = action.payload;
            return {
                ...state,
                privilegeGroupInfo: {
                    ...state.privilegeGroupInfo,
                    data: action.payload,
                    isLoading: false,
                },
                createPrivilegeGroup: {
                    name: data?.name,
                    description: data?.description,
                },
            };
        }
        case CLEAR_PRIVILEGE_GROUP_BY_ID:
            return {
                ...state,
                privilegeGroupInfo: {
                    data: {},
                    isLoading: true,
                },
            };
        case CHANGE_USER_USERNAME: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    username: action.payload,
                },
            };
        }
        case CHANGE_USER_EMAIL: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    email: action.payload,
                },
            };
        }
        case CHANGE_USER_PASSWORD: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    password: action.payload,
                },
            };
        }
        case CHANGE_USER_CONFIRM_PASSWORD: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    confirmPassword: action.payload,
                },
            };
        }
        case SET_USER_ROLES: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    userRoles: action.payload,
                },
            };
        }
        case SELECT_USER_ROLE: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    selectedUserRole: action.payload,
                    selectedConnectionRole: '',
                },
            };
        }
        case GET_USER_CONNECTION_ROLES: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    //selectedConnectionRole: '',
                    connectionRoles: [],
                },
            };
        }
        case SET_USER_CONNECTION_ROLES: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    connectionRoles: action.payload,
                },
            };
        }
        case SELECT_USER_CONNECTION_ROLE: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    selectedConnectionRole: action.payload,
                },
            };
        }
        case SET_USER_PRIVILEGE_GROUPS: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    privilegeGroups: action.payload,
                },
            };
        }
        case SELECT_USER_PRIVILEGE_GROUP: {
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    selectedPrivilegeGroup: action.payload,
                },
            };
        }
        case CLEAR_USER_CREATE_USER:
            return {
                ...state,
                userInfo: {
                    data: {},
                    isLoading: true,
                },
                createUser: {
                    username: '',
                    email: '',
                    userRoles: [],
                    connectionRoles: [],
                    privilegeGroups: [],
                    selectedUserRole: '',
                    selectedConnectionRole: '',
                    selectedPrivilegeGroup: '',
                },
            };
        case SET_CREATE_USER_ORGANIZATION:
            return state;
        case SET_CREATE_USER_COMPANY:
            return state;
        case SET_CREATE_USER_FACILITY:
            return state;
        case SET_CREATE_USER_ORGANIZATION_BACKOFFICE:
            return state;
        case SET_CREATE_USER_COMPANY_BACKOFFICE:
            return state;
        case SET_CREATE_USER_BACKOFFICE:
            return state;
        case CHANGE_PRIVILEGE_GROUP_NAME:
            return {
                ...state,
                createPrivilegeGroup: {
                    ...state.createPrivilegeGroup,
                    name: action.payload,
                },
            };
        case CHANGE_PRIVILEGE_GROUP_DESCRIPTION:
            return {
                ...state,
                createPrivilegeGroup: {
                    ...state.createPrivilegeGroup,
                    description: action.payload,
                },
            };
        case CLEAR_PRIVILEGE_GROUP_INPUTS:
            return {
                ...state,
                createPrivilegeGroup: {
                    name: '',
                    description: '',
                },
            };
        default:
            return state;
    }
};
