import { AnyAction } from 'redux';
import {
    CHANGE_FUGITIVE_CONSUMPTION_AMOUNT,
    CHANGE_FUGITIVE_DESCRIPTION,
    CHANGE_FUGITIVE_DISTANCE,
    CHANGE_FUGITIVE_EQUIVALENT_FACTOR,
    CHANGE_FUGITIVE_MAIN_FACILITY,
    CHANGE_FUGITIVE_SUB_FACILITY,
    CHANGE_FUGITIVE_TAGS,
    CHANGE_FUGITIVE_WEIGHT,
    CHANGE_FUGITIVE_YEAR,
    CLEAR_FUGITIVE_CONSUMPTION_AMOUNT,
    CLEAR_FUGITIVE_INPUT,
    SELECT_FUGITIVE_ACTIVITY,
    SELECT_FUGITIVE_ACTIVITY_DATA,
    SELECT_FUGITIVE_ACTIVITY_TYPE,
    SELECT_FUGITIVE_DATABASE,
    SELECT_FUGITIVE_FUEL_TYPE,
    SELECT_FUGITIVE_GAS_GROUP,
    SELECT_FUGITIVE_LADEN_PERCENTAGE,
    SELECT_FUGITIVE_SIZE,
    SELECT_FUGITIVE_TIER,
    SELECT_FUGITIVE_UNIT,
    SET_FUGITIVE_ACTIVITIES,
    SET_FUGITIVE_ACTIVITY_TYPES,
    SET_FUGITIVE_DATABASES,
    SET_FUGITIVE_FUEL_TYPES,
    SET_FUGITIVE_GAS_GROUP,
    SET_FUGITIVE_LADEN_PERCENTAGES,
    SET_FUGITIVE_RESULT,
    SET_FUGITIVE_SIZES,
    SET_FUGITIVE_TIERS,
    SET_FUGITIVE_UNITS,
} from '../../actions/emission/fugitive.action';
import { CLEAR_BASE_INPUTS } from '../../actions/emission/base.action';
import { clearInput } from './base.reducer';

const initialState = {
    mainFacility: '',
    subFacility: '',
    tiers: [],
    tier: '',
    sectorList: [],
    sector: '',
    emissionSource: '',
    sourceList: [],
    source: '',
    databases: [],
    database: '',
    activityList: [],
    activity: '',
    activityTypeList: [],
    activityType: '',
    gasGroupList: [],
    gasGroup: '',
    activityData: '',
    unitList: [],
    unit: '',
    weight: '',
    distance: '',
    tags: '',
    year: '',
    equivalentFactor: '',
    consumptions: {
        0: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        1: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        2: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        3: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        4: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        5: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        6: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        7: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        8: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        9: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        10: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        11: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
        12: { numberOfDevices: null, gasCapacity: null, gasAmount: null },
    },
    calculationDate: '',
    description: '',
    result: [],
};

export const emissionFugitiveReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case CLEAR_FUGITIVE_INPUT:
            const data = clearInput(action, initialState);
            return {
                ...state,
                ...data,
            };
        case CLEAR_BASE_INPUTS:
            return {
                ...initialState,
            };
        case CHANGE_FUGITIVE_MAIN_FACILITY:
            return {
                ...state,
                mainFacility: action.payload,
            };
        case CHANGE_FUGITIVE_SUB_FACILITY:
            return {
                ...state,
                subFacility: action.payload,
            };
        case SET_FUGITIVE_TIERS:
            return {
                ...state,
                tiers: action.payload,
            };
        case SELECT_FUGITIVE_TIER:
            return {
                ...state,
                tier: action.payload,
            };
        case SET_FUGITIVE_DATABASES:
            return {
                ...state,
                databases: action.payload,
            };
        case SELECT_FUGITIVE_DATABASE:
            return {
                ...state,
                database: action.payload,
            };
        case CHANGE_FUGITIVE_EQUIVALENT_FACTOR:
            return {
                ...state,
                equivalentFactor: action.payload,
            };

        case CHANGE_FUGITIVE_TAGS:
            return {
                ...state,
                tags: action.payload,
            };
        case SET_FUGITIVE_ACTIVITIES:
            return {
                ...state,
                activityList: action.payload,
            };
        case SELECT_FUGITIVE_ACTIVITY:
            return {
                ...state,
                activity: action.payload,
            };
        case SET_FUGITIVE_FUEL_TYPES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_FUGITIVE_FUEL_TYPE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_FUGITIVE_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypeList: action.payload,
            };
        case SELECT_FUGITIVE_ACTIVITY_TYPE:
            return {
                ...state,
                activityType: action.payload,
            };
        case SET_FUGITIVE_GAS_GROUP: {
            const data = action.payload;
            const tmp: Array<string> = [];
            const unique = [];

            for (let i = 0; i < data.length; i++) {
                if (!tmp.includes(data[i]?.key)) {
                    console.log(data[i]);
                    unique.push(data[i]);
                    tmp.push(data[i]?.key);
                }
            }
            return {
                ...state,
                gasGroupList: unique,
            };
        }

        case SELECT_FUGITIVE_GAS_GROUP:
            return {
                ...state,
                gasGroup: action.payload,
                //activityTypeList: state.activityTypeList.filter((item) => item?.gasgroups === action.payload.gasgroups),
            };
        case SET_FUGITIVE_UNITS:
            return {
                ...state,
                unitList: action.payload,
            };
        case SELECT_FUGITIVE_UNIT:
            return {
                ...state,
                unit: action.payload,
            };
        case CHANGE_FUGITIVE_WEIGHT:
            return {
                ...state,
                weight: action.payload,
            };
        case CHANGE_FUGITIVE_DISTANCE:
            return {
                ...state,
                distance: action.payload,
            };
        case SET_FUGITIVE_LADEN_PERCENTAGES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_FUGITIVE_LADEN_PERCENTAGE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_FUGITIVE_SIZES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_FUGITIVE_SIZE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_FUGITIVE_RESULT:
            return {
                ...state,
                result: action.payload,
            };
        case SELECT_FUGITIVE_ACTIVITY_DATA:
            return {
                ...state,
                activityData: action.payload,
            };
        case CHANGE_FUGITIVE_YEAR:
            return {
                ...state,
                year: action.payload,
            };
        case CHANGE_FUGITIVE_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };

        case CHANGE_FUGITIVE_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: {
                    ...state.consumptions,
                    [action.payload.month]: {
                        ...state.consumptions[action.payload.month as keyof typeof state.consumptions],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case CLEAR_FUGITIVE_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: initialState.consumptions,
            };
        default:
            return state;
    }
};
