export const CREATE_USER = '[CREATE_USER]';

export const CREATE_USER_ORGANIZATION_BACKOFFICE = `${CREATE_USER} Create Backoffice Organization`;
export const SET_CREATE_USER_ORGANIZATION_BACKOFFICE = `${CREATE_USER} Set Create Backoffice Organization`;

export const CREATE_USER_COMPANY_BACKOFFICE = `${CREATE_USER} Create Backoffice Company`;
export const SET_CREATE_USER_COMPANY_BACKOFFICE = `${CREATE_USER} Set Create Backoffice Company`;

export const CREATE_USER_BACKOFFICE = `${CREATE_USER} Create Backoffice`;
export const SET_CREATE_USER_BACKOFFICE = `${CREATE_USER} Set Create Backoffice`;

export const CREATE_USER_ORGANIZATION = `${CREATE_USER} Create Organization`;
export const SET_CREATE_USER_ORGANIZATION = `${CREATE_USER} Set Create Organization`;

export const CREATE_USER_COMPANY = `${CREATE_USER} Create Company`;
export const SET_CREATE_USER_COMPANY = `${CREATE_USER} Set Create Company`;

export const CREATE_USER_FACILITY = `${CREATE_USER} Create Facility`;
export const SET_CREATE_USER_FACILITY = `${CREATE_USER} Set Create Facility`;

export type ISubmit = {
    onSuccess: () => void;
};

// Organization
export const createUserOrganizationBackoffice = () => ({
    type: CREATE_USER_ORGANIZATION_BACKOFFICE,
});
export const setCreateUserOrganizationBackoffice = () => ({
    type: SET_CREATE_USER_ORGANIZATION_BACKOFFICE,
});
export const createUserOrganization = ({ onSuccess }: ISubmit) => ({
    type: CREATE_USER_ORGANIZATION,
    meta: {
        onSuccess,
    },
});
export const setCreateUserOrganization = () => ({
    type: SET_CREATE_USER_ORGANIZATION,
});

// Company
export const createUserCompanyBackoffice = () => ({
    type: CREATE_USER_COMPANY_BACKOFFICE,
});
export const setCreateUserCompanyBackoffice = () => ({
    type: SET_CREATE_USER_COMPANY_BACKOFFICE,
});
export const createUserCompany = ({ onSuccess }: ISubmit) => ({
    type: CREATE_USER_COMPANY,
    meta: {
        onSuccess,
    },
});
export const setCreateUserCompany = () => ({
    type: SET_CREATE_USER_COMPANY,
});

// Facility
export const createUserBackoffice = () => ({
    type: CREATE_USER_BACKOFFICE,
});
export const setCreateUserBackoffice = () => ({
    type: SET_CREATE_USER_BACKOFFICE,
});
export const createUserFacility = ({ onSuccess }: ISubmit) => ({
    type: CREATE_USER_FACILITY,
    meta: {
        onSuccess,
    },
});
export const setCreateUserFacility = () => ({
    type: SET_CREATE_USER_FACILITY,
});
