import { Middleware } from 'redux';
import { IUpdatedEmission } from '../../../../entities/emissionTypes/updatedCombustion';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_INVENTORY_EMISSION_BY_ID,
    setInventoryEmissionById,
    UPDATE_EMISSION,
    UPDATE_EMISSION_FUGITIVE,
} from '../../../actions/emission/update.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

const updateFormPaths = {
    1: '/stationaryEmission', // Sabit Yanma
    2: '/mobileEmission', // Mobil Yanma
    3: '/processEmission', // Process Emisyonları
    4: '/fugitiveEmission', // Sızıntı Emisyonları
    5: '/electricityEmission', // Elektrik Tüketimleri
    6: '/steamHeatEmission', // Isı / Buhar Tüketimleri
    7: '/upstreamTransportationEmission', // Yukarı Yönlü Taşımacılık
    8: '/downstreamTransportationEmission', // Aşağı Yönlü Taşımacılık
    9: '/employeeCommutingTransportationEmission', // Çalışan İşe Gidiş Geliş
    10: '/visitorAndCustomerTransportationEmission', // Ziyaretçi Tesise Ulaşım
    11: '/businessTravelEmission', // İş Seyahatleri
    12: '/purchasedGoodsEmission', // Satın Alınan Ürün
    13: '/purchasedCapitalGoodsEmission', // Sermaye Mallar
    14: '/wasteDisposalEmission', // Katı ve Sıvı Atık Bertarafı
    15: '/serviceUsageEmission', // Hizmet Kullanımı
    16: '', // Ürün Kullanım Aşaması
    17: '/treatmentSoldProductEmission', // Ürün kullanım Ömrü Sonrası
    18: '', // Diğer
    19: '/landUseChangeAndForestryEmission', // Lulucf
    20: '/biomassEmission', // Biyokütle Emisyonları
    21: '/upstreamLeadAssetsEmission', // Yukarı Yönlü Kiralanan Varlıklar
    22: '/downstreamLeadAssetsEmission', // Aşağı Yönlü Kiralanan Varlıklar
    100: '', // Varlık Kullanımı
    101: '', // Kiralanan Varlıklar
    102: '', // Yatırım Emisyonları
};

export const updateEmissionMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case UPDATE_EMISSION: {
                const inventory = state?.emission?.update?.inventoryDetail;
                const equivalent = inventory?.emissionCalculationArgumentSet?.filter(
                    (item: any) => item?.identifier === 'CO2E'
                )[0];

                const consumptionArray = [];
                const consumption = state?.emission?.update?.consumptionsGeneral;
                consumptionArray.push(consumption);

                const body: IUpdatedEmission = {
                    id: action.payload || state.emission.update.id,
                    organizationId: inventory?.organzationId || inventory?.organizationId || null,
                    companyId: inventory?.companyId || null,
                    facilityId: inventory?.facilityId || null,
                    emissionFactorDatabaseId: inventory?.emissionFactorDatabase?.id || null,
                    emissionSourceId: inventory?.emissionSource?.id || null,
                    emissionActivityId: inventory?.emissionActivity?.id || null,
                    emissionActivityTypeId: inventory?.emissionActivityType?.id || null,
                    tags: inventory?.tags || null,
                    description: inventory?.description || null,
                    activityData: 1, //inventory?.activityData?.id || null,
                    tierId: inventory?.tier?.id || null,
                    equivalentTags: inventory?.equivalentTags || '',
                    equivalentFactor: equivalent?.value || null,
                    unitId: inventory?.emissionUnit?.id || null,
                    consumptionList: consumptionArray,
                    emissionCategoryId: inventory?.emissionCategory?.id || null,
                };

                next(
                    apiRequest({
                        method: 'PUT',
                        url: updateFormPaths[inventory?.emissionCategory?.id as keyof typeof updateFormPaths],
                        feature: UPDATE_EMISSION,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }
            case `${UPDATE_EMISSION} ${API_SUCCESS}`: {
                const { message } = action.payload;

                dispatch(
                    createNotification({
                        type: 'success',
                        message: message || i18next.t('notification.successUpdated'),
                        time: 3000,
                    })
                );

                action.meta.onSuccess();
                break;
            }
            case `${UPDATE_EMISSION} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }

            case UPDATE_EMISSION_FUGITIVE: {
                const body: any = {
                    id: state.emission.update.id,
                    consumption: state.emission.update.consumption,
                    equivalentFactor: state.emission.update.equivalentFactor,
                    tags: state.emission.update.tags,
                    description: state.emission.update.description,
                    equivalentTags: state.emission.update.equivalentTags,
                    activityData: state.emission.update.activityData,
                };

                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/fugitiveEmission',
                        feature: UPDATE_EMISSION_FUGITIVE,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${UPDATE_EMISSION_FUGITIVE} ${API_SUCCESS}`: {
                window.location.reload();
                break;
            }

            case GET_INVENTORY_EMISSION_BY_ID: {
                interface IInventoryBody {
                    id: number;
                    detailed: boolean;
                }

                const body: IInventoryBody = {
                    id: action.payload,
                    detailed: true,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporateEmission',
                        feature: GET_INVENTORY_EMISSION_BY_ID,
                        body,
                    })
                );
                break;
            }
            case `${GET_INVENTORY_EMISSION_BY_ID} ${API_SUCCESS}`: {
                const data = action.payload?.corporateEmissionContentDto;
                next(setInventoryEmissionById(data));
                break;
            }

            case `${GET_INVENTORY_EMISSION_BY_ID} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }
        }
    };
