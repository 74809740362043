import { AnyAction } from 'redux';
import { SET_ORGANIZATION_DASHBOARD_DATA } from '../../actions/company/organization.action';

const initialState = {
    dashboard: {
        allTimeTotalEmission: 0,
        total: 0,
        monthlyTotal: 0,
        cumulative: {
            data: [],
            labels: [],
        },
        monthly: {
            data: [],
            labels: [],
        },
        category: {
            data: [] as Array<number>,
            labels: [] as Array<string>,
        },
        scopes: {
            data: [],
            labels: [],
        },
    },
};

export const organizationReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case SET_ORGANIZATION_DASHBOARD_DATA:
            return {
                ...state,
                dashboard: action.payload,
            };
        default:
            return state;
    }
};
