import { AnyAction } from 'redux';
import {
    CHANGE_PROCESS_CONSUMPTION_AMOUNT,
    CHANGE_PROCESS_DESCRIPTION,
    CHANGE_PROCESS_DISTANCE,
    CHANGE_PROCESS_EQUIVALENT_FACTOR,
    CHANGE_PROCESS_MAIN_FACILITY,
    CHANGE_PROCESS_SUB_FACILITY,
    CHANGE_PROCESS_WEIGHT,
    CHANGE_PROCESS_YEAR,
    CLEAR_PROCESS_CONSUMPTION_AMOUNT,
    CLEAR_PROCESS_INPUT,
    SELECT_PROCESS_ACTIVITY,
    SELECT_PROCESS_ACTIVITY_DATA,
    SELECT_PROCESS_ACTIVITY_TYPE,
    SELECT_PROCESS_DATABASE,
    SELECT_PROCESS_FUEL_TYPE,
    SELECT_PROCESS_LADEN_PERCENTAGE,
    SELECT_PROCESS_SIZE,
    SELECT_PROCESS_TIER,
    SELECT_PROCESS_UNIT,
    SET_PROCESS_ACTIVITIES,
    SET_PROCESS_ACTIVITY_TYPES,
    SET_PROCESS_ADDITIONAL_CONSTANTS,
    SET_PROCESS_DATABASES,
    SET_PROCESS_FUEL_TYPES,
    SET_PROCESS_LADEN_PERCENTAGES,
    SET_PROCESS_RESULT,
    SET_PROCESS_SIZES,
    SET_PROCESS_TIERS,
    SET_PROCESS_UNITS,
} from '../../actions/emission/process.action';
import { CLEAR_BASE_INPUTS } from '../../actions/emission/base.action';
import { clearInput } from './base.reducer';
import { IProcessAdditional } from '../../../entities/emissionTypes/processCombustion';

const initialState = {
    mainFacility: '',
    subFacility: '',
    tiers: [],
    tier: '',
    sectorList: [],
    sector: '',
    emissionSource: '',
    sourceList: [],
    source: '',
    databases: [],
    database: '',
    activityList: [],
    activity: '',
    activityTypeList: [],
    activityType: '',
    activityData: '',
    unitList: [],
    unit: '',
    weight: '',
    distance: '',
    year: '',
    equivalentFactor: '',
    consumptions: {
        0: { tonne: null, km: null },
        1: { tonne: null, km: null },
        2: { tonne: null, km: null },
        3: { tonne: null, km: null },
        4: { tonne: null, km: null },
        5: { tonne: null, km: null },
        6: { tonne: null, km: null },
        7: { tonne: null, km: null },
        8: { tonne: null, km: null },
        9: { tonne: null, km: null },
        10: { tonne: null, km: null },
        11: { tonne: null, km: null },
        12: { tonne: null, km: null },
    },
    calculationDate: '',
    description: '',
    result: [],
    equivalentFactorUnit: '',
};

export const emissionProcessReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_PROCESS_ADDITIONAL_CONSTANTS:
            let unit = '';
            action.payload.map((i: IProcessAdditional) => {
                if (i.identifier === 'CO2E') unit = i.unit || '';
            });
            return {
                ...state,
                equivalentFactorUnit: unit,
            };
        case CLEAR_PROCESS_INPUT:
            const data = clearInput(action, initialState);
            return {
                ...state,
                ...data,
            };
        case CLEAR_BASE_INPUTS:
            return {
                ...initialState,
            };
        case CHANGE_PROCESS_MAIN_FACILITY:
            return {
                ...state,
                mainFacility: action.payload,
            };
        case CHANGE_PROCESS_SUB_FACILITY:
            return {
                ...state,
                subFacility: action.payload,
            };
        case SET_PROCESS_TIERS:
            return {
                ...state,
                tiers: action.payload,
            };
        case SELECT_PROCESS_TIER:
            return {
                ...state,
                tier: action.payload,
            };
        case SET_PROCESS_DATABASES:
            return {
                ...state,
                databases: action.payload,
            };
        case SELECT_PROCESS_DATABASE:
            return {
                ...state,
                database: action.payload,
            };
        case CHANGE_PROCESS_EQUIVALENT_FACTOR:
            return {
                ...state,
                equivalentFactor: action.payload,
            };
        case SET_PROCESS_ACTIVITIES:
            return {
                ...state,
                activityList: action.payload,
            };
        case SELECT_PROCESS_ACTIVITY:
            return {
                ...state,
                activity: action.payload,
            };
        case SET_PROCESS_FUEL_TYPES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_PROCESS_FUEL_TYPE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_PROCESS_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypeList: action.payload,
            };
        case SELECT_PROCESS_ACTIVITY_TYPE:
            return {
                ...state,
                activityType: action.payload,
            };
        case SET_PROCESS_UNITS:
            return {
                ...state,
                unitList: action.payload,
            };
        case SELECT_PROCESS_UNIT:
            return {
                ...state,
                unit: action.payload,
            };
        case CHANGE_PROCESS_WEIGHT:
            return {
                ...state,
                weight: action.payload,
            };
        case CHANGE_PROCESS_DISTANCE:
            return {
                ...state,
                distance: action.payload,
            };
        case SET_PROCESS_LADEN_PERCENTAGES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_PROCESS_LADEN_PERCENTAGE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_PROCESS_SIZES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_PROCESS_SIZE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_PROCESS_RESULT:
            return {
                ...state,
                result: action.payload,
            };
        case SELECT_PROCESS_ACTIVITY_DATA:
            return {
                ...state,
                activityData: action.payload,
            };
        case CHANGE_PROCESS_YEAR:
            return {
                ...state,
                year: action.payload,
            };
        case CHANGE_PROCESS_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };

        case CHANGE_PROCESS_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: {
                    ...state.consumptions,
                    [action.payload.month]: {
                        ...state.consumptions[action.payload.month as keyof typeof state.consumptions],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case CLEAR_PROCESS_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: initialState.consumptions,
            };
        default:
            return state;
    }
};
