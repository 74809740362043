import * as React from 'react';
import { useContext, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import TranslateIcon from '@mui/icons-material/Translate';
import { LayoutContext } from '../../layouts/LayoutContext';
import { Button, ButtonGroup, Divider, Grid, useThemeProps } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { setAuthLogout } from '../../../redux/actions/auth/auth.action';
import { useTranslation } from 'react-i18next';
import Input from '../Form/Input';
import { getFacilityGlobalList, selectFacility } from '../../../redux/actions/company/facility.action';
import { getFacilitiesGlobal, getSelectedFacilityGlobal } from '../../../redux/selectors/company/facility.selector';
import { clearBaseInputs } from '../../../redux/actions/emission/base.action';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import usePermission from '../../../hooks/usePermission';
import { getAuthLevel } from '../../../redux/selectors/auth/auth.selector';
import { PermissionTypes } from '../../../data/authorizations/permissionType';
import { PermissionTypeSetsGroup } from '../../../data/authorizations/permissionTypeSetGroup';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { getThemeMode } from '../../../redux/selectors/ui/ui.selector';
import { changeThemeMode } from '../../../redux/actions/ui.action';
import { useTheme } from '@mui/material/styles';

const Header = () => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'topbar' });
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const context = useContext(LayoutContext);
    const userLevel = useAppSelector(getAuthLevel);
    const themeMode = useAppSelector(getThemeMode);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [languageEl, setLanguageEl] = React.useState<null | HTMLElement>(null);
    const [notificationEl, setNotificationEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

    const facilities = useAppSelector(getFacilitiesGlobal);
    const selectedFacility = useAppSelector(getSelectedFacilityGlobal);
    useEffect(() => {
        dispatch(getFacilityGlobalList(userLevel, i18n?.language));
    }, []);

    useEffect(() => {
        if (userLevel == 'FACILITY' && facilities.length > 1) {
            dispatch(selectFacility(facilities[1]));
        }
    }, [facilities]);

    const handleThemeChange = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(changeThemeMode());
    };

    const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setLanguageEl(event.currentTarget);
    };

    const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setNotificationEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setLanguageEl(null);
        handleMobileMenuClose();
    };

    const handleNotificationMenuClose = () => {
        setNotificationEl(null);
        handleMobileMenuClose();
    };

    const isMenuOpen = Boolean(anchorEl);
    const isLanguageMenuOpen = Boolean(languageEl);
    const isNotificationMenuOpen = Boolean(notificationEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem disabled>{t(userLevel)}</MenuItem>

            {usePermission(PermissionTypes.COMPANY_PROFILE, PermissionTypeSetsGroup.ALL, [
                'ROLE_ORGANIZATION_ADMIN',
                'ROLE_COMPANY_ADMIN',
            ]) && (
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        navigate('/profile');
                    }}
                >
                    {t('profile')}
                </MenuItem>
            )}

            {usePermission(PermissionTypes.SYSTEM, PermissionTypeSetsGroup.ALL, [
                'ROLE_ORGANIZATION_ADMIN',
                'ROLE_COMPANY_ADMIN',
            ]) && (
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        navigate('/system/users');
                    }}
                >
                    {t('system')}
                </MenuItem>
            )}

            <MenuItem
                onClick={() => {
                    handleMenuClose();
                    dispatch(setAuthLogout());
                    navigate('/');
                }}
            >
                {t('logout')}
            </MenuItem>
        </Menu>
    );

    const langMenuId = 'primary-search-language-menu';
    const renderLangMenu = (
        <Menu
            anchorEl={languageEl}
            id={langMenuId}
            keepMounted
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isLanguageMenuOpen}
            onClose={handleLanguageMenuClose}
        >
            <MenuItem
                onClick={() => {
                    handleLanguageMenuClose();
                    i18n.changeLanguage('tr');
                    window.location.reload();
                }}
            >
                {t('turkish')}
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleLanguageMenuClose();
                    i18n.changeLanguage('en');
                    window.location.reload();
                }}
            >
                {t('english')}
            </MenuItem>
        </Menu>
    );

    const notificationMenuId = 'primary-search-notification-menu';
    const renderNotificationMenu = (
        <Menu
            anchorEl={notificationEl}
            id={notificationMenuId}
            keepMounted
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isNotificationMenuOpen}
            onClose={handleNotificationMenuClose}
        >
            <MenuItem>{t('noNotification')}</MenuItem>
        </Menu>
    );

    const themeId = 'primary-search-theme';

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleNotificationMenuOpen}>
                <IconButton
                    onClick={handleNotificationMenuOpen}
                    aria-controls={notificationMenuId}
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={0} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>

            <MenuItem onClick={handleLanguageMenuOpen}>
                <IconButton
                    size="large"
                    color="inherit"
                    aria-controls={langMenuId}
                    aria-haspopup="true"
                    onClick={handleLanguageMenuOpen}
                >
                    <Badge badgeContent={i18n.language.substring(0, 2).toUpperCase()} color={'primary'}>
                        <TranslateIcon />
                    </Badge>
                </IconButton>
                <p>Dil Seçimi</p>
            </MenuItem>
            {/*
            <MenuItem onClick={handleThemeChange}>
                <IconButton
                    size="large"
                    color="inherit"
                    aria-controls={themeId}
                    aria-haspopup="true"
                    onClick={handleThemeChange}
                >
                    {themeMode === 'light' ? <LightModeIcon /> : <DarkModeIcon />}
                </IconButton>
                <p>{themeMode === 'light' ? 'Light' : 'Dark'} Mode</p>
            </MenuItem>
        */}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <AppBar
                style={{
                    position: 'fixed',
                    width: `calc(100% - 113px)`, // sidebar + padding 65 + 24 + 24
                    flexGrow: 1,
                    margin: '10px 24px 10px',
                    background: theme.palette.background.paper,
                    color: 'rgb(119, 126, 137)',
                    borderRadius: '15px',
                    boxShadow: theme.shadows[3],
                }}
            >
                <Toolbar>
                    <Grid
                        container
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Grid item xs={4}>
                            <Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
                                <ButtonGroup>
                                    <Button
                                        variant={'text'}
                                        color={'success'}
                                        onClick={() => {
                                            navigate(-1);
                                            dispatch(clearBaseInputs());
                                        }}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </Button>
                                    <Button
                                        variant={'text'}
                                        color={'success'}
                                        onClick={() => {
                                            navigate(1);
                                            dispatch(clearBaseInputs());
                                        }}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </Button>
                                </ButtonGroup>
                                <Typography
                                    variant={'h4'}
                                    ml={1}
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        color: theme.palette.text.secondary,
                                    }}
                                >
                                    {context.title}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {context.sectionFacilities && (
                                <Input
                                    id={'facilities'}
                                    options={facilities}
                                    title={''}
                                    type={'select-facility'}
                                    size={context.sectionAddButton ? 8 : 8}
                                    value={selectedFacility}
                                    onChange={(e) => dispatch(selectFacility(e))}
                                    isSmall={true}
                                    isDisabled={userLevel == 'FACILITY'}
                                />
                            )}
                            {context.sectionAddButton && (
                                <Button
                                    component={Link}
                                    to={'/inventory/create/category'}
                                    variant={'contained'}
                                    color={'success'}
                                    onClick={() => dispatch(clearBaseInputs())}
                                    sx={{
                                        pl: 1,
                                        pr: 1,
                                        marginLeft: '10px',
                                        height: '38.25px',
                                        width: '114px',
                                        lineHeight: '16px',
                                    }}
                                >
                                    {t('addInventory')}
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <div
                                    style={{
                                        maxHeight: '64px',
                                        display: 'flex',
                                        justifyContent: 'end',
                                        alignItems: 'center',
                                        margin: 'auto',
                                        marginRight: '15px',
                                    }}
                                >
                                    {context.sectionRight}
                                </div>
                                {/*
                                    <IconButton
                                        size="large"
                                        color="inherit"
                                        aria-controls={themeId}
                                        aria-haspopup="true"
                                        onClick={handleThemeChange}
                                    >
                                        {themeMode === 'light' ? <LightModeIcon /> : <DarkModeIcon />}
                                    </IconButton>
                                */}
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    aria-controls={langMenuId}
                                    aria-haspopup="true"
                                    onClick={handleLanguageMenuOpen}
                                >
                                    <Badge badgeContent={i18n.language.substring(0, 2).toUpperCase()} color={'info'}>
                                        <TranslateIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    onClick={handleNotificationMenuOpen}
                                    aria-controls={notificationMenuId}
                                    size="large"
                                    aria-label="show 17 new notifications"
                                    color="inherit"
                                >
                                    <Badge>
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                    sx={{ marginLeft: '5px', marginRight: '5px' }}
                                />
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderLangMenu}
            {renderNotificationMenu}
            {renderMenu}
        </>
    );
};

export default Header;
