import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_AUTH_USER_INFO,
    getAuthUserInfo,
    setAuthResult,
    setAuthUserInfo,
    setLoading,
    SUBMIT_AUTH_LOGIN,
} from '../../../actions/auth/auth.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const authenticationMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            // region AUTH
            case SUBMIT_AUTH_LOGIN: {
                interface IBody {
                    username: string;
                    password: string;
                }

                const body: IBody = {
                    username: state.auth.username,
                    password: state.auth.password,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/login',
                        feature: SUBMIT_AUTH_LOGIN,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }

            case `${SUBMIT_AUTH_LOGIN} ${API_SUCCESS}`: {
                const data = action.payload.loginInfo;
                const authData = { isLogin: true, user: data };
                next(setAuthResult(authData));
                dispatch(
                    getAuthUserInfo(() => {
                        action.meta.onSuccess();
                        dispatch(
                            createNotification({
                                type: 'success',
                                message: i18next.t('notification.welcome'),
                                time: 3000,
                            })
                        );
                    })
                );
                break;
            }

            case `${SUBMIT_AUTH_LOGIN} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(setLoading());
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message || data.error || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_AUTH_USER_INFO: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'auth/userInfo',
                        feature: GET_AUTH_USER_INFO,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }

            case `${GET_AUTH_USER_INFO} ${API_SUCCESS}`: {
                const data = action.payload.userInfo;
                dispatch(setAuthUserInfo(data));
                dispatch(setLoading());
                action.meta.onSuccess();
                break;
            }
            case `${GET_AUTH_USER_INFO} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(setLoading());
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            // endregion AUTH
        }
    };
