import {
    IDetailedItem,
    IQuantified,
    IQuantifiedContent,
    IQuantifiedResult,
} from '../../../entities/company/quantifiedCombustion';
import { ICorporateEmission } from '../../../entities/company/facilityCombustion';

export const QUANTIFIED = '[QUANTIFIED]';

export const GET_QUANTIFIED_LIST = `${QUANTIFIED} Get Quantified`;
export const SET_QUANTIFIED_LIST = `${QUANTIFIED} Set Quantified`;
export const GET_QUANTIFIED_RESULT_LIST = `${QUANTIFIED} Get Result Quantified`;
export const SET_QUANTIFIED_RESULT_LIST = `${QUANTIFIED} Set Result Quantified`;

export const GET_QUANTIFIED_CONTENT = `${QUANTIFIED} Get Result Content`;
export const SET_QUANTIFIED_CONTENT = `${QUANTIFIED} Set Result Content`;

export const CHANGE_QUANTIFIED_ACTIVITY_DATA_AND_QUALITY_MANAGEMENT = `${QUANTIFIED} Change Activity Data and Quality`;
export const CHANGE_QUANTIFIED_CALCULATION_METHODOLOGIES = `${QUANTIFIED} Change Calculation Methodlogies`;
export const CHANGE_QUANTIFIED_CHANGES_CALCULATION_METHODOLOGY = `${QUANTIFIED} Change Changed Calculation Methodlogy`;
export const CHANGE_QUANTIFIED_REVIEW_AND_IMPROVEMENTS = `${QUANTIFIED} Change Review And Improvements`;
export const CHANGE_QUANTIFIED_GREEN_HOUSE_GAS = `${QUANTIFIED} Change Green House Gas`;
export const CHANGE_QUANTIFIED_EVALUATION_UNCERTAINTY = `${QUANTIFIED} Change Evaluation Uncertainty`;
export const CHANGE_QUANTIFIED_RECALCULATION_GREEN_HOUSE_GAS = `${QUANTIFIED} Change Recalculation Green House Gas`;
export const CHANGE_QUANTIFIED_EMISSION_REMOVALS = `${QUANTIFIED} Change Emission Removals`;
export const CHANGE_QUANTIFIED_EMISSION_REDUCTION_INCREASES = `${QUANTIFIED} Change Emission Reduction Increases`;

export const UPDATE_QUANTIFIED_FORM = `${QUANTIFIED} Update Quantified Form`;

export const GET_QUANTIFIED_DETAIL_BY_ID = `${QUANTIFIED} Get Quantified Detail By Id`;
export const SET_QUANTIFIED_DETAIL_BY_ID = `${QUANTIFIED} Set Quantified Detail By Id`;

export type ISubmit = {
    onSuccess: () => void;
};

export const getQuantifiedDetailById = (data: {
    categoryId: number;
    facilityId: number;
    source: string;
    inventoryActivityData: string;
}) => ({
    type: GET_QUANTIFIED_DETAIL_BY_ID,
    payload: data,
});

export const setQuantifiedDetailById = (list: Array<IDetailedItem>) => ({
    type: SET_QUANTIFIED_DETAIL_BY_ID,
    payload: list,
});

export const updateQuantifiedForm = ({ onSuccess }: ISubmit) => ({
    type: UPDATE_QUANTIFIED_FORM,
    meta: {
        onSuccess,
    },
});

export const getQuantifiedContent = () => ({
    type: GET_QUANTIFIED_CONTENT,
});

export const setQuantifiedContent = (list: IQuantifiedContent) => ({
    type: SET_QUANTIFIED_CONTENT,
    payload: list,
});

export const getQuantifiedList = () => ({
    type: GET_QUANTIFIED_LIST,
});

export const setQuantifiedList = (list: Array<IQuantified>) => ({
    type: SET_QUANTIFIED_LIST,
    payload: list,
});

export const getQuantifiedResultList = () => ({
    type: GET_QUANTIFIED_RESULT_LIST,
});

export const setQuantifiedResultList = (list: IQuantifiedResult) => ({
    type: SET_QUANTIFIED_RESULT_LIST,
    payload: list,
});

export const changeQuantifiedActivityDataAndQualityManagement = (value: string) => ({
    type: CHANGE_QUANTIFIED_ACTIVITY_DATA_AND_QUALITY_MANAGEMENT,
    payload: value,
});

export const changeQuantifiedChangesCalculationMethodology = (value: string) => ({
    type: CHANGE_QUANTIFIED_CHANGES_CALCULATION_METHODOLOGY,
    payload: value,
});

export const changeQuantifiedCalculationMethodologies = (value: string) => ({
    type: CHANGE_QUANTIFIED_CALCULATION_METHODOLOGIES,
    payload: value,
});

export const changeQuantifiedReviewAndImprovements = (value: string) => ({
    type: CHANGE_QUANTIFIED_REVIEW_AND_IMPROVEMENTS,
    payload: value,
});

export const changeQuantifiedGreenHouseGas = (value: string) => ({
    type: CHANGE_QUANTIFIED_GREEN_HOUSE_GAS,
    payload: value,
});

export const changeQuantifiedEvaluationUncertainty = (value: string) => ({
    type: CHANGE_QUANTIFIED_EVALUATION_UNCERTAINTY,
    payload: value,
});

export const changeQuantifiedRecalculationGreenHouseGas = (value: string) => ({
    type: CHANGE_QUANTIFIED_RECALCULATION_GREEN_HOUSE_GAS,
    payload: value,
});

export const changeQuantifiedEmissionRemovals = (value: string) => ({
    type: CHANGE_QUANTIFIED_EMISSION_REMOVALS,
    payload: value,
});

export const changeQuantifiedEmissionReductionIncreases = (value: string) => ({
    type: CHANGE_QUANTIFIED_EMISSION_REDUCTION_INCREASES,
    payload: value,
});
