import { Link, Typography } from '@mui/material';

const CopyRight = (props: any) => {
    return (
        <div
            style={{
                backgroundColor: 'inherit',
                height: '50px',
                left: '0px',
                right: '0px',
                marginBottom: '0px',
                justifyContent: 'end',
            }}
        >
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href="https://www.linkedin.com/showcase/ecofoni/">
                    Ecofoni
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </div>
    );
};

export default CopyRight;
