import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    CREATE_USER_BACKOFFICE,
    CREATE_USER_COMPANY,
    CREATE_USER_COMPANY_BACKOFFICE,
    CREATE_USER_FACILITY,
    CREATE_USER_ORGANIZATION,
    CREATE_USER_ORGANIZATION_BACKOFFICE,
} from '../../../actions/system/createUser.action';
import { createNotification } from '../../../actions/ui.action';
import { getUserList } from '../../../actions/system/user.action';
import i18next from 'i18next';

export const createUserMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case CREATE_USER_ORGANIZATION: {
                interface IBody {
                    username: string;
                    password: string;
                    email: string;
                    roles: Array<string>;
                    organizationId: number;
                }

                const body: IBody = {
                    username: state?.system?.createUser?.username,
                    password: state?.system?.createUser?.password,
                    email: state?.system?.createUser?.email,
                    roles: [state?.system?.createUser?.selectedUserRole?.key],
                    organizationId: state?.auth?.userInfo?.organization?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/createUser/organization',
                        feature: CREATE_USER_ORGANIZATION,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }
            case `${CREATE_USER_ORGANIZATION} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                const { message } = action.payload;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: message || i18next.t('notification.successCreated'),
                    })
                );
                dispatch(getUserList());
                break;
            }
            case `${CREATE_USER_ORGANIZATION} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }

            case CREATE_USER_COMPANY: {
                interface IBody {
                    username: string;
                    password: string;
                    email: string;
                    roles: Array<string>;
                    organizationId?: number;
                    companyId: number;
                }
                const body: IBody = {
                    username: state?.system?.createUser?.username,
                    password: state?.system?.createUser?.password,
                    email: state?.system?.createUser?.email,
                    roles: [state?.system?.createUser?.selectedUserRole?.key],
                    organizationId:
                        state?.auth?.userInfo?.organization?.id || state?.auth?.userInfo?.company?.organizationId,
                    companyId:
                        state?.system?.createUser?.selectedConnectionRole?.id || state?.auth?.userInfo?.company?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/createUser/company',
                        feature: CREATE_USER_COMPANY,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }
            case `${CREATE_USER_COMPANY} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                const { message } = action.payload;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: message || i18next.t('notification.successCreated'),
                    })
                );

                dispatch(getUserList());
                break;
            }
            case `${CREATE_USER_COMPANY} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }

            case CREATE_USER_FACILITY: {
                interface IBody {
                    username: string;
                    password: string;
                    email: string;
                    roles: Array<string>;
                    organizationId?: number;
                    companyId?: number;
                    facilityId: number;
                }

                const body: IBody = {
                    username: state?.system?.createUser?.username,
                    password: state?.system?.createUser?.password,
                    email: state?.system?.createUser?.email,
                    roles: [state?.system?.createUser?.selectedUserRole?.key],
                    organizationId:
                        state?.auth?.userInfo?.organization?.id || state?.auth?.userInfo?.company?.organizationId,
                    companyId: state?.auth?.userInfo?.company?.id || null,
                    facilityId: state?.system?.createUser?.selectedConnectionRole?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/createUser/facility',
                        feature: CREATE_USER_FACILITY,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }
            case `${CREATE_USER_FACILITY} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                const { message } = action.payload;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: message || i18next.t('notification.successCreated'),
                    })
                );
                dispatch(getUserList());
                break;
            }
            case `${CREATE_USER_FACILITY} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }

            case CREATE_USER_ORGANIZATION_BACKOFFICE: {
                interface IBody {
                    username: string;
                    password: string;
                    email: string;
                    roles: Array<string>;
                    organizationId: 0;
                    membershipPackage: {
                        companyLimit: number;
                        facilityLimit: number;
                        expirationDate: string;
                    };
                }
                const body: IBody = {
                    username: 'string',
                    password: 'string',
                    email: 'string',
                    roles: ['string'],
                    organizationId: 0,
                    membershipPackage: {
                        companyLimit: 0,
                        facilityLimit: 0,
                        expirationDate: '2023-01-12T09:50:27.157Z',
                    },
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/createUser/backoffice/organization',
                        feature: CREATE_USER_ORGANIZATION_BACKOFFICE,
                        body,
                    })
                );
                break;
            }
            case `${CREATE_USER_ORGANIZATION_BACKOFFICE} ${API_SUCCESS}`: {
                break;
            }

            case CREATE_USER_COMPANY_BACKOFFICE: {
                interface IBody {
                    username: string;
                    password: string;
                    email: string;
                    roles: Array<string>;
                    organizationId: number;
                    companyId: number;
                    membershipPackage: {
                        companyLimit: number;
                        facilityLimit: number;
                        expirationDate: string;
                    };
                }

                const body: IBody = {
                    username: 'string',
                    password: 'string',
                    email: 'string',
                    roles: ['string'],
                    organizationId: 0,
                    companyId: 0,
                    membershipPackage: {
                        companyLimit: 0,
                        facilityLimit: 0,
                        expirationDate: '2023-01-12T09:51:28.207Z',
                    },
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/createUser/backoffice/company',
                        feature: CREATE_USER_COMPANY_BACKOFFICE,
                        body,
                    })
                );
                break;
            }
            case `${CREATE_USER_COMPANY_BACKOFFICE} ${API_SUCCESS}`: {
                break;
            }

            case CREATE_USER_BACKOFFICE: {
                interface IBody {
                    username: string;
                    password: string;
                    email: string;
                    roles: Array<string>;
                }

                const body: IBody = {
                    username: 'string',
                    password: 'string',
                    email: 'string',
                    roles: ['string'],
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/createUser/backoffice',
                        feature: CREATE_USER_BACKOFFICE,
                        body,
                    })
                );
                break;
            }
            case `${CREATE_USER_BACKOFFICE} ${API_SUCCESS}`: {
                break;
            }
        }
    };
