import EmissionCategory from '../entities/emissionCategory.model';
import { initialTypeData } from './initialType.data';

export const initialCategoryData: Array<EmissionCategory> = [
    {
        title: 'Doğrudan Sera Gazı Emisyonları ve Uzaklaştırmaları',
        types: [
            initialTypeData[0],
            initialTypeData[1],
            initialTypeData[2],
            initialTypeData[3],
            initialTypeData[18],
            initialTypeData[22],
        ],
    },
    {
        title: 'Satın Alınan Enerjiden Kaynaklanan Dolaylı Sera Gazı Emisyonları ',
        types: [initialTypeData[4], initialTypeData[5]],
    },
    {
        title: 'Ulaşımdan kaynaklanan Dolaylı Sera Gazı Emisyonları',
        types: [initialTypeData[6], initialTypeData[7], initialTypeData[8], initialTypeData[9], initialTypeData[10]],
    },
    {
        title: 'Kuruluş Tarafından Kullanılan Ürünlerden Kaynaklanan Dolaylı Sera Gazı Emisyonları',
        types: [
            initialTypeData[11],
            initialTypeData[12],
            initialTypeData[13],
            initialTypeData[19],
            initialTypeData[14],
        ],
    },
    {
        title: 'Kuruluşun Ürünlerinin Kullanımıyla İlişkili Dolaylı Sera Gazı Emisyonları',
        types: [initialTypeData[15], initialTypeData[20], initialTypeData[16], initialTypeData[21]],
    },
    {
        title: 'Diğer Kaynaklardan Dolaylı Sera Gazı Emisyonları',
        types: [initialTypeData[17]],
    },
];
