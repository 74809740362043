import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    ISoldProductsActivity,
    ISoldProductsActivityType,
    ISoldProductsAdditional,
    ISoldProductsDatabase,
    ISoldProductsFuelType,
    ISoldProductsResult,
    ISoldProductsSize,
    ISoldProductsUnit,
} from '../../../entities/emissionTypes/soldProductsCombustion';
import { EMISSION } from './base.action';

export const SOLD_PRODUCTS = 'SOLD_PRODUCTS';

////-- Base
export const CHANGE_SOLD_PRODUCTS_MAIN_FACILITY = `${EMISSION} ${SOLD_PRODUCTS} Change Main Facility`;
export const CHANGE_SOLD_PRODUCTS_SUB_FACILITY = `${EMISSION} ${SOLD_PRODUCTS} Change Sub Facility`;

export const GET_SOLD_PRODUCTS_TIERS = `${EMISSION} ${SOLD_PRODUCTS} Get Tiers`;
export const SET_SOLD_PRODUCTS_TIERS = `${EMISSION} ${SOLD_PRODUCTS} Set Tiers`;
export const SELECT_SOLD_PRODUCTS_TIER = `${EMISSION} ${SOLD_PRODUCTS} Select Tier`;

export const GET_SOLD_PRODUCTS_DATABASES = `${EMISSION} ${SOLD_PRODUCTS} Get Databases`;
export const SET_SOLD_PRODUCTS_DATABASES = `${EMISSION} ${SOLD_PRODUCTS} Set Databases`;
export const SELECT_SOLD_PRODUCTS_DATABASE = `${EMISSION} ${SOLD_PRODUCTS} Select Database`;

export const CHANGE_SOLD_PRODUCTS_EQUIVALENT_FACTOR = `${EMISSION} ${SOLD_PRODUCTS} Change Equivalent Factor`;

export const GET_SOLD_PRODUCTS_ACTIVITIES = `${EMISSION} ${SOLD_PRODUCTS} Get Activities`;
export const SET_SOLD_PRODUCTS_ACTIVITIES = `${EMISSION} ${SOLD_PRODUCTS} Set Activities`;
export const SELECT_SOLD_PRODUCTS_ACTIVITY = `${EMISSION} ${SOLD_PRODUCTS} Select Activity`;
/////--- End base

export const GET_SOLD_PRODUCTS_FUEL_TYPES = `${EMISSION} ${SOLD_PRODUCTS} Get Fuel Types`;
export const SET_SOLD_PRODUCTS_FUEL_TYPES = `${EMISSION} ${SOLD_PRODUCTS} Set Fuel Types`;
export const SELECT_SOLD_PRODUCTS_FUEL_TYPE = `${EMISSION} ${SOLD_PRODUCTS} Select Fuel Type`;

export const GET_SOLD_PRODUCTS_ACTIVITY_TYPES = `${EMISSION} ${SOLD_PRODUCTS} Get Activity Types`;
export const SET_SOLD_PRODUCTS_ACTIVITY_TYPES = `${EMISSION} ${SOLD_PRODUCTS} Set Activity Types`;
export const SELECT_SOLD_PRODUCTS_ACTIVITY_TYPE = `${EMISSION} ${SOLD_PRODUCTS} Select Activity Type`;

export const GET_SOLD_PRODUCTS_UNITS = `${EMISSION} ${SOLD_PRODUCTS} Get Units`;
export const SET_SOLD_PRODUCTS_UNITS = `${EMISSION} ${SOLD_PRODUCTS} Set Units`;
export const SELECT_SOLD_PRODUCTS_UNIT = `${EMISSION} ${SOLD_PRODUCTS} Select Unit`;

export const GET_SOLD_PRODUCTS_ADDITIONAL_CONSTANTS = `${EMISSION} ${SOLD_PRODUCTS} Get Additional Constants`;
export const SET_SOLD_PRODUCTS_ADDITIONAL_CONSTANTS = `${EMISSION} ${SOLD_PRODUCTS} Set Additional Constants`;

export const CHANGE_SOLD_PRODUCTS_WEIGHT = `${EMISSION} ${SOLD_PRODUCTS} Change Weight`;
export const CHANGE_SOLD_PRODUCTS_DISTANCE = `${EMISSION} ${SOLD_PRODUCTS} Change Distance`;

export const GET_SOLD_PRODUCTS_LADEN_PERCENTAGES = `${EMISSION} ${SOLD_PRODUCTS} Get Laden Percentages`;
export const SET_SOLD_PRODUCTS_LADEN_PERCENTAGES = `${EMISSION} ${SOLD_PRODUCTS} Set Laden Percentages`;
export const SELECT_SOLD_PRODUCTS_LADEN_PERCENTAGE = `${EMISSION} ${SOLD_PRODUCTS} Select Laden percentage`;

export const GET_SOLD_PRODUCTS_SIZES = `${EMISSION} ${SOLD_PRODUCTS} Get Sizes`;
export const SET_SOLD_PRODUCTS_SIZES = `${EMISSION} ${SOLD_PRODUCTS} Set Sizes`;
export const SELECT_SOLD_PRODUCTS_SIZE = `${EMISSION} ${SOLD_PRODUCTS} Select Size`;

////-- Base
export const CHANGE_SOLD_PRODUCTS_YEAR = `${EMISSION} ${SOLD_PRODUCTS} Change Year`;
export const SELECT_SOLD_PRODUCTS_ACTIVITY_DATA = `${EMISSION} ${SOLD_PRODUCTS} Select Activity Data`;
export const CHANGE_SOLD_PRODUCTS_CONSUMPTION_AMOUNT = `${EMISSION} ${SOLD_PRODUCTS} Change Consumption Amount`;
export const CHANGE_SOLD_PRODUCTS_DESCRIPTION = `${EMISSION} ${SOLD_PRODUCTS} Change Description`;

export const SUBMIT_SOLD_PRODUCTS_FORM = `${EMISSION} ${SOLD_PRODUCTS} Submit Form`;
export const SET_SOLD_PRODUCTS_RESULT = `${EMISSION} ${SOLD_PRODUCTS} Set Result`;
/////-- End Base
export const CLEAR_SOLD_PRODUCTS_CONSUMPTION_AMOUNT = `${EMISSION} ${SOLD_PRODUCTS} Clear Consumption Amount`;
export const CLEAR_SOLD_PRODUCTS_INPUT = `${EMISSION} ${SOLD_PRODUCTS} Clear Sold Products Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearSoldProductsInput = (list: Array<string>) => ({
    type: CLEAR_SOLD_PRODUCTS_INPUT,
    payload: list,
});
/// Region SoldProducts
export const changeSoldProductsMainFacility = (text: string) => ({
    type: CHANGE_SOLD_PRODUCTS_MAIN_FACILITY,
    payload: text,
});

export const changeSoldProductsSubFacility = (text: string) => {
    return {
        type: CHANGE_SOLD_PRODUCTS_SUB_FACILITY,
        payload: text,
    };
};

export const getSoldProductsTiers = () => ({
    type: GET_SOLD_PRODUCTS_TIERS,
});

export const setSoldProductsTiers = (list: Array<ITier>) => ({
    type: SET_SOLD_PRODUCTS_TIERS,
    payload: list,
});

export const selectSoldProductsTier = (tier: ITier) => ({
    type: SELECT_SOLD_PRODUCTS_TIER,
    payload: tier,
});

export const getSoldProductsDatabases = (tier: ITier) => ({
    type: GET_SOLD_PRODUCTS_DATABASES,
    payload: tier,
});

export const setSoldProductsDatabases = (list: Array<ISoldProductsDatabase>) => ({
    type: SET_SOLD_PRODUCTS_DATABASES,
    payload: list,
});

export const selectSoldProductsDatabase = (database: ISoldProductsDatabase) => ({
    type: SELECT_SOLD_PRODUCTS_DATABASE,
    payload: database,
});

export const getSoldProductsActivities = (tier: ITier) => ({
    type: GET_SOLD_PRODUCTS_ACTIVITIES,
    payload: tier,
});

export const setSoldProductsActivities = (activities: Array<ISoldProductsActivity>) => ({
    type: SET_SOLD_PRODUCTS_ACTIVITIES,
    payload: activities,
});

export const selectSoldProductsActivity = (activity: ISoldProductsActivity) => ({
    type: SELECT_SOLD_PRODUCTS_ACTIVITY,
    payload: activity,
});

///////
export const getSoldProductsFuelTypes = (tier: ITier) => ({
    type: GET_SOLD_PRODUCTS_FUEL_TYPES,
    payload: tier,
});

export const setSoldProductsFuelTypes = (list: Array<ISoldProductsFuelType>) => ({
    type: SET_SOLD_PRODUCTS_FUEL_TYPES,
    payload: list,
});

export const selectSoldProductsFuelType = (database: ISoldProductsFuelType) => ({
    type: SELECT_SOLD_PRODUCTS_FUEL_TYPE,
    payload: database,
});

export const getSoldProductsActivityTypes = (value: any) => ({
    type: GET_SOLD_PRODUCTS_ACTIVITY_TYPES,
    payload: value,
});

export const setSoldProductsActivityTypes = (activities: Array<ISoldProductsActivityType>) => ({
    type: SET_SOLD_PRODUCTS_ACTIVITY_TYPES,
    payload: activities,
});

export const selectSoldProductsActivityType = (activity: ISoldProductsActivityType) => ({
    type: SELECT_SOLD_PRODUCTS_ACTIVITY_TYPE,
    payload: activity,
});

export const getSoldProductsUnits = (units: ISoldProductsActivityType) => ({
    type: GET_SOLD_PRODUCTS_UNITS,
    payload: units,
});

export const setSoldProductsUnits = (units: Array<ISoldProductsUnit>) => ({
    type: SET_SOLD_PRODUCTS_UNITS,
    payload: units,
});

export const selectSoldProductsUnit = (unit: ISoldProductsUnit) => ({
    type: SELECT_SOLD_PRODUCTS_UNIT,
    payload: unit,
});

export const getSoldProductsAdditionalConstants = (additional: ISoldProductsUnit) => ({
    type: GET_SOLD_PRODUCTS_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setSoldProductsAdditionalConstants = (additional: Array<ISoldProductsAdditional>) => ({
    type: SET_SOLD_PRODUCTS_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeSoldProductsWeight = (value: number) => ({
    type: CHANGE_SOLD_PRODUCTS_WEIGHT,
    payload: value,
});

export const changeSoldProductsDistance = (value: number) => ({
    type: CHANGE_SOLD_PRODUCTS_DISTANCE,
    payload: value,
});

export const getSoldProductsLadenPercentages = (ladens: any) => ({
    type: GET_SOLD_PRODUCTS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setSoldProductsLadenPercentages = (ladens: Array<any>) => ({
    type: SET_SOLD_PRODUCTS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectSoldProductsLadenPercentage = (laden: any) => ({
    type: SELECT_SOLD_PRODUCTS_LADEN_PERCENTAGE,
    payload: laden,
});

export const getSoldProductsSizes = (sizes: ISoldProductsActivityType) => ({
    type: GET_SOLD_PRODUCTS_SIZES,
    payload: sizes,
});

export const setSoldProductsSizes = (sizes: Array<ISoldProductsSize>) => ({
    type: SET_SOLD_PRODUCTS_SIZES,
    payload: sizes,
});

export const selectSoldProductsSize = (size: ISoldProductsSize) => ({
    type: SELECT_SOLD_PRODUCTS_SIZE,
    payload: size,
});

///////
export const submitSoldProductsForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_SOLD_PRODUCTS_FORM,
    meta: {
        onSuccess,
    },
});

export const setSoldProductsResult = (data: Array<ISoldProductsResult>) => ({
    type: SET_SOLD_PRODUCTS_RESULT,
    payload: data,
});

export const selectSoldProductsActivityData = (activity: string) => ({
    type: SELECT_SOLD_PRODUCTS_ACTIVITY_DATA,
    payload: activity,
});

export const changeSoldProductsYear = (value: number) => ({
    type: CHANGE_SOLD_PRODUCTS_YEAR,
    payload: value,
});

export const changeSoldProductsDescription = (text: string) => ({
    type: CHANGE_SOLD_PRODUCTS_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_SOLD_PRODUCTS_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_SOLD_PRODUCTS_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_SOLD_PRODUCTS_CONSUMPTION_AMOUNT,
});
/// End Region SoldProducts
