import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    DELETE_PRIVILEGE_GROUP_BY_ID,
    DELETE_PRIVILEGE_GROUP_PRIVILEGE,
    GET_PRIVILEGE_GROUP_BY_ID,
    getPrivilegeGroupById,
    POST_PRIVILEGE_GROUP,
    POST_PRIVILEGE_GROUP_NAMES,
    postPrivilegeGroupNames,
    PUT_PRIVILEGE_GROUP_PRIVILEGE,
    setPrivilegeGroupById,
    setPrivilegeGroupNames,
} from '../../../actions/system/privilegeGroup.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const privilegeGroupMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case PUT_PRIVILEGE_GROUP_PRIVILEGE: {
                interface IBody {
                    privilegeList: [
                        {
                            privilegedApiType: string;
                            permissionTypeSet: Array<string>;
                        }
                    ];
                    id: number;
                }
                const body: IBody = action.payload;

                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/auth/privilegeGroup/privilege',
                        feature: PUT_PRIVILEGE_GROUP_PRIVILEGE,
                        body,
                    })
                );
                break;
            }
            case `${PUT_PRIVILEGE_GROUP_PRIVILEGE} ${API_SUCCESS}`: {
                dispatch(getPrivilegeGroupById(action.payload?.privilegeGroup.id));
                break;
            }

            case DELETE_PRIVILEGE_GROUP_PRIVILEGE: {
                interface IBody {
                    privilegeList: [
                        {
                            privilegedApiType: string;
                            permissionTypeSet: Array<string>;
                        }
                    ];
                    id: number;
                }
                const body: IBody = action.payload;
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: '/auth/privilegeGroup/privilege',
                        feature: DELETE_PRIVILEGE_GROUP_PRIVILEGE,
                        body,
                    })
                );
                break;
            }
            case `${DELETE_PRIVILEGE_GROUP_PRIVILEGE} ${API_SUCCESS}`: {
                dispatch(getPrivilegeGroupById(action.payload?.privilegeGroup.id));
                break;
            }

            case POST_PRIVILEGE_GROUP: {
                interface IBody {
                    privilegeList: [
                        {
                            privilegedApiType: string;
                            permissionTypeSet: Array<string>;
                        }
                    ];
                    name: string;
                    description: string;
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                }

                const body: IBody = {
                    privilegeList: [
                        {
                            privilegedApiType: 'EMISSION',
                            permissionTypeSet: ['READ'],
                        },
                    ],
                    name: state?.system?.createPrivilegeGroup?.name,
                    description: state?.system?.createPrivilegeGroup?.description,
                    organizationId: state?.auth?.userInfo?.organization?.id,
                    companyId:
                        state?.company?.facility?.facility?.companyId ||
                        state?.auth?.userInfo?.company?.id ||
                        state?.auth?.userInfo?.facility?.companyId,
                    facilityId: state?.company?.facility?.facility?.id || state?.auth?.userInfo?.facility?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/privilegeGroup',
                        feature: POST_PRIVILEGE_GROUP,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }
            case `${POST_PRIVILEGE_GROUP} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                const { message } = action.payload;
                dispatch(
                    createNotification({
                        type: 'success',
                        message: message || i18next.t('notification.successCreated'),
                        time: 3000,
                    })
                );
                dispatch(postPrivilegeGroupNames());
                break;
            }
            case `${POST_PRIVILEGE_GROUP} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case POST_PRIVILEGE_GROUP_NAMES: {
                interface IBody {
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                }
                const body: IBody = {
                    companyId: state?.auth?.userInfo?.company?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/privilegeGroup/names',
                        feature: POST_PRIVILEGE_GROUP_NAMES,
                        body,
                    })
                );
                break;
            }
            case `${POST_PRIVILEGE_GROUP_NAMES} ${API_SUCCESS}`: {
                const data = action.payload?.contentDtoList;
                dispatch(setPrivilegeGroupNames(data));
                break;
            }

            case GET_PRIVILEGE_GROUP_BY_ID: {
                const id = action.payload;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/auth/privilegeGroup/${id}`,
                        feature: GET_PRIVILEGE_GROUP_BY_ID,
                    })
                );
                break;
            }
            case `${GET_PRIVILEGE_GROUP_BY_ID} ${API_SUCCESS}`: {
                const data = action.payload?.privilegeGroup;
                dispatch(setPrivilegeGroupById(data));
                break;
            }

            case DELETE_PRIVILEGE_GROUP_BY_ID: {
                const id = action.payload;
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: `/auth/privilegeGroup/${id}`,
                        feature: DELETE_PRIVILEGE_GROUP_BY_ID,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${DELETE_PRIVILEGE_GROUP_BY_ID} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                const { message } = action.payload;
                dispatch(
                    createNotification({
                        type: 'success',
                        message: message || i18next.t('notification.successDeleted'),
                        time: 3000,
                    })
                );
                dispatch(postPrivilegeGroupNames());
                break;
            }
            case `${DELETE_PRIVILEGE_GROUP_BY_ID} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || action.payload.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }
        }
    };
