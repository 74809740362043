import DashboardIcon from '@mui/icons-material/Dashboard';
import FactoryIcon from '@mui/icons-material/Factory';
import InventoryIcon from '@mui/icons-material/Inventory';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import HelpIcon from '@mui/icons-material/Help';
import { Box, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/ecofoni.png';
import { useAppDispatch } from '../../../redux/hooks';
import SideBarListItem from './SideBarListItem';
import { useTranslation } from 'react-i18next';
import usePermission from '../../../hooks/usePermission';
import FlagIcon from '@mui/icons-material/Flag';
import { PermissionTypes } from '../../../data/authorizations/permissionType';
import { PermissionTypeSetsGroup } from '../../../data/authorizations/permissionTypeSetGroup';
import { Roles, RolesGroup } from '../../../data/authorizations/rolesGroup';

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: theme.shadows[3],
    borderRight: 0,
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflowX: 'hidden',
    '::-webkit-scrollbar': {
        width: '3px',
    },

    '::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.mode === 'light' ? '#eeeeee' : '#aeaeae',
    },

    '::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.mode === 'light' ? '#bcbcbc' : '#9a9a9a',
    },
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    boxShadow: theme.shadows[3],
    borderRight: 0,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

interface IMenuLink {
    title: string;
    path: string;
    icon: any;
    type: any;
    onClick?: any;
    items?: Array<any>;
    isShow?: boolean;
}

export default function MiniDrawer() {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'sidebar' });
    const [open, setOpen] = React.useState(false);
    const [openSubMenu, setOpenSubMenu] = React.useState(false);
    const dispatch = useAppDispatch();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenSubMenu(false);
    };

    const handleSubMenu = () => {
        setOpenSubMenu(!openSubMenu);
    };

    const links: Array<IMenuLink> = [
        {
            title: t('dashboard'),
            path: '/company/dashboard',
            isShow: usePermission(PermissionTypes.COMPANY_DASHBOARD, PermissionTypeSetsGroup.ONLY_R, [
                Roles.ORGANIZATION_ADMIN,
                Roles.COMPANY_ADMIN,
                Roles.FACILITY_USER,
            ]),
            icon: (prop: any) => <DashboardIcon {...prop} />,
            type: 'link',
            onClick: () => localStorage.removeItem('facility'),
        },
        {
            title: t('analytical'),
            path: '/company/analytic',
            isShow: usePermission(PermissionTypes.FACILITY_ANALYTICS, PermissionTypeSetsGroup.ONLY_R, [
                Roles.ORGANIZATION_ADMIN,
                Roles.COMPANY_ADMIN,
                Roles.FACILITY_USER,
            ]),
            icon: (prop: any) => <LeaderboardIcon {...prop} />,
            type: 'link',
        },
        {
            title: t('organizations'),
            path: '/facility',
            isShow: usePermission(PermissionTypes.FACILITY, PermissionTypeSetsGroup.ONLY_R, RolesGroup.ONLY_ADMINS),
            icon: (prop: any) => <FactoryIcon {...prop} />,
            type: 'link',
        },
        {
            title: t('inventory'),
            path: '/',
            isShow: usePermission(PermissionTypes.INVENTORY_FORM, PermissionTypeSetsGroup.ONLY_R, [
                Roles.ORGANIZATION_ADMIN,
                Roles.COMPANY_ADMIN,
                Roles.FACILITY_USER,
            ]),
            icon: (prop: any) => <InventoryIcon {...prop} />,
            type: 'collapse',
            items: [
                {
                    title: t('inventoryEntry'),
                    path: '/inventory/create/category',
                    isShow: usePermission(PermissionTypes.INVENTORY_FORM, PermissionTypeSetsGroup.ONLY_R, [
                        Roles.ORGANIZATION_ADMIN,
                        Roles.COMPANY_ADMIN,
                        Roles.FACILITY_USER,
                    ]),
                    //icon: (prop: any) => <ArrowRightIcon {...prop} />,
                    type: 'link',
                },
                //{
                //    title: t('inventoryEntryGHG'),
                //    path: '/inventory/create/category/ghg',
                //    isShow: usePermission(
                //        PermissionTypes.INVENTORY_FORM,
                //        PermissionTypeSetsGroup.ONLY_R,
                //        RolesGroup.ONLY_ADMINS
                //    ),
                //    //icon: (prop: any) => <ArrowRightIcon {...prop} />,
                //    type: 'link',
                //},
                {
                    title: t('inventoryList'),
                    path: '/inventory',
                    isShow: usePermission(PermissionTypes.INVENTORY_LIST, PermissionTypeSetsGroup.ONLY_R, [
                        Roles.ORGANIZATION_ADMIN,
                        Roles.COMPANY_ADMIN,
                        Roles.FACILITY_USER,
                    ]),
                    //icon: (prop: any) => <ArrowRightIcon {...prop} />,
                    type: 'link',
                },
            ],
        },
        {
            title: t('reporting'),
            path: '/reporting/module',
            isShow: usePermission('X', ['X'], RolesGroup.ORGANIZATION_ADMIN),
            icon: (prop: any) => <SummarizeIcon {...prop} />,
            type: 'link',
        },
        {
            title: t('reporting'),
            path: '/',
            isShow: usePermission('', [''], RolesGroup.COMPANY_ADMIN),
            icon: (prop: any) => <SummarizeIcon {...prop} />,
            type: 'collapse',
            items: [
                {
                    title: t('generalInformation'),
                    path: '/general',
                    isShow: usePermission(
                        PermissionTypes.REPORT,
                        PermissionTypeSetsGroup.ONLY_R,
                        RolesGroup.COMPANY_ADMIN
                    ),
                    //icon: (prop: any) => <FiberManualRecordIcon fontSize={'small'} {...prop} />,
                    type: 'link',
                },
                {
                    title: t('organizational'),
                    path: '/organization',
                    isShow: usePermission(
                        PermissionTypes.ORGANIZATIONAL_BOUNDARY,
                        PermissionTypeSetsGroup.ONLY_R,
                        RolesGroup.COMPANY_ADMIN
                    ),
                    //icon: (prop: any) => <ArrowRightIcon {...prop} />,
                    type: 'link',
                },
                {
                    title: t('reportingLimits'),
                    path: '/reporting',
                    isShow: usePermission(
                        PermissionTypes.REPORT_BOUNDARY,
                        PermissionTypeSetsGroup.ONLY_R,
                        RolesGroup.COMPANY_ADMIN
                    ),
                    //icon: (prop: any) => <ArrowRightIcon {...prop} />,
                    type: 'link',
                },
                {
                    title: t('calculatedGHGEmissionsInventory'),
                    path: '/quantified',
                    isShow: usePermission(
                        PermissionTypes.QUANTIFIED_EMISSION,
                        PermissionTypeSetsGroup.ONLY_R,
                        RolesGroup.COMPANY_ADMIN
                    ),
                    //icon: (prop: any) => <ArrowRightIcon {...prop} />,
                    type: 'link',
                },
                {
                    title: t('internalPerformanceTracking'),
                    path: '/internal',
                    isShow: usePermission(
                        PermissionTypes.INTERNAL_PERFORMANCE,
                        PermissionTypeSetsGroup.ONLY_R,
                        RolesGroup.COMPANY_ADMIN
                    ),
                    //icon: (prop: any) => <ArrowRightIcon {...prop} />,
                    type: 'link',
                },
                {
                    title: t('appendices'),
                    path: '/appendices',
                    isShow: usePermission(
                        PermissionTypes.APPENDIX,
                        PermissionTypeSetsGroup.ONLY_R,
                        RolesGroup.COMPANY_ADMIN
                    ),
                    //icon: (prop: any) => <ArrowRightIcon {...prop} />,
                    type: 'link',
                },
            ],
        },
        {
            title: t('targetKPI'),
            path: '/kpi',
            isShow: usePermission(
                PermissionTypes.KPI_DASHBOARD,
                PermissionTypeSetsGroup.ONLY_R,
                RolesGroup.ONLY_ADMINS
            ),
            icon: (prop: any) => <FlagIcon {...prop} />,
            type: 'link',
        },
    ];

    const linksBottom: Array<IMenuLink> = [
        /*{
            title: t('faqTitle'),
            path: '/faq',
            isShow: usePermission('', [''], RolesGroup.ALL),
            type: 'link',
            icon: (prop: any) => <Typography variant="h5">{t('faq')}</Typography>,
            onClick: () => {},
        },*/
        {
            title: t('guide'),
            path: '/guide',
            isShow: usePermission(PermissionTypes.GUIDE, PermissionTypeSetsGroup.ONLY_R, [
                Roles.ORGANIZATION_ADMIN,
                Roles.COMPANY_ADMIN,
                Roles.FACILITY_USER,
            ]),
            type: 'link',
            icon: (prop: any) => <HelpIcon {...prop} />,
            onClick: () => {},
        },
    ];

    return (
        <Drawer variant="permanent" open={open} onMouseOver={handleDrawerOpen} onMouseOut={handleDrawerClose}>
            <List sx={{ marginTop: '35px', marginBottom: '70px' }}>
                <ListItem sx={{ display: 'block', borderRadius: 9 }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            color: 'text.primary',
                        }}
                        component={Link}
                        to="/"
                    >
                        <Box
                            component={'img'}
                            sx={{
                                width: 33,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                            src={Logo}
                        />
                        <ListItemText primary={'Ecofoni'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List>
                {links.map((link, index) => (
                    <>
                        {link.isShow && (
                            <SideBarListItem
                                key={link.title}
                                type={link.type}
                                title={link.title}
                                path={link.path}
                                icon={link.icon}
                                items={link.items}
                                isSidebarOpened={open}
                                onClick={link.onClick ? link.onClick : () => {}}
                            />
                        )}
                    </>
                ))}
            </List>
            <List sx={{ marginTop: 'auto', marginBottom: '10px' }}>
                {linksBottom.map((link) => (
                    <>
                        {link.isShow && (
                            <SideBarListItem
                                key={link.title}
                                type={link.type}
                                title={link.title}
                                path={link.path}
                                icon={link.icon}
                                items={link.items}
                                isSidebarOpened={open}
                                onClick={() => {}}
                            />
                        )}
                    </>
                ))}
            </List>
        </Drawer>
    );
}
