// TR
export const translation = {
    pages: {
        login: {
            title: 'Giriş Yap',
            username: 'Kullanıcı Adı',
            password: 'Şifre',
            login: 'Giriş',
            forgetPassword: 'Şifremi Unuttum',
            register: 'Hesabınız yok mu? Kayıt olun',
        },
        resetPassword: {
            step1Text: 'Email Gir',
            step1Description: 'E-postanıza bir kod göndereceğiz.',
            step1InputTitle: 'E-posta',
            step1InputPlaceholder: 'E-posta',
            step1Button: 'Kodu Gönder',
            step2Text: 'Kodu Doğrula',
            step2Description: 'E-postanıza gelen kodu giriniz.',
            step2InputTitle: 'Kod',
            step2InputPlaceholder: 'Kod',
            step2Button: 'Kodu Doğrula',
            step3Text: 'Şifreyi Sıfırla',
            step3Description: 'Yeni Şifre Oluştur',
            step3PasswordInput: 'Şifre',
            step3PasswordPlaceholder: 'Şifre',
            step3ConfirmInput: 'Şifreyi Onayla',
            step3ConfirmPlaceholder: 'Şifreyi Onayla',
            notMatch: '* Parolalar uyuşmuyor',
            step3Button: 'Kaydet',
        },
        dashboard: {
            dashboard: 'Dashboard',
            totalEmission: 'Toplam Emisyon',
            averageMonthlyEmission: 'Ortalama Aylık Emisyon',
            targetValue: 'Hedef Değer',
            emissionBreakdown: 'Emisyon Kırılımı',
            monthBasedEmissionValues: 'Ay Bazlı Emisyon Değerleri',
            cumulativeIncreasingEmissionsGraph: 'Kümülatif Artan Emisyon Grafiği',
            scope: 'Kapsam',
            month: 'Ay',
            year: 'Yıl',
        },
        analytical: {
            analytical: 'Analitik',
            totalEmission: 'Toplam Emisyon',
            averageMonthlyEmission: 'Ortalama Aylık Emisyon',
            scopeBasedEmission: 'Kapsam Bazlı Emisyon',
            mainCategoryBasedEmissionValues: 'Ana Kategori Bazlı Emisyon Değerleri',
            mainCategoryBasedBreakdowns: 'Ana Kategori Bazlı Alt Kırılımlar',
            mainCategoryBasedEmissionBreakdowns: 'Ana Kategori Bazlı Emisyon Kırılımı',
            directEmissions: 'Doğrudan Emisyonlar',
            energyIndirectEmissions: 'Enerji Dolaylı Emisyonlar',
            emissionsFromTransport: 'Taşımacılık Kaynaklı Emisyonlar',
            manufacturedProductEmissions: 'Üretilen Ürün Emisyonları',
            usedProductEmissions: 'Kullanılan Ürün Emisyonları',
            otherEmissions: 'Diğer Emisyonlar',
            subCategoryBasedEmissionsBreakdown: 'Alt Kategori Bazlı Emisyon Kırılımı',
            scopeEmissionDistribution: 'Kapsam Emisyon Dağılımı',
            year: 'Yıl',
            month: 'Ay',
        },
        organizations: {
            organizations: 'Organizasyonlar',
            companies: 'Şirketler',
            facilities: 'Tesisler',
            no: 'No',
            companyName: 'Şirket Adı',
            facilityName: 'Tesis Adı',
            email: 'E-posta',
            country: 'Ülke',
            state: 'Şehir',
            phone: 'Telefon',
            address: 'Adres',
            actions: 'Aksiyonlar',
            rowCount: 'Gösterilecek Satır Sayısı',
            of: "'de",
            createDialog: {
                addButtonCompany: 'Şirket Ekle',
                addButtonFacility: 'Tesis Ekle',
                createCompany: {
                    title: 'Şirket Bilgilerini Doldurun',
                    companyName: 'Şirket Adı',
                    phone: 'Telefon',
                    email: 'E-posta',
                    address: 'Adres',
                    url: 'Website URL',
                    facilityLimit: 'Tesis Limit',
                    country: 'Ülke',
                    state: 'Şehir',
                    cancel: 'Vazgeç',
                    add: 'Ekle',
                },
                createFacility: {
                    title: 'Tesis Bilgilerini Doldurun',
                    facilityName: 'Tesis Adı',
                    phone: 'Telefon',
                    email: 'E-posta',
                    address: 'Adres',
                    country: 'Ülke',
                    state: 'Şehir',
                    cancel: 'Vazgeç',
                    add: 'Ekle',
                },
            },
            updateDialog: {
                updateCompany: {
                    title: 'Şirketin güncellemek istediğiniz alanlarını değiştiriniz.',
                    companyName: 'Tesis Adı',
                    email: 'E-posta',
                    country: 'Ülke',
                    state: 'Şehir',
                    phone: 'Telefon',
                    address: 'Adres',
                    url: 'Website URL',
                    facilityLimit: 'Tesis Limit',
                    cancel: 'Vazgeç',
                    update: 'Güncelle',
                },
                updateFacility: {
                    title: 'Tesisin güncellemek istediğiniz alanlarını değiştiriniz.',
                    facilityName: 'Tesis Adı',
                    email: 'E-posta',
                    country: 'Ülke',
                    state: 'Şehir',
                    phone: 'Telefon',
                    address: 'Adres',
                    cancel: 'Vazgeç',
                    update: 'Güncelle',
                },
            },
            deleteDialog: {
                deleteCompany: {
                    title: 'Şirketi silmek istediğinizden emin misiniz?',
                    description: 'Emin misiniz?',
                    cancel: 'Vazgeç',
                    delete: 'Sil',
                },
                deleteFacility: {
                    title: 'Tesisi silmek istediğinizden emin misiniz?',
                    description: 'Emin misiniz?',
                    cancel: 'Vazgeç',
                    delete: 'Sil',
                },
            },
        },
        inventoryEntry: {
            inventoryEntry: 'Envanter Girişi - Kategori Seçimi',
            scope: 'Kapsam',
            category: 'Kategori',
            category1: 'Doğrudan Sera Gazı Emisyonları ve Uzaklaştırmaları',
            category2: 'Satın Alınan Enerjiden Kaynaklanan Dolaylı Sera Gazı Emisyonları',
            category3: 'Ulaşımdan kaynaklanan Dolaylı Sera Gazı Emisyonları',
            category4: 'Kuruluş Tarafından Kullanılan Ürünlerden Kaynaklanan Dolaylı Sera Gazı Emisyonları',
            category5: 'Kuruluşun Ürünlerinin Kullanımıyla İlişkili Dolaylı Sera Gazı Emisyonları',
            category6: 'Diğer Kaynaklardan Dolaylı Sera Gazı Emisyonları',
            // Forms
            static: 'Sabit Yanma',
            mobile: 'Mobil Yanma',
            process: 'Proses Emisyonları',
            leak: 'Sızıntı Emisyonları',
            electric: 'Elektrik Tüketimleri',
            steam: 'Isı / Buhar Tüketimleri',
            income: 'Yukarı Yönlü Taşımacılık',
            outcome: 'Aşağı Yönlü Taşımacılık',
            employee: 'Çalışan İşe Geliş Gidiş',
            visitor: 'Ziyaretçi / Müşteri Tesise Ulaşım',
            business: 'İş Seyahatleri',
            sold: 'Satın Alınan Ürün',
            bought: 'Sermaye Mallar',
            waste: 'Katı ve Sıvı Atık Bertarafı',
            service: 'Hizmet Kullanımı',
            product: 'Ürün Kullanım Aşaması',
            lifetime: 'Ürün Kullanım Ömrü Sonrası',
            others: 'Diğer',
            lulucf: 'LULUCF',
            assets: 'Varlık Kullanımı',
            leased: 'Kiralanan Varlıklar',
            investment: 'Yatırım Emisyonları',
            biomass: 'Biyokütle Emisyonları',
            downstreamLeasedAssets: 'Aşağı Yönlü Kiralanan Varlıklar',
            upstreamLeasedAssets: 'Yukarı Yönlü Kiralanan Varlıklar',
            continue: 'Devam Et',
        },
        inventoryEntryGHG: {
            inventoryEntryGHG: 'Envanter Girişi - Kategori Seçimi - GHG',
            scope: 'Kapsam',
            scope3Title: 'Tedarik zincirindeki dolaylı emisyonlar',
            category1: 'Doğrudan Sera Gazı Emisyonları ve Uzaklaştırmaları',
            category2: 'Satın Alınan Enerjiden Kaynaklanan Dolaylı Sera Gazı Emisyonları',
            category3: 'Yukarı Yönlü Tedarik',
            category4: 'Aşağı Yönlü Tedarik',
            // Forms
            static: 'Sabit Yanma',
            mobile: 'Mobil Yanma',
            process: 'Proses Emisyonları',
            leak: 'Sızıntı Emisyonları',
            electric: 'Elektrik Tüketimleri',
            steam: 'Isı / Buhar Tüketimleri',
            income: 'Taşıma ve Dağıtım',
            outcome: 'Taşıma ve Dağıtım',
            employee: 'Çalışan İşe Geliş Gidiş',
            visitor: 'Ziyaretçi / Müşteri Tesise Ulaşım',
            business: 'İş Seyahatleri',
            sold: 'Satın Alınan Ürün ve Hizmetler',
            bought: 'Sermaye Mallar',
            waste: 'Atık',
            service: 'Hizmet Kullanımı',
            product: 'Satılan Ürünün Kullanımı',
            lifetime: 'Ürün Kullanım Ömrü Sonrası',
            others: 'Diğer',
            lulucf: 'LULUCF',
            assets: 'Varlık Kullanımı',
            leased: 'Kiralanan Varlıklar',
            leasedFacility: 'Kiralık Tesisler',
            investment: 'Yatırımlar',
            biomass: 'Biyokütle Emisyonları',
            continue: 'Devam Et',
        },
        inventoryList: {
            inventoryList: 'Envanter Listesi',
            period: 'Dönem',
            category: 'Kategori',
            subCategory: 'Alt Kategori',
            source: 'Kaynak',
            activityData: 'Faaliyet Verisi',
            consumptionData: 'Tüketim Verisi',
            sum: 'Toplam CO2e',
            tier: 'Kademe',
            filter: {
                filter: 'Filtrele',
                selectFilter: 'Filtre Seçiniz',
                beginning: 'Başlangıç',
                finish: 'Bitiş',
                category: 'Kategori',
                subCategory: 'Alt Kategori',
                source: 'Kaynak',
                cancel: 'Vazgeç',
                okey: 'Tamam',
                selectAll: 'Tümünü Seç',
                select: 'Seç',
                allWillBeSelected: 'Seçilen Tüm Envanterler Silinecektir.',
                sure: 'Emin misiniz?',
                remove: 'Sil',
                maxAllowedSize: "Dosya 1 MB'tan büyük olmamalıdır",
            },
            customTable: {
                of: "'de",
                rowCount: 'Gösterilecek Satır Sayısı',
                inventoryList: {
                    companyName: 'Şirket Adı',
                    facilityName: 'Tesis Adı',
                    actions: 'Aksiyonlar',
                    reports: 'Raporlar',
                    cancel: 'Vazgeç',
                    allWillBeSelected: 'Seçilen envanteri silmek istediğinizden emin misiniz?',
                    sure: 'Emin misiniz?',
                    remove: 'Sil',
                    maxAllowedSize: "Dosya 1 MB'tan büyük olmamalıdır",
                },
            },
        },
        inventoryResult: {
            seeAll: 'Tüm Sonuçları Gör',
            entered: {
                entries: 'Envanter Girdileri',
                mainFacility: 'Ana Tesis',
                subFacility: 'Alt Tesis',
                activityData: 'Faaliyet Verisi',
                primary: 'Birincil Faaliyet Verisi',
                secondary: 'İkincil Faaliyet Verisi',
                stageApproach: 'Kademe Yaklaşımı',
                database: 'Veritabanı',
                equivalentFactor: 'Eşdeğer Faktör',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                sourceStream: 'Kaynak Akışı',
                year: 'Yıl',
                unit: 'Birim',
                description: 'Açıklama',
            },
            result: {
                results: 'Sonuçlar',
                year: 'Yıl',
                month: 'Ay',
                total: 'Toplam',
                addDocument: 'Döküman Ekle',
                maxAllowedSize: "Dosya 1 MB'tan büyük olmamalıdır",
            },
            // Forms
            static: 'Sabit Yanma',
            mobile: 'Mobil Yanma',
            process: 'Proses Emisyonları',
            leak: 'Sızıntı Emisyonları',
            electric: 'Elektrik Tüketimleri',
            steam: 'Isı / Buhar Tüketimleri',
            income: 'Yukarı Yönlü Taşımacılık',
            outcome: 'Aşağı Yönlü Taşımacılık',
            employee: 'Çalışan İşe Geliş Gidiş',
            visitor: 'Ziyaretçi / Müşteri Tesise Ulaşım',
            business: 'İş Seyahatleri',
            sold: 'Satın Alınan Ürün',
            bought: 'Sermaye Mallar',
            waste: 'Katı ve Sıvı Atık Bertarafı',
            service: 'Hizmet Kullanımı',
            product: 'Ürün Kullanım Aşaması',
            lifetime: 'Ürün Kullanım Ömrü Sonrası',
            others: 'Diğer',
            lulucf: 'LULUCF',
            assets: 'Varlık Kullanımı',
            leased: 'Kiralanan Varlıklar',
            investment: 'Yatırım Emisyonları',
            biomass: 'Biyokütle Emisyonları',
            upstreamLeased: 'Yukarı Yönlü Kiralanan Varlıklar',
            downstreamLeased: 'Aşağı Yönlü Kiralanan Varlıklar',
        },
        inventoryDetail: {
            title: 'Envanter Detay',
            update: 'Güncelle',
            dialogTitle: 'Güncellemek istediğiniz alanları değiştiriniz.',
            dialogCancel: 'Vazgeç',
            dialogUpdate: 'Güncelle',
            inputs: 'Girdiler',
            values: 'Değerler',
            results: 'Sonuçlar',
            facilityName: 'Tesis Adı',
            tags: 'Etiket',
            period: 'Dönem',
            activityData: 'Faaliyet Verisi',
            database: 'Veritabanı',
            description: 'Açıklama',
            emissionActivity: 'Emisyon Aktivitesi',
            emissionActivityType: 'Emisyon Aktivite Türü',
            emissionCategory: 'Emisyon Kategori',
            emissionCategoryType: 'Emisyon Kategori Türü',
            emissionSource: 'Emisyon Kaynağı',
            equivalentTags: 'Eşdeğer Etiketler',
            CH4: 'CH4',
            CO2: 'CO2',
            N2O: 'N2O',
            total: 'Toplam',
        },
        profile: {
            profile: 'Profil',
            update: 'Güncelle',
            save: 'Kaydet',
            cancel: 'Vazgeç',
            accountInformationStep: 'Hesap Bilgileri',
            corporateInformationStep: 'Kurum Bilgileri',
            accountInformation: {
                companyName: 'Şirket Adı',
                completeProfile: 'Profili Tamamla',
                establishmentInformation: 'Kuruluş Bilgileri',
                summaryInformation: 'Özet Bilgiler',
                email: 'Email',
                website: 'Web Sitesi',
                phone: 'Telefon Numarası',
                numberOfLocation: 'Lokasyon Sayısı',
                country: 'Ülke',
                city: 'Şehir',
                sector: 'Sektör',
                address: 'Adres',
                companyNamePlaceholder: 'Google',
                save: 'Kaydet',
                cancel: 'Vazgeç',
                addImage: 'Görsel Ekle',
                maxAllowedSize: "Dosya 1 MB'tan büyük olmamalıdır",
            },
            corporateInformation: {
                email: 'Email',
                website: 'Web Sitesi',
                phone: 'Telefon Numarası',
            },
        },
        reporting: {
            general: {
                general: 'Genel Bilgiler, Amaç ve Politika',
                update: 'Güncelle',
                title: 'Genel Bilgiler, Amaç ve Politika',
                episode1: 'Bölüm 1',
                intro: 'Giriş',
                purpose: 'Amaç, Kapsam ve Hedefler',
                organizationDescription: 'Organizasyon Tanımı',
                ghgPolicy: 'Sera Gazı Yönetim Politikası, Stratejileri ve Programları',
                responsibilities: 'Sorumluluklar',
                targetAudience: 'Hedef Kitle ve Yaygınlaştırma Politikası',
                reportingPeriod: 'Raporlama Dönemi ve Raporlama Sıklığı',
                baseYear: 'Temel Yıl',
                reportingStandarts: 'Raporlama Standartları ve Doğrulama',
                footer: 'KONSORSİYUM KURUMSAL SERA GAZI RAPORU',
                edit: {
                    general: 'Genel Bilgiler, Amaç ve Politika',
                    save: 'Kaydet',
                    intro: 'Giriş',
                    introTooltip: 'Giriş',
                    purpose: 'Amaç, Kapsam ve Hedefler',
                    purposeTooltip:
                        'Kuruluşun Sera Gazı politikaları, stratejileri veya programları ve uygulanabilir Sera Gazı programları bağlamında raporun amacı ve hedeflerini açıklayınız',
                    organizationDescription: 'Organizasyon Tanımı',
                    organizationDescriptionTooltip: 'Raporlama yapan kuruluşun tanımını yapınız',
                    ghgPolicy: 'Sera Gazı Yönetim Politikası, Stratejileri ve Programları',
                    ghgPolicyTooltip:
                        'Sera gazı emisyonu ve sürdürülebilirlik politikaları ve bununla ilgili yapılabilecek çalışmaları, stratejileri ve programları açıklayınız',
                    responsibilities: 'Sorumluluklar',
                    responsibilitiesTooltip:
                        'Sera Gazı Raporunun hazırlanması için gerekli olan faaliyet verilerinin toplatılmasından, çalışmaların koordine edilmesinden ve hazırlanan raporun kontrol edilmesinden sorumlu personellerin, firmadaki görevleri ve iletişim bilgileri içerecek şekilde açıklayınız',
                    targetAudience: 'Hedef Kitle ve Yaygınlaştırma Politikası',
                    targetAudienceTooltip:
                        'Sera Gazı Raporunun hedef kitlesini ve yayılma yöntemlerine ilişkin politikalarını açıklayınız; Hedef kullanıcı, müşteri, sorumlu taraf, kuruluşun kendisi, GHG programı yöneticileri, düzenleyiciler, finans topluluğu veya etkilenen diğer ilgili taraflar olabilir. yerel topluluklar, devlet daireleri, genel kamu veya sivil toplum kuruluşları olabilir',
                    reportingPeriod: 'Raporlama Dönemi ve Raporlama Sıklığı',
                    reportingPeriodTooltip: 'Raporlama dönemini ve raporlama sıklığını belirtiniz',
                    baseYear: 'Temel Yıl',
                    baseYearTooltip:
                        'Kuruluş, karşılaştırmalı amaçlarla, Sera gazı programı gerekliliklerini veya Sera gazı envanterinin diğer amaçlanan kullanımlarını karşılamak için Sera gazı emisyonları ve giderimlerine tarihsel bir temel yıl oluşturmalıdır. Seçilen temel yılı ve gerekçelerini açıklayınız; Genellikle Temel Yıl olarak tesisler Karbon ayak izi hesaplamalarını yaptıkları İzleme Yılını seçerler',
                    reportingStandarts: 'Raporlama Standartları ve Doğrulama',
                    reportingStandartsTooltip: 'Raporun, hangi standarda göre hazırlandığını belirtiniz',
                },
            },
            organization: {
                organization: 'Organizasyonel Sınırlar',
                update: 'Güncelle',
                title: 'Organizasyonel Sınırlar',
                episode2: 'Bölüm 2',
                consolidationApproach: 'Konsolidasyon Yaklaşımı',
                organizationChart: 'Organizasyon Şeması',
                table1Facilities: 'Tablo: 1. Tesisler',
                no: 'No',
                facilityName: 'Tesis Adı',
                email: 'E-mail',
                address: 'Adres',
                footer: 'KONSORSİYUM KURUMSAL SERA GAZI RAPORU',
                edit: {
                    organization: 'Organizasyonel Sınırlar',
                    save: 'Kaydet',
                    selectAppraches: 'Kontrol Yaklaşımı', //Consolidation based on control
                    headerDescription:
                        'Organizasyonel sınırlarınızı tanımlayın. Organizasyon bir veya daha fazla tesisi içerebilir. Organizasyonunuzun, tesis düzeyindeki sera gazı emisyonlarını ve giderimlerini aşağıdaki yaklaşımlardan biriyle konsolide etmelisiniz. Sera gazı envanterinizin kullanım amacı ile tutarlı Konsolidasyon yaklaşımını belirleyiniz:',
                    control: 'Kontrol',
                    controlDescription:
                        'Kuruluş, üzerinde mali veya operasyonel kontrole sahip olduğu tesislerden tüm sera gazı emisyonlarını ve/veya uzaklaştırmalarını hesaplar ... daha fazla bilgi al',
                    equalSharing: 'Eşit Paylaşım',
                    equalSharingDescription:
                        'Kuruluş, sera gazı emisyonlarının ve/veya ilgili tesislerden yapılan uzaklaştırmaların payını hesaplar. Kuruluş ilgili tesislerdeki hissesi oranında, bu tesislere ait olan sera gazı emisyonlarından ve/veya uzaklaştırmalarından sorumludur. ...daha fazla bilgi al',
                    organizationalBoundaries: 'Organizasyonel Sınırları Belirleme',
                    purposeOfUsage:
                        'Sera gazı envanterinizin kullanım amacı ile tutarlı Konsolidasyon yaklaşımını belirleyiniz:',
                    mainReason: 'Seçtiğiniz yaklaşımın başlıca sebebini açıklayınız.',
                    willBeIncluded: 'Raporlamaya Dahil Edilecek Lokasyonları Belirleyiniz.',
                    organizationDetail: 'Firmanızın organizasyonel yapısının detaylarını açıklayınız.',
                },
            },
            reporting: {
                reporting: 'Raporlama Sınırları',
                update: 'Güncelle',
                facilityName: 'Tesis Adı',
                category: 'Kategori',
                subCategory: 'Alt Kategori',
                source: 'Kaynak',
                activityData: 'Faaliyet Verisi',
                emissions: 'Emisyonlar',
                reason: 'Dahil Edilmeme Sebebi',
                reportingLimits: 'Raporlama Sınırları',
                episode3: 'Bölüm 3',
                categories: 'Sera Gazı Emisyon Kategorileri',
                categoriesDescription:
                    'Sera gazı emisyon kaynakları ISO 14064-1:2018 standardına göre belirlenmiş ve gruplandırılmıştır. Bu metodoloji, altı emisyon kategorisini listeler',
                category1: 'Kategori 1 : Doğrudan Sera Gazı Emisyonları ve Uzaklaştırmaları',
                category2: 'Kategori 2 : Satın Alınan Enerjiden Kaynaklanan Dolaylı Sera Gazı Emisyonları',
                category3: 'Kategori 3 : Ulaşımdan kaynaklanan Dolaylı Sera Gazı Emisyonları',
                category4:
                    'Kategori 4 : Kuruluş Tarafından Kullanılan Ürünlerden Kaynaklanan Dolaylı Sera Gazı Emisyonları',
                category5: 'Kategori 5 : Kuruluşun Ürünlerinin Kullanımıyla İlişkili Dolaylı Sera Gazı Emisyonları',
                category6: 'Kategori 6 : Diğer Kaynaklardan Dolaylı Sera Gazı Emisyonları',
                materialityAssessment: 'Önemlilik Değerlendirmesi',
                includedCalculation: 'Hesaplamaya Dahil Edilen Emisyon Kaynakları',
                table2includes: 'Tablo 2. Hesaplamaya Dahil Edilen Emisyon Kaynakları',
                excludedEmission: 'Kapsam Dışı Emisyon Kaynakları',
                table3Excluded: 'Tablo 3. Kapsam Dışı Emisyon Kaynakları',
                footer: 'KONSORSİYUM KURUMSAL SERA GAZI RAPORU',
                edit: {
                    reporting: 'Raporlama Sınırları',
                    backToReport: 'Rapora Dön',
                    add: 'Ekle',
                    facilityName: 'Tesis Adı',
                    category: 'Kategori',
                    subCategory: 'Alt Kategori',
                    source: 'Kaynak',
                    activityData: 'Faaliyet Verisi',
                    emissions: 'Emisyonlar',
                    reason: 'Dahil Edilmeme Sebebi',
                    reasonPlaceholder: 'Veri Toplama Zorluğu, Emisyonların Boyutu vb.',
                    determiningTitle: 'Önemli dolaylı sera gazı emisyonlarını belirleme süreci için rehberlik',
                    defineCriteria:
                        'Sera gazı envanterin amaçlanan kullanımıyla tutarlı olarak dolaylı emisyonların önemini değerlendirmek için kriterleri tanımlayın',
                    definePurpose1: '1-Sera Gazı Envanterinin Kullanım Amacını Tanımlayın',
                    definePurposeTooltip1:
                        "'Düzenleyici veya gönüllü ifşa düzenlerini, Kamu taahhüdünü, Emisyon ticareti planlarını, Bir kuruluşun emisyonların ve / veya ortadan kaldırmaların azaltılmasına yönelik performans ve ilerleme izleme sistemini, Azaltma programlarını, Bir kuruluşun yıllık raporunu, Yatırımcıların bilgilerini, Karbon riskler veya fırsatlar ve durum tespiti raporu tanımlamasını içerebilir",
                    definePurpose2:
                        '2-Envanterin kullanım amacı ile tutarlı olarak dolaylı emisyonların önemini değerlendirmek için kriterler tanımlayın',
                    definePurposeTooltip2:
                        "'Büyüklük: Sayısal olarak önemli olduğu varsayılan dolaylı emisyonlar veya azaltmalar. Etki seviyesi: Kuruluşun emisyonları ve azaltımları izleme ve azaltma becerisine sahip olma derecesi (örneğin enerji verimliliği, eko-tasarım, müşteri katılımı, iş tanımları). Risk veya fırsat: Kuruluşun riske maruz kalmasına katkıda bulunan dolaylı emisyonlar veya uzaklaştırmalar (örn. Finansal, düzenleyici, tedarik zinciri, ürün ve müşteri, dava, itibar riskleri gibi iklimle ilgili riskler) veya iş fırsatları (örn. Yeni pazar, yeni iş modeli). Sektöre özel rehberlik: Sektöre özel rehber tarafından sağlandığı üzere, iş sektörü tarafından önemli kabul edilen sera gazı emisyonları. Dış kaynak kullanımı: Genellikle temel iş faaliyetleri olan dış kaynaklı faaliyetlerden kaynaklanan dolaylı emisyonlar ve uzaklaştırmalar. Çalışan katılımı: Çalışanları enerji kullanımını azaltmaya motive edebilecek veya iklim değişikliği konusunda ekip ruhunu birleştirebilecek dolaylı emisyonlar (örneğin enerji tasarrufu teşvikleri, araba paylaşımı, dahili karbon fiyatlandırması).",
                    definePurpose3: '3-Dolaylı emisyonları belirleyin ve değerlendirin',
                    definePurposeTooltip3:
                        'Dolaylı emisyonların her kategorisi için, dahili ve harici uzmanlar, sektöre özgü sera gazı kılavuzu, literatür incelemesi veya üçüncü taraf veritabanı gibi kaynakları kullanarak, ayrıntılı hesaplama yapmadan bir tarama adımı olarak dolaylı emisyonları tanımlayın ve değerlendirin.',
                    definePurpose4: '4-Önemli dolaylı emisyonları seçmek için kriterleri uygulayın',
                    definePurposeTooltip4:
                        'Kuruluşlar önceden tanımlanmış kriterleri uygulayarak dolaylı emisyonların ve azaltmaların önemini belirler. Çoğu durumda, kriterlerin belirli bir dolaylı emisyon veya uzaklaştırma kaynağına uygulanması, emisyonun veya ortadan kaldırmanın önemli olup olmadığının net bir şekilde belirlenmesiyle sonuçlanır.',
                    excluded: 'Hesaplamaya Dahil Edilmeyen Dolaylı Sera Gazı Emisyonları',
                    excludedList:
                        'Tanımlanmış ancak sera gazı emisyon envanterinden hariç tutulan emisyon kaynaklarını listeleyiniz.',
                    edit: 'Düzenle',
                    materialityEvaluation: 'Önemlilik Değerlendirilmesi',
                    materialityEvaluationTooltip:
                        'Yapılan önemlilik değerlendirilmesi ve tanımlanmış ancak sera gazı emisyon envanterinden hariç tutulan emisyon kaynaklarının dahil edilmeme nedenini açıklayınız.',
                    update: 'Güncelle',
                    newEmission: 'Yeni emisyon ekleyiniz.',
                    removeSure: 'Emisyonu silmek istediğinizden emin misiniz?',
                    sure: 'Emin misiniz ?',
                    cancel: 'Vazgeç',
                    save: 'Kaydet',
                    remove: 'Sil',
                },
            },
            quantified: {
                quantified: 'Hesaplanmış Sera Gazı Emisyonu Envanteri',
                update: 'Güncelle',
                title: 'Hesaplanmış Sera Gazı Emisyonu Envanteri',
                episode4: 'Bölüm 4',
                no: 'No',
                facility: 'Tesis',
                category: 'Kategori',
                subCategory: 'Alt Kategori',
                deviceType: 'Cihaz Tipi',
                gasType: 'Gaz Tipi',
                fuelType: 'Yakıt Tipi',
                kip: 'KIP',
                nkd: 'NKD',
                leakageGasRate: 'Kaçak Gaz Oranı %',
                reference: 'Referans',
                unit: 'Birim',
                density: 'Yoğunluk',
                activityData: 'Faalitet Verisi',
                formula: 'Formül',
                greenhouseGas: 'Sera Gazı',
                loaderText: 'Yükleniyor',
                footer: 'KONSORSİYUM KURUMSAL SERA GAZI RAPORU',
                title4_1: 'Sera Gazı Emisyonları Tablosu',
                title4_1_1: 'Hesaplamalarda kullanılan referanslar',
                title4_1_2: 'Sonuçlar',
                title4_2: 'Verilerin Toplanması ve Hesaplama Metodolojileri',
                title4_2_1: ' Faaliyet Verilerinin Toplanması ve Sera Gazı Kalite Yönetimi',
                title4_2_2: 'Hesaplama Metodolojileri',
                title4_2_2_1: 'Hesaplama Metodolojisinde Yapılan Değişiklikler',
                title4_2_2_2: 'Gözden Geçirme ve İyileştirmeler',
                title4_3: 'Sera Gazı Envanteri Bilgi Yönetimi',
                title4_4: 'Belirsizlik Değerlendirilmesi',
                title4_5: 'Sera Gazı Envanterinin Yeniden Hesaplanması',
                title4_6: 'Emisyon Uzaklaştırmaları ve Azalımlar/ Artışlar',
                title4_6_1: 'Emisyon Uzaklaştırmaları',
                title4_6_2: 'Emisyon Azaltımları / Artışları',
                table4Title: 'Tablo 4. Hesaplamalarda Kullanılan Soğutucu Gaz Kaçak Oranları',
                table5Title: 'Tablo 5. Hesaplamalarda Kullanılan Soğutucu Gaz KIP Değerleri',
                table6Title: 'Tablo 6. Hesaplamalarda Kullanılan NKD Değerleri',
                table7Title: 'Tablo 7. Hesaplamalarda Kullanılan Yoğunluk Değerleri',
                table8Title: 'Tablo 8. Hesaplamalarda Kullanılan Emisyon Faktörleri',
                table9Title: 'Tablo 9. Kategori Bazlı Toplam Emisyonlar',
                table10Title: 'Tablo 10. Alt Kategori Bazlı Toplam Emisyonlar',
                table11Title: 'Tablo 11. Tesis Bazlı Toplam Emisyonlar',

                // 4.2.2 description
                general: 'Hesaplamalarda genel olarak;',
                total: 'Toplam CO2e = Faaliyet Verisi x Uygun Emisyon Faktörü',
                usage: 'KIP ler için IPCC AR6 referans alınmıştır. CO2, CH4 ve N2O için aşağıdaki KIP değerleri kullanılmıştır.',
                carbon: 'Karbondioksit',
                methane: 'Metan',
                nitrogen: 'Azotoksit',
                usageTier1:
                    'Hesaplama metodunda da alternatif metotlar belirlenmiş ve ölçüm belirsizliğini en aza indirecek şekilde uygun metot seçilmiştir. Emisyon faktörü seçiminde ulusal kaynaklarının yeterli olmadığı durumlarda uluslararası faktörler (Tier 1) kullanılmıştır.',
                table4: {
                    note: 'Not',
                    total: 'Toplam(Ton)',
                    carbon: 'Karbon Dioksit (CO2)',
                    methane: 'Metan (CH4)',
                    nitrogen: 'Azot Oksit (N2O)',
                    hydrofluorocarbon: 'Hidrofloro Karbon (HFC)',
                    perfluorocarbons: 'Perfloro Karbonlar (PFC)',
                    sulfurHexafluoride: 'Kükürt Heksaflorür (SF6)',
                    nitrogenTrifluoride: 'Azot Triflorür (NF3)',
                    quantitativeUncertainty: 'Nicel Belirsizlik',
                    qualitativeUncertainty: 'Nitel Belirsizlik',
                    greenhouseGas: 'SERA GAZI EMİSYONLARI',
                },
                categoriesTable: {
                    category1: 'Kategori 1: Doğrudan Sera Gazı Emisyonları ve Uzaklaştırmaları',
                    subCategory1_1: 'Sabit yanmadan kaynaklanan doğrudan emisyonlar',
                    subCategory1_2: 'Hareketli yanmadan kaynaklanan doğrudan emisyonlar',
                    subCategory1_3:
                        'Endüstriyel proseslerden kaynaklanan doğrudan proses emisyonları ve uzaklaştırmaları',
                    subCategory1_4:
                        'Antropojenik sistemlerde sera gazı salımından kaynaklanan doğrudan kaçak emisyonlar',
                    subCategory1_5:
                        'Arazi Kullanımı, Arazi Kullanım Değişikliği ve Ormancılıktan kaynaklanan doğrudan emisyonlar',
                    subCategory1_6: 'Biokütleden kaynaklanan doğrudan emisyonlar',
                    category2: 'Kategori 2: Satın alınan enerjiden kaynaklanan dolaylı sera gazı emisyonları',
                    subCategory2_1: 'Satın alınan elektrikten kaynaklanan dolaylı emisyonlar',
                    subCategory2_2: 'Satın alınan enerjiden kaynaklanan dolaylı emisyonlar',
                    category3: 'Kategori 3: Taşımadan kaynaklanan dolaylı sera gazı emisyonları',
                    subCategory3_1: 'Yukarı yönlü nakliye ve mal dağıtımından kaynaklanan emisyonlar',
                    subCategory3_2: 'Aşağı yönlü nakliye ve mal dağıtımından kaynaklanan emisyonlar',
                    subCategory3_3: 'Çalışan ulaşımından kaynaklanan emisyonlar',
                    subCategory3_4: 'Müşteri ve ziyaretçi ulaşımından kaynaklanan emisyonlar',
                    subCategory3_5: 'İş seyehatlerinden kaynaklanan emisyonlar',
                    category4:
                        'Kategori 4: Kuruluş tarafından kullanılan ürünlerden kaynaklanan dolaylı sera gazı emisyonları',
                    subCategory4_1: 'Satın alınan ürünlerden kaynaklanan emisyonlar',
                    subCategory4_2: 'Sermaye mallardan kaynaklanan emisyonlar',
                    subCategory4_3: 'Katı ve sıvı atıkların bertarafından kaynaklanan emisyonlar',
                    subCategory4_4: 'Yukarı yönlü kiralanan varlıklardan kaynaklanan emisyonlar',
                    subCategory4_5:
                        'Yukarıdaki alt kategorilerde açıklanmayan hizmetlerin (danışmanlık, temizlik, bakım, posta teslimi, banka vb.) kullanımından kaynaklanan emisyonlar',
                    category5:
                        'Kategori 5: Kuruluşun ürettiği ürünlerin kullanımından kaynaklanan dolaylı sera gazı emisyonları',
                    subCategory5_1: 'Ürünün kullanım aşamasından kaynaklanan emisyonlar veya uzaklaştırmalar',
                    subCategory5_2: 'Aşağı yönlü kiralanan varlıklardan kaynaklanan emisyonlar',
                    subCategory5_3: 'Ürünün kullanım ömrünün sonundan kaynaklanan emisyonlar',
                    subCategory5_4: 'Yatırımlardan kaynaklanan emisyonlar',
                    category6: 'Kategori 6: Diğer kaynaklardan kaynaklanan dolaylı sera gazı emisyonları',
                    subCategory6_1: 'Diğer',
                    removals: 'UZAKLAŞTIRMALAR',
                    directRemovals: 'Doğrudan uzaklaştırmalar',
                    storage: 'DEPOLAMA',
                    totalStorage: 'Toplam Depolama',
                    financialInstruments: 'KARBON FİNANSAL ARAÇLARI',
                    otherInfos: 'DİĞER BİLGİLER',
                    details: 'Detaylar',
                    notes: 'NOTLAR',
                    back: 'Geri',
                },
                edit: {
                    quantified: 'Hesaplanmış Sera Gazı Emisyonu Envanteri',
                    update: 'Güncelle',
                    title4_2_1: 'Faaliyet Verilerinin Toplanması ve Sera Gazı Kalite Yönetimi',
                    title4_2_1Tooltip: 'Faaliyet Verilerinin Toplanması ve Sera Gazı Kalite Yönetimi',
                    title4_2_2: 'Hesaplama Metodolojileri',
                    title4_2_2Tooltip: 'Hesaplama Metodolojileri',
                    title4_2_3: 'Hesaplama Metodolojisinde Yapılan Değişiklikler',
                    title4_2_3Tooltip: 'Hesaplama Metodolojisinde Yapılan Değişiklikler',
                    title4_2_4: 'Gözden Geçirme ve İyileştirmeler',
                    title4_2_4Tooltip: 'Gözden Geçirme ve İyileştirmeler',
                    title4_3: 'Sera Gazı Envanteri Bilgi Yönetimi',
                    title4_3Tooltip: 'Sera Gazı Envanteri Bilgi Yönetimi',
                    title4_4: 'Belirsizlik Değerlendirilmesi',
                    title4_4Tooltip: 'Belirsizlik Değerlendirilmesi',
                    title4_5: 'Sera Gazı Envanterinin Yeniden Hesaplanması',
                    title4_5Tooltip: 'Sera Gazı Envanterinin Yeniden Hesaplanması',
                    title4_6_1: 'Emisyon Uzaklaştırmaları',
                    title4_6_1Tooltip: 'Emisyon Uzaklaştırmaları',
                    title4_6_2: 'Emisyon Azaltımları / Artışları',
                    title4_6_2Tooltip: 'Emisyon Azaltımları / Artışları',
                },
                detailDialog: {
                    inputs: 'Girdiler',
                    database: 'Veritabanı',
                    activity: 'Aktivite',
                    month: 'Ay',
                    year: 'Yıl',
                    results: 'Sonuçlar',
                    addDocument: 'Döküman Ekle',
                    total: 'Toplam',
                    reports: 'Raporlar',
                    cancel: 'Vazgeç',
                    maxAllowedSize: "Dosya 1 MB'tan büyük olmamalıdır",
                },
            },
            internal: {
                internal: 'Envanter Raporu İç Performans Takibi',
                update: 'Güncelle',
                internalPerformance: 'Envanter Raporu İç Performans Takibi',
                episode5: 'Bölüm 5',
                categoryBased: 'Kategorilere Göre Sera Gazı Emisyonları',
                subCategoryBased: 'Alt Kategorilere Göre Sera Gazı Emisyonları',
                performanceEvaluation: 'Performans Değerlendirmesi',
                footer: 'KONSORSİYUM KURUMSAL SERA GAZI RAPORU',
                figure1: 'Şekil 1. Kategorilere Göre Sera Gazı Emisyonları Grafiği',
                figure2: 'Şekil 2. Kategori 1 Sera Gazı Emisyonları Grafiği',
                figure3: 'Şekil 3. Kategori 2 Sera Gazı Emisyonları Grafiği',
                figure4: 'Şekil 4. Kategori 3 Sera Gazı Emisyonları Grafiği',
                figure5: 'Şekil 5. Kategori 4 Sera Gazı Emisyonları Grafiği',
                figure6: 'Şekil 6. Kategori 5 Sera Gazı Emisyonları Grafiği',
                figure7: 'Şekil 7. Kategori 6 Sera Gazı Emisyonları Grafiği',
                edit: {
                    internal: 'Envanter Raporu İç Performans Takibi',
                    backToReport: 'Rapora Dön',
                    performanceEvaluation: '5.3 Performans Değerlendirmesi',
                    performanceEvaluationInput: 'Performans Değerlendirmesi',
                },
            },
            appendices: {
                appendices: 'Ekler',
                update: 'Güncelle',
                title: 'Ekler',
                reportingIndex: 'EK 1 - ISO 14064-1:2018 Raporlama İndeksi',
                isoReporting: 'ISO Raporlama',
                section: 'Rapordaki Bölüm',
                no: 'No',
                document: 'Belge',
                documentName: 'Belge Adı',
                download: 'İndir',
                footer: 'KONSORSİYUM KURUMSAL SERA GAZI RAPORU',
                edit: {
                    appendices: 'Ekler',
                    backToReport: 'Rapora Dön',
                    fileName: 'Dosya Adı',
                    complete: 'Tamamla',
                    cancel: 'Vazgeç',
                    addDocument: 'Belge Ekle',
                    no: 'No',
                    document: 'Belge',
                    documentName: 'Belge Adı',
                    action: 'Aksiyon',
                    willBeRemove: 'Raporu silmek istediğinizden emin misiniz?',
                    sure: 'Emin misiniz?',
                    remove: 'Sil',
                    maxAllowedSize: "Dosya 1 MB'tan büyük olmamalıdır",
                },
            },
            module: {
                title: 'Raporlama',
                description:
                    ' ISO 14064-1 Standartlarına uygun rapor çıktısı oluşturabilmek için Şirket seviyesinde olmanız gerekmektedir.',
            },
        },
        kpi: {
            createProject: 'Proje Oluştur',
            addKPI: 'KPI Oluştur',
            dashboard: {
                dashboard: 'KPI Dashboard',
                filter: 'Filtrele',
                addProject: 'Proje Ekle',
                addCriterion: 'Kriter Ekle',
            },
            list: {
                backlog: 'Bekleyen',
                notStarted: 'Başlamadı',
                inProgress: 'Devam Ediyor',
                completed: 'Tamamlandı',
                backlogProgress: 'Bekleyen',
                notStartedProgress: 'Başlamadı',
                inProgressProgress: 'Devam Ediyor',
                completedProgress: 'Tamamlandı',
                id: 'id',
                title: 'Title',
                status: 'Status',
                module: 'Module',
                situation: 'Durum',
            },
            dropList: {
                backlogKey: 'BACKLOG',
                notStartedKey: 'NOT_STARTED',
                inProgressKey: 'IN_PROGRESS',
                completedKey: 'COMPLETED',
                backlogTitle: 'Bekleyen',
                notStartedTitle: 'Başlamadı',
                inProgressTitle: 'Devam Ediyor',
                completedTitle: 'Tamamlandı',
            },
            detailKpi: {
                detailKpi: 'KPI Detayı',
                addAction: 'Aksiyon Ekle',
                kpiRemoveButton: 'KPI Sil',
                kpiRemove: "KPI'yı silmek üzeresiniz.",
                kpiRemoveSure: "Seçilen KPI'yı silmek istediğinize emin misiniz?",
                cancel: 'Vazgeç',
                remove: 'Sil',
            },
            kpiDetailBase: {
                kpiName: 'KPI Adı',
                kpiNamePlaceholder: 'KPI Adı',
                kpiDescription: 'KPI Açıklama',
                kpiDescriptionPlaceholder: 'KPI Açıklama',
                startDate: 'Başlangıç Tarihi',
                startDatePlaaceholder: 'Başlangıç Tarihi',
                finishDate: 'Bitiş Tarihi',
                finishDatePlaaceholder: 'Bitiş Tarihi',
                startAmount: 'Başlangıç Değeri',
                startAmountPlaceholder: 'Başlangıç Değeri',
                goalAmount: 'Hedef Değeri',
                goalAmountPlaceholder: 'Hedef Değeri',
                update: 'Güncelle',
            },
            actionList: {
                kpisAction: 'KPI Ait Aksiyonlar',
                kpiManagement: "KPI'larınızı yönetebileceğiniz ve alt görevler belirleyebileceğiniz kısım.",
                actionNotYet: 'Henüz bir Aksiyon eklemediniz',
                addAction: 'Aksiyon Ekle',
                actionTitle: 'Aksyion Başlık',
                actionDescription: 'Açıklama',
                actionProcess: 'İşlem',
                actionState: 'Aksiyon Durumu',
                willBeRemoveAction: 'Aksiyonu silmek üzeresiniz.',
                sure: 'Seçilen aksiyonu silmek istediğinize emin misiniz?',
                cancel: 'Vazgeç',
                remove: 'Sil',
            },
            actionUpdateDialog: {
                updateAction: 'Aksiyonu Güncelle',
                actionName: 'Aksiyon Adı',
                actionNamePlaceholder: 'Aksiyon Adı',
                finishDate: 'Bitiş Tarihi',
                finishDatePlaaceholder: 'Bitiş Tarihi',
                actionDescription: 'Açıklama',
                actionDescriptionPlaceholder: 'Açıklama',
                howGoing: 'Nasıl İlerliyorsun ?',
                cancel: 'Vazgeç',
                update: 'Güncelle',
            },
            actionAddDialog: {
                addAction: 'Aksiyon Ekle',
                actionName: 'Aksiyon Adı',
                actionNamePlaceholder: 'Aksiyon Adı',
                finishDate: 'Bitiş Tarihi',
                finishDatePlaaceholder: 'Bitiş Tarihi',
                actionDescription: 'Açıklama',
                actionDescriptionPlaceholder: 'Açıklama',
                howGoing: 'Nasıl İlerliyorsun ?',
                cancel: 'Vazgeç',
                add: 'Ekle',
            },
            kpiInputs: {
                kpiName: 'KPI Adı',
                kpiNamePlaceholder: 'KPI Adı',
                kpiDescription: 'KPI Açıklama',
                kpiDescriptionPlaceholder: 'KPI Açıklama',
                startDate: 'Başlangıç Tarihi',
                startDatePlaaceholder: 'Başlangıç Tarihi',
                finishDate: 'Bitiş Tarihi',
                finishDatePlaaceholder: 'Bitiş Tarihi',
                startAmount: 'Başlangıç Değeri',
                startAmountPlaceholder: 'Başlangıç Değeri',
                goalAmount: 'Hedef Değeri',
                goalAmountPlaceholder: 'Hedef Değeri',
                back: 'Geri',
                create: 'Oluştur',
            },
            detailProject: {
                backlog: 'Bekleyen',
                notStarted: 'Başlamadı',
                inProgress: 'Devam Ediyor',
                completed: 'Tamamlandı',
                projectDetail: 'Proje Detayı',
                status: 'Statü',
                removeProject: 'Projeyi Sil',
                addKpi: 'Kpi Ekle',
                willBeRemove: 'Projeyi silmek üzeresiniz.',
                sure: 'Seçilen projeyi silmek istediğinize emin misiniz?',
                cancel: 'Vazgeç',
                remove: 'Sil',
            },
            kpiList: {
                projectsKpi: 'Projeye Ait KPIs',
                projectInfo: 'Projenizi gruplayarak dilediğiniz kadar KPI oluşturabilirsiniz.',
                kpiNotYet: 'Henüz bir KPI eklemediniz.',
                addKpi: 'Kpi Ekle',
                title: 'Başlık',
                description: 'Açıklama',
                beginning: 'Başlangıç',
                target: 'Hedef',
                kpiStatus: 'Kpi Durumu',
                willBeRemove: "KPI'yı silmek üzeresiniz.",
                sure: "Seçilen KPI'yı silmek istediğinize emin misiniz?",
                cancel: 'Vazgeç',
                remove: 'Sil',
            },
            projectBaseDetail: {
                projectName: 'Proje Adı',
                projectNamePlaceholder: 'Proje Adı',
                category: 'Kategori',
                categoryPlaceholder: 'Kategori',
                description: 'Açıklama',
                descriptionPlaceholder: 'Açıklama',
                baseYear: 'Baz Yıl',
                baseYearPlaceholder: 'Baz Yıl',
                targetYear: 'Hedef Yıl',
                targetYearPlaceholder: 'Hedef Yıl',
                update: 'Güncelle',
            },
            selectGoals: {
                title: " Desteklediğiniz önemli gelişmeleri görebilmek için bu projeyle ilgili SDG'leri ve SBTi kriterlerini seçebilirsiniz. Kriter eşleştirirken 2'den fazla seçilmemesini öneriyoruz.",
                projectsCriterion: 'Projeye Ait Kriterler',
                criterionNotYet: 'Henüz kriter eklemediniz..',
                sdg: 'Sürdürülebilir Kalkınma Amaçları',
                SBTICriteria: 'SBTİ Kriterleri',
                back: 'Geri',
                create: 'Oluştur',
                criterion: 'Kriter',
            },
            projectInputs: {
                projectName: 'Proje Adı',
                projectNamePlaceholder: 'Proje Adı',
                category: 'Kategori',
                categoryPlaceholder: 'Kategori',
                description: 'Açıklama',
                descriptionPlaceholder: 'Proje Açıklama',
                baseYear: 'Baz Yıl',
                baseYearPlaceholder: 'Baz Yıl',
                targetYear: 'Hedef Yıl',
                targetYearPlaceholder: 'Hedef Yıl',
                back: 'Geri',
                next: 'İleri',
            },
        },
        guide: {
            guide: 'Kılavuz',
            step1: {
                title: 'Başlangıç',
                subtitle: 'İklim yolculuğunda bilmeniz gereken her şeyi keşfedin',
                beginning: 'Başlangıç',
                overview: 'Genel Bakış',
                listItem1:
                    'Yardım içeriğinin bu bölümü, iklim yolculuğunuza başlamak için ihtiyaç duyduğunuz tüm karbon bilgisini anlamanıza yardımcı olacaktır.',
                listItem2:
                    'Size GHG protokolü ve ISO 14064-1:2018 karbon yönetimi standartları hakkında bilgi vereceğiz.',
            },
            step2: {
                title: 'Sera Gazı Emisyonları ve Karbondioksit Eşdeğeri',
                subtitle: 'İklim Değişikliği ve Karbon Ayakizi kısa açıklamaları',
                equivalent: 'Sera Gazı Emisyonları ve Karbondioksit Eşdeğeri',
                humanActivity:
                    'İnsan faaliyetleri, atmosferdeki ısıyı hapseden ve iklim değişikliğine katkıda bulunan sera gazı (GHG) emisyonuna yol açar.',
                atmosphere:
                    "Atmosferimizde biriken sera gazları nedeniyle Dünya'nın sıcaklığı sanayi öncesi döneme (1880-1900) göre 1,2°C artmıştır.",
                global: 'Sera gazı salmaya devam ettikçe küresel sıcaklık artmaya devam edecek. İklim değişikliğinin olumsuz etkilerini en aza indirmek için küresel ısınmayı mümkün olduğunca sınırlamaya çalışmalıyız.',
                globalWarming: 'Küresel ısınma potansiyeli',
                potent: 'Bazı sera gazları diğerlerinden daha güçlüdür, yani ısıyı hapsetmede daha etkilidirler.',
                amountOfWarming:
                    'Küresel Isınma Potansiyeli (GWP), belirli bir süre boyunca (genellikle 100 yıl) bir gazın neden olduğu ısınma miktarını gösterir.',
                gwpEqual:
                    "Temel olarak karbondioksit seçilmiştir ve bu nedenle GWP'si 1'dir. Diğer tüm gazların GWP'si, o gazın CO2'ye kıyasla ne kadar güçlü olduğuna eşittir.",
                co2eTitle: 'Karbon dioksit eşdeğeri (CO2e)',
                co2eSub1:
                    'Karbon dioksit eşdeğerliği (CO2e), tüm sera gazlarını tek bir birimde toplamak için kullanılır.',
                co2eSub2:
                    'CO2e, bu gazla aynı küresel ısınma etkisine sahip olacak CO2 miktarını ifade eder. Dönüşüm, gaz miktarının GWP ile çarpılmasıyla yapılır.',
                co2eSub3: 'Örneğin:',
                co2eSub4: "1kg CH4 salınırsa, bu 25kg CO2e'ye eşittir (1kg CH4 x 25 = 25 kgCO2e).",
                co2eSub5:
                    "Yayılan her gaz CO2 eşdeğerine dönüştürülür. Bunlar daha sonra CO2e'deki bir aktivite ile ilişkili toplam sera gazı emisyonlarını vermek için toplanır.",
                footPrintTitle: 'Karbon ayak izinizi hesaplama',
                footPrintSub1:
                    'Bir karbon ayak izini hesaplamak için çeşitli metodolojiler mevcuttur (UNE-ISO 14064, GHG Protokolü, Bilan Carbone, vb.). Hepsi aynı formüle dayanmaktadır:',
                footPrintSub2: 'İlgili emisyon faktörleriyle çarpılan tüm aktivite verilerinin toplamı.',
                activityDataTitle: 'Faaliyet Verileri',
                activityDataSub1: 'Faaliyet verileri, sera gazı (GHG) yayan bir etkinliğin nicel bir ölçümüdür.',
                activityDataSub2: 'Örneğin: Isınma için kullanılan gaz miktarı veya bir uçağın kat ettiği mesafe.',
                emissionFactorTitle: 'Emisyon Faktörü',
                emissionFactorSub1: 'Bir emisyon faktörü, faaliyet verilerinin her birimiyle ilişkili emisyonlardır.',
                emissionFactorSub2: 'Örneğin: Elektrik, Fransa ortalama karışım = 0,0791 kgCO2e/kWh',
                emissionFactorSub3: 'Emisyon faktörleri hakkında daha fazla bilgi edinin',
                emissionFactorSub4:
                    'Faaliyet verilerinin ve bir emisyon faktörünün ürünü, o faaliyet tarafından salınan emisyon miktarıdır.',
                methodologies: 'Metodolojiler',
                methodologiesSub1:
                    'Bilmeniz gereken ana metodoloji, karbon muhasebesi ve raporlaması için küresel bir standart olan GHG Protokolüdür.',
                methodologiesSub2:
                    'Kurumsal emisyonlarınızı üç kapsama ayırır: şirketinizin kontrol ettiği kaynaklardan doğrudan emisyonlar, satın alınan enerjiden dolaylı emisyonlar ve yukarı ve aşağı emisyonlar.',
                methodologiesSub3:
                    'Kapsam 3 emisyonları genellikle karbon ayak izinizin en büyük kısmıdır ve aynı zamanda ölçülmesi en zor kısımdır.',
            },
            step3: {
                title: 'Nasıl Hesaplıyoruz ?',
                subtitle: '',
                how: 'Nasıl Hesaplıyoruz ?',
                sub1: 'Ecofoni ile sera gazı emisyonlarınızı şu denklemi kullanarak hesaplıyoruz:',
                sub2: 'Faaliyet verileri x Emisyon faktörü = Söz konusu faaliyetten kaynaklanan emisyonlar (CO2e cinsinden)',
                sub3: 'Faaliyet verileri: Emisyon üreten faaliyet birimi. Bu, satın aldığınız malzemenin kilogramı veya bir ofiste kullanılan kilovat saat (KWh) elektrik gibi bir şey olabilir.',
                sub4: 'Emisyon faktörü: Bir faaliyet veya süreç tarafından ne kadar CO2e üretildiğini ölçmenin bir yolu, örneğin üretilen her bir kg malzeme için yayılan kg CO2e. Ecofoni de devasa bir emisyon faktörleri veri tabanı var.',
                sub5: 'Emisyon faktörleri hakkında daha fazla bilgi edinin',
                sub6: 'Karbon dioksit eşdeğeri (CO2e): Sera gazı emisyonlarını ifade etmek için standart bir birim. Farklı sera gazlarını küresel ısınma potansiyelleri ile (GWP) dönüştürür ve bunları tek bir eşdeğer olarak ifade eder.',
                sub7: 'Ayrıca, Sabit Yanma kategorisi için NKD ve Yoğunluk değerleri de bu formül ile çarpılarak hesaplanmaktadır.',
            },
            step4: {
                title: 'Emisyon Faktörleri',
                subtitle: '',
                emissionFactors: 'Emisyon Faktörleri',
                measures:
                    'Bir emisyon faktörü, bir faaliyet tarafından ne kadar sera gazı salındığını ölçen bir değerdir.',
                show: 'Emisyonları hesaplamak için bir emisyon faktörü ve faaliyet verilerini kullanırız. Emisyonları, farklı sera gazlarının ısınma potansiyelini tek bir rakama dönüştüren standart bir birim olan CO2 eşdeğeri (CO2e) cinsinden gösteriyoruz.',
                calculation: 'Emisyonları şu denklemle hesaplıyoruz:',
                activityXEmission: 'Faaliyet verileri x Emisyon faktörü = CO2e cinsinden emisyonlar',
                example: 'Örneğin:',
                exampleContent:
                    "Kısa mesafeli bir uçuş için emisyon faktörü 0,27kgCO2e/yolcu.km'dir . Bu, uçulan her km için yolcu başına 0,27 kg CO2e salındığı anlamına gelir. 2000 km'lik bir uçuşta 1 kişi için emisyonlar:",
                database:
                    'Ecofoni, geniş bir veri tabanına sahiptir. Ancak, kendi veritabanınızı da kullanarak hesaplamalar yapabilirsiniz.',
                emissonFactorTypes: 'Emisyon faktörü türleri',
                emissonFactorTypesDescription: 'Öğrenmek için birkaç temel emisyon faktörü türü vardır:',
                emissonFactorTypes1: 'Karbon salınımı',
                emissonFactorTypes2: 'Önlenen emisyonlar',
                emissonFactorTypes3: 'Negatif emisyonlar',
                carbonEmissions: 'Karbon Salınımı',
                carbonEmissionsSub1:
                    'Karbon emisyon faktörleri, sera gazı yayan faaliyetler içindir (örneğin, uçakla gidilen km başına kgCO2e). Kullandığınız en yaygın emisyon faktörü türü olmaları muhtemeldir.',
                compositeEmission: 'Bileşik emisyon faktörleri',
                compositeEmissionSub1:
                    'Bileşik bir emisyon faktörü, birden çok mevcut karbon emisyon faktörünün bir araya getirilmesiyle oluşturulan bir karbon emisyon faktörüdür.',
                compositeEmissionSub2:
                    'Bir ürünün yapısında kullanılan tüm malzemeler için emisyon faktörlerini kullanarak, bir ürünün yapısı için bir bileşik emisyon faktörü yaratırsınız.',
                avoidedEmissions: 'Önlenen Emisyonlar',
                avoidedEmissionsSub1:
                    'Kaçınılan emisyon faktörleri, düşük karbonlu ürünler gibi şirketinizin dışındaki kişilerin emisyonlarını düşürmelerine yardımcı olan ürünler içindir.',
                avoidedEmissionsSub2:
                    'Yeni ürününüzü her zaman temel bir rakamla karşılaştırmanız gerekir. Temel değer, yeni ürününüzün yerini aldığı alternatif bir üründen kaynaklanan emisyon miktarıdır. Bu basit bir toplamda yapılır:',
                avoidedEmissionsSub3: 'Temel emisyon faktörü - Yeni emisyon faktörü = Kaçınılan emisyonlar',
                avoidedEmissionsSub4: 'Örneğin:',
                avoidedEmissionsSub5:
                    'Diyelim ki benzinli bir arabayı ömrünün sonunda elektrikli bir araba ile değiştirdiniz. Elektrikli araba, benzinli arabadan daha az emisyon üretir, bu nedenle bazı emisyonlardan kaçınmış olursunuz. Kabaca bir tahmin için karbon emisyon faktörü 0,4kgCO2e/km olan benzinli bir araba kullanacağız. Ve emisyon faktörü 0,1kgCO2e/km olan elektrikli bir araba.',
                avoidedEmissionsSub6:
                    "Yani elektrikli otomobilinizin kaçınılan emisyon faktörü 0,3kgCO2e/km'dir. Bu, eski benzinli arabayı kullanmaya kıyasla kat edilen her km için 0,3kgCO2e emisyondan kaçındığınız anlamına gelir.",
                negativeEmissions: 'Negatif Emisyonlar',
                negativeEmissionsSub1:
                    'Negatif emisyonlar, sera gazını atmosferden uzaklaştıran ürünler veya uygulamalar için kullanılır. Bu, Doğrudan Hava Yakalama ve Depolama (DACS) gibi teknik çözümleri ve ormanlar ve sulak alanlar gibi doğal karbon yutaklarını geliştirmeyi içerir.',
                negativeEmissionsSub2:
                    'Negatif emisyonlar için diğer yaygın terimler, Karbon Dioksit Giderimi (CDR) ve Sera Gazı Giderimidir (GGR).',
                physicalOrMonetary: 'Fiziksel mi yoksa parasal emisyon faktörleri mi?',
                physicalOrMonetarySub1:
                    'Kullandığınız birime veya emisyonlarınız için elinizde bulunan verilere bağlı olarak, fiziksel veya parasal bir emisyon faktörü arasında karar vermeniz gerekir.',
                physicalFactors: 'Fiziksel Emisyon Faktörleri',
                physicalFactorsSub1:
                    'Fiziksel emisyon faktörleri, emisyonlarınızı hesaplamak için uçakla katedilen km veya kullanılan kWh elektrik gibi aktivite verilerini kullanır.',
                physicalFactorsSub2:
                    'Genellikle parasal emisyon faktörlerinden çok daha doğrudurlar. Daha büyük emisyon kaynaklarınız için fiziksel emisyon faktörlerini kullanmak özellikle önemlidir.',
                monetaryFactors: 'Parasal Emisyon Faktörleri',
                monetaryFactorsSub1:
                    "Parasal emisyon faktörleri, harcamaya dayalı olarak emisyonlarınızın kabaca bir tahminini verir (örneğin, satın alınan elektriğin €'su).",
                monetaryFactorsSub2:
                    'Ölçümleriniz için iyi bir ilk adımdır, ancak genellikle fiziksel emisyon faktörleri kadar kesin değildirler ve hatalı tahminlere yol açabilirler. Örneğin, karbon ayak izi tahmininiz, eğer daha pahalıysa, daha yeşil ekipmanlara yatırım yaptıktan sonra artabilir.',
            },
            step5: {
                title: 'Kapsam ve Kategori Bazlı Hesaplamalar',
                subtitle: '',
                scopeBased: 'Kapsam ve Kategori Bazlı Hesaplamalar',
                intro: 'GHG Protokolü 3 kapsamda ve ISO 14064-1:2018 Standartlarında 22 emisyon kategorisi ile hesaplama yapılmaktadır.',
                scope1: 'Kapsam 1',
                scope1Sub1:
                    'Kapsam 1, doğrudan emisyonlarınızı kapsar. Bu, yerinde enerji üretiminiz, endüstriyel prosesleriniz, arazi kullanımınız veya kendi araçlarınız varsa geçerlidir.',
                scope1Sub2:
                    'Bu faaliyet verileri, dağıtım sırasındaki kayıpların yanı sıra çıkarma, üretim ve nakliyeden kaynaklanan kapsam 3 emisyonlarınız için de geçerli olabilir.',
                combustionOfStatic: 'Yakıtların şirkete ait/kontrol edilen sabit kaynaklarda yakılması',
                combustionOfStaticSub1:
                    'Kazanlar, fırınlar ve türbinler gibi şirkete ait veya kontrol edilen kaynaklarda organik veya fosil yakıtların yakılmasından kaynaklanan emisyonlar.',
                combustionOfMobile: 'Şirkete ait/kontrol edilen mobil kaynaklarda yakıtların yakılması',
                combustionOfMobileSub1:
                    'Kamyonlar, trenler, gemiler, uçaklar, otobüsler ve arabalar gibi şirket için insan ve malzeme taşımakta kullanılan, şirkete ait veya kontrol edilen araçlarda yanan yakıtlardan kaynaklanan emisyonlar.',
                combustionOfMobileSub2:
                    'Bu, Yakıt ve enerji ile ilgili emisyonlar (kapsam 3) kapsamında ele alınması gereken, araçlarınız için başka bir şirketten satın alınan yakıtı içermez.',
                physicalOrChemical: 'Fiziksel veya kimyasal işleme',
                physicalOrChemicalSub1:
                    'Şirketinizin sahip olduğu ve işlettiği kimyasallar ve malzemelerin (örn. çimento, alüminyum) ve atık tesislerinin yapımından veya işlenmesinden kaynaklanan emisyonlar.',
                equipmentLeaks: 'Ekipman sızıntıları (kaçak emisyonlar)',
                equipmentLeaksSub1: 'Kasıtlı veya kasıtsız salınımlardan kaynaklanan emisyonlar, aşağıdakiler dahil:',
                equipmentLeaksSub2: 'Eklemlerden, contalardan, salmastralardan ve contalardan ekipman sızıntıları',
                equipmentLeaksSub3: 'Kömür madenlerinden ve menfezlerden kaynaklanan metan emisyonları',
                equipmentLeaksSub4:
                    'Soğutma ve iklimlendirme ekipmanından kaynaklanan hidrofloro-karbon (HFC) emisyonları',
                equipmentLeaksSub5: 'Gaz nakliyesinden metan sızıntısı',
                landUse: 'Arazi Kullanımı, Arazi Kullanımı Değişikliği ve Ormancılık emisyonları',
                landUseSub1:
                    'Arazi kullanımı, arazi kullanım değişikliği ve ormancılıktan (LULUCF) kaynaklanan doğrudan emisyonlar.',
                scope2: 'Kapsam 2',
                scope2Sub1:
                    'Kapsam 2, kendi üretmediğiniz elektrik, gaz veya diğer enerji dahil olmak üzere şirketinizin satın aldığı enerji için emisyonları kapsar.',
                scope2Sub2:
                    'Not: Bu faaliyet verileri muhtemelen Kapsam 3 - Yakıt ve enerji ile ilgili emisyonlar için de geçerli olacaktır , çünkü satın aldığınız enerji çıkarma, üretim ve nakliyeden kaynaklanan yukarı yönlü emisyonların yanı sıra iletim ve dağıtım sırasındaki kayıplara neden olur',
                purchasedElectricity: 'Satın alınan elektrik',
                purchasedElectricitySub1:
                    'Şirket ekipmanınıza güç sağlamak için üretilen elektrikten kaynaklanan emisyonlar.',
                purchasedElectricitySub2:
                    'Not: Sera Gazı Protokolü, bunu hem konuma dayalı (yani şebekenin ortalama emisyon yoğunluğu) hem de pazara dayalı (yani satın alınan enerjiden kaynaklanan emisyonlar) açısından raporlamanızı gerektirir.',
                purchasedSteam: 'Satın alınan buhar, ısıtma ve soğutma',
                purchasedSteamSub1:
                    'Şirketinizin buhar, ısıtma, soğutma ve basınçlı hava gibi şeyler için kullandığı diğer enerjiden (elektrik hariç) kaynaklanan emisyonlar.',
                scope3: 'Kapsam 3',
                scope3Sub1:
                    'Kapsam 3 emisyonları, etkileyebileceğiniz ancak doğrudan kontrolünüz dışında olan emisyonlardır.',
                scope3Sub2:
                    'Buna atık bertaraftı, satın aldığınız ürünler için nakliye (yukarı akış), sattığınız ürünler için nakliye (aşağı akış) ve şirketiniz tarafından kullanılan enerji, sermaye malları ve satın alınan mal ve hizmetlerin beşikten kapıya emisyonları dahildir.',
                scope3Sub3: "Ayrıca kiralama, yatırımlar ve franchise'larla bağlantılı emisyonları da bildirmelisiniz.",
                purchasedGoods: 'Satın alınan mal ve hizmetler',
                purchasedGoodsSub1:
                    'Satın aldığınız ürünlerin üretiminden kaynaklanan tüm yukarı akış (yani beşikten kapıya) emisyonlar. Buna mallar (somut ürünler) ve hizmetler (maddi olmayan ürünler) dahildir.',
                purchasedGoodsSub2:
                    'Bu, diğer kapsam 3 emisyon kategorilerine dahil olmayan, satın alınan tüm mal ve hizmetlerden kaynaklanan emisyonları da içerir.',
                capitalGoods: 'Sermaye malları',
                capitalGoodsSub1:
                    'Şirketinizin satın aldığı sermaye mallarının üretiminden kaynaklanan tüm yukarı akış (yani beşikten kapıya) emisyonlar. Sermaye mallarının kullanımından kaynaklanan emisyonlar buna dahil değildir (kapsam 1 veya 2).',
                capitalGoodsSub2:
                    'Sermaye malları, uzun ömürlü olan ve şirketiniz tarafından ekipman, makine, bina, tesis ve araç gibi bir ürün yapmak, hizmet sağlamak veya mal satmak, depolamak ve teslim etmek için kullanılan ürünlerdir.',
                capitalGoodsSub3:
                    'Beşikten kapıya emisyonlar, satın aldığınız ürünlerin yaşam döngüsü boyunca onu teslim alana kadar oluşan tüm emisyonları içerir (sahip olduğunuz veya kontrol ettiğiniz kaynaklardan kaynaklanan emisyonlar hariç). Bu, ürünü teslim almadan önce hammaddelerin çıkarılması, tarımsal faaliyetler, imalat, üretim, işleme, malzeme ve ürünlerin tedarikçiler arasında taşınması ve diğer tüm faaliyetler gibi şeyleri içerir.',
                fuelAndEnergy: 'Yakıt ve enerji ile ilgili emisyonlar',
                fuelAndEnergySub1:
                    'Kapsam 1 veya kapsam 2 kapsamında olmayan, kullandığınız yakıt ve enerjiden kaynaklanan emisyonlar:',
                fuelAndEnergySub2: 'Satın aldığınız yakıtlardan yukarı akış emisyonları:',
                fuelAndEnergySub3:
                    'Yakıtınızın çıkarılması, üretilmesi ve taşınması (kömür madenciliği, rafine benzin, doğal gaz iletimi ve dağıtımı, biyoyakıt üretimi vb.)',
                fuelAndEnergySub4: 'Satın aldığınız elektriğin yukarı akış emisyonları:',
                fuelAndEnergySub5:
                    'Elektriğinizi, buharınızı, ısıtma ve soğutmanızı üretmek için kullanılan yakıtın çıkarılması, üretilmesi ve taşınması (kömür madenciliği, yakıtların rafine edilmesi, doğal gazın çıkarılması vb.)',
                fuelAndEnergySub6: 'İletim ve dağıtım kayıpları:',
                fuelAndEnergySub7: 'İletim ve dağıtım sırasında kaybedilen elektrik, buhar, ısıtma ve soğutma',
                fuelAndEnergySub8:
                    'Kamu hizmeti şirketleri veya enerji perakendecileri için: Alıp son kullanıcılara sattığınız elektrik (başka birine sattığınız elektrik, buhar, ısıtma ve soğutma)',
                upstream: 'Akış yukarı taşıma ve dağıtım',
                upstreamSub1:
                    'Satın aldığınız ürün/hizmetlerin size ait olmayan araç ve tesislerde taşınması ve dağıtılmasından kaynaklanan emisyonlar.',
                waste: 'Operasyonlarda oluşan atıklar',
                wasteSub1:
                    'Şirketinizden kaynaklanan katı atıkları ve atık suyu bertaraf eden ve arıtan diğer şirketlerden kaynaklanan emisyonlar.',
                business: 'İş Seyahati',
                businessSub1:
                    'Çalışanları işle ilgili faaliyetler için uçak, tren, otobüs ve yolcu arabaları dahil olmak üzere diğer şirketlerin sahip olduğu veya işlettiği araçlarda taşımaktan kaynaklanan emisyonlar. Bu işe gidip gelmeyi içermez.',
                employee: 'Çalışan işe gidip gelme',
                employeeSub1:
                    'Evleri ile işleri arasında genellikle araba, otobüs, tren veya uçakla seyahat eden çalışanlardan kaynaklanan emisyonlar. Uzaktan çalışan çalışanlardan kaynaklanan emisyonları bu kategoriye dahil edebilirsiniz.',
                upstreamAssets: 'Yukarı akışlı kiralanan varlıklar',
                upstreamAssetsSub1:
                    "Halihazırda kapsam 1 veya kapsam 2'de yer almayan, raporlama yılında şirketiniz tarafından kiralanan işletme varlıklarından kaynaklanan emisyonlar. Bu yalnızca kiralanan varlıkları işleten şirketler (yani kiracılar) için geçerlidir.",
                downstream: 'Aşağı yönlü taşıma ve dağıtım',
                downstreamSub1:
                    'Sahip olmadığınız araç ve tesislerde ürünlerinizin şirketiniz ile son tüketici arasında taşınması ve dağıtılmasından kaynaklanan emisyonlar. Buna perakende satış ve depolamadan kaynaklanan emisyonlar da dahildir.',
                downstreamSub2:
                    'Not: Satın aldığınız giden taşıma ve dağıtım hizmetleri, hizmeti satın aldığınız için yukarı yönlü taşıma ve dağıtıma dahildir.',
                processing: 'Satılan ürünlerin işlenmesi',
                processingSub1:
                    'Başka bir şirket (örneğin üreticiler) tarafından sattığınız ürünlerin işlenmesinden kaynaklanan emisyonlar. Bu, kullanımdan önce başka bir ürüne dahil edilmesi veya işlenmesi, dönüştürülmesi gereken tüm aracı ürünler için geçerlidir.',
                useOfSold: 'Satılan ürünlerin kullanımı',
                useOfSoldSub1:
                    'Sattığınız mal ve hizmetleri kullanan tüketiciler ve ticari müşteriler tarafından oluşturulan emisyonlar.',
                endOfLife: 'Satılan ürünlerin kullanım ömrü sonu',
                endOfLifeSub1:
                    'Sattığınız ürünlerin kullanım ömürlerinin sonunda elden çıkarılmasından ve işlenmesinden kaynaklanan emisyonlar. Bu, raporlama yılında satılan tüm ürünlerden beklenen toplam kullanım ömrü sonu emisyonlarını içerir.',
                downstreamAssets: 'Aşağı yönde kiralanan varlıklar',
                downstreamAssetsSub1:
                    'Sahip olduğunuz ve halihazırda kapsam 1 veya kapsam 2 emisyonlarınıza dahil olmayan diğer şirketlere kiraladığınız varlıklardan kaynaklanan emisyonlar. Kiralanan varlıkları (kiracıları) işleten şirketler, yukarı yönlü kiralanan varlıklar altında raporlama yapmalıdır.',
                franchise: 'Franchise',
                franchiseSub1:
                    "Kapsam 1 veya kapsam 2'ye dahil olmayan işletme franchise'larından kaynaklanan emisyonlar. Franchise, başka bir şirketin mallarını veya hizmetlerini belirli bir yerde satmak veya dağıtmak için lisans altında faaliyet gösteren bir işletmedir",
                franchiseSub2:
                    'Bu, franchise verenler için geçerlidir (yani, ticari markaların ve diğer hizmetlerin kullanımına ilişkin telif ücretleri gibi ödemeler karşılığında mallarını veya hizmetlerini başka kuruluşlara satması veya dağıtması için lisans veren şirketler).',
                franchiseSub3:
                    " Franchise verenler, bu kategoride franchise'ların işletilmesinden kaynaklanan emisyonları (yani franchise alanların kapsam 1 ve 2 emisyonlarını) hesaba katmalıdır. Franchise alanlar (yani franchise işleten ve bir franchise verene ücret ödeyen şirketler), kontrolleri altındaki operasyonlardan kaynaklanan emisyonları, kapsam 1 ve 2'ye dahil etmemişlerse, bu kategoriye dahil etmelidir.",
                investments: 'Yatırımlar',
                investmentsSub1:
                    "Halihazırda kapsam 1 veya kapsam 2'de yer almayan, şirketinizin yatırımlarıyla ilgili emisyonlar. Bu, yatırımcılar (yani kar elde etmek amacıyla yatırım yapan şirketler) ve finansal hizmet sağlayan şirketler için geçerlidir.",
                investmentsSub2:
                    'Yatırımlardan kaynaklanan emisyonlar, yatırım yapılan şirketteki orantılı yatırım payınıza göre şirketinize tahsis edilmelidir. Finansal yatırımlar 4 türe ayrılır:',
                investmentsList1: 'Sermaye yatırımları',
                investmentsList2: 'Borç yatırımları',
                investmentsList3: 'Proje finansmanı',
                investmentsList4: 'Yönetilen yatırımlar ve müşteri hizmetleri',
            },
            step6: {
                title: 'ISO 14064-1:2018’e göre Raporlama',
                subtitle: '',
                reporting: 'ISO 14064-1:2018’e göre Raporlama',
                reportingSub1:
                    'Doğrulayıcı kuruluşlar tarafından doğrulanarak belgelendirilecek ISO 14064 standartlarına uygun raporlama için Raporlama modülü oluşturulmuştur.',
                reportingSub2:
                    'Raporlama modülü sayesinde işletmeler 6 bölümde hızlı bir şekilde işlemlerini gerçekleştirebilecektir:',
                list1Title: '1. Genel Bilgiler, Amaç ve Politika:',
                list1Content:
                    'Bu bölümde işletmeler sera gazı emisyonları doğrultusunda amaç, kapsam, politika ve stratejilerini belirleyebilir, hazır şablonlar sayesinde çözümler üretebilir.',
                list2Title: '2. Organizasyonel Sınırlar:',
                list2Content:
                    'Kuruluşlar sera gazı envanterlerinin kullanım amacı ile tutarlı olacak şekilde konsolidasyon yaklaşımı seçerek organizasyon sınırlarını belirleyebilirler.',
                list3Title: '3. Raporlama Sınırları:',
                list3Content:
                    'Kullanıcılar sera gazı envanter listelerini hesaplamaları sonucunda takip edebilir ve kapsam dışı emisyon kaynaklarını dahil edilmeme sebebi ile birlikte beyan edebilirler.',
                list4Title: '4. Hesaplanmış Sera Gazı Emisyonu Envanteri',
                list4Content:
                    'Yapılan hesaplamalarda kullanılan değerleri, bu değerlere ait referansları ve yapılan hesaplamaların detaylı sonuçlarını görebilecek ve beyan edebilecekler.',
                list5Title: '5. Envanter Raporu İç Performans Takibi:',
                list5Content:
                    'Bu bölümde otomatik gelen efektif görseller ile tüm kategorileri pasta grafiklerde görebilecek ve rapor çıktısında beyan edebilecektir.',
                list6Title: '6. Ekler:',
                list6Content:
                    'Son bölüm de işletmeler rapor çıktısında ek olarak görmek istediği tüm dokümanları ekleyebilecektir.',
            },
            step7: {
                title: 'Kaynak Dökümanları',
                subtitle: '',
                sourcaDocuments: 'Kaynak Dökümanları',
                clickDownload: 'içeriğini indirmek için tıklayınız.',
                category1: 'Kategori 1 Veri Toplama',
                category2: 'Kategori 2 Veri Toplama',
                category3: 'Kategori 3 Veri Toplama',
                category4: 'Kategori 4 Veri Toplama',
                category5: 'Kategori 5 Veri Toplama',
                category6: 'Sera Gazı Emisyon Kaynakları Önemlilik Değerlendirme Tablosu',
                category7: 'Sera Gazı Faaliyet Verisi İzleme Planı',
            },
        },
        system: {
            system: 'Sistem - Yetkilendirme',
            users: 'Kullanıcılar',
            privilegeGroups: 'Yetki Grupları',
            newUser: 'Yeni Kullanıcı',
            privilegeGroupList: {
                system: 'Sistem - Yetkilendirme',
                newGroup: 'Yeni Grup',
                no: 'No',
                groupId: 'Yetki Grup ID',
                groupName: 'Grup Adı',
                isActive: 'Aktif',
                edit: 'Düzenle',
                create: {
                    system: 'Sistem - Yetkilendirme',
                    newUser: 'Yeni Kullanıcı',
                    groupInfo: 'Yetki Grubu Bilgileri',
                    back: 'Geri',
                    add: 'Ekle',
                    generalInfo: 'Genel Bilgiler',
                    groupId: 'Yetki Grup ID',
                    groupIdPlaceholder: '0091999',
                    description: 'Açıklama',
                    groupName: 'Grup Adı',
                    groupNamePlaceholder: 'Örn: Ecofoni',
                },
                privilege: {
                    system: 'Sistem - Yetkilendirme',
                    permissions: 'Kayıt İzinleri',
                    generalTab: 'Genel',
                    permissionsTab: 'Kayıt İzinleri',
                    usersTab: 'Kullanıcılar',
                    groupId: 'Yetki Grup ID',
                    description: 'Açıklama',
                    groupName: 'Grup Adı',
                    userList: 'Kullanıcı Listesi',
                    back: 'Geri',
                    update: 'Güncelle',
                },
                registration: {
                    system: 'Sistem - Yetkilendirme',
                    yes: 'Evet',
                    no: 'Hayır',
                    privileges: 'İzinler',
                    read: 'Okuma',
                    create: 'Oluşturma',
                    update: 'Düzenleme',
                    delete: 'Silme',
                },
                deleteDialog: {
                    title: 'Yetki grubunu silmek istediğinizden emin misiniz?',
                    description: 'Emin misiniz?',
                    cancel: 'Vazgeç',
                    delete: 'Sil',
                },
            },
            userList: {
                system: 'Sistem - Yetkilendirme',
                newUser: 'Yeni Kullanıcı',
                no: 'No',
                userRole: 'Kullanıcı Rolü',
                userId: 'Kullanıcı ID',
                username: 'Kullanıcı Adı',
                email: 'E-posta',
                connection: 'Bağlantı',
                edit: 'Düzenle',
                warningMessage: 'Şirket kullanıcılarının listeye erişme yetkisi yoktur.',
                create: {
                    system: 'Sistem - Yetkilendirme',
                    newUser: 'Yeni Kullanıcı',
                    userInfo: 'Kullanıcı Bilgileri',
                    back: 'Geri',
                    add: 'Ekle',
                    generalInfo: 'Genel Bilgiler',
                    detailInfo: 'Detay Bilgiler',
                    username: 'Kullanıcı Adı',
                    usernamePlaceholder: 'Örn: Ecofoni',
                    userRole: 'Kullanıcı Rolü',
                    email: 'E-posta',
                    emailPlaceholder: 'ecofoni@test.com',
                    connection: 'Bağlantı',
                    password: 'Şifre',
                    passwordPlaceholder: 'Şifre',
                    confirmPassword: 'Şifreyi Onayla',
                    confirmPasswordPlaceholder: 'Şifreyi Onayla',
                    notMatch: '*Şifreler uyuşmuyor',
                },
                dialog: {
                    system: 'Sistem - Yetkilendirme',
                    userInfo: 'Kullanıcı Bilgileri',
                    generalTab: 'Genel',
                    permissionsTab: 'Kayıt İzinleri',
                    groupsTab: 'Gruplar',
                    userId: 'Kullanıcı ID',
                    username: 'Kullanıcı Adı',
                    email: 'E-posta',
                    back: 'Geri',
                    update: 'Güncelle',
                    groups: 'Gruplar',
                    yes: 'Evet',
                    no: 'Hayır',
                    privileges: 'İzinler',
                    read: 'Okuma',
                    create: 'Oluşturma',
                    update_: 'Düzenleme',
                    delete: 'Silme',
                },
                deleteDialog: {
                    title: 'Kullanıcıyı silmek istediğinizden emin misiniz?',
                    description: 'Emin misiniz?',
                    cancel: 'Vazgeç',
                    delete: 'Sil',
                },
            },
        },
    },
    topbar: {
        turkish: 'Türkçe',
        english: 'İngilizce',
        profile: 'Profil',
        logout: 'Çıkış Yap',
        system: 'Sistem',
        addInventory: 'Envanter Ekle',
        allCompany: 'Tüm Şirketler',
        allFacility: 'Tüm Tesisler',
        allYear: 'Tüm Yıllar',
        noNotification: 'Hiç bildiriminiz bulunmamaktadır',
        FACILITY: 'Tesis',
        COMPANY: 'Şirket',
        ORGANIZATION: 'Organizasyon',
    },
    sidebar: {
        dashboard: 'Dashboard',
        analytical: 'Analitik',
        organizations: 'Organizasyonlar',
        inventory: 'Envanter',
        inventoryEntry: 'Envanter Girişi', // ISO
        inventoryEntryGHG: 'Envanter Girişi GHG',
        inventoryList: 'Envanter Listesi',
        reporting: 'Raporlama',
        generalInformation: 'Genel Bilgiler',
        organizational: 'Organizasyonel',
        reportingLimits: 'Raporlama Sınırları',
        calculatedGHGEmissionsInventory: 'Hesaplanmış Sera Gazı Emisyonu Envanteri',
        internalPerformanceTracking: 'İç Performans Takibi',
        appendices: 'Ekler',
        targetKPI: 'Hedef & KPI',
        faq: 'SSS',
        faqTitle: 'Sıkça Sorulan Sorular',
        guide: 'Klavuz',
    },
    common: {
        homePage: 'Ana Sayfa',
        unAuthorized: 'Yetkisiz',
        organization: 'Organizasyon',
        company: 'Şirket',
        facility: 'Tesis',
        calculationForms: {
            calculateButton: 'Hesapla',
            baseFacility: 'Ana Tesis',
            subFacility: 'Alt Tesis',
            greenhouseGasSource: 'Sera Gazı Kaynağı',
            emissionFactorStepSelection: 'Emisyon Faktörü Kademe Seçimi',
            database: 'Veritabanı',
            sourceStream: 'Kaynak Akışı',
            unit: 'Birim',
            emissionFactor: 'Emisyon Faktörü',
            emissionFactorDescription: '*Değer girmemeniz halinde varsayılan veritabanından alınacaktır.',
            emissionSourceReference: 'Emisyon Kaynağı Referansı',
            emissionSourceReferencePlaceholder: 'Emisyon Kaynağı Referansınızı Yazınız',
            emissionSourceReferenceDescription: '*Değer girmemeniz halinde varsayılan veritabanından alınacaktır.',
            nkd: 'NKD',
            nkdPlaceholder: 'NKD',
            nkdReference: 'NKD Referansı',
            density: 'Yoğunluk',
            densityPlaceholder: 'Yoğunluk',
            densityReference: 'Yoğunluk Referansı',
            calculationYear: 'Hesaplama Yılı',
            activityData: 'Faaliyet Verisi',
            activityDataDescription: 'Hesaplama yapacağınız faaliyet verisini giriniz.',
            activityDataPrimary: 'Birincil faaliyet verisi',
            activityDataPrimaryDescription:
                'Sahaya (tesise) özgü faaliyet verileri mevcut ise birincil faaliyet verisini seçiniz.',
            activityDataSecondary: 'İkincil faaliyet verisi',
            activityDataSecondaryDescription:
                'Sahaya (tesise) özgü faaliyet verileri mevcut değil ve tahmini aktivite verileri kullanılacak ise ikincil faaliyet verisini seçiniz.',
            selectMonth: 'Ay seçin',
            consumptionData: 'Tüketim Verisi',
            consumptionDataDescription: 'Hesaplama yapmak istediğiniz ayları seçerek faaliyet verilerinizi giriniz.',
            description: 'Açıklama',
            loading: 'Yükleniyor..',
            optionNotFound: 'Seçenek Bulunamadı.',
            static: {
                greenhouseGasSourcePlaceholder: 'Örn: Kazan, Isıtıcı, Gaz Türbini, Jeneratör, LPG Tüpleri vb.',
                sector: 'Sektör',
                fuelType: 'Yakıt Tipi',
                vehicleOccupancyRate: 'Araç Doluluk Oranı',
                dimension: 'Boyut',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            mobile: {
                greenhouseGasSourcePlaceholder: 'Örn: Şirket Otomobilleri, İş makineleri, Forklift, Kamyonet vb.',
                activity: 'Aktivite',
                fuelType: 'Yakıt Tipi',
                vehicleOccupancyRate: 'Araç Doluluk Oranı',
                dimension: 'Boyut',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            process: {
                greenhouseGasSourcePlaceholder: 'Örn: Çimento üretimi, Seramik üretimi, Demir Çelik üretimi vb.',
                sector: 'Sektör',
                processType: 'Proses Türü',
                material: 'Malzeme',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            fugitive: {
                greenhouseGasSourcePlaceholder: 'Örn: Buzdolapları & Su Sebilleri vb.',
                deviceType: 'Cihaz Tipi',
                gasGroups: 'Gaz Grubu',
                gasType: 'Gaz Tipi',
                deviceFillStatus: 'Cihaz Dolum Durumu',
                numberOfDevices: 'Cihaz Adedi',
                numberOfDevicesPlaceholder: 'Cihaz Adedi',
                gasCapacity: 'Gaz Kapasitesi',
                amountOfGasCompressed: 'Basılan Gaz Miktarı',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
                filled: 'Dolum Yapılmış',
                notFilled: 'Dolum Yapılmamış',
            },
            lulucf: {
                greenhouseGasSourcePlaceholder: 'Örn: Arazi Kullanım Değişikliği',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            biomass: {
                greenhouseGasSourcePlaceholder: 'Örn: Kazan, Isıtıcı, Gaz Türbini, Jeneratör, LPG Tüpleri vb.',
                sector: 'Sektör',
                fuelType: 'Yakıt Tipi',
                vehicleOccupancyRate: 'Araç Doluluk Oranı',
                dimension: 'Boyut',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            electric: {
                activityType: 'Aktivite Türü',
                greenhouseGasSourcePlaceholder: 'Örn: Satılan ürünlerin nakliyesi',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            steam: {
                greenhouseGasSourcePlaceholder: 'Örn: Kazan, Isıtıcı, Gaz Türbini, Jeneratör, LPG Tüpleri vb.',
                sector: 'Sektör',
                fuel: 'Fuel',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            upstream: {
                greenhouseGasSourcePlaceholder: 'Örn: Satın alınan malzemelerin nakliyesi',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                fuelType: 'Yakıt Tipi',
                vehicleOccupancyRate: 'Araç Doluluk Oranı',
                dimension: 'Boyut',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            downstream: {
                greenhouseGasSourcePlaceholder: 'Örn: Satın alınan malzemelerin nakliyesi',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                fuelType: 'Yakıt Tipi',
                vehicleOccupancyRate: 'Araç Doluluk Oranı',
                dimension: 'Boyut',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            employee: {
                greenhouseGasSourcePlaceholder: 'Örn: Personelin şahsi/toplu taşıma ile ulaşımı',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                fuelType: 'Yakıt Tipi',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            visitor: {
                greenhouseGasSourcePlaceholder:
                    'Örn: Ziyaretçinin/Müşterinin şahsi araçları/toplu taşıma/havayolu ile ulaşımı',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                fuelType: 'Yakıt Tipi',
                flightClass: 'Uçuş Sınıfı',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            business: {
                greenhouseGasSourcePlaceholder:
                    'Örn: Personelin şahsi aracı/havayolu ile ulaşımı, Personelin gecelik otel konaklaması vb.',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                country: 'Ülke',
                fuelType: 'Yakıt Tipi',
                flightClass: 'Uçuş Sınıfı',
                whichCountry: 'Hangi ülkede konaklama yaptığınızı belirtiniz',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            soldProducts: {
                greenhouseGasSourcePlaceholder: 'Örn: Su Temini ve İnşaat Malzemesi satın alımı',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                material: 'Malzeme',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            boughtAssets: {
                greenhouseGasSourcePlaceholder: 'Örn: İnşaat Malzemesi sermaye mal ve Plastik sermaye mal',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                material: 'Malzeme',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            waste: {
                greenhouseGasSourcePlaceholder: 'Örn: İnşaat Malzemesi bertarafı ve Metal bertarafı',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                disposalMethod: 'Bertaraf Yöntemi',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            productLifetime: {
                greenhouseGasSourcePlaceholder: 'Örn: İnşaat Malzemesi, Metal, Kağıt bertarafı',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                disposalMethod: 'Bertaraf Yöntemi',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            serviceUsage: {
                greenhouseGasSourcePlaceholder: 'Örn: Satın alınan malzemelerin nakliyesi',
                activity: 'Aktivite',
                activityType: 'Aktivite Türü',
                fuelType: 'Yakıt Tipi',
                vehicleOccupancyRate: 'Araç Doluluk Oranı',
                dimension: 'Boyut',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            upstreamLeased: {
                greenhouseGasSourcePlaceholder: 'Örn: Şirket Otomobilleri, İş makineleri, Forklift, Kamyonet vb.',
                activity: 'Aktivite',
                fuelType: 'Yakıt Tipi',
                vehicleOccupancyRate: 'Araç Doluluk Oranı',
                dimension: 'Boyut',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            downstreamLeased: {
                greenhouseGasSourcePlaceholder: 'Örn: Şirket Otomobilleri, İş makineleri, Forklift, Kamyonet vb.',
                activity: 'Aktivite',
                fuelType: 'Yakıt Tipi',
                vehicleOccupancyRate: 'Araç Doluluk Oranı',
                dimension: 'Boyut',
                descriptionPlaceholder: 'Örn: Üretim + Isınma',
            },
            months: {
                january: 'Ocak',
                february: 'Şubat',
                march: 'Mart',
                april: 'Nisan',
                may: 'Mayıs',
                june: 'Haziran',
                july: 'Temmuz',
                august: 'Ağustos',
                september: 'Eylül',
                october: 'Ekim',
                november: 'Kasım',
                december: 'Aralık',
                all: 'Tüm yıl',
            },
        },
    },
    notification: {
        welcome: 'Hoşgeldiniz.',
        someFieldsBlank: 'Zorunlu alan(lar) boş bırakıldı/seçilmedi.',
        somethingWentWrong: 'Bir şeyler yanlış gitti.',
        documentAdded: 'Belge başarıyla eklendi.',
        documentDownload: 'Belge başarıyla indirildi.',
        cannotDownloaded: 'Bu dosya indirilemiyor.',
        successCreated: 'Başarıyla oluşturuldu.',
        successUpdated: 'Başarıyla güncellendi.',
        successDeleted: 'Başarıyla silindi.',
        pleaseMakeYourChoise: 'Lütfen seçim yapınız.',
        codeError: 'Geçersiz kod.',
        emailNotFound: 'Email bulunamadı.',
    },
    sdg: {
        name1: 'YOKSULLUĞA SON',
        title1: 'Yoksulluğun tüm biçimlerini her yerde sona erdirmek',
        name2: 'AÇLIĞA SON',
        title2: 'Açlığı bitirmek, gıda güvenliğine ve iyi beslenmeye ulaşmak ve sürdürülebilir tarımı desteklemek',
        name3: 'SAĞLIK VE KALİTELİ YAŞAM',
        title3: 'Sağlıklı ve kaliteli yaşamı her yaşta güvence altına almak',
        name4: 'NİTELİKLİ EĞİTİM',
        title4: 'Kapsayıcı ve hakkaniyete dayanan nitelikli eğitimi sağlamak ve herkes için yaşam boyu öğrenim fırsatlarını teşvik etmek',
        name5: 'TOPLUMSAL CİNSİYET EŞİTLİĞİ',
        title5: 'Toplumsal cinsiyet eşitliğini sağlamak ve tüm kadınlar ile kız çocuklarını güçlendirmek.',
        name6: 'TEMİZ SU VE SANİTASYON',
        title6: 'Herkes için erişilebilir su ve atık su hizmetlerini ve sürdürülebilir su yönetimini güvence altına almak',
        name7: 'ERİŞİLEBİLİR VE TEMİZ ENERJİ',
        title7: 'Herkes için karşılanabilir, güvenilir, sürdürülebilir ve modern enerjiye erişimi sağlamak',
        name8: 'İNSANA YAKIŞIR İŞ VE EKONOMİK BÜYÜME',
        title8: 'İstikrarlı, kapsayıcı ve sürdürülebilir ekonomik büyümeyi, tam ve üretken istihdamı ve herkes için insana yakışır işleri desteklemek',
        name9: 'SANAYİ, YENİLİKÇİLİK VE ALTYAPI',
        title9: 'Dayanıklı altyapılar tesis etmek, kapsayıcı ve sürdürülebilir sanayileşmeyi desteklemek ve yenilikçiliği güçlendirmek',
        name10: 'EŞİTSİZLİKLERİN AZALTILMASI',
        title10: 'Ülkeler içinde ve arasında eşitsizlikleri azaltmak',
        name11: 'SÜRDÜRÜLEBİLİR ŞEHİRLER VE TOPLULUKLAR',
        title11: 'Şehirleri ve insan yerleşimlerini kapsayıcı, güvenli, dayanıklı ve sürdürülebilir kılmak',
        name12: 'SORUMLU ÜRETİM VE TÜKETİM',
        title12: 'Sürdürülebilir üretim ve tüketim kalıplarını sağlamak',
        name13: 'İKLİM EYLEMİ',
        title13: 'İklim değişikliği ve etkileri ile mücadele için acilen eyleme geçmek',
        name14: 'SUDAKİ YAŞAM',
        title14:
            'Sürdürülebilir kalkınma için okyanusları, denizleri ve deniz kaynaklarını korumak ve sürdürülebilir kullanmak',
        name15: 'KARASAL YAŞAM',
        title15:
            'Karasal ekosistemleri korumak, iyileştirmek ve sürdürülebilir kullanımını desteklemek; sürdürülebilir orman yönetimini sağlamak; çölleşme ile mücadele etmek; arazi bozunumunu durdurmak ve tersine çevirmek; biyolojik çeşitlilik kaybını engellemek',
        name16: 'BARIŞ, ADALET VE GÜÇLÜ KURUMLAR',
        title16:
            'Sürdürülebilir kalkınma için barışçıl ve kapsayıcı toplumlar tesis etmek, herkes için adalete erişimi sağlamak ve her düzeyde etkili, hesap verebilir ve kapsayıcı kurumlar oluşturmak',
        name17: 'AMAÇLAR İÇİN ORTAKLIKLAR',
        title17: 'Uygulama araçlarını güçlendirmek ve sürdürülebilir kalkınma için küresel ortaklığı canlandırmak',
    },
};
