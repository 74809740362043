import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IBoughtAssetsActivity,
    IBoughtAssetsActivityType,
    IBoughtAssetsAdditional,
    IBoughtAssetsDatabase,
    IBoughtAssetsFuelType,
    IBoughtAssetsResult,
    IBoughtAssetsSize,
    IBoughtAssetsUnit,
} from '../../../entities/emissionTypes/boughtAssetsCombustion';
import { EMISSION } from './base.action';

export const BOUGHT_ASSETS = 'BOUGHT_ASSETS';

////-- Base
export const CHANGE_BOUGHT_ASSETS_MAIN_FACILITY = `${EMISSION} ${BOUGHT_ASSETS} Change Main Facility`;
export const CHANGE_BOUGHT_ASSETS_SUB_FACILITY = `${EMISSION} ${BOUGHT_ASSETS} Change Sub Facility`;

export const GET_BOUGHT_ASSETS_TIERS = `${EMISSION} ${BOUGHT_ASSETS} Get Tiers`;
export const SET_BOUGHT_ASSETS_TIERS = `${EMISSION} ${BOUGHT_ASSETS} Set Tiers`;
export const SELECT_BOUGHT_ASSETS_TIER = `${EMISSION} ${BOUGHT_ASSETS} Select Tier`;

export const GET_BOUGHT_ASSETS_DATABASES = `${EMISSION} ${BOUGHT_ASSETS} Get Databases`;
export const SET_BOUGHT_ASSETS_DATABASES = `${EMISSION} ${BOUGHT_ASSETS} Set Databases`;
export const SELECT_BOUGHT_ASSETS_DATABASE = `${EMISSION} ${BOUGHT_ASSETS} Select Database`;

export const CHANGE_BOUGHT_ASSETS_EQUIVALENT_FACTOR = `${EMISSION} ${BOUGHT_ASSETS} Change Equivalent Factor`;

export const GET_BOUGHT_ASSETS_ACTIVITIES = `${EMISSION} ${BOUGHT_ASSETS} Get Activities`;
export const SET_BOUGHT_ASSETS_ACTIVITIES = `${EMISSION} ${BOUGHT_ASSETS} Set Activities`;
export const SELECT_BOUGHT_ASSETS_ACTIVITY = `${EMISSION} ${BOUGHT_ASSETS} Select Activity`;
/////--- End base

export const GET_BOUGHT_ASSETS_FUEL_TYPES = `${EMISSION} ${BOUGHT_ASSETS} Get Fuel Types`;
export const SET_BOUGHT_ASSETS_FUEL_TYPES = `${EMISSION} ${BOUGHT_ASSETS} Set Fuel Types`;
export const SELECT_BOUGHT_ASSETS_FUEL_TYPE = `${EMISSION} ${BOUGHT_ASSETS} Select Fuel Type`;

export const GET_BOUGHT_ASSETS_ACTIVITY_TYPES = `${EMISSION} ${BOUGHT_ASSETS} Get Activity Types`;
export const SET_BOUGHT_ASSETS_ACTIVITY_TYPES = `${EMISSION} ${BOUGHT_ASSETS} Set Activity Types`;
export const SELECT_BOUGHT_ASSETS_ACTIVITY_TYPE = `${EMISSION} ${BOUGHT_ASSETS} Select Activity Type`;

export const GET_BOUGHT_ASSETS_UNITS = `${EMISSION} ${BOUGHT_ASSETS} Get Units`;
export const SET_BOUGHT_ASSETS_UNITS = `${EMISSION} ${BOUGHT_ASSETS} Set Units`;
export const SELECT_BOUGHT_ASSETS_UNIT = `${EMISSION} ${BOUGHT_ASSETS} Select Unit`;

export const GET_BOUGHT_ASSETS_ADDITIONAL_CONSTANTS = `${EMISSION} ${BOUGHT_ASSETS} Get Additional Constants`;
export const SET_BOUGHT_ASSETS_ADDITIONAL_CONSTANTS = `${EMISSION} ${BOUGHT_ASSETS} Set Additional Constants`;

export const CHANGE_BOUGHT_ASSETS_WEIGHT = `${EMISSION} ${BOUGHT_ASSETS} Change Weight`;
export const CHANGE_BOUGHT_ASSETS_DISTANCE = `${EMISSION} ${BOUGHT_ASSETS} Change Distance`;

export const GET_BOUGHT_ASSETS_LADEN_PERCENTAGES = `${EMISSION} ${BOUGHT_ASSETS} Get Laden Percentages`;
export const SET_BOUGHT_ASSETS_LADEN_PERCENTAGES = `${EMISSION} ${BOUGHT_ASSETS} Set Laden Percentages`;
export const SELECT_BOUGHT_ASSETS_LADEN_PERCENTAGE = `${EMISSION} ${BOUGHT_ASSETS} Select Laden percentage`;

export const GET_BOUGHT_ASSETS_SIZES = `${EMISSION} ${BOUGHT_ASSETS} Get Sizes`;
export const SET_BOUGHT_ASSETS_SIZES = `${EMISSION} ${BOUGHT_ASSETS} Set Sizes`;
export const SELECT_BOUGHT_ASSETS_SIZE = `${EMISSION} ${BOUGHT_ASSETS} Select Size`;

////-- Base
export const CHANGE_BOUGHT_ASSETS_YEAR = `${EMISSION} ${BOUGHT_ASSETS} Change Year`;
export const SELECT_BOUGHT_ASSETS_ACTIVITY_DATA = `${EMISSION} ${BOUGHT_ASSETS} Select Activity Data`;
export const CHANGE_BOUGHT_ASSETS_CONSUMPTION_AMOUNT = `${EMISSION} ${BOUGHT_ASSETS} Change Consumption Amount`;
export const CHANGE_BOUGHT_ASSETS_DESCRIPTION = `${EMISSION} ${BOUGHT_ASSETS} Change Description`;

export const SUBMIT_BOUGHT_ASSETS_FORM = `${EMISSION} ${BOUGHT_ASSETS} Submit Form`;
export const SET_BOUGHT_ASSETS_RESULT = `${EMISSION} ${BOUGHT_ASSETS} Set Result`;
/////-- End Base
export const CLEAR_BOUGHT_ASSETS_CONSUMPTION_AMOUNT = `${EMISSION} ${BOUGHT_ASSETS} Clear Consumption Amount`;
export const CLEAR_BOUGHT_ASSETS_INPUT = `${EMISSION} ${BOUGHT_ASSETS} Clear Bought Assets Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearBoughtAssetsInput = (list: Array<string>) => ({
    type: CLEAR_BOUGHT_ASSETS_INPUT,
    payload: list,
});

/// Region BoughtAssets
export const changeBoughtAssetsMainFacility = (text: string) => ({
    type: CHANGE_BOUGHT_ASSETS_MAIN_FACILITY,
    payload: text,
});

export const changeBoughtAssetsSubFacility = (text: string) => {
    return {
        type: CHANGE_BOUGHT_ASSETS_SUB_FACILITY,
        payload: text,
    };
};

export const getBoughtAssetsTiers = () => ({
    type: GET_BOUGHT_ASSETS_TIERS,
});

export const setBoughtAssetsTiers = (list: Array<ITier>) => ({
    type: SET_BOUGHT_ASSETS_TIERS,
    payload: list,
});

export const selectBoughtAssetsTier = (tier: ITier) => ({
    type: SELECT_BOUGHT_ASSETS_TIER,
    payload: tier,
});

export const getBoughtAssetsDatabases = (tier: ITier) => ({
    type: GET_BOUGHT_ASSETS_DATABASES,
    payload: tier,
});

export const setBoughtAssetsDatabases = (list: Array<IBoughtAssetsDatabase>) => ({
    type: SET_BOUGHT_ASSETS_DATABASES,
    payload: list,
});

export const selectBoughtAssetsDatabase = (database: IBoughtAssetsDatabase) => ({
    type: SELECT_BOUGHT_ASSETS_DATABASE,
    payload: database,
});

export const getBoughtAssetsActivities = (tier: ITier) => ({
    type: GET_BOUGHT_ASSETS_ACTIVITIES,
    payload: tier,
});

export const setBoughtAssetsActivities = (activities: Array<IBoughtAssetsActivity>) => ({
    type: SET_BOUGHT_ASSETS_ACTIVITIES,
    payload: activities,
});

export const selectBoughtAssetsActivity = (activity: IBoughtAssetsActivity) => ({
    type: SELECT_BOUGHT_ASSETS_ACTIVITY,
    payload: activity,
});

///////
export const getBoughtAssetsFuelTypes = (tier: ITier) => ({
    type: GET_BOUGHT_ASSETS_FUEL_TYPES,
    payload: tier,
});

export const setBoughtAssetsFuelTypes = (list: Array<IBoughtAssetsFuelType>) => ({
    type: SET_BOUGHT_ASSETS_FUEL_TYPES,
    payload: list,
});

export const selectBoughtAssetsFuelType = (database: IBoughtAssetsFuelType) => ({
    type: SELECT_BOUGHT_ASSETS_FUEL_TYPE,
    payload: database,
});

export const getBoughtAssetsActivityTypes = (value: any) => ({
    type: GET_BOUGHT_ASSETS_ACTIVITY_TYPES,
    payload: value,
});

export const setBoughtAssetsActivityTypes = (activities: Array<IBoughtAssetsActivityType>) => ({
    type: SET_BOUGHT_ASSETS_ACTIVITY_TYPES,
    payload: activities,
});

export const selectBoughtAssetsActivityType = (activity: IBoughtAssetsActivityType) => ({
    type: SELECT_BOUGHT_ASSETS_ACTIVITY_TYPE,
    payload: activity,
});

export const getBoughtAssetsUnits = (units: IBoughtAssetsActivityType) => ({
    type: GET_BOUGHT_ASSETS_UNITS,
    payload: units,
});

export const setBoughtAssetsUnits = (units: Array<IBoughtAssetsUnit>) => ({
    type: SET_BOUGHT_ASSETS_UNITS,
    payload: units,
});

export const selectBoughtAssetsUnit = (unit: IBoughtAssetsUnit) => ({
    type: SELECT_BOUGHT_ASSETS_UNIT,
    payload: unit,
});

export const getBoughtAssetsAdditionalConstants = (additional: IBoughtAssetsUnit) => ({
    type: GET_BOUGHT_ASSETS_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setBoughtAssetsAdditionalConstants = (additional: Array<IBoughtAssetsAdditional>) => ({
    type: SET_BOUGHT_ASSETS_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeBoughtAssetsWeight = (value: number) => ({
    type: CHANGE_BOUGHT_ASSETS_WEIGHT,
    payload: value,
});

export const changeBoughtAssetsDistance = (value: number) => ({
    type: CHANGE_BOUGHT_ASSETS_DISTANCE,
    payload: value,
});

export const getBoughtAssetsLadenPercentages = (ladens: any) => ({
    type: GET_BOUGHT_ASSETS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setBoughtAssetsLadenPercentages = (ladens: Array<any>) => ({
    type: SET_BOUGHT_ASSETS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectBoughtAssetsLadenPercentage = (laden: any) => ({
    type: SELECT_BOUGHT_ASSETS_LADEN_PERCENTAGE,
    payload: laden,
});

export const getBoughtAssetsSizes = (sizes: IBoughtAssetsActivityType) => ({
    type: GET_BOUGHT_ASSETS_SIZES,
    payload: sizes,
});

export const setBoughtAssetsSizes = (sizes: Array<IBoughtAssetsSize>) => ({
    type: SET_BOUGHT_ASSETS_SIZES,
    payload: sizes,
});

export const selectBoughtAssetsSize = (size: IBoughtAssetsSize) => ({
    type: SELECT_BOUGHT_ASSETS_SIZE,
    payload: size,
});

///////
export const submitBoughtAssetsForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_BOUGHT_ASSETS_FORM,
    meta: {
        onSuccess,
    },
});

export const setBoughtAssetsResult = (data: Array<IBoughtAssetsResult>) => ({
    type: SET_BOUGHT_ASSETS_RESULT,
    payload: data,
});

export const selectBoughtAssetsActivityData = (activity: string) => ({
    type: SELECT_BOUGHT_ASSETS_ACTIVITY_DATA,
    payload: activity,
});

export const changeBoughtAssetsYear = (value: number) => ({
    type: CHANGE_BOUGHT_ASSETS_YEAR,
    payload: value,
});

export const changeBoughtAssetsDescription = (text: string) => ({
    type: CHANGE_BOUGHT_ASSETS_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_BOUGHT_ASSETS_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_BOUGHT_ASSETS_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_BOUGHT_ASSETS_CONSUMPTION_AMOUNT,
});
/// End Region BoughtAssets
