import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LayoutSideBar from './views/layouts/LayoutSideBar';
import Loader from './views/components/Loader';
import Login from './views/pages/authorization/Login';
import PermissionProvider from './providers/PermissionProvider';
import NotFound from './views/pages/NotFound';
import { useTranslation } from 'react-i18next';
import { getBaseYears } from './redux/actions/emission/base.action';
import { useAppDispatch } from './redux/hooks';
import { PermissionTypes } from './data/authorizations/permissionType';
import { PermissionTypeSetsGroup } from './data/authorizations/permissionTypeSetGroup';
import { Roles, RolesGroup } from './data/authorizations/rolesGroup';
import { setLoading } from './redux/actions/auth/auth.action';
import ResetPassword from './views/pages/authorization/ResetPassword';

const ApplicationRoutes = () => {
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const General = lazy(() => import('./views/pages/reporting/General/General'));
    const GeneralEdit = lazy(() => import('./views/pages/reporting/General/Edit'));
    const Organizational = lazy(() => import('./views/pages/reporting/Organizational/Organizational'));
    const OrganizationalEdit = lazy(() => import('./views/pages/reporting/Organizational/Edit'));
    const Reporting = lazy(() => import('./views/pages/reporting/Reporting/Reporting'));
    const ReportingEdit = lazy(() => import('./views/pages/reporting/Reporting/Edit'));
    const Quantified = lazy(() => import('./views/pages/reporting/Quantified/Quantified'));
    const Internal = lazy(() => import('./views/pages/reporting/Internal/Internal'));
    const InternalEdit = lazy(() => import('./views/pages/reporting/Internal/Edit'));
    const CompanyDashboard = lazy(() => import('./views/pages/company/Dashboard'));
    const CompanyProfile = lazy(() => import('./views/pages/company/Profile'));
    const CompanyEdit = lazy(() => import('./views/pages/company/Edit'));
    const FacilityList = lazy(() => import('./views/pages/FacilityList'));
    const FacilityDashboard = lazy(() => import('./views/pages/facility/Dashboard'));
    const InventoryList = lazy(() => import('./views/pages/InventoryList'));
    const CategorySelection = lazy(() => import('./views/pages/InventoryForm/CategorySelection'));
    const CategorySelectionGHG = lazy(() => import('./views/pages/InventoryForm/CategorySelectionGHG'));
    const CategoryForm = lazy(() => import('./views/pages/InventoryForm/CategoryForm'));
    const CategoryResult = lazy(() => import('./views/pages/InventoryForm/CategoryResult'));
    const Appendices = lazy(() => import('./views/pages/reporting/Appendices/Appendices'));
    const AppendicesEdit = lazy(() => import('./views/pages/reporting/Appendices/Edit'));
    const QuantifiedEdit = lazy(() => import('./views/pages/reporting/Quantified/Edit'));
    const Guide = lazy(() => import('./views/pages/guide/Guide'));
    const Intro = lazy(() => import('./views/components/Guide/pages/Intro'));
    const How = lazy(() => import('./views/components/Guide/pages/How'));
    const Equivalent = lazy(() => import('./views/components/Guide/pages/Equivalent'));
    const Emission = lazy(() => import('./views/components/Guide/pages/Emission'));
    const Scope = lazy(() => import('./views/components/Guide/pages/Scope'));
    const Source = lazy(() => import('./views/components/Guide/pages/Source'));
    const ReportingGuide = lazy(() => import('./views/components/Guide/pages/Reporting'));
    const System = lazy(() => import('./views/pages/system/System'));
    const ReportingModule = lazy(() => import('./views/pages/reporting/ReportingModule'));
    const Kpi = lazy(() => import('./views/pages/kpi/Kpi'));
    const InventoryDetail = lazy(() => import('./views/pages/IntentoryDetail'));
    const FAQ = lazy(() => import('./views/pages/FAQ'));
    const Excel = lazy(() => import('./views/pages/emission/excel/Excel'));

    interface IPage {
        path: string;
        element: React.ReactNode;
        permissionType: string;
        permissionTypeSet: Array<string>;
        roles: Array<string>;
    }

    const PermissionRoutes: Array<IPage> = [
        {
            path: 'reporting/module',
            element: <ReportingModule />,
            permissionType: 'X',
            permissionTypeSet: ['X'],
            roles: RolesGroup.ORGANIZATION_ADMIN,
        },
        {
            path: 'general',
            element: <General />,
            permissionType: PermissionTypes.REPORT,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'general/edit',
            element: <GeneralEdit />,
            permissionType: PermissionTypes.REPORT,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'organization',
            element: <Organizational />,
            permissionType: PermissionTypes.ORGANIZATIONAL_BOUNDARY,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'organization/edit',
            element: <OrganizationalEdit />,
            permissionType: PermissionTypes.ORGANIZATIONAL_BOUNDARY,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'reporting',
            element: <Reporting />,
            permissionType: PermissionTypes.REPORT_BOUNDARY,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'reporting/edit',
            element: <ReportingEdit />,
            permissionType: PermissionTypes.REPORT_BOUNDARY,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'quantified',
            element: <Quantified />,
            permissionType: PermissionTypes.QUANTIFIED_EMISSION,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'quantified/edit',
            element: <QuantifiedEdit />,
            permissionType: PermissionTypes.QUANTIFIED_EMISSION,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'internal',
            element: <Internal />,
            permissionType: PermissionTypes.INTERNAL_PERFORMANCE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'internal/edit',
            element: <InternalEdit />,
            permissionType: PermissionTypes.INTERNAL_PERFORMANCE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'appendices',
            element: <Appendices />,
            permissionType: PermissionTypes.APPENDIX,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'appendices/edit',
            element: <AppendicesEdit />,
            permissionType: PermissionTypes.APPENDIX,
            permissionTypeSet: PermissionTypeSetsGroup.ALL,
            roles: RolesGroup.COMPANY_ADMIN,
        },
        {
            path: 'guide',
            element: <Guide />,
            permissionType: PermissionTypes.GUIDE,
            permissionTypeSet: [],
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'guide/intro',
            element: <Intro />,
            permissionType: PermissionTypes.GUIDE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'guide/equivalent',
            element: <Equivalent />,
            permissionType: PermissionTypes.GUIDE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'guide/how',
            element: <How />,
            permissionType: PermissionTypes.GUIDE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'guide/emission',
            element: <Emission />,
            permissionType: PermissionTypes.GUIDE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'guide/scope',
            element: <Scope />,
            permissionType: PermissionTypes.GUIDE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'guide/reporting',
            element: <ReportingGuide />,
            permissionType: PermissionTypes.GUIDE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'guide/source',
            element: <Source />,
            permissionType: PermissionTypes.GUIDE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'company/dashboard',
            element: <CompanyDashboard />,
            permissionType: PermissionTypes.COMPANY_DASHBOARD,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: RolesGroup.ALL,
        },
        {
            path: 'profile',
            element: <CompanyProfile />,
            permissionType: PermissionTypes.COMPANY_PROFILE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: RolesGroup.ONLY_ADMINS,
        },
        {
            path: 'company/edit',
            element: <CompanyEdit />,
            permissionType: PermissionTypes.COMPANY_PROFILE,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: RolesGroup.ONLY_ADMINS,
        },
        {
            path: 'facility',
            element: <FacilityList />,
            permissionType: PermissionTypes.FACILITY,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: RolesGroup.ONLY_ADMINS,
        },
        {
            path: 'company/analytic',
            element: <FacilityDashboard />,
            permissionType: PermissionTypes.FACILITY_ANALYTICS,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'inventory',
            element: <InventoryList />,
            permissionType: PermissionTypes.INVENTORY_LIST,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_R,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'inventory/create/category',
            element: <CategorySelection />,
            permissionType: PermissionTypes.INVENTORY_FORM,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        //{
        //    path: 'inventory/create/category/ghg',
        //    element: <CategorySelectionGHG />,
        //    permissionType: PermissionTypes.INVENTORY_FORM,
        //    permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
        //    roles: RolesGroup.ONLY_ADMINS
        //},
        {
            path: 'inventory/create/category/:id',
            element: <CategoryForm />,
            permissionType: PermissionTypes.INVENTORY_FORM,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'inventory/create/category/:id/result',
            element: <CategoryResult />,
            permissionType: PermissionTypes.INVENTORY_FORM,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        {
            path: 'system/*',
            element: <System />,
            permissionType: PermissionTypes.SYSTEM,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: RolesGroup.ONLY_ADMINS,
        },
        {
            path: 'kpi/*',
            element: <Kpi />,
            permissionType: PermissionTypes.KPI_DASHBOARD,
            permissionTypeSet: PermissionTypeSetsGroup.ONLY_RWU,
            roles: RolesGroup.ONLY_ADMINS,
        },
        {
            path: 'inventory/:id',
            element: <InventoryDetail />,
            permissionType: PermissionTypes.INVENTORY_FORM,
            permissionTypeSet: PermissionTypeSetsGroup.ALL,
            roles: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_USER],
        },
        /*{
            path: 'faq',
            element: <FAQ />,
            permissionType: '',
            permissionTypeSet: [''],
            roles: RolesGroup.ALL,
        },*/
        {
            path: 'excel/:id',
            element: <Excel />,
            permissionType: '',
            permissionTypeSet: PermissionTypeSetsGroup.ALL,
            roles: RolesGroup.ALL,
        },
    ];

    useEffect(() => {
        dispatch(getBaseYears(i18n?.language || 'tr'));
    }, [dispatch, i18n]);

    useEffect(() => {
        dispatch(setLoading());
    }, [dispatch]);

    return (
        <Routes>
            <Route path="/" element={<LayoutSideBar />}>
                <Route path="/" element={<Navigate to="company/dashboard" />} />

                {PermissionRoutes.map((page, index) => (
                    <Route
                        key={page.path}
                        path={page.path}
                        element={
                            <Suspense fallback={<Loader />} key={page.path}>
                                <PermissionProvider
                                    id={index}
                                    key={page.path}
                                    permissionType={page.permissionType}
                                    permissionTypeSet={page.permissionTypeSet}
                                    roles={page.roles}
                                    element={page.element}
                                />
                            </Suspense>
                        }
                    />
                ))}
            </Route>
            {/*<Route path="reset" element={<ResetPassword />} />*/}
            <Route path="login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default ApplicationRoutes;
