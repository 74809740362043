import { Middleware } from 'redux';
import { ITier } from '../../../../entities/emissionTypes/baseType';
import {
    IVisitorActivity,
    IVisitorActivityType,
    IVisitorDatabase,
    IVisitorFuelType,
    IVisitorResult,
    IVisitorUnit,
} from '../../../../entities/emissionTypes/visitorCombustion';

import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_VISITOR_ACTIVITIES,
    GET_VISITOR_ACTIVITY_TYPES,
    GET_VISITOR_DATABASES,
    GET_VISITOR_FUEL_TYPES,
    GET_VISITOR_TIERS,
    GET_VISITOR_UNITS,
    setVisitorActivities,
    setVisitorActivityTypes,
    setVisitorDatabases,
    setVisitorFuelTypes,
    setVisitorResult,
    setVisitorTiers,
    setVisitorUnits,
    SUBMIT_VISITOR_FORM,
} from '../../../actions/emission/visitor.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const emissionVisitorMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            // region VISITOR
            case GET_VISITOR_TIERS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/common/data/tiers',
                        feature: GET_VISITOR_TIERS,
                        query: {
                            emissionCategoryId: 10,
                        },
                    })
                );
                break;
            case `${GET_VISITOR_TIERS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<ITier>,
                        v: {
                            id: number;
                            language: string;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setVisitorTiers(list));
                break;
            }

            case GET_VISITOR_DATABASES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/emissionFactorDatabases',
                        feature: GET_VISITOR_DATABASES,
                        query: {
                            tierId: action.payload.id,
                            emissionCategoryId: 10,
                        },
                    })
                );
                break;

            case `${GET_VISITOR_DATABASES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IVisitorDatabase>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setVisitorDatabases(list));
                break;
            }

            case GET_VISITOR_ACTIVITIES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'visitorAndCustomerTransportationEmission/data/emissionSources',
                        feature: GET_VISITOR_ACTIVITIES,
                        query: {
                            emissionFactorDatabaseId: action.payload.id || state.emission.visitor.databases[0].id,
                        },
                    })
                );
                break;

            case `${GET_VISITOR_ACTIVITIES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IVisitorActivity>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            title: v.title,
                        });
                        return acc;
                    },
                    []
                );
                next(setVisitorActivities(list));
                break;
            }

            case GET_VISITOR_ACTIVITY_TYPES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'visitorAndCustomerTransportationEmission/data/emissionActivities',
                        feature: GET_VISITOR_ACTIVITY_TYPES,
                        query: {
                            emissionSourceId: action.payload.id,
                        },
                    })
                );
                break;

            case `${GET_VISITOR_ACTIVITY_TYPES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IVisitorActivityType>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setVisitorActivityTypes(list));
                break;
            }

            case GET_VISITOR_FUEL_TYPES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'visitorAndCustomerTransportationEmission/data/emissionActivityTypes',
                        feature: GET_VISITOR_FUEL_TYPES,
                        query: {
                            emissionActivityId: action.payload.id,
                        },
                    })
                );
                break;

            case `${GET_VISITOR_FUEL_TYPES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IVisitorFuelType>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setVisitorFuelTypes(list));
                break;
            }

            case GET_VISITOR_UNITS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'visitorAndCustomerTransportationEmission/data/units',
                        feature: GET_VISITOR_UNITS,
                        query: {
                            emissionActivityTypeId: action.payload?.id || state.emission.visitor.sourceList[0]?.id,
                        },
                    })
                );
                break;

            case `${GET_VISITOR_UNITS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IVisitorUnit>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                            arg0: string;
                            arg1?: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            arg0: v.arg0,
                            arg1: v?.arg1,
                        });
                        return acc;
                    },
                    []
                );
                next(setVisitorUnits(list));
                break;
            }

            case SUBMIT_VISITOR_FORM: {
                interface IBody {
                    organizationId?: number;
                    companyId: number;
                    facilityId: number;
                    consumptionList: Array<{ arg0: number; arg1: number; period: string }>;
                    tierId: number;
                    emissionFactorDatabaseId: number;
                    emissionSourceId: number;
                    emissionActivityId: number;
                    emissionActivityTypeId: number;
                    emissionCategoryId: number;
                    unitId: number;
                    equivalentFactor?: number;
                    tags: string;
                    equivalentTags: string;
                    description: string;
                    activityData: string;
                }

                const body: IBody = {
                    organizationId:
                        state?.auth?.userInfo?.organization?.id ||
                        state?.auth?.userInfo?.company?.organizationId ||
                        //state?.auth?.userInfo?.user?.organizationId ||
                        null,
                    companyId: state?.emission?.base?.selectedCompany?.id,
                    facilityId: state?.emission?.base?.selectedFacility?.id,
                    consumptionList: [],
                    tierId: state.emission.visitor.tier.id,
                    emissionFactorDatabaseId:
                        state.emission.visitor.database?.id || state.emission.visitor.databases[0]?.id,
                    emissionSourceId: state.emission.visitor.activity.id,
                    emissionActivityId: state.emission.visitor.activityType?.id,
                    emissionActivityTypeId:
                        state.emission.visitor.source?.id || state.emission.visitor.sourceList[0]?.id,
                    emissionCategoryId: 10,
                    unitId: state.emission.visitor.unit?.id,
                    equivalentFactor: state.emission.visitor.equivalentFactor || null,
                    tags: state.emission.base.tags || '',
                    equivalentTags: state.emission.base.equivalentTags || '',
                    description: state.emission.visitor.description,
                    activityData: state.emission.visitor.activityData,
                };
                const consumptions = state.emission.visitor.consumptions;
                const consumptionKeys: Array<string> = Object.keys(consumptions as { [key: number]: number | null });
                const consumptionData = consumptionKeys.reduce(
                    (acc: Array<{ arg0: number; arg1: number; period: string }>, key: string) => {
                        const numberKey = Number(key);
                        if (consumptions[key]['km'] && numberKey !== 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['km']),
                                arg1: Number(consumptions[key]['tonne']),
                                period: `${state?.emission?.base?.calculationYear}-${numberKey + 1 < 10 ? '0' : ''}${
                                    numberKey + 1
                                }`,
                            });
                        }
                        if (consumptions[key]['km'] && numberKey === 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['km']),
                                arg1: Number(consumptions[key]['tonne']),
                                period: state?.emission?.base?.calculationYear,
                            });
                        }
                        return acc;
                    },
                    []
                );
                body.consumptionList = consumptionData;
                next(
                    apiRequest({
                        method: 'POST',
                        url: 'visitorAndCustomerTransportationEmission/calculate',
                        feature: SUBMIT_VISITOR_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_VISITOR_FORM} ${API_SUCCESS}`: {
                const data = action.payload.corporateEmissionDto.emissionList;
                const onSuccess = action.meta.onSuccess;
                const savedData: Array<IVisitorResult> = [...data];
                next(setVisitorResult(savedData));
                onSuccess();
                break;
            }

            case `${SUBMIT_VISITOR_FORM} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }
            // endregion VISITOR
        }
    };
