import { AnyAction } from 'redux';
import {
    CHANGE_WASTE_CONSUMPTION_AMOUNT,
    CHANGE_WASTE_DESCRIPTION,
    CHANGE_WASTE_DISTANCE,
    CHANGE_WASTE_EQUIVALENT_FACTOR,
    CHANGE_WASTE_MAIN_FACILITY,
    CHANGE_WASTE_SUB_FACILITY,
    CHANGE_WASTE_WEIGHT,
    CHANGE_WASTE_YEAR,
    CLEAR_WASTE_CONSUMPTION_AMOUNT,
    CLEAR_WASTE_INPUT,
    SELECT_WASTE_ACTIVITY,
    SELECT_WASTE_ACTIVITY_DATA,
    SELECT_WASTE_ACTIVITY_TYPE,
    SELECT_WASTE_DATABASE,
    SELECT_WASTE_FUEL_TYPE,
    SELECT_WASTE_LADEN_PERCENTAGE,
    SELECT_WASTE_SIZE,
    SELECT_WASTE_TIER,
    SELECT_WASTE_UNIT,
    SET_WASTE_ACTIVITIES,
    SET_WASTE_ACTIVITY_TYPES,
    SET_WASTE_ADDITIONAL_CONSTANTS,
    SET_WASTE_DATABASES,
    SET_WASTE_FUEL_TYPES,
    SET_WASTE_LADEN_PERCENTAGES,
    SET_WASTE_RESULT,
    SET_WASTE_SIZES,
    SET_WASTE_TIERS,
    SET_WASTE_UNITS,
} from '../../actions/emission/waste.action';
import { CLEAR_BASE_INPUTS } from '../../actions/emission/base.action';
import { clearInput } from './base.reducer';
import { IWasteAdditional } from '../../../entities/emissionTypes/wasteCombustion';

const initialState = {
    mainFacility: '',
    subFacility: '',
    tiers: [],
    tier: '',
    sectorList: [],
    sector: '',
    emissionSource: '',
    sourceList: [],
    source: '',
    databases: [],
    database: '',
    activityList: [],
    activity: '',
    activityTypeList: [],
    activityType: '',
    activityData: '',
    unitList: [],
    unit: '',
    weight: '',
    distance: '',
    year: '',
    equivalentFactor: '',
    consumptions: {
        0: { tonne: null, km: null },
        1: { tonne: null, km: null },
        2: { tonne: null, km: null },
        3: { tonne: null, km: null },
        4: { tonne: null, km: null },
        5: { tonne: null, km: null },
        6: { tonne: null, km: null },
        7: { tonne: null, km: null },
        8: { tonne: null, km: null },
        9: { tonne: null, km: null },
        10: { tonne: null, km: null },
        11: { tonne: null, km: null },
        12: { tonne: null, km: null },
    },
    calculationDate: '',
    description: '',
    result: [],
    equivalentFactorUnit: '',
};

export const emissionWasteReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_WASTE_ADDITIONAL_CONSTANTS:
            let unit = '';
            action.payload.map((i: IWasteAdditional) => {
                if (i.identifier === 'CO2E') unit = i.unit || '';
            });
            return {
                ...state,
                equivalentFactorUnit: unit,
            };
        case CLEAR_WASTE_INPUT:
            const data = clearInput(action, initialState);
            return {
                ...state,
                ...data,
            };
        case CLEAR_BASE_INPUTS:
            return {
                ...initialState,
            };
        case CHANGE_WASTE_MAIN_FACILITY:
            return {
                ...state,
                mainFacility: action.payload,
            };
        case CHANGE_WASTE_SUB_FACILITY:
            return {
                ...state,
                subFacility: action.payload,
            };
        case SET_WASTE_TIERS:
            return {
                ...state,
                tiers: action.payload,
            };
        case SELECT_WASTE_TIER:
            return {
                ...state,
                tier: action.payload,
            };
        case SET_WASTE_DATABASES:
            return {
                ...state,
                databases: action.payload,
            };
        case SELECT_WASTE_DATABASE:
            return {
                ...state,
                database: action.payload,
            };
        case CHANGE_WASTE_EQUIVALENT_FACTOR:
            return {
                ...state,
                equivalentFactor: action.payload,
            };
        case SET_WASTE_ACTIVITIES:
            return {
                ...state,
                activityList: action.payload,
            };
        case SELECT_WASTE_ACTIVITY:
            return {
                ...state,
                activity: action.payload,
            };
        case SET_WASTE_FUEL_TYPES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_WASTE_FUEL_TYPE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_WASTE_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypeList: action.payload,
            };
        case SELECT_WASTE_ACTIVITY_TYPE:
            return {
                ...state,
                activityType: action.payload,
            };
        case SET_WASTE_UNITS:
            return {
                ...state,
                unitList: action.payload,
            };
        case SELECT_WASTE_UNIT:
            return {
                ...state,
                unit: action.payload,
            };
        case CHANGE_WASTE_WEIGHT:
            return {
                ...state,
                weight: action.payload,
            };
        case CHANGE_WASTE_DISTANCE:
            return {
                ...state,
                distance: action.payload,
            };
        case SET_WASTE_LADEN_PERCENTAGES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_WASTE_LADEN_PERCENTAGE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_WASTE_SIZES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_WASTE_SIZE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_WASTE_RESULT:
            return {
                ...state,
                result: action.payload,
            };
        case SELECT_WASTE_ACTIVITY_DATA:
            return {
                ...state,
                activityData: action.payload,
            };
        case CHANGE_WASTE_YEAR:
            return {
                ...state,
                year: action.payload,
            };
        case CHANGE_WASTE_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };

        case CHANGE_WASTE_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: {
                    ...state.consumptions,
                    [action.payload.month]: {
                        ...state.consumptions[action.payload.month as keyof typeof state.consumptions],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case CLEAR_WASTE_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: initialState.consumptions,
            };
        default:
            return state;
    }
};
