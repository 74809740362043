import { Middleware } from 'redux';
import http from '../../../infrastructure/http';
import { apiError, apiSuccess, API_REQUEST } from '../../actions/api.action';

export const apiMiddleware: Middleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        if (action.type.includes(API_REQUEST)) {
            const { query, body } = action.payload;
            const { url, method, feature, onSuccess, responseType } = action.meta;
            http(url, { method, params: query, data: body, responseType })
                .then((res) => dispatch(apiSuccess({ response: res.data, feature, onSuccess })))
                .catch((error) => dispatch(apiError({ error, feature })));
        }
    };
