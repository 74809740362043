import { AnyAction } from 'redux';
import {
    CHANGE_INVENTORY_DETAIL_ARG_0,
    CHANGE_INVENTORY_DETAIL_ARG_1,
    CHANGE_INVENTORY_DETAIL_ARG_2,
    CHANGE_UPDATE_ACTIVITY_DATA,
    CHANGE_UPDATE_CONSUMPTION_AMOUNT,
    CHANGE_UPDATE_DESCRIPTION,
    CHANGE_UPDATE_EQUIVALENT_FACTOR,
    CHANGE_UPDATE_EQUIVALENT_TAGS,
    CHANGE_UPDATE_INVENTORY,
    CHANGE_UPDATE_TAGS,
    CLEAR_UPDATE_INPUT,
    GET_INVENTORY_EMISSION_BY_ID,
    SET_INVENTORY_EMISSION_BY_ID,
    SET_UPDATE_EMISSION_RESULT,
    UPDATE_EMISSION,
    UPDATE_EMISSION_FUGITIVE,
} from '../../actions/emission/update.action';
import { CLEAR_BASE_INPUTS } from '../../actions/emission/base.action';
import { clearInput } from './base.reducer';
import { IDetailedEmission } from '../../../entities/emissionTypes/updatedCombustion';

const initialState = {
    consumptions: {
        // Fugitive
        0: { tonne: null, km: null },
        1: { tonne: null, km: null },
        2: { tonne: null, km: null },
        3: { tonne: null, km: null },
        4: { tonne: null, km: null },
        5: { tonne: null, km: null },
        6: { tonne: null, km: null },
        7: { tonne: null, km: null },
        8: { tonne: null, km: null },
        9: { tonne: null, km: null },
        10: { tonne: null, km: null },
        11: { tonne: null, km: null },
        12: { tonne: null, km: null },
    },
    consumptionsGeneral: {
        arg0: 0,
        arg1: 0,
        arg2: 0,
        period: '',
        emissionCalculationArgumentSet: [
            {
                identifier: '',
                value: 0,
                unit: '',
                reference: '',
            },
        ],
    },
    inventoryDetail: {} as IDetailedEmission,
    updateInventory: false,
};

export const updateEmissionReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case CLEAR_UPDATE_INPUT:
            const data = clearInput(action, initialState);
            return {
                ...state,
                ...data,
            };
        case CLEAR_BASE_INPUTS:
            return {
                ...initialState,
            };
        case UPDATE_EMISSION:
            return {
                ...state,
            };

        case UPDATE_EMISSION_FUGITIVE:
            return {
                ...state,
            };

        case SET_UPDATE_EMISSION_RESULT:
            return {
                ...state,
            };

        case CHANGE_UPDATE_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: {
                    ...state.consumptions,
                    [action.payload.month]: {
                        ...state.consumptions[action.payload.month as keyof typeof state.consumptions],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };

        case CHANGE_UPDATE_EQUIVALENT_FACTOR:
            return {
                ...state,
                //equivalentFactor: action.payload,
            };

        case CHANGE_UPDATE_EQUIVALENT_TAGS:
            return {
                ...state,
                //equivalentTags: action.payload,
            };

        case CHANGE_UPDATE_TAGS:
            return {
                ...state,
                //tags: action.payload,
            };

        case CHANGE_UPDATE_DESCRIPTION:
            return {
                ...state,
                //description: action.payload,
            };

        case CHANGE_UPDATE_ACTIVITY_DATA:
            return {
                ...state,
                //activityData: action.payload,
            };

        case GET_INVENTORY_EMISSION_BY_ID:
            return {
                ...state,
                inventoryDetail: {
                    ...state.inventoryDetail,
                    isLoading: true,
                },
            };
        case SET_INVENTORY_EMISSION_BY_ID: {
            const data = action.payload;
            const arg0 = data?.argumentList?.filter((arg: any) => arg?.argumentType === 'ARG_0')[0]?.value;
            const arg1 = data?.argumentList?.filter((arg: any) => arg?.argumentType === 'ARG_1')[0]?.value;
            const arg2 = data?.argumentList?.filter((arg: any) => arg?.argumentType === 'ARG_2')[0]?.value;
            const period = data?.period;
            const emissionCalculationArgumentSet = data?.emissionCalculationArgumentSet;

            return {
                ...state,
                inventoryDetail: {
                    ...action.payload,
                    isLoading: false,
                },
                consumptionsGeneral: {
                    ...state.consumptionsGeneral,
                    arg0: Number(arg0),
                    arg1: Number(arg1),
                    arg2: Number(arg2),
                    period,
                    emissionCalculationArgumentSet,
                },
            };
        }
        case CHANGE_UPDATE_INVENTORY:
            return {
                ...state,
                updateInventory: action.payload,
            };
        case CHANGE_INVENTORY_DETAIL_ARG_0:
            return {
                ...state,
                consumptionsGeneral: {
                    ...state.consumptionsGeneral,
                    arg0: action.payload,
                },
            };

        case CHANGE_INVENTORY_DETAIL_ARG_1:
            return {
                ...state,
                consumptionsGeneral: {
                    ...state.consumptionsGeneral,
                    arg1: action.payload,
                },
            };

        case CHANGE_INVENTORY_DETAIL_ARG_2:
            return {
                ...state,
                consumptionsGeneral: {
                    ...state.consumptionsGeneral,
                    arg2: action.payload,
                },
            };
        default:
            return state;
    }
};
