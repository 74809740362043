import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IDownstreamActivity,
    IDownstreamActivityType,
    IDownstreamDatabase,
    IDownstreamFuelType,
    IDownstreamResult,
    IDownstreamSize,
    IDownstreamUnit,
} from '../../../entities/emissionTypes/downstreamCombustion';
import { EMISSION } from './base.action';

export const DOWNSTREAM = 'DOWNSTREAM';

////-- Base
export const CHANGE_DOWNSTREAM_MAIN_FACILITY = `${EMISSION} ${DOWNSTREAM} Change Main Facility`;
export const CHANGE_DOWNSTREAM_SUB_FACILITY = `${EMISSION} ${DOWNSTREAM} Change Sub Facility`;

export const GET_DOWNSTREAM_TIERS = `${EMISSION} ${DOWNSTREAM} Get Tiers`;
export const SET_DOWNSTREAM_TIERS = `${EMISSION} ${DOWNSTREAM} Set Tiers`;
export const SELECT_DOWNSTREAM_TIER = `${EMISSION} ${DOWNSTREAM} Select Tier`;

export const GET_DOWNSTREAM_DATABASES = `${EMISSION} ${DOWNSTREAM} Get Databases`;
export const SET_DOWNSTREAM_DATABASES = `${EMISSION} ${DOWNSTREAM} Set Databases`;
export const SELECT_DOWNSTREAM_DATABASE = `${EMISSION} ${DOWNSTREAM} Select Database`;

export const CHANGE_DOWNSTREAM_EQUIVALENT_FACTOR = `${EMISSION} ${DOWNSTREAM} Change Equivalent Factor`;

export const GET_DOWNSTREAM_ACTIVITIES = `${EMISSION} ${DOWNSTREAM} Get Activities`;
export const SET_DOWNSTREAM_ACTIVITIES = `${EMISSION} ${DOWNSTREAM} Set Activities`;
export const SELECT_DOWNSTREAM_ACTIVITY = `${EMISSION} ${DOWNSTREAM} Select Activity`;
/////--- End base

export const GET_DOWNSTREAM_FUEL_TYPES = `${EMISSION} ${DOWNSTREAM} Get Fuel Types`;
export const SET_DOWNSTREAM_FUEL_TYPES = `${EMISSION} ${DOWNSTREAM} Set Fuel Types`;
export const SELECT_DOWNSTREAM_FUEL_TYPE = `${EMISSION} ${DOWNSTREAM} Select Fuel Type`;

export const GET_DOWNSTREAM_ACTIVITY_TYPES = `${EMISSION} ${DOWNSTREAM} Get Activity Types`;
export const SET_DOWNSTREAM_ACTIVITY_TYPES = `${EMISSION} ${DOWNSTREAM} Set Activity Types`;
export const SELECT_DOWNSTREAM_ACTIVITY_TYPE = `${EMISSION} ${DOWNSTREAM} Select Activity Type`;

export const GET_DOWNSTREAM_UNITS = `${EMISSION} ${DOWNSTREAM} Get Units`;
export const SET_DOWNSTREAM_UNITS = `${EMISSION} ${DOWNSTREAM} Set Units`;
export const SELECT_DOWNSTREAM_UNIT = `${EMISSION} ${DOWNSTREAM} Select Unit`;

export const CHANGE_DOWNSTREAM_WEIGHT = `${EMISSION} ${DOWNSTREAM} Change Weight`;
export const CHANGE_DOWNSTREAM_DISTANCE = `${EMISSION} ${DOWNSTREAM} Change Distance`;

export const GET_DOWNSTREAM_LADEN_PERCENTAGES = `${EMISSION} ${DOWNSTREAM} Get Laden Percentages`;
export const SET_DOWNSTREAM_LADEN_PERCENTAGES = `${EMISSION} ${DOWNSTREAM} Set Laden Percentages`;
export const SELECT_DOWNSTREAM_LADEN_PERCENTAGE = `${EMISSION} ${DOWNSTREAM} Select Laden percentage`;

export const GET_DOWNSTREAM_SIZES = `${EMISSION} ${DOWNSTREAM} Get Sizes`;
export const SET_DOWNSTREAM_SIZES = `${EMISSION} ${DOWNSTREAM} Set Sizes`;
export const SELECT_DOWNSTREAM_SIZE = `${EMISSION} ${DOWNSTREAM} Select Size`;

////-- Base
export const CHANGE_DOWNSTREAM_YEAR = `${EMISSION} ${DOWNSTREAM} Change Year`;
export const SELECT_DOWNSTREAM_ACTIVITY_DATA = `${EMISSION} ${DOWNSTREAM} Select Activity Data`;
export const CHANGE_DOWNSTREAM_CONSUMPTION_AMOUNT = `${EMISSION} ${DOWNSTREAM} Change Consumption Amount`;
export const CHANGE_DOWNSTREAM_DESCRIPTION = `${EMISSION} ${DOWNSTREAM} Change Description`;

export const SUBMIT_DOWNSTREAM_FORM = `${EMISSION} ${DOWNSTREAM} Submit Form`;
export const SET_DOWNSTREAM_RESULT = `${EMISSION} ${DOWNSTREAM} Set Result`;
/////-- End Base
export const CLEAR_DOWNSTREAM_CONSUMPTION_AMOUNT = `${EMISSION} ${DOWNSTREAM} Clear Consumption Amount`;
export const CLEAR_DOWNSTREAM_INPUT= `${EMISSION} ${DOWNSTREAM} Clear Downstream Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearDownstreamInput = (list: Array<string>) => ({
    type: CLEAR_DOWNSTREAM_INPUT,
    payload: list,
});
/// Region Downstream
export const changeDownstreamMainFacility = (text: string) => ({
    type: CHANGE_DOWNSTREAM_MAIN_FACILITY,
    payload: text,
});

export const changeDownstreamSubFacility = (text: string) => {
    return {
        type: CHANGE_DOWNSTREAM_SUB_FACILITY,
        payload: text,
    };
};

export const getDownstreamTiers = () => ({
    type: GET_DOWNSTREAM_TIERS,
});

export const setDownstreamTiers = (list: Array<ITier>) => ({
    type: SET_DOWNSTREAM_TIERS,
    payload: list,
});

export const selectDownstreamTier = (tier: ITier) => ({
    type: SELECT_DOWNSTREAM_TIER,
    payload: tier,
});

export const getDownstreamDatabases = (tier: ITier) => ({
    type: GET_DOWNSTREAM_DATABASES,
    payload: tier,
});

export const setDownstreamDatabases = (list: Array<IDownstreamDatabase>) => ({
    type: SET_DOWNSTREAM_DATABASES,
    payload: list,
});

export const selectDownstreamDatabase = (database: IDownstreamDatabase) => ({
    type: SELECT_DOWNSTREAM_DATABASE,
    payload: database,
});

export const getDownstreamActivities = (tier: ITier) => ({
    type: GET_DOWNSTREAM_ACTIVITIES,
    payload: tier,
});

export const setDownstreamActivities = (activities: Array<IDownstreamActivity>) => ({
    type: SET_DOWNSTREAM_ACTIVITIES,
    payload: activities,
});

export const selectDownstreamActivity = (activity: IDownstreamActivity) => ({
    type: SELECT_DOWNSTREAM_ACTIVITY,
    payload: activity,
});

///////
export const getDownstreamFuelTypes = (tier: ITier) => ({
    type: GET_DOWNSTREAM_FUEL_TYPES,
    payload: tier,
});

export const setDownstreamFuelTypes = (list: Array<IDownstreamFuelType>) => ({
    type: SET_DOWNSTREAM_FUEL_TYPES,
    payload: list,
});

export const selectDownstreamFuelType = (database: IDownstreamFuelType) => ({
    type: SELECT_DOWNSTREAM_FUEL_TYPE,
    payload: database,
});

export const getDownstreamActivityTypes = (value: any) => ({
    type: GET_DOWNSTREAM_ACTIVITY_TYPES,
    payload: value,
});

export const setDownstreamActivityTypes = (activities: Array<IDownstreamActivityType>) => ({
    type: SET_DOWNSTREAM_ACTIVITY_TYPES,
    payload: activities,
});

export const selectDownstreamActivityType = (activity: IDownstreamActivityType) => ({
    type: SELECT_DOWNSTREAM_ACTIVITY_TYPE,
    payload: activity,
});

export const getDownstreamUnits = (units: IDownstreamActivityType) => ({
    type: GET_DOWNSTREAM_UNITS,
    payload: units,
});

export const setDownstreamUnits = (units: Array<IDownstreamUnit>) => ({
    type: SET_DOWNSTREAM_UNITS,
    payload: units,
});

export const selectDownstreamUnit = (unit: IDownstreamUnit) => ({
    type: SELECT_DOWNSTREAM_UNIT,
    payload: unit,
});

export const changeDownstreamWeight = (value: number) => ({
    type: CHANGE_DOWNSTREAM_WEIGHT,
    payload: value,
});

export const changeDownstreamDistance = (value: number) => ({
    type: CHANGE_DOWNSTREAM_DISTANCE,
    payload: value,
});

export const getDownstreamLadenPercentages = (ladens: any) => ({
    type: GET_DOWNSTREAM_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setDownstreamLadenPercentages = (ladens: Array<any>) => ({
    type: SET_DOWNSTREAM_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectDownstreamLadenPercentage = (laden: any) => ({
    type: SELECT_DOWNSTREAM_LADEN_PERCENTAGE,
    payload: laden,
});

export const getDownstreamSizes = (sizes: IDownstreamActivityType) => ({
    type: GET_DOWNSTREAM_SIZES,
    payload: sizes,
});

export const setDownstreamSizes = (sizes: Array<IDownstreamSize>) => ({
    type: SET_DOWNSTREAM_SIZES,
    payload: sizes,
});

export const selectDownstreamSize = (size: IDownstreamSize) => ({
    type: SELECT_DOWNSTREAM_SIZE,
    payload: size,
});

///////
export const submitDownstreamForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_DOWNSTREAM_FORM,
    meta: {
        onSuccess,
    },
});

export const setDownstreamResult = (data: Array<IDownstreamResult>) => ({
    type: SET_DOWNSTREAM_RESULT,
    payload: data,
});

export const selectDownstreamActivityData = (activity: string) => ({
    type: SELECT_DOWNSTREAM_ACTIVITY_DATA,
    payload: activity,
});

export const changeDownstreamYear = (value: number) => ({
    type: CHANGE_DOWNSTREAM_YEAR,
    payload: value,
});

export const changeDownstreamDescription = (text: string) => ({
    type: CHANGE_DOWNSTREAM_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_DOWNSTREAM_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_DOWNSTREAM_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_DOWNSTREAM_CONSUMPTION_AMOUNT,
});
/// End Region Downstream
