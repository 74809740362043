import { Middleware } from 'redux';
import {
    IReportingCategory,
    IReportingEmission,
    IReportingFacility,
    IReportingResult,
    IReportingSubCategory,
} from '../../../../entities/company/reportingCombustion';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    DELETE_REPORTING,
    GET_REPORTING_CALCULATED_RESULT,
    GET_REPORTING_CATEGORY,
    GET_REPORTING_EMISSION,
    GET_REPORTING_FACILITY,
    GET_REPORTING_INFORMATION,
    GET_REPORTING_RESULT,
    GET_REPORTING_SUB_CATEGORY,
    setReportingCalculatedResult,
    setReportingCategory,
    setReportingEmission,
    setReportingFacility,
    setReportingInformation,
    setReportingResult,
    setReportingSubCategory,
    SUBMIT_REPORTING_FORM,
    SUBMIT_REPORTING_INFORMATION,
    UPDATE_REPORTING_FORM,
} from '../../../actions/company/reporting.action';
import { createNotification } from '../../../actions/ui.action';

export const reportingMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_REPORTING_RESULT: {
                const companyId = state.auth.userInfo?.company?.id;
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/boundry/report/get',
                        feature: GET_REPORTING_RESULT,
                        query: {
                            companyId,
                        },
                    })
                );
                break;
            }

            case `${GET_REPORTING_RESULT} ${API_SUCCESS}`: {
                const list = action.payload.companyBoundryReportList.reduce(
                    (
                        acc: Array<IReportingResult>,
                        v: {
                            id: number;
                            facility: { facilityName: string };
                            boundryCategory: string;
                            boundrySubCategory: string;
                            source: string;
                            activityData: string;
                            emission: string;
                            reason: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            facilityName: v.facility.facilityName,
                            boundryCategory: v.boundryCategory,
                            boundrySubCategory: v.boundrySubCategory,
                            source: v.source,
                            activityData: v.activityData,
                            emission: v.emission,
                            reason: v.reason,
                        });
                        return acc;
                    },
                    []
                );
                next(setReportingResult(list));
                break;
            }

            case GET_REPORTING_CALCULATED_RESULT: {
                const facilityIdList =
                    state.company.organizational?.selectedFacilities?.map((item: any) => item?.id) || [];
                const companyId = state.auth.userInfo?.company?.id;

                interface IBody {
                    companyId: number;
                    facilityIdList: Array<number>;
                    period: {
                        periodStart: string;
                        periodEnd: string;
                        includeYearlyValues: boolean;
                        onlyYearlyValues: boolean;
                    };
                }

                const body: IBody = {
                    companyId,
                    facilityIdList,
                    period: {
                        periodStart: state.company.company.period.start,
                        periodEnd: state.company.company.period.end,
                        includeYearlyValues: true,
                        onlyYearlyValues: false,
                    },
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporateEmission/inventory',
                        feature: GET_REPORTING_CALCULATED_RESULT,
                        body,
                    })
                );
                break;
            }

            case `${GET_REPORTING_CALCULATED_RESULT} ${API_SUCCESS}`: {
                const list = action.payload.corporateEmissionInventoryDtoList;
                next(setReportingCalculatedResult(list));
                break;
            }

            // TODO Kullanıcı Organizasyonel Sekmesini güncellemeli
            case `${GET_REPORTING_CALCULATED_RESULT} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message,
                    })
                );
                break;
            }

            case GET_REPORTING_FACILITY: {
                const companyId = state.auth.userInfo?.company?.id;
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/facility/list',
                        feature: GET_REPORTING_FACILITY,
                        query: {
                            companyId,
                        },
                    })
                );
                break;
            }

            case `${GET_REPORTING_FACILITY} ${API_SUCCESS}`: {
                const list = action.payload.facilityList.reduce(
                    (
                        acc: Array<IReportingFacility>,
                        v: {
                            id: number;
                            facilityName: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.facilityName,
                            label: v.facilityName,
                        });
                        return acc;
                    },
                    []
                );
                next(setReportingFacility(list));
                break;
            }

            case GET_REPORTING_CATEGORY: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/boundry/report/list/category',
                        feature: GET_REPORTING_CATEGORY,
                    })
                );
                break;
            }

            case `${GET_REPORTING_CATEGORY} ${API_SUCCESS}`: {
                const list = action.payload.categories.reduce(
                    (
                        acc: Array<IReportingCategory>,
                        v: {
                            id: number;
                            category: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.category,
                            label: v.category,
                        });
                        return acc;
                    },
                    []
                );
                next(setReportingCategory(list));
                break;
            }

            case GET_REPORTING_SUB_CATEGORY: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/boundry/report/list/subcategory',
                        feature: GET_REPORTING_SUB_CATEGORY,
                        query: {
                            categoryId: action.payload.id,
                        },
                    })
                );
                break;
            }

            case `${GET_REPORTING_SUB_CATEGORY} ${API_SUCCESS}`: {
                const list = action.payload.subCategories.reduce(
                    (
                        acc: Array<IReportingSubCategory>,
                        v: {
                            id: number;
                            category: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.category,
                            label: v.category,
                        });
                        return acc;
                    },
                    []
                );
                next(setReportingSubCategory(list));
                break;
            }

            case GET_REPORTING_EMISSION: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/boundry/report/list/emission',
                        feature: GET_REPORTING_EMISSION,
                    })
                );
                break;
            }

            case `${GET_REPORTING_EMISSION} ${API_SUCCESS}`: {
                const list = action.payload.emissions.reduce(
                    (
                        acc: Array<IReportingEmission>,
                        v: {
                            id: number;
                            emission: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.emission,
                            label: v.emission,
                        });
                        return acc;
                    },
                    []
                );
                next(setReportingEmission(list));
                break;
            }

            case UPDATE_REPORTING_FORM: {
                const companyId = state.auth.userInfo?.company?.id;
                const selectedId = action.payload;

                interface IBody {
                    id: number;
                    companyId: number;
                    categoryId: number;
                    subCategoryId: number;
                    emissionId: number;
                    facilityId: number;
                    source: string;
                    reason: string;
                    activity_data: string;
                    information: string;
                }

                const body: IBody = {
                    id: selectedId,
                    companyId,
                    categoryId: state.company.reporting.category?.id,
                    subCategoryId: state.company.reporting.subCategory?.id,
                    emissionId: state.company.reporting.emission?.id,
                    facilityId: state.company.reporting.facility?.id,
                    source: state.company.reporting?.source,
                    reason: state.company.reporting?.reason,
                    activity_data: state.company.reporting?.activity_data,
                    information: state.company.reporting?.information,
                };

                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/company/boundry/report/update',
                        feature: UPDATE_REPORTING_FORM,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }
            case `${UPDATE_REPORTING_FORM} ${API_SUCCESS}`: {
                const onSuccess = action.meta.onSuccess;
                onSuccess();
                window.location.reload();
                break;
            }

            case SUBMIT_REPORTING_INFORMATION: {
                const companyId = state.auth.userInfo?.company?.id;

                interface IBody {
                    companyId: number;
                    information: string;
                }

                const body: IBody = {
                    companyId,
                    information: state.company.reporting.information || '',
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/company/boundry/report/information',
                        feature: SUBMIT_REPORTING_INFORMATION,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }
            case `${SUBMIT_REPORTING_INFORMATION} ${API_SUCCESS}`: {
                const data = action.payload;
                const onSuccess = action.meta.onSuccess;
                onSuccess();
                window.location.reload();
                break;
            }

            case GET_REPORTING_INFORMATION: {
                const companyId = state.auth.userInfo?.company?.id;
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/boundry/report/get/information',
                        feature: GET_REPORTING_INFORMATION,
                        query: {
                            companyId,
                        },
                    })
                );
                break;
            }

            case `${GET_REPORTING_INFORMATION} ${API_SUCCESS}`: {
                const list = action.payload.companyBoundryReportInformation;
                next(setReportingInformation(list));
                break;
            }

            case SUBMIT_REPORTING_FORM: {
                const companyId = state.auth.userInfo?.company?.id;

                interface IBody {
                    companyId: number;
                    categoryId: number;
                    subCategoryId: number;
                    emissionId: number;
                    facilityId: number;
                    source: string;
                    reason: string;
                    activity_data: string;
                }

                const body: IBody = {
                    companyId,
                    categoryId: state.company.reporting.category?.id,
                    subCategoryId: state.company.reporting.subCategory?.id,
                    emissionId: state.company.reporting.emission?.id,
                    facilityId: state.company.reporting.facility?.id,
                    source: state.company.reporting.source,
                    reason: state.company.reporting.reason,
                    activity_data: state.company.reporting.activity_data,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/company/boundry/report/create',
                        feature: SUBMIT_REPORTING_FORM,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }
            case `${SUBMIT_REPORTING_FORM} ${API_SUCCESS}`: {
                const onSuccess = action.meta.onSuccess;
                onSuccess();
                window.location.reload();
                break;
            }

            case DELETE_REPORTING: {
                const companyId = state.auth.userInfo?.company?.id;
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: '/company/boundry/report/delete',
                        feature: DELETE_REPORTING,
                        onSuccess: action.meta.onSuccess,
                        query: { companyId, reportId: action.payload },
                    })
                );
                break;
            }
            case `${DELETE_REPORTING} ${API_SUCCESS}`: {
                const onSuccess = action.meta.onSuccess;
                onSuccess();
                window.location.reload();
                break;
            }
        }
    };
