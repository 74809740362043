import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IUpstreamActivity,
    IUpstreamActivityType,
    IUpstreamDatabase,
    IUpstreamFuelType,
    IUpstreamResult,
    IUpstreamSize,
    IUpstreamUnit,
} from '../../../entities/emissionTypes/upstreamCombustion';
import { EMISSION } from './base.action';
import { STATIC } from './static.action';

export const UPSTREAM = 'UPSTREAM';

////-- Base
export const CHANGE_UPSTREAM_MAIN_FACILITY = `${EMISSION} ${UPSTREAM} Change Main Facility`;
export const CHANGE_UPSTREAM_SUB_FACILITY = `${EMISSION} ${UPSTREAM} Change Sub Facility`;

export const GET_UPSTREAM_TIERS = `${EMISSION} ${UPSTREAM} Get Tiers`;
export const SET_UPSTREAM_TIERS = `${EMISSION} ${UPSTREAM} Set Tiers`;
export const SELECT_UPSTREAM_TIER = `${EMISSION} ${UPSTREAM} Select Tier`;

export const GET_UPSTREAM_DATABASES = `${EMISSION} ${UPSTREAM} Get Databases`;
export const SET_UPSTREAM_DATABASES = `${EMISSION} ${UPSTREAM} Set Databases`;
export const SELECT_UPSTREAM_DATABASE = `${EMISSION} ${UPSTREAM} Select Database`;

export const CHANGE_UPSTREAM_EQUIVALENT_FACTOR = `${EMISSION} ${UPSTREAM} Change Equivalent Factor`;

export const GET_UPSTREAM_ACTIVITIES = `${EMISSION} ${UPSTREAM} Get Activities`;
export const SET_UPSTREAM_ACTIVITIES = `${EMISSION} ${UPSTREAM} Set Activities`;
export const SELECT_UPSTREAM_ACTIVITY = `${EMISSION} ${UPSTREAM} Select Activity`;
/////--- End base

export const GET_UPSTREAM_FUEL_TYPES = `${EMISSION} ${UPSTREAM} Get Fuel Types`;
export const SET_UPSTREAM_FUEL_TYPES = `${EMISSION} ${UPSTREAM} Set Fuel Types`;
export const SELECT_UPSTREAM_FUEL_TYPE = `${EMISSION} ${UPSTREAM} Select Fuel Type`;

export const GET_UPSTREAM_ACTIVITY_TYPES = `${EMISSION} ${UPSTREAM} Get Activity Types`;
export const SET_UPSTREAM_ACTIVITY_TYPES = `${EMISSION} ${UPSTREAM} Set Activity Types`;
export const SELECT_UPSTREAM_ACTIVITY_TYPE = `${EMISSION} ${UPSTREAM} Select Activity Type`;

export const GET_UPSTREAM_UNITS = `${EMISSION} ${UPSTREAM} Get Units`;
export const SET_UPSTREAM_UNITS = `${EMISSION} ${UPSTREAM} Set Units`;
export const SELECT_UPSTREAM_UNIT = `${EMISSION} ${UPSTREAM} Select Unit`;

export const CHANGE_UPSTREAM_WEIGHT = `${EMISSION} ${UPSTREAM} Change Weight`;
export const CHANGE_UPSTREAM_DISTANCE = `${EMISSION} ${UPSTREAM} Change Distance`;

export const GET_UPSTREAM_LADEN_PERCENTAGES = `${EMISSION} ${UPSTREAM} Get Laden Percentages`;
export const SET_UPSTREAM_LADEN_PERCENTAGES = `${EMISSION} ${UPSTREAM} Set Laden Percentages`;
export const SELECT_UPSTREAM_LADEN_PERCENTAGE = `${EMISSION} ${UPSTREAM} Select Laden percentage`;

export const GET_UPSTREAM_SIZES = `${EMISSION} ${UPSTREAM} Get Sizes`;
export const SET_UPSTREAM_SIZES = `${EMISSION} ${UPSTREAM} Set Sizes`;
export const SELECT_UPSTREAM_SIZE = `${EMISSION} ${UPSTREAM} Select Size`;

////-- Base
export const CHANGE_UPSTREAM_YEAR = `${EMISSION} ${UPSTREAM} Change Year`;
export const SELECT_UPSTREAM_ACTIVITY_DATA = `${EMISSION} ${UPSTREAM} Select Activity Data`;
export const CHANGE_UPSTREAM_CONSUMPTION_AMOUNT = `${EMISSION} ${UPSTREAM} Change Consumption Amount`;
export const CHANGE_UPSTREAM_DESCRIPTION = `${EMISSION} ${UPSTREAM} Change Description`;

export const SUBMIT_UPSTREAM_FORM = `${EMISSION} ${UPSTREAM} Submit Form`;
export const SET_UPSTREAM_RESULT = `${EMISSION} ${UPSTREAM} Set Result`;
/////-- End Base
export const CLEAR_UPSTREAM_CONSUMPTION_AMOUNT = `${EMISSION} ${UPSTREAM} Clear Consumption Amount`;
export const CLEAR_UPSTREAM_INPUT = `${EMISSION} ${UPSTREAM} Clear Upstream Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearUpstreamInput = (list: Array<string>) => ({
    type: CLEAR_UPSTREAM_INPUT,
    payload: list,
});

/// Region Upstream
export const changeUpstreamMainFacility = (text: string) => ({
    type: CHANGE_UPSTREAM_MAIN_FACILITY,
    payload: text,
});

export const changeUpstreamSubFacility = (text: string) => {
    return {
        type: CHANGE_UPSTREAM_SUB_FACILITY,
        payload: text,
    };
};

export const getUpstreamTiers = () => ({
    type: GET_UPSTREAM_TIERS,
});

export const setUpstreamTiers = (list: Array<ITier>) => ({
    type: SET_UPSTREAM_TIERS,
    payload: list,
});

export const selectUpstreamTier = (tier: ITier) => ({
    type: SELECT_UPSTREAM_TIER,
    payload: tier,
});

export const getUpstreamDatabases = (tier: ITier) => ({
    type: GET_UPSTREAM_DATABASES,
    payload: tier,
});

export const setUpstreamDatabases = (list: Array<IUpstreamDatabase>) => ({
    type: SET_UPSTREAM_DATABASES,
    payload: list,
});

export const selectUpstreamDatabase = (database: IUpstreamDatabase) => ({
    type: SELECT_UPSTREAM_DATABASE,
    payload: database,
});

export const getUpstreamActivities = (tier: ITier) => ({
    type: GET_UPSTREAM_ACTIVITIES,
    payload: tier,
});

export const setUpstreamActivities = (activities: Array<IUpstreamActivity>) => ({
    type: SET_UPSTREAM_ACTIVITIES,
    payload: activities,
});

export const selectUpstreamActivity = (activity: IUpstreamActivity) => ({
    type: SELECT_UPSTREAM_ACTIVITY,
    payload: activity,
});

///////
export const getUpstreamFuelTypes = (tier: ITier) => ({
    type: GET_UPSTREAM_FUEL_TYPES,
    payload: tier,
});

export const setUpstreamFuelTypes = (list: Array<IUpstreamFuelType>) => ({
    type: SET_UPSTREAM_FUEL_TYPES,
    payload: list,
});

export const selectUpstreamFuelType = (database: IUpstreamFuelType) => ({
    type: SELECT_UPSTREAM_FUEL_TYPE,
    payload: database,
});

export const getUpstreamActivityTypes = (value: any) => ({
    type: GET_UPSTREAM_ACTIVITY_TYPES,
    payload: value,
});

export const setUpstreamActivityTypes = (activities: Array<IUpstreamActivityType>) => ({
    type: SET_UPSTREAM_ACTIVITY_TYPES,
    payload: activities,
});

export const selectUpstreamActivityType = (activity: IUpstreamActivityType) => ({
    type: SELECT_UPSTREAM_ACTIVITY_TYPE,
    payload: activity,
});

export const getUpstreamUnits = (units: IUpstreamActivityType) => ({
    type: GET_UPSTREAM_UNITS,
    payload: units,
});

export const setUpstreamUnits = (units: Array<IUpstreamUnit>) => ({
    type: SET_UPSTREAM_UNITS,
    payload: units,
});

export const selectUpstreamUnit = (unit: IUpstreamUnit) => ({
    type: SELECT_UPSTREAM_UNIT,
    payload: unit,
});

export const changeUpstreamWeight = (value: number) => ({
    type: CHANGE_UPSTREAM_WEIGHT,
    payload: value,
});

export const changeUpstreamDistance = (value: number) => ({
    type: CHANGE_UPSTREAM_DISTANCE,
    payload: value,
});

export const getUpstreamLadenPercentages = (ladens: any) => ({
    type: GET_UPSTREAM_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setUpstreamLadenPercentages = (ladens: Array<any>) => ({
    type: SET_UPSTREAM_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectUpstreamLadenPercentage = (laden: any) => ({
    type: SELECT_UPSTREAM_LADEN_PERCENTAGE,
    payload: laden,
});

export const getUpstreamSizes = (sizes: IUpstreamActivityType) => ({
    type: GET_UPSTREAM_SIZES,
    payload: sizes,
});

export const setUpstreamSizes = (sizes: Array<IUpstreamSize>) => ({
    type: SET_UPSTREAM_SIZES,
    payload: sizes,
});

export const selectUpstreamSize = (size: IUpstreamSize) => ({
    type: SELECT_UPSTREAM_SIZE,
    payload: size,
});

///////
export const submitUpstreamForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_UPSTREAM_FORM,
    meta: {
        onSuccess,
    },
});

export const setUpstreamResult = (data: Array<IUpstreamResult>) => ({
    type: SET_UPSTREAM_RESULT,
    payload: data,
});

export const selectUpstreamActivityData = (activity: string) => ({
    type: SELECT_UPSTREAM_ACTIVITY_DATA,
    payload: activity,
});

export const changeUpstreamYear = (value: number) => ({
    type: CHANGE_UPSTREAM_YEAR,
    payload: value,
});

export const changeUpstreamDescription = (text: string) => ({
    type: CHANGE_UPSTREAM_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_UPSTREAM_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_UPSTREAM_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_UPSTREAM_CONSUMPTION_AMOUNT,
});
/// End Region Upstream
