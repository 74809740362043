import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IVisitorActivity,
    IVisitorActivityType,
    IVisitorDatabase,
    IVisitorFuelType,
    IVisitorResult,
    IVisitorSize,
    IVisitorUnit,
} from '../../../entities/emissionTypes/visitorCombustion';
import { EMISSION } from './base.action';
import { STATIC } from './static.action';

export const VISITOR = 'VISITOR';

////-- Base
export const CHANGE_VISITOR_MAIN_FACILITY = `${EMISSION} ${VISITOR} Change Main Facility`;
export const CHANGE_VISITOR_SUB_FACILITY = `${EMISSION} ${VISITOR} Change Sub Facility`;

export const GET_VISITOR_TIERS = `${EMISSION} ${VISITOR} Get Tiers`;
export const SET_VISITOR_TIERS = `${EMISSION} ${VISITOR} Set Tiers`;
export const SELECT_VISITOR_TIER = `${EMISSION} ${VISITOR} Select Tier`;

export const GET_VISITOR_DATABASES = `${EMISSION} ${VISITOR} Get Databases`;
export const SET_VISITOR_DATABASES = `${EMISSION} ${VISITOR} Set Databases`;
export const SELECT_VISITOR_DATABASE = `${EMISSION} ${VISITOR} Select Database`;

export const CHANGE_VISITOR_EQUIVALENT_FACTOR = `${EMISSION} ${VISITOR} Change Equivalent Factor`;

export const GET_VISITOR_ACTIVITIES = `${EMISSION} ${VISITOR} Get Activities`;
export const SET_VISITOR_ACTIVITIES = `${EMISSION} ${VISITOR} Set Activities`;
export const SELECT_VISITOR_ACTIVITY = `${EMISSION} ${VISITOR} Select Activity`;
/////--- End base

export const GET_VISITOR_FUEL_TYPES = `${EMISSION} ${VISITOR} Get Fuel Types`;
export const SET_VISITOR_FUEL_TYPES = `${EMISSION} ${VISITOR} Set Fuel Types`;
export const SELECT_VISITOR_FUEL_TYPE = `${EMISSION} ${VISITOR} Select Fuel Type`;

export const GET_VISITOR_ACTIVITY_TYPES = `${EMISSION} ${VISITOR} Get Activity Types`;
export const SET_VISITOR_ACTIVITY_TYPES = `${EMISSION} ${VISITOR} Set Activity Types`;
export const SELECT_VISITOR_ACTIVITY_TYPE = `${EMISSION} ${VISITOR} Select Activity Type`;

export const GET_VISITOR_UNITS = `${EMISSION} ${VISITOR} Get Units`;
export const SET_VISITOR_UNITS = `${EMISSION} ${VISITOR} Set Units`;
export const SELECT_VISITOR_UNIT = `${EMISSION} ${VISITOR} Select Unit`;

export const CHANGE_VISITOR_WEIGHT = `${EMISSION} ${VISITOR} Change Weight`;
export const CHANGE_VISITOR_DISTANCE = `${EMISSION} ${VISITOR} Change Distance`;

export const GET_VISITOR_LADEN_PERCENTAGES = `${EMISSION} ${VISITOR} Get Laden Percentages`;
export const SET_VISITOR_LADEN_PERCENTAGES = `${EMISSION} ${VISITOR} Set Laden Percentages`;
export const SELECT_VISITOR_LADEN_PERCENTAGE = `${EMISSION} ${VISITOR} Select Laden percentage`;

export const GET_VISITOR_SIZES = `${EMISSION} ${VISITOR} Get Sizes`;
export const SET_VISITOR_SIZES = `${EMISSION} ${VISITOR} Set Sizes`;
export const SELECT_VISITOR_SIZE = `${EMISSION} ${VISITOR} Select Size`;

////-- Base
export const CHANGE_VISITOR_YEAR = `${EMISSION} ${VISITOR} Change Year`;
export const SELECT_VISITOR_ACTIVITY_DATA = `${EMISSION} ${VISITOR} Select Activity Data`;
export const CHANGE_VISITOR_CONSUMPTION_AMOUNT = `${EMISSION} ${VISITOR} Change Consumption Amount`;
export const CHANGE_VISITOR_DESCRIPTION = `${EMISSION} ${VISITOR} Change Description`;

export const SUBMIT_VISITOR_FORM = `${EMISSION} ${VISITOR} Submit Form`;
export const SET_VISITOR_RESULT = `${EMISSION} ${VISITOR} Set Result`;
/////-- End Base
export const CLEAR_VISITOR_CONSUMPTION_AMOUNT = `${EMISSION} ${VISITOR} Clear Consumption Amount`;
export const CLEAR_VISITOR_INPUT= `${EMISSION} ${VISITOR} Clear Visitor Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearVisitorInput = (list: Array<string>) => ({
    type: CLEAR_VISITOR_INPUT,
    payload: list,
});

/// Region Visitor
export const changeVisitorMainFacility = (text: string) => ({
    type: CHANGE_VISITOR_MAIN_FACILITY,
    payload: text,
});

export const changeVisitorSubFacility = (text: string) => {
    return {
        type: CHANGE_VISITOR_SUB_FACILITY,
        payload: text,
    };
};

export const getVisitorTiers = () => ({
    type: GET_VISITOR_TIERS,
});

export const setVisitorTiers = (list: Array<ITier>) => ({
    type: SET_VISITOR_TIERS,
    payload: list,
});

export const selectVisitorTier = (tier: ITier) => ({
    type: SELECT_VISITOR_TIER,
    payload: tier,
});

export const getVisitorDatabases = (tier: ITier) => ({
    type: GET_VISITOR_DATABASES,
    payload: tier,
});

export const setVisitorDatabases = (list: Array<IVisitorDatabase>) => ({
    type: SET_VISITOR_DATABASES,
    payload: list,
});

export const selectVisitorDatabase = (database: IVisitorDatabase) => ({
    type: SELECT_VISITOR_DATABASE,
    payload: database,
});

export const getVisitorActivities = (tier: ITier) => ({
    type: GET_VISITOR_ACTIVITIES,
    payload: tier,
});

export const setVisitorActivities = (activities: Array<IVisitorActivity>) => ({
    type: SET_VISITOR_ACTIVITIES,
    payload: activities,
});

export const selectVisitorActivity = (activity: IVisitorActivity) => ({
    type: SELECT_VISITOR_ACTIVITY,
    payload: activity,
});

///////
export const getVisitorFuelTypes = (tier: ITier) => ({
    type: GET_VISITOR_FUEL_TYPES,
    payload: tier,
});

export const setVisitorFuelTypes = (list: Array<IVisitorFuelType>) => ({
    type: SET_VISITOR_FUEL_TYPES,
    payload: list,
});

export const selectVisitorFuelType = (database: IVisitorFuelType) => ({
    type: SELECT_VISITOR_FUEL_TYPE,
    payload: database,
});

export const getVisitorActivityTypes = (value: any) => ({
    type: GET_VISITOR_ACTIVITY_TYPES,
    payload: value,
});

export const setVisitorActivityTypes = (activities: Array<IVisitorActivityType>) => ({
    type: SET_VISITOR_ACTIVITY_TYPES,
    payload: activities,
});

export const selectVisitorActivityType = (activity: IVisitorActivityType) => ({
    type: SELECT_VISITOR_ACTIVITY_TYPE,
    payload: activity,
});

export const getVisitorUnits = (units: IVisitorActivityType) => ({
    type: GET_VISITOR_UNITS,
    payload: units,
});

export const setVisitorUnits = (units: Array<IVisitorUnit>) => ({
    type: SET_VISITOR_UNITS,
    payload: units,
});

export const selectVisitorUnit = (unit: IVisitorUnit) => ({
    type: SELECT_VISITOR_UNIT,
    payload: unit,
});

export const changeVisitorWeight = (value: number) => ({
    type: CHANGE_VISITOR_WEIGHT,
    payload: value,
});

export const changeVisitorDistance = (value: number) => ({
    type: CHANGE_VISITOR_DISTANCE,
    payload: value,
});

export const getVisitorLadenPercentages = (ladens: any) => ({
    type: GET_VISITOR_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setVisitorLadenPercentages = (ladens: Array<any>) => ({
    type: SET_VISITOR_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectVisitorLadenPercentage = (laden: any) => ({
    type: SELECT_VISITOR_LADEN_PERCENTAGE,
    payload: laden,
});

export const getVisitorSizes = (sizes: IVisitorActivityType) => ({
    type: GET_VISITOR_SIZES,
    payload: sizes,
});

export const setVisitorSizes = (sizes: Array<IVisitorSize>) => ({
    type: SET_VISITOR_SIZES,
    payload: sizes,
});

export const selectVisitorSize = (size: IVisitorSize) => ({
    type: SELECT_VISITOR_SIZE,
    payload: size,
});

///////
export const submitVisitorForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_VISITOR_FORM,
    meta: {
        onSuccess,
    },
});

export const setVisitorResult = (data: Array<IVisitorResult>) => ({
    type: SET_VISITOR_RESULT,
    payload: data,
});

export const selectVisitorActivityData = (activity: string) => ({
    type: SELECT_VISITOR_ACTIVITY_DATA,
    payload: activity,
});

export const changeVisitorYear = (value: number) => ({
    type: CHANGE_VISITOR_YEAR,
    payload: value,
});

export const changeVisitorDescription = (text: string) => ({
    type: CHANGE_VISITOR_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_VISITOR_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_VISITOR_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_VISITOR_CONSUMPTION_AMOUNT,
});
/// End Region Visitor
