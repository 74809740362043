import { IPrivilegeDelete, IPrivilegeGroup, IPrivilegePut } from '../../../entities/system/privilegeGroupCombustion';

export const PRIVILEGE_GROUP = '[PRIVILEGE_GROUP]';

export const PUT_PRIVILEGE_GROUP_PRIVILEGE = `${PRIVILEGE_GROUP} Put Privilege Group Privilege`;
export const DELETE_PRIVILEGE_GROUP_PRIVILEGE = `${PRIVILEGE_GROUP} Delete Privilege Group Privilege`;

export const POST_PRIVILEGE_GROUP = `${PRIVILEGE_GROUP} Post Privilege Group`;
export const POST_PRIVILEGE_GROUP_NAMES = `${PRIVILEGE_GROUP} Post Privilege Group Names`;
export const SET_PRIVILEGE_GROUP_NAMES = `${PRIVILEGE_GROUP} Set Privilege Group Names`;

export const GET_PRIVILEGE_GROUP_BY_ID = `${PRIVILEGE_GROUP} Get Privilege Group By Id`;
export const SET_PRIVILEGE_GROUP_BY_ID = `${PRIVILEGE_GROUP} Set Privilege Group By Id`;
export const CLEAR_PRIVILEGE_GROUP_BY_ID = `${PRIVILEGE_GROUP} Clear Privilege Group By Id`;
export const DELETE_PRIVILEGE_GROUP_BY_ID = `${PRIVILEGE_GROUP} Delete Privilege Group By Id`;

export const CHANGE_PRIVILEGE_GROUP_DESCRIPTION = `${PRIVILEGE_GROUP} Change Privilege Group Description`;
export const CHANGE_PRIVILEGE_GROUP_NAME = `${PRIVILEGE_GROUP} Change Privilege Group Name`;
export const CLEAR_PRIVILEGE_GROUP_INPUTS = `${PRIVILEGE_GROUP} Clear Privilege Group Inputs`;

export type ISubmit = {
    onSuccess: () => void;
};

export const putPrivilegeGroupPrivilege = (data: IPrivilegePut) => ({
    type: PUT_PRIVILEGE_GROUP_PRIVILEGE,
    payload: data,
});
export const deletePrivilegeGroupPrivilege = (data: IPrivilegeDelete) => ({
    type: DELETE_PRIVILEGE_GROUP_PRIVILEGE,
    payload: data,
});
export const postPrivilegeGroup = ({ onSuccess }: ISubmit) => ({
    type: POST_PRIVILEGE_GROUP,
    meta: {
        onSuccess,
    },
});
export const postPrivilegeGroupNames = () => ({
    type: POST_PRIVILEGE_GROUP_NAMES,
});
export const setPrivilegeGroupNames = (list: Array<IPrivilegeGroup>) => ({
    type: SET_PRIVILEGE_GROUP_NAMES,
    payload: list,
});
export const getPrivilegeGroupById = (id: number) => ({
    type: GET_PRIVILEGE_GROUP_BY_ID,
    payload: id,
});
export const setPrivilegeGroupById = (list: Array<any>) => ({
    type: SET_PRIVILEGE_GROUP_BY_ID,
    payload: list,
});
export const clearPrivilegeGroupById = () => ({
    type: CLEAR_PRIVILEGE_GROUP_BY_ID,
});
export const deletePrivilegeGroupById = ({ onSuccess }: ISubmit, id: number) => ({
    type: DELETE_PRIVILEGE_GROUP_BY_ID,
    payload: id,
    meta: {
        onSuccess,
    },
});
export const changePrivilegeGroupDescription = (data: string) => ({
    type: CHANGE_PRIVILEGE_GROUP_DESCRIPTION,
    payload: data,
});
export const changePrivilegeGroupName = (data: string) => ({
    type: CHANGE_PRIVILEGE_GROUP_NAME,
    payload: data,
});
export const clearPrivilegeGroupInputs = () => ({
    type: CLEAR_PRIVILEGE_GROUP_INPUTS,
});
