import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    ILulucfActivity,
    ILulucfActivityType,
    ILulucfAdditional,
    ILulucfDatabase,
    ILulucfFuelType,
    ILulucfResult,
    ILulucfUnit,
} from '../../../entities/emissionTypes/lulucfCombustion';
import { EMISSION } from './base.action';
import { IStaticAdditional, IStaticUnit } from '../../../entities/emissionTypes/staticCombustion';
import { GET_STATIC_ADDITIONAL_CONSTANTS, SET_STATIC_ADDITIONAL_CONSTANTS } from './static.action';

export const LULUCF = 'LULUCF';

////-- Base
export const CHANGE_LULUCF_MAIN_FACILITY = `${EMISSION} ${LULUCF} Change Main Facility`;
export const CHANGE_LULUCF_SUB_FACILITY = `${EMISSION} ${LULUCF} Change Sub Facility`;

export const GET_LULUCF_TIERS = `${EMISSION} ${LULUCF} Get Tiers`;
export const SET_LULUCF_TIERS = `${EMISSION} ${LULUCF} Set Tiers`;
export const SELECT_LULUCF_TIER = `${EMISSION} ${LULUCF} Select Tier`;

export const GET_LULUCF_DATABASES = `${EMISSION} ${LULUCF} Get Databases`;
export const SET_LULUCF_DATABASES = `${EMISSION} ${LULUCF} Set Databases`;
export const SELECT_LULUCF_DATABASE = `${EMISSION} ${LULUCF} Select Database`;

export const GET_LULUCF_ACTIVITIES = `${EMISSION} ${LULUCF} Get Activities`;
export const SET_LULUCF_ACTIVITIES = `${EMISSION} ${LULUCF} Set Activities`;
export const SELECT_LULUCF_ACTIVITY = `${EMISSION} ${LULUCF} Select Activity`;
/////--- End base

export const GET_LULUCF_ACTIVITY_TYPES = `${EMISSION} ${LULUCF} Get Activity Types`;
export const SET_LULUCF_ACTIVITY_TYPES = `${EMISSION} ${LULUCF} Set Activity Types`;
export const SELECT_LULUCF_ACTIVITY_TYPE = `${EMISSION} ${LULUCF} Select Activity Type`;

export const GET_LULUCF_FUEL_TYPES = `${EMISSION} ${LULUCF} Get Fuel Types`;
export const SET_LULUCF_FUEL_TYPES = `${EMISSION} ${LULUCF} Set Fuel Types`;
export const SELECT_LULUCF_FUEL_TYPES = `${EMISSION} ${LULUCF} Select Fuel Type`;

export const GET_LULUCF_UNITS = `${EMISSION} ${LULUCF} Get Units`;
export const SET_LULUCF_UNITS = `${EMISSION} ${LULUCF} Set Units`;
export const SELECT_LULUCF_UNIT = `${EMISSION} ${LULUCF} Select Unit`;

export const GET_LULUCF_ADDITIONAL_CONSTANTS = `${EMISSION} ${LULUCF} Get Additional Constants`;
export const SET_LULUCF_ADDITIONAL_CONSTANTS = `${EMISSION} ${LULUCF} Set Additional Constants`;
////-- Base
export const CHANGE_LULUCF_YEAR = `${EMISSION} ${LULUCF} Change Year`;
export const SELECT_LULUCF_ACTIVITY_DATA = `${EMISSION} ${LULUCF} Select Activity Data`;
export const CHANGE_LULUCF_CONSUMPTION_AMOUNT = `${EMISSION} ${LULUCF} Change Consumption Amount`;
export const CHANGE_LULUCF_DESCRIPTION = `${EMISSION} ${LULUCF} Change Description`;

export const SUBMIT_LULUCF_FORM = `${EMISSION} ${LULUCF} Submit Form`;
export const SET_LULUCF_RESULT = `${EMISSION} ${LULUCF} Set Result`;
/////-- End Base
export const CHANGE_LULUCF_EQUIVALENT_FACTOR = `${EMISSION} ${LULUCF} Change Equivalent Factor`;
export const CLEAR_LULUCF_CONSUMPTION_AMOUNT = `${EMISSION} ${LULUCF} Clear Consumption Amount`;
export const CLEAR_LULUCF_INPUT = `${EMISSION} ${LULUCF} Clear Product Lifetime Input`;

export type ISubmit = {
    onSuccess: () => void;
};
/// Region Lulucf
export const clearLulucfInput = (list: Array<string>) => ({
    type: CLEAR_LULUCF_INPUT,
    payload: list,
});

export const changeLulucfMainFacility = (text: string) => ({
    type: CHANGE_LULUCF_MAIN_FACILITY,
    payload: text,
});

export const changeLulucfSubFacility = (text: string) => {
    return {
        type: CHANGE_LULUCF_SUB_FACILITY,
        payload: text,
    };
};

export const getLulucfTiers = () => ({
    type: GET_LULUCF_TIERS,
});

export const setLulucfTiers = (list: Array<ITier>) => ({
    type: SET_LULUCF_TIERS,
    payload: list,
});

export const selectLulucfTier = (tier: ITier) => ({
    type: SELECT_LULUCF_TIER,
    payload: tier,
});

export const getLulucfDatabases = (tier: ITier) => ({
    type: GET_LULUCF_DATABASES,
    payload: tier,
});

export const setLulucfDatabases = (list: Array<ILulucfDatabase>) => ({
    type: SET_LULUCF_DATABASES,
    payload: list,
});

export const selectLulucfDatabase = (database: ILulucfDatabase) => ({
    type: SELECT_LULUCF_DATABASE,
    payload: database,
});

export const getLulucfActivities = (tier: ITier) => ({
    type: GET_LULUCF_ACTIVITIES,
    payload: tier,
});

export const setLulucfActivities = (activities: Array<ILulucfActivity>) => ({
    type: SET_LULUCF_ACTIVITIES,
    payload: activities,
});

export const selectLulucfActivity = (activity: ILulucfActivity) => ({
    type: SELECT_LULUCF_ACTIVITY,
    payload: activity,
});

///////

export const getLulucfActivityTypes = (value: ILulucfActivity) => ({
    type: GET_LULUCF_ACTIVITY_TYPES,
    payload: value,
});

export const setLulucfActivityTypes = (activities: Array<ILulucfActivityType>) => ({
    type: SET_LULUCF_ACTIVITY_TYPES,
    payload: activities,
});

export const selectLulucfActivityType = (activity: ILulucfActivityType) => ({
    type: SELECT_LULUCF_ACTIVITY_TYPE,
    payload: activity,
});

export const getLulucfFuelTypes = (value: ILulucfActivityType) => ({
    type: GET_LULUCF_FUEL_TYPES,
    payload: value,
});

export const setLulucfFuelTypes = (fuelTypes: Array<ILulucfFuelType>) => ({
    type: SET_LULUCF_FUEL_TYPES,
    payload: fuelTypes,
});

export const selectLulucfFuelType = (fuelType: ILulucfFuelType) => ({
    type: SELECT_LULUCF_FUEL_TYPES,
    payload: fuelType,
});

export const getLulucfUnits = (units: ILulucfActivityType) => ({
    type: GET_LULUCF_UNITS,
    payload: units,
});

export const setLulucfUnits = (units: Array<ILulucfUnit>) => ({
    type: SET_LULUCF_UNITS,
    payload: units,
});

export const selectLulucfUnit = (unit: ILulucfUnit) => ({
    type: SELECT_LULUCF_UNIT,
    payload: unit,
});

export const getLulucfAdditionalConstants = (additional: ILulucfUnit) => ({
    type: GET_LULUCF_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setLulucfAdditionalConstants = (additional: Array<ILulucfAdditional>) => ({
    type: SET_LULUCF_ADDITIONAL_CONSTANTS,
    payload: additional,
});

///////
export const submitLulucfForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_LULUCF_FORM,
    meta: {
        onSuccess,
    },
});

export const setLulucfResult = (data: Array<ILulucfResult>) => ({
    type: SET_LULUCF_RESULT,
    payload: data,
});

export const selectLulucfActivityData = (activity: string) => ({
    type: SELECT_LULUCF_ACTIVITY_DATA,
    payload: activity,
});

export const changeLulucfYear = (value: number) => ({
    type: CHANGE_LULUCF_YEAR,
    payload: value,
});

export const changeLulucfDescription = (text: string) => ({
    type: CHANGE_LULUCF_DESCRIPTION,
    payload: text,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_LULUCF_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_LULUCF_EQUIVALENT_FACTOR,
    payload: value,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_LULUCF_CONSUMPTION_AMOUNT,
});
/// End Region Lulucf
