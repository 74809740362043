export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';

type IApiRequest = {
    body?: object;
    query?: object;
    method: string;
    url: string;
    feature: string;
    responseType?: string;
    onSuccess?: () => void;
};
const lang = localStorage.getItem("i18nextLng")
export const apiRequest = ({ body, query, method, url, feature, onSuccess, responseType }: IApiRequest) => ({
    type: `${feature} ${API_REQUEST}`,
    payload: { body, query: { ...(query || {}), language: lang?.includes("tr") ? "TURKISH":"ENGLISH" } },
    meta: { method, url, feature, onSuccess, responseType },
});

type IApiSuccess = {
    response: object;
    feature: string;
    onSuccess?: () => void;
};
export const apiSuccess = ({ response, feature, onSuccess }: IApiSuccess) => ({
    type: `${feature} ${API_SUCCESS}`,
    payload: response,
    meta: { feature, onSuccess },
});

type IApiError = {
    error: object;
    feature: string;
};
export const apiError = ({ error, feature }: IApiError) => ({
    type: `${feature} ${API_ERROR}`,
    payload: error,
    meta: { feature },
});
