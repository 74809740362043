import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { setAuthLogout } from '../redux/actions/auth/auth.action';
import { store } from '../redux/store';

const http = axios.create({
    baseURL: process.env.REACT_APP_API_END_POINT || '/api',
    timeout: 50000,
});

http.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const storeState = store.getState();
        const token = storeState.auth.loginInfo.token;
        config.headers = { Authorization: `Bearer ${token}` };
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error: AxiosError) {
        if (error.response && error.response.status === 401) {
            store.dispatch(setAuthLogout());
            // window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default http;
