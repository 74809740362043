import { AnyAction } from 'redux';
import {
    CHANGE_STEAM_CONSUMPTION_AMOUNT,
    CHANGE_STEAM_DESCRIPTION,
    CHANGE_STEAM_EQUIVALENT_FACTOR,
    CHANGE_STEAM_MAIN_FACILITY,
    CHANGE_STEAM_SUB_FACILITY,
    CHANGE_STEAM_TAGS,
    CHANGE_STEAM_YEAR,
    CLEAR_STEAM_CONSUMPTION_AMOUNT,
    CLEAR_STEAM_INPUT,
    SELECT_STEAM_ACTIVITY,
    SELECT_STEAM_ACTIVITY_DATA,
    SELECT_STEAM_ACTIVITY_TYPE,
    SELECT_STEAM_DATABASE,
    SELECT_STEAM_FUEL_TYPE,
    SELECT_STEAM_TIER,
    SELECT_STEAM_UNIT,
    SET_STEAM_ACTIVITIES,
    SET_STEAM_ACTIVITY_TYPES,
    SET_STEAM_ADDITIONAL_CONSTANTS,
    SET_STEAM_DATABASES,
    SET_STEAM_FUEL_TYPES,
    SET_STEAM_RESULT,
    SET_STEAM_TIERS,
    SET_STEAM_UNITS,
} from '../../actions/emission/steam.action';
import { CLEAR_BASE_INPUTS } from '../../actions/emission/base.action';
import { clearInput } from './base.reducer';
import { IStaticAdditional } from '../../../entities/emissionTypes/staticCombustion';

const initialState = {
    mainFacility: '',
    subFacility: '',
    tiers: [],
    tier: '',
    activityList: [],
    activity: '',
    activityTypeList: [],
    activityType: '',
    sourceList: [],
    source: '',
    unitList: [],
    unit: '',
    databases: [],
    database: '',
    quantity: '',
    activityData: '',
    description: '',
    year: '',
    equivalentFactor: '',
    consumptions: {
        0: { tonne: null, km: null },
        1: { tonne: null, km: null },
        2: { tonne: null, km: null },
        3: { tonne: null, km: null },
        4: { tonne: null, km: null },
        5: { tonne: null, km: null },
        6: { tonne: null, km: null },
        7: { tonne: null, km: null },
        8: { tonne: null, km: null },
        9: { tonne: null, km: null },
        10: { tonne: null, km: null },
        11: { tonne: null, km: null },
        12: { tonne: null, km: null },
    },
    result: [],
    tags: 'Isı/Buhar',
    equivalentFactorUnit: '',
};

export const emissionSteamReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_STEAM_ADDITIONAL_CONSTANTS:
            let unit = '';
            action.payload.map((i: IStaticAdditional) => {
                if (i.identifier === 'CO2E') unit = i.unit || '';
            });
            return {
                ...state,
                equivalentFactorUnit: unit,
            };
        case CLEAR_STEAM_INPUT:
            const data = clearInput(action, initialState);
            return {
                ...state,
                ...data,
            };
        case CLEAR_BASE_INPUTS:
            return {
                ...initialState,
            };
        case CHANGE_STEAM_MAIN_FACILITY:
            return {
                ...state,
                mainFacility: action.payload,
            };

        case CHANGE_STEAM_SUB_FACILITY:
            return {
                ...state,
                subFacility: action.payload,
            };

        case CHANGE_STEAM_TAGS:
            return {
                ...state,
                tags: action.payload,
            };

        case SET_STEAM_TIERS:
            return {
                ...state,
                tiers: action.payload,
            };
        case SELECT_STEAM_TIER:
            return {
                ...state,
                tier: action.payload,
            };

        case SET_STEAM_DATABASES:
            return {
                ...state,
                databases: action.payload,
            };
        case SELECT_STEAM_DATABASE:
            return {
                ...state,
                database: action.payload,
            };
        case CHANGE_STEAM_EQUIVALENT_FACTOR:
            return {
                ...state,
                equivalentFactor: action.payload,
            };

        case SET_STEAM_ACTIVITIES:
            return {
                ...state,
                activityList: action.payload,
            };
        case SELECT_STEAM_ACTIVITY:
            return {
                ...state,
                activity: action.payload,
            };

        case SELECT_STEAM_ACTIVITY_DATA:
            return {
                ...state,
                activityData: action.payload,
            };

        case SET_STEAM_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypeList: action.payload,
            };
        case SELECT_STEAM_ACTIVITY_TYPE:
            return {
                ...state,
                activityType: action.payload,
            };

        case SET_STEAM_FUEL_TYPES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_STEAM_FUEL_TYPE:
            return {
                ...state,
                source: action.payload,
            };

        case SET_STEAM_UNITS:
            return {
                ...state,
                unitList: action.payload,
            };
        case SELECT_STEAM_UNIT:
            return {
                ...state,
                unit: action.payload,
            };

        case CHANGE_STEAM_YEAR:
            return {
                ...state,
                year: action.payload,
            };
        case CHANGE_STEAM_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };

        case SET_STEAM_RESULT:
            return {
                ...state,
                result: action.payload,
            };

        case CHANGE_STEAM_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: {
                    ...state.consumptions,
                    [action.payload.month]: {
                        ...state.consumptions[action.payload.month as keyof typeof state.consumptions],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case CLEAR_STEAM_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: initialState.consumptions,
            };

        default:
            return state;
    }
};
