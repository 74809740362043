import { AnyAction } from 'redux';
import {
    CHANGE_FACILITY_ADDRESS,
    CHANGE_FACILITY_DESCRIPTION,
    CHANGE_FACILITY_EMAIL,
    CHANGE_FACILITY_LIMIT,
    CHANGE_FACILITY_NAME,
    CHANGE_FACILITY_PHONE,
    CHANGE_FACILITY_WEBSITE,
    CLEAR_FACILITY_INVENTORY_LIST_FILTER,
    DELETE_FACILITY_LIST_ITEM,
    GET_COMPANY_LIST,
    GET_FACILITY_CORPORATE_LIST,
    GET_FACILITY_GLOBAL_LIST,
    GET_FACILITY_LIST,
    GET_FACILITY_RESULT,
    SELECT_FACILITY,
    SELECT_FACILITY_CATEGORY,
    SELECT_FACILITY_CITY,
    SELECT_FACILITY_COUNTRY,
    SELECT_FACILITY_DASHBOARD_YEAR,
    SELECT_FACILITY_SECTOR,
    SELECT_FACILITY_SUB_CATEGORY,
    SET_COMPANY_LIST,
    SET_FACILITY_CATEGORY,
    SET_FACILITY_CITY,
    SET_FACILITY_CORPORATE_LIST,
    SET_FACILITY_COUNTRY,
    SET_FACILITY_DASHBOARD_DATA,
    SET_FACILITY_DETAIL,
    SET_FACILITY_GLOBAL_LIST,
    SET_FACILITY_LIST,
    SET_FACILITY_RESULT,
    SET_FACILITY_SECTOR,
    SET_FACILITY_SUB_CATEGORY,
} from '../../actions/company/facility.action';
import { IGlobalFacility } from '../../../entities/company/facilityCombustion';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';
import { GET_COMPANY_RESULT, SET_COMPANY_RESULT } from '../../actions/company/company.action';
import { API_ERROR } from '../../actions/api.action';

const initialState = {
    facilityList: [] as Array<IGlobalFacility>,
    facility: {} as IGlobalFacility,
    deleteItem: [] as Array<number>,
    categoryList: [],
    category: '',
    subCategoryList: [],
    subCategory: '',
    facilityName: '',
    email: '',
    websiteUrl: '',
    countryList: [],
    country: { name: '', key: '', label: '' },
    cityList: [],
    city: { name: '', key: '', label: '' },
    address: '',
    phone: '',
    industryList: [],
    industry: '',
    numberOfFacilities: '',
    facilityDescription: '',
    facilityLimit: 0,
    list: [],
    period: { id: '1', key: 'Tüm Yıllar', label: 'Tüm Yıllar', start: '2010-01', end: '2023-12' },
    dashboard: {
        total: 0,
        cumulative: {
            data: [],
            labels: [],
        },
        monthly: {
            data: [],
            labels: [],
        },
        category: {
            data: [],
            labels: [],
        },
        categoryGrouped: {
            data: {
                CATEGORY_TYPE_1: [],
                CATEGORY_TYPE_2: [],
                CATEGORY_TYPE_3: [],
                CATEGORY_TYPE_4: [],
                CATEGORY_TYPE_5: [],
                CATEGORY_TYPE_6: [],
            },
            labels: {
                CATEGORY_TYPE_1: [],
                CATEGORY_TYPE_2: [],
                CATEGORY_TYPE_3: [],
                CATEGORY_TYPE_4: [],
                CATEGORY_TYPE_5: [],
                CATEGORY_TYPE_6: [],
            },
        },
    },
    corporateList: [],
    corporateListTotal: 0,
    isLoading: false,
    dashboardDetail: {
        facilityName: '',
    },
};

export const facilityCommonReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case SET_AUTH_LOGOUT:
            return initialState;
        case GET_FACILITY_RESULT:
            return {
                ...state,
                country: { name: '', key: '', label: '' },
                city: { name: '', key: '', label: '' },
            };
        case GET_COMPANY_RESULT:
            return {
                ...state,
                country: { name: '', key: '', label: '' },
                city: { name: '', key: '', label: '' },
            };
        case SET_COMPANY_RESULT:
            return {
                ...state,
                facilityLimit: action.payload?.facilityLimit,
                country: action.payload?.country?.name,
                city: action.payload?.state?.name,
            };
        case GET_FACILITY_GLOBAL_LIST:
            const isOrganization = action.payload?.type == 'ORGANIZATION';
            const isCompany = action.payload?.type == 'COMPANY';
            const isFacility = action.payload?.type == 'FACILITY';
            const isTurkish = action.payload?.lang?.includes('tr');

            const list = [] as Array<IGlobalFacility>;
            if (isOrganization) {
                list.push({
                    id: '',
                    key: isTurkish ? 'Tüm Şirketler' : 'All Companies',
                    label: isTurkish ? 'Tüm Şirketler' : 'All Companies',
                    type: 'ORGANIZATION',
                });
            }
            if (isCompany) {
                list.push({
                    id: '',
                    key: isTurkish ? 'Tüm Tesisler' : 'All Facilities',
                    label: isTurkish ? 'Tüm Tesisler' : 'All Facilities',
                    type: 'COMPANY',
                });
            }
            if (isFacility) {
                list.push({ id: '', key: '', label: '', type: 'FACILITY' });
            }
            return {
                ...state,
                facilityList: list,
                facility: list[0],
            };
        case CHANGE_FACILITY_LIMIT:
            return {
                ...state,
                facilityLimit: action.payload,
            };
        case SET_FACILITY_GLOBAL_LIST:
            let facility: IGlobalFacility;
            if (!state?.facility?.id) {
                facility = state.facilityList[0];
            } else {
                facility = state.facility;
            }
            return {
                ...state,
                facilityList: [...state.facilityList, ...action.payload],
                facility,
            };
        case SELECT_FACILITY:
            return {
                ...state,
                facility: action.payload,
            };
        case DELETE_FACILITY_LIST_ITEM:
            return {
                ...state,
                deleteItem: action.payload,
            };
        case SET_FACILITY_CATEGORY:
            return {
                ...state,
                categoryList: action.payload,
            };
        case SELECT_FACILITY_CATEGORY:
            return {
                ...state,
                category: action.payload,
            };

        case SET_FACILITY_SUB_CATEGORY:
            return {
                ...state,
                subCategoryList: action.payload,
            };
        case SELECT_FACILITY_SUB_CATEGORY:
            return {
                ...state,
                subCategory: action.payload,
            };

        case CHANGE_FACILITY_NAME:
            return {
                ...state,
                facilityName: action.payload,
            };

        case CHANGE_FACILITY_EMAIL:
            return {
                ...state,
                email: action.payload,
            };

        case CHANGE_FACILITY_WEBSITE:
            return {
                ...state,
                websiteUrl: action.payload,
            };

        case CHANGE_FACILITY_PHONE:
            return {
                ...state,
                phone: action.payload,
            };

        case SET_FACILITY_COUNTRY:
            return {
                ...state,
                countryList: action.payload,
            };

        case SELECT_FACILITY_COUNTRY:
            return {
                ...state,
                country: state.countryList[0],
                city: { name: '', key: '', label: '' },
            };

        case SELECT_FACILITY_DASHBOARD_YEAR:
            return {
                ...state,
                period: action.payload,
            };

        case SET_FACILITY_CITY:
            return {
                ...state,
                cityList: action.payload,
            };

        case SELECT_FACILITY_CITY:
            return {
                ...state,
                city: action.payload,
            };

        case SET_FACILITY_SECTOR:
            return {
                ...state,
                industryList: action.payload,
            };

        case SELECT_FACILITY_SECTOR:
            return {
                ...state,
                industry: action.payload,
            };

        case CHANGE_FACILITY_ADDRESS:
            return {
                ...state,
                address: action.payload,
            };

        case CHANGE_FACILITY_DESCRIPTION:
            return {
                ...state,
                facilityDescription: action.payload,
            };

        case GET_FACILITY_LIST:
            return {
                ...state,
                list: [],
                isLoading: true,
            };

        case SET_FACILITY_LIST:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            };

        case GET_COMPANY_LIST:
            return {
                ...state,
                list: [],
                isLoading: true,
            };
        case SET_COMPANY_LIST:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            };

        case GET_FACILITY_CORPORATE_LIST:
            return {
                ...state,
                corporateList: [],
                corporateListTotal: 0,
                isLoading: true,
            };

        case `${GET_FACILITY_CORPORATE_LIST} ${API_ERROR}`:
            return {
                ...state,
                isLoading: false,
            };

        case SET_FACILITY_CORPORATE_LIST:
            return {
                ...state,
                corporateList: action.payload.list,
                corporateListTotal: action.payload.totalElements,
                isLoading: false,
            };

        case GET_FACILITY_RESULT:
            return {
                ...state,
                address: '',
                phone: '',
                country: { name: '', key: '', label: '' },
                email: '',
                facilityName: '',
                city: { name: '', key: '', label: '' },
            };
        case SET_FACILITY_RESULT:
            const data = action.payload;
            return {
                ...state,
                address: data.address,
                phone: data.phone,
                country: data.country.name,
                email: data.email,
                facilityName: data.facilityName,
                city: data.state.name,
            };

        case SET_FACILITY_DASHBOARD_DATA:
            return {
                ...state,
                dashboard: action.payload,
            };
        case SET_FACILITY_DETAIL: {
            return {
                ...state,
                dashboardDetail: {
                    facilityName: action.payload.facilityName,
                },
            };
        }
        case CLEAR_FACILITY_INVENTORY_LIST_FILTER: {
            return {
                ...state,
                category: '',
                subCategory: '',
            };
        }
        default:
            return state;
    }
};
