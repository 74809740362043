import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from './locales';

// Initial i18n
// @ts-ignore
i18n.use(detector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'tr',
        whitelist: ['en', 'tr'],
        nonExplicitWhitelist: true,
        resources,
        react: {
            wait: true,
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
