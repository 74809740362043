import { RootState } from '../../store';

export const getCategories1 = (state: RootState) => state.emission.base.categories;
export const getFacilityState = (state: RootState) => state.company.facility;
export const getName = (state: RootState) => state.company.facility.facilityName;
export const getEmail = (state: RootState) => state.company.facility.email;
export const getWebsite = (state: RootState) => state.company.facility.websiteUrl;
export const getPhone = (state: RootState) => state.company.facility.phone;
export const getCountry = (state: RootState) => state.company.facility.country;
export const getCountryList = (state: RootState) => state.company.facility.countryList;
export const getCity = (state: RootState) => state.company.facility.city;
export const getCityList = (state: RootState) => state.company.facility.cityList;
export const getFacility = (state: RootState) => state.company.facility.numberOfFacilities;
export const getSector = (state: RootState) => state.company.facility.industry;
export const getSectorList = (state: RootState) => state.company.facility.industryList;
export const getDescription = (state: RootState) => state.company.facility.facilityDescription;
export const getList = (state: RootState) => state.company.facility.list;
export const getCorporateList = (state: RootState) => state.company.facility.corporateList;
export const getAddress = (state: RootState) => state.company.facility.address;
export const getIsLoading = (state: RootState) => state.company.facility.isLoading;
export const getCorporateListTotal = (state: RootState) => state.company.facility.corporateListTotal;
export const getCategoryList = (state: RootState) => state.company.facility.categoryList;
export const getCategory = (state: RootState) => state.company.facility.category;
export const getSubCategoryList = (state: RootState) => state.company.facility.subCategoryList;
export const getSubCategory = (state: RootState) => state.company.facility.subCategory;
export const getDashboardData = (state: RootState) => state.company.facility.dashboard;
export const getPeriod = (state: RootState) => state.company.facility.period;
export const getDashboardDetailFacilityName = (state: RootState) => state.company.facility.dashboardDetail.facilityName;
export const getFacilitiesGlobal = (state: RootState) => state.company.facility.facilityList;
export const getSelectedFacilityGlobal = (state: RootState) => state.company.facility.facility;
