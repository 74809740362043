import { AnyAction } from 'redux';
import {
    CHANGE_KPI_DESCRIPTION,
    CHANGE_KPI_END_DATE,
    CHANGE_KPI_GOAL_AMOUNT,
    CHANGE_KPI_START_DATE,
    CHANGE_KPI_STARTING_AMOUNT,
    CHANGE_KPI_TITLE,
    CLEAR_KPI_INPUTS,
    GET_KPI_BY_ID,
    GET_KPI_LIST,
    SET_KPI_BY_ID,
    SET_KPI_LIST,
    SET_KPI_PAGE,
} from '../../actions/kpi/kpi.action';
import { IKpi } from '../../../entities/kpi/kpiCombustion';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';

const initialState = {
    kpiList: {
        data: [],
        isLoading: true,
    },
    selectedKpi: {
        data: {} as IKpi,
        isLoading: true,
    },
    updateKpi: {
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        startingAmount: '',
        goalAmount: '',
    },
    title: '',
    description: '',
    startDate: '',
    endDate: '',
    startingAmount: '',
    goalAmount: '',
};

export const kpiReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_KPI_BY_ID:
            return {
                ...state,
                selectedKpi: {
                    data: {} as IKpi,
                    isLoading: true,
                },
                updateKpi: {
                    title: '',
                    description: '',
                    startDate: '',
                    endDate: '',
                    startingAmount: '',
                    goalAmount: '',
                },
                title: '',
                description: '',
                startDate: '',
                endDate: '',
                startingAmount: '',
                goalAmount: '',
            };
        case SET_KPI_BY_ID:
            return {
                ...state,
                selectedKpi: {
                    data: action.payload,
                    isLoading: false,
                },
                title: action.payload?.title,
                description: action.payload?.description,
                startDate: action.payload?.startDate,
                endDate: action.payload?.endDate,
                startingAmount: action.payload?.startingAmount,
                goalAmount: action.payload?.goalAmount,
            };
        case SET_KPI_PAGE:
            return state;
        case GET_KPI_LIST:
            return {
                ...state,
                kpiList: {
                    data: [],
                    isLoading: true,
                },
            };
        case SET_KPI_LIST:
            return {
                ...state,
                kpiList: {
                    data: action.payload,
                    isLoading: false,
                },
            };
        case CHANGE_KPI_TITLE:
            return {
                ...state,
                title: action.payload,
            };
        case CHANGE_KPI_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };
        case CHANGE_KPI_START_DATE:
            return {
                ...state,
                startDate: action.payload,
            };
        case CHANGE_KPI_END_DATE:
            return {
                ...state,
                endDate: action.payload,
            };
        case CHANGE_KPI_STARTING_AMOUNT:
            return {
                ...state,
                startingAmount: action.payload,
            };
        case CHANGE_KPI_GOAL_AMOUNT:
            return {
                ...state,
                goalAmount: action.payload,
            };
        case CLEAR_KPI_INPUTS:
            return initialState;
        case SET_AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
};
