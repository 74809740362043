import { IKpiProject } from '../../../entities/kpi/kpiProjectCombustion';

export const PROJECT = '[PROJECT]';

// API
export const KPI_PROJECT_STATUS = `${PROJECT} KPI Project Status`;
export const SET_KPI_PROJECT_STATUS = `${PROJECT} Set KPI Project Status`;
export const KPI_PROJECT_GOALS = `${PROJECT} KPI Project Goals`;
export const SET_KPI_PROJECT_GOALS = `${PROJECT} Set KPI Project Goals`;

export const KPI_PROJECT_CREATE = `${PROJECT} KPI Project Create`;
export const KPI_PROJECT_UPDATE = `${PROJECT} KPI Project Update`;
export const KPI_PROJECT_DELETE = `${PROJECT} KPI Project Delete`;
export const GET_KPI_PROJECT_BY_ID = `${PROJECT} Get KPI Project By ID`;
export const SET_KPI_PROJECT_BY_ID = `${PROJECT} Set KPI Project By ID`;
export const GET_KPI_PROJECT_PAGE = `${PROJECT} Get KPI Project Page`;
export const SET_KPI_PROJECT_PAGE = `${PROJECT} Set KPI Project Page`;
export const GET_KPI_PROJECT_LIST = `${PROJECT} Get KPI Project List`;
export const SET_KPI_PROJECT_LIST = `${PROJECT} Set KPI Project List`;
export const GET_KPI_PROJECT_DASHBOARD = `${PROJECT} Get KPI Project Dashboard`;
export const SET_KPI_PROJECT_DASHBOARD = `${PROJECT} Set KPI Project Dashboard`;
export const CHANGE_KPI_PROJECT_STATUS = `${PROJECT} Change KPI Project Status`;
export const CHANGE_KPI_PROJECT_STATUS_IN_LIST = `${PROJECT} Change KPI Project Status In List`;
export const CLEAR_KPI_PROJECT_INPUTS = `${PROJECT} Clear KPI Project Inputs`;
// END API

// INPUT
export const CHANGE_KPI_PROJECT_TITLE = `${PROJECT} Change KPI Project Title`;
export const CHANGE_KPI_PROJECT_DESCRIPTION = `${PROJECT} Change KPI Project Description`;
export const CHANGE_KPI_PROJECT_CATEGORY = `${PROJECT} Change KPI Project Category`;
export const CHANGE_KPI_PROJECT_BASE_YEAR = `${PROJECT} Change KPI Project Base Year`;
export const CHANGE_KPI_PROJECT_TARGET_YEAR = `${PROJECT} Change KPI Project Target Year`;
export const SELECT_KPI_PROJECT_GOALS = `${PROJECT} Select KPI Project Goals`;
export const SELECT_KPI_PROJECT_STATUS = `${PROJECT} Select KPI Project Status`;
// END INPUT

export type ISubmit = {
    onSuccess: () => void;
};
export const submit = ({ onSuccess }: ISubmit) => ({
    meta: {
        onSuccess,
    },
});

export const kpiProjectStatus = (data?: any) => ({
    type: KPI_PROJECT_STATUS,
    payload: data,
});
export const setKpiProjectStatus = (data?: any) => ({
    type: SET_KPI_PROJECT_STATUS,
    payload: data,
});
export const kpiProjectGoals = (data?: any) => ({
    type: KPI_PROJECT_GOALS,
    payload: data,
});
export const setKpiProjectGoals = (data?: any) => ({
    type: SET_KPI_PROJECT_GOALS,
    payload: data,
});

export const kpiProjectCreate = ({ onSuccess }: ISubmit, data?: any) => ({
    type: KPI_PROJECT_CREATE,
    payload: data,
    meta: {
        onSuccess,
    },
});
export const kpiProjectUpdate = (data?: any) => ({
    type: KPI_PROJECT_UPDATE,
    payload: data,
});
export const kpiProjectDelete = (data?: any) => ({
    type: KPI_PROJECT_DELETE,
    payload: data,
});
export const getKpiProjectById = (data?: any) => ({
    type: GET_KPI_PROJECT_BY_ID,
    payload: data,
});
export const setKpiProjectById = (data?: any) => ({
    type: SET_KPI_PROJECT_BY_ID,
    payload: data,
});
export const getKpiProjectPage = (data?: any) => ({
    type: GET_KPI_PROJECT_PAGE,
    payload: data,
});
export const setKpiProjectPage = (data?: any) => ({
    type: SET_KPI_PROJECT_PAGE,
    payload: data,
});
export const getKpiProjectList = (data?: any) => ({
    type: GET_KPI_PROJECT_LIST,
    payload: data,
});
export const setKpiProjectList = (data: Array<IKpiProject>) => ({
    type: SET_KPI_PROJECT_LIST,
    payload: data,
});
export const getKpiProjectDashboard = (data?: any) => ({
    type: GET_KPI_PROJECT_DASHBOARD,
    payload: data,
});
export const setKpiProjectDashboard = (data?: any) => ({
    type: SET_KPI_PROJECT_DASHBOARD,
    payload: data,
});
export const changeKpiProjectStatus = (data?: any) => ({
    type: CHANGE_KPI_PROJECT_STATUS,
    payload: data,
});
export const changeKpiProjectStatusInList = (data?: any) => ({
    type: CHANGE_KPI_PROJECT_STATUS_IN_LIST,
    payload: data,
});
export const clearKpiProjectInputs = (data?: any) => ({
    type: CLEAR_KPI_PROJECT_INPUTS,
    payload: data,
});

// INPUTS
export const changeKpiProjectTitle = (data?: any) => ({
    type: CHANGE_KPI_PROJECT_TITLE,
    payload: data,
});
export const changeKpiProjectDescription = (data?: any) => ({
    type: CHANGE_KPI_PROJECT_DESCRIPTION,
    payload: data,
});
export const changeKpiProjectCategory = (data?: any) => ({
    type: CHANGE_KPI_PROJECT_CATEGORY,
    payload: data,
});
export const changeKpiProjectBaseYear = (data?: any) => ({
    type: CHANGE_KPI_PROJECT_BASE_YEAR,
    payload: data,
});
export const changeKpiProjectTargetYear = (data?: any) => ({
    type: CHANGE_KPI_PROJECT_TARGET_YEAR,
    payload: data,
});
export const selectKpiProjectGoals = (data?: any) => ({
    type: SELECT_KPI_PROJECT_GOALS,
    payload: data,
});
export const selectKpiProjectStatus = (data?: any) => ({
    type: SELECT_KPI_PROJECT_STATUS,
    payload: data,
});
