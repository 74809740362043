import React, { FC } from 'react';
import Unauthorized from '../views/pages/Unauthorized';
import usePermission from '../hooks/usePermission';

type IPermissionProviderProps = {
    element?: React.ReactNode | null;
    permissionType: string;
    permissionTypeSet: Array<string>;
    roles: Array<string>;
    id?: number;
};

const PermissionProvider: FC<IPermissionProviderProps> = ({
    element,
    permissionType,
    permissionTypeSet,
    roles,
    id,
}) => {
    const isAllowed = usePermission(permissionType, permissionTypeSet, roles, id);

    return <React.Fragment>{isAllowed ? element : <Unauthorized />}</React.Fragment>;
};

export default PermissionProvider;
