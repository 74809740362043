import { Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { LayoutContext } from '../layouts/LayoutContext';
import { useAppDispatch } from '../../redux/hooks';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'common' });
    const context = useContext(LayoutContext);
    const dispatch = useAppDispatch();

    useEffect(() => {
        context.setTitle('Yetkiniz Yok!');
        context.setSectionFacilities(false);
        context.setSectionAddButton(false);
        context.setSectionRight(null);
    }, [dispatch]);

    return (
        <Container maxWidth="xl">
            <Grid
                container
                xs={12}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                height={'calc(100vh - 180px)'}
            >
                <Button component={Link} to="/" sx={{ minWidth: '270px', justifyContent: 'space-between' }}>
                    <KeyboardArrowLeftIcon />
                    <Typography variant={'h2'}>403 {t("unAuthorized")}</Typography>
                </Button>
            </Grid>
        </Container>
    );
};

export default Unauthorized;
