import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import { getAuthState } from '../redux/selectors/auth/auth.selector';

type IAuthorizationProviderProps = {
    children: React.ReactNode;
};

const AuthorizationProvider: FC<IAuthorizationProviderProps> = ({ children }) => {
    const authState = useAppSelector(getAuthState);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!authState.isLogin) {
            navigate(`/login?redirectUrl=${location.pathname}`);
        }
    }, [authState.isLogin]);

    return (
        <>{authState.isLogin || location.pathname === '/login' || location.pathname === '/reset' ? children : null}</>
    );
};

export default AuthorizationProvider;
