import { AnyAction } from 'redux';
import {
    CHANGE_GENERAL_AUDIENCE_AND_DISSEMINATION_POLICIES,
    CHANGE_GENERAL_BASE_YEAR,
    CHANGE_GENERAL_DESCRIPTION_GENERAL_ORGANIZATION,
    CHANGE_GENERAL_ENTITY_RESPONSIBLE_FOR_REPORT,
    CHANGE_GENERAL_INTRODUCTION,
    CHANGE_GENERAL_PERIOD,
    CHANGE_GENERAL_PURPOSE_SCOPE_OBJECTIVE,
    CHANGE_GENERAL_STANDARTS,
    CHANGE_GENERAL_SUSTAINABILITY_POLICIES,
    SET_GENERAL_RESULT,
} from '../../actions/company/general.action';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';

const initialState = {
    introduction:
        'Bu Sera Gazı Envanteri Raporu “……” Kuruluşu’nun “……” takvim yılındaki sera gazı emisyonlarını içermekte olup, ISO 14064-1:2018 standardının 9.3.1 ve 9.3.2 maddelerine uygun olarak hazırlanmıştır.',
    purposeScopeObjective:
        'Sera gazı raporunun amacı, Kurumumuz sorumluluğunda gerçekleştirilen tüm operasyonlara ilişkin sera gazı emisyonlarını ve uzaklaştırmalarını kuruluş seviyesinde hesaplamak ve ISO 14064-1:2018 standardı gerekliliklerine göre sera gazı beyanında bulunmaktır.Sera Gazı Raporu doğrudan ve dolaylı emisyonları kapsamakta olup hesaplamalarda Karbon Dioksit (CO2), Metan (CH4), Azot Oksit (N2O), Azottriflorür (NF3), Hidroflorokarbonlar (HFC), Perflorokarbonlar (PFC) ve Kükürt Hekzaflorürden (SF6) gazları dikkate alınmıştır.',
    descriptionReportingOrganization:
        'Örnek; “………..” ; enerji, çevre ve finans sektörünü daha verimli hale getirmek için yüksek teknolojili platformlar geliştiren bir teknoloji girişimidir. Enerji, çevre ve finans sektöründe yenilikçi teknolojileri sürdürülebilir rekabet avantajına sahip ürünlere dönüştürmektedir. Ürettiği ticari çözümleri çevre ve sürdürülebilirlik perspektifiyle geliştiren “………………”, iklim değişikliğiyle mücadelede yüksek teknolojilerin üretken rolünü ön plana çıkarmaktadır.',
    entityResposibleForReport:
        'Sera Gazı Raporunun hazırlanması için gerekli olan faaliyet verilerinin toplatılmasından, çalışmaların koordine edilmesinden ve hazırlanan raporun kontrol edilmesinden sorumlu kişiler aşağıda listelenmiştir;Sera Gazı Envanteri ve Raporu, “………. Merkezli” ekip tarafından, tüm büyük faaliyet bölgelerindeki birçok tarafın önemli desteğiyle hazırlanmıştır.Genel sorumluluk, “……. ……. …….” aittirEnvanterin ve raporun hazırlanması sorumluluğu“…………… , …………… , ……………”Envanterin desteklenmesi ve hazırlanması“…………… , …………… , ……………”Arka plan verileri ve destekleyici bilgilerle yardım“…………… , …………… , ……………”',
    audienceAndDisseminationPolicies:
        'Bu rapor, sera gazı emisyon envanteri ve ilgili raporlama yapısı, gösterimi ve açıklamalarıyla ilgilenen tüm “………………” paydaşlarına yöneliktir. Uygun üçüncü taraf doğrulamasının ardından herkese açık olarak sağlanır. Hazırlanmış olan Sera gazı raporu hedef kullanıcıları; Firmamız Üst Yönetimi Çalışanlar Müşterilerimiz Tedarikçiler Diğer paydaşlar ',
    reportingPeriod:
        'Bu sera gazı raporu, “…… …… ….. …… …… ……” arasındaki takvim yılını kapsar.Sera gazı raporları yıllık olarak üretilir.',
    baseYear:
        'Kuruluşumuzda sera gazı emisyonlarının ISO 14064-1:2018 gerekliliklerine göre hesaplama çalışması ilk olarak “………………” yılında yapıldığı için ilgili yıl temel yıl olarak belirlenmiştir. Sera gazı hesaplamalarında temel yılın değişmesine neden olacak herhangi bir değişiklik olmamıştır.Temel yılın değişmesi durumunda, kuruluşumuz yeniden belirlenen yıla ait verilerdeki değişiklikleri sonraki raporlarda belirtecektir.',
    reportingStandards:
        "Sera gazı raporu ISO 14064-1:2018 standardı baz alınarak hazırlanmıştır. Ek 1'de bir raporlama endeksi verilmiştir.",
    ghgSustainabilityPolicy:
        'İklim değişikliği, işletmeler ve hükümetler için belirleyici bir konu olmaya devam etmektedir.Kuruluşumuz olarak, işimizin karbon emisyonu üreten bir faaliyete dayandığını kabul etmekle başladık. Rekabet gücümüzü ve müşterilerimizin beklediği gibi kaliteli hizmetler sunma kabiliyetimizi korumak için sera gazı emisyonlarımızı zaman içinde azaltma sorumluluğunu üstlendik.Sera gazı azaltım ve sürdürülebilirlik yönetim stratejilerimiz;Sera gazı oluşumuna neden olabilecek faaliyetleri kontrol altında tutarak, çevreye verilebilecek zararları azaltmak, Sera gazı kaynaklarının verimli kullanılmasını sağlayarak sera gazı emisyonunu azaltmak, Yapığımız faaliyetlerde enerji yönetimini sağlamak ve geliştirmek, Dünya genelindeki iklim politikaları, uluslararası anlaşmalar, ulusal ve uluslararası taahhütler çerçevesinde sera gazı salımını azaltarak iklim değişikliğinin önüne geçmek için pozitif katkı sağlamak, Sürekli iyileştirme kapsamında, eğitimler ile çalışan, tedarikçi ve taşeronların enerji ve doğal kaynak tüketimlerini azaltmak.',
    isLoading: false,
};

export const generalCommonReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case SET_AUTH_LOGOUT:
            return initialState;
        case SET_GENERAL_RESULT:
            return {
                ...state,
                introduction: action.payload.introduction,
                purposeScopeObjective: action.payload.purposeScopeObjective,
                descriptionReportingOrganization: action.payload.descriptionReportingOrganization,
                entityResposibleForReport: action.payload.entityResposibleForReport,
                audienceAndDisseminationPolicies: action.payload.audienceAndDisseminationPolicies,
                reportingPeriod: action.payload.reportingPeriod,
                baseYear: action.payload.baseYear,
                reportingStandards: action.payload.reportingStandards,
                ghgSustainabilityPolicy: action.payload.ghgSustainabilityPolicy,
                isLoading: true,
            };
        case CHANGE_GENERAL_INTRODUCTION:
            return {
                ...state,
                introduction: action.payload,
            };
        case CHANGE_GENERAL_PURPOSE_SCOPE_OBJECTIVE:
            return {
                ...state,
                purposeScopeObjective: action.payload,
            };

        case CHANGE_GENERAL_DESCRIPTION_GENERAL_ORGANIZATION:
            return {
                ...state,
                descriptionReportingOrganization: action.payload,
            };

        case CHANGE_GENERAL_ENTITY_RESPONSIBLE_FOR_REPORT:
            return {
                ...state,
                entityResposibleForReport: action.payload,
            };

        case CHANGE_GENERAL_AUDIENCE_AND_DISSEMINATION_POLICIES:
            return {
                ...state,
                audienceAndDisseminationPolicies: action.payload,
            };

        case CHANGE_GENERAL_PERIOD:
            return {
                ...state,
                reportingPeriod: action.payload,
            };

        case CHANGE_GENERAL_BASE_YEAR:
            return {
                ...state,
                baseYear: action.payload,
            };
        case CHANGE_GENERAL_STANDARTS:
            return {
                ...state,
                reportingStandards: action.payload,
            };

        case CHANGE_GENERAL_SUSTAINABILITY_POLICIES:
            return {
                ...state,
                ghgSustainabilityPolicy: action.payload,
            };

        default:
            return state;
    }
};
