enum PermissionTypeSet {
    READ = 'READ',
    WRITE = 'WRITE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

type PermissionTypeSetGroup = Array<PermissionTypeSet>;

interface IPermissionTypeSetGroup {
    [key: string]: PermissionTypeSetGroup;
}

export const PermissionTypeSetsGroup: IPermissionTypeSetGroup = {
    ALL: [PermissionTypeSet.READ, PermissionTypeSet.WRITE, PermissionTypeSet.UPDATE, PermissionTypeSet.DELETE],
    ONLY_R: [PermissionTypeSet.READ],
    ONLY_W: [PermissionTypeSet.WRITE],
    ONLY_RW: [PermissionTypeSet.READ, PermissionTypeSet.WRITE],
    ONLY_RWU: [PermissionTypeSet.READ, PermissionTypeSet.WRITE, PermissionTypeSet.UPDATE],
};
