export enum Roles {
    ADMIN = 'ROLE_ADMIN',
    MODERATOR = 'ROLE_MODERATOR',
    USER = 'ROLE_USER',
    TEST = 'ROLE_TEST',
    ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN',
    ORGANIZATION_USER = 'ROLE_ORGANIZATION_USER',
    COMPANY_ADMIN = 'ROLE_COMPANY_ADMIN',
    COMPANY_USER = 'ROLE_COMPANY_USER',
    FACILITY_ADMIN = 'ROLE_FACILITY_ADMIN',
    FACILITY_USER = 'ROLE_FACILITY_USER',
}

type RoleGroup = Array<Roles>;

interface IRoleGroup {
    [key: string]: RoleGroup;
}

export const RolesGroup: IRoleGroup = {
    ADMIN: [Roles.ADMIN],
    ALL: [
        Roles.ADMIN,
        Roles.MODERATOR,
        Roles.USER,
        Roles.TEST,
        Roles.ORGANIZATION_ADMIN,
        Roles.ORGANIZATION_USER,
        Roles.COMPANY_ADMIN,
        Roles.COMPANY_USER,
        Roles.FACILITY_ADMIN,
        Roles.FACILITY_USER,
    ],

    EVERYONE: [
        Roles.ORGANIZATION_ADMIN,
        Roles.ORGANIZATION_USER,
        Roles.COMPANY_ADMIN,
        Roles.COMPANY_USER,
        Roles.FACILITY_ADMIN,
        Roles.FACILITY_USER,
    ],

    ONLY_ORGANIZATION: [Roles.ORGANIZATION_ADMIN, Roles.ORGANIZATION_USER],
    ONLY_COMPANY: [Roles.COMPANY_ADMIN, Roles.COMPANY_USER],
    ONLY_FACILITY: [Roles.FACILITY_ADMIN, Roles.FACILITY_USER],

    ONLY_ADMINS: [Roles.ORGANIZATION_ADMIN, Roles.COMPANY_ADMIN, Roles.FACILITY_ADMIN],
    ONLY_USERS: [Roles.ORGANIZATION_USER, Roles.COMPANY_USER, Roles.FACILITY_USER],

    ORGANIZATION_ADMIN: [Roles.ORGANIZATION_ADMIN],
    ORGANIZATION_USER: [Roles.ORGANIZATION_USER],
    COMPANY_ADMIN: [Roles.COMPANY_ADMIN],
    COMPANY_USER: [Roles.COMPANY_USER],
    FACILITY_ADMIN: [Roles.FACILITY_ADMIN],
    FACILITY_USER: [Roles.FACILITY_USER],

    MEXT_CUSTOM: [],
};
