import { IDetailedEmission, IUpdatedEmission } from '../../../entities/emissionTypes/updatedCombustion';
import { EMISSION } from './base.action';

export const UPDATE = '[UPDATE]';

export const SET_UPDATE_EMISSION_RESULT = `${UPDATE} Set Emission Result`;

export const SELECT_INVENTORY_EMISSION = `${UPDATE} Select Inventory Emission`;
export const UPDATE_EMISSION = `${UPDATE} Emission`;
export const UPDATE_EMISSION_FUGITIVE = `${UPDATE} Emission Fugitive`;

export const CHANGE_UPDATE_CONSUMPTION_AMOUNT = `${UPDATE} Change Consumption Amount`;
export const CHANGE_UPDATE_EQUIVALENT_FACTOR = `${UPDATE} Change Equivalent Factor`;
export const CHANGE_UPDATE_TAGS = `${UPDATE} Change Tags`;
export const CHANGE_UPDATE_DESCRIPTION = `${UPDATE} Change Description`;
export const CHANGE_UPDATE_EQUIVALENT_TAGS = `${UPDATE} Change Equivalent Tags`;
export const CHANGE_UPDATE_ACTIVITY_DATA = `${UPDATE} Change Activity Data`;
export const CLEAR_UPDATE_INPUT = `${EMISSION} ${UPDATE} Clear Update Input`;

export const GET_INVENTORY_EMISSION_BY_ID = `${UPDATE} Get Inventory Emission By ID`;
export const SET_INVENTORY_EMISSION_BY_ID = `${UPDATE} Set Inventory Emission By ID`;
export const CHANGE_UPDATE_INVENTORY = `${UPDATE} Change Update Inventory`;
export const SET_FORM_WITH_INVENTORY_DETAIL = `${UPDATE} Set Form With Inventory Detail`;
export const CHANGE_INVENTORY_DETAIL_ARG_0 = `${UPDATE} Change Inventory Detail ARG_0`;
export const CHANGE_INVENTORY_DETAIL_ARG_1 = `${UPDATE} Change Inventory Detail ARG_1`;
export const CHANGE_INVENTORY_DETAIL_ARG_2 = `${UPDATE} Change Inventory Detail ARG_2`;

export type ISubmit = {
    onSuccess: () => void;
};

export const selectInventoryEmission = (item: any) => ({
    type: SELECT_INVENTORY_EMISSION,
    payload: item,
});

export const clearUpdateInput = (list: Array<string>) => ({
    type: CLEAR_UPDATE_INPUT,
    payload: list,
});

export const updateEmission = (value: number, { onSuccess }: ISubmit) => ({
    type: UPDATE_EMISSION,
    payload: value,
    meta: {
        onSuccess,
    },
});

export const updateEmissionFugitive = ({ onSuccess }: ISubmit) => ({
    type: UPDATE_EMISSION_FUGITIVE,
    meta: {
        onSuccess,
    },
});

export const setUpdateEmissionResult = (value: IUpdatedEmission) => ({
    type: SET_UPDATE_EMISSION_RESULT,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_UPDATE_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_UPDATE_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeEquivalentTags = (value: string) => ({
    type: CHANGE_UPDATE_EQUIVALENT_TAGS,
    payload: value,
});

export const changeTags = (value: string) => ({
    type: CHANGE_UPDATE_TAGS,
    payload: value,
});

export const changeDescription = (value: string) => ({
    type: CHANGE_UPDATE_DESCRIPTION,
    payload: value,
});

export const changeActivityData = (value: string) => ({
    type: CHANGE_UPDATE_ACTIVITY_DATA,
    payload: value,
});

export const getInventoryEmissionById = (id: number) => ({
    type: GET_INVENTORY_EMISSION_BY_ID,
    payload: id,
});

export const setInventoryEmissionById = (result: IDetailedEmission) => ({
    type: SET_INVENTORY_EMISSION_BY_ID,
    payload: result,
});

export const changeUpdateInventory = (value: boolean) => ({
    type: CHANGE_UPDATE_INVENTORY,
    payload: value,
});

export const setFormWithInventoryDetail = (result: any) => ({
    type: SET_FORM_WITH_INVENTORY_DETAIL,
    payload: result,
});

export const changeInventoryDetailARG_0 = (value: number) => ({
    type: CHANGE_INVENTORY_DETAIL_ARG_0,
    payload: value,
});

export const changeInventoryDetailARG_1 = (value: number) => ({
    type: CHANGE_INVENTORY_DETAIL_ARG_1,
    payload: value,
});

export const changeInventoryDetailARG_2 = (value: number) => ({
    type: CHANGE_INVENTORY_DETAIL_ARG_2,
    payload: value,
});
