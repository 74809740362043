import { IOrganization } from '../../../entities/company/companyCombustion';

export const ORGANIZATION = '[ORGANIZATION]';

export const GET_ORGANIZATION_DASHBOARD_DATA = `${ORGANIZATION} Get Dashboard Data`;
export const SET_ORGANIZATION_DASHBOARD_DATA = `${ORGANIZATION} Set Dashboard Data`;

export const GET_ORGANIZATION_ANALYTIC_DATA = `${ORGANIZATION} Get Analytic Data`;
export const SET_ORGANIZATION_ANALYTIC_DATA = `${ORGANIZATION} Set Analytic Data`;

export const GET_ORGANIZATION_RESULT = `${ORGANIZATION} Get Result Organization`;
export const SET_ORGANIZATION_RESULT = `${ORGANIZATION} Set Result Organization`;

export const GET_ORGANIZATION_IMAGE = `${ORGANIZATION} Get Organization Image`;
export const SET_ORGANIZATION_IMAGE = `${ORGANIZATION} Set Organization Image`;
export const SELECT_ORGANIZATION_IMAGE = `${ORGANIZATION} Select Organization Image`;
export const UPLOAD_ORGANIZATION_IMAGE = `${ORGANIZATION} Upload Organization Image`;

export const SUBMIT_ORGANZATION_FORM = `${ORGANIZATION} Submit Organization Form`;

export type ISubmit = {
    onSuccess: () => void;
};

export const getOrganizationDashboardData = () => ({
    type: GET_ORGANIZATION_DASHBOARD_DATA,
});

export const setOrganizationDashboardData = (value: any) => ({
    type: SET_ORGANIZATION_DASHBOARD_DATA,
    payload: value,
});

export const getOrganizationAnalyticData = () => ({
    type: GET_ORGANIZATION_ANALYTIC_DATA,
});

export const setOrganizationAnalyticData = (value: any) => ({
    type: SET_ORGANIZATION_ANALYTIC_DATA,
    payload: value,
});

export const getOrganizationResult = () => ({
    type: GET_ORGANIZATION_RESULT,
});

export const setOrganizationResult = (organization: IOrganization) => ({
    type: SET_ORGANIZATION_RESULT,
    payload: organization,
});

export const getOrganizationImage = () => ({
    type: GET_ORGANIZATION_IMAGE,
});

export const setOrganizationImage = (value: any) => ({
    type: SET_ORGANIZATION_IMAGE,
    payload: value,
});

export const selectOrganizationImage = (value: FormData) => ({
    type: SELECT_ORGANIZATION_IMAGE,
    payload: value,
});

export const uploadOrganizationImage = ({ onSuccess }: ISubmit, value: any) => ({
    type: UPLOAD_ORGANIZATION_IMAGE,
    meta: {
        onSuccess,
    },
    payload: value,
});

export const submitOrganizationForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_ORGANZATION_FORM,
    meta: {
        onSuccess,
    },
});
