export const ACTION = '[ACTION]';

// API
export const KPI_ACTION_CREATE = `${ACTION} KPI Action Create`;
export const KPI_ACTION_UPDATE = `${ACTION} KPI Action Update`;
export const KPI_ACTION_DELETE = `${ACTION} KPI Action Delete`;
export const GET_KPI_ACTION_BY_ID = `${ACTION} Get KPI Action By ID`;
export const SET_KPI_ACTION_BY_ID = `${ACTION} Set KPI Action By ID`;
export const GET_KPI_ACTION_PAGE = `${ACTION} Get KPI Action Page`;
export const SET_KPI_ACTION_PAGE = `${ACTION} Set KPI Action Page`;
export const GET_KPI_ACTION_LIST = `${ACTION} Get KPI Action List`;
export const SET_KPI_ACTION_LIST = `${ACTION} Set KPI Action List`;
export const CLEAR_KPI_ACTION_INPUTS = `${ACTION} Clear KPI Action Inputs`;
// END API

// INPUT
export const CHANGE_KPI_ACTION_TITLE = `${ACTION} Change KPI Action Title`;
export const CHANGE_KPI_ACTION_DESCRIPTION = `${ACTION} Change KPI Action Description`;
export const CHANGE_KPI_ACTION_END_DATE = `${ACTION} Change KPI Action End Date`;
export const CHANGE_KPI_ACTION_PROGRESS = `${ACTION} Change KPI Action Progress`;
// END INPUT

export type ISubmit = {
    onSuccess: () => void;
};
export const submit = ({ onSuccess }: ISubmit) => ({
    meta: {
        onSuccess,
    },
});

export const kpiActionCreate = (data?: any) => ({
    type: KPI_ACTION_CREATE,
    payload: data,
});
export const kpiActionUpdate = (data?: any) => ({
    type: KPI_ACTION_UPDATE,
    payload: data,
});
export const kpiActionDelete = (data?: any) => ({
    type: KPI_ACTION_DELETE,
    payload: data,
});
export const getKpiActionById = (data?: any) => ({
    type: GET_KPI_ACTION_BY_ID,
    payload: data,
});
export const setKpiActionById = (data?: any) => ({
    type: SET_KPI_ACTION_BY_ID,
    payload: data,
});
export const getKpiActionPage = (data?: any) => ({
    type: GET_KPI_ACTION_PAGE,
    payload: data,
});
export const setKpiActionPage = (data?: any) => ({
    type: SET_KPI_ACTION_PAGE,
    payload: data,
});
export const getKpiActionList = (data?: any) => ({
    type: GET_KPI_ACTION_LIST,
    payload: data,
});
export const setKpiActionList = (data?: any) => ({
    type: SET_KPI_ACTION_LIST,
    payload: data,
});
export const clearKpiActionInputs = (data?: any) => ({
    type: CLEAR_KPI_ACTION_INPUTS,
    payload: data,
});

// INPUTS
export const changeKpiActionTitle = (data?: any) => ({
    type: CHANGE_KPI_ACTION_TITLE,
    payload: data,
});
export const changeKpiActionDescription = (data?: any) => ({
    type: CHANGE_KPI_ACTION_DESCRIPTION,
    payload: data,
});

export const changeKpiActionEndDate = (data?: any) => ({
    type: CHANGE_KPI_ACTION_END_DATE,
    payload: data,
});

export const changeKpiActionProgress = (data?: any) => ({
    type: CHANGE_KPI_ACTION_PROGRESS,
    payload: data,
});
