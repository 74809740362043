import { AnyAction } from 'redux';
import {
    CHANGE_AUTH_USERNAME,
    CHANGE_AUTH_PASSWORD,
    SET_AUTH_IS_LOGIN,
    SET_AUTH_RESULT,
    SET_AUTH_LOGOUT,
    SET_AUTH_USER_INFO,
} from '../../actions/auth/auth.action';

const initialState = {
    username: '',
    password: '',
    isLogin: false,
    userInfo: {},
    loginInfo: {},
};

export const authenticationReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_AUTH_USER_INFO:
            return {
                ...state,
                userInfo: action.payload,
            };
        case CHANGE_AUTH_USERNAME:
            return {
                ...state,
                username: action.payload,
            };
        case CHANGE_AUTH_PASSWORD:
            return {
                ...state,
                password: action.payload,
            };
        case SET_AUTH_IS_LOGIN:
            return {
                ...state,
                isLogin: action.payload,
            };
        case SET_AUTH_LOGOUT:
            return initialState;
        case SET_AUTH_RESULT:
            return {
                ...state,
                isLogin: action.payload.isLogin,
                loginInfo: action.payload.user,
            };
        default:
            return state;
    }
};
