import { Middleware } from 'redux';

export const uiMiddleware: Middleware =
    ({ dispatch }) =>
        (next) =>
            (action) => {
                next(action);

                //console.log(action);
            };
