import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IServiceUsageActivity,
    IServiceUsageActivityType,
    IServiceUsageDatabase,
    IServiceUsageFuelType,
    IServiceUsageResult,
    IServiceUsageSize,
    IServiceUsageUnit,
} from '../../../entities/emissionTypes/serviceUsageCombustion';
import { EMISSION } from './base.action';

export const SERVICE_USAGE = 'SERVICE_USAGE';

////-- Base
export const CHANGE_SERVICE_USAGE_MAIN_FACILITY = `${EMISSION} ${SERVICE_USAGE} Change Main Facility`;
export const CHANGE_SERVICE_USAGE_SUB_FACILITY = `${EMISSION} ${SERVICE_USAGE} Change Sub Facility`;

export const GET_SERVICE_USAGE_TIERS = `${EMISSION} ${SERVICE_USAGE} Get Tiers`;
export const SET_SERVICE_USAGE_TIERS = `${EMISSION} ${SERVICE_USAGE} Set Tiers`;
export const SELECT_SERVICE_USAGE_TIER = `${EMISSION} ${SERVICE_USAGE} Select Tier`;

export const GET_SERVICE_USAGE_DATABASES = `${EMISSION} ${SERVICE_USAGE} Get Databases`;
export const SET_SERVICE_USAGE_DATABASES = `${EMISSION} ${SERVICE_USAGE} Set Databases`;
export const SELECT_SERVICE_USAGE_DATABASE = `${EMISSION} ${SERVICE_USAGE} Select Database`;

export const CHANGE_SERVICE_USAGE_EQUIVALENT_FACTOR = `${EMISSION} ${SERVICE_USAGE} Change Equivalent Factor`;

export const GET_SERVICE_USAGE_ACTIVITIES = `${EMISSION} ${SERVICE_USAGE} Get Activities`;
export const SET_SERVICE_USAGE_ACTIVITIES = `${EMISSION} ${SERVICE_USAGE} Set Activities`;
export const SELECT_SERVICE_USAGE_ACTIVITY = `${EMISSION} ${SERVICE_USAGE} Select Activity`;
/////--- End base

export const GET_SERVICE_USAGE_FUEL_TYPES = `${EMISSION} ${SERVICE_USAGE} Get Fuel Types`;
export const SET_SERVICE_USAGE_FUEL_TYPES = `${EMISSION} ${SERVICE_USAGE} Set Fuel Types`;
export const SELECT_SERVICE_USAGE_FUEL_TYPE = `${EMISSION} ${SERVICE_USAGE} Select Fuel Type`;

export const GET_SERVICE_USAGE_ACTIVITY_TYPES = `${EMISSION} ${SERVICE_USAGE} Get Activity Types`;
export const SET_SERVICE_USAGE_ACTIVITY_TYPES = `${EMISSION} ${SERVICE_USAGE} Set Activity Types`;
export const SELECT_SERVICE_USAGE_ACTIVITY_TYPE = `${EMISSION} ${SERVICE_USAGE} Select Activity Type`;

export const GET_SERVICE_USAGE_UNITS = `${EMISSION} ${SERVICE_USAGE} Get Units`;
export const SET_SERVICE_USAGE_UNITS = `${EMISSION} ${SERVICE_USAGE} Set Units`;
export const SELECT_SERVICE_USAGE_UNIT = `${EMISSION} ${SERVICE_USAGE} Select Unit`;

export const CHANGE_SERVICE_USAGE_WEIGHT = `${EMISSION} ${SERVICE_USAGE} Change Weight`;
export const CHANGE_SERVICE_USAGE_DISTANCE = `${EMISSION} ${SERVICE_USAGE} Change Distance`;

export const GET_SERVICE_USAGE_LADEN_PERCENTAGES = `${EMISSION} ${SERVICE_USAGE} Get Laden Percentages`;
export const SET_SERVICE_USAGE_LADEN_PERCENTAGES = `${EMISSION} ${SERVICE_USAGE} Set Laden Percentages`;
export const SELECT_SERVICE_USAGE_LADEN_PERCENTAGE = `${EMISSION} ${SERVICE_USAGE} Select Laden percentage`;

export const GET_SERVICE_USAGE_SIZES = `${EMISSION} ${SERVICE_USAGE} Get Sizes`;
export const SET_SERVICE_USAGE_SIZES = `${EMISSION} ${SERVICE_USAGE} Set Sizes`;
export const SELECT_SERVICE_USAGE_SIZE = `${EMISSION} ${SERVICE_USAGE} Select Size`;

////-- Base
export const CHANGE_SERVICE_USAGE_YEAR = `${EMISSION} ${SERVICE_USAGE} Change Year`;
export const SELECT_SERVICE_USAGE_ACTIVITY_DATA = `${EMISSION} ${SERVICE_USAGE} Select Activity Data`;
export const CHANGE_SERVICE_USAGE_CONSUMPTION_AMOUNT = `${EMISSION} ${SERVICE_USAGE} Change Consumption Amount`;
export const CHANGE_SERVICE_USAGE_DESCRIPTION = `${EMISSION} ${SERVICE_USAGE} Change Description`;

export const SUBMIT_SERVICE_USAGE_FORM = `${EMISSION} ${SERVICE_USAGE} Submit Form`;
export const SET_SERVICE_USAGE_RESULT = `${EMISSION} ${SERVICE_USAGE} Set Result`;
/////-- End Base
export const CLEAR_SERVICE_USAGE_CONSUMPTION_AMOUNT = `${EMISSION} ${SERVICE_USAGE} Clear Consumption Amount`;
export const CLEAR_SERVICE_USAGE_INPUT = `${EMISSION} ${SERVICE_USAGE} Clear ServiceUsage Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearServiceUsageInput = (list: Array<string>) => ({
    type: CLEAR_SERVICE_USAGE_INPUT,
    payload: list,
});

/// Region ServiceUsage
export const changeServiceUsageMainFacility = (text: string) => ({
    type: CHANGE_SERVICE_USAGE_MAIN_FACILITY,
    payload: text,
});

export const changeServiceUsageSubFacility = (text: string) => {
    return {
        type: CHANGE_SERVICE_USAGE_SUB_FACILITY,
        payload: text,
    };
};

export const getServiceUsageTiers = () => ({
    type: GET_SERVICE_USAGE_TIERS,
});

export const setServiceUsageTiers = (list: Array<ITier>) => ({
    type: SET_SERVICE_USAGE_TIERS,
    payload: list,
});

export const selectServiceUsageTier = (tier: ITier) => ({
    type: SELECT_SERVICE_USAGE_TIER,
    payload: tier,
});

export const getServiceUsageDatabases = (tier: ITier) => ({
    type: GET_SERVICE_USAGE_DATABASES,
    payload: tier,
});

export const setServiceUsageDatabases = (list: Array<IServiceUsageDatabase>) => ({
    type: SET_SERVICE_USAGE_DATABASES,
    payload: list,
});

export const selectServiceUsageDatabase = (database: IServiceUsageDatabase) => ({
    type: SELECT_SERVICE_USAGE_DATABASE,
    payload: database,
});

export const getServiceUsageActivities = (tier: ITier) => ({
    type: GET_SERVICE_USAGE_ACTIVITIES,
    payload: tier,
});

export const setServiceUsageActivities = (activities: Array<IServiceUsageActivity>) => ({
    type: SET_SERVICE_USAGE_ACTIVITIES,
    payload: activities,
});

export const selectServiceUsageActivity = (activity: IServiceUsageActivity) => ({
    type: SELECT_SERVICE_USAGE_ACTIVITY,
    payload: activity,
});

///////
export const getServiceUsageFuelTypes = (tier: ITier) => ({
    type: GET_SERVICE_USAGE_FUEL_TYPES,
    payload: tier,
});

export const setServiceUsageFuelTypes = (list: Array<IServiceUsageFuelType>) => ({
    type: SET_SERVICE_USAGE_FUEL_TYPES,
    payload: list,
});

export const selectServiceUsageFuelType = (database: IServiceUsageFuelType) => ({
    type: SELECT_SERVICE_USAGE_FUEL_TYPE,
    payload: database,
});

export const getServiceUsageActivityTypes = (value: any) => ({
    type: GET_SERVICE_USAGE_ACTIVITY_TYPES,
    payload: value,
});

export const setServiceUsageActivityTypes = (activities: Array<IServiceUsageActivityType>) => ({
    type: SET_SERVICE_USAGE_ACTIVITY_TYPES,
    payload: activities,
});

export const selectServiceUsageActivityType = (activity: IServiceUsageActivityType) => ({
    type: SELECT_SERVICE_USAGE_ACTIVITY_TYPE,
    payload: activity,
});

export const getServiceUsageUnits = (units: IServiceUsageActivityType) => ({
    type: GET_SERVICE_USAGE_UNITS,
    payload: units,
});

export const setServiceUsageUnits = (units: Array<IServiceUsageUnit>) => ({
    type: SET_SERVICE_USAGE_UNITS,
    payload: units,
});

export const selectServiceUsageUnit = (unit: IServiceUsageUnit) => ({
    type: SELECT_SERVICE_USAGE_UNIT,
    payload: unit,
});

export const changeServiceUsageWeight = (value: number) => ({
    type: CHANGE_SERVICE_USAGE_WEIGHT,
    payload: value,
});

export const changeServiceUsageDistance = (value: number) => ({
    type: CHANGE_SERVICE_USAGE_DISTANCE,
    payload: value,
});

export const getServiceUsageLadenPercentages = (ladens: any) => ({
    type: GET_SERVICE_USAGE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setServiceUsageLadenPercentages = (ladens: Array<any>) => ({
    type: SET_SERVICE_USAGE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectServiceUsageLadenPercentage = (laden: any) => ({
    type: SELECT_SERVICE_USAGE_LADEN_PERCENTAGE,
    payload: laden,
});

export const getServiceUsageSizes = (sizes: IServiceUsageActivityType) => ({
    type: GET_SERVICE_USAGE_SIZES,
    payload: sizes,
});

export const setServiceUsageSizes = (sizes: Array<IServiceUsageSize>) => ({
    type: SET_SERVICE_USAGE_SIZES,
    payload: sizes,
});

export const selectServiceUsageSize = (size: IServiceUsageSize) => ({
    type: SELECT_SERVICE_USAGE_SIZE,
    payload: size,
});

///////
export const submitServiceUsageForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_SERVICE_USAGE_FORM,
    meta: {
        onSuccess,
    },
});

export const setServiceUsageResult = (data: Array<IServiceUsageResult>) => ({
    type: SET_SERVICE_USAGE_RESULT,
    payload: data,
});

export const selectServiceUsageActivityData = (activity: string) => ({
    type: SELECT_SERVICE_USAGE_ACTIVITY_DATA,
    payload: activity,
});

export const changeServiceUsageYear = (value: number) => ({
    type: CHANGE_SERVICE_USAGE_YEAR,
    payload: value,
});

export const changeServiceUsageDescription = (text: string) => ({
    type: CHANGE_SERVICE_USAGE_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_SERVICE_USAGE_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_SERVICE_USAGE_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_SERVICE_USAGE_CONSUMPTION_AMOUNT,
});
/// End Region ServiceUsage
