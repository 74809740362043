import { AnyAction } from 'redux';
import { ICalculatedResult, IReportingResult } from '../../../entities/company/reportingCombustion';
import {
    SET_REPORTING_RESULT,
    SET_REPORTING_CATEGORY,
    SELECT_REPORTING_CATEGORY,
    SET_REPORTING_SUB_CATEGORY,
    SELECT_REPORTING_EMISSION,
    SELECT_REPORTING_SUB_CATEGORY,
    SET_REPORTING_EMISSION,
    CHANGE_REPORTING_SOURCE,
    CHANGE_REPORTING_REASON,
    CHANGE_REPORTING_ACTIVITY_DATA,
    CHANGE_REPORTING_INFORMATION,
    SET_REPORTING_FACILITY,
    SELECT_REPORTING_FACILITY,
    SELECT_REPORTING_CATEGORY_EDIT,
    SET_REPORTING_INFORMATION,
    SET_REPORTING_CALCULATED_RESULT,
    SELECT_REPORTING_SUB_CATEGORY_EDIT,
    SELECT_REPORTING_FACILITY_EDIT,
    CHANGE_REPORTING_SOURCE_EDIT,
    CHANGE_REPORTING_ACTIVITY_DATA_EDIT,
    SELECT_REPORTING_EMISSION_EDIT,
    CHANGE_REPORTING_REASON_EDIT,
} from '../../actions/company/reporting.action';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';

const initialState = {
    calculatedResult: [
        {
            facilityName: '',
            emissionCategoryName: '',
            emissionCategory: '',
            emissionCategoryTypeName: '',
            source: '',
            activityData: '',
            emissionNames: '',
            emission: { ch4Emission: 0, co2Emission: 0, n2oEmission: 0, totalCalculatedEmission: 0 },
            argumentList: [
                {
                    argumentName: '',
                    argumentTitle: '',
                    argumentType: '',
                    value: 0,
                },
            ],
        },
    ] as Array<ICalculatedResult>,
    isCalculatedResultLoading: false,
    facilityList: [],
    facility: '',
    categoryList: [],
    category: '',
    subCategoryList: [],
    subCategory: '',
    emissionList: [],
    emission: '',
    source: null,
    reason: null,
    activity_data: null,
    information:
        'Kuruluşta ISO 14064-1:2018 Standardı EK-H uyarınca önemlilik değerlendirmesi yapılarak emisyon kaynakları belirlenmiştir. Önemlilik değerlendirilmesinde dikkate alınacak faktörler aşağıdaki gibidir.\n' +
        '\n' +
        'Emisyonların Boyutu: Niceliksel olarak önemli olduğu varsayılan dolaylı emisyonlar veya uzaklaştırmalar\n' +
        'Etki düzeyi: Kuruluşun emisyonları ve uzaklaştırmaları izleme ve azaltma becerisine sahip olma derecesi (örn; enerji verimliliği, ekolojik tasarım, müşteri katılımı, iş tanımı)\n' +
        'Veri kalitesi: Elde edilen verilerin doğru ve güvenilir olması\n' +
        'Veri toplama zorluğu: Faaliyet verilerini toplama mümkün olmaması veya maliyetinin yüksek olması\n' +
        '\n' +
        'Önemlilik değerlendirilmesi önemlilik değerlendirme tablosu ile yapıldıktan sonra envantere dahil edilen kaynaklar Emisyon Kaynakları tablosuna, dahil edilmeyen kaynaklar Kapsam Dışı Emisyon Kaynakları tablosuna işlenmektedir.',
    resultList: [] as Array<IReportingResult>,
    isLoading: false,
};

export const reportingCommonReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case SET_AUTH_LOGOUT:
            return initialState;
        case SELECT_REPORTING_FACILITY_EDIT:
            const { facility } = action.payload;
            const rowId3 = action.payload.id;
            return {
                ...state,
                resultList: [
                    (state.resultList[rowId3] = {
                        ...state.resultList[rowId3],
                        facilityName: facility,
                    }),
                ],
            };
        case SELECT_REPORTING_CATEGORY_EDIT:
            const { category } = action.payload;
            const rowId = action.payload.id;
            return {
                ...state,
                resultList: [
                    (state.resultList[rowId] = {
                        ...state.resultList[rowId],
                        boundryCategory: category,
                    }),
                ],
            };

        case SELECT_REPORTING_SUB_CATEGORY_EDIT:
            const { subCategory } = action.payload;
            const rowId2 = action.payload.id;
            return {
                ...state,
                resultList: [
                    (state.resultList[rowId2] = {
                        ...state.resultList[rowId2],
                        boundrySubCategory: subCategory,
                    }),
                ],
            };

        case CHANGE_REPORTING_SOURCE_EDIT:
            const { source } = action.payload;
            const rowId4 = action.payload.id;
            return {
                ...state,
                resultList: [
                    (state.resultList[rowId4] = {
                        ...state.resultList[rowId4],
                        source,
                    }),
                ],
            };

        case CHANGE_REPORTING_ACTIVITY_DATA_EDIT:
            const rowId5 = action.payload.id;
            return {
                ...state,
                resultList: [
                    (state.resultList[rowId5] = {
                        ...state.resultList[rowId5],
                        activityData: action.payload.value,
                    }),
                ],
            };

        case SELECT_REPORTING_EMISSION_EDIT:
            const { emission } = action.payload;
            const rowId6 = action.payload.id;
            return {
                ...state,
                resultList: [
                    (state.resultList[rowId6] = {
                        ...state.resultList[rowId6],
                        emission,
                    }),
                ],
            };

        case CHANGE_REPORTING_REASON_EDIT:
            const { reason } = action.payload;
            const rowId7 = action.payload.id;
            return {
                ...state,
                resultList: [
                    (state.resultList[rowId7] = {
                        ...state.resultList[rowId7],
                        reason,
                    }),
                ],
            };

        case SET_REPORTING_CALCULATED_RESULT:
            return {
                ...state,
                calculatedResult: action.payload,
                isCalculatedResultLoading: true,
            };
        case SET_REPORTING_RESULT:
            const data = action.payload;
            return {
                ...state,
                resultList: data,
                isLoading: true,
            };
        case SET_REPORTING_INFORMATION:
            return {
                ...state,
                information: action.payload?.information,
            };

        case SET_REPORTING_CATEGORY:
            return {
                ...state,
                categoryList: action.payload,
            };

        case SELECT_REPORTING_CATEGORY:
            return {
                ...state,
                category: action.payload,
            };

        case SET_REPORTING_FACILITY:
            return {
                ...state,
                facilityList: action.payload,
            };

        case SELECT_REPORTING_FACILITY:
            return {
                ...state,
                facility: action.payload,
            };

        case SET_REPORTING_SUB_CATEGORY:
            return {
                ...state,
                subCategoryList: action.payload,
            };

        case SELECT_REPORTING_SUB_CATEGORY:
            return {
                ...state,
                subCategory: action.payload,
            };

        case SET_REPORTING_EMISSION:
            return {
                ...state,
                emissionList: action.payload,
            };

        case SELECT_REPORTING_EMISSION:
            return {
                ...state,
                emission: action.payload,
            };

        case CHANGE_REPORTING_SOURCE:
            return {
                ...state,
                source: action.payload,
            };

        case CHANGE_REPORTING_REASON:
            return {
                ...state,
                reason: action.payload,
            };

        case CHANGE_REPORTING_ACTIVITY_DATA:
            return {
                ...state,
                activity_data: action.payload,
            };

        case CHANGE_REPORTING_INFORMATION:
            return {
                ...state,
                information: action.payload,
            };

        default:
            return state;
    }
};
