import { AnyAction } from 'redux';
import {
    CHANGE_COMPANY_ADDRESS,
    CHANGE_COMPANY_CREATE_ADDRESS,
    CHANGE_COMPANY_CREATE_EMAIL,
    CHANGE_COMPANY_CREATE_FACILITY_LIMIT,
    CHANGE_COMPANY_CREATE_NAME,
    CHANGE_COMPANY_CREATE_PHONE,
    CHANGE_COMPANY_CREATE_URL,
    CHANGE_COMPANY_DESCRIPTION,
    CHANGE_COMPANY_EMAIL,
    CHANGE_COMPANY_NAME,
    CHANGE_COMPANY_PHONE,
    CHANGE_COMPANY_WEBSITE,
    GENERATE_COMPANY_REPORT,
    GET_COMPANY_RESULT,
    GET_COMPANY_USER_ID,
    SELECT_COMPANY_CITY,
    SELECT_COMPANY_COUNTRY,
    SELECT_COMPANY_DASHBOARD_YEAR,
    SELECT_COMPANY_SECTOR,
    SET_COMPANY_CITY,
    SET_COMPANY_COUNTRY,
    SET_COMPANY_DASHBOARD_DATA,
    SET_COMPANY_ID,
    SET_COMPANY_IMAGE,
    SET_COMPANY_PROFILE_RESULT,
    SET_COMPANY_RESULT,
    SET_COMPANY_SECTOR,
    SET_COMPANY_USER_ID,
} from '../../actions/company/company.action';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';
import { SET_ORGANIZATION_RESULT } from '../../actions/company/organization.action';
import {
    GET_FACILITY_RESULT,
    SELECT_FACILITY_CITY,
    SELECT_FACILITY_COUNTRY,
} from '../../actions/company/facility.action';
import { GET_BASE_YEARS } from '../../actions/emission/base.action';

const initialState = {
    image: '',
    userId: '',
    companyId: '',
    companyName: '',
    companyImage: '',
    email: '',
    websiteUrl: '',
    countryList: [],
    country: { name: '', key: '', label: '' },
    cityList: [],
    city: { name: '', key: '', label: '' },
    address: '',
    phone: '',
    industryList: [],
    industry: { turkishSector: '', label: '' },
    numberOfFacilities: '',
    companyDescription: '',
    facilityCount: 0,
    isLoading: false,
    period: { id: '2', key: '2023', label: '2023', start: '2023-01', end: '2023-12' },
    periodData: [
        { id: '1', key: 'Tüm Yıllar', label: 'Tüm Yıllar', start: '2010-01', end: '2023-12' },
        { id: '2', key: '2023', label: '2023', start: '2023-01', end: '2023-12' },
        { id: '3', key: '2022', label: '2022', start: '2022-01', end: '2022-12' },
        { id: '4', key: '2021', label: '2021', start: '2021-01', end: '2021-12' },
        { id: '5', key: '2020', label: '2020', start: '2020-01', end: '2020-12' },
        { id: '6', key: '2019', label: '2019', start: '2019-01', end: '2019-12' },
        { id: '7', key: '2018', label: '2018', start: '2018-01', end: '2018-12' },
        { id: '8', key: '2017', label: '2017', start: '2017-01', end: '2017-12' },
        { id: '9', key: '2016', label: '2016', start: '2016-01', end: '2016-12' },
        { id: '10', key: '2015', label: '2015', start: '2015-01', end: '2015-12' },
        { id: '11', key: '2014', label: '2014', start: '2014-01', end: '2014-12' },
        { id: '12', key: '2013', label: '2013', start: '2013-01', end: '2013-12' },
        { id: '13', key: '2012', label: '2012', start: '2012-01', end: '2012-12' },
        { id: '14', key: '2011', label: '2011', start: '2011-01', end: '2011-12' },
        { id: '15', key: '2010', label: '2010', start: '2010-01', end: '2010-12' },
    ],
    createCompany: {
        companyName: '',
        email: '',
        phone: '',
        url: '',
        address: '',
        facilityLimit: '',
    },
    dashboard: {
        allTimeTotalEmission: 0,
        total: 0,
        monthlyTotal: 0,
        cumulative: {
            data: [],
            labels: [],
        },
        monthly: {
            data: [],
            labels: [],
        },
        category: {
            data: [] as Array<number>,
            labels: [] as Array<string>,
        },
        scopes: {
            data: [],
            labels: [],
        },
    },
};

export const companyCommonReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        //case SUBMIT_COMPANY_FORM: {
        //    return {
        //        ...state,
        //        isLoading: false,
        //    };
        //}
        case GENERATE_COMPANY_REPORT: {
            return state;
        }
        case GET_BASE_YEARS: {
            const isTR = action.payload?.includes('tr');
            return {
                ...state,
                periodData: [
                    {
                        id: '1',
                        key: isTR ? 'Tüm Yıllar' : 'All Years',
                        label: isTR ? 'Tüm Yıllar' : 'All Years',
                        start: '2010-01',
                        end: '2023-12',
                    },
                    { id: '2', key: '2023', label: '2023', start: '2023-01', end: '2023-12' },
                    { id: '3', key: '2022', label: '2022', start: '2022-01', end: '2022-12' },
                    { id: '4', key: '2021', label: '2021', start: '2021-01', end: '2021-12' },
                    { id: '5', key: '2020', label: '2020', start: '2020-01', end: '2020-12' },
                    { id: '6', key: '2019', label: '2019', start: '2019-01', end: '2019-12' },
                    { id: '7', key: '2018', label: '2018', start: '2018-01', end: '2018-12' },
                    { id: '8', key: '2017', label: '2017', start: '2017-01', end: '2017-12' },
                    { id: '9', key: '2016', label: '2016', start: '2016-01', end: '2016-12' },
                    { id: '10', key: '2015', label: '2015', start: '2015-01', end: '2015-12' },
                    { id: '11', key: '2014', label: '2014', start: '2014-01', end: '2014-12' },
                    { id: '12', key: '2013', label: '2013', start: '2013-01', end: '2013-12' },
                    { id: '13', key: '2012', label: '2012', start: '2012-01', end: '2012-12' },
                    { id: '14', key: '2011', label: '2011', start: '2011-01', end: '2011-12' },
                    { id: '15', key: '2010', label: '2010', start: '2010-01', end: '2010-12' },
                ],
            };
        }
        case SET_AUTH_LOGOUT:
            return initialState;
        case GET_COMPANY_RESULT:
            return {
                ...state,
                address: '',
                phone: '',
                companyDescription: '',
                companyName: '',
                email: '',
                industry: { turkishSector: '', label: '' },
                websiteUrl: '',
                country: { name: '', key: '', label: '' },
                city: { name: '', key: '', label: '' },
                facilityCount: 0,
                isLoading: false,
                numberOfFacilities: '',
                createCompany: {
                    ...state.createCompany,
                    url: '',
                    facilityLimit: '',
                },
            };

        case GET_FACILITY_RESULT:
            return {
                ...state,
                country: { name: '', key: '', label: '' },
                city: { name: '', key: '', label: '' },
                createCompany: {
                    ...state.createCompany,
                    facilityLimit: action.payload?.facilityLimit,
                },
            };
        case SET_COMPANY_RESULT:
            return {
                ...state,
                address: action.payload.address,
                phone: action.payload?.phone,
                companyDescription: action.payload?.companyDescription,
                companyName: action.payload?.companyName,
                email: action.payload?.email,
                industry: action.payload?.sector,
                websiteUrl: action.payload?.websiteUrl,
                country: action.payload?.country,
                city: action.payload?.state,
                facilityCount: action.payload?.facilityCount,
                isLoading: true,
                numberOfFacilities: action.payload?.numberOfFacilities,
                createCompany: {
                    ...state.createCompany,
                    facilityLimit: action.payload?.numberOfFacilities || action.payload?.facilityLimit,
                    url: action.payload?.websiteUrl,
                },
            };
        case SELECT_FACILITY_CITY:
            return {
                ...state,
                city: action.payload,
            };
        case SELECT_FACILITY_COUNTRY:
            return {
                ...state,
                country: state.countryList[0],
            };
        case SET_ORGANIZATION_RESULT:
            return {
                ...state,
                address: action.payload.address,
                phone: action.payload?.phone,
                companyDescription: action.payload?.companyDescription,
                companyName: action.payload?.organizationName,
                email: action.payload?.email,
                industry: action.payload?.sector,
                websiteUrl: action.payload?.websiteUrl,
                country: action.payload?.country,
                city: action.payload?.state,
                facilityCount: action.payload?.companyCount,
                isLoading: true,
            };

        case SET_COMPANY_PROFILE_RESULT:
            return {
                ...state,
                address: action.payload.address,
                phone: action.payload?.phone,
                companyDescription: action.payload?.companyDescription,
                companyName: action.payload?.companyName,
                email: action.payload?.email,
                industry: action.payload?.sector,
                websiteUrl: action.payload?.websiteUrl,
                country: action.payload?.country,
                city: action.payload?.state,
                isLoading: true,
            };

        case SET_COMPANY_IMAGE:
            return {
                ...state,
                companyImage: action.payload,
            };

        case SELECT_COMPANY_DASHBOARD_YEAR:
            return {
                ...state,
                period: action.payload,
            };

        case GET_COMPANY_USER_ID:
            return {
                ...state,
                isLoading: false,
            };

        case SET_COMPANY_ID:
            return {
                ...state,
                companyId: action.payload,
            };

        case SET_COMPANY_USER_ID:
            return {
                ...state,
                userId: action.payload,
            };

        case CHANGE_COMPANY_NAME:
            return {
                ...state,
                companyName: action.payload,
            };

        case CHANGE_COMPANY_EMAIL:
            return {
                ...state,
                email: action.payload,
            };

        case CHANGE_COMPANY_WEBSITE:
            return {
                ...state,
                websiteUrl: action.payload,
            };

        case CHANGE_COMPANY_PHONE:
            return {
                ...state,
                phone: action.payload,
            };

        case SET_COMPANY_COUNTRY:
            return {
                ...state,
                countryList: action.payload,
            };

        case SELECT_COMPANY_COUNTRY:
            return {
                ...state,
                country: action.payload,
                cityList: [],
                city: { name: '', key: '', label: '' },
            };

        case SET_COMPANY_CITY:
            return {
                ...state,
                cityList: action.payload,
            };

        case SELECT_COMPANY_CITY:
            return {
                ...state,
                city: action.payload,
            };

        case SET_COMPANY_SECTOR:
            return {
                ...state,
                industryList: action.payload,
            };

        case SELECT_COMPANY_SECTOR:
            return {
                ...state,
                industry: action.payload,
            };

        case CHANGE_COMPANY_ADDRESS:
            return {
                ...state,
                address: action.payload,
            };

        case CHANGE_COMPANY_DESCRIPTION:
            return {
                ...state,
                companyDescription: action.payload,
            };

        case SET_COMPANY_DASHBOARD_DATA:
            return {
                ...state,
                dashboard: action.payload,
            };
        case CHANGE_COMPANY_CREATE_NAME:
            return {
                ...state,
                createCompany: {
                    ...state.createCompany,
                    companyName: action.payload,
                },
            };

        case CHANGE_COMPANY_CREATE_EMAIL:
            return {
                ...state,
                createCompany: {
                    ...state.createCompany,
                    email: action.payload,
                },
            };
        case CHANGE_COMPANY_CREATE_PHONE:
            return {
                ...state,
                createCompany: {
                    ...state.createCompany,
                    phone: action.payload,
                },
            };
        case CHANGE_COMPANY_CREATE_URL:
            return {
                ...state,
                createCompany: {
                    ...state.createCompany,
                    url: action.payload,
                },
            };
        case CHANGE_COMPANY_CREATE_ADDRESS:
            return {
                ...state,
                createCompany: {
                    ...state.createCompany,
                    address: action.payload,
                },
            };
        case CHANGE_COMPANY_CREATE_FACILITY_LIMIT:
            return {
                ...state,
                createCompany: {
                    ...state.createCompany,
                    facilityLimit: action.payload,
                },
            };
        default:
            return state;
    }
};
