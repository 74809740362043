import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IUpstreamLeasedActivity,
    IUpstreamLeasedActivityType,
    IUpstreamLeasedAdditional,
    IUpstreamLeasedDatabase,
    IUpstreamLeasedFuelType,
    IUpstreamLeasedResult,
    IUpstreamLeasedSize,
    IUpstreamLeasedUnit,
} from '../../../entities/emissionTypes/upstreamLeasedCombustion';
import { EMISSION } from './base.action';

export const UPSTREAM_LEASED = 'UPSTREAM_LEASED';

////-- Base
export const CHANGE_UPSTREAM_LEASED_MAIN_FACILITY = `${EMISSION} ${UPSTREAM_LEASED} Change Main Facility`;
export const CHANGE_UPSTREAM_LEASED_SUB_FACILITY = `${EMISSION} ${UPSTREAM_LEASED} Change Sub Facility`;

export const GET_UPSTREAM_LEASED_TIERS = `${EMISSION} ${UPSTREAM_LEASED} Get Tiers`;
export const SET_UPSTREAM_LEASED_TIERS = `${EMISSION} ${UPSTREAM_LEASED} Set Tiers`;
export const SELECT_UPSTREAM_LEASED_TIER = `${EMISSION} ${UPSTREAM_LEASED} Select Tier`;

export const GET_UPSTREAM_LEASED_DATABASES = `${EMISSION} ${UPSTREAM_LEASED} Get Databases`;
export const SET_UPSTREAM_LEASED_DATABASES = `${EMISSION} ${UPSTREAM_LEASED} Set Databases`;
export const SELECT_UPSTREAM_LEASED_DATABASE = `${EMISSION} ${UPSTREAM_LEASED} Select Database`;

export const CHANGE_UPSTREAM_LEASED_EQUIVALENT_FACTOR = `${EMISSION} ${UPSTREAM_LEASED} Change Equivalent Factor`;

export const GET_UPSTREAM_LEASED_ACTIVITIES = `${EMISSION} ${UPSTREAM_LEASED} Get Activities`;
export const SET_UPSTREAM_LEASED_ACTIVITIES = `${EMISSION} ${UPSTREAM_LEASED} Set Activities`;
export const SELECT_UPSTREAM_LEASED_ACTIVITY = `${EMISSION} ${UPSTREAM_LEASED} Select Activity`;
/////--- End base

export const GET_UPSTREAM_LEASED_FUEL_TYPES = `${EMISSION} ${UPSTREAM_LEASED} Get Fuel Types`;
export const SET_UPSTREAM_LEASED_FUEL_TYPES = `${EMISSION} ${UPSTREAM_LEASED} Set Fuel Types`;
export const SELECT_UPSTREAM_LEASED_FUEL_TYPE = `${EMISSION} ${UPSTREAM_LEASED} Select Fuel Type`;

export const GET_UPSTREAM_LEASED_ACTIVITY_TYPES = `${EMISSION} ${UPSTREAM_LEASED} Get Activity Types`;
export const SET_UPSTREAM_LEASED_ACTIVITY_TYPES = `${EMISSION} ${UPSTREAM_LEASED} Set Activity Types`;
export const SELECT_UPSTREAM_LEASED_ACTIVITY_TYPE = `${EMISSION} ${UPSTREAM_LEASED} Select Activity Type`;

export const GET_UPSTREAM_LEASED_UNITS = `${EMISSION} ${UPSTREAM_LEASED} Get Units`;
export const SET_UPSTREAM_LEASED_UNITS = `${EMISSION} ${UPSTREAM_LEASED} Set Units`;
export const SELECT_UPSTREAM_LEASED_UNIT = `${EMISSION} ${UPSTREAM_LEASED} Select Unit`;

export const GET_UPSTREAM_LEASED_ADDITIONAL_CONSTANTS = `${EMISSION} ${UPSTREAM_LEASED} Get Additional Constants`;
export const SET_UPSTREAM_LEASED_ADDITIONAL_CONSTANTS = `${EMISSION} ${UPSTREAM_LEASED} Set Additional Constants`;

export const CHANGE_UPSTREAM_LEASED_WEIGHT = `${EMISSION} ${UPSTREAM_LEASED} Change Weight`;
export const CHANGE_UPSTREAM_LEASED_DISTANCE = `${EMISSION} ${UPSTREAM_LEASED} Change Distance`;

export const GET_UPSTREAM_LEASED_LADEN_PERCENTAGES = `${EMISSION} ${UPSTREAM_LEASED} Get Laden Percentages`;
export const SET_UPSTREAM_LEASED_LADEN_PERCENTAGES = `${EMISSION} ${UPSTREAM_LEASED} Set Laden Percentages`;
export const SELECT_UPSTREAM_LEASED_LADEN_PERCENTAGE = `${EMISSION} ${UPSTREAM_LEASED} Select Laden percentage`;

export const GET_UPSTREAM_LEASED_SIZES = `${EMISSION} ${UPSTREAM_LEASED} Get Sizes`;
export const SET_UPSTREAM_LEASED_SIZES = `${EMISSION} ${UPSTREAM_LEASED} Set Sizes`;
export const SELECT_UPSTREAM_LEASED_SIZE = `${EMISSION} ${UPSTREAM_LEASED} Select Size`;

////-- Base
export const CHANGE_UPSTREAM_LEASED_YEAR = `${EMISSION} ${UPSTREAM_LEASED} Change Year`;
export const CHANGE_UPSTREAM_LEASED_TAGS = `${EMISSION} ${UPSTREAM_LEASED} Change Tags`;
export const SELECT_UPSTREAM_LEASED_ACTIVITY_DATA = `${EMISSION} ${UPSTREAM_LEASED} Select Activity Data`;
export const CHANGE_UPSTREAM_LEASED_CONSUMPTION_AMOUNT = `${EMISSION} ${UPSTREAM_LEASED} Change Consumption Amount`;
export const CHANGE_UPSTREAM_LEASED_DESCRIPTION = `${EMISSION} ${UPSTREAM_LEASED} Change Description`;

export const SUBMIT_UPSTREAM_LEASED_FORM = `${EMISSION} ${UPSTREAM_LEASED} Submit Form`;
export const SET_UPSTREAM_LEASED_RESULT = `${EMISSION} ${UPSTREAM_LEASED} Set Result`;
/////-- End Base
export const CLEAR_UPSTREAM_LEASED_CONSUMPTION_AMOUNT = `${EMISSION} ${UPSTREAM_LEASED} Clear Consumption Amount`;
export const CLEAR_UPSTREAM_LEASED_INPUT = `${EMISSION} ${UPSTREAM_LEASED} Clear UpstreamLeased Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearUpstreamLeasedInput = (list: Array<string>) => ({
    type: CLEAR_UPSTREAM_LEASED_INPUT,
    payload: list,
});
/// Region UpstreamLeased
export const changeUpstreamLeasedMainFacility = (text: string) => ({
    type: CHANGE_UPSTREAM_LEASED_MAIN_FACILITY,
    payload: text,
});

export const changeUpstreamLeasedSubFacility = (text: string) => {
    return {
        type: CHANGE_UPSTREAM_LEASED_SUB_FACILITY,
        payload: text,
    };
};

export const getUpstreamLeasedTiers = () => ({
    type: GET_UPSTREAM_LEASED_TIERS,
});

export const setUpstreamLeasedTiers = (list: Array<ITier>) => ({
    type: SET_UPSTREAM_LEASED_TIERS,
    payload: list,
});

export const selectUpstreamLeasedTier = (tier: ITier) => ({
    type: SELECT_UPSTREAM_LEASED_TIER,
    payload: tier,
});

export const getUpstreamLeasedDatabases = (tier: ITier) => ({
    type: GET_UPSTREAM_LEASED_DATABASES,
    payload: tier,
});

export const setUpstreamLeasedDatabases = (list: Array<IUpstreamLeasedDatabase>) => ({
    type: SET_UPSTREAM_LEASED_DATABASES,
    payload: list,
});

export const selectUpstreamLeasedDatabase = (database: IUpstreamLeasedDatabase) => ({
    type: SELECT_UPSTREAM_LEASED_DATABASE,
    payload: database,
});

export const getUpstreamLeasedActivities = (tier: ITier) => ({
    type: GET_UPSTREAM_LEASED_ACTIVITIES,
    payload: tier,
});

export const setUpstreamLeasedActivities = (activities: Array<IUpstreamLeasedActivity>) => ({
    type: SET_UPSTREAM_LEASED_ACTIVITIES,
    payload: activities,
});

export const selectUpstreamLeasedActivity = (activity: IUpstreamLeasedActivity) => ({
    type: SELECT_UPSTREAM_LEASED_ACTIVITY,
    payload: activity,
});

///////
export const getUpstreamLeasedFuelTypes = (tier: ITier) => ({
    type: GET_UPSTREAM_LEASED_FUEL_TYPES,
    payload: tier,
});

export const setUpstreamLeasedFuelTypes = (list: Array<IUpstreamLeasedFuelType>) => ({
    type: SET_UPSTREAM_LEASED_FUEL_TYPES,
    payload: list,
});

export const selectUpstreamLeasedFuelType = (database: IUpstreamLeasedFuelType) => ({
    type: SELECT_UPSTREAM_LEASED_FUEL_TYPE,
    payload: database,
});

export const getUpstreamLeasedActivityTypes = (value: any) => ({
    type: GET_UPSTREAM_LEASED_ACTIVITY_TYPES,
    payload: value,
});

export const setUpstreamLeasedActivityTypes = (activities: Array<IUpstreamLeasedActivityType>) => ({
    type: SET_UPSTREAM_LEASED_ACTIVITY_TYPES,
    payload: activities,
});

export const selectUpstreamLeasedActivityType = (activity: IUpstreamLeasedActivityType) => ({
    type: SELECT_UPSTREAM_LEASED_ACTIVITY_TYPE,
    payload: activity,
});

export const getUpstreamLeasedUnits = (units: IUpstreamLeasedActivityType) => ({
    type: GET_UPSTREAM_LEASED_UNITS,
    payload: units,
});

export const setUpstreamLeasedUnits = (units: Array<IUpstreamLeasedUnit>) => ({
    type: SET_UPSTREAM_LEASED_UNITS,
    payload: units,
});

export const selectUpstreamLeasedUnit = (unit: IUpstreamLeasedUnit) => ({
    type: SELECT_UPSTREAM_LEASED_UNIT,
    payload: unit,
});

export const getUpstreamLeasedAdditionalConstants = (additional: IUpstreamLeasedUnit) => ({
    type: GET_UPSTREAM_LEASED_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setUpstreamLeasedAdditionalConstants = (additional: Array<IUpstreamLeasedAdditional>) => ({
    type: SET_UPSTREAM_LEASED_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeUpstreamLeasedWeight = (value: number) => ({
    type: CHANGE_UPSTREAM_LEASED_WEIGHT,
    payload: value,
});

export const changeUpstreamLeasedDistance = (value: number) => ({
    type: CHANGE_UPSTREAM_LEASED_DISTANCE,
    payload: value,
});

export const getUpstreamLeasedLadenPercentages = (ladens: any) => ({
    type: GET_UPSTREAM_LEASED_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setUpstreamLeasedLadenPercentages = (ladens: Array<any>) => ({
    type: SET_UPSTREAM_LEASED_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectUpstreamLeasedLadenPercentage = (laden: any) => ({
    type: SELECT_UPSTREAM_LEASED_LADEN_PERCENTAGE,
    payload: laden,
});

export const getUpstreamLeasedSizes = (sizes: IUpstreamLeasedActivityType) => ({
    type: GET_UPSTREAM_LEASED_SIZES,
    payload: sizes,
});

export const setUpstreamLeasedSizes = (sizes: Array<IUpstreamLeasedSize>) => ({
    type: SET_UPSTREAM_LEASED_SIZES,
    payload: sizes,
});

export const selectUpstreamLeasedSize = (size: IUpstreamLeasedSize) => ({
    type: SELECT_UPSTREAM_LEASED_SIZE,
    payload: size,
});

///////
export const submitUpstreamLeasedForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_UPSTREAM_LEASED_FORM,
    meta: {
        onSuccess,
    },
});

export const setUpstreamLeasedResult = (data: Array<IUpstreamLeasedResult>) => ({
    type: SET_UPSTREAM_LEASED_RESULT,
    payload: data,
});

export const selectUpstreamLeasedActivityData = (activity: string) => ({
    type: SELECT_UPSTREAM_LEASED_ACTIVITY_DATA,
    payload: activity,
});

export const changeUpstreamLeasedYear = (value: number) => ({
    type: CHANGE_UPSTREAM_LEASED_YEAR,
    payload: value,
});

export const changeUpstreamLeasedTags = (value: string) => ({
    type: CHANGE_UPSTREAM_LEASED_TAGS,
    payload: value,
});

export const changeUpstreamLeasedDescription = (text: string) => ({
    type: CHANGE_UPSTREAM_LEASED_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_UPSTREAM_LEASED_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_UPSTREAM_LEASED_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_UPSTREAM_LEASED_CONSUMPTION_AMOUNT,
});
/// End Region UpstreamLeased
