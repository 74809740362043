import { Middleware } from 'redux';
import { API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    DELETE_USER_GROUP_BY_ID,
    DELETE_USER_GROUP_BY_PRIVILEGE_GROUP_ID,
    DELETE_USER_GROUP_BY_USER_ID,
    GET_USER_GROUP_BY_ID,
    POST_USER_GROUP,
    POST_USER_GROUP_NAMES,
    PUT_USER_GROUP_BY_PRIVILEGE_GROUP_ID,
    PUT_USER_GROUP_BY_USER_ID,
} from '../../../actions/system/userGroup.action';

export const userGroupMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case PUT_USER_GROUP_BY_USER_ID: {
                const userGroupId = 0;
                const userId = 0;

                next(
                    apiRequest({
                        method: 'PUT',
                        url: `/auth/userGroup/${userGroupId}/user/${userId}`,
                        feature: PUT_USER_GROUP_BY_USER_ID,
                    })
                );
                break;
            }
            case `${PUT_USER_GROUP_BY_USER_ID} ${API_SUCCESS}`: {
                break;
            }

            case DELETE_USER_GROUP_BY_USER_ID: {
                const userGroupId = 0;
                const userId = 0;

                next(
                    apiRequest({
                        method: 'DELETE',
                        url: `/auth/userGroup/${userGroupId}/user/${userId}`,
                        feature: DELETE_USER_GROUP_BY_USER_ID,
                    })
                );
                break;
            }
            case `${DELETE_USER_GROUP_BY_USER_ID} ${API_SUCCESS}`: {
                break;
            }

            case PUT_USER_GROUP_BY_PRIVILEGE_GROUP_ID: {
                const userGroupId = 0;
                const privilegeGroupId = 0;
                next(
                    apiRequest({
                        method: 'PUT',
                        url: `/auth/userGroup/${userGroupId}/privilegeGroup/${privilegeGroupId}`,
                        feature: PUT_USER_GROUP_BY_PRIVILEGE_GROUP_ID,
                    })
                );
                break;
            }
            case `${PUT_USER_GROUP_BY_PRIVILEGE_GROUP_ID} ${API_SUCCESS}`: {
                break;
            }

            case DELETE_USER_GROUP_BY_PRIVILEGE_GROUP_ID: {
                const userGroupId = 0;
                const privilegeGroupId = 0;

                next(
                    apiRequest({
                        method: 'DELETE',
                        url: `/auth/userGroup/${userGroupId}/privilegeGroup/${privilegeGroupId}`,
                        feature: DELETE_USER_GROUP_BY_PRIVILEGE_GROUP_ID,
                    })
                );
                break;
            }
            case `${DELETE_USER_GROUP_BY_PRIVILEGE_GROUP_ID} ${API_SUCCESS}`: {
                break;
            }

            case POST_USER_GROUP: {
                interface IBody {
                    name: string;
                    description: string;
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                    userIdList: Array<number>;
                    privilegeGroupIdList: Array<number>;
                }

                const body: IBody = {
                    name: 'string',
                    description: 'string',
                    organizationId: 0,
                    companyId: 0,
                    facilityId: 0,
                    userIdList: [0],
                    privilegeGroupIdList: [0],
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/userGroup',
                        feature: POST_USER_GROUP,
                        body,
                    })
                );
                break;
            }
            case `${POST_USER_GROUP} ${API_SUCCESS}`: {
                break;
            }

            case POST_USER_GROUP_NAMES: {
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                }
                const body: IBody = {
                    organizationId: 0,
                    companyId: 0,
                    facilityId: 0,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/userGroup/names',
                        feature: POST_USER_GROUP_NAMES,
                        body,
                    })
                );
                break;
            }
            case `${POST_USER_GROUP_NAMES} ${API_SUCCESS}`: {
                break;
            }

            case GET_USER_GROUP_BY_ID: {
                const id = 0;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/auth/userGroup/${id}`,
                        feature: GET_USER_GROUP_BY_ID,
                    })
                );
                break;
            }
            case `${GET_USER_GROUP_BY_ID} ${API_SUCCESS}`: {
                break;
            }

            case DELETE_USER_GROUP_BY_ID: {
                const id = 0;
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: `/auth/userGroup/${id}`,
                        feature: DELETE_USER_GROUP_BY_ID,
                    })
                );
                break;
            }
            case `${DELETE_USER_GROUP_BY_ID} ${API_SUCCESS}`: {
                break;
            }
        }
    };
