import { Middleware } from 'redux';
import { ITier } from '../../../../entities/emissionTypes/baseType';
import {
    IBusinessActivity,
    IBusinessActivityType,
    IBusinessDatabase,
    IBusinessFuelType,
    IBusinessResult,
    IBusinessUnit,
} from '../../../../entities/emissionTypes/businessCombustion';

import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_BUSINESS_ACTIVITIES,
    GET_BUSINESS_ACTIVITY_TYPES,
    GET_BUSINESS_DATABASES,
    GET_BUSINESS_FUEL_TYPES,
    GET_BUSINESS_TIERS,
    GET_BUSINESS_UNITS,
    setBusinessActivities,
    setBusinessActivityTypes,
    setBusinessDatabases,
    setBusinessFuelTypes,
    setBusinessResult,
    setBusinessTiers,
    setBusinessUnits,
    SUBMIT_BUSINESS_FORM,
} from '../../../actions/emission/business.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const emissionBusinessMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            // region BUSINESS
            case GET_BUSINESS_TIERS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/common/data/tiers',
                        feature: GET_BUSINESS_TIERS,
                        query: {
                            emissionCategoryId: 11,
                        },
                    })
                );
                break;
            case `${GET_BUSINESS_TIERS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<ITier>,
                        v: {
                            id: number;
                            language: string;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setBusinessTiers(list));
                break;
            }

            case GET_BUSINESS_DATABASES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/emissionFactorDatabases',
                        feature: GET_BUSINESS_DATABASES,
                        query: {
                            tierId: action.payload.id,
                            emissionCategoryId: 11,
                        },
                    })
                );
                break;

            case `${GET_BUSINESS_DATABASES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IBusinessDatabase>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setBusinessDatabases(list));
                break;
            }

            case GET_BUSINESS_ACTIVITIES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'businessTravelEmission/data/emissionSources',
                        feature: GET_BUSINESS_ACTIVITIES,
                        query: {
                            emissionFactorDatabaseId: action.payload.id || state.emission.business.databases[0].id,
                        },
                    })
                );
                break;

            case `${GET_BUSINESS_ACTIVITIES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IBusinessActivity>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            title: v.title,
                        });
                        return acc;
                    },
                    []
                );
                next(setBusinessActivities(list));
                break;
            }

            case GET_BUSINESS_ACTIVITY_TYPES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'businessTravelEmission/data/emissionActivities',
                        feature: GET_BUSINESS_ACTIVITY_TYPES,
                        query: {
                            emissionSourceId: action.payload.id,
                        },
                    })
                );
                break;

            case `${GET_BUSINESS_ACTIVITY_TYPES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IBusinessActivityType>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setBusinessActivityTypes(list));
                break;
            }

            case GET_BUSINESS_FUEL_TYPES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'businessTravelEmission/data/emissionActivityTypes',
                        feature: GET_BUSINESS_FUEL_TYPES,
                        query: {
                            emissionActivityId: action.payload.id,
                        },
                    })
                );
                break;

            case `${GET_BUSINESS_FUEL_TYPES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IBusinessFuelType>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setBusinessFuelTypes(list));
                break;
            }

            case GET_BUSINESS_UNITS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'businessTravelEmission/data/units',
                        feature: GET_BUSINESS_UNITS,
                        query: {
                            emissionActivityTypeId: action.payload?.id || state.emission.business.sourceList[0]?.id,
                        },
                    })
                );
                break;

            case `${GET_BUSINESS_UNITS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IBusinessUnit>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                            arg0: string;
                            arg1?: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            arg0: v.arg0,
                            arg1: v?.arg1,
                        });
                        return acc;
                    },
                    []
                );
                next(setBusinessUnits(list));
                break;
            }

            case SUBMIT_BUSINESS_FORM: {
                interface IBody {
                    organizationId?: number;
                    companyId: number;
                    facilityId: number;
                    consumptionList: Array<{ arg0: number; arg1: number; period: string }>;
                    tierId: number;
                    emissionFactorDatabaseId: number;
                    emissionSourceId: number;
                    emissionActivityId: number;
                    emissionActivityTypeId: number;
                    emissionCategoryId: number;
                    unitId: number;
                    equivalentFactor?: number;
                    tags: string;
                    equivalentTags: string;
                    description: string;
                    activityData: string;
                }

                const body: IBody = {
                    organizationId:
                        state?.auth?.userInfo?.organization?.id ||
                        state?.auth?.userInfo?.company?.organizationId ||
                        //state?.auth?.userInfo?.user?.organizationId ||
                        null,
                    companyId: state?.emission?.base?.selectedCompany?.id,
                    facilityId: state?.emission?.base?.selectedFacility?.id,
                    consumptionList: [],
                    tierId: state.emission.business.tier.id,
                    emissionFactorDatabaseId:
                        state.emission.business.database?.id || state.emission.business.databases[0]?.id,
                    emissionSourceId: state.emission.business.activity?.id,
                    emissionActivityId: state.emission.business.activityType?.id,
                    emissionActivityTypeId:
                        state.emission.business.source?.id || state.emission.business.sourceList[0]?.id,
                    emissionCategoryId: 11,
                    unitId: state.emission.business.unit?.id || Number(state.emission.business.perNight),
                    equivalentFactor: state.emission.business.equivalentFactor || null,
                    tags: state.emission.base.tags || '',
                    equivalentTags: state.emission.base.equivalentTags || '',
                    description: state.emission.business.description,
                    activityData: state.emission.business.activityData,
                };
                const consumptions = state.emission.business.consumptions;
                const consumptionKeys: Array<string> = Object.keys(consumptions as { [key: number]: number | null });
                const consumptionData = consumptionKeys.reduce(
                    (acc: Array<{ arg0: number; arg1: number; period: string }>, key: string) => {
                        const numberKey = Number(key);
                        if (consumptions[key]['km'] && numberKey !== 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['km']),
                                arg1: Number(consumptions[key]['tonne']),
                                period: `${state?.emission?.base?.calculationYear}-${numberKey + 1 < 10 ? '0' : ''}${
                                    numberKey + 1
                                }`,
                            });
                        }
                        if (consumptions[key]['km'] && numberKey === 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['km']),
                                arg1: Number(consumptions[key]['tonne']),
                                period: state?.emission?.base?.calculationYear,
                            });
                        }
                        return acc;
                    },
                    []
                );
                body.consumptionList = consumptionData;
                next(
                    apiRequest({
                        method: 'POST',
                        url: 'businessTravelEmission/calculate',
                        feature: SUBMIT_BUSINESS_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_BUSINESS_FORM} ${API_SUCCESS}`: {
                const data = action.payload.corporateEmissionDto.emissionList;
                const onSuccess = action.meta.onSuccess;
                const savedData: Array<IBusinessResult> = [...data];
                next(setBusinessResult(savedData));
                onSuccess();
                break;
            }

            case `${SUBMIT_BUSINESS_FORM} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }

            // endregion BUSINESS
        }
    };
