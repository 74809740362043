import { AnyAction } from 'redux';
import {
    CHANGE_INTERNAL_PERFORMANCE_REPORT,
    SET_INTERNAL_ANALYTIC,
    SET_INTERNAL_RESULT,
} from '../../actions/company/internal.action';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';
import { ICategoryTotal, ICategoryType } from '../../../entities/company/internalCombustion';

const initialState = {
    internalPerformanceReport:
        'Spesifik performans ölçütleri henüz belirlenmemiştir (bu envanter ve rapor, baz yılımıza paralel olarak hazırlanmıştır). Bunun yerine, burada sunulan içgörülerin, performans ölçütlerinin oluşturulmasını ve ilerlemelerinin izlenmesini desteklemek için gelecekteki karar verme için bir temel sağlaması amaçlanmaktadır.',
    analytic: {
        categoryTypeTotalCalculatedEmissionStatisticDtoList: [
            {
                scope: '',
                scopeName: '',
                categoryType: '',
                categoryTypeName: '',
                category: '',
                categoryName: '',
                totalEmission: 0,
                totalEmissionPercentage: 0,
            },
        ],
        categoryTotalCalculatedEmissionStatisticDtoList: [
            {
                scope: '',
                scopeName: '',
                categoryType: '',
                categoryTypeName: '',
                category: '',
                categoryName: '',
                totalEmission: 0,
                totalEmissionPercentage: 0,
            },
        ],
    },
};

export const internalCommonReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case SET_AUTH_LOGOUT:
            return initialState;
        case SET_INTERNAL_RESULT:
            const { internalPerformanceReport } = action.payload;
            return {
                ...state,
                internalPerformanceReport,
            };

        case SET_INTERNAL_ANALYTIC:
            return {
                ...state,
                analytic: {
                    categoryTypeTotalCalculatedEmissionStatisticDtoList:
                        action.payload.categoryTypeTotalCalculatedEmissionStatisticDtoList,
                    categoryTotalCalculatedEmissionStatisticDtoList:
                        action.payload.categoryTotalCalculatedEmissionStatisticDtoList,
                },
            };
        case CHANGE_INTERNAL_PERFORMANCE_REPORT:
            return {
                ...state,
                internalPerformanceReport: action.payload,
            };
        default:
            return state;
    }
};
