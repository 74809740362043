import EmissionType, { TypeEnum } from '../entities/emissionType.model';

export const initialTypeData: Array<EmissionType> = [
    {
        id: '1',
        title: 'Sabit Yanma',
        description:
            'Isıtıcılar, kazanlar, gaz türbinleri, jeneratörler, yangın pompaları gibi sabit ekipmanlarda yakılan her tür yakıtın yanması sonucu oluşan emisyonlardır.',
        type: TypeEnum.STATIC_COMBUSTION,
        disabled: false,
    },
    {
        id: '2',
        title: 'Mobil Yanma',
        description:
            'Bir firma tarafından sahip olunan veya kontrol edilen arabalar, kamyonetler ve kamyonlar gibi araçlardan yakıtların yanması sonucu oluşan emisyonlardır.',
        type: TypeEnum.MOBILE_COMBUSTION,
        disabled: false,
    },
    {
        id: '3',
        title: 'Proses Emisyonları',
        description:
            'Organizasyonel sınırlar içerisinde gerçekleşen endüstriyel prosesler sonucu oluşan emisyonlardır.',
        type: TypeEnum.PROCESS_EMISSIONS,
        disabled: false,
    },
    {
        id: '4',
        title: 'Sızıntı Emisyonları',
        description: 'Soğutma ve klima üniteleri gibi ekipmanlardan kaçan gazlar sonucu oluşan emisyonlardır.',
        type: TypeEnum.LEAK_EMISSIONS,
        disabled: false,
    },
    {
        id: '5',
        title: 'Elektrik Tüketimleri',
        description: 'Satın alınan elektrik tüketimi sonucu oluşan emisyonlardır.',
        type: TypeEnum.ELECTRIC_CONSUMPTION,
        disabled: false,
    },
    {
        id: '6',
        title: 'Isı / Buhar Tüketimleri',
        description: 'Satın alınan ısı/buhar tüketimi sonucu oluşan emisyonlardır.',
        type: TypeEnum.STEAM_EMISSIONS,
        disabled: false,
    },
    {
        id: '7',
        title: 'Yukarı Yönlü Taşımacılık',
        description:
            'Kuruma ait olmayan araçlar ile tedarikçiden kuruluşa gerçekleştirilen nakliye faaliyetleri veya tedarik zinciri boyunca tüm nakliye ekipmanlarından kaynaklı emisyonlardır.',
        type: TypeEnum.INCOME_MATERIAL_TRANSPORTATION,
        disabled: false,
    },
    {
        id: '8',
        title: 'Aşağı Yönlü Taşımacılık',
        description:
            'Kuruma ait olmayan araçlar ile kurumdan Quantified tüketiciye gerçekleştirilen nakliye faaliyetleri veya tedarik zinciri boyunca tüm nakliye ekipmanlarından kaynaklı emisyonlardır.',
        type: TypeEnum.OUTCOME_MATERIAL_TRANSPORTATION,
        disabled: false,
    },
    {
        id: '9',
        title: 'Çalışan İşe Geliş Gidiş',
        description: 'Çalışan işe geliş/gidiş ’ini sağlayan kuruma ait olmayan araçlardan kaynaklı emisyonlardır.',
        type: TypeEnum.EMPLOYEE_TRANSPORTATION,
        disabled: false,
    },
    {
        id: '10',
        title: 'Ziyaretçi / Müşteri Tesise Ulaşım',
        description:
            'Ziyaretçi ve müşterilerin kuruma ulaşımını sağlayan kuruma ait olmayan araçlardan kaynaklı emisyonlardır.',
        type: TypeEnum.VISITOR_TRANSPORTATION,
        disabled: false,
    },
    {
        id: '11',
        title: 'İş Seyahatleri',
        description:
            'İş seyahatlerinde kullanılan kuruma ait olmayan araçlardan ve otel konaklamalarından kaynaklı emisyonlardır.',
        type: TypeEnum.BUSINESS_TRAVEL,
        disabled: false,
    },
    {
        id: '12',
        title: 'Satın Alınan Ürün',
        description:
            'Organizasyonel sınırların dışında bulunan kaynaklardan temin edilen ve kurum içerisinde kullanılan mallardan kaynaklı emisyonlardır.',
        type: TypeEnum.SOLD_PRODUCTS,
        disabled: false,
    },
    {
        id: '13',
        title: 'Sermaye Mallar',
        description: 'Kurum tarafından satın alınan sermaye mallardan kaynaklı emisyonlardır.',
        type: TypeEnum.BOUGHT_ASSETS,
        disabled: false,
    },
    {
        id: '14',
        title: 'Katı ve Sıvı Atık Bertarafı',
        description:
            'Kurum tarafından oluşturulan atıkların bertarafı ve atıksu arıtımı işlemleri kaynaklı emisyonlardır.',
        type: TypeEnum.WASTE_DISPOSAL,
        disabled: false,
    },
    {
        id: '15',
        title: 'Hizmet Kullanımı',
        description: 'Hizmet Kullanımı',
        type: TypeEnum.SERVICE_USAGE,
        disabled: true,
    },

    {
        id: '16',
        title: 'Ürün Kullanım Aşaması',
        description: 'Kurum tarafından üretilen ürünün tüketici tarafından kullanımı sonucu oluşan emisyonlardır.',
        type: TypeEnum.PRODUCT_USAGE,
        disabled: true,
    },

    {
        id: '17',
        title: 'Ürün Kullanım Ömrü Sonrası',
        description: 'Kurum tarafından üretilen ürünün kullanım ömrünü tamamlaması sonucu oluşan emisyonlardır.',
        type: TypeEnum.AFTER_PRODUCT_LIFETIME,
        disabled: false,
    },
    {
        id: '18',
        title: 'Diğer',
        description: 'Herhangi bir kategoride rapor edilemeyen ve kuruma özgü oluşan emisyonlardır.',
        type: TypeEnum.OTHERS,
        disabled: true,
    },
    {
        id: '19',
        title: 'LULUCF',
        description:
            'Arazi kullanımı, arazi kullanım değişikliği ve ormancılıktan kaynaklanan sera gazı emisyonları ve uzaklaştırmaları.',
        type: TypeEnum.LULUCF_EMISSION,
        disabled: false,
    },
    {
        id: '100',
        title: 'Varlık Kullanımı',
        description: '',
        type: TypeEnum.ASSET_USAGE,
        disabled: true,
    },
    {
        id: '101',
        title: 'Kiralanan Varlıklar',
        description: '',
        type: TypeEnum.LEASED_ASSETS,
        disabled: true,
    },
    {
        id: '102',
        title: 'Yatırım Emisyonları',
        description: '',
        type: TypeEnum.INVESTMENT_EMISSIONS,
        disabled: true,
    },
    {
        id: '20',
        title: 'Biyokütle Emisyonları',
        description: '',
        type: TypeEnum.BIOMASS_EMISSION,
        disabled: false,
    },
    {
        id: '21',
        title: 'Yukarı Yönlü Kiralanan Varlıklar',
        description: '',
        type: TypeEnum.UPSTREAM_LEASED_ASSETS,
        disabled: true,
    },
    {
        id: '22',
        title: 'Aşağı Yönlü Kiralanan Varlıklar',
        description: '',
        type: TypeEnum.DOWNSTREAM_LEASED_ASSETS,
        disabled: true,
    },
];
