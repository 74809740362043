import { ICompany, ICountry, ISector, IState } from '../../../entities/company/companyCombustion';
import { FACILITY } from './facility.action';

export const COMPANY = '[COMPANY]';

export const SELECT_COMPANY_DASHBOARD_YEAR = `${COMPANY} Select Dashboard Year`;

export const GET_COMPANY_IMAGE = `${COMPANY} Get Image`;
export const SET_COMPANY_IMAGE = `${COMPANY} Set Image`;
export const SELECT_COMPANY_IMAGE = `${COMPANY} Select Image`;

export const UPLOAD_COMPANY_IMAGE = `${COMPANY} Upload Company Image`;

export const GET_COMPANY_USER_ID = `${COMPANY} Get User ID`;
export const SET_COMPANY_USER_ID = `${COMPANY} Set User ID`;

export const GET_COMPANY_ID = `${COMPANY} Get Company ID`;
export const SET_COMPANY_ID = `${COMPANY} Set Company ID`;

export const GET_COMPANY_PROFILE_RESULT = `${COMPANY} Get Profile Result`;
export const SET_COMPANY_PROFILE_RESULT = `${COMPANY} Set Profile Result`;

export const GET_COMPANY_RESULT = `${COMPANY} Get Result Company`;
export const SET_COMPANY_RESULT = `${COMPANY} Set Result Company`;

export const CHANGE_COMPANY_NAME = `${COMPANY} Change Name`;
export const CHANGE_COMPANY_EMAIL = `${COMPANY} Change Email`;
export const CHANGE_COMPANY_WEBSITE = `${COMPANY} Change Website`;
export const CHANGE_COMPANY_PHONE = `${COMPANY} Change Phone`;

export const GET_COMPANY_COUNTRY = `${COMPANY} Get Country`;
export const SET_COMPANY_COUNTRY = `${COMPANY} Set Country`;
export const SELECT_COMPANY_COUNTRY = `${COMPANY} SELECT Country`;

export const GET_COMPANY_CITY = `${COMPANY} Get City`;
export const SET_COMPANY_CITY = `${COMPANY} Set City`;
export const SELECT_COMPANY_CITY = `${COMPANY} Select City`;

export const GET_COMPANY_SECTOR = `${COMPANY} Get Sector`;
export const SET_COMPANY_SECTOR = `${COMPANY} Set Sector`;
export const SELECT_COMPANY_SECTOR = `${COMPANY} Select Sector`;

export const CHANGE_COMPANY_ADDRESS = `${COMPANY} Change Address`;
export const CHANGE_COMPANY_DESCRIPTION = `${COMPANY} Change Description`;

export const SUBMIT_COMPANY_FORM = `${COMPANY} Submit Company Form`;

export const GET_COMPANY_DASHBOARD_DATA = `${COMPANY} Get Dashboard Data`;
export const SET_COMPANY_DASHBOARD_DATA = `${COMPANY} Set Dashboard Data`;

export const CHANGE_COMPANY_CREATE_NAME = `${COMPANY} Create Change CompanyName`;
export const CHANGE_COMPANY_CREATE_EMAIL = `${COMPANY} Create Change Email`;
export const CHANGE_COMPANY_CREATE_PHONE = `${COMPANY} Create Change Phone`;
export const CHANGE_COMPANY_CREATE_URL = `${COMPANY} Create Change Web Url`;
export const CHANGE_COMPANY_CREATE_ADDRESS = `${COMPANY} Create Change Address`;
export const CHANGE_COMPANY_CREATE_FACILITY_LIMIT = `${COMPANY} Create Change Facility Limit`;
export const SUBMIT_COMPANY_CREATE = `${COMPANY} Submit Create Company`;

export const GENERATE_COMPANY_REPORT = `${COMPANY} Generate Company Report`;
export const DELETE_COMPANY = `${COMPANY} Delete Company`;
export const UPDATE_COMPANY = `${COMPANY} Update Company`;
//

/// COMPANY
export type ISubmit = {
    onSuccess: () => void;
};

export const deleteCompany = (value: number) => ({
    type: DELETE_COMPANY,
    payload: value,
});

export const updateCompany = (value: number) => ({
    type: UPDATE_COMPANY,
    payload: value,
});

export const selectCompanyDashboardYear = (value: any) => ({
    type: SELECT_COMPANY_DASHBOARD_YEAR,
    payload: value,
});

export const getCompanyImage = () => ({
    type: GET_COMPANY_IMAGE,
});

export const setCompanyImage = (value: any) => ({
    type: SET_COMPANY_IMAGE,
    payload: value,
});

export const selectCompanyImage = (value: FormData) => ({
    type: SELECT_COMPANY_IMAGE,
    payload: value,
});

export const uploadCompanyImage = ({ onSuccess }: ISubmit, value: any) => ({
    type: UPLOAD_COMPANY_IMAGE,
    meta: {
        onSuccess,
    },
    payload: value,
});

export const getCompanyUserId = () => ({
    type: GET_COMPANY_USER_ID,
});

export const setCompanyUserId = (id: number) => ({
    type: SET_COMPANY_USER_ID,
    payload: id,
});

export const getCompanyId = () => ({
    type: GET_COMPANY_ID,
});

export const setCompanyId = (id: number) => ({
    type: SET_COMPANY_ID,
    payload: id,
});

export const getCompanyResult = (data?: any) => ({
    type: GET_COMPANY_RESULT,
    payload: data,
});

export const setCompanyResult = (company: ICompany) => ({
    type: SET_COMPANY_RESULT,
    payload: company,
});

export const getCompanyProfileResult = () => ({
    type: GET_COMPANY_PROFILE_RESULT,
});

export const setCompanyProfileResult = (company: ICompany) => ({
    type: SET_COMPANY_PROFILE_RESULT,
    payload: company,
});

export const changeCompanyName = (value: string) => ({
    type: CHANGE_COMPANY_NAME,
    payload: value,
});

export const changeCompanyEmail = (value: string) => ({
    type: CHANGE_COMPANY_EMAIL,
    payload: value,
});

export const changeCompanyWebsite = (value: string) => ({
    type: CHANGE_COMPANY_WEBSITE,
    payload: value,
});

export const changeCompanyPhone = (value: string) => ({
    type: CHANGE_COMPANY_PHONE,
    payload: value,
});

export const getCompanyCountry = () => ({
    type: GET_COMPANY_COUNTRY,
});

export const setCompanyCountry = (countries: Array<ICountry>) => ({
    type: SET_COMPANY_COUNTRY,
    payload: countries,
});

export const selectCompanyCountry = (country: ICountry) => ({
    type: SELECT_COMPANY_COUNTRY,
    payload: country,
});

export const getCompanyCity = () => ({
    type: GET_COMPANY_CITY,
});

export const setCompanyCity = (cities: Array<IState>) => ({
    type: SET_COMPANY_CITY,
    payload: cities,
});

export const selectCompanyCity = (city: IState) => ({
    type: SELECT_COMPANY_CITY,
    payload: city,
});

export const getCompanySector = () => ({
    type: GET_COMPANY_SECTOR,
});

export const setCompanySector = (sectors: Array<ISector>) => ({
    type: SET_COMPANY_SECTOR,
    payload: sectors,
});

export const selectCompanySector = (sector: ISector) => ({
    type: SELECT_COMPANY_SECTOR,
    payload: sector,
});

export const changeCompanyAddress = (value: string) => ({
    type: CHANGE_COMPANY_ADDRESS,
    payload: value,
});

export const changeCompanyDescription = (value: string) => ({
    type: CHANGE_COMPANY_DESCRIPTION,
    payload: value,
});

export const submitCompanyForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_COMPANY_FORM,
    meta: {
        onSuccess,
    },
});

export const getCompanyDashboardData = () => ({
    type: GET_COMPANY_DASHBOARD_DATA,
});

export const setCompanyDashboardData = (value: any) => ({
    type: SET_COMPANY_DASHBOARD_DATA,
    payload: value,
});

export const changeCompanyCreateName = (value: string) => ({
    type: CHANGE_COMPANY_CREATE_NAME,
    payload: value,
});
export const changeCompanyCreateEmail = (value: string) => ({
    type: CHANGE_COMPANY_CREATE_EMAIL,
    payload: value,
});
export const changeCompanyCreatePhone = (value: string) => ({
    type: CHANGE_COMPANY_CREATE_PHONE,
    payload: value,
});
export const changeCompanyCreateUrl = (value: string) => ({
    type: CHANGE_COMPANY_CREATE_URL,
    payload: value,
});
export const changeCompanyCreateAddress = (value: string) => ({
    type: CHANGE_COMPANY_CREATE_ADDRESS,
    payload: value,
});
export const changeCompanyCreateFacilityLimit = (value: string) => ({
    type: CHANGE_COMPANY_CREATE_FACILITY_LIMIT,
    payload: value,
});
export const submitCompanyCreate = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_COMPANY_CREATE,
    meta: {
        onSuccess,
    },
});
export const generateCompanyReport = () => ({
    type: GENERATE_COMPANY_REPORT,
});
/// END COMPANY
