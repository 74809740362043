import React from 'react';
import { Alert, Stack } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import { getNotifications } from '../../../redux/selectors/ui/ui.selector';
import NotificationElement from './NotificationElement';

const NotificationContainer = () => {
    const notifications = useAppSelector(getNotifications);

    return (
        <Stack
            sx={{ position: 'fixed', top: 18, left: '50%', transform: 'translateX(-50%)', zIndex: '1800' }}
            spacing={2}
        >
            {notifications.map((n) => {
                return (
                    <NotificationElement
                        key={n.key}
                        id={n.key}
                        title={n.title}
                        message={n.message}
                        time={n.time}
                        type={n.type}
                    />
                );
            })}
        </Stack>
    );
};

export default NotificationContainer;
