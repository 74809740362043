import { AnyAction } from 'redux';
import {
    CHANGE_KPI_ACTION_DESCRIPTION,
    CHANGE_KPI_ACTION_END_DATE,
    CHANGE_KPI_ACTION_PROGRESS,
    CHANGE_KPI_ACTION_TITLE,
    CLEAR_KPI_ACTION_INPUTS,
    GET_KPI_ACTION_LIST,
    SET_KPI_ACTION_BY_ID,
    SET_KPI_ACTION_LIST,
    SET_KPI_ACTION_PAGE,
} from '../../actions/kpi/action.action';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';

const initialState = {
    actionList: {
        data: [],
        isLoading: true,
    },
    title: '',
    description: '',
    endDate: '',
    progress: 0,
};

export const actionReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_KPI_ACTION_BY_ID:
            return {
                ...state,
                title: action.payload?.title,
                endDate: action.payload?.endDate,
                description: action.payload?.description,
                progress: action.payload?.progress,
            };
            return state;
        case SET_KPI_ACTION_PAGE:
            return state;
        case GET_KPI_ACTION_LIST:
            return {
                ...state,
                actionList: {
                    data: [],
                    isLoading: true,
                },
            };
        case SET_KPI_ACTION_LIST:
            return {
                ...state,
                actionList: {
                    data: action.payload,
                    isLoading: false,
                },
            };
        case CHANGE_KPI_ACTION_TITLE:
            return {
                ...state,
                title: action.payload,
            };
        case CHANGE_KPI_ACTION_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };
        case CHANGE_KPI_ACTION_END_DATE:
            return {
                ...state,
                endDate: action.payload,
            };
        case CHANGE_KPI_ACTION_PROGRESS:
            return {
                ...state,
                progress: action.payload,
            };
        case CLEAR_KPI_ACTION_INPUTS:
            return {
                ...state,
                title: '',
                description: '',
                endDate: '',
                progress: 0,
            };
        case SET_AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
};
