import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IEmployeeActivity,
    IEmployeeActivityType,
    IEmployeeDatabase,
    IEmployeeFuelType,
    IEmployeeResult,
    IEmployeeSize,
    IEmployeeUnit,
} from '../../../entities/emissionTypes/employeeCombustion';
import { EMISSION } from './base.action';

export const EMPLOYEE = 'EMPLOYEE';

////-- Base
export const CHANGE_EMPLOYEE_MAIN_FACILITY = `${EMISSION} ${EMPLOYEE} Change Main Facility`;
export const CHANGE_EMPLOYEE_SUB_FACILITY = `${EMISSION} ${EMPLOYEE} Change Sub Facility`;

export const GET_EMPLOYEE_TIERS = `${EMISSION} ${EMPLOYEE} Get Tiers`;
export const SET_EMPLOYEE_TIERS = `${EMISSION} ${EMPLOYEE} Set Tiers`;
export const SELECT_EMPLOYEE_TIER = `${EMISSION} ${EMPLOYEE} Select Tier`;

export const GET_EMPLOYEE_DATABASES = `${EMISSION} ${EMPLOYEE} Get Databases`;
export const SET_EMPLOYEE_DATABASES = `${EMISSION} ${EMPLOYEE} Set Databases`;
export const SELECT_EMPLOYEE_DATABASE = `${EMISSION} ${EMPLOYEE} Select Database`;

export const CHANGE_EMPLOYEE_EQUIVALENT_FACTOR = `${EMISSION} ${EMPLOYEE} Change Equivalent Factor`;

export const GET_EMPLOYEE_ACTIVITIES = `${EMISSION} ${EMPLOYEE} Get Activities`;
export const SET_EMPLOYEE_ACTIVITIES = `${EMISSION} ${EMPLOYEE} Set Activities`;
export const SELECT_EMPLOYEE_ACTIVITY = `${EMISSION} ${EMPLOYEE} Select Activity`;
/////--- End base

export const GET_EMPLOYEE_FUEL_TYPES = `${EMISSION} ${EMPLOYEE} Get Fuel Types`;
export const SET_EMPLOYEE_FUEL_TYPES = `${EMISSION} ${EMPLOYEE} Set Fuel Types`;
export const SELECT_EMPLOYEE_FUEL_TYPE = `${EMISSION} ${EMPLOYEE} Select Fuel Type`;

export const GET_EMPLOYEE_ACTIVITY_TYPES = `${EMISSION} ${EMPLOYEE} Get Activity Types`;
export const SET_EMPLOYEE_ACTIVITY_TYPES = `${EMISSION} ${EMPLOYEE} Set Activity Types`;
export const SELECT_EMPLOYEE_ACTIVITY_TYPE = `${EMISSION} ${EMPLOYEE} Select Activity Type`;

export const GET_EMPLOYEE_UNITS = `${EMISSION} ${EMPLOYEE} Get Units`;
export const SET_EMPLOYEE_UNITS = `${EMISSION} ${EMPLOYEE} Set Units`;
export const SELECT_EMPLOYEE_UNIT = `${EMISSION} ${EMPLOYEE} Select Unit`;

export const CHANGE_EMPLOYEE_WEIGHT = `${EMISSION} ${EMPLOYEE} Change Weight`;
export const CHANGE_EMPLOYEE_DISTANCE = `${EMISSION} ${EMPLOYEE} Change Distance`;

export const GET_EMPLOYEE_LADEN_PERCENTAGES = `${EMISSION} ${EMPLOYEE} Get Laden Percentages`;
export const SET_EMPLOYEE_LADEN_PERCENTAGES = `${EMISSION} ${EMPLOYEE} Set Laden Percentages`;
export const SELECT_EMPLOYEE_LADEN_PERCENTAGE = `${EMISSION} ${EMPLOYEE} Select Laden percentage`;

export const GET_EMPLOYEE_SIZES = `${EMISSION} ${EMPLOYEE} Get Sizes`;
export const SET_EMPLOYEE_SIZES = `${EMISSION} ${EMPLOYEE} Set Sizes`;
export const SELECT_EMPLOYEE_SIZE = `${EMISSION} ${EMPLOYEE} Select Size`;

////-- Base
export const CHANGE_EMPLOYEE_YEAR = `${EMISSION} ${EMPLOYEE} Change Year`;
export const SELECT_EMPLOYEE_ACTIVITY_DATA = `${EMISSION} ${EMPLOYEE} Select Activity Data`;
export const CHANGE_EMPLOYEE_CONSUMPTION_AMOUNT = `${EMISSION} ${EMPLOYEE} Change Consumption Amount`;
export const CHANGE_EMPLOYEE_DESCRIPTION = `${EMISSION} ${EMPLOYEE} Change Description`;

export const SUBMIT_EMPLOYEE_FORM = `${EMISSION} ${EMPLOYEE} Submit Form`;
export const SET_EMPLOYEE_RESULT = `${EMISSION} ${EMPLOYEE} Set Result`;
/////-- End Base
export const CLEAR_EMPLOYEE_CONSUMPTION_AMOUNT = `${EMISSION} ${EMPLOYEE} Clear Consumption Amount`;
export const CLEAR_EMPLOYEE_INPUT= `${EMISSION} ${EMPLOYEE} Clear Employee Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearEmployeeInput = (list: Array<string>) => ({
    type: CLEAR_EMPLOYEE_INPUT,
    payload: list,
});
/// Region Employee
export const changeEmployeeMainFacility = (text: string) => ({
    type: CHANGE_EMPLOYEE_MAIN_FACILITY,
    payload: text,
});

export const changeEmployeeSubFacility = (text: string) => {
    return {
        type: CHANGE_EMPLOYEE_SUB_FACILITY,
        payload: text,
    };
};

export const getEmployeeTiers = () => ({
    type: GET_EMPLOYEE_TIERS,
});

export const setEmployeeTiers = (list: Array<ITier>) => ({
    type: SET_EMPLOYEE_TIERS,
    payload: list,
});

export const selectEmployeeTier = (tier: ITier) => ({
    type: SELECT_EMPLOYEE_TIER,
    payload: tier,
});

export const getEmployeeDatabases = (tier: ITier) => ({
    type: GET_EMPLOYEE_DATABASES,
    payload: tier,
});

export const setEmployeeDatabases = (list: Array<IEmployeeDatabase>) => ({
    type: SET_EMPLOYEE_DATABASES,
    payload: list,
});

export const selectEmployeeDatabase = (database: IEmployeeDatabase) => ({
    type: SELECT_EMPLOYEE_DATABASE,
    payload: database,
});

export const getEmployeeActivities = (tier: ITier) => ({
    type: GET_EMPLOYEE_ACTIVITIES,
    payload: tier,
});

export const setEmployeeActivities = (activities: Array<IEmployeeActivity>) => ({
    type: SET_EMPLOYEE_ACTIVITIES,
    payload: activities,
});

export const selectEmployeeActivity = (activity: IEmployeeActivity) => ({
    type: SELECT_EMPLOYEE_ACTIVITY,
    payload: activity,
});

///////
export const getEmployeeFuelTypes = (tier: ITier) => ({
    type: GET_EMPLOYEE_FUEL_TYPES,
    payload: tier,
});

export const setEmployeeFuelTypes = (list: Array<IEmployeeFuelType>) => ({
    type: SET_EMPLOYEE_FUEL_TYPES,
    payload: list,
});

export const selectEmployeeFuelType = (database: IEmployeeFuelType) => ({
    type: SELECT_EMPLOYEE_FUEL_TYPE,
    payload: database,
});

export const getEmployeeActivityTypes = (value: any) => ({
    type: GET_EMPLOYEE_ACTIVITY_TYPES,
    payload: value,
});

export const setEmployeeActivityTypes = (activities: Array<IEmployeeActivityType>) => ({
    type: SET_EMPLOYEE_ACTIVITY_TYPES,
    payload: activities,
});

export const selectEmployeeActivityType = (activity: IEmployeeActivityType) => ({
    type: SELECT_EMPLOYEE_ACTIVITY_TYPE,
    payload: activity,
});

export const getEmployeeUnits = (units: IEmployeeActivityType) => ({
    type: GET_EMPLOYEE_UNITS,
    payload: units,
});

export const setEmployeeUnits = (units: Array<IEmployeeUnit>) => ({
    type: SET_EMPLOYEE_UNITS,
    payload: units,
});

export const selectEmployeeUnit = (unit: IEmployeeUnit) => ({
    type: SELECT_EMPLOYEE_UNIT,
    payload: unit,
});

export const changeEmployeeWeight = (value: number) => ({
    type: CHANGE_EMPLOYEE_WEIGHT,
    payload: value,
});

export const changeEmployeeDistance = (value: number) => ({
    type: CHANGE_EMPLOYEE_DISTANCE,
    payload: value,
});

export const getEmployeeLadenPercentages = (ladens: any) => ({
    type: GET_EMPLOYEE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setEmployeeLadenPercentages = (ladens: Array<any>) => ({
    type: SET_EMPLOYEE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectEmployeeLadenPercentage = (laden: any) => ({
    type: SELECT_EMPLOYEE_LADEN_PERCENTAGE,
    payload: laden,
});

export const getEmployeeSizes = (sizes: IEmployeeActivityType) => ({
    type: GET_EMPLOYEE_SIZES,
    payload: sizes,
});

export const setEmployeeSizes = (sizes: Array<IEmployeeSize>) => ({
    type: SET_EMPLOYEE_SIZES,
    payload: sizes,
});

export const selectEmployeeSize = (size: IEmployeeSize) => ({
    type: SELECT_EMPLOYEE_SIZE,
    payload: size,
});

///////
export const submitEmployeeForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_EMPLOYEE_FORM,
    meta: {
        onSuccess,
    },
});

export const setEmployeeResult = (data: Array<IEmployeeResult>) => ({
    type: SET_EMPLOYEE_RESULT,
    payload: data,
});

export const selectEmployeeActivityData = (activity: string) => ({
    type: SELECT_EMPLOYEE_ACTIVITY_DATA,
    payload: activity,
});

export const changeEmployeeYear = (value: number) => ({
    type: CHANGE_EMPLOYEE_YEAR,
    payload: value,
});

export const changeEmployeeDescription = (text: string) => ({
    type: CHANGE_EMPLOYEE_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_EMPLOYEE_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_EMPLOYEE_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_EMPLOYEE_CONSUMPTION_AMOUNT,
});
/// End Region Employee
