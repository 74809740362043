import {
    ICalculatedResult,
    IReportingCategory,
    IReportingEmission,
    IReportingFacility,
    IReportingInformation,
    IReportingResult,
    IReportingSubCategory,
} from '../../../entities/company/reportingCombustion';

export const REPORTING = '[REPORTING]';

export const GET_REPORTING_FACILITY = `${REPORTING} Get Facility`;
export const SET_REPORTING_FACILITY = `${REPORTING} Set Facility`;
export const SELECT_REPORTING_FACILITY = `${REPORTING} Select Facility`;
export const SELECT_REPORTING_FACILITY_EDIT = `${REPORTING} Select Facility Edit`;

export const GET_REPORTING_CATEGORY = `${REPORTING} Get Category`;
export const SET_REPORTING_CATEGORY = `${REPORTING} Set Category`;
export const SELECT_REPORTING_CATEGORY = `${REPORTING} Select Category`;
export const SELECT_REPORTING_CATEGORY_EDIT = `${REPORTING} Select Category Edit`;

export const GET_REPORTING_SUB_CATEGORY = `${REPORTING} Get Sub Category`;
export const SET_REPORTING_SUB_CATEGORY = `${REPORTING} Set Sub Category`;
export const SELECT_REPORTING_SUB_CATEGORY = `${REPORTING} Select Sub Category`;
export const SELECT_REPORTING_SUB_CATEGORY_EDIT = `${REPORTING} Select Sub Category Edit`;

export const GET_REPORTING_EMISSION = `${REPORTING} Get Emission`;
export const SET_REPORTING_EMISSION = `${REPORTING} Set Emission`;
export const SELECT_REPORTING_EMISSION = `${REPORTING} Select Emission`;
export const SELECT_REPORTING_EMISSION_EDIT = `${REPORTING} Select Emission Edit`;

export const CHANGE_REPORTING_SOURCE = `${REPORTING} Change Source`;
export const CHANGE_REPORTING_REASON = `${REPORTING} Change Reason`;
export const CHANGE_REPORTING_ACTIVITY_DATA = `${REPORTING} Change Activity Data`;

export const SUBMIT_REPORTING_INFORMATION = `${REPORTING} Submit Information`;
export const GET_REPORTING_INFORMATION = `${REPORTING} Get Information`;
export const SET_REPORTING_INFORMATION = `${REPORTING} Get Information`;
export const CHANGE_REPORTING_INFORMATION = `${REPORTING} Change Information`;

export const CHANGE_REPORTING_SOURCE_EDIT = `${REPORTING} Change Source Edit`;
export const CHANGE_REPORTING_REASON_EDIT = `${REPORTING} Change Reason Edit`;
export const CHANGE_REPORTING_ACTIVITY_DATA_EDIT = `${REPORTING} Change Activity Data Edit`;
export const CHANGE_REPORTING_INFORMATION_EDIT = `${REPORTING} Change Information Edit`;

export const GET_REPORTING_CALCULATED_RESULT = `${REPORTING} Get Calculated Result`;
export const SET_REPORTING_CALCULATED_RESULT = `${REPORTING} Set Calculated Result`;
export const GET_REPORTING_RESULT = `${REPORTING} Get Result`;
export const SET_REPORTING_RESULT = `${REPORTING} Set Result`;
export const UPDATE_REPORTING_FORM = `${REPORTING} Update Form`;
export const SUBMIT_REPORTING_FORM = `${REPORTING} Submit Form`;

export const DELETE_REPORTING = `${REPORTING} Delete Reporting`;

export type ISubmit = {
    onSuccess: () => void;
};

export const submitReportingForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_REPORTING_FORM,
    meta: {
        onSuccess,
    },
});

export const updateReportingForm = ({ onSuccess }: ISubmit, value: any) => ({
    type: UPDATE_REPORTING_FORM,
    payload: value,
    meta: {
        onSuccess,
    },
});

export const deleteReporting = ({ onSuccess }: ISubmit, value: number) => ({
    type: DELETE_REPORTING,
    payload: value,
    meta: {
        onSuccess,
    },
});

export const submitReportingInformation = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_REPORTING_INFORMATION,
    meta: {
        onSuccess,
    },
});

export const getReportingInformation = () => ({
    type: GET_REPORTING_INFORMATION,
});

export const setReportingInformation = (result: IReportingInformation) => ({
    type: SET_REPORTING_INFORMATION,
    payload: result,
});

export const getReportingResult = () => ({
    type: GET_REPORTING_RESULT,
});

export const setReportingResult = (result: IReportingResult) => ({
    type: SET_REPORTING_RESULT,
    payload: result,
});

export const getReportingCalculatedResult = () => ({
    type: GET_REPORTING_CALCULATED_RESULT,
});

export const setReportingCalculatedResult = (result: Array<ICalculatedResult>) => ({
    type: SET_REPORTING_CALCULATED_RESULT,
    payload: result,
});

export const getReportingFacility = () => ({
    type: GET_REPORTING_FACILITY,
});

export const setReportingFacility = (categories: Array<IReportingFacility>) => ({
    type: SET_REPORTING_FACILITY,
    payload: categories,
});

export const selectReportingFacility = (category: IReportingFacility) => ({
    type: SELECT_REPORTING_FACILITY,
    payload: category,
});

export const selectReportingFacilityEdit = (id: number, facility: IReportingFacility) => ({
    type: SELECT_REPORTING_FACILITY_EDIT,
    payload: { id, facility },
});

export const getReportingCategory = () => ({
    type: GET_REPORTING_CATEGORY,
});

export const setReportingCategory = (categories: Array<IReportingCategory>) => ({
    type: SET_REPORTING_CATEGORY,
    payload: categories,
});

export const selectReportingCategory = (category: IReportingCategory) => ({
    type: SELECT_REPORTING_CATEGORY,
    payload: category,
});

export const selectReportingCategoryEdit = (id: number, category: IReportingCategory) => ({
    type: SELECT_REPORTING_CATEGORY_EDIT,
    payload: { id, category },
});

export const getReportingSubCategory = (value: number) => ({
    type: GET_REPORTING_SUB_CATEGORY,
    payload: value,
});

export const setReportingSubCategory = (subCategories: Array<IReportingSubCategory>) => ({
    type: SET_REPORTING_SUB_CATEGORY,
    payload: subCategories,
});

export const selectReportingSubCategory = (subCategory: IReportingSubCategory) => ({
    type: SELECT_REPORTING_SUB_CATEGORY,
    payload: subCategory,
});

export const selectReportingSubCategoryEdit = (id: number, subCategory: IReportingSubCategory) => ({
    type: SELECT_REPORTING_SUB_CATEGORY_EDIT,
    payload: { id, subCategory },
});

export const getReportingEmission = () => ({
    type: GET_REPORTING_EMISSION,
});

export const setReportingEmission = (emissions: Array<IReportingEmission>) => ({
    type: SET_REPORTING_EMISSION,
    payload: emissions,
});

export const selectReportingEmission = (emission: IReportingEmission) => ({
    type: SELECT_REPORTING_EMISSION,
    payload: emission,
});

export const selectReportingEmissionEdit = (id: number, emission: IReportingEmission) => ({
    type: SELECT_REPORTING_EMISSION_EDIT,
    payload: { id, emission },
});

export const changeReportingSource = (value: string) => ({
    type: CHANGE_REPORTING_SOURCE,
    payload: value,
});

export const changeReportingReason = (value: string) => ({
    type: CHANGE_REPORTING_REASON,
    payload: value,
});

export const changeReportingActivityData = (value: string) => ({
    type: CHANGE_REPORTING_ACTIVITY_DATA,
    payload: value,
});

export const changeReportingInformation = (value: string) => ({
    type: CHANGE_REPORTING_INFORMATION,
    payload: value,
});

export const changeReportingSourceEdit = (id: number, value: string) => ({
    type: CHANGE_REPORTING_SOURCE_EDIT,
    payload: { id, value },
});

export const changeReportingReasonEdit = (id: number, value: string) => ({
    type: CHANGE_REPORTING_REASON_EDIT,
    payload: { id, value },
});

export const changeReportingActivityDataEdit = (id: number, value: string) => ({
    type: CHANGE_REPORTING_ACTIVITY_DATA_EDIT,
    payload: { id, value },
});

export const changeReportingInformationEdit = (id: number, value: string) => ({
    type: CHANGE_REPORTING_INFORMATION_EDIT,
    payload: { id, value },
});
