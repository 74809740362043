import { Middleware } from 'redux';
import { IActivity, IActivityType, ISource, ITier, IUnit } from '../../../../entities/emissionTypes/baseType';
import { IElectricAdditional, IElectricResult } from '../../../../entities/emissionTypes/electricCombustion';
import { IStaticDatabase } from '../../../../entities/emissionTypes/staticCombustion';

import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_ELECTRIC_ACTIVITIES,
    GET_ELECTRIC_ACTIVITY_TYPES,
    GET_ELECTRIC_ADDITIONAL_CONSTANTS,
    GET_ELECTRIC_DATABASES,
    GET_ELECTRIC_SOURCES,
    GET_ELECTRIC_TIERS,
    GET_ELECTRIC_UNITS,
    setElectricActivity,
    setElectricActivityType,
    setElectricAdditionalConstants,
    setElectricDatabases,
    setElectricResult,
    setElectricSources,
    setElectricTiers,
    setElectricUnits,
    SUBMIT_ELECTRIC_FORM,
} from '../../../actions/emission/electric.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const emissionElectricMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            // region ELECTRIC
            case GET_ELECTRIC_TIERS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/common/data/tiers',
                        feature: GET_ELECTRIC_TIERS,
                        query: {
                            emissionCategoryId: 5,
                        },
                    })
                );
                break;
            case `${GET_ELECTRIC_TIERS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<ITier>,
                        v: {
                            id: number;
                            language: string;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setElectricTiers(list));
                break;
            }

            case GET_ELECTRIC_DATABASES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/emissionFactorDatabases',
                        feature: GET_ELECTRIC_DATABASES,
                        query: {
                            tierId: action.payload.id,
                            emissionCategoryId: 5,
                        },
                    })
                );
                break;
            case `${GET_ELECTRIC_DATABASES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IStaticDatabase>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setElectricDatabases(list));
                break;
            }

            case GET_ELECTRIC_SOURCES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/electricityEmission/data/emissionSources',
                        feature: GET_ELECTRIC_SOURCES,
                        query: {
                            emissionFactorDatabaseId: +action.payload,
                        },
                    })
                );
                break;
            case `${GET_ELECTRIC_SOURCES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<ISource>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setElectricSources(list));
                break;
            }

            case GET_ELECTRIC_ACTIVITIES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/electricityEmission/data/emissionActivities',
                        feature: GET_ELECTRIC_ACTIVITIES,
                        query: {
                            emissionSourceId: action.payload?.id,
                        },
                    })
                );
                break;
            case `${GET_ELECTRIC_ACTIVITIES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IActivity>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setElectricActivity(list));
                break;
            }

            case GET_ELECTRIC_ACTIVITY_TYPES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/electricityEmission/data/emissionActivityTypes',
                        feature: GET_ELECTRIC_ACTIVITY_TYPES,
                        query: {
                            emissionActivityId: action.payload?.id,
                        },
                    })
                );
                break;
            case `${GET_ELECTRIC_ACTIVITY_TYPES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IActivityType>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setElectricActivityType(list));
                break;
            }

            case GET_ELECTRIC_UNITS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/electricityEmission/data/units',
                        feature: GET_ELECTRIC_UNITS,
                        query: {
                            emissionActivityTypeId: action.payload?.id,
                        },
                    })
                );
                break;
            case `${GET_ELECTRIC_UNITS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IUnit>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                            arg0: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            arg0: v.arg0,
                        });
                        return acc;
                    },
                    []
                );
                next(setElectricUnits(list));
                break;
            }

            case GET_ELECTRIC_ADDITIONAL_CONSTANTS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'electricityEmission/data/additionalConstants',
                        feature: GET_ELECTRIC_ADDITIONAL_CONSTANTS,
                        query: {
                            emissionUnitId: action.payload?.id,
                        },
                    })
                );
                break;

            case `${GET_ELECTRIC_ADDITIONAL_CONSTANTS} ${API_SUCCESS}`: {
                const list: Array<IElectricAdditional> = action.payload.emissionCalculationArgumentDtoSet;
                next(setElectricAdditionalConstants(list));
                break;
            }

            case SUBMIT_ELECTRIC_FORM: {
                interface IArgumentSet {
                    identifier: string;
                    value: number | null;
                    reference: string | null;
                }

                interface IBody {
                    organizationId?: number;
                    companyId: number;
                    facilityId: number;
                    consumptionList: Array<{ arg0: number; arg1: number; period: string }>;
                    emissionFactorDatabaseId: number;
                    tierId: number;
                    emissionCategoryId: number;
                    tags: string;
                    equivalentTags: string;
                    description: string;
                    equivalentFactor?: number;
                    activityData: string;
                    emissionSourceId?: number;
                    emissionActivityId: number;
                    emissionActivityTypeId: number;
                    unitId: number;
                }
                const body: IBody = {
                    organizationId:
                        state?.auth?.userInfo?.organization?.id ||
                        state?.auth?.userInfo?.company?.organizationId ||
                        //state?.auth?.userInfo?.user?.organizationId ||
                        null,
                    companyId: state?.emission?.base?.selectedCompany?.id,
                    facilityId: state?.emission?.base?.selectedFacility?.id,
                    consumptionList: [],
                    emissionFactorDatabaseId:
                        state.emission.electric.database?.id || state.emission.electric.databases[0]?.id,
                    tierId: state.emission.electric.tier?.id,
                    emissionCategoryId: 5,
                    emissionSourceId: state.emission.electric.source?.id,
                    emissionActivityId: state.emission.electric.activity?.id,
                    emissionActivityTypeId: state.emission.electric.activityType?.id,
                    unitId: state.emission.electric.unit?.id,
                    tags: state.emission.electric.tags || '',
                    equivalentTags: state.emission.base.equivalentTags || '',
                    description: state.emission.electric.description,
                    equivalentFactor: state.emission.electric.equivalentFactor || null,
                    activityData: state.emission.electric.activityData,
                };

                //const nkd =
                //    state.emission.electric.nkd &&
                //    ({
                //        identifier: 'NKD',
                //        value: +state.emission.electric.nkd.value || null,
                //        reference: state.emission.electric.nkd.reference || null,
                //    } as IArgumentSet);

                //const density =
                //    state.emission.electric.density &&
                //    ({
                //        identifier: 'DENSITY',
                //        value: +state.emission.electric.density.value || null,
                //        reference: state.emission.electric.density.reference,
                //    } as IArgumentSet);

                const equivalent =
                    state.emission.electric.equivalentFactorUnit &&
                    ({
                        identifier: 'CO2E',
                        value: +state.emission.electric.equivalentFactor || null,
                        reference: state.emission.base.equivalentTags || null,
                    } as IArgumentSet);

                let argSetArray: any[] | IArgumentSet[] = [];
                //typeof nkd.value === 'number' && argSetArray.push(nkd);
                //typeof density.value === 'number' && argSetArray.push(density);
                typeof equivalent.value === 'number' && argSetArray.push(equivalent);

                const consumptions = state.emission.electric.consumptions;
                const consumptionKeys: Array<string> = Object.keys(consumptions as { [key: number]: number | null });
                const consumptionData = consumptionKeys.reduce(
                    (
                        acc: Array<{
                            arg0: number;
                            arg1: number;
                            period: string;
                            emissionCalculationArgumentSet: Array<IArgumentSet> | [];
                        }>,
                        key: string
                    ) => {
                        const numberKey = Number(key);
                        if (consumptions[key]['km'] && numberKey !== 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['km']),
                                arg1: Number(consumptions[key]['tonne']),
                                period: `${state?.emission?.base?.calculationYear}-${numberKey + 1 < 10 ? '0' : ''}${
                                    numberKey + 1
                                }`,
                                emissionCalculationArgumentSet: argSetArray,
                            });
                        }
                        if (consumptions[key]['km'] && numberKey === 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['km']),
                                arg1: Number(consumptions[key]['tonne']),
                                period: state?.emission?.base?.calculationYear,
                                emissionCalculationArgumentSet: argSetArray,
                            });
                        }
                        return acc;
                    },
                    []
                );
                body.consumptionList = consumptionData;
                next(
                    apiRequest({
                        method: 'POST',
                        url: 'electricityEmission/calculate',
                        feature: SUBMIT_ELECTRIC_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_ELECTRIC_FORM} ${API_SUCCESS}`: {
                const data = action.payload.corporateEmissionDto.emissionList;
                const onSuccess = action.meta.onSuccess;
                const savedData: Array<IElectricResult> = [...data];
                next(setElectricResult(savedData));
                onSuccess();
                break;
            }

            case `${SUBMIT_ELECTRIC_FORM} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }
            // endregion ELECTRIC
        }
    };
