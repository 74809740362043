import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import { createNotification } from '../../../actions/ui.action';
import {
    clearKPIInputs,
    GET_KPI_BY_ID,
    GET_KPI_LIST,
    GET_KPI_PAGE,
    getKpiById,
    getKpiList,
    KPI_ASSIGN_USER,
    KPI_CREATE,
    KPI_DELETE,
    KPI_UNASSIGN_USER,
    KPI_UPDATE,
    kpiUpdate,
    setKpiById,
    setKpiList,
} from '../../../actions/kpi/kpi.action';
import i18next from 'i18next';

export const kpiMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case KPI_CREATE: {
                interface IBody {
                    kpiProjectId: number;
                    title: string;
                    description: string;
                    startDate: string;
                    endDate: string;
                    startingAmount: number;
                    goalAmount: number;
                }
                const body: IBody = {
                    kpiProjectId: state?.kpi?.project?.selectedProject?.data?.id,
                    title: state?.kpi?.kpi?.title,
                    description: state?.kpi?.kpi?.description,
                    startDate: state?.kpi?.kpi?.startDate,
                    endDate: state?.kpi?.kpi?.endDate,
                    startingAmount: state?.kpi?.kpi?.startingAmount,
                    goalAmount: state?.kpi?.kpi?.goalAmount,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/kpi',
                        feature: KPI_CREATE,
                        body,
                    })
                );
                break;
            }
            case `${KPI_CREATE} ${API_SUCCESS}`: {
                window.history.back();
                dispatch(clearKPIInputs());
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'success',
                        message: action.payload?.message || i18next.t('notification.successCreated'),
                    })
                );
                break;
            }
            case `${KPI_CREATE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_UPDATE: {
                interface IBody {
                    kpiId: number;
                    title: string;
                    description: string;
                    startDate: string;
                    endDate: string;
                    startingAmount: number;
                    goalAmount: number;
                }
                const body: IBody = {
                    kpiId: action.payload,
                    title: state?.kpi?.kpi?.title,
                    description: state?.kpi?.kpi?.description,
                    startDate: state?.kpi?.kpi?.startDate,
                    endDate: state?.kpi?.kpi?.endDate,
                    startingAmount: state?.kpi?.kpi?.startingAmount,
                    goalAmount: state?.kpi?.kpi?.goalAmount,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/kpi',
                        feature: KPI_UPDATE,
                        body,
                    })
                );
                break;
            }
            case `${KPI_UPDATE} ${API_SUCCESS}`: {
                const id = action.payload?.kpiDto?.id;
                dispatch(getKpiById(id));
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'success',
                        message: action.payload?.message || i18next.t('notification.successUpdated'),
                    })
                );
                break;
            }
            case `${KPI_UPDATE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_DELETE: {
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: '/kpi',
                        feature: KPI_DELETE,
                        query: {
                            id: action.payload,
                        },
                    })
                );
                break;
            }
            case `${KPI_DELETE} ${API_SUCCESS}`: {
                dispatch(getKpiList());
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'success',
                        message: action.payload?.message || i18next.t('notification.successDeleted'),
                    })
                );
                window.history.back();
                break;
            }
            case `${KPI_DELETE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_UNASSIGN_USER: {
                interface IQuery {
                    kpiId: number;
                    userId: number;
                }
                const query: IQuery = {
                    kpiId: -1,
                    userId: -1,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/kpi/unassignUser',
                        feature: KPI_UNASSIGN_USER,
                        query,
                    })
                );
                break;
            }
            case `${KPI_UNASSIGN_USER} ${API_SUCCESS}`: {
                break;
            }
            case `${KPI_UNASSIGN_USER} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_ASSIGN_USER: {
                interface IQuery {
                    kpiId: number;
                    userId: number;
                }
                const query: IQuery = {
                    kpiId: -1,
                    userId: -1,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/kpi/assignUser',
                        feature: KPI_ASSIGN_USER,
                        query,
                    })
                );
                break;
            }
            case `${KPI_ASSIGN_USER} ${API_SUCCESS}`: {
                break;
            }
            case `${KPI_ASSIGN_USER} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_BY_ID: {
                const id = action.payload;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/kpi/${id}`,
                        feature: GET_KPI_BY_ID,
                    })
                );
                break;
            }
            case `${GET_KPI_BY_ID} ${API_SUCCESS}`: {
                const data = action.payload?.kpiDto;
                dispatch(setKpiById(data));
                break;
            }
            case `${GET_KPI_BY_ID} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_PAGE: {
                interface IQuery {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                    page: number;
                    size: number;
                    sort: string;
                }
                const query: IQuery = {
                    organizationId: -1,
                    companyId: -1,
                    facilityId: -1,
                    page: 0,
                    size: 50,
                    sort: 'createdAt,desc',
                };
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/kpi/page',
                        feature: GET_KPI_PAGE,
                        query,
                    })
                );
                break;
            }
            case `${GET_KPI_PAGE} ${API_SUCCESS}`: {
                break;
            }
            case `${GET_KPI_PAGE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_LIST: {
                const selectedProject = state?.kpi?.project?.selectedProject?.data;
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                }
                const body: IBody = {
                    organizationId: selectedProject?.organizationId,
                    companyId: selectedProject?.companyId,
                    facilityId: selectedProject?.facilityId,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: `/kpi/list?projectId=${selectedProject?.id}`,
                        feature: GET_KPI_LIST,
                        body,
                    })
                );
                break;
            }
            case `${GET_KPI_LIST} ${API_SUCCESS}`: {
                const data = action.payload?.kpiDtoList;
                dispatch(setKpiList(data));
                break;
            }
            case `${GET_KPI_LIST} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }
        }
    };
