import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IFugitiveActivity,
    IFugitiveActivityType,
    IFugitiveDatabase,
    IFugitiveFuelType,
    IFugitiveGasGroup,
    IFugitiveResult,
    IFugitiveSize,
    IFugitiveUnit,
} from '../../../entities/emissionTypes/fugitiveCombustion';
import { EMISSION } from './base.action';

export const FUGITIVE = 'FUGITIVE';

////-- Base
export const CHANGE_FUGITIVE_MAIN_FACILITY = `${EMISSION} ${FUGITIVE} Change Main Facility`;
export const CHANGE_FUGITIVE_SUB_FACILITY = `${EMISSION} ${FUGITIVE} Change Sub Facility`;

export const GET_FUGITIVE_TIERS = `${EMISSION} ${FUGITIVE} Get Tiers`;
export const SET_FUGITIVE_TIERS = `${EMISSION} ${FUGITIVE} Set Tiers`;
export const SELECT_FUGITIVE_TIER = `${EMISSION} ${FUGITIVE} Select Tier`;

export const GET_FUGITIVE_DATABASES = `${EMISSION} ${FUGITIVE} Get Databases`;
export const SET_FUGITIVE_DATABASES = `${EMISSION} ${FUGITIVE} Set Databases`;
export const SELECT_FUGITIVE_DATABASE = `${EMISSION} ${FUGITIVE} Select Database`;

export const CHANGE_FUGITIVE_EQUIVALENT_FACTOR = `${EMISSION} ${FUGITIVE} Change Equivalent Factor`;

export const GET_FUGITIVE_ACTIVITIES = `${EMISSION} ${FUGITIVE} Get Activities`;
export const SET_FUGITIVE_ACTIVITIES = `${EMISSION} ${FUGITIVE} Set Activities`;
export const SELECT_FUGITIVE_ACTIVITY = `${EMISSION} ${FUGITIVE} Select Activity`;
/////--- End base

export const GET_FUGITIVE_FUEL_TYPES = `${EMISSION} ${FUGITIVE} Get Fuel Types`;
export const SET_FUGITIVE_FUEL_TYPES = `${EMISSION} ${FUGITIVE} Set Fuel Types`;
export const SELECT_FUGITIVE_FUEL_TYPE = `${EMISSION} ${FUGITIVE} Select Fuel Type`;

export const GET_FUGITIVE_ACTIVITY_TYPES = `${EMISSION} ${FUGITIVE} Get Activity Types`;
export const SET_FUGITIVE_ACTIVITY_TYPES = `${EMISSION} ${FUGITIVE} Set Activity Types`;
export const SELECT_FUGITIVE_ACTIVITY_TYPE = `${EMISSION} ${FUGITIVE} Select Activity Type`;

export const SET_FUGITIVE_GAS_GROUP = `${EMISSION} ${FUGITIVE} Set Gas Group`;
export const SELECT_FUGITIVE_GAS_GROUP = `${EMISSION} ${FUGITIVE} Select Gas Group`;

export const GET_FUGITIVE_UNITS = `${EMISSION} ${FUGITIVE} Get Units`;
export const SET_FUGITIVE_UNITS = `${EMISSION} ${FUGITIVE} Set Units`;
export const SELECT_FUGITIVE_UNIT = `${EMISSION} ${FUGITIVE} Select Unit`;

export const CHANGE_FUGITIVE_WEIGHT = `${EMISSION} ${FUGITIVE} Change Weight`;
export const CHANGE_FUGITIVE_DISTANCE = `${EMISSION} ${FUGITIVE} Change Distance`;

export const GET_FUGITIVE_LADEN_PERCENTAGES = `${EMISSION} ${FUGITIVE} Get Laden Percentages`;
export const SET_FUGITIVE_LADEN_PERCENTAGES = `${EMISSION} ${FUGITIVE} Set Laden Percentages`;
export const SELECT_FUGITIVE_LADEN_PERCENTAGE = `${EMISSION} ${FUGITIVE} Select Laden percentage`;

export const GET_FUGITIVE_SIZES = `${EMISSION} ${FUGITIVE} Get Sizes`;
export const SET_FUGITIVE_SIZES = `${EMISSION} ${FUGITIVE} Set Sizes`;
export const SELECT_FUGITIVE_SIZE = `${EMISSION} ${FUGITIVE} Select Size`;

////-- Base
export const CHANGE_FUGITIVE_TAGS = `${EMISSION} ${FUGITIVE} Change Tags`;
export const CHANGE_FUGITIVE_YEAR = `${EMISSION} ${FUGITIVE} Change Year`;
export const SELECT_FUGITIVE_ACTIVITY_DATA = `${EMISSION} ${FUGITIVE} Select Activity Data`;
export const CHANGE_FUGITIVE_CONSUMPTION_AMOUNT = `${EMISSION} ${FUGITIVE} Change Consumption Amount`;
export const CHANGE_FUGITIVE_DESCRIPTION = `${EMISSION} ${FUGITIVE} Change Description`;

export const SUBMIT_FUGITIVE_FORM = `${EMISSION} ${FUGITIVE} Submit Form`;
export const SET_FUGITIVE_RESULT = `${EMISSION} ${FUGITIVE} Set Result`;
/////-- End Base
export const CLEAR_FUGITIVE_CONSUMPTION_AMOUNT = `${EMISSION} ${FUGITIVE} Clear Consumption Amount`;
export const CLEAR_FUGITIVE_INPUT = `${EMISSION} ${FUGITIVE} Clear Fugitive Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearFugitiveInput = (list: Array<string>) => ({
    type: CLEAR_FUGITIVE_INPUT,
    payload: list,
});
/// Region Fugitive
export const changeFugitiveMainFacility = (text: string) => ({
    type: CHANGE_FUGITIVE_MAIN_FACILITY,
    payload: text,
});

export const changeFugitiveSubFacility = (text: string) => {
    return {
        type: CHANGE_FUGITIVE_SUB_FACILITY,
        payload: text,
    };
};

export const getFugitiveTiers = () => ({
    type: GET_FUGITIVE_TIERS,
});

export const setFugitiveTiers = (list: Array<ITier>) => ({
    type: SET_FUGITIVE_TIERS,
    payload: list,
});

export const selectFugitiveTier = (tier: ITier) => ({
    type: SELECT_FUGITIVE_TIER,
    payload: tier,
});

export const getFugitiveDatabases = (tier: ITier) => ({
    type: GET_FUGITIVE_DATABASES,
    payload: tier,
});

export const setFugitiveDatabases = (list: Array<IFugitiveDatabase>) => ({
    type: SET_FUGITIVE_DATABASES,
    payload: list,
});

export const selectFugitiveDatabase = (database: IFugitiveDatabase) => ({
    type: SELECT_FUGITIVE_DATABASE,
    payload: database,
});

export const getFugitiveActivities = (tier: ITier) => ({
    type: GET_FUGITIVE_ACTIVITIES,
    payload: tier,
});

export const setFugitiveActivities = (activities: Array<IFugitiveActivity>) => ({
    type: SET_FUGITIVE_ACTIVITIES,
    payload: activities,
});

export const selectFugitiveActivity = (activity: IFugitiveActivity) => ({
    type: SELECT_FUGITIVE_ACTIVITY,
    payload: activity,
});

///////
export const getFugitiveFuelTypes = (tier: ITier) => ({
    type: GET_FUGITIVE_FUEL_TYPES,
    payload: tier,
});

export const setFugitiveFuelTypes = (list: Array<IFugitiveFuelType>) => ({
    type: SET_FUGITIVE_FUEL_TYPES,
    payload: list,
});

export const selectFugitiveFuelType = (database: IFugitiveFuelType) => ({
    type: SELECT_FUGITIVE_FUEL_TYPE,
    payload: database,
});

export const getFugitiveActivityTypes = (value: any) => ({
    type: GET_FUGITIVE_ACTIVITY_TYPES,
    payload: value,
});

export const setFugitiveActivityTypes = (activities: Array<IFugitiveActivityType>) => ({
    type: SET_FUGITIVE_ACTIVITY_TYPES,
    payload: activities,
});

export const selectFugitiveActivityType = (activity: IFugitiveActivityType) => ({
    type: SELECT_FUGITIVE_ACTIVITY_TYPE,
    payload: activity,
});

export const setFugitiveGasGroup = (groups: Array<IFugitiveGasGroup>) => ({
    type: SET_FUGITIVE_GAS_GROUP,
    payload: groups,
});

export const selectFugitiveGasGroup = (group: IFugitiveGasGroup) => ({
    type: SELECT_FUGITIVE_GAS_GROUP,
    payload: group,
});

export const getFugitiveUnits = (units: IFugitiveActivityType) => ({
    type: GET_FUGITIVE_UNITS,
    payload: units,
});

export const setFugitiveUnits = (units: Array<IFugitiveUnit>) => ({
    type: SET_FUGITIVE_UNITS,
    payload: units,
});

export const selectFugitiveUnit = (unit: IFugitiveUnit) => ({
    type: SELECT_FUGITIVE_UNIT,
    payload: unit,
});

export const changeFugitiveWeight = (value: number) => ({
    type: CHANGE_FUGITIVE_WEIGHT,
    payload: value,
});

export const changeFugitiveTags = (value: string) => ({
    type: CHANGE_FUGITIVE_TAGS,
    payload: value,
});

export const changeFugitiveDistance = (value: number) => ({
    type: CHANGE_FUGITIVE_DISTANCE,
    payload: value,
});

export const getFugitiveLadenPercentages = (ladens: any) => ({
    type: GET_FUGITIVE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setFugitiveLadenPercentages = (ladens: Array<any>) => ({
    type: SET_FUGITIVE_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectFugitiveLadenPercentage = (laden: any) => ({
    type: SELECT_FUGITIVE_LADEN_PERCENTAGE,
    payload: laden,
});

export const getFugitiveSizes = (sizes: IFugitiveActivityType) => ({
    type: GET_FUGITIVE_SIZES,
    payload: sizes,
});

export const setFugitiveSizes = (sizes: Array<IFugitiveSize>) => ({
    type: SET_FUGITIVE_SIZES,
    payload: sizes,
});

export const selectFugitiveSize = (size: IFugitiveSize) => ({
    type: SELECT_FUGITIVE_SIZE,
    payload: size,
});

///////
export const submitFugitiveForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_FUGITIVE_FORM,
    meta: {
        onSuccess,
    },
});

export const setFugitiveResult = (data: Array<IFugitiveResult>) => ({
    type: SET_FUGITIVE_RESULT,
    payload: data,
});

export const selectFugitiveActivityData = (activity: string) => ({
    type: SELECT_FUGITIVE_ACTIVITY_DATA,
    payload: activity,
});

export const changeFugitiveYear = (value: number) => ({
    type: CHANGE_FUGITIVE_YEAR,
    payload: value,
});

export const changeFugitiveDescription = (text: string) => ({
    type: CHANGE_FUGITIVE_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_FUGITIVE_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_FUGITIVE_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_FUGITIVE_CONSUMPTION_AMOUNT,
});
/// End Region Fugitive
