import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

type ILayoutHeaderProps = {
    children: React.ReactNode;
    header?: React.ReactNode;
    fullWidth?: boolean;
};

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    paddingBottom: '100px',
}));

const LayoutHeader: FC<ILayoutHeaderProps> = ({ children, header, fullWidth }) => {
    return (
        <Wrapper>
            {header}
            <Container maxWidth={fullWidth ? 'xl' : 'md'} sx={{ paddingTop: '120px' }}>
                {children}
            </Container>
        </Wrapper>
    );
};

export default LayoutHeader;
