import { FC, useState, useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { Link, useLocation } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import * as React from 'react';
import List from '@mui/material/List';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface ISideBarListItemProps {
    type: string;
    title: string;
    path: string;
    icon: (prop: any) => any;
    isSidebarOpened: boolean;
    onClick?: () => void;
    items?: Array<{ title: string; path: string; icon: (prop: any) => any }>;
}
const SideBarListItem: FC<ISideBarListItemProps> = ({
    type,
    title,
    path,
    icon,
    isSidebarOpened,
    onClick,
    items = [],
}) => {
    const location = useLocation();
    if (type === 'link')
        return (
            <ListItem sx={{ display: 'block', py: 0 }} disablePadding={!isSidebarOpened}>
                <SideBarListItemButton
                    title={title}
                    path={path}
                    icon={icon}
                    isSidebarOpened={isSidebarOpened}
                    isActive={location.pathname === path}
                    onClick={onClick}
                />
            </ListItem>
        );
    if (type === 'collapse')
        return (
            <ListItem sx={{ display: 'block', py: 0 }} disablePadding={!isSidebarOpened}>
                <SideBarCollapseItem
                    title={title}
                    path={path}
                    icon={icon}
                    isSidebarOpened={isSidebarOpened}
                    items={items}
                />
            </ListItem>
        );
    return null;
};

interface ISideBarCollapseItemProps {
    title: string;
    path: string;
    icon: (prop: any) => any;
    isSidebarOpened: boolean;
    items: Array<{ title: string; path: string; icon: (prop: any) => any; isShow?: boolean }>;
}

const SideBarCollapseItem: FC<ISideBarCollapseItemProps> = ({ title, path, icon, isSidebarOpened, items = [] }) => {
    const location = useLocation();
    const isCurrentLocation = !!items.find((i) => i.path === location.pathname);
    const [isCollapseOpened, toggleCollapse] = useState(isCurrentLocation && isSidebarOpened);

    useEffect(() => {
        if (!isSidebarOpened) {
            toggleCollapse(false);
        } else {
            toggleCollapse(isCurrentLocation);
        }
    }, [isSidebarOpened]);

    return (
        <>
            <SideBarListItemButton
                key={title}
                title={title}
                icon={icon}
                isSidebarOpened={isSidebarOpened}
                onClick={() => toggleCollapse((prevState) => !prevState)}
                isActive={isCurrentLocation && !isSidebarOpened}
                suffixIcon={
                    isCollapseOpened ? (
                        <ExpandLess sx={{ color: 'text.secondary', display: isSidebarOpened ? 'block' : 'none' }} />
                    ) : (
                        <ExpandMore sx={{ color: 'text.secondary', display: isSidebarOpened ? 'block' : 'none' }} />
                    )
                }
            />
            <Collapse in={isCollapseOpened} timeout="auto" unmountOnExit onMouseOver={() => toggleCollapse(true)}>
                <List sx={{ margin: 0 }} disablePadding>
                    {items.map((item) => {
                        return (
                            <>
                                {item.isShow && (
                                    <ListItem key={item.title} sx={{ display: 'block', py: 0 }} disablePadding={true}>
                                        <SideBarListItemButton
                                            title={item.title}
                                            path={item.path}
                                            icon={item.icon}
                                            isActive={location.pathname === item.path}
                                            isSidebarOpened={isSidebarOpened}
                                        />
                                    </ListItem>
                                )}
                            </>
                        );
                    })}
                </List>
            </Collapse>
        </>
    );
};

interface ISideBarListItemButton {
    title: string;
    path?: string;
    icon?: (prop: any) => any;
    isSidebarOpened: boolean;
    onClick?: () => void;
    suffixIcon?: any;
    isActive: boolean;
}
const SideBarListItemButton: FC<ISideBarListItemButton> = ({
    title,
    path = '',
    icon,
    isSidebarOpened,
    onClick = () => {},
    suffixIcon,
    isActive,
}) => {
    return (
        <ListItemButton
            sx={{
                minHeight: 48,
                justifyContent: isSidebarOpened ? 'initial' : 'center',
                color: isActive ? 'success.contrastText' : 'text.primary',
                backgroundColor: isActive ? 'success.main' : '',
                borderRadius: isSidebarOpened ? '9px' : '0px',
                '&:hover': {
                    color: isActive ? 'success.contrastText' : 'text.primary',
                    backgroundColor: isActive ? 'success.main' : '',
                },
            }}
            component={path ? Link : 'div'}
            to={path}
            onClick={onClick}
        >
            {icon && (
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: isSidebarOpened ? 3 : '0',
                        justifyContent: 'center',
                    }}
                >
                    {icon(isActive ? { sx: { color: 'success.contrastText' } } : { sx: { color: 'text.secondary' } })}
                </ListItemIcon>
            )}

            <ListItemText
                primary={title}
                sx={{
                    display: isSidebarOpened ? 'block' : 'none',
                    width: '30px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    marginLeft: icon ? 0 : 4,
                }}
            />
            {suffixIcon}
        </ListItemButton>
    );
};

export default SideBarListItem;
