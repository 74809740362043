export enum PermissionTypes {
    EMISSION = 'EMISSION',
    ORGANIZATION = 'ORGANIZATION',
    COMPANY = 'COMPANY',
    FACILITY = 'FACILITY',
    ORGANIZATION_DASHBOARD = 'ORGANIZATION_DASHBOARD',
    COMPANY_DASHBOARD = 'COMPANY_DASHBOARD',
    FACILITY_DASHBOARD = 'FACILITY_DASHBOARD',
    ORGANIZATION_ANALYTICS = 'ORGANIZATION_ANALYTICS',
    COMPANY_ANALYTICS = 'COMPANY_ANALYTICS',
    FACILITY_ANALYTICS = 'FACILITY_ANALYTICS',
    ORGANIZATION_PROFILE = 'ORGANIZATION_PROFILE',
    COMPANY_PROFILE = 'COMPANY_PROFILE',
    FACILITY_PROFILE = 'FACILITY_PROFILE',
    REPORT = 'REPORT',
    REPORT_BOUNDARY = 'REPORT_BOUNDARY',
    QUANTIFIED_EMISSION = 'QUANTIFIED_EMISSION',
    INTERNAL_PERFORMANCE = 'INTERNAL_PERFORMANCE',
    APPENDIX = 'APPENDIX',
    ORGANIZATIONAL_BOUNDARY = 'ORGANIZATIONAL_BOUNDARY',
    INVENTORY_LIST = 'INVENTORY_LIST',
    INVENTORY_FORM = 'INVENTORY_FORM',
    GUIDE = 'GUIDE',
    SYSTEM = 'SYSTEM',
    KPI_DASHBOARD = 'KPI_DASHBOARD',
    GENERAL = 'GENERAL',
}
