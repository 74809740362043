import { ThemeProvider } from '@mui/material/styles';
import React, { FC } from 'react';
import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import { useAppSelector } from '../../redux/hooks';
import { getThemeMode } from '../../redux/selectors/ui/ui.selector';
import { PaletteMode } from '@mui/material';

type ILayoutThemeProps = {
    children: React.ReactNode;
};

const LayoutTheme: FC<ILayoutThemeProps> = ({ children }) => {
    const mode = useAppSelector(getThemeMode) as PaletteMode;
    const shadowArray = [
        'none',
        'rgb(113 122 131 / 11%) 0px 7px 30px 0px',
        'rgb(90 114 123 / 11%) 0px 7px 30px 0px',
        mode === 'light' ? '0px 7px 30px 0px rgba(35, 38, 47, 0.1)' : '5px 5px 10px 0px #0a0a0a',
        ...Array(21).fill('rgb(90 114 123 / 11%) 0px 7px 30px 0px'),
    ] as Shadows;

    const baseTheme = createTheme({
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                      // LIGHT MODE
                      primary: {
                          main: '#03c9d7',
                          contrastText: '#ffffff',
                      },
                      secondary: {
                          main: '#fb9678',
                          contrastText: '#ffffff',
                      },
                      background: {
                          default: '#F9F9F9',
                          paper: '#ffffff',
                      },
                      text: {
                          primary: 'rgba(0, 0, 0, 0.87)',
                          secondary: 'rgb(119, 126, 137)',
                          disabled: 'grey',
                      },
                      error: {
                          main: '#e46a76',
                      },
                      warning: {
                          main: '#fec90f',
                          contrastText: '#ffffff',
                      },
                      success: {
                          main: '#00C292',
                          contrastText: '#ffffff',
                      },
                      common: {
                          black: '#F9F9F9',
                          white: 'rgb(240,240,240)',
                      },
                  }
                : {
                      // DARK MODE
                      primary: {
                          main: '#03c9d7',
                          contrastText: 'rgba(240,240,240, 0.87)',
                      },
                      secondary: {
                          main: '#fb9678',
                          contrastText: 'rgba(240,240,240, 0.87)',
                      },
                      background: {
                          default: '#212121',
                          paper: '#181818', // #0c0c0c
                      },
                      text: {
                          primary: 'rgba(240,240,240, 0.87)',
                          secondary: 'rgb(180, 180, 180)',
                          disabled: 'grey',
                      },
                      error: {
                          main: '#e46a76',
                      },
                      warning: {
                          main: '#fec90f',
                          contrastText: 'rgba(240,240,240, 0.87)',
                      },
                      success: {
                          main: '#00C292',
                          contrastText: 'rgba(240,240,240, 0.87)',
                      },
                      common: {
                          black: '#2d2d2d',
                          white: 'rgb(60,60,60)',
                      },
                  }),
        },
        typography: {
            fontFamily: "'DM Sans', sans-serif",
            h1: {
                fontSize: 30,
                lineHeight: 1.5,
                fontWeight: 500,
                letterSpacing: '0em',
            },
            h2: {
                fontSize: 24,
                lineHeight: 1.5,
                fontWeight: 500,
                letterSpacing: '0em',
            },
            h3: {
                fontSize: 21,
                fontWeight: 500,
                lineHeight: 1.5,
            },
            h4: {
                fontSize: 18,
                fontWeight: 500,
                lineHeight: 1.5,
                letterSpacing: '0em',
            },
            h5: {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.5,
            },
            h6: {
                fontSize: 14,
                lineHeight: 1.5,
                letterSpacing: '0em',
            },
            subtitle1: {
                fontSize: 16,
                fontWeight: 400,
                lineHeight: 1.75,
                letterSpacing: '0em',
            },
            subtitle2: {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1.5,
                letterSpacing: '0em',
            },
            body1: {
                fontSize: 16,
                fontWeight: 400,
                letterSpacing: '0em',
            },
            body2: {
                fontSize: 14,
                letterSpacing: '0em',
            },
            caption: {
                fontSize: 12,
                letterSpacing: '0em',
                lineHeight: 1.58,
            },
            overline: {
                fontSize: 12,
                lineHeight: 2.6,
                letterSpacing: '0em',
            },
            button: {
                fontSize: 15,
                fontWeight: 400,
                lineHeight: 1.75,
                letterSpacing: '0em',
                textTransform: 'none',
            },
        },
        shape: {
            borderRadius: 5,
        },
        shadows: shadowArray,
    });

    const theme = createTheme(
        {
            components: {
                //
                MuiCssBaseline: {
                    styleOverrides: {
                        //body: mode === 'dark' && darkScrollbar(),
                        '*::-webkit-scrollbar': {
                            width: '0.3em',
                        },
                        '*::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': `inset 0 0 6px #b5b5b5`,
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: baseTheme.palette.mode === 'light' ? '#b5b5b5' : '#959595',
                            outline: '1px solid a5a5a5',
                        },
                    },
                },
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                    },
                },
                MuiLink: {
                    defaultProps: {
                        underline: 'none',
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            //color: baseTheme.palette.text.secondary,
                            //backgroundColor: baseTheme.palette.primary.contrastText,
                            backgroundColor: baseTheme.palette.background.paper,
                            //borderColor: 'rgb(224, 224, 224)',
                            ...baseTheme.typography.h5,
                        },
                    },
                },
                MuiInput: {
                    styleOverrides: {
                        root: {},
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            '&.Mui-disabled': {
                                backgroundColor:
                                    baseTheme.palette.mode === 'light' ? 'rgba(230,230,230,0.4)' : 'rgba(30,30,30,0.6)',
                                cursor: 'not-allowed',
                            },
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& input:disabled': {
                                borderColor: 'white',
                                cursor: 'not-allowed',
                            },
                            '& label': {
                                color: 'grey',
                            },
                            '& label.Mui-focused': {
                                color: baseTheme.palette.mode === 'light' ? 'rgb(120,120,120)' : 'rgb(180,180,180)',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor:
                                    baseTheme.palette.mode === 'light' ? 'rgb(120,120,120)' : 'rgb(180,180,180)',
                            },
                            '& fieldset.MuiOutlinedInput-notchedOutline': {
                                borderColor: 'none',
                            },
                            '-webkit-text-fill-color': {
                                color: 'red',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor:
                                        baseTheme.palette.mode === 'light' ? 'rgb(180,180,180)' : 'rgb(80,80,80)',
                                },
                                '&:hover fieldset': {
                                    borderColor:
                                        baseTheme.palette.mode === 'light' ? 'rgb(120,120,120)' : 'rgb(120,120,120)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor:
                                        baseTheme.palette.mode === 'light' ? 'rgb(120,120,120)' : 'rgb(120,120,120)',
                                },
                            },
                        },
                    },
                },
                MuiDialogTitle: {
                    styleOverrides: {
                        root: {
                            background: baseTheme.palette.common.black,
                        },
                    },
                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: {
                            background: baseTheme.palette.common.black,
                        },
                    },
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            background: baseTheme.palette.common.black,
                        },
                    },
                },
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            fontSize: '12px',
                            color: '#f5f5f5',
                            backgroundColor: '#5a5a5a',
                        },
                    },
                },
            },
        },
        baseTheme
    );
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default LayoutTheme;
