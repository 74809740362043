import { combineReducers } from 'redux';
import { projectReducer } from './project.reducer';
import { kpiReducer } from './kpi.reducer';
import { actionReducer } from './action.reducer';

const kpiReducers = combineReducers({
    project: projectReducer,
    kpi: kpiReducer,
    action: actionReducer,
});

export { kpiReducers };
