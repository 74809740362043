import { IInternal, IInternalAnalytic } from '../../../entities/company/internalCombustion';

export const INTERNAL = '[INTERNAL]';

export const GET_INTERNAL_RESULT = `${INTERNAL} Get Internal Result`;
export const SET_INTERNAL_RESULT = `${INTERNAL} Set Internal Result`;

export const GET_INTERNAL_ANALYTIC = `${INTERNAL} Get Internal Analytic`;
export const SET_INTERNAL_ANALYTIC = `${INTERNAL} Set Internal Analytic`;

export const CHANGE_INTERNAL_PERFORMANCE_REPORT = `${INTERNAL} Change Internal Performance Result`;
export const SUBMIT_INTERNAL_FORM = `${INTERNAL} Submit Internal Form`;

export type ISubmit = {
    onSuccess: () => void;
};

export const submitInternalForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_INTERNAL_FORM,
    meta: { onSuccess },
});

export const getInternalResult = () => ({
    type: GET_INTERNAL_RESULT,
});

export const setInternalResult = (result: IInternal) => ({
    type: SET_INTERNAL_RESULT,
    payload: result,
});

export const getInternalAnalytic = () => ({
    type: GET_INTERNAL_ANALYTIC,
});

export const setInternalAnalytic = (result: IInternalAnalytic) => ({
    type: SET_INTERNAL_ANALYTIC,
    payload: result,
});

export const changeInternalPerformanceReport = (value: string) => ({
    type: CHANGE_INTERNAL_PERFORMANCE_REPORT,
    payload: value,
});
