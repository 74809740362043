import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import { DOWNLOAD_EXCEL_TEMPLATE, UPLOAD_EXCEL_TEMPLATE } from '../../../actions/emission/excel.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const emissionExcelMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case DOWNLOAD_EXCEL_TEMPLATE:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '',
                        feature: DOWNLOAD_EXCEL_TEMPLATE,
                    })
                );
                break;

            case `${DOWNLOAD_EXCEL_TEMPLATE} ${API_SUCCESS}`: {
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: i18next.t('notification.successUpdated'),
                    })
                );
                break;
            }

            case `${DOWNLOAD_EXCEL_TEMPLATE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case UPLOAD_EXCEL_TEMPLATE:
                interface IBody {
                    organizationId?: number;
                    companyId: number;
                    facilityId: number;
                    description?: string;
                }
                const body: IBody = {
                    organizationId:
                        state?.auth?.userInfo?.organization?.id || state?.auth?.userInfo?.company?.organizationId,
                    companyId: state?.emission?.base?.selectedCompany?.id,
                    facilityId: state?.emission?.base?.selectedFacility?.id,
                    description: state.emission.excel.description,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/bulkEmission/calculate',
                        feature: UPLOAD_EXCEL_TEMPLATE,
                        body,
                    })
                );
                break;

            case `${UPLOAD_EXCEL_TEMPLATE} ${API_SUCCESS}`: {
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: i18next.t('notification.successUpdated'),
                    })
                );
                break;
            }

            case `${UPLOAD_EXCEL_TEMPLATE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }
        }
    };
