import { IGeneral } from "../../../entities/company/generalCombustion";

export const GENERAL = '[GENERAL]';

export const GET_GENERAL_RESULT = `${GENERAL} Get Result`;
export const SET_GENERAL_RESULT = `${GENERAL} Set Result`;

export const CHANGE_GENERAL_INTRODUCTION = `${GENERAL} Change Introduction`;
export const CHANGE_GENERAL_PURPOSE_SCOPE_OBJECTIVE = `${GENERAL} Change PurposeScopeObjective`;
export const CHANGE_GENERAL_DESCRIPTION_GENERAL_ORGANIZATION = `${GENERAL} Change Description General Organization`;
export const CHANGE_GENERAL_ENTITY_RESPONSIBLE_FOR_REPORT = `${GENERAL} Change Entitiy Responsible For Report`;
export const CHANGE_GENERAL_AUDIENCE_AND_DISSEMINATION_POLICIES = `${GENERAL} Change Audience And Dissemination Policies`;
export const CHANGE_GENERAL_PERIOD = `${GENERAL} Change General Period`;
export const CHANGE_GENERAL_BASE_YEAR = `${GENERAL} Change Base Year`;
export const CHANGE_GENERAL_STANDARTS = `${GENERAL} Change General Standarts`;
export const CHANGE_GENERAL_SUSTAINABILITY_POLICIES = `${GENERAL} Change Sustainability Policies`;
export const SUBMIT_GENERAL_FORM = `${GENERAL} Submit General Form`;

export type ISubmit = {
    onSuccess: () => void;
};

export const submitGeneralForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_GENERAL_FORM,
    meta: {
        onSuccess,
    },
});

export const getGeneralResult = (value: number) => ({
    type: GET_GENERAL_RESULT,
    payload: value
});

export const setGeneralResult = (value: IGeneral) => ({
    type: SET_GENERAL_RESULT,
    payload: value
});

export const changeGeneralIntroduction = (value: string) => ({
    type: CHANGE_GENERAL_INTRODUCTION,
    payload: value,
});

export const changeGeneralPurposeScopeObjective = (value: string) => ({
    type: CHANGE_GENERAL_PURPOSE_SCOPE_OBJECTIVE,
    payload: value,
});

export const changeGeneralDescriptionGeneralOrganization = (value: string) => ({
    type: CHANGE_GENERAL_DESCRIPTION_GENERAL_ORGANIZATION,
    payload: value,
});

export const changeGeneralEntityResponsibleForReport = (value: string) => ({
    type: CHANGE_GENERAL_ENTITY_RESPONSIBLE_FOR_REPORT,
    payload: value,
});

export const changeGeneralAudienceAndDisseminationPolicies = (value: string) => ({
    type: CHANGE_GENERAL_AUDIENCE_AND_DISSEMINATION_POLICIES,
    payload: value,
});

export const changeGeneralPeriod = (value: string) => ({
    type: CHANGE_GENERAL_PERIOD,
    payload: value,
});

export const changeGeneralBaseYear = (value: string) => ({
    type: CHANGE_GENERAL_BASE_YEAR,
    payload: value,
});

export const changeGeneralStandarts = (value: string) => ({
    type: CHANGE_GENERAL_STANDARTS,
    payload: value,
});

export const changeGeneralSustainabilityPolicies = (value: string) => ({
    type: CHANGE_GENERAL_SUSTAINABILITY_POLICIES,
    payload: value,
});


