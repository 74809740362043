import { AnyAction } from 'redux';
import {
    CHANGE_ELECTRIC_CONSUMPTION_AMOUNT,
    CHANGE_ELECTRIC_DESCRIPTION,
    CHANGE_ELECTRIC_EQUIVALENT_FACTOR,
    CHANGE_ELECTRIC_MAIN_FACILITY,
    CHANGE_ELECTRIC_SUB_FACILITY,
    CHANGE_ELECTRIC_TAGS,
    CHANGE_ELECTRIC_YEAR,
    CLEAR_ELECTRIC_CONSUMPTION_AMOUNT,
    CLEAR_ELECTRIC_INPUT,
    SELECT_ELECTRIC_ACTIVITY,
    SELECT_ELECTRIC_ACTIVITY_DATA,
    SELECT_ELECTRIC_ACTIVITY_TYPE,
    SELECT_ELECTRIC_DATABASE,
    SELECT_ELECTRIC_SOURCE,
    SELECT_ELECTRIC_TIER,
    SELECT_ELECTRIC_UNIT,
    SET_ELECTRIC_ACTIVITIES,
    SET_ELECTRIC_ACTIVITY_TYPES,
    SET_ELECTRIC_ADDITIONAL_CONSTANTS,
    SET_ELECTRIC_DATABASES,
    SET_ELECTRIC_RESULT,
    SET_ELECTRIC_SOURCES,
    SET_ELECTRIC_TIERS,
    SET_ELECTRIC_UNITS,
} from '../../actions/emission/electric.action';
import { CLEAR_BASE_INPUTS } from '../../actions/emission/base.action';
import { clearInput } from './base.reducer';
import { IElectricAdditional } from '../../../entities/emissionTypes/electricCombustion';

const initialState = {
    mainFacility: '',
    subFacility: '',
    tiers: [],
    tier: '',
    databases: [],
    database: '',
    quantity: '',
    activityData: '',
    description: '',
    sourceList: [],
    source: '',
    activityList: [],
    activity: '',
    activityTypeList: [],
    activityType: '',
    unitList: [],
    unit: '',
    year: '',
    equivalentFactor: '',
    consumptions: {
        0: { tonne: null, km: null },
        1: { tonne: null, km: null },
        2: { tonne: null, km: null },
        3: { tonne: null, km: null },
        4: { tonne: null, km: null },
        5: { tonne: null, km: null },
        6: { tonne: null, km: null },
        7: { tonne: null, km: null },
        8: { tonne: null, km: null },
        9: { tonne: null, km: null },
        10: { tonne: null, km: null },
        11: { tonne: null, km: null },
        12: { tonne: null, km: null },
    },
    result: [],
    tags: 'Elektrik',
    equivalentFactorUnit: '',
};

export const emissionElectricReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_ELECTRIC_ADDITIONAL_CONSTANTS:
            let unit = '';
            action.payload.map((i: IElectricAdditional) => {
                if (i.identifier === 'CO2E') unit = i.unit || '';
            });
            return {
                ...state,
                equivalentFactorUnit: unit,
            };
        case CLEAR_ELECTRIC_INPUT:
            const data = clearInput(action, initialState);
            return {
                ...state,
                ...data,
            };
        case CLEAR_BASE_INPUTS:
            return {
                ...initialState,
            };
        case CHANGE_ELECTRIC_MAIN_FACILITY:
            return {
                ...state,
                mainFacility: action.payload,
            };

        case CHANGE_ELECTRIC_SUB_FACILITY:
            return {
                ...state,
                subFacility: action.payload,
            };

        case CHANGE_ELECTRIC_TAGS:
            return {
                ...state,
                tags: action.payload,
            };

        case SET_ELECTRIC_TIERS:
            return {
                ...state,
                tiers: action.payload,
            };
        case SELECT_ELECTRIC_TIER:
            return {
                ...state,
                tier: action.payload,
            };

        case SET_ELECTRIC_DATABASES:
            return {
                ...state,
                databases: action.payload,
            };
        case SELECT_ELECTRIC_DATABASE:
            return {
                ...state,
                database: action.payload,
            };
        case CHANGE_ELECTRIC_EQUIVALENT_FACTOR:
            return {
                ...state,
                equivalentFactor: action.payload,
            };

        case SET_ELECTRIC_SOURCES:
            return {
                ...state,
                sourceList: action.payload,
            };

        case SELECT_ELECTRIC_SOURCE:
            return {
                ...state,
                source: action.payload,
            };

        case SET_ELECTRIC_ACTIVITIES:
            return {
                ...state,
                activityList: action.payload,
            };

        case SELECT_ELECTRIC_ACTIVITY:
            return {
                ...state,
                activity: action.payload,
            };

        case SET_ELECTRIC_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypeList: action.payload,
            };

        case SELECT_ELECTRIC_ACTIVITY_TYPE:
            return {
                ...state,
                activityType: action.payload,
            };

        case SET_ELECTRIC_UNITS:
            return {
                ...state,
                unitList: action.payload,
            };

        case SELECT_ELECTRIC_UNIT:
            return {
                ...state,
                unit: action.payload,
            };

        case SELECT_ELECTRIC_ACTIVITY_DATA:
            return {
                ...state,
                activityData: action.payload,
            };
        case CHANGE_ELECTRIC_YEAR:
            return {
                ...state,
                year: action.payload,
            };
        case CHANGE_ELECTRIC_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };

        case SET_ELECTRIC_RESULT:
            return {
                ...state,
                result: action.payload,
            };

        case CHANGE_ELECTRIC_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: {
                    ...state.consumptions,
                    [action.payload.month]: {
                        ...state.consumptions[action.payload.month as keyof typeof state.consumptions],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case CLEAR_ELECTRIC_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: initialState.consumptions,
            };
        default:
            return state;
    }
};
