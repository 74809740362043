import { AnyAction } from 'redux';
import {
    CHANGE_ORGANIZATIONAL_REASON,
    CHANGE_ORGANIZATIONAL_STRUCTURE,
    SELECT_ORGANIZATIONAL_APPRACHES,
    SELECT_ORGANIZATIONAL_FACILITY,
    SELECT_ORGANIZATIONAL_SUB_APPRACHES,
    SET_ORGANIZATIONAL_APPRACHES,
    SET_ORGANIZATIONAL_FACILITY_LIST,
    SET_ORGANIZATIONAL_RESULT,
    SET_ORGANIZATIONAL_SUB_APPRACHES,
} from '../../actions/company/organizational.action';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';

const initialState = {
    reason: '',
    structure:
        "Örnek: “Kuruluşumuz, 3 ana üretim tesisine, 1 merkezi yönetim binasına ve 260'tan fazla şubeye sahiptir. 3 ana üretim tesisi; İstanbul, Ankara ve Antalya da bulunmaktadır. Merkezi yönetim binası ise İstanbul da bulunmaktadır.” Kuruluş Sınırları, Faaliyet Sınırları ve Hesaplamalar ile ilgili yöntemler belirlenirken ISO 14064-1:2018 Standardı kılavuz olarak seçilmiştir.  Sera Gazı Raporlaması aşağıdaki adreste verilen sınırlar çerçevesinde yapılmıştır. Sınırlar içerisindeki tüm hesaplamalarda aynı metodoloji kullanılmıştır. ",
    appraches: '',
    apprachesList: [{ name: '', id: 0 }],
    subAppraches: '',
    subApprachesList: [{ name: '', id: 0 }],
    facilityList: [{ facilityName: '', id: 0 }],
    selectedFacilities: [],
    facilities: [{ id: 0, facilityName: '', email: '', address: '' }],
    isLoading: false,
};

const defaultText = [
    "Kuruluşumuz kendi idari kontrolünde olan tesislere ait hesaplanmış bütün sera gazı emisyonlarından ve/veya uzaklaştırmalarından sorumludur. Bu nedenle sera gazı emisyonlarının ve uzaklaştırmalarının birleştirilmesinde 'Operasyonel Kontrol Yaklaşımı' metodu seçilmiştir. Seçilen birleştirme yönteminde yapılan herhangi bir değişiklik bir sonraki sera gazı raporunda beyan edilecektir",

    'Kuruluşumuz kendi finansal kontrolünde olan tesislere ait hesaplanmış bütün sera gazı emisyonlarından ve/veya uzaklaştırmalarından sorumludur. Bu nedenle sera gazı emisyonlarının ve uzaklaştırmalarının birleştirilmesinde “Finansal Kontrol Yaklaşımı” metodu seçilmiştir. Seçilen birleştirme yönteminde yapılan herhangi bir değişiklik bir sonraki sera gazı raporunda beyan edilecektir.',

    'Kuruluşumuz tesislerden elde edilen ekonomik menfaatin veya elde edilen faydanın yüzdesi oranında tesislere ait hesaplanmış bütün sera gazı emisyonlarından ve/veya uzaklaştırmalarından sorumludur. Bu nedenle sera gazı emisyonlarının ve uzaklaştırmalarının birleştirilmesinde “Eşit Paylaşım Yaklaşımı” metodu seçilmiştir. Seçilen birleştirme yönteminde yapılan herhangi bir değişiklik bir sonraki sera gazı raporunda beyan edilecektir.', // eşit
];

export const organizationalCommonReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case SET_AUTH_LOGOUT:
            return initialState;
        case SET_ORGANIZATIONAL_RESULT:
            const data = action.payload;
            return {
                ...state,
                appraches: data.consolidationApproach,
                subAppraches: data.consolidationSubApproach,
                structure: data.organization_structure,
                reason: data.reason,
                selectedFacilities: data.facilityList,
                facilities: data.facilityList,
                isLoading: true,
            };

        case SET_ORGANIZATIONAL_APPRACHES:
            return {
                ...state,
                apprachesList: action.payload,
            };

        case SELECT_ORGANIZATIONAL_APPRACHES:
            const text = action.payload?.name === 'Eşit Paylaşım' ? defaultText[2] : '';
            return {
                ...state,
                appraches: action.payload,
                subAppraches: '',
                reason: text,
            };

        case SET_ORGANIZATIONAL_SUB_APPRACHES:
            return {
                ...state,
                subApprachesList: action.payload,
            };

        case SELECT_ORGANIZATIONAL_SUB_APPRACHES:
            let textControl = '';
            if (action.payload?.name === 'Operasyonel(idari) Kontrol') {
                textControl = defaultText[0];
            } else if (action.payload?.name === 'Finansal Kontrol') {
                textControl = defaultText[1];
            }

            return {
                ...state,
                subAppraches: action.payload,
                reason: textControl,
            };

        case SET_ORGANIZATIONAL_FACILITY_LIST:
            return {
                ...state,
                facilityList: action.payload,
            };

        case SELECT_ORGANIZATIONAL_FACILITY:
            return {
                ...state,
                selectedFacilities: action.payload,
            };

        case CHANGE_ORGANIZATIONAL_REASON:
            return {
                ...state,
                reason: action.payload,
            };

        case CHANGE_ORGANIZATIONAL_STRUCTURE:
            return {
                ...state,
                structure: action.payload,
            };

        default:
            return state;
    }
};
