import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IDownstreamLeasedActivity,
    IDownstreamLeasedActivityType,
    IDownstreamLeasedAdditional,
    IDownstreamLeasedDatabase,
    IDownstreamLeasedFuelType,
    IDownstreamLeasedResult,
    IDownstreamLeasedSize,
    IDownstreamLeasedUnit,
} from '../../../entities/emissionTypes/downstreamLeasedCombustion';
import { EMISSION } from './base.action';

export const DOWNSTREAM_LEASED = 'DOWNSTREAM_LEASED';

////-- Base
export const CHANGE_DOWNSTREAM_LEASED_MAIN_FACILITY = `${EMISSION} ${DOWNSTREAM_LEASED} Change Main Facility`;
export const CHANGE_DOWNSTREAM_LEASED_SUB_FACILITY = `${EMISSION} ${DOWNSTREAM_LEASED} Change Sub Facility`;

export const GET_DOWNSTREAM_LEASED_TIERS = `${EMISSION} ${DOWNSTREAM_LEASED} Get Tiers`;
export const SET_DOWNSTREAM_LEASED_TIERS = `${EMISSION} ${DOWNSTREAM_LEASED} Set Tiers`;
export const SELECT_DOWNSTREAM_LEASED_TIER = `${EMISSION} ${DOWNSTREAM_LEASED} Select Tier`;

export const GET_DOWNSTREAM_LEASED_DATABASES = `${EMISSION} ${DOWNSTREAM_LEASED} Get Databases`;
export const SET_DOWNSTREAM_LEASED_DATABASES = `${EMISSION} ${DOWNSTREAM_LEASED} Set Databases`;
export const SELECT_DOWNSTREAM_LEASED_DATABASE = `${EMISSION} ${DOWNSTREAM_LEASED} Select Database`;

export const CHANGE_DOWNSTREAM_LEASED_EQUIVALENT_FACTOR = `${EMISSION} ${DOWNSTREAM_LEASED} Change Equivalent Factor`;

export const GET_DOWNSTREAM_LEASED_ACTIVITIES = `${EMISSION} ${DOWNSTREAM_LEASED} Get Activities`;
export const SET_DOWNSTREAM_LEASED_ACTIVITIES = `${EMISSION} ${DOWNSTREAM_LEASED} Set Activities`;
export const SELECT_DOWNSTREAM_LEASED_ACTIVITY = `${EMISSION} ${DOWNSTREAM_LEASED} Select Activity`;
/////--- End base

export const GET_DOWNSTREAM_LEASED_FUEL_TYPES = `${EMISSION} ${DOWNSTREAM_LEASED} Get Fuel Types`;
export const SET_DOWNSTREAM_LEASED_FUEL_TYPES = `${EMISSION} ${DOWNSTREAM_LEASED} Set Fuel Types`;
export const SELECT_DOWNSTREAM_LEASED_FUEL_TYPE = `${EMISSION} ${DOWNSTREAM_LEASED} Select Fuel Type`;

export const GET_DOWNSTREAM_LEASED_ACTIVITY_TYPES = `${EMISSION} ${DOWNSTREAM_LEASED} Get Activity Types`;
export const SET_DOWNSTREAM_LEASED_ACTIVITY_TYPES = `${EMISSION} ${DOWNSTREAM_LEASED} Set Activity Types`;
export const SELECT_DOWNSTREAM_LEASED_ACTIVITY_TYPE = `${EMISSION} ${DOWNSTREAM_LEASED} Select Activity Type`;

export const GET_DOWNSTREAM_LEASED_UNITS = `${EMISSION} ${DOWNSTREAM_LEASED} Get Units`;
export const SET_DOWNSTREAM_LEASED_UNITS = `${EMISSION} ${DOWNSTREAM_LEASED} Set Units`;
export const SELECT_DOWNSTREAM_LEASED_UNIT = `${EMISSION} ${DOWNSTREAM_LEASED} Select Unit`;

export const GET_DOWNSTREAM_LEASED_ADDITIONAL_CONSTANTS = `${EMISSION} ${DOWNSTREAM_LEASED} Get Additional Constants`;
export const SET_DOWNSTREAM_LEASED_ADDITIONAL_CONSTANTS = `${EMISSION} ${DOWNSTREAM_LEASED} Set Additional Constants`;

export const CHANGE_DOWNSTREAM_LEASED_WEIGHT = `${EMISSION} ${DOWNSTREAM_LEASED} Change Weight`;
export const CHANGE_DOWNSTREAM_LEASED_DISTANCE = `${EMISSION} ${DOWNSTREAM_LEASED} Change Distance`;

export const GET_DOWNSTREAM_LEASED_LADEN_PERCENTAGES = `${EMISSION} ${DOWNSTREAM_LEASED} Get Laden Percentages`;
export const SET_DOWNSTREAM_LEASED_LADEN_PERCENTAGES = `${EMISSION} ${DOWNSTREAM_LEASED} Set Laden Percentages`;
export const SELECT_DOWNSTREAM_LEASED_LADEN_PERCENTAGE = `${EMISSION} ${DOWNSTREAM_LEASED} Select Laden percentage`;

export const GET_DOWNSTREAM_LEASED_SIZES = `${EMISSION} ${DOWNSTREAM_LEASED} Get Sizes`;
export const SET_DOWNSTREAM_LEASED_SIZES = `${EMISSION} ${DOWNSTREAM_LEASED} Set Sizes`;
export const SELECT_DOWNSTREAM_LEASED_SIZE = `${EMISSION} ${DOWNSTREAM_LEASED} Select Size`;

////-- Base
export const CHANGE_DOWNSTREAM_LEASED_YEAR = `${EMISSION} ${DOWNSTREAM_LEASED} Change Year`;
export const CHANGE_DOWNSTREAM_LEASED_TAGS = `${EMISSION} ${DOWNSTREAM_LEASED} Change Tags`;
export const SELECT_DOWNSTREAM_LEASED_ACTIVITY_DATA = `${EMISSION} ${DOWNSTREAM_LEASED} Select Activity Data`;
export const CHANGE_DOWNSTREAM_LEASED_CONSUMPTION_AMOUNT = `${EMISSION} ${DOWNSTREAM_LEASED} Change Consumption Amount`;
export const CHANGE_DOWNSTREAM_LEASED_DESCRIPTION = `${EMISSION} ${DOWNSTREAM_LEASED} Change Description`;

export const SUBMIT_DOWNSTREAM_LEASED_FORM = `${EMISSION} ${DOWNSTREAM_LEASED} Submit Form`;
export const SET_DOWNSTREAM_LEASED_RESULT = `${EMISSION} ${DOWNSTREAM_LEASED} Set Result`;
/////-- End Base
export const CLEAR_DOWNSTREAM_LEASED_CONSUMPTION_AMOUNT = `${EMISSION} ${DOWNSTREAM_LEASED} Clear Consumption Amount`;
export const CLEAR_DOWNSTREAM_LEASED_INPUT = `${EMISSION} ${DOWNSTREAM_LEASED} Clear DownstreamLeased Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearDownstreamLeasedInput = (list: Array<string>) => ({
    type: CLEAR_DOWNSTREAM_LEASED_INPUT,
    payload: list,
});
/// Region DownstreamLeased
export const changeDownstreamLeasedMainFacility = (text: string) => ({
    type: CHANGE_DOWNSTREAM_LEASED_MAIN_FACILITY,
    payload: text,
});

export const changeDownstreamLeasedSubFacility = (text: string) => {
    return {
        type: CHANGE_DOWNSTREAM_LEASED_SUB_FACILITY,
        payload: text,
    };
};

export const getDownstreamLeasedTiers = () => ({
    type: GET_DOWNSTREAM_LEASED_TIERS,
});

export const setDownstreamLeasedTiers = (list: Array<ITier>) => ({
    type: SET_DOWNSTREAM_LEASED_TIERS,
    payload: list,
});

export const selectDownstreamLeasedTier = (tier: ITier) => ({
    type: SELECT_DOWNSTREAM_LEASED_TIER,
    payload: tier,
});

export const getDownstreamLeasedDatabases = (tier: ITier) => ({
    type: GET_DOWNSTREAM_LEASED_DATABASES,
    payload: tier,
});

export const setDownstreamLeasedDatabases = (list: Array<IDownstreamLeasedDatabase>) => ({
    type: SET_DOWNSTREAM_LEASED_DATABASES,
    payload: list,
});

export const selectDownstreamLeasedDatabase = (database: IDownstreamLeasedDatabase) => ({
    type: SELECT_DOWNSTREAM_LEASED_DATABASE,
    payload: database,
});

export const getDownstreamLeasedActivities = (tier: ITier) => ({
    type: GET_DOWNSTREAM_LEASED_ACTIVITIES,
    payload: tier,
});

export const setDownstreamLeasedActivities = (activities: Array<IDownstreamLeasedActivity>) => ({
    type: SET_DOWNSTREAM_LEASED_ACTIVITIES,
    payload: activities,
});

export const selectDownstreamLeasedActivity = (activity: IDownstreamLeasedActivity) => ({
    type: SELECT_DOWNSTREAM_LEASED_ACTIVITY,
    payload: activity,
});

///////
export const getDownstreamLeasedFuelTypes = (tier: ITier) => ({
    type: GET_DOWNSTREAM_LEASED_FUEL_TYPES,
    payload: tier,
});

export const setDownstreamLeasedFuelTypes = (list: Array<IDownstreamLeasedFuelType>) => ({
    type: SET_DOWNSTREAM_LEASED_FUEL_TYPES,
    payload: list,
});

export const selectDownstreamLeasedFuelType = (database: IDownstreamLeasedFuelType) => ({
    type: SELECT_DOWNSTREAM_LEASED_FUEL_TYPE,
    payload: database,
});

export const getDownstreamLeasedActivityTypes = (value: any) => ({
    type: GET_DOWNSTREAM_LEASED_ACTIVITY_TYPES,
    payload: value,
});

export const setDownstreamLeasedActivityTypes = (activities: Array<IDownstreamLeasedActivityType>) => ({
    type: SET_DOWNSTREAM_LEASED_ACTIVITY_TYPES,
    payload: activities,
});

export const selectDownstreamLeasedActivityType = (activity: IDownstreamLeasedActivityType) => ({
    type: SELECT_DOWNSTREAM_LEASED_ACTIVITY_TYPE,
    payload: activity,
});

export const getDownstreamLeasedUnits = (units: IDownstreamLeasedActivityType) => ({
    type: GET_DOWNSTREAM_LEASED_UNITS,
    payload: units,
});

export const setDownstreamLeasedUnits = (units: Array<IDownstreamLeasedUnit>) => ({
    type: SET_DOWNSTREAM_LEASED_UNITS,
    payload: units,
});

export const selectDownstreamLeasedUnit = (unit: IDownstreamLeasedUnit) => ({
    type: SELECT_DOWNSTREAM_LEASED_UNIT,
    payload: unit,
});

export const getDownstreamLeasedAdditionalConstants = (additional: IDownstreamLeasedUnit) => ({
    type: GET_DOWNSTREAM_LEASED_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setDownstreamLeasedAdditionalConstants = (additional: Array<IDownstreamLeasedAdditional>) => ({
    type: SET_DOWNSTREAM_LEASED_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeDownstreamLeasedWeight = (value: number) => ({
    type: CHANGE_DOWNSTREAM_LEASED_WEIGHT,
    payload: value,
});

export const changeDownstreamLeasedDistance = (value: number) => ({
    type: CHANGE_DOWNSTREAM_LEASED_DISTANCE,
    payload: value,
});

export const getDownstreamLeasedLadenPercentages = (ladens: any) => ({
    type: GET_DOWNSTREAM_LEASED_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setDownstreamLeasedLadenPercentages = (ladens: Array<any>) => ({
    type: SET_DOWNSTREAM_LEASED_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectDownstreamLeasedLadenPercentage = (laden: any) => ({
    type: SELECT_DOWNSTREAM_LEASED_LADEN_PERCENTAGE,
    payload: laden,
});

export const getDownstreamLeasedSizes = (sizes: IDownstreamLeasedActivityType) => ({
    type: GET_DOWNSTREAM_LEASED_SIZES,
    payload: sizes,
});

export const setDownstreamLeasedSizes = (sizes: Array<IDownstreamLeasedSize>) => ({
    type: SET_DOWNSTREAM_LEASED_SIZES,
    payload: sizes,
});

export const selectDownstreamLeasedSize = (size: IDownstreamLeasedSize) => ({
    type: SELECT_DOWNSTREAM_LEASED_SIZE,
    payload: size,
});

///////
export const submitDownstreamLeasedForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_DOWNSTREAM_LEASED_FORM,
    meta: {
        onSuccess,
    },
});

export const setDownstreamLeasedResult = (data: Array<IDownstreamLeasedResult>) => ({
    type: SET_DOWNSTREAM_LEASED_RESULT,
    payload: data,
});

export const selectDownstreamLeasedActivityData = (activity: string) => ({
    type: SELECT_DOWNSTREAM_LEASED_ACTIVITY_DATA,
    payload: activity,
});

export const changeDownstreamLeasedYear = (value: number) => ({
    type: CHANGE_DOWNSTREAM_LEASED_YEAR,
    payload: value,
});

export const changeDownstreamLeasedTags = (value: string) => ({
    type: CHANGE_DOWNSTREAM_LEASED_TAGS,
    payload: value,
});

export const changeDownstreamLeasedDescription = (text: string) => ({
    type: CHANGE_DOWNSTREAM_LEASED_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_DOWNSTREAM_LEASED_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_DOWNSTREAM_LEASED_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_DOWNSTREAM_LEASED_CONSUMPTION_AMOUNT,
});
/// End Region DownstreamLeased
