import { combineReducers } from 'redux';
import { companyCommonReducer } from './company.reducer';
import { facilityCommonReducer } from './facility.reducer';
import { organizationalCommonReducer } from './organizational.reducer';
import { generalCommonReducer } from './general.reducer';
import { reportingCommonReducer } from './reporting.reducers';
import { quantifiedCommonReducer } from './quantified.reducer';
import { internalCommonReducer } from './internal.reducer';
import { appendicesCommonReducer } from './appendices.reducer';
import { analyticCommonReducer } from './analytic.reducer';
import { organizationReducer } from './organization.reducer';

const companyReducer = combineReducers({
    company: companyCommonReducer,
    facility: facilityCommonReducer,
    general: generalCommonReducer,
    organizational: organizationalCommonReducer,
    reporting: reportingCommonReducer,
    quantified: quantifiedCommonReducer,
    internal: internalCommonReducer,
    appendices: appendicesCommonReducer,
    analytic: analyticCommonReducer,
    organization: organizationReducer,
});

export { companyReducer };
