import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IBusinessActivity,
    IBusinessActivityType,
    IBusinessDatabase,
    IBusinessFuelType,
    IBusinessPerNight,
    IBusinessResult,
    IBusinessSize,
    IBusinessUnit,
} from '../../../entities/emissionTypes/businessCombustion';
import { EMISSION } from './base.action';

export const BUSINESS = 'BUSINESS';

////-- Base
export const CHANGE_BUSINESS_MAIN_FACILITY = `${EMISSION} ${BUSINESS} Change Main Facility`;
export const CHANGE_BUSINESS_SUB_FACILITY = `${EMISSION} ${BUSINESS} Change Sub Facility`;

export const GET_BUSINESS_TIERS = `${EMISSION} ${BUSINESS} Get Tiers`;
export const SET_BUSINESS_TIERS = `${EMISSION} ${BUSINESS} Set Tiers`;
export const SELECT_BUSINESS_TIER = `${EMISSION} ${BUSINESS} Select Tier`;

export const GET_BUSINESS_DATABASES = `${EMISSION} ${BUSINESS} Get Databases`;
export const SET_BUSINESS_DATABASES = `${EMISSION} ${BUSINESS} Set Databases`;
export const SELECT_BUSINESS_DATABASE = `${EMISSION} ${BUSINESS} Select Database`;

export const CHANGE_BUSINESS_EQUIVALENT_FACTOR = `${EMISSION} ${BUSINESS} Change Equivalent Factor`;

export const GET_BUSINESS_ACTIVITIES = `${EMISSION} ${BUSINESS} Get Activities`;
export const SET_BUSINESS_ACTIVITIES = `${EMISSION} ${BUSINESS} Set Activities`;
export const SELECT_BUSINESS_ACTIVITY = `${EMISSION} ${BUSINESS} Select Activity`;
/////--- End base

export const GET_BUSINESS_FUEL_TYPES = `${EMISSION} ${BUSINESS} Get Fuel Types`;
export const SET_BUSINESS_FUEL_TYPES = `${EMISSION} ${BUSINESS} Set Fuel Types`;
export const SELECT_BUSINESS_FUEL_TYPE = `${EMISSION} ${BUSINESS} Select Fuel Type`;

export const GET_BUSINESS_ACTIVITY_TYPES = `${EMISSION} ${BUSINESS} Get Activity Types`;
export const SET_BUSINESS_ACTIVITY_TYPES = `${EMISSION} ${BUSINESS} Set Activity Types`;
export const SELECT_BUSINESS_ACTIVITY_TYPE = `${EMISSION} ${BUSINESS} Select Activity Type`;

export const GET_BUSINESS_UNITS = `${EMISSION} ${BUSINESS} Get Units`;
export const SET_BUSINESS_UNITS = `${EMISSION} ${BUSINESS} Set Units`;
export const SELECT_BUSINESS_UNIT = `${EMISSION} ${BUSINESS} Select Unit`;

export const CHANGE_BUSINESS_PER_NIGHT = `${EMISSION} ${BUSINESS} Change Per Night`;
export const CHANGE_BUSINESS_WEIGHT = `${EMISSION} ${BUSINESS} Change Weight`;
export const CHANGE_BUSINESS_DISTANCE = `${EMISSION} ${BUSINESS} Change Distance`;

export const GET_BUSINESS_LADEN_PERCENTAGES = `${EMISSION} ${BUSINESS} Get Laden Percentages`;
export const SET_BUSINESS_LADEN_PERCENTAGES = `${EMISSION} ${BUSINESS} Set Laden Percentages`;
export const SELECT_BUSINESS_LADEN_PERCENTAGE = `${EMISSION} ${BUSINESS} Select Laden percentage`;

export const GET_BUSINESS_SIZES = `${EMISSION} ${BUSINESS} Get Sizes`;
export const SET_BUSINESS_SIZES = `${EMISSION} ${BUSINESS} Set Sizes`;
export const SELECT_BUSINESS_SIZE = `${EMISSION} ${BUSINESS} Select Size`;

////-- Base
export const CHANGE_BUSINESS_YEAR = `${EMISSION} ${BUSINESS} Change Year`;
export const SELECT_BUSINESS_ACTIVITY_DATA = `${EMISSION} ${BUSINESS} Select Activity Data`;
export const CHANGE_BUSINESS_CONSUMPTION_AMOUNT = `${EMISSION} ${BUSINESS} Change Consumption Amount`;
export const CHANGE_BUSINESS_DESCRIPTION = `${EMISSION} ${BUSINESS} Change Description`;

export const SUBMIT_BUSINESS_FORM = `${EMISSION} ${BUSINESS} Submit Form`;
export const SET_BUSINESS_RESULT = `${EMISSION} ${BUSINESS} Set Result`;
/////-- End Base
export const CLEAR_BUSINESS_CONSUMPTION_AMOUNT = `${EMISSION} ${BUSINESS} Clear Consumption Amount`;
export const CLEAR_BUSINESS_INPUT= `${EMISSION} ${BUSINESS} Clear Business Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearBusinessnput = (list: Array<string>) => ({
    type: CLEAR_BUSINESS_INPUT,
    payload: list,
});
/// Region Business
export const changeBusinessMainFacility = (text: string) => ({
    type: CHANGE_BUSINESS_MAIN_FACILITY,
    payload: text,
});

export const changeBusinessSubFacility = (text: string) => {
    return {
        type: CHANGE_BUSINESS_SUB_FACILITY,
        payload: text,
    };
};

export const getBusinessTiers = () => ({
    type: GET_BUSINESS_TIERS,
});

export const setBusinessTiers = (list: Array<ITier>) => ({
    type: SET_BUSINESS_TIERS,
    payload: list,
});

export const selectBusinessTier = (tier: ITier) => ({
    type: SELECT_BUSINESS_TIER,
    payload: tier,
});

export const getBusinessDatabases = (tier: ITier) => ({
    type: GET_BUSINESS_DATABASES,
    payload: tier,
});

export const setBusinessDatabases = (list: Array<IBusinessDatabase>) => ({
    type: SET_BUSINESS_DATABASES,
    payload: list,
});

export const selectBusinessDatabase = (database: IBusinessDatabase) => ({
    type: SELECT_BUSINESS_DATABASE,
    payload: database,
});

export const getBusinessActivities = (tier: ITier) => ({
    type: GET_BUSINESS_ACTIVITIES,
    payload: tier,
});

export const setBusinessActivities = (activities: Array<IBusinessActivity>) => ({
    type: SET_BUSINESS_ACTIVITIES,
    payload: activities,
});

export const selectBusinessActivity = (activity: IBusinessActivity) => ({
    type: SELECT_BUSINESS_ACTIVITY,
    payload: activity,
});

///////
export const getBusinessFuelTypes = (tier: ITier) => ({
    type: GET_BUSINESS_FUEL_TYPES,
    payload: tier,
});

export const setBusinessFuelTypes = (list: Array<IBusinessFuelType>) => ({
    type: SET_BUSINESS_FUEL_TYPES,
    payload: list,
});

export const selectBusinessFuelType = (database: IBusinessFuelType) => ({
    type: SELECT_BUSINESS_FUEL_TYPE,
    payload: database,
});

export const getBusinessActivityTypes = (value: any) => ({
    type: GET_BUSINESS_ACTIVITY_TYPES,
    payload: value,
});

export const setBusinessActivityTypes = (activities: Array<IBusinessActivityType>) => ({
    type: SET_BUSINESS_ACTIVITY_TYPES,
    payload: activities,
});

export const selectBusinessActivityType = (activity: IBusinessActivityType) => ({
    type: SELECT_BUSINESS_ACTIVITY_TYPE,
    payload: activity,
});

export const getBusinessUnits = (units: IBusinessActivityType) => ({
    type: GET_BUSINESS_UNITS,
    payload: units,
});

export const setBusinessUnits = (units: Array<IBusinessUnit>) => ({
    type: SET_BUSINESS_UNITS,
    payload: units,
});

export const selectBusinessUnit = (unit: IBusinessUnit) => ({
    type: SELECT_BUSINESS_UNIT,
    payload: unit,
});

export const changeBusinessPerNight = (perNight: IBusinessPerNight) => ({
    type: CHANGE_BUSINESS_PER_NIGHT,
    payload: perNight,
});

export const changeBusinessWeight = (value: number) => ({
    type: CHANGE_BUSINESS_WEIGHT,
    payload: value,
});

export const changeBusinessDistance = (value: number) => ({
    type: CHANGE_BUSINESS_DISTANCE,
    payload: value,
});

export const getBusinessLadenPercentages = (ladens: any) => ({
    type: GET_BUSINESS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setBusinessLadenPercentages = (ladens: Array<any>) => ({
    type: SET_BUSINESS_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectBusinessLadenPercentage = (laden: any) => ({
    type: SELECT_BUSINESS_LADEN_PERCENTAGE,
    payload: laden,
});

export const getBusinessSizes = (sizes: IBusinessActivityType) => ({
    type: GET_BUSINESS_SIZES,
    payload: sizes,
});

export const setBusinessSizes = (sizes: Array<IBusinessSize>) => ({
    type: SET_BUSINESS_SIZES,
    payload: sizes,
});

export const selectBusinessSize = (size: IBusinessSize) => ({
    type: SELECT_BUSINESS_SIZE,
    payload: size,
});

///////
export const submitBusinessForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_BUSINESS_FORM,
    meta: {
        onSuccess,
    },
});

export const setBusinessResult = (data: Array<IBusinessResult>) => ({
    type: SET_BUSINESS_RESULT,
    payload: data,
});

export const selectBusinessActivityData = (activity: string) => ({
    type: SELECT_BUSINESS_ACTIVITY_DATA,
    payload: activity,
});

export const changeBusinessYear = (value: number) => ({
    type: CHANGE_BUSINESS_YEAR,
    payload: value,
});

export const changeBusinessDescription = (text: string) => ({
    type: CHANGE_BUSINESS_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_BUSINESS_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_BUSINESS_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_BUSINESS_CONSUMPTION_AMOUNT,
});
/// End Region Business
