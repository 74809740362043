export const Countries = [
    {
        id: 228,
        name: 'TÜRKİYE',
        englishName: 'TURKEY',
        phoneCode: '+90',
    },
];

export const Cities = [
    {
        id: 4270,
        name: 'İstanbul',
        countryId: 228,
    },
    {
        id: 4237,
        name: 'Ankara',
        countryId: 228,
    },
    {
        id: 4271,
        name: 'İzmir',
        countryId: 228,
    },
    {
        id: 4251,
        name: 'Bursa',
        countryId: 228,
    },
    {
        id: 4232,
        name: 'Adıyaman',
        countryId: 228,
    },
    {
        id: 4233,
        name: 'Afyonkarahisar',
        countryId: 228,
    },
    {
        id: 4234,
        name: 'Ağrı',
        countryId: 228,
    },
    {
        id: 4235,
        name: 'Aksaray',
        countryId: 228,
    },
    {
        id: 4236,
        name: 'Amasya',
        countryId: 228,
    },
    {
        id: 4238,
        name: 'Antalya',
        countryId: 228,
    },
    {
        id: 4240,
        name: 'Artvin',
        countryId: 228,
    },
    {
        id: 4241,
        name: 'Aydın',
        countryId: 228,
    },
    {
        id: 4245,
        name: 'Bayburt',
        countryId: 228,
    },
    {
        id: 4246,
        name: 'Bilecik',
        countryId: 228,
    },
    {
        id: 4247,
        name: 'Bingöl',
        countryId: 228,
    },
    {
        id: 4248,
        name: 'Bitlis',
        countryId: 228,
    },
    {
        id: 4249,
        name: 'Bolu',
        countryId: 228,
    },
    {
        id: 4250,
        name: 'Burdur',
        countryId: 228,
    },
    {
        id: 4252,
        name: 'Çanakkale',
        countryId: 228,
    },
    {
        id: 4253,
        name: 'Çankırı',
        countryId: 228,
    },
    {
        id: 4254,
        name: 'Çorum',
        countryId: 228,
    },
    {
        id: 4255,
        name: 'Denizli',
        countryId: 228,
    },
    {
        id: 4256,
        name: 'Diyarbakır',
        countryId: 228,
    },
    {
        id: 4258,
        name: 'Edirne',
        countryId: 228,
    },
    {
        id: 4260,
        name: 'Erzincan',
        countryId: 228,
    },
    {
        id: 4261,
        name: 'Erzurum',
        countryId: 228,
    },
    {
        id: 4263,
        name: 'Gaziantep',
        countryId: 228,
    },
    {
        id: 4264,
        name: 'Giresun',
        countryId: 228,
    },
    {
        id: 4266,
        name: 'Hakkari',
        countryId: 228,
    },
    {
        id: 4267,
        name: 'Hatay',
        countryId: 228,
    },
    {
        id: 4268,
        name: 'Iğdır',
        countryId: 228,
    },
    {
        id: 4269,
        name: 'Isparta',
        countryId: 228,
    },
    {
        id: 4272,
        name: 'Kahramanmaraş',
        countryId: 228,
    },
    {
        id: 4273,
        name: 'Karabük',
        countryId: 228,
    },
    {
        id: 4274,
        name: 'Karaman',
        countryId: 228,
    },
    {
        id: 4276,
        name: 'Kastamonu',
        countryId: 228,
    },
    {
        id: 4277,
        name: 'Kayseri',
        countryId: 228,
    },
    {
        id: 4278,
        name: 'Kilis',
        countryId: 228,
    },
    {
        id: 4279,
        name: 'Kırıkkale',
        countryId: 228,
    },
    {
        id: 4280,
        name: 'Kırklareli',
        countryId: 228,
    },
    {
        id: 4281,
        name: 'Kırşehir',
        countryId: 228,
    },
    {
        id: 4282,
        name: 'Kocaeli',
        countryId: 228,
    },
    {
        id: 4283,
        name: 'Konya',
        countryId: 228,
    },
    {
        id: 4284,
        name: 'Kütahya',
        countryId: 228,
    },
    {
        id: 4285,
        name: 'Malatya',
        countryId: 228,
    },
    {
        id: 4286,
        name: 'Manisa',
        countryId: 228,
    },
    {
        id: 4288,
        name: 'Mersin',
        countryId: 228,
    },
    {
        id: 4289,
        name: 'Muğla',
        countryId: 228,
    },
    {
        id: 4290,
        name: 'Muş',
        countryId: 228,
    },
    {
        id: 4291,
        name: 'Nevşehir',
        countryId: 228,
    },
    {
        id: 4292,
        name: 'Niğde',
        countryId: 228,
    },
    {
        id: 4293,
        name: 'Ordu',
        countryId: 228,
    },
    {
        id: 4294,
        name: 'Osmaniye',
        countryId: 228,
    },
    {
        id: 4295,
        name: 'Rize',
        countryId: 228,
    },
    {
        id: 4296,
        name: 'Sakarya',
        countryId: 228,
    },
    {
        id: 4297,
        name: 'Samsun',
        countryId: 228,
    },
    {
        id: 4298,
        name: 'Şanlıurfa',
        countryId: 228,
    },
    {
        id: 4299,
        name: 'Siirt',
        countryId: 228,
    },
    {
        id: 4302,
        name: 'Şırnak',
        countryId: 228,
    },
    {
        id: 4303,
        name: 'Tekirdağ',
        countryId: 228,
    },
    {
        id: 4304,
        name: 'Tokat',
        countryId: 228,
    },
    {
        id: 4305,
        name: 'Trabzon',
        countryId: 228,
    },
    {
        id: 4306,
        name: 'Tunceli',
        countryId: 228,
    },
    {
        id: 4307,
        name: 'Uşak',
        countryId: 228,
    },
    {
        id: 4310,
        name: 'Yozgat',
        countryId: 228,
    },
    {
        id: 4311,
        name: 'Zonguldak',
        countryId: 228,
    },
    {
        id: 4244,
        name: 'Batman',
        countryId: 228,
    },
    {
        id: 4231,
        name: 'Adana',
        countryId: 228,
    },
    {
        id: 4242,
        name: 'Balıkesir',
        countryId: 228,
    },
    {
        id: 4243,
        name: 'Bartın',
        countryId: 228,
    },
    {
        id: 4265,
        name: 'Gümüşhane',
        countryId: 228,
    },
    {
        id: 4300,
        name: 'Sinop',
        countryId: 228,
    },
    {
        id: 4308,
        name: 'Van',
        countryId: 228,
    },
    {
        id: 4257,
        name: 'Düzce',
        countryId: 228,
    },
    {
        id: 4259,
        name: 'Elazığ',
        countryId: 228,
    },
    {
        id: 4262,
        name: 'Eskişehir',
        countryId: 228,
    },
    {
        id: 4275,
        name: 'Kars',
        countryId: 228,
    },
    {
        id: 4287,
        name: 'Mardin',
        countryId: 228,
    },
    {
        id: 4301,
        name: 'Sivas',
        countryId: 228,
    },
    {
        id: 4309,
        name: 'Yalova',
        countryId: 228,
    },
    {
        id: 4239,
        name: 'Ardahan',
        countryId: 228,
    },
];
