interface EmissionType {
    id: string;
    title: string;
    description: string;
    type: TypeEnum;
    disabled: boolean;
}

export enum TypeEnum {
    STATIC_COMBUSTION = 'STATIC_COMBUSTION',
    MOBILE_COMBUSTION = 'MOBILE_COMBUSTION',
    PROCESS_EMISSIONS = 'PROCESS_EMISSIONS',
    LEAK_EMISSIONS = 'LEAK_EMISSIONS',
    LULUCF_EMISSION = 'LULUCF_EMISSION',
    BIOMASS_EMISSION = 'BIOMASS_EMISSON',

    ELECTRIC_CONSUMPTION = 'ELECTRIC_CONSUMPTION',
    STEAM_EMISSIONS = 'STEAM_EMISSIONS',

    INCOME_MATERIAL_TRANSPORTATION = 'INCOME_MATERIAL_TRANSPORTATION',
    OUTCOME_MATERIAL_TRANSPORTATION = 'OUTCOME_MATERIAL_TRANSPORTATION',
    EMPLOYEE_TRANSPORTATION = 'EMPLOYEE_TRANSPORTATION',
    VISITOR_TRANSPORTATION = 'VISITOR_TRANSPORTATION',
    BUSINESS_TRAVEL = 'BUSINESS_TRAVEL',

    SOLD_PRODUCTS = 'SOLD_PRODUCTS',
    BOUGHT_ASSETS = 'BOUGHT_ASSETS',
    WASTE_DISPOSAL = 'WASTE_DISPOSAL',
    ASSET_USAGE = 'ASSET_USAGE',
    SERVICE_USAGE = 'SERVICE_USAGE',

    PRODUCT_USAGE = 'PRODUCT_USAGE',
    LEASED_ASSETS = 'LEASED_ASSETS', // Deprecated
    AFTER_PRODUCT_LIFETIME = 'AFTER_PRODUCT_LIFETIME',
    INVESTMENT_EMISSIONS = 'INVESTMENT_EMISSIONS',
    UPSTREAM_LEASED_ASSETS = 'UPSTREAM_LEASED_ASSETS',
    DOWNSTREAM_LEASED_ASSETS = 'DOWNSTREAM_LEASED_ASSETS',

    OTHERS = 'OTHERS',
}
export default EmissionType;
