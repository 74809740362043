import { combineReducers } from 'redux';
import { emissionStaticReducer } from './static.reducer';
import { emissionBaseReducer } from './base.reducer';
import { emissionElectricReducer } from './electric.reducer';
import { emissionSteamReducer } from './steam.reducer';
import { emissionMobileReducer } from './mobile.reducer';
import { emissionDownstreamReducer } from './downstream.reducer';
import { emissionUpstreamReducer } from './upstream.reducer';
import { emissionEmployeeReducer } from './employee.reducer';
import { emissionBusinessReducer } from './business.reducer';
import { emissionVisitorReducer } from './visitor.reducer';
import { emissionProductLifetimeReducer } from './productLifetime.reducer';
import { emissionBoughtAssetsReducer } from './boughtAssets.reducer';
import { emissionSoldProductsReducer } from './soldProducts.reducer';
import { emissionWasteReducer } from './waste.reducer';
import { emissionFugitiveReducer } from './fugivitive.reducer';
import { updateEmissionReducer } from './update.reducer';
import { emissionProcessReducer } from './process.reducer';
import { emissionLulucfReducer } from './lulucf.reducer';
import { emissionBiomassReducer } from './biomass.reducer';
import { emissionExcelReducer } from './excel.reducer';
import { emissionServiceUsageReducer } from './serviceUsage.reducer';
import { emissionDownstreamLeasedReducer } from './downstreamLeased.reducer';
import { emissionUpstreamLeasedReducer } from './upstreamLeased.reducer';

const emissionReducer = combineReducers({
    base: emissionBaseReducer,
    static: emissionStaticReducer,
    electric: emissionElectricReducer,
    steam: emissionSteamReducer,
    mobile: emissionMobileReducer,
    fugitive: emissionFugitiveReducer,
    downstream: emissionDownstreamReducer,
    business: emissionBusinessReducer,
    upstream: emissionUpstreamReducer,
    employee: emissionEmployeeReducer,
    visitor: emissionVisitorReducer,
    productLifetime: emissionProductLifetimeReducer,
    boughtAssets: emissionBoughtAssetsReducer,
    soldProducts: emissionSoldProductsReducer,
    waste: emissionWasteReducer,
    update: updateEmissionReducer,
    process: emissionProcessReducer,
    lulucf: emissionLulucfReducer,
    biomass: emissionBiomassReducer,
    excel: emissionExcelReducer,
    serviceUsage: emissionServiceUsageReducer,
    downstreamLeased: emissionDownstreamLeasedReducer,
    upstreamLeased: emissionUpstreamLeasedReducer,
});

export { emissionReducer };
