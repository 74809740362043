export const RESET = '[RESET]';

export const CHANGE_RESET_EMAIL = `${RESET} Change Email`;
export const CHECK_RESET_EMAIL = `${RESET} Check Reset Email`;

export const CHANGE_RESET_CODE = `${RESET} Change Code`;
export const CHECK_RESET_CODE = `${RESET} Check Reset Code`;

export const CHANGE_RESET_PASSWORD = `${RESET} Check Reset Password`;
export const SUBMIT_RESET_PASSWORD = `${RESET} Submit Reset Password`;

// RESET
export type ISubmit = {
    onSuccess: () => void;
};

// STEP 1
export const changeResetEmail = (email: string) => ({
    type: CHANGE_RESET_EMAIL,
    payload: email,
});

export const checkResetEmail = (email: string, { onSuccess }: ISubmit) => ({
    type: CHECK_RESET_EMAIL,
    payload: email,
    meta: {
        onSuccess,
    },
});
// END STEP 1

// STEP 2
export const changeResetCode = (code: string) => ({
    type: CHANGE_RESET_CODE,
    payload: code,
});

export const checkResetCode = (code: string, { onSuccess }: ISubmit) => ({
    type: CHECK_RESET_CODE,
    payload: code,
    meta: {
        onSuccess,
    },
});
// END STEP 2

// STEP 3
export const changeResetPassword = (password: string) => ({
    type: CHANGE_RESET_PASSWORD,
    payload: password,
});

export const submitResetPassword = (password: string, { onSuccess }: ISubmit) => ({
    type: SUBMIT_RESET_PASSWORD,
    payload: password,
    meta: {
        onSuccess,
    },
});
// END STEP 3
