import { IActivity, IActivityType, ISource, ITier, IUnit } from '../../../entities/emissionTypes/baseType';
import {
    IElectricAdditional,
    IElectriccUnit,
    IElectricResult,
} from '../../../entities/emissionTypes/electricCombustion';
import { IStaticDatabase } from '../../../entities/emissionTypes/staticCombustion';
import { EMISSION } from './base.action';
import { STATIC } from './static.action';

export const ELECTRIC = 'ELECTRIC';

export const CHANGE_ELECTRIC_MAIN_FACILITY = `${EMISSION} ${ELECTRIC} Change Main Facility`;
export const CHANGE_ELECTRIC_SUB_FACILITY = `${EMISSION} ${ELECTRIC} Change Sub Facility`;

export const GET_ELECTRIC_TIERS = `${EMISSION} ${ELECTRIC} Get Tiers`;
export const SET_ELECTRIC_TIERS = `${EMISSION} ${ELECTRIC} Set Tiers`;
export const SELECT_ELECTRIC_TIER = `${EMISSION} ${ELECTRIC} Select Tier`;

export const GET_ELECTRIC_DATABASES = `${EMISSION} ${ELECTRIC} Get Databases`;
export const SET_ELECTRIC_DATABASES = `${EMISSION} ${ELECTRIC} Set Databases`;
export const SELECT_ELECTRIC_DATABASE = `${EMISSION} ${ELECTRIC} Select Database`;
export const CHANGE_ELECTRIC_EQUIVALENT_FACTOR = `${EMISSION} ${ELECTRIC} Change Equivalent Factor`;

export const GET_ELECTRIC_SOURCES = `S${EMISSION} ${ELECTRIC} Get Sources`;
export const SET_ELECTRIC_SOURCES = `${EMISSION} ${ELECTRIC} Set Sources`;
export const SELECT_ELECTRIC_SOURCE = `${EMISSION} ${ELECTRIC} Select Source`;

export const GET_ELECTRIC_ACTIVITIES = `${EMISSION} ${ELECTRIC} Get Activities`;
export const SET_ELECTRIC_ACTIVITIES = `${EMISSION} ${ELECTRIC} Set Activities`;
export const SELECT_ELECTRIC_ACTIVITY = `${EMISSION} ${ELECTRIC} Select Activity`;

export const GET_ELECTRIC_ACTIVITY_TYPES = `${EMISSION} ${ELECTRIC} Get Activity Types`;
export const SET_ELECTRIC_ACTIVITY_TYPES = `${EMISSION} ${ELECTRIC} Set Activity Types`;
export const SELECT_ELECTRIC_ACTIVITY_TYPE = `${EMISSION} ${ELECTRIC} Select Activity Type`;

export const GET_ELECTRIC_UNITS = `${EMISSION} ${ELECTRIC} Get Units`;
export const SET_ELECTRIC_UNITS = `${EMISSION} ${ELECTRIC} Set Units`;
export const SELECT_ELECTRIC_UNIT = `${EMISSION} ${ELECTRIC} Select Units`;

export const GET_ELECTRIC_ADDITIONAL_CONSTANTS = `${EMISSION} ${ELECTRIC} Get Additional Constants`;
export const SET_ELECTRIC_ADDITIONAL_CONSTANTS = `${EMISSION} ${ELECTRIC} Set Additional Constants`;

export const CHANGE_ELECTRIC_YEAR = `${EMISSION} ${ELECTRIC} Change Year`;
export const CHANGE_ELECTRIC_DESCRIPTION = `${EMISSION} ${ELECTRIC} Change Description`;

export const SELECT_ELECTRIC_ACTIVITY_DATA = `${EMISSION} ${ELECTRIC} Select Activity Data`;

export const CHANGE_ELECTRIC_CONSUMPTION_AMOUNT = `${EMISSION} ${ELECTRIC} Change Consumption Amount`;
export const CLEAR_ELECTRIC_CONSUMPTION_AMOUNT = `${EMISSION} ${ELECTRIC} Clear Consumption Amount`;

export const CHANGE_ELECTRIC_TAGS = `${EMISSION} ${ELECTRIC} Change Tags`;

export const CHANGE_ELECTRIC_QUANTITY = `${EMISSION} ${ELECTRIC} Change Quantity`;
export const SUBMIT_ELECTRIC_FORM = `${EMISSION} ${ELECTRIC} Submit Form`;
export const SET_ELECTRIC_RESULT = `${EMISSION} ${ELECTRIC} Set Result`;
export const CLEAR_ELECTRIC_INPUT = `${EMISSION} ${ELECTRIC} Clear Electric Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearElectricInput = (list: Array<string>) => ({
    type: CLEAR_ELECTRIC_INPUT,
    payload: list,
});

// region ELECTRIC
export const changeElectricMainFacility = (text: string) => ({
    type: CHANGE_ELECTRIC_MAIN_FACILITY,
    payload: text,
});

export const changeElectricSubFacility = (text: string) => ({
    type: CHANGE_ELECTRIC_SUB_FACILITY,
    payload: text,
});

export const changeElectricTags = (text: string) => ({
    type: CHANGE_ELECTRIC_TAGS,
    payload: text,
});

export const getElectricTiers = () => ({
    type: GET_ELECTRIC_TIERS,
});

export const setElectricTiers = (list: Array<ITier>) => ({
    type: SET_ELECTRIC_TIERS,
    payload: list,
});

export const selectElectricTier = (tier: ITier) => ({
    type: SELECT_ELECTRIC_TIER,
    payload: tier,
});

export const getElectricDatabases = (tier: ITier) => ({
    type: GET_ELECTRIC_DATABASES,
    payload: tier,
});

export const setElectricDatabases = (list: Array<IStaticDatabase>) => ({
    type: SET_ELECTRIC_DATABASES,
    payload: list,
});

export const selectElectricDatabase = (database: IStaticDatabase) => ({
    type: SELECT_ELECTRIC_DATABASE,
    payload: database,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_ELECTRIC_EQUIVALENT_FACTOR,
    payload: value,
});

export const getElectricSources = (value: any) => ({
    type: GET_ELECTRIC_SOURCES,
    payload: value,
});

export const setElectricSources = (list: Array<ISource>) => ({
    type: SET_ELECTRIC_SOURCES,
    payload: list,
});

export const selectElectricSources = (source: ISource) => ({
    type: SELECT_ELECTRIC_SOURCE,
    payload: source,
});

export const getElectricActivity = (value: ISource) => ({
    type: GET_ELECTRIC_ACTIVITIES,
    payload: value,
});

export const setElectricActivity = (list: Array<IActivity>) => ({
    type: SET_ELECTRIC_ACTIVITIES,
    payload: list,
});

export const selectElectricActivity = (activity: IActivity) => ({
    type: SELECT_ELECTRIC_ACTIVITY,
    payload: activity,
});

export const getElectricActivityType = (value: IActivity) => ({
    type: GET_ELECTRIC_ACTIVITY_TYPES,
    payload: value,
});

export const setElectricActivityType = (list: Array<IActivityType>) => ({
    type: SET_ELECTRIC_ACTIVITY_TYPES,
    payload: list,
});

export const selectElectricActivityType = (activityType: IActivityType) => ({
    type: SELECT_ELECTRIC_ACTIVITY_TYPE,
    payload: activityType,
});

export const getElectricUnits = (value: IActivityType) => ({
    type: GET_ELECTRIC_UNITS,
    payload: value,
});

export const setElectricUnits = (list: Array<IUnit>) => ({
    type: SET_ELECTRIC_UNITS,
    payload: list,
});

export const selectElectricUnit = (unit: IUnit) => ({
    type: SELECT_ELECTRIC_UNIT,
    payload: unit,
});

export const getElectricAdditionalConstants = (additional: IElectriccUnit) => ({
    type: GET_ELECTRIC_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setElectricAdditionalConstants = (additional: Array<IElectricAdditional>) => ({
    type: SET_ELECTRIC_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeElectricYear = (value: number) => ({
    type: CHANGE_ELECTRIC_YEAR,
    payload: value,
});

export const changeElectricDescription = (text: string) => ({
    type: CHANGE_ELECTRIC_DESCRIPTION,
    payload: text,
});

export const selectElectricActivityData = (activity: string) => ({
    type: SELECT_ELECTRIC_ACTIVITY_DATA,
    payload: activity,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_ELECTRIC_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_ELECTRIC_CONSUMPTION_AMOUNT,
});

export const submitElectricForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_ELECTRIC_FORM,
    meta: {
        onSuccess,
    },
});

export const setElectricResult = (data: Array<IElectricResult>) => ({
    type: SET_ELECTRIC_RESULT,
    payload: data,
});
// endregion ELECTRIC
