import { Middleware } from 'redux';
import { ITier } from '../../../../entities/emissionTypes/baseType';
import {
    IFugitiveActivity,
    IFugitiveActivityType,
    IFugitiveDatabase,
    IFugitiveFuelType,
    IFugitiveGasGroup,
    IFugitiveResult,
    IFugitiveUnit,
} from '../../../../entities/emissionTypes/fugitiveCombustion';

import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_FUGITIVE_ACTIVITIES,
    GET_FUGITIVE_ACTIVITY_TYPES,
    GET_FUGITIVE_DATABASES,
    GET_FUGITIVE_FUEL_TYPES,
    GET_FUGITIVE_TIERS,
    GET_FUGITIVE_UNITS,
    setFugitiveActivities,
    setFugitiveActivityTypes,
    setFugitiveDatabases,
    setFugitiveFuelTypes,
    setFugitiveGasGroup,
    setFugitiveResult,
    setFugitiveTiers,
    setFugitiveUnits,
    SUBMIT_FUGITIVE_FORM,
} from '../../../actions/emission/fugitive.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const emissionFugitiveMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            // region FUGITIVE
            case GET_FUGITIVE_TIERS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/common/data/tiers',
                        feature: GET_FUGITIVE_TIERS,
                        query: {
                            emissionCategoryId: 4,
                        },
                    })
                );
                break;
            case `${GET_FUGITIVE_TIERS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<ITier>,
                        v: {
                            id: number;
                            language: string;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setFugitiveTiers(list));
                break;
            }

            case GET_FUGITIVE_DATABASES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/emissionFactorDatabases',
                        feature: GET_FUGITIVE_DATABASES,
                        query: {
                            tierId: action.payload.id,
                            emissionCategoryId: 4,
                        },
                    })
                );
                break;

            case `${GET_FUGITIVE_DATABASES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IFugitiveDatabase>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setFugitiveDatabases(list));
                break;
            }

            case GET_FUGITIVE_ACTIVITIES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'fugitiveEmission/data/emissionSources',
                        feature: GET_FUGITIVE_ACTIVITIES,
                        query: {
                            emissionFactorDatabaseId: action.payload?.id || state.emission.fugitive.databases[0]?.id,
                        },
                    })
                );
                break;

            case `${GET_FUGITIVE_ACTIVITIES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IFugitiveActivity>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            title: v.title,
                        });
                        return acc;
                    },
                    []
                );
                next(setFugitiveActivities(list));
                break;
            }

            case GET_FUGITIVE_ACTIVITY_TYPES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'fugitiveEmission/data/emissionActivities',
                        feature: GET_FUGITIVE_ACTIVITY_TYPES,
                        query: {
                            emissionSourceId: action.payload?.id,
                        },
                    })
                );
                break;

            case `${GET_FUGITIVE_ACTIVITY_TYPES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IFugitiveActivityType>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                            gasgroups: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            gasgroups: v.gasgroups,
                        });
                        return acc;
                    },
                    []
                );
                const gasGroup = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IFugitiveGasGroup>,
                        v: {
                            id: number;
                            gasgroups: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.gasgroups,
                            label: v.gasgroups,
                        });
                        return acc;
                    },
                    []
                );
                next(setFugitiveActivityTypes(list));
                next(setFugitiveGasGroup(gasGroup));
                break;
            }

            case GET_FUGITIVE_FUEL_TYPES:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'fugitiveEmission/data/emissionActivityTypes',
                        feature: GET_FUGITIVE_FUEL_TYPES,
                        query: {
                            emissionActivityId: action.payload.id,
                        },
                    })
                );
                break;

            case `${GET_FUGITIVE_FUEL_TYPES} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IFugitiveFuelType>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setFugitiveFuelTypes(list));
                break;
            }

            case GET_FUGITIVE_UNITS:
                next(
                    apiRequest({
                        method: 'GET',
                        url: 'fugitiveEmission/data/units',
                        feature: GET_FUGITIVE_UNITS,
                        query: {
                            emissionActivityTypeId: action.payload?.id || state.emission.fugitive.sourceList[0]?.id,
                        },
                    })
                );
                break;

            case `${GET_FUGITIVE_UNITS} ${API_SUCCESS}`: {
                const list = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<IFugitiveUnit>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                            arg0: string;
                            arg1?: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            arg0: v.arg0,
                            arg1: v?.arg1,
                        });
                        return acc;
                    },
                    []
                );
                next(setFugitiveUnits(list));
                break;
            }

            case SUBMIT_FUGITIVE_FORM: {
                interface IBody {
                    organizationId?: number;
                    companyId: number;
                    facilityId: number;
                    consumptionList: Array<{ arg0: number; arg1: number; arg2: number; period: string }>;
                    tierId: number;
                    emissionFactorDatabaseId: number;
                    emissionSourceId: number;
                    emissionActivityId: number;
                    emissionActivityTypeId: number;
                    emissionCategoryId: number;
                    unitId: number;
                    equivalentFactor?: number;
                    tags: string;
                    equivalentTags: string;
                    description: string;
                    activityData: string;
                }

                const body: IBody = {
                    organizationId:
                        state?.auth?.userInfo?.organization?.id ||
                        state?.auth?.userInfo?.company?.organizationId ||
                        //state?.auth?.userInfo?.user?.organizationId ||
                        null,
                    companyId: state?.emission?.base?.selectedCompany?.id,
                    facilityId: state?.emission?.base?.selectedFacility?.id,
                    consumptionList: [],
                    tierId: state.emission.fugitive.tier.id,
                    emissionFactorDatabaseId:
                        state.emission.fugitive.database?.id || state.emission.fugitive.databases[0]?.id,
                    emissionSourceId: state.emission.fugitive.activity?.id,
                    emissionActivityId: state.emission.fugitive.activityType?.id,
                    emissionActivityTypeId:
                        state.emission.fugitive.source?.id || state.emission.fugitive.sourceList[0]?.id,
                    emissionCategoryId: 4,
                    unitId: state.emission.fugitive.unit?.id,
                    equivalentFactor: state.emission.fugitive.equivalentFactor || null,
                    tags: state.emission.base.tags || '',
                    equivalentTags: state.emission.base.equivalentTags || '',
                    description: state.emission.fugitive.description,
                    activityData: state.emission.fugitive.activityData,
                };
                const consumptions = state.emission.fugitive.consumptions;
                const consumptionKeys: Array<string> = Object.keys(consumptions as { [key: number]: number | null });
                const consumptionData = consumptionKeys.reduce(
                    (acc: Array<{ arg0: number; arg1: number; arg2: number; period: string }>, key: string) => {
                        const numberKey = Number(key);
                        if (consumptions[key]['numberOfDevices'] && numberKey !== 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['numberOfDevices']),
                                arg1: Number(consumptions[key]['gasCapacity']),
                                arg2: Number(consumptions[key]['gasAmount']) || 0,
                                period: `${state?.emission?.base?.calculationYear}-${numberKey + 1 < 10 ? '0' : ''}${
                                    numberKey + 1
                                }`,
                            });
                        }
                        if (consumptions[key]['numberOfDevices'] && numberKey === 12) {
                            acc.push({
                                arg0: Number(consumptions[key]['numberOfDevices']),
                                arg1: Number(consumptions[key]['gasCapacity']),
                                arg2: Number(consumptions[key]['gasAmount']) || 0,
                                period: state?.emission?.base?.calculationYear,
                            });
                        }
                        return acc;
                    },
                    []
                );
                body.consumptionList = consumptionData;
                next(
                    apiRequest({
                        method: 'POST',
                        url: 'fugitiveEmission/calculate',
                        feature: SUBMIT_FUGITIVE_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_FUGITIVE_FORM} ${API_SUCCESS}`: {
                const data = action.payload.corporateEmissionDto.emissionList;
                const onSuccess = action.meta.onSuccess;
                const savedData: Array<IFugitiveResult> = [...data];
                next(setFugitiveResult(savedData));
                onSuccess();
                break;
            }

            case `${SUBMIT_FUGITIVE_FORM} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }

            // endregion FUGITIVE
        }
    };
