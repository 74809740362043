import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IStaticActivity,
    IStaticActivityType,
    IStaticAdditional,
    IStaticFuelType,
    IStaticUnit,
} from '../../../entities/emissionTypes/staticCombustion';
import { ISteamDatabase, ISteamResult } from '../../../entities/emissionTypes/steamCombustion';
import { EMISSION } from './base.action';
import { GET_STATIC_ADDITIONAL_CONSTANTS, SET_STATIC_ADDITIONAL_CONSTANTS, STATIC } from './static.action';

export const STEAM = 'STEAM';

export const CHANGE_STEAM_MAIN_FACILITY = `${EMISSION} ${STEAM} Change Main Facility`;
export const CHANGE_STEAM_SUB_FACILITY = `${EMISSION} ${STEAM} Change Sub Facility`;

export const GET_STEAM_TIERS = `${EMISSION} ${STEAM} Get Tiers`;
export const SET_STEAM_TIERS = `${EMISSION} ${STEAM} Set Tiers`;
export const SELECT_STEAM_TIER = `${EMISSION} ${STEAM} Select Tier`;

export const GET_STEAM_DATABASES = `${EMISSION} ${STEAM} Get Databases`;
export const SET_STEAM_DATABASES = `${EMISSION} ${STEAM} Set Databases`;
export const SELECT_STEAM_DATABASE = `${EMISSION} ${STEAM} Select Database`;
export const CHANGE_STEAM_EQUIVALENT_FACTOR = `${EMISSION} ${STEAM} Change Equivalent Factor`;

export const GET_STEAM_ACTIVITIES = `${EMISSION} ${STEAM} Get Activities`;
export const SET_STEAM_ACTIVITIES = `${EMISSION} ${STEAM} Set Activities`;
export const SELECT_STEAM_ACTIVITY = `${EMISSION} ${STEAM} Select Activity`;

export const GET_STEAM_ACTIVITY_TYPES = `${EMISSION} ${STEAM} Get Activity Types`;
export const SET_STEAM_ACTIVITY_TYPES = `${EMISSION} ${STEAM} Set Activity Types`;
export const SELECT_STEAM_ACTIVITY_TYPE = `${EMISSION} ${STEAM} Select Activity Type`;

export const GET_STEAM_FUEL_TYPES = `${EMISSION} ${STEAM} Get Fuel Types`;
export const SET_STEAM_FUEL_TYPES = `${EMISSION} ${STEAM} Set Fuel Types`;
export const SELECT_STEAM_FUEL_TYPE = `${EMISSION} ${STEAM} Select Fuel Type`;

export const GET_STEAM_UNITS = `${EMISSION} ${STEAM} Get Units`;
export const SET_STEAM_UNITS = `${EMISSION} ${STEAM} Set Units`;
export const SELECT_STEAM_UNIT = `${EMISSION} ${STEAM} Select Unit`;

export const GET_STEAM_ADDITIONAL_CONSTANTS = `${EMISSION} ${STEAM} Get Additional Constants`;
export const SET_STEAM_ADDITIONAL_CONSTANTS = `${EMISSION} ${STEAM} Set Additional Constants`;

export const CHANGE_STEAM_YEAR = `${EMISSION} ${STEAM} Change Year`;
export const CHANGE_STEAM_DESCRIPTION = `${EMISSION} ${STEAM} Change Description`;

export const SELECT_STEAM_ACTIVITY_DATA = `${EMISSION} ${STEAM} Select Activity Data`;

export const CHANGE_STEAM_CONSUMPTION_AMOUNT = `${EMISSION} ${STEAM} Change Consumption Amount`;
export const CLEAR_STEAM_CONSUMPTION_AMOUNT = `${EMISSION} ${STEAM} Clear Consumption Amount`;

export const CHANGE_STEAM_TAGS = `${EMISSION} ${STEAM} Change Tags`;

export const CHANGE_STEAM_QUANTITY = `${EMISSION} ${STEAM} Change Quantity`;
export const SUBMIT_STEAM_FORM = `${EMISSION} ${STEAM} Submit Form`;
export const SET_STEAM_RESULT = `${EMISSION} ${STEAM} Set Result`;
export const CLEAR_STEAM_INPUT = `${EMISSION} ${STEAM} Clear Steam Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearSteamInput = (list: Array<string>) => ({
    type: CLEAR_STEAM_INPUT,
    payload: list,
});

// region STEAM
export const changeSteamMainFacility = (text: string) => ({
    type: CHANGE_STEAM_MAIN_FACILITY,
    payload: text,
});

export const changeSteamSubFacility = (text: string) => ({
    type: CHANGE_STEAM_SUB_FACILITY,
    payload: text,
});

export const changeSteamTags = (text: string) => ({
    type: CHANGE_STEAM_TAGS,
    payload: text,
});

export const getSteamTiers = () => ({
    type: GET_STEAM_TIERS,
});

export const setSteamTiers = (list: Array<ITier>) => ({
    type: SET_STEAM_TIERS,
    payload: list,
});

export const selectSteamTier = (tier: ITier) => ({
    type: SELECT_STEAM_TIER,
    payload: tier,
});

export const getSteamDatabases = (tier: ITier) => ({
    type: GET_STEAM_DATABASES,
    payload: tier,
});

export const setSteamDatabases = (list: Array<ISteamDatabase>) => ({
    type: SET_STEAM_DATABASES,
    payload: list,
});

export const selectSteamDatabase = (database: ISteamDatabase) => ({
    type: SELECT_STEAM_DATABASE,
    payload: database,
});

export const getSteamActivities = (tier: ITier) => ({
    type: GET_STEAM_ACTIVITIES,
    payload: tier,
});

export const setSteamActivities = (activities: Array<IStaticActivity>) => ({
    type: SET_STEAM_ACTIVITIES,
    payload: activities,
});

export const selectSteamActivity = (activity: IStaticActivity) => ({
    type: SELECT_STEAM_ACTIVITY,
    payload: activity,
});

export const getSteamActivityTypes = (value: any) => ({
    type: GET_STEAM_ACTIVITY_TYPES,
    payload: value,
});

export const setSteamActivityTypes = (activities: Array<IStaticActivityType>) => ({
    type: SET_STEAM_ACTIVITY_TYPES,
    payload: activities,
});

export const selectSteamActivityType = (activity: IStaticActivityType) => ({
    type: SELECT_STEAM_ACTIVITY_TYPE,
    payload: activity,
});

export const getSteamFuelTypes = (tier: ITier) => ({
    type: GET_STEAM_FUEL_TYPES,
    payload: tier,
});

export const setSteamFuelTypes = (list: Array<IStaticFuelType>) => ({
    type: SET_STEAM_FUEL_TYPES,
    payload: list,
});

export const selectSteamFuelType = (database: IStaticFuelType) => ({
    type: SELECT_STEAM_FUEL_TYPE,
    payload: database,
});

export const getSteamUnits = (units: IStaticActivityType) => ({
    type: GET_STEAM_UNITS,
    payload: units,
});

export const setSteamUnits = (units: Array<IStaticUnit>) => ({
    type: SET_STEAM_UNITS,
    payload: units,
});

export const selectSteamUnit = (unit: IStaticUnit) => ({
    type: SELECT_STEAM_UNIT,
    payload: unit,
});

export const getSteamAdditionalConstants = (additional: IStaticUnit) => ({
    type: GET_STEAM_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setSteamAdditionalConstants = (additional: Array<IStaticAdditional>) => ({
    type: SET_STEAM_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_STEAM_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeSteamYear = (value: number) => ({
    type: CHANGE_STEAM_YEAR,
    payload: value,
});

export const changeSteamDescription = (text: string) => ({
    type: CHANGE_STEAM_DESCRIPTION,
    payload: text,
});

export const selectSteamActivityData = (activity: string) => ({
    type: SELECT_STEAM_ACTIVITY_DATA,
    payload: activity,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_STEAM_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_STEAM_CONSUMPTION_AMOUNT,
});

export const submitSteamForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_STEAM_FORM,
    meta: {
        onSuccess,
    },
});

export const setSteamResult = (data: Array<ISteamResult>) => ({
    type: SET_STEAM_RESULT,
    payload: data,
});
// endregion STEAM
