import Logo from '../../../assets/images/ecofoni.png';
import React from 'react';

const Loading = () => {
    return (
        <div className="loader-container">
            <img src={Logo} height="150" width="150" />
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loading;
