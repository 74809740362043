import {
    Autocomplete,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Slider,
    TextareaAutosize,
    TextField,
    Typography,
} from '@mui/material';
import React, { FC } from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { ITag } from '../../../entities/emissionTypes/baseType';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';

type IInputProps = {
    id: string;
    title: string;
    description?: string;
    type: string;
    size: number;
    value?: any;
    unit?: string;
    onChange?: (v: any) => void;
    isDisabled?: boolean;
    options?: Array<{ id: string; key: string; label: string }>;
    placeHolder?: string;
    isSmall?: boolean;
    customOptions?: Array<ITag>;
    minRows?: number;
    isCorrect?: boolean;
};
const Input: FC<IInputProps> = ({
    id,
    title,
    description,
    type,
    options = [],
    onChange,
    size,
    isDisabled,
    placeHolder,
    unit,
    value,
    isSmall = false,
    customOptions,
    minRows = 1,
    isCorrect = false,
}) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'common' });

    const changeSelect = (k: any, v: any) => {
        if (onChange) onChange(v);
    };

    const changeCustomSelect = (e: any) => {
        if (onChange) onChange(e.target.value);
    };

    const changeText = (e: any) => {
        if (onChange) onChange(e.target.value);
    };

    const changeNumber = (v: any) => {
        if (onChange) onChange(v);
    };

    const changeRange = (e: any) => {
        if (onChange) onChange(e.target.value);
    };

    const changeDate = (v: any) => {
        if (onChange) onChange(v);
    };

    switch (type) {
        case 'select':
            return (
                <Grid item xs={size}>
                    <Autocomplete
                        id={id}
                        options={options?.sort((a: any, b: any) => a?.id - b?.id)}
                        groupBy={(options: any) => options?.type && t(options?.type?.toLowerCase())}
                        value={value}
                        isOptionEqualToValue={(option, value) => option?.label === value?.label}
                        renderInput={(params) => (
                            <TextField {...params} name={id} label={title} helperText={description} />
                        )}
                        loading
                        noOptionsText={t('calculationForms.optionNotFound')}
                        loadingText={t('calculationForms.loading')}
                        onChange={changeSelect}
                        disabled={isDisabled}
                        placeholder={placeHolder}
                        disableClearable
                        size={isSmall ? 'small' : 'medium'}
                    />
                </Grid>
            );
        case 'select-facility':
            return (
                <Grid item xs={size}>
                    <Autocomplete
                        id={id}
                        options={options}
                        groupBy={(options: any) => options?.type && t(options?.type?.toLowerCase())}
                        value={value}
                        isOptionEqualToValue={(option, value) => option?.label === value?.label}
                        renderInput={(params) => (
                            <TextField {...params} name={id} label={title} helperText={description} />
                        )}
                        loading
                        noOptionsText={t('calculationForms.optionNotFound')}
                        loadingText={t('calculationForms.loading')}
                        onChange={changeSelect}
                        disabled={isDisabled}
                        placeholder={placeHolder}
                        disableClearable
                        size={isSmall ? 'small' : 'medium'}
                    />
                </Grid>
            );
        case 'select&text':
            return <Grid item xs={size}></Grid>;
        case 'text':
            return (
                <Grid item xs={size}>
                    <TextField
                        id={id}
                        name={id}
                        value={value}
                        sx={{ width: '100%' }}
                        label={title}
                        helperText={description}
                        disabled={isDisabled}
                        placeholder={placeHolder}
                        onChange={(e) => changeText(e)}
                        size={isSmall ? 'small' : 'medium'}
                        error={isCorrect}
                    />
                </Grid>
            );
        case 'textbox':
            return (
                <Grid item xs={size}>
                    <TextField
                        fullWidth
                        multiline
                        maxRows={4}
                        minRows={minRows}
                        placeholder={placeHolder}
                        inputProps={{
                            inputComponent: TextareaAutosize,
                            maxLength: 2000,
                        }}
                        value={value}
                        onChange={(e) => changeText(e)}
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                    />
                </Grid>
            );
        case 'number':
            return (
                <Grid item xs={size}>
                    <TextField
                        id={id}
                        name={id}
                        sx={{ width: '100%' }}
                        label={title}
                        helperText={description}
                        placeholder={placeHolder}
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            inputProps: {
                                prefix: `${unit ? unit + '  ' : ''}`,
                                value: value,
                            },
                        }}
                        disabled={isDisabled}
                        onChange={(e) => changeNumber(e.target.value)}
                    />
                </Grid>
            );
        case 'custom-radio':
            return (
                <Grid item xs={size}>
                    <Typography variant="h5">{title}</Typography>
                    <FormControl onChange={(e) => changeCustomSelect(e)}>
                        <FormLabel>{description}</FormLabel>
                        <RadioGroup name="radio-buttons-group" sx={{ marginTop: '30px' }}>
                            <FormControlLabel
                                value="PRIMARY_ACTIVITY"
                                control={<Radio />}
                                label={t('calculationForms.activityDataPrimary')}
                            />
                            <Typography variant="caption">
                                {t('calculationForms.activityDataPrimaryDescription')}
                            </Typography>

                            <FormControlLabel
                                value="SECONDARY_ACTIVITY"
                                control={<Radio />}
                                label={t('calculationForms.activityDataSecondary')}
                            />
                            <Typography variant="caption">
                                {t('calculationForms.activityDataSecondaryDescription')}
                            </Typography>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            );
        case 'range':
            return (
                <Grid item xs={size} m={1}>
                    <Typography gutterBottom>{title}</Typography>
                    <Slider
                        onChange={(e) => changeRange(e)}
                        valueLabelDisplay="auto"
                        aria-label="custom thumb label"
                        value={value}
                    />
                </Grid>
            );
        case 'date-year':
            return (
                <Grid item xs={size}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['year']}
                            label={title}
                            value={value}
                            onChange={(e) => changeDate(e?.year() + '')}
                            renderInput={(params: any) => (
                                <TextField {...params} label={title} sx={{ width: '100%' }} size={isSmall} />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
            );
        case 'date-full':
            return (
                <Grid item xs={size}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['year', 'month', 'day']}
                            label={title}
                            value={value}
                            onChange={(e) => {
                                changeDate(e);
                            }}
                            renderInput={(params: any) => (
                                <TextField {...params} label={title} sx={{ width: '100%' }} size={isSmall} />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
            );
        default:
            return <div>Default</div>;
    }
};

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    prefix: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(function NumberFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            prefix={props.prefix}
            thousandSeparator={'.'}
            decimalSeparator={','}
            isNumericString
        />
    );
});

export default Input;
