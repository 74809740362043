import { ITier } from '../../../entities/emissionTypes/baseType';
import {
    IProductLifetimeActivity,
    IProductLifetimeActivityType,
    IProductLifetimeAdditional,
    IProductLifetimeDatabase,
    IProductLifetimeFuelType,
    IProductLifetimeResult,
    IProductLifetimeSize,
    IProductLifetimeUnit,
} from '../../../entities/emissionTypes/productLifetimeCombustion';
import { EMISSION } from './base.action';

export const PRODUCT_LIFETIME = 'PRODUCT_LIFETIME';

////-- Base
export const CHANGE_PRODUCT_LIFETIME_MAIN_FACILITY = `${EMISSION} ${PRODUCT_LIFETIME} Change Main Facility`;
export const CHANGE_PRODUCT_LIFETIME_SUB_FACILITY = `${EMISSION} ${PRODUCT_LIFETIME} Change Sub Facility`;

export const GET_PRODUCT_LIFETIME_TIERS = `${EMISSION} ${PRODUCT_LIFETIME} Get Tiers`;
export const SET_PRODUCT_LIFETIME_TIERS = `${EMISSION} ${PRODUCT_LIFETIME} Set Tiers`;
export const SELECT_PRODUCT_LIFETIME_TIER = `${EMISSION} ${PRODUCT_LIFETIME} Select Tier`;

export const GET_PRODUCT_LIFETIME_DATABASES = `${EMISSION} ${PRODUCT_LIFETIME} Get Databases`;
export const SET_PRODUCT_LIFETIME_DATABASES = `${EMISSION} ${PRODUCT_LIFETIME} Set Databases`;
export const SELECT_PRODUCT_LIFETIME_DATABASE = `${EMISSION} ${PRODUCT_LIFETIME} Select Database`;

export const CHANGE_PRODUCT_LIFETIME_EQUIVALENT_FACTOR = `${EMISSION} ${PRODUCT_LIFETIME} Change Equivalent Factor`;

export const GET_PRODUCT_LIFETIME_ACTIVITIES = `${EMISSION} ${PRODUCT_LIFETIME} Get Activities`;
export const SET_PRODUCT_LIFETIME_ACTIVITIES = `${EMISSION} ${PRODUCT_LIFETIME} Set Activities`;
export const SELECT_PRODUCT_LIFETIME_ACTIVITY = `${EMISSION} ${PRODUCT_LIFETIME} Select Activity`;
/////--- End base

export const GET_PRODUCT_LIFETIME_FUEL_TYPES = `${EMISSION} ${PRODUCT_LIFETIME} Get Fuel Types`;
export const SET_PRODUCT_LIFETIME_FUEL_TYPES = `${EMISSION} ${PRODUCT_LIFETIME} Set Fuel Types`;
export const SELECT_PRODUCT_LIFETIME_FUEL_TYPE = `${EMISSION} ${PRODUCT_LIFETIME} Select Fuel Type`;

export const GET_PRODUCT_LIFETIME_ACTIVITY_TYPES = `${EMISSION} ${PRODUCT_LIFETIME} Get Activity Types`;
export const SET_PRODUCT_LIFETIME_ACTIVITY_TYPES = `${EMISSION} ${PRODUCT_LIFETIME} Set Activity Types`;
export const SELECT_PRODUCT_LIFETIME_ACTIVITY_TYPE = `${EMISSION} ${PRODUCT_LIFETIME} Select Activity Type`;

export const GET_PRODUCT_LIFETIME_UNITS = `${EMISSION} ${PRODUCT_LIFETIME} Get Units`;
export const SET_PRODUCT_LIFETIME_UNITS = `${EMISSION} ${PRODUCT_LIFETIME} Set Units`;
export const SELECT_PRODUCT_LIFETIME_UNIT = `${EMISSION} ${PRODUCT_LIFETIME} Select Unit`;

export const GET_PRODUCT_LIFETIME_ADDITIONAL_CONSTANTS = `${EMISSION} ${PRODUCT_LIFETIME} Get Additional Constants`;
export const SET_PRODUCT_LIFETIME_ADDITIONAL_CONSTANTS = `${EMISSION} ${PRODUCT_LIFETIME} Set Additional Constants`;

export const CHANGE_PRODUCT_LIFETIME_WEIGHT = `${EMISSION} ${PRODUCT_LIFETIME} Change Weight`;
export const CHANGE_PRODUCT_LIFETIME_DISTANCE = `${EMISSION} ${PRODUCT_LIFETIME} Change Distance`;

export const GET_PRODUCT_LIFETIME_LADEN_PERCENTAGES = `${EMISSION} ${PRODUCT_LIFETIME} Get Laden Percentages`;
export const SET_PRODUCT_LIFETIME_LADEN_PERCENTAGES = `${EMISSION} ${PRODUCT_LIFETIME} Set Laden Percentages`;
export const SELECT_PRODUCT_LIFETIME_LADEN_PERCENTAGE = `${EMISSION} ${PRODUCT_LIFETIME} Select Laden percentage`;

export const GET_PRODUCT_LIFETIME_SIZES = `${EMISSION} ${PRODUCT_LIFETIME} Get Sizes`;
export const SET_PRODUCT_LIFETIME_SIZES = `${EMISSION} ${PRODUCT_LIFETIME} Set Sizes`;
export const SELECT_PRODUCT_LIFETIME_SIZE = `${EMISSION} ${PRODUCT_LIFETIME} Select Size`;

////-- Base
export const CHANGE_PRODUCT_LIFETIME_YEAR = `${EMISSION} ${PRODUCT_LIFETIME} Change Year`;
export const SELECT_PRODUCT_LIFETIME_ACTIVITY_DATA = `${EMISSION} ${PRODUCT_LIFETIME} Select Activity Data`;
export const CHANGE_PRODUCT_LIFETIME_CONSUMPTION_AMOUNT = `${EMISSION} ${PRODUCT_LIFETIME} Change Consumption Amount`;
export const CHANGE_PRODUCT_LIFETIME_DESCRIPTION = `${EMISSION} ${PRODUCT_LIFETIME} Change Description`;

export const SUBMIT_PRODUCT_LIFETIME_FORM = `${EMISSION} ${PRODUCT_LIFETIME} Submit Form`;
export const SET_PRODUCT_LIFETIME_RESULT = `${EMISSION} ${PRODUCT_LIFETIME} Set Result`;
/////-- End Base
export const CLEAR_PRODUCT_LIFETIME_CONSUMPTION_AMOUNT = `${EMISSION} ${PRODUCT_LIFETIME} Clear Consumption Amount`;
export const CLEAR_PRODUCT_LIFETIME_INPUT = `${EMISSION} ${PRODUCT_LIFETIME} Clear Product Lifetime Input`;

export type ISubmit = {
    onSuccess: () => void;
};

export const clearProductLifetimeInput = (list: Array<string>) => ({
    type: CLEAR_PRODUCT_LIFETIME_INPUT,
    payload: list,
});
/// Region ProductLifetime
export const changeProductLifetimeMainFacility = (text: string) => ({
    type: CHANGE_PRODUCT_LIFETIME_MAIN_FACILITY,
    payload: text,
});

export const changeProductLifetimeSubFacility = (text: string) => {
    return {
        type: CHANGE_PRODUCT_LIFETIME_SUB_FACILITY,
        payload: text,
    };
};

export const getProductLifetimeTiers = () => ({
    type: GET_PRODUCT_LIFETIME_TIERS,
});

export const setProductLifetimeTiers = (list: Array<ITier>) => ({
    type: SET_PRODUCT_LIFETIME_TIERS,
    payload: list,
});

export const selectProductLifetimeTier = (tier: ITier) => ({
    type: SELECT_PRODUCT_LIFETIME_TIER,
    payload: tier,
});

export const getProductLifetimeDatabases = (tier: ITier) => ({
    type: GET_PRODUCT_LIFETIME_DATABASES,
    payload: tier,
});

export const setProductLifetimeDatabases = (list: Array<IProductLifetimeDatabase>) => ({
    type: SET_PRODUCT_LIFETIME_DATABASES,
    payload: list,
});

export const selectProductLifetimeDatabase = (database: IProductLifetimeDatabase) => ({
    type: SELECT_PRODUCT_LIFETIME_DATABASE,
    payload: database,
});

export const getProductLifetimeActivities = (tier: ITier) => ({
    type: GET_PRODUCT_LIFETIME_ACTIVITIES,
    payload: tier,
});

export const setProductLifetimeActivities = (activities: Array<IProductLifetimeActivity>) => ({
    type: SET_PRODUCT_LIFETIME_ACTIVITIES,
    payload: activities,
});

export const selectProductLifetimeActivity = (activity: IProductLifetimeActivity) => ({
    type: SELECT_PRODUCT_LIFETIME_ACTIVITY,
    payload: activity,
});

///////
export const getProductLifetimeFuelTypes = (tier: ITier) => ({
    type: GET_PRODUCT_LIFETIME_FUEL_TYPES,
    payload: tier,
});

export const setProductLifetimeFuelTypes = (list: Array<IProductLifetimeFuelType>) => ({
    type: SET_PRODUCT_LIFETIME_FUEL_TYPES,
    payload: list,
});

export const selectProductLifetimeFuelType = (database: IProductLifetimeFuelType) => ({
    type: SELECT_PRODUCT_LIFETIME_FUEL_TYPE,
    payload: database,
});

export const getProductLifetimeActivityTypes = (value: any) => ({
    type: GET_PRODUCT_LIFETIME_ACTIVITY_TYPES,
    payload: value,
});

export const setProductLifetimeActivityTypes = (activities: Array<IProductLifetimeActivityType>) => ({
    type: SET_PRODUCT_LIFETIME_ACTIVITY_TYPES,
    payload: activities,
});

export const selectProductLifetimeActivityType = (activity: IProductLifetimeActivityType) => ({
    type: SELECT_PRODUCT_LIFETIME_ACTIVITY_TYPE,
    payload: activity,
});

export const getProductLifetimeUnits = (units: IProductLifetimeActivityType) => ({
    type: GET_PRODUCT_LIFETIME_UNITS,
    payload: units,
});

export const setProductLifetimeUnits = (units: Array<IProductLifetimeUnit>) => ({
    type: SET_PRODUCT_LIFETIME_UNITS,
    payload: units,
});

export const selectProductLifetimeUnit = (unit: IProductLifetimeUnit) => ({
    type: SELECT_PRODUCT_LIFETIME_UNIT,
    payload: unit,
});

export const getProductLifetimeAdditionalConstants = (additional: IProductLifetimeUnit) => ({
    type: GET_PRODUCT_LIFETIME_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const setProductLifetimeAdditionalConstants = (additional: Array<IProductLifetimeAdditional>) => ({
    type: SET_PRODUCT_LIFETIME_ADDITIONAL_CONSTANTS,
    payload: additional,
});

export const changeProductLifetimeWeight = (value: number) => ({
    type: CHANGE_PRODUCT_LIFETIME_WEIGHT,
    payload: value,
});

export const changeProductLifetimeDistance = (value: number) => ({
    type: CHANGE_PRODUCT_LIFETIME_DISTANCE,
    payload: value,
});

export const getProductLifetimeLadenPercentages = (ladens: any) => ({
    type: GET_PRODUCT_LIFETIME_LADEN_PERCENTAGES,
    payload: ladens,
});

export const setProductLifetimeLadenPercentages = (ladens: Array<any>) => ({
    type: SET_PRODUCT_LIFETIME_LADEN_PERCENTAGES,
    payload: ladens,
});

export const selectProductLifetimeLadenPercentage = (laden: any) => ({
    type: SELECT_PRODUCT_LIFETIME_LADEN_PERCENTAGE,
    payload: laden,
});

export const getProductLifetimeSizes = (sizes: IProductLifetimeActivityType) => ({
    type: GET_PRODUCT_LIFETIME_SIZES,
    payload: sizes,
});

export const setProductLifetimeSizes = (sizes: Array<IProductLifetimeSize>) => ({
    type: SET_PRODUCT_LIFETIME_SIZES,
    payload: sizes,
});

export const selectProductLifetimeSize = (size: IProductLifetimeSize) => ({
    type: SELECT_PRODUCT_LIFETIME_SIZE,
    payload: size,
});

///////
export const submitProductLifetimeForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_PRODUCT_LIFETIME_FORM,
    meta: {
        onSuccess,
    },
});

export const setProductLifetimeResult = (data: Array<IProductLifetimeResult>) => ({
    type: SET_PRODUCT_LIFETIME_RESULT,
    payload: data,
});

export const selectProductLifetimeActivityData = (activity: string) => ({
    type: SELECT_PRODUCT_LIFETIME_ACTIVITY_DATA,
    payload: activity,
});

export const changeProductLifetimeYear = (value: number) => ({
    type: CHANGE_PRODUCT_LIFETIME_YEAR,
    payload: value,
});

export const changeProductLifetimeDescription = (text: string) => ({
    type: CHANGE_PRODUCT_LIFETIME_DESCRIPTION,
    payload: text,
});

export const changeEquivalentFactor = (value: number) => ({
    type: CHANGE_PRODUCT_LIFETIME_EQUIVALENT_FACTOR,
    payload: value,
});

export const changeConsumptionAmount = (consumption: { value: number | null; month: number; key: string }) => ({
    type: CHANGE_PRODUCT_LIFETIME_CONSUMPTION_AMOUNT,
    payload: consumption,
});

export const clearConsumptionAmount = () => ({
    type: CLEAR_PRODUCT_LIFETIME_CONSUMPTION_AMOUNT,
});
/// End Region ProductLifetime
