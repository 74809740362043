import { Middleware } from 'redux';
import { apiRequest, API_SUCCESS } from '../../../actions/api.action';

import { GET_GENERAL_RESULT, setGeneralResult, SUBMIT_GENERAL_FORM } from '../../../actions/company/general.action';

export const generalMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_GENERAL_RESULT: {
                interface IBody {}
                const body: IBody = {};
                next(
                    apiRequest({
                        method: 'GET',
                        url: `company/detail/${action.payload}`,
                        feature: GET_GENERAL_RESULT,
                        body,
                    })
                );
                break;
            }

            case `${GET_GENERAL_RESULT} ${API_SUCCESS}`: {
                const data = action.payload.companyDetail;
                const onSuccess = action.meta.onSuccess;
                next(setGeneralResult(data));
                onSuccess();
                break;
            }

            case SUBMIT_GENERAL_FORM: {
                const companyId = state.auth.userInfo?.company?.id;
                interface IBody {
                    companyId: number;
                    introduction: string;
                    purposeScopeObjective: string;
                    descriptionReportingOrganization: string;
                    entityResposibleForReport: string;
                    audienceAndDisseminationPolicies: string;
                    reportingPeriod: string;
                    baseYear: string;
                    reportingStandards: string;
                    ghgSustainabilityPolicy: string;
                }
                const body: IBody = {
                    companyId: companyId,
                    introduction: state.company.general.introduction,
                    purposeScopeObjective: state.company.general.purposeScopeObjective,
                    descriptionReportingOrganization: state.company.general.descriptionReportingOrganization,
                    entityResposibleForReport: state.company.general.entityResposibleForReport,
                    audienceAndDisseminationPolicies: state.company.general.audienceAndDisseminationPolicies,
                    reportingPeriod: state.company.general.reportingPeriod,
                    baseYear: state.company.general.baseYear,
                    reportingStandards: state.company.general.reportingStandards,
                    ghgSustainabilityPolicy: state.company.general.ghgSustainabilityPolicy,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: 'company/detail/add',
                        feature: SUBMIT_GENERAL_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_GENERAL_FORM} ${API_SUCCESS}`: {
                const data = action.payload.companyDetail;
                const onSuccess = action.meta.onSuccess;
                dispatch(setGeneralResult(data));
                onSuccess();
                break;
            }
        }
    };
