import { IMobileFuelType, IMobileResult } from '../../entities/emissionTypes/mobileCombustion';
import { IStaticFuelType, IStaticResult } from '../../entities/emissionTypes/staticCombustion';
import { IUpstreamActivity } from '../../entities/emissionTypes/upstreamCombustion';

export const EMISSION = '[Emission]';
export const STATIC = 'STATIC';
export const MOBILE = 'MOBILE';
export const ELECTRIC = 'ELECTRIC';
export const STEAM = 'STEAM';
export const LEAK = 'LEAK';
export const UPSTREAM = 'UPSTREAM';

export const SELECT_TYPE = `${EMISSION} Select Type`;

// region MOBILE
export const GET_MOBILE_SECTORS = `${EMISSION} ${MOBILE} Get Sectors`;
export const SET_MOBILE_SECTORS = `${EMISSION} ${MOBILE} Set Sectors`;
export const SELECT_MOBILE_SECTOR = `${EMISSION} ${MOBILE} Select Sector`;

export const GET_MOBILE_FUEL_TYPES = `${EMISSION} ${MOBILE} Get Fuel Types`;
export const SET_MOBILE_FUEL_TYPES = `${EMISSION} ${MOBILE} Set Fuel Types`;
export const SELECT_MOBILE_FUEL_TYPE = `${EMISSION} ${MOBILE} Select Fuel Type`;

export const CHANGE_MOBILE_QUANTITY = `${EMISSION} ${MOBILE} Change Quantity`;

export const SUBMIT_MOBILE_FORM = `${EMISSION} ${MOBILE} Submit Form`;
export const SET_MOBILE_RESULT = `${EMISSION} ${MOBILE} Set Result`;
// endregion MOBILE

// region ELECTRIC

// endregion ELECTRIC

// region STEAM
export const CHANGE_STEAM_QUANTITY = `${EMISSION} ${STEAM} Change Quantity`;

export const SUBMIT_STEAM_FORM = `${EMISSION} ${STEAM} Submit Form`;
export const SET_STEAM_RESULT = `${EMISSION} ${STEAM} Set Result`;

// endregion STEAM

// region STATIC
export const GET_LEAK_SOURCES = `${EMISSION} ${LEAK} Get Sources`;
export const SET_LEAK_SOURCES = `${EMISSION} ${LEAK} Set Sources`;
export const SELECT_LEAK_SOURCE = `${EMISSION} ${LEAK} Select Source`;

export const GET_LEAK_GASSES = `${EMISSION} ${LEAK} Get Gasses`;
export const SET_LEAK_GASSES = `${EMISSION} ${LEAK} Set Gasses`;
export const SELECT_LEAK_GAS = `${EMISSION} ${LEAK} Select Gas`;

export const SELECT_LEAK_FILLING_STATUS = `${EMISSION} ${LEAK} Select Filling Status`;
export const CHANGE_LEAK_QUANTITY = `${EMISSION} ${LEAK} Change Quantity`;
export const CHANGE_LEAK_DEVICE_QUANTITY = `${EMISSION} ${LEAK} Change Device Quantity`;

export const SUBMIT_LEAK_FORM = `${EMISSION} ${LEAK} Submit Form`;
export const SET_LEAK_RESULT = `${EMISSION} ${LEAK} Set Result`;
// endregion STATIC

// region UPSTREAM
export const GET_UPSTREAM_ACTIVITIES = `${EMISSION} ${UPSTREAM} Get Activities`;
export const SET_UPSTREAM_ACTIVITIES = `${EMISSION} ${UPSTREAM} Set Activities`;
export const SELECT_UPSTREAM_ACTIVITY = `${EMISSION} ${UPSTREAM} Select Activity`;
// endregion UPSTREAM

export type ISubmit = {
    onSuccess: () => void;
};

export const selectType = (id: string) => ({
    type: SELECT_TYPE,
    payload: id,
});

// region MOBILE
export const getMobileSectors = () => ({
    type: GET_MOBILE_SECTORS,
});

export const getMobileFuelTypes = () => ({
    type: GET_MOBILE_FUEL_TYPES,
});

export const setMobileFuelTypes = (list: Array<IMobileFuelType>) => ({
    type: SET_MOBILE_FUEL_TYPES,
    payload: list,
});

export const selectMobileFuelType = (sector: IMobileFuelType) => ({
    type: SELECT_MOBILE_FUEL_TYPE,
    payload: sector,
});

export const changeMobileQuantity = (value: number) => ({
    type: CHANGE_MOBILE_QUANTITY,
    payload: value,
});

export const submitMobileForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_MOBILE_FORM,
    meta: {
        onSuccess,
    },
});

export const setMobileResult = (data: IMobileResult) => ({
    type: SET_MOBILE_RESULT,
    payload: data,
});
// endregion MOBILE

// region STEAM
export const changeSteamQuantity = (value: number) => ({
    type: CHANGE_STEAM_QUANTITY,
    payload: value,
});

export const submitSteamForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_STEAM_FORM,
    meta: {
        onSuccess,
    },
});

export const setSteamResult = (data: IMobileResult) => ({
    type: SET_STEAM_RESULT,
    payload: data,
});
// endregion STEAM

// region LEAK
export const getLeakSources = () => ({
    type: GET_LEAK_SOURCES,
});

export const getLeakGasses = () => ({
    type: GET_LEAK_GASSES,
});

export const setLeakGasses = (list: Array<IStaticFuelType>) => ({
    type: SET_LEAK_GASSES,
    payload: list,
});

export const selectLeakGas = (sector: IStaticFuelType) => ({
    type: SELECT_LEAK_GAS,
    payload: sector,
});

export const selectLeakFillingStatus = (status: IStaticFuelType) => ({
    type: SELECT_LEAK_FILLING_STATUS,
    payload: status,
});

export const changeLeakQuantity = (value: number) => ({
    type: CHANGE_LEAK_QUANTITY,
    payload: value,
});

export const changeLeakDeviceQuantity = (value: number) => ({
    type: CHANGE_LEAK_DEVICE_QUANTITY,
    payload: value,
});

export const submitLeakForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_LEAK_FORM,
    meta: {
        onSuccess,
    },
});

export const setLeakResult = (data: IStaticResult) => ({
    type: SET_LEAK_RESULT,
    payload: data,
});
// endregion LEAK

// region UPSTREAM
export const getUpstreamActivities = () => ({
    type: GET_UPSTREAM_ACTIVITIES,
});

export const setUpstreamActivities = (list: Array<IUpstreamActivity>) => ({
    type: SET_UPSTREAM_ACTIVITIES,
    payload: list,
});

export const selectUpstreamActivity = (activity: IUpstreamActivity) => ({
    type: SELECT_UPSTREAM_ACTIVITY,
    payload: activity,
});
// endregion UPSTREAM
