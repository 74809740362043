import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_INTERNAL_ANALYTIC,
    GET_INTERNAL_RESULT,
    setInternalAnalytic,
    setInternalResult,
    SUBMIT_INTERNAL_FORM,
} from '../../../actions/company/internal.action';
import { createNotification } from '../../../actions/ui.action';

export const internalMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_INTERNAL_RESULT: {
                const companyId = state.auth.userInfo?.company?.id;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/company/internal/performance/${companyId}`,
                        feature: GET_INTERNAL_RESULT,
                    })
                );
                break;
            }

            case `${GET_INTERNAL_RESULT} ${API_SUCCESS}`: {
                const data = action.payload.companyInternalPerformance;
                next(setInternalResult(data));
                break;
            }

            case GET_INTERNAL_ANALYTIC: {
                const companyId = state.auth.userInfo?.company?.id;
                const facilityIdList =
                    state.company.organizational?.selectedFacilities?.map((item: any) => item?.id) || [];

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporateEmission/metrics/internalPerformance/facility',
                        feature: GET_INTERNAL_ANALYTIC,
                        body: {
                            companyIdList: [companyId],
                            facilityIdList,
                            period: {
                                periodStart: state.company.company.period.start,
                                periodEnd: state.company.company.period.end,
                                includeYearlyValues: true,
                                onlyYearlyValues: false,
                            },
                        },
                    })
                );
                break;
            }

            case `${GET_INTERNAL_ANALYTIC} ${API_SUCCESS}`: {
                const data = action.payload.internalPerformanceMetrics;
                next(setInternalAnalytic(data));
                break;
            }

            // TODO Kullanıcı Organizasyonel Sekmesini güncellemeli
            case `${GET_INTERNAL_ANALYTIC} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message,
                    })
                );
                break;
            }

            case SUBMIT_INTERNAL_FORM: {
                const companyId = state.auth.userInfo?.company?.id;

                interface IBody {
                    companyId: number;
                    internalPerformanceReport: string;
                }

                const body: IBody = {
                    companyId,
                    internalPerformanceReport: state.company.internal.internalPerformanceReport,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/company/internal/performance/add',
                        feature: SUBMIT_INTERNAL_FORM,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }

            case `${SUBMIT_INTERNAL_FORM} ${API_SUCCESS}`: {
                const data = action.payload.companyInternalPerformance;
                next(setInternalResult(data));
                action.meta.onSuccess();
                break;
            }
        }
    };
