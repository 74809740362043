import React, { FC, useEffect } from 'react';
import { Alert, AlertTitle, AlertColor } from '@mui/material';
import { useAppDispatch } from '../../../redux/hooks';
import { removeNotification } from '../../../redux/actions/ui.action';

interface INotificationElement {
    title?: string;
    message: string;
    type: AlertColor;
    time: number;
    id: string;
}
const NotificationElement: FC<INotificationElement> = ({ title, message, type, time, id }) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        setTimeout(function () {
            dispatch(removeNotification(id));
        }, time);
    }, [id, time]);

    return (
        <Alert severity={type} variant={'filled'} sx={{ width: '100%' }}>
            {title ? <AlertTitle>{title}</AlertTitle> : null}
            {message}
        </Alert>
    );
};

export default NotificationElement;
