export const UI = '[UI]';
export const CREATE_NOTIFICATION = `${UI} Create Notification`;
export const REMOVE_NOTIFICATION = `${UI} Remove Notification`;
export const CHANGE_THEME_MODE = `${UI} Change Theme Mode`;

type ICreateNotification = {
    title?: string;
    message: string;
    type: string;
    time?: number;
};

export const createNotification = (payload: ICreateNotification) => ({
    type: `${CREATE_NOTIFICATION}`,
    payload: payload,
});

export const removeNotification = (payload: string) => ({
    type: `${REMOVE_NOTIFICATION}`,
    payload: payload,
});

export const changeThemeMode = () => ({
    type: `${CHANGE_THEME_MODE}`,
});
