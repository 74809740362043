import { AnyAction } from 'redux';
import { CHANGE_RESET_CODE, CHANGE_RESET_EMAIL, CHANGE_RESET_PASSWORD } from '../../actions/auth/resetPassword.action';

const initialState = {
    checkEmail: {
        email: '',
        isLoading: false,
    },
    checkCode: {
        code: '',
        isLoading: false,
    },
    confirmPassword: {
        password: '',
    },
};

export const resetPasswordReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case CHANGE_RESET_EMAIL:
            return {
                ...state,
                checkEmail: {
                    ...state.checkEmail,
                    email: action.payload,
                },
            };

        case CHANGE_RESET_CODE:
            return {
                ...state,
                checkCode: {
                    ...state.checkCode,
                    code: action.payload,
                },
            };
        case CHANGE_RESET_PASSWORD:
            return {
                ...state,
                confirmPassword: {
                    ...state.confirmPassword,
                    password: action.payload,
                },
            };
        default:
            return state;
    }
};
