import { AnyAction } from 'redux';
import { AlertColor } from '@mui/material';
import { CHANGE_THEME_MODE, CREATE_NOTIFICATION, REMOVE_NOTIFICATION } from '../../actions/ui.action';

interface IInitialState {
    themeMode: string;
    notifications: { [key: string]: { key: string; title?: string; message: string; type: AlertColor; time: number } };
}
const DEFAULT_NOTIFICATION_TIME = 3000;
const initialState: IInitialState = {
    themeMode: 'light',
    notifications: {},
};

export const uiReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case CREATE_NOTIFICATION:
            const notificationKey = (Math.random() + 1).toString(36).substring(7);
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [notificationKey]: {
                        key: notificationKey,
                        title: action.payload?.title,
                        message: action.payload?.message,
                        type: action.payload?.type,
                        time: action.payload?.time || DEFAULT_NOTIFICATION_TIME,
                    },
                },
            };
        case REMOVE_NOTIFICATION:
            const notifCopy = { ...state.notifications };
            delete notifCopy[action.payload];
            return {
                ...state,
                notifications: notifCopy,
            };
        case CHANGE_THEME_MODE:
            console.log(state.themeMode);
            return {
                ...state,
                themeMode: state.themeMode === 'light' ? 'dark' : 'light',
            };
        default:
            return state;
    }
};
