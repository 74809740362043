import { Middleware } from 'redux';
import { ICountry, ISector, IState } from '../../../../entities/company/companyCombustion';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    DELETE_COMPANY,
    GENERATE_COMPANY_REPORT,
    GET_COMPANY_CITY,
    GET_COMPANY_COUNTRY,
    GET_COMPANY_DASHBOARD_DATA,
    GET_COMPANY_ID,
    GET_COMPANY_IMAGE,
    GET_COMPANY_RESULT,
    GET_COMPANY_SECTOR,
    GET_COMPANY_USER_ID,
    getCompanyId,
    getCompanyResult,
    setCompanyCity,
    setCompanyCountry,
    setCompanyDashboardData,
    setCompanyId,
    setCompanyImage,
    setCompanyResult,
    setCompanySector,
    setCompanyUserId,
    SUBMIT_COMPANY_CREATE,
    SUBMIT_COMPANY_FORM,
    UPDATE_COMPANY,
    UPLOAD_COMPANY_IMAGE,
} from '../../../actions/company/company.action';
import { createNotification } from '../../../actions/ui.action';
import { getCompanyList } from '../../../actions/company/facility.action';
import i18next from 'i18next';
import { Cities, Countries } from '../../../../data/countries';

export const companyMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GENERATE_COMPANY_REPORT: {
                const id = state.auth.userInfo?.user?.companyId;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/company/report/generate?companyId=${id}`,
                        feature: GENERATE_COMPANY_REPORT,
                        responseType: 'blob',
                    })
                );
                break;
            }
            case `${GENERATE_COMPANY_REPORT} ${API_SUCCESS}`: {
                const href = URL.createObjectURL(action.payload);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `report.docx`); //or any other extension
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);

                dispatch(
                    createNotification({
                        type: 'success',
                        message: i18next.t('notification.documentDownload'),
                        time: 3000,
                    })
                );
                break;
            }
            case DELETE_COMPANY: {
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: `/company/${action.payload}`,
                        feature: DELETE_COMPANY,
                    })
                );
                break;
            }
            case `${DELETE_COMPANY} ${API_SUCCESS}`: {
                dispatch(getCompanyList());
                const { message } = action.payload;
                dispatch(
                    createNotification({
                        type: 'success',
                        message: message || i18next.t('notification.successDeleted'),
                        time: 3000,
                    })
                );
                break;
            }

            case `${DELETE_COMPANY} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case UPDATE_COMPANY: {
                interface IBody {
                    id: number;
                    companyName: string;
                    countryId: number;
                    stateId: number;
                    address: string;
                    phone: number;
                    email: string;
                    facilityLimit: number;
                    websiteUrl: string;
                }

                const body: IBody = {
                    id: action.payload || 0,
                    companyName: state.company.company.companyName,
                    countryId: state.company.facility.country.id,
                    stateId: state.company.facility.city.id,
                    address: state.company.company.address,
                    phone: state.company.company.phone,
                    email: state.company.company.email,
                    facilityLimit: state.company.company.createCompany.facilityLimit,
                    websiteUrl: state.company.company.createCompany.url,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/company',
                        feature: UPDATE_COMPANY,
                        body,
                    })
                );
                break;
            }
            case `${UPDATE_COMPANY} ${API_SUCCESS}`: {
                dispatch(getCompanyList());
                const { message } = action.payload;
                dispatch(
                    createNotification({
                        type: 'success',
                        message: message || i18next.t('notification.successUpdated'),
                        time: 3000,
                    })
                );
                break;
            }
            case `${UPDATE_COMPANY} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || action.payload.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case UPLOAD_COMPANY_IMAGE: {
                const companyId = state.auth.userInfo?.company?.id;

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/company/logo/add',
                        feature: UPLOAD_COMPANY_IMAGE,
                        onSuccess: action.meta.onSuccess,
                        query: {
                            companyId,
                        },
                        body: action.payload,
                    })
                );
                break;
            }
            case `${UPLOAD_COMPANY_IMAGE} ${API_SUCCESS}`: {
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: i18next.t('notification.successUpdated'),
                    })
                );
                window.location.reload();
                break;
            }
            case `${UPLOAD_COMPANY_IMAGE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }
            case GET_COMPANY_IMAGE: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/logo/get',
                        feature: GET_COMPANY_IMAGE,
                        responseType: 'blob',
                        query: {
                            companyId: state.auth.userInfo?.company?.id,
                        },
                    })
                );
                break;
            }

            case `${GET_COMPANY_IMAGE} ${API_SUCCESS}`: {
                const href = URL.createObjectURL(action.payload);
                dispatch(setCompanyImage(href));
                //const link = document.createElement('a');
                //link.href = href;
                //link.setAttribute('download', `${companyName}.png`);
                //document.body.appendChild(link);
                ////link.click();
                //
                //document.body.removeChild(link);
                //URL.revokeObjectURL(href);
                break;
            }

            case GET_COMPANY_USER_ID: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/auth/whoAmI',
                        feature: GET_COMPANY_USER_ID,
                    })
                );
                break;
            }

            case `${GET_COMPANY_USER_ID} ${API_SUCCESS}`: {
                const data = action.payload.user.id;
                next(setCompanyUserId(data));
                dispatch(getCompanyId());
                break;
            }

            case GET_COMPANY_ID: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/getCompanyInfo',
                        feature: GET_COMPANY_ID,
                        query: {
                            userId: state.company.company.userId,
                        },
                    })
                );
                break;
            }

            case `${GET_COMPANY_ID} ${API_SUCCESS}`: {
                const data = action.payload?.company?.id;
                next(setCompanyId(data));
                dispatch(getCompanyResult());
                break;
            }

            case GET_COMPANY_RESULT: {
                const id = action.payload || state.auth.userInfo?.company?.id;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/company/${id}`,
                        feature: GET_COMPANY_RESULT,
                    })
                );
                break;
            }

            case `${GET_COMPANY_RESULT} ${API_SUCCESS}`: {
                const data = action.payload.company;
                next(setCompanyResult(data));
                break;
            }

            case GET_COMPANY_COUNTRY: {
                const list = Countries.reduce(
                    (
                        acc: Array<ICountry>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setCompanyCountry(list));
                break;
            }

            case `${GET_COMPANY_COUNTRY} ${API_SUCCESS}`: {
                const list = Countries.reduce(
                    (
                        acc: Array<ICountry>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setCompanyCountry(list));
                break;
            }

            case GET_COMPANY_CITY: {
                const list = Cities.reduce(
                    (
                        acc: Array<IState>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setCompanyCity(list));
                break;
            }

            case `${GET_COMPANY_CITY} ${API_SUCCESS}`: {
                const list = Cities.reduce(
                    (
                        acc: Array<IState>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setCompanyCity(list));
                break;
            }

            case GET_COMPANY_SECTOR: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/sector/sector/list',
                        feature: GET_COMPANY_SECTOR,
                    })
                );
                break;
            }

            case `${GET_COMPANY_SECTOR} ${API_SUCCESS}`: {
                const list = action.payload.sectorList.reduce(
                    (
                        acc: Array<ISector>,
                        v: {
                            id: number;
                            sector: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.sector,
                            label: v.sector,
                        });
                        return acc;
                    },
                    []
                );
                next(setCompanySector(list));
                break;
            }

            case SUBMIT_COMPANY_FORM: {
                interface IBody {
                    id: number;
                    companyName: string;
                    email: string;
                    websiteUrl: string;
                    countryId: number;
                    stateId: number;
                    address: string;
                    phone: number;
                    industry: string;
                    sectorId: number;
                    companyDescription: string;
                }

                const body: IBody = {
                    id: state?.auth?.userInfo?.company?.id,
                    companyName: state.company.company.companyName,
                    email: state.company.company.email,
                    websiteUrl: state.company.company.websiteUrl,
                    countryId: state.company.company.country?.id,
                    stateId: state.company.company.city?.id,
                    address: state.company.company.address,
                    phone: state.company.company.phone,
                    industry: state.company.company.industry?.label,
                    sectorId: state.company.company.industry?.id,
                    companyDescription: state.company.company.companyDescription,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/company',
                        feature: SUBMIT_COMPANY_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_COMPANY_FORM} ${API_SUCCESS}`: {
                const { message } = action.payload;
                dispatch(getCompanyResult());
                dispatch(
                    createNotification({
                        type: 'success',
                        message: message || i18next.t('notification.successCreated'),
                        time: 3000,
                    })
                );
                action.meta.onSuccess();
                break;
            }

            case SUBMIT_COMPANY_CREATE: {
                interface IBody {
                    organizationId?: number;
                    companyName: string;
                    email: string;
                    phone: string;
                    websiteUrl: string;
                    address: string;
                    facilityLimit: number;
                    countryId?: number;
                    stateId?: number;
                }
                const body: IBody = {
                    organizationId: state?.auth?.userInfo?.organization?.id,
                    companyName: state?.company?.company?.createCompany?.companyName,
                    email: state?.company?.company?.createCompany?.email,
                    phone: state?.company?.company?.createCompany?.phone,
                    websiteUrl: state?.company?.company?.createCompany?.url,
                    address: state?.company?.company?.createCompany?.address,
                    facilityLimit: +state?.company?.company?.createCompany?.facilityLimit,
                    countryId: state.company.company.country?.id,
                    stateId: state.company.company.city?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/company',
                        feature: SUBMIT_COMPANY_CREATE,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }

            case `${SUBMIT_COMPANY_CREATE} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                break;
            }
            case `${SUBMIT_COMPANY_CREATE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_COMPANY_DASHBOARD_DATA: {
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/dashboard/company',
                        feature: GET_COMPANY_DASHBOARD_DATA,
                        body: {
                            organizationId: state?.auth?.userInfo?.organization?.id,
                            companyId: state?.company?.facility?.facility?.id || state?.auth?.userInfo?.company?.id, // will be change here
                            facilityId: state?.auth?.userInfo?.facility?.id,
                            periodStart: state.company.company.period.start,
                            periodEnd: state.company.company.period.end,
                        },
                    })
                );
                break;
            }

            case `${GET_COMPANY_DASHBOARD_DATA} ${API_SUCCESS}`: {
                const { dashboardDto } = action.payload;

                const category = dashboardDto.emissionCategoryTypeStatisticList.reduce(
                    (
                        acc: { data: Array<number>; labels: Array<string> },
                        v: { categoryTypeName: string; totalEmission: number | undefined }
                    ) => {
                        acc.data.push(v.totalEmission || 0);
                        acc.labels.push(v.categoryTypeName);
                        return acc;
                    },
                    {
                        data: [],
                        labels: [],
                    }
                );
                const monthlyCumulative = dashboardDto.monthlyStatisticList.reduce(
                    (
                        acc: {
                            monthly: { data: Array<number>; labels: Array<string> };
                            cumulative: { data: Array<number>; labels: Array<string> };
                        },
                        v: {
                            period: string;
                            totalEmission: number | undefined;
                            cumulativeTotalEmission: number | undefined;
                        }
                    ) => {
                        acc.monthly.data.push(v.totalEmission || 0);
                        acc.monthly.labels.push(v.period);
                        acc.cumulative.data.push(v.cumulativeTotalEmission || 0);
                        acc.cumulative.labels.push(v.period);
                        return acc;
                    },
                    {
                        monthly: {
                            data: [],
                            labels: [],
                        },
                        cumulative: {
                            data: [],
                            labels: [],
                        },
                    }
                );

                const scopes = dashboardDto.emissionScopeStatisticList.reduce(
                    (
                        acc: { scopes: { data: Array<number>; labels: Array<string> } },
                        v: {
                            scope: string;
                            scopeName: string;
                            totalEmission: number;
                            totalEmissionPercentage: number;
                        }
                    ) => {
                        acc.scopes.data.push(v.totalEmission || 0);
                        acc.scopes.labels.push(v.scopeName);
                        return acc;
                    },
                    {
                        scopes: {
                            data: [],
                            labels: [],
                        },
                    }
                );

                const data = {
                    ...monthlyCumulative,
                    ...scopes,
                    allTimeTotalEmission: dashboardDto.periodTotalEmission, // allTimeTotalEmission
                    monthlyTotal: dashboardDto.periodAverageMonthlyEmission,
                    total: dashboardDto.periodTotalEmission,
                    category: category,
                };

                next(setCompanyDashboardData(data));
            }
            // endregion MOBILE
        }
    };
