import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AuthorizationProvider from './providers/AuthorizationProvider';
import trLocale from 'date-fns/locale/tr';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import ApplicationRoutes from './Routes';
import CopyRight from '../src/views/components/CopyRight';
import Loading from './views/components/Loading/Loading';
import NotificationContainer from './views/components/Notifications/NotificationContainer';
import LayoutContext from './views/layouts/LayoutContext';
import LayoutTheme from './views/theme/LayoutTheme';

function App() {
    return (
        <>
            <Provider store={store}>
                <PersistGate loading={<Loading />} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={trLocale}>
                        <LayoutContext>
                            <LayoutTheme>
                                <CssBaseline />
                                <AuthorizationProvider>
                                    <ApplicationRoutes />
                                    <NotificationContainer />
                                    <CopyRight sx={{ mt: 3, mb: 3, backgroundColor: 'inherit' }} />
                                </AuthorizationProvider>
                            </LayoutTheme>
                        </LayoutContext>
                    </LocalizationProvider>
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
