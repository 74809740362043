import { Middleware } from 'redux';
import { ITag } from '../../../../entities/emissionTypes/baseType';

import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    DOWNLOAD_FILE_TO_INVENTORY,
    GET_BASE_COMPANIES,
    GET_BASE_FACILITIES,
    GET_BASE_TAGS,
    setBaseCompanies,
    setBaseFacilities,
    setBaseTags,
    UPLOAD_EXCEL,
    UPLOAD_FILE_TO_INVENTORY,
} from '../../../actions/emission/base.action';
import { createNotification } from '../../../actions/ui.action';
import { IConnectionRole } from '../../../../entities/system/userCombustion';
import i18next from 'i18next';

export const baseMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_BASE_TAGS:
                const companyId = state.auth.userInfo?.company?.id;
                const facilityId = state.company.facility?.facility?.id || null;
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporateEmission/tags',
                        feature: GET_BASE_TAGS,
                        query: {
                            companyId,
                            facilityId,
                            character: action.payload || '',
                        },
                    })
                );
                break;
            case `${GET_BASE_TAGS} ${API_SUCCESS}`: {
                let count = 1;
                const list = action.payload.tags.reduce((acc: Array<ITag>, v: string) => {
                    acc.push({
                        id: count++,
                        key: v,
                        label: v,
                    });
                    return acc;
                }, []);
                next(setBaseTags(list));
                break;
            }

            case UPLOAD_EXCEL: {
                action.payload.doc.append('companyId', state.auth.userInfo?.company?.id);
                action.payload.doc.append('fileName', state.emission.base.fileName || 'document');
                action.payload.doc.append('corporateEmissionId', action.payload.id || null);
                action.payload.doc.append('isCalculationFile', true);

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/excel',
                        feature: UPLOAD_EXCEL,
                        onSuccess: action.meta.onSuccess,
                        body: action.payload.doc,
                    })
                );
                break;
            }

            case `${UPLOAD_EXCEL} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: i18next.t('notification.documentAdded'),
                    })
                );
                break;
            }

            case `${UPLOAD_EXCEL} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case UPLOAD_FILE_TO_INVENTORY: {
                action.payload.doc.append('companyId', state.auth.userInfo?.company?.id);
                action.payload.doc.append('fileName', state.emission.base.fileName || 'document');
                action.payload.doc.append('corporateEmissionId', action.payload.id || null);
                action.payload.doc.append('isCalculationFile', true);

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/company/document/add',
                        feature: UPLOAD_FILE_TO_INVENTORY,
                        onSuccess: action.meta.onSuccess,
                        body: action.payload.doc,
                    })
                );
                break;
            }
            case `${UPLOAD_FILE_TO_INVENTORY} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: i18next.t('notification.documentAdded'),
                    })
                );
                break;
            }
            case `${UPLOAD_FILE_TO_INVENTORY} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case DOWNLOAD_FILE_TO_INVENTORY: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/document/get',
                        feature: DOWNLOAD_FILE_TO_INVENTORY,
                        query: {
                            companyId: state.auth.userInfo?.company?.id,
                            documentId: action.payload.id,
                        },
                        responseType: 'blob',
                        onSuccess: () => {
                            return action.payload;
                        },
                    })
                );
                break;
            }
            case `${DOWNLOAD_FILE_TO_INVENTORY} ${API_SUCCESS}`: {
                const downloadedFile = action.meta.onSuccess();
                const href = URL.createObjectURL(action.payload);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${downloadedFile.documentFileName}.${downloadedFile.extension}`); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                break;
            }
            case `${DOWNLOAD_FILE_TO_INVENTORY} ${API_ERROR}`: {
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: action.payload.message || i18next.t('notification.cannotDownloaded'),
                    })
                );
                break;
            }

            case GET_BASE_COMPANIES: {
                interface IBody {
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                }

                const body: IBody = {};
                if (state?.auth?.userInfo?.user?.userLevel == 'ORGANIZATION') {
                    body['organizationId'] = state?.auth?.userInfo?.organization?.id;
                }
                if (state?.auth?.userInfo?.user?.userLevel == 'COMPANY') {
                    body['companyId'] = state?.auth?.userInfo?.company?.id;
                }

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporation/scheme',
                        feature: GET_BASE_COMPANIES,
                        body,
                    })
                );
                break;
            }

            case `${GET_BASE_COMPANIES} ${API_SUCCESS}`: {
                const data = action.payload?.corporationPart?.subPartSet?.reduce(
                    (
                        acc: Array<IConnectionRole>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );

                dispatch(setBaseCompanies(data));
                break;
            }

            case GET_BASE_FACILITIES: {
                interface IBody {
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                }

                const body: IBody = {
                    companyId: action.payload?.id,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporation/scheme',
                        feature: GET_BASE_FACILITIES,
                        body,
                    })
                );
                break;
            }

            case `${GET_BASE_FACILITIES} ${API_SUCCESS}`: {
                const data = action.payload?.corporationPart?.subPartSet?.reduce(
                    (
                        acc: Array<IConnectionRole>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                dispatch(setBaseFacilities(data));
                break;
            }
        }
    };
