import { authenticationMiddleware } from '../auth/auth.middleware';
import { companyMiddleware } from '../company/company.middleware';
import { facilityMiddleware } from '../company/facility.middleware';
import { generalMiddleware } from '../company/general.middleware';
import { organizationalMiddleware } from '../company/organizational.middleware';
import { reportingMiddleware } from '../company/reporting.middleware';
import { emissionBoughtAssetsMiddleware } from './boughtAssets.middleware';
import { emissionBusinessMiddleware } from './business.middleware';
import { emissionDownstreamMiddleware } from './downstream.middleware';
import { emissionElectricMiddleware } from './electric.middleware';
import { emissionEmployeeMiddleware } from './employee.middleware';
import { emissionFugitiveMiddleware } from './fugitive.middleware';
import { emissionMobileMiddleware } from './mobile.middleware';
import { emissionProductLifetimeMiddleware } from './productLifetime.middleware';
import { emissionSoldProductsMiddleware } from './soldProducts.middleware';
import { emissionStaticMiddleware } from './static.middleware';
import { emissionSteamMiddleware } from './steam.middleware';
import { updateEmissionMiddleware } from './update.middleware';
import { emissionUpstreamMiddleware } from './upstream.middleware';
import { emissionVisitorMiddleware } from './visitor.middleware';
import { emissionWasteMiddleware } from './waste.middleware';
import { quantifiedMiddleware } from '../company/quantified.middleware';
import { internalMiddleware } from '../company/internal.middleware';
import { emissionProcessMiddleware } from './process.middleware';
import { baseMiddleware } from './base.middleware';
import { appendicesMiddleware } from '../company/appendices.middleware';
import { emissionLulucfMiddleware } from './lulucf.middleware';
import { analyticMiddleware } from '../company/analytic.middleware';
import { createUserMiddleware } from '../system/createUser.middleware';
import { userGroupMiddleware } from '../system/userGroup.middleware';
import { privilegeMiddleware } from '../system/privilege.middleware';
import { privilegeGroupMiddleware } from '../system/privilegeGroup.middleware';
import { userMiddleware } from '../system/user.middleware';
import { organizationMiddleware } from '../company/organization.middleware';
import { projectMiddleware } from '../kpi/project.middleware';
import { kpiMiddleware } from '../kpi/kpi.middleware';
import { actionMiddleware } from '../kpi/action.middleware';
import { emissionBiomassMiddleware } from './biomass.middleware';
import { resetPasswordMiddleware } from '../auth/resetPassword.middleware';
import { emissionExcelMiddleware } from './excel.middleware';
import { emissionServiceUsageMiddleware } from './serviceUsage.middleware';
import { emissionDownstreamLeasedMiddleware } from './downstreamLeased.middleware';
import { emissionUpstreamLeasedMiddleware } from './upstreamLeased.middleware';

export const emissionMiddlewares = [
    emissionStaticMiddleware,
    emissionElectricMiddleware,
    emissionSteamMiddleware,
    emissionMobileMiddleware,
    emissionFugitiveMiddleware,
    emissionDownstreamMiddleware,
    emissionBusinessMiddleware,
    emissionUpstreamMiddleware,
    emissionEmployeeMiddleware,
    emissionVisitorMiddleware,
    emissionProductLifetimeMiddleware,
    emissionBoughtAssetsMiddleware,
    emissionSoldProductsMiddleware,
    emissionWasteMiddleware,
    authenticationMiddleware,
    companyMiddleware,
    facilityMiddleware,
    generalMiddleware,
    organizationalMiddleware,
    updateEmissionMiddleware,
    reportingMiddleware,
    quantifiedMiddleware,
    internalMiddleware,
    emissionProcessMiddleware,
    baseMiddleware,
    appendicesMiddleware,
    emissionLulucfMiddleware,
    analyticMiddleware,
    createUserMiddleware,
    userGroupMiddleware,
    privilegeMiddleware,
    privilegeGroupMiddleware,
    userMiddleware,
    organizationMiddleware,
    projectMiddleware,
    kpiMiddleware,
    actionMiddleware,
    emissionBiomassMiddleware,
    resetPasswordMiddleware,
    emissionExcelMiddleware,
    emissionServiceUsageMiddleware,
    emissionDownstreamLeasedMiddleware,
    emissionUpstreamLeasedMiddleware,
];
