import { Middleware } from 'redux';
import {
    IArgumentList,
    ICategory,
    ICompany,
    ICorporateEmission,
    ICountry,
    IDocument,
    IFacility,
    IGlobalFacility,
    ISector,
    IState,
    ISubCategory,
} from '../../../../entities/company/facilityCombustion';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    DELETE_FACILITY,
    DELETE_FACILITY_INVERTORY,
    DELETE_FACILITY_LIST_ITEM,
    EXPORT_INVENTORY_LIST,
    GET_COMPANY_LIST,
    GET_FACILITY_CATEGORY,
    GET_FACILITY_CITY,
    GET_FACILITY_CORPORATE_LIST,
    GET_FACILITY_COUNTRY,
    GET_FACILITY_DASHBOARD_DATA,
    GET_FACILITY_DETAIL,
    GET_FACILITY_GLOBAL_LIST,
    GET_FACILITY_LIST,
    GET_FACILITY_RESULT,
    GET_FACILITY_SECTOR,
    GET_FACILITY_SUB_CATEGORY,
    getFacilityList,
    getCompanyList,
    setFacilityCategory,
    setFacilityCity,
    setFacilityCorporateList,
    setFacilityCountry,
    setFacilityDetail,
    setFacilityGlobalList,
    setFacilityList,
    setFacilityResult,
    setFacilitySector,
    setFacilitySubCategory,
    SUBMIT_FACILITY_FORM,
    UPDATE_FACILITY,
    getFacilityCountry,
    getFacilityCity,
} from '../../../actions/company/facility.action';
import { GET_COMPANY_RESULT, setCompanyDashboardData } from '../../../actions/company/company.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';
import { Cities, Countries } from '../../../../data/countries';
import { getFacilityState } from '../../../selectors/company/facility.selector';

export enum Category {
    STATIONARY_EMISSION = 'STATIONARY_EMISSION',
    MOBILE_EMISSION = 'MOBILE_EMISSION',
    PROCESS_EMISSION = 'PROCESS_EMISSION',
    FUGITIVE_EMISSION = 'FUGITIVE_EMISSION',

    ELECTRICITY_EMISSION = 'ELECTRICITY_EMISSION',
    STEAM_HEAT_EMISSION = 'STEAM_HEAT_EMISSION',

    UPSTREAM_TRANSPORTATION_EMISSION = 'UPSTREAM_TRANSPORTATION_EMISSION',
    DOWNSTREAM_TRANSPORTATION_EMISSION = 'DOWNSTREAM_TRANSPORTATION_EMISSION',
    EMPLOYEE_COMMITTING_EMISSION = 'EMPLOYEE_COMMITTING_EMISSION',
    VISITOR_AND_COSTUMER_EMISSION = 'VISITOR_AND_COSTUMER_EMISSION',
    BUSINESS_TRAVEL_EMISSION = 'BUSINESS_TRAVEL_EMISSION',

    PURCHASED_GOODS_EMISSION = 'PURCHASED_GOODS_EMISSION',
    PURCHASED_CAPITAL_GOODS_EMISSION = 'PURCHASED_CAPITAL_GOODS_EMISSION',
    WASTE_DISPOSAL_EMISSION = 'WASTE_DISPOSAL_EMISSION',
    SERVICE_USE_EMISSION = 'SERVICE_USE_EMISSION',

    USE_OF_PRODUCT_EMISSION = 'USE_OF_PRODUCT_EMISSION',
    END_OF_LIFE_TREATMENT_EMISSION = 'END_OF_LIFE_TREATMENT_EMISSION',

    OTHER_EMISSION = 'OTHER_EMISSION',
}

export const facilityMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            // region AUTH
            case EXPORT_INVENTORY_LIST: {
                const { startedDate, finishedDate, category, tags, activityType } = action.payload;
                const companyId = state.auth.userInfo?.company?.id;
                const facilityId = state.company.facility?.facility?.id || null;
                const categoryList = category.reduce(
                    (
                        acc: Array<number>,
                        v: {
                            id: number;
                        }
                    ) => {
                        acc.push(v.id);
                        return acc;
                    },
                    []
                );

                const tagList = tags.reduce(
                    (
                        acc: Array<string>,
                        v: {
                            value: string;
                        }
                    ) => {
                        acc.push(v.value);
                        return acc;
                    },
                    []
                );

                const companyList = [];
                const facilityList = [];

                companyId && companyList.push(companyId);
                facilityId && facilityList.push(facilityId);

                interface Body {
                    companyIdList: Array<number>;
                    facilityIdList: Array<number>;
                    categoryIdList: Array<number>;
                    period: {
                        periodStart: string;
                        periodEnd: string;
                        includeYearlyValues: boolean;
                        onlyYearlyValues: boolean;
                    };
                    activityData: string;
                    tags: Array<string>;
                    detailed: boolean;
                }
                const body: Body = {
                    categoryIdList: categoryList,
                    companyIdList: companyList,
                    facilityIdList: facilityList,
                    period: {
                        periodStart: startedDate.value || '2010-01',
                        periodEnd: finishedDate.value || '2023-12',
                        includeYearlyValues: true,
                        onlyYearlyValues: false,
                    },
                    activityData: activityType.value,
                    tags: tagList,
                    detailed: true,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporateEmission/export',
                        feature: EXPORT_INVENTORY_LIST,
                        body,
                        responseType: 'blob',
                        onSuccess: () => {
                            return action.payload;
                        },
                    })
                );
                break;
            }
            case `${EXPORT_INVENTORY_LIST} ${API_SUCCESS}`: {
                const downloadedFile = action.meta.onSuccess();
                const href = URL.createObjectURL(action.payload);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'inventories.xlsx');
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);

                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: i18next.t('notification.documentDownload'),
                    })
                );
                break;
            }

            case DELETE_FACILITY_LIST_ITEM: {
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: `/corporateEmission/${action.payload}`,
                        feature: DELETE_FACILITY_LIST_ITEM,
                    })
                );
                break;
            }

            case `${DELETE_FACILITY_LIST_ITEM} ${API_SUCCESS}`: {
                window.location.reload();
                break;
            }

            case GET_FACILITY_CATEGORY: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/common/data/emissionCategoryTypes',
                        feature: GET_FACILITY_CATEGORY,
                    })
                );
                break;
            }

            case `${GET_FACILITY_CATEGORY} ${API_SUCCESS}`: {
                const data = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<ICategory>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            name: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilityCategory(data));
                break;
            }

            case GET_FACILITY_SUB_CATEGORY: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/carbonCalculator/common/data/emissionCategories',
                        feature: GET_FACILITY_SUB_CATEGORY,
                        query: {
                            emissionCategoryTypeId: action.payload || state.company.facility.category.id,
                        },
                    })
                );
                break;
            }

            case `${GET_FACILITY_SUB_CATEGORY} ${API_SUCCESS}`: {
                const data = action.payload.contentDtoList.reduce(
                    (
                        acc: Array<ISubCategory>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            name: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilitySubCategory(data));
                break;
            }

            case GET_FACILITY_RESULT: {
                const companyId = state.auth.userInfo?.company?.id;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/facility/${action.payload}`,
                        feature: GET_FACILITY_RESULT,
                        query: {
                            companyId,
                        },
                    })
                );
                break;
            }

            case `${GET_FACILITY_RESULT} ${API_SUCCESS}`: {
                const data = action.payload.facility;
                next(setFacilityResult(data));
                break;
            }

            case GET_FACILITY_LIST: {
                let companyId;
                if (state.company?.facility?.facility?.type == 'ORGANIZATION') {
                }
                if (state.company?.facility?.facility?.type == 'COMPANY') {
                    companyId = state.company?.facility?.facility?.id || state?.auth?.userInfo?.company?.id;
                }
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/facility/list',
                        feature: GET_FACILITY_LIST,
                        query: {
                            companyId,
                        },
                    })
                );
                break;
            }

            case `${GET_FACILITY_LIST} ${API_SUCCESS}`: {
                const data = action.payload.facilityList.reduce(
                    (
                        acc: Array<IFacility>,
                        v: {
                            id: number;
                            facilityName: string;
                            email: string;
                            country: { name: string };
                            state: { name: string };
                            address: string;
                            phone: number;
                        }
                    ) => {
                        acc.push({
                            id: v.id || 0,
                            facilityName: v.facilityName || '',
                            email: v.email || '',
                            country: v.country?.name || '',
                            state: v.state?.name || '',
                            address: v.address || '',
                            phone: v.phone || 0,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilityList(data));
                break;
            }

            case `${GET_FACILITY_LIST} ${API_ERROR}`: {
                const list = [] as Array<IFacility>;
                const { data } = action.payload.response;
                next(setFacilityList(list));
                //dispatch(
                //    createNotification({
                //        time: 2000,
                //        type: 'error',
                //        message: data.message || 'Bir hata oluştu',
                //    })
                //);
                break;
            }

            case GET_COMPANY_LIST: {
                const organizationId = state?.auth?.userInfo?.organization?.id;
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/company/list',
                        feature: GET_COMPANY_LIST,
                        query: {
                            organizationId,
                        },
                    })
                );
                break;
            }

            case `${GET_COMPANY_LIST} ${API_SUCCESS}`: {
                const data = action.payload?.companyList?.reduce(
                    (
                        acc: Array<ICompany>,
                        v: {
                            id: number;
                            companyName: string;
                            email: string;
                            country: { name: string };
                            state: { name: string };
                            address: string;
                            phone: number;
                        }
                    ) => {
                        acc.push({
                            id: v.id || 0,
                            companyName: v.companyName || '',
                            email: v.email || '',
                            country: v.country?.name || '',
                            state: v.state?.name || '',
                            address: v.address || '',
                            phone: v.phone || 0,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilityList(data));
                break;
            }

            case `${GET_COMPANY_LIST} ${API_ERROR}`: {
                const { data } = action.payload.response;
                const list = [] as Array<IFacility>;
                next(setFacilityList(list));

                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_FACILITY_CORPORATE_LIST: {
                const { startedDate, finishedDate, page, size, category, tags, activityType } = action.payload;
                const selected = state.company.facility?.facility || null;

                const categoryList = category.reduce(
                    (
                        acc: Array<number>,
                        v: {
                            id: number;
                        }
                    ) => {
                        acc.push(v.id);
                        return acc;
                    },
                    []
                );

                const tagList = tags.reduce(
                    (
                        acc: Array<string>,
                        v: {
                            value: string;
                        }
                    ) => {
                        acc.push(v.value);
                        return acc;
                    },
                    []
                );

                const companyList = [];
                const facilityList = [];

                if (selected?.type === 'ORGANIZATION') {
                }
                if (selected?.type === 'COMPANY') {
                    if (selected?.id === '') companyList.push(state?.auth?.userInfo?.company?.id);
                    else companyList.push(selected?.id);
                }
                if (selected?.type === 'FACILITY') {
                    facilityList.push(selected?.id);
                    companyList.push(
                        selected?.companyId ||
                            state?.auth?.userInfo?.company?.id ||
                            state?.auth?.userInfo?.facility?.companyId
                    );
                }

                const body = {
                    categoryIdList: categoryList,
                    companyIdList: companyList,
                    facilityIdList: facilityList,
                    period: {
                        periodStart: startedDate.value || '2010-01',
                        periodEnd: finishedDate.value || '2023-12',
                        includeYearlyValues: true,
                        onlyYearlyValues: false,
                    },
                    activityData: null, //activityType.value,
                    tags: tagList,
                    detailed: true,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporateEmission/filter',
                        feature: GET_FACILITY_CORPORATE_LIST,
                        body,
                        query: {
                            page: page.value,
                            size: size.value,
                            sort: 'createdAt,desc',
                        },
                    })
                );
                break;
            }

            case `${GET_FACILITY_CORPORATE_LIST} ${API_SUCCESS}`: {
                const data = action.payload;
                const totalElements = action.payload.corporateEmissionContentDtoPage.totalElements;
                const list = action.payload.corporateEmissionContentDtoPage.content.reduce(
                    (
                        acc: Array<ICorporateEmission>,
                        v: {
                            id: number;
                            facilityId: number;
                            period: string;
                            emissionCategory: { name: string; title: string };
                            emissionActivityType: { name: string };
                            totalCalculatedEmission?: number;
                            emissionActivity: { name: string };
                            emissionSource: { name: string; title: string };
                            emisyonlar: string;
                            tags: string;
                            emissionFactorDatabase: { title: string };
                            emissionCategoryType: { name: string };
                            facilityName: string;
                            document?: IDocument | undefined;
                            argumentList: Array<IArgumentList>;
                            tier: { name: string };
                        }
                    ) => {
                        let periyot = v.period;
                        let kategori = v.emissionCategory.name;
                        let aktiviteTipi = v.emissionActivityType.name;
                        let emissions = v.emissionActivity.name;
                        let faalitetVerisi = v.emissionActivity.name;
                        let kaynak = v.tags;
                        let toplam = v.totalCalculatedEmission || 0;
                        let kademe = v.tier.name;

                        if (v.emissionCategory.title === Category.STATIONARY_EMISSION) {
                        }
                        if (v.emissionCategory.title === Category.MOBILE_EMISSION) {
                            faalitetVerisi = v.emissionActivity.name;
                        }

                        if (v.emissionCategory.title === Category.PROCESS_EMISSION) {
                        }

                        if (v.emissionCategory.title === Category.FUGITIVE_EMISSION) {
                            faalitetVerisi = v.emissionSource.name;
                            emissions = v.emissionActivity.name;
                        }

                        if (v.emissionCategory.title === Category.ELECTRICITY_EMISSION) {
                            faalitetVerisi = 'Elektrik';
                            kaynak = v.emissionSource.name;
                        }

                        if (v.emissionCategory.title === Category.STEAM_HEAT_EMISSION) {
                            if (v.emissionFactorDatabase.title === 'IPCC_2006') {
                            }
                            if (v.emissionFactorDatabase.title === 'DEFRA_2021') {
                                faalitetVerisi = v.emissionSource.name;
                            }
                        }

                        if (v.emissionCategory.title === Category.UPSTREAM_TRANSPORTATION_EMISSION) {
                            if (v.emissionFactorDatabase.title === 'IPCC_2006') {
                            }
                            if (v.emissionFactorDatabase.title === 'DEFRA_2021') {
                                faalitetVerisi = `${v.emissionSource.name}/${v.emissionActivityType.name}`;
                            }
                        }

                        if (v.emissionCategory.title === Category.DOWNSTREAM_TRANSPORTATION_EMISSION) {
                            if (v.emissionFactorDatabase.title === 'IPCC_2006') {
                            }
                            if (v.emissionFactorDatabase.title === 'DEFRA_2021') {
                                faalitetVerisi = `${v.emissionSource.name}/${v.emissionActivityType.name}`;
                            }
                        }

                        if (v.emissionCategory.title === Category.EMPLOYEE_COMMITTING_EMISSION) {
                            if (v.emissionFactorDatabase.title === 'IPCC_2006') {
                            }
                            if (v.emissionFactorDatabase.title === 'DEFRA_2021') {
                                faalitetVerisi = `${v.emissionSource.name}/${v.emissionActivityType.name}`;
                            }
                        }

                        if (v.emissionCategory.title === Category.VISITOR_AND_COSTUMER_EMISSION) {
                            if (v.emissionFactorDatabase.title === 'IPCC_2006') {
                            }
                            if (v.emissionFactorDatabase.title === 'DEFRA_2021') {
                                faalitetVerisi = `${v.emissionSource.name}/${v.emissionActivityType.name}`;
                            }
                        }

                        if (v.emissionCategory.title === Category.BUSINESS_TRAVEL_EMISSION) {
                            if (v.emissionFactorDatabase.title === 'IPCC_2006') {
                            }
                            if (v.emissionFactorDatabase.title === 'DEFRA_2021') {
                                faalitetVerisi = `${v.emissionSource.name}/${v.emissionActivityType.name}`;
                            }
                        }

                        if (v.emissionCategory.title === Category.PURCHASED_GOODS_EMISSION) {
                            faalitetVerisi = v.emissionSource.name;
                        }

                        if (v.emissionCategory.title === Category.PURCHASED_CAPITAL_GOODS_EMISSION) {
                            faalitetVerisi = v.emissionSource.name;
                        }

                        if (v.emissionCategory.title === Category.WASTE_DISPOSAL_EMISSION) {
                            faalitetVerisi = v.emissionSource.name;
                        }

                        if (v.emissionCategory.title === Category.END_OF_LIFE_TREATMENT_EMISSION) {
                            faalitetVerisi = `${v.emissionSource.name}/${v.emissionActivity.name}`;
                        }

                        emissions = `${v.argumentList[0].argument.name}: ${v.argumentList[0].value}`;
                        if (v.argumentList[1]) {
                            emissions += `\n${v.argumentList[1]?.argument?.name}: ${v.argumentList[1]?.value}`;
                        }

                        if (v.argumentList[2]) {
                            emissions += `\n${v.argumentList[2]?.argument?.name}: ${v.argumentList[2]?.value}`;
                        }

                        acc.push({
                            id: v.id,
                            facilityId: v.facilityId,
                            period: periyot,
                            emissionCategory: kategori,
                            emissionActivityType: aktiviteTipi,
                            totalCalculatedEmission: +toplam.toFixed(6),
                            emissionActivity: faalitetVerisi,
                            emissionSource: v.emissionSource.name,
                            emisyonlar: emissions,
                            tags: kaynak,
                            emissionCategoryType: v.emissionCategoryType.name,
                            facilityName: v.facilityName,
                            document: v?.document,
                            argumentList: v?.argumentList,
                            tier: kademe,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilityCorporateList({ list, totalElements }));
                break;
            }

            case `${GET_FACILITY_CORPORATE_LIST} ${API_ERROR}`: {
                dispatch(
                    createNotification({
                        time: 2500,
                        type: 'warning',
                        message: i18next.t('notification.pleaseMakeYourChoise'),
                    })
                );
                break;
            }

            case GET_FACILITY_COUNTRY: {
                const list = Countries.reduce(
                    (
                        acc: Array<ICountry>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilityCountry(list));
                break;
            }

            case `${GET_FACILITY_COUNTRY} ${API_SUCCESS}`: {
                const list = Countries.reduce(
                    (
                        acc: Array<ICountry>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilityCountry(list));
                break;
            }

            case GET_FACILITY_CITY: {
                const list = Cities.reduce(
                    (
                        acc: Array<IState>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilityCity(list));
                break;
            }

            case `${GET_FACILITY_CITY} ${API_SUCCESS}`: {
                const list = Cities.reduce(
                    (
                        acc: Array<IState>,
                        v: {
                            id: number;
                            name: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilityCity(list));
                break;
            }

            case GET_FACILITY_SECTOR: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/facility/sector/sector/list',
                        feature: GET_FACILITY_SECTOR,
                    })
                );
                break;
            }

            case `${GET_FACILITY_SECTOR} ${API_SUCCESS}`: {
                const list = action.payload.sectorList.reduce(
                    (
                        acc: Array<ISector>,
                        v: {
                            id: number;
                            sector: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.sector,
                            label: v.sector,
                        });
                        return acc;
                    },
                    []
                );
                next(setFacilitySector(list));
                break;
            }

            case DELETE_FACILITY: {
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: `/facility/${action.payload}`,
                        feature: DELETE_FACILITY,
                    })
                );
                break;
            }

            case `${DELETE_FACILITY} ${API_SUCCESS}`: {
                dispatch(getFacilityList());
                const { message } = action.payload;
                dispatch(
                    createNotification({
                        type: 'success',
                        message: message || i18next.t('notification.successDeleted'),
                        time: 3000,
                    })
                );
                break;
            }

            case `${DELETE_FACILITY} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case UPDATE_FACILITY: {
                interface IBody {
                    id: number;
                    facilityName: string;
                    countryId: number;
                    stateId: number;
                    address: string;
                    phone: number;
                    email: string;
                }

                const body: IBody = {
                    id: action.payload || 0,
                    facilityName: state.company.facility.facilityName,
                    countryId: state.company.facility.country.id,
                    stateId: state.company.facility.city.id,
                    address: state.company.facility.address,
                    phone: state.company.facility.phone,
                    email: state.company.facility.email,
                };

                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/company/facility/update',
                        feature: UPDATE_FACILITY,
                        body,
                    })
                );
                break;
            }

            case `${UPDATE_FACILITY} ${API_SUCCESS}`: {
                dispatch(getFacilityList());
                const { message } = action.payload;
                dispatch(
                    createNotification({
                        type: 'success',
                        message: message || i18next.t('notification.successUpdated'),
                        time: 3000,
                    })
                );
                break;
            }

            case `${UPDATE_FACILITY} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case DELETE_FACILITY_INVERTORY: {
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: `/corporateEmission/${action.payload}`,
                        feature: DELETE_FACILITY_INVERTORY,
                    })
                );
                break;
            }

            case `${DELETE_FACILITY_INVERTORY} ${API_SUCCESS}`: {
                window.location.reload();
                break;
            }

            case SUBMIT_FACILITY_FORM: {
                const companyId = state.auth.userInfo?.company?.id;
                const organizationId = state.auth.userInfo?.company?.organizationId;

                interface IBody {
                    organizationId: number;
                    facilityName: string;
                    email: string;
                    countryId: number;
                    stateId: number;
                    address: string;
                    phone: number;
                    companyId: number;
                }

                const body: IBody = {
                    facilityName: state.company.facility.facilityName,
                    countryId: state.company.facility.country.id,
                    stateId: state.company.facility.city.id,
                    address: state.company.facility.address,
                    phone: state.company.facility.phone,
                    email: state.company.facility.email,
                    companyId,
                    organizationId,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/facility',
                        feature: SUBMIT_FACILITY_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_FACILITY_FORM} ${API_SUCCESS}`: {
                const onSuccess = action.meta.onSuccess;
                onSuccess();
                window.location.reload();
                break;
            }
            case `${SUBMIT_FACILITY_FORM} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_FACILITY_DASHBOARD_DATA: {
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/dashboard/facility',
                        feature: GET_FACILITY_DASHBOARD_DATA,
                        body: {
                            organizationId: state?.auth?.userInfo?.organization?.id,
                            companyId:
                                state?.company?.facility?.facility?.companyId ||
                                state?.auth?.userInfo?.company?.id ||
                                state?.auth?.userInfo?.facility?.companyId,
                            facilityId: state?.company?.facility?.facility?.id || state?.auth?.userInfo?.facility?.id,
                            periodStart: state.company.company.period.start,
                            periodEnd: state.company.company.period.end,
                        },
                    })
                );
                break;
            }
            case `${GET_FACILITY_DASHBOARD_DATA} ${API_SUCCESS}`: {
                const { dashboardDto } = action.payload;

                const category = dashboardDto.emissionCategoryTypeStatisticList.reduce(
                    (
                        acc: { data: Array<number>; labels: Array<string> },
                        v: { categoryTypeName: string; totalEmission: number | undefined }
                    ) => {
                        acc.data.push(v.totalEmission || 0);
                        acc.labels.push(v.categoryTypeName);
                        return acc;
                    },
                    {
                        data: [],
                        labels: [],
                    }
                );
                const monthlyCumulative = dashboardDto.monthlyStatisticList.reduce(
                    (
                        acc: {
                            monthly: { data: Array<number>; labels: Array<string> };
                            cumulative: { data: Array<number>; labels: Array<string> };
                        },
                        v: {
                            period: string;
                            totalEmission: number | undefined;
                            cumulativeTotalEmission: number | undefined;
                        }
                    ) => {
                        acc.monthly.data.push(v.totalEmission || 0);
                        acc.monthly.labels.push(v.period);
                        acc.cumulative.data.push(v.cumulativeTotalEmission || 0);
                        acc.cumulative.labels.push(v.period);
                        return acc;
                    },
                    {
                        monthly: {
                            data: [],
                            labels: [],
                        },
                        cumulative: {
                            data: [],
                            labels: [],
                        },
                    }
                );

                const scopes = dashboardDto.emissionScopeStatisticList.reduce(
                    (
                        acc: { scopes: { data: Array<number>; labels: Array<string> } },
                        v: {
                            scope: string;
                            scopeName: string;
                            totalEmission: number;
                            totalEmissionPercentage: number;
                        }
                    ) => {
                        acc.scopes.data.push(v.totalEmission || 0);
                        acc.scopes.labels.push(v.scopeName);
                        return acc;
                    },
                    {
                        scopes: {
                            data: [],
                            labels: [],
                        },
                    }
                );

                const data = {
                    ...monthlyCumulative,
                    ...scopes,
                    allTimeTotalEmission: dashboardDto.periodTotalEmission, //.allTimeTotalEmission,
                    monthlyTotal: dashboardDto.periodAverageMonthlyEmission,
                    total: dashboardDto.periodTotalEmission,
                    category: category,
                };

                next(setCompanyDashboardData(data));
                break;
            }

            case GET_FACILITY_DETAIL: {
                const facilityId = state.company?.facility?.facility?.id || '';
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/company/facility/${facilityId}`,
                        feature: GET_FACILITY_DETAIL,
                    })
                );
                break;
            }

            case `${GET_FACILITY_DETAIL} ${API_SUCCESS}`: {
                const data = action.payload.facility;
                next(setFacilityDetail(data));
                break;
            }

            case GET_FACILITY_GLOBAL_LIST: {
                const organization = !!state?.auth?.userInfo?.organization;
                const company = !!state?.auth?.userInfo?.company;
                const facility = !!state?.auth?.userInfo?.facility;

                interface IBody {
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                }
                const body: IBody = {};
                if (organization) {
                    body['organizationId'] = state?.auth?.userInfo?.organization?.id;
                }
                if (company) {
                    body['companyId'] = state?.auth?.userInfo?.company?.id;
                }
                if (facility) {
                    body['facilityId'] = state?.auth?.userInfo?.facility?.id;
                }

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporation/scheme',
                        feature: GET_FACILITY_GLOBAL_LIST,
                        body,
                    })
                );
                break;
            }

            case `${GET_FACILITY_GLOBAL_LIST} ${API_SUCCESS}`: {
                let list: Array<IGlobalFacility> = [];
                if (!action.payload?.corporationPart?.subPartSet) {
                    list.push({
                        id: action.payload?.corporationPart?.id,
                        key: action.payload?.corporationPart?.name,
                        label: action.payload?.corporationPart?.name,
                        type: action.payload?.corporationPart?.type,
                    });
                }
                action.payload?.corporationPart?.subPartSet?.map((item: any) => {
                    list.push({
                        id: item?.id,
                        key: item?.name,
                        label: item?.name,
                        type: item?.type,
                    });
                    if (item?.subPartSet) {
                        item?.subPartSet?.map((i: any) => {
                            list.push({
                                id: i?.id,
                                key: i?.name,
                                label: i?.name,
                                type: i?.type,
                                companyId: item?.id,
                            });
                        });
                    }
                });
                next(setFacilityGlobalList(list));
                break;
            }

            // endregion MOBILE
        }
    };
