import { AnyAction } from 'redux';
import { SET_ORGANIZATIONAL_RESULT } from '../../actions/company/organizational.action';
import {
    CHANGE_QUANTIFIED_ACTIVITY_DATA_AND_QUALITY_MANAGEMENT,
    CHANGE_QUANTIFIED_CALCULATION_METHODOLOGIES,
    CHANGE_QUANTIFIED_CHANGES_CALCULATION_METHODOLOGY,
    CHANGE_QUANTIFIED_EMISSION_REDUCTION_INCREASES,
    CHANGE_QUANTIFIED_EMISSION_REMOVALS,
    CHANGE_QUANTIFIED_EVALUATION_UNCERTAINTY,
    CHANGE_QUANTIFIED_GREEN_HOUSE_GAS,
    CHANGE_QUANTIFIED_RECALCULATION_GREEN_HOUSE_GAS,
    CHANGE_QUANTIFIED_REVIEW_AND_IMPROVEMENTS,
    GET_QUANTIFIED_DETAIL_BY_ID,
    SET_QUANTIFIED_CONTENT,
    SET_QUANTIFIED_DETAIL_BY_ID,
    SET_QUANTIFIED_LIST,
    SET_QUANTIFIED_RESULT_LIST,
} from '../../actions/company/quantified.action';
import {
    IDetailedItem,
    IQuantified,
    IQuantifiedCategory,
    IQuantifiedFacility,
    IQuantifiedResult,
} from '../../../entities/company/quantifiedCombustion';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';

const initialState = {
    referanceList: [] as Array<IQuantified>,
    referanceLoading: false,
    categoryResult: {} as IQuantifiedCategory,
    facilityResult: {} as IQuantifiedFacility,
    resultLoading: false,
    activityDataAndQualityManagement:
        'Sera gazı hesabının yapılmasında kullanılan faaliyet verileri kurumumuz bünyesinde uygulanmakta olan Kalite Yönetim Sistemi proseslerine uygun olarak toplanmakta ve kontrol edilmektedir. Envanter dahilinde yer alan verilerin kim tarafından, nerde, ne zaman, nasıl toplanacağına dair Sera Gazı Faaliyet Verisi İzleme Planı hazırlanmış ve uygulanmaktadır.\n' +
        ' \n' +
        'Sera Gazı Envanteri Kalite Yönetimi firmamız bünyesinde oluşturulmuş olan Doküman ve Kayıt Kontrol, Düzeltici Faaliyet, İç Tetkik, Yönetim Gözden Geçirme, Eğitim, Kalibrasyon gibi prosesler aracılığı ile sağlanmaktadır. \n',
    calculationMethodologies:
        'Kurumumuzda sera gazı envanterinde yer alan ve aşağıda açıklanan emisyon kaynaklarında ölçüm metodolojisi kullanılarak sera gazı miktarının tespiti için bir sistem bulunmadığından, hesaplama bazlı metodolojiler tercih edilmiştir. \n' +
        '\n' +
        'Hesaplama metodolojileri ile ilgili detaylara (Greenhouse Gas Protocol (GHG), Intergovernmental Panel on Climate Change (2006) (IPCC)) ilgili dokümanlarından ulaşılabilmektedir.\n',
    changesCalculationMethodology: 'Hesaplama metodolojisinden hiçbir değişiklik yapılmamıştır.',
    reviewAndImprovements:
        '“.... ….. …. ” tarihinde sona eren yıla ait bu rapor, “..... ….. …..” tarihinde sona eren rapora paralel olarak hazırlanmıştır.\n' +
        'Tüm önemli emisyon kaynakları için birincil veriler aranmıştır. Verilerin mevcut olmadığı veya karşılaştırılabilir olmadığı durumlarda, birincil verilerin tahmin yaklaşımlarına oranını sürekli olarak iyileştirmeye yönelik teşvikler olacak şekilde muhafazakar tahmin yöntemleri uygulanmıştır.\n' +
        'Gelecek yıllarda niyetimiz, emisyon rakamlarını yakalamak ve hesaplamak ve belirsizliği azaltmak için çerçeve ve sürecimizdeki iyileştirmeleri vurgulamak için bu bölümü kullanmaktır.\n',
    greenhouseGasInventoryInformationManagement:
        'Sera gazı ölçümü ve raporlama süreci, ISO 14064-1:2018 standardının ilkelerine uygunluğu sağlamak ve sera gazı envanterinin kullanım amacı ile tutarlı olmak için geliştirilmiştir.\n' +
        'Prosedür öğeleri, envanterin doğruluğunu ve eksiksizliğini sağlamak ve hata ve eksiklikleri ele almak için yapı ve tutarlı kontroller oluşturmak üzere tasarlanmıştır.\n',
    evaluationUncertainty:
        'Faaliyet verilerinin toplanması sırasında objektif belirsizlik kanıtlarının (ölçü cihazları, ölçüm metodu, kaçak gazlar) elde edilememesinden dolayı belirsizlik değeri rakamsal olarak verilememiştir.',
    recalculationGreenhouseGasInventory:
        'Kurumumuz aşağıda belirtilen durumların oluşması halinde, sera gazı emisyonu veya uzaklaştırmalarına ilişkin yeniden hesaplama işlemi gerçekleştirecektir.\n' +
        '\n' +
        'İşletme sınırlarındaki değişiklik,\n' +
        'Kurumumuz sınırlarının içine veya dışına aktarılan sera gazı kaynaklarının veya yutaklarının sahiplik ve kontrol bilgileri,\n' +
        'Hesaplanmış sera gazı emisyonları veya uzaklaştırmalarında önemli değişikliğe sebep olan sera gazı hesaplama metodolojilerindeki değişiklik\n' +
        ' \n' +
        'Ülkemizde Sera Gazı Emisyonlarının hesaplanması ile ilgili yeterli alt yapının olmaması, emisyon faktörlerinin belirlenmemiş olması gibi nedenlerden ötürü hesaplamalarda ağırlıklı olarak uluslararası emisyon faktörleri ve metodları referans alınır.  Ulusal referansların yayınlanması durumunda gerekli güncellemeler yapılacaktır.\n' +
        ' \n' +
        'Tesisin belirli bir dönem için açılıp/kapanması durumunda sera gazı envanteri yeniden hesaplanmayacaktır.\n' +
        '\n' +
        'Yeniden hesaplama işlemi aşağıdaki basamaklara göre Sera Gazı Yönetim Komitesi koordinasyonunda gerçekleştirilir;\n' +
        '\n' +
        'Kuruluş ve faaliyet sınırları gözden geçirilir ve değişiklikler doğrultusunda güncellenir,\n' +
        'Doğrudan, enerji dolaylı ve diğer dolaylı sera gazı emisyonlarındaki değişiklikler gözden geçirilir ve yeniden belirlenir,\n' +
        'Sera gazı kaynakları ve yutakları mevcut duruma göre gözden geçirilir ve gerekiyorsa yeni kaynaklar ve yutaklar tanımlanır. \n' +
        'Hesaplama metodolojisinde değişiklik olması durumunda bu değişiklikler tanımlanır ve önceki dönem hesaplamalarında da yeni hesaplama metodolojisi uygulanır. Tüm hesaplamalar yeni metodolojiye göre yeniden yapılır,\n' +
        'Sera gazı faaliyet verileri yeni kapsam/hesaplama metodolojisi doğrultusunda gözden geçirilir ve gerekiyorsa güncellenir,\n' +
        'Belirsizlikler yeniden hesaplanır,\n' +
        'Sera gazı raporu değişiklikleri yansıtacak şekilde yeniden oluşturulur,\n' +
        'Doğrulama kuruluşuna güncel raporun doğrulanması için başvuruda bulunulur,\n' +
        '\n' +
        'Yeni verilerin eski tarihlere uygulanmadığı durumlarda güncel trend analizleri kullanılarak geriye dönük çıkarımlarda bulunulabilir veya güncel değişiklikler yeni hesaplama yapmadan geriye dönük olarak da kabul edilebilir. \n',
    emissionsRemovals: 'Bu raporlama döneminde beyan edilecek herhangi bir emisyon uzaklaştırmaları bulunmamaktadır.',
    emissionReductionsIncreases:
        "Küresel operasyonlarımız da her yıl 9.339.6 ton emisyon artışı kaydettik. Bu, brüt emisyonlarda %0.56'lık bir artışı temsil ediyor.\n" +
        "Buna karşılık, son iki yılda gelir, yerel tonaj ve deniz navlun hacimlerinin %10'dan fazla büyüdüğünü gördük.\n" +
        'Büyümeye kıyasla emisyonlardaki mütevazı artış, bu ilk yıl için yıllık karşılaştırma için iyiye işaret ediyor.\n',
    detail: {
        isLoading: false,
        activityData: '',
        data: [] as Array<IDetailedItem>,
    },
};

export const quantifiedCommonReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case SET_AUTH_LOGOUT:
            return initialState;
        case GET_QUANTIFIED_DETAIL_BY_ID: {
            return {
                ...state,
                detail: {
                    activityData: action.payload.inventoryActivityData,
                    isLoading: false,
                    data: [],
                },
            };
        }
        case SET_QUANTIFIED_DETAIL_BY_ID: {
            return {
                ...state,
                detail: {
                    ...state.detail,
                    isLoading: true,
                    data: action.payload,
                },
            };
        }
        case SET_QUANTIFIED_LIST:
            const data: Array<IQuantified> = action.payload;
            return {
                ...state,
                referanceList: data,
                referanceLoading: true,
            };
        case SET_QUANTIFIED_RESULT_LIST:
            const list: IQuantifiedResult = action.payload;
            return {
                ...state,
                categoryResult: list.categoryTypeTotalEmissionsStatisticDto,
                facilityResult: list.facilityTotalEmissionStatisticDto,
                resultLoading: true,
            };
        case SET_QUANTIFIED_CONTENT:
            const d = action.payload;
            return {
                ...state,
                activityDataAndQualityManagement: d.activityDataAndQualityManagement,
                calculationMethodologies: d.calculationMethodologies,
                changesCalculationMethodology: d.changesCalculationMethodology,
                reviewAndImprovements: d.reviewAndImprovements,
                greenhouseGasInventoryInformationManagement: d.greenhouseGasInventoryInformationManagement,
                evaluationUncertainty: d.evaluationUncertainty,
                recalculationGreenhouseGasInventory: d.recalculationGreenhouseGasInventory,
                emissionsRemovals: d.emissionsRemovals,
                emissionReductionsIncreases: d.emissionReductionsIncreases,
            };

        case CHANGE_QUANTIFIED_ACTIVITY_DATA_AND_QUALITY_MANAGEMENT: {
            return {
                ...state,
                activityDataAndQualityManagement: action.payload,
            };
        }
        case CHANGE_QUANTIFIED_CALCULATION_METHODOLOGIES: {
            return {
                ...state,
                calculationMethodologies: action.payload,
            };
        }
        case CHANGE_QUANTIFIED_CHANGES_CALCULATION_METHODOLOGY: {
            return {
                ...state,
                changesCalculationMethodology: action.payload,
            };
        }
        case CHANGE_QUANTIFIED_REVIEW_AND_IMPROVEMENTS: {
            return {
                ...state,
                reviewAndImprovements: action.payload,
            };
        }
        case CHANGE_QUANTIFIED_GREEN_HOUSE_GAS: {
            return {
                ...state,
                greenhouseGasInventoryInformationManagement: action.payload,
            };
        }
        case CHANGE_QUANTIFIED_EVALUATION_UNCERTAINTY: {
            return {
                ...state,
                evaluationUncertainty: action.payload,
            };
        }
        case CHANGE_QUANTIFIED_RECALCULATION_GREEN_HOUSE_GAS: {
            return {
                ...state,
                recalculationGreenhouseGasInventory: action.payload,
            };
        }
        case CHANGE_QUANTIFIED_EMISSION_REMOVALS: {
            return {
                ...state,
                emissionsRemovals: action.payload,
            };
        }
        case CHANGE_QUANTIFIED_EMISSION_REDUCTION_INCREASES: {
            return {
                ...state,
                emissionReductionsIncreases: action.payload,
            };
        }
        default:
            return state;
    }
};
