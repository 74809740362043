export const PRIVILEGE = '[PRIVILEGE]';

export const GET_PRIVILEGE_ENUM_PRIVILEGE = `${PRIVILEGE} Get Privilege Enum Privilege`;
export const SET_PRIVILEGE_ENUM_PRIVILEGE = `${PRIVILEGE} Set Privilege Enum Privilege`;
export const GET_PRIVILEGE_ENUM_PERMISSION = `${PRIVILEGE} Get Privilege Enum Permission`;
export const SET_PRIVILEGE_ENUM_PERMISSION = `${PRIVILEGE} Set Privilege Enum Permission`;

export type ISubmit = {
    onSuccess: () => void;
};

export const getPrivilegeEnumPrivilege = () => ({
    type: GET_PRIVILEGE_ENUM_PRIVILEGE,
});
export const setPrivilegeEnumPrivilege = () => ({
    type: SET_PRIVILEGE_ENUM_PRIVILEGE,
});
export const getPrivilegeEnumPermission = () => ({
    type: GET_PRIVILEGE_ENUM_PERMISSION,
});
export const setPrivilegeEnumPermission = () => ({
    type: SET_PRIVILEGE_ENUM_PERMISSION,
});
