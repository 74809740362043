import { AnyAction } from 'redux';
import { SUBMIT_AUTH_LOGIN, SET_LOADING } from '../../actions/auth/auth.action';

const initialState = {
    loginLoading: false,
};

export const loginReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loginLoading: false,
            };
        case SUBMIT_AUTH_LOGIN:
            return {
                ...state,
                loginLoading: true,
            };
        default:
            return state;
    }
};
