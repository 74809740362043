import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension';
import { apiMiddleware } from './middleware/core/api.middleware';
import { emissionMiddlewares } from './middleware/feature/emission/index.middleware';
import { uiMiddleware } from './middleware/core/ui.middleware';
import { authenticationReducer } from './reducers/auth/auth.reducer';
import { companyReducer } from './reducers/company/index.reducer';
import { emissionReducer } from './reducers/emisssion/index.reducer';
import { uiReducer } from './reducers/ui/ui.reducer';
import { systemReducer } from './reducers/system/system.reducer';
import { kpiReducers } from './reducers/kpi/index.reducer';
import { resetPasswordReducer } from './reducers/auth/resetPassword.reducer';
import { loginReducer } from './reducers/auth/login.reducer';

const persistConfig = {
    key: 'root',
    whitelist: ['auth', 'company', 'kpi', 'ui'],
    storage,
    version: 12,
};

const rootReducer = combineReducers({
    auth: authenticationReducer,
    resetPassword: resetPasswordReducer,
    login: loginReducer,
    emission: emissionReducer,
    company: companyReducer,
    ui: uiReducer,
    system: systemReducer,
    kpi: kpiReducers,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const featureMiddlewares = [...emissionMiddlewares];

const coreMiddlewares = [apiMiddleware, uiMiddleware];

const enhancer = composeWithDevTools(applyMiddleware(...featureMiddlewares, ...coreMiddlewares));

export const store = createStore(persistedReducer, {}, enhancer);
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
