import { AnyAction } from 'redux';
import { CHANGE_EXCEL_DESCRIPTION } from '../../actions/emission/excel.action';

const initialState = {
    description: '',
};

export const emissionExcelReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case CHANGE_EXCEL_DESCRIPTION: {
            return {
                ...state,
                description: action.payload,
            };
        }
        default:
            return state;
    }
};
