import { AnyAction } from 'redux';
import { SET_ANALYTIC_COMPANY, SET_ANALYTIC_FACILITY } from '../../actions/company/analytic.action';
import { ICompanyAnalytic } from '../../../entities/company/analyticCombustion';
import { SET_AUTH_LOGOUT } from '../../actions/auth/auth.action';
import { SET_ORGANIZATION_ANALYTIC_DATA } from '../../actions/company/organization.action';

const initialState = {
    companyData: {
        totalCalculatedEmission: 0,
        periodTotalCalculatedEmission: 0,
        periodAverageCalculatedEmission: 0,
        scopeTotalCalculatedEmissionStatisticDtoList: [
            {
                scope: '',
                scopeName: '',
                totalEmission: 0,
                totalEmissionPercentage: 0,
            },
        ],
        categoryTypeTotalCalculatedEmissionStatisticDtoList: [
            {
                scope: '',
                scopeName: '',
                categoryType: '',
                categoryTypeName: '',
                totalEmission: 0,
                totalEmissionPercentage: 0,
            },
        ],
        scopeTotalCalculatedEmissionPeriodsStatisticList: [
            {
                scope: '',
                scopeName: '',
                monthlyTotalCalculatedEmissionStatisticList: [
                    {
                        period: '',
                        totalEmission: 0,
                    },
                ],
            },
        ],
        categoryTypeTotalCalculatedEmissionPeriodsStatisticList: [
            {
                categoryType: '',
                categoryTypeName: '',
                monthlyTotalCalculatedEmissionStatisticList: [
                    {
                        period: '',
                        totalEmission: 0,
                    },
                ],
            },
        ],
        categoryTotalCalculatedEmissionPeriodsStatisticList: [
            {
                scope: '',
                scopeName: '',
                categoryType: '',
                categoryTypeName: '',
                category: '',
                categoryName: '',
                monthlyTotalCalculatedEmissionStatisticList: [
                    {
                        period: '',
                        totalEmission: 0,
                    },
                ],
            },
        ],
        categoryTotalCalculatedEmissionStatisticDtoList: [
            {
                scope: '',
                scopeName: '',
                categoryType: '',
                categoryTypeName: '',
                category: '',
                categoryName: '',
                totalEmission: 0,
                totalEmissionPercentage: 0,
            },
        ],
        monthlyCumulativeEmissionStatisticList: [
            {
                period: '',
                totalEmission: 0,
                cumulativeTotalEmission: 0,
            },
        ],
    } as ICompanyAnalytic,
};

export const analyticCommonReducer = (state = initialState, action: AnyAction): typeof initialState => {
    switch (action.type) {
        case SET_AUTH_LOGOUT:
            return initialState;
        case SET_ORGANIZATION_ANALYTIC_DATA:
            return {
                ...state,
                companyData: action.payload,
            };
        case SET_ANALYTIC_COMPANY:
            return {
                ...state,
                companyData: action.payload,
            };
        case SET_ANALYTIC_FACILITY:
            return {
                ...state,
                companyData: action.payload,
            };
        default:
            return state;
    }
};
