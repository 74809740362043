import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import LayoutHeader from '../layouts/LayoutHeader';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'common' });

    return (
        <LayoutHeader>
            404
            <Button component={Link} to="/">
                {t("homePage")}
            </Button>
        </LayoutHeader>
    );
};

export default NotFound;
