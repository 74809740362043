import { ITag } from '../../../entities/emissionTypes/baseType';

export const EMISSION = '[Emission]';
export const BASE = 'BASE';

export const SELECT_TYPE = `${EMISSION} Select Type`;

export const CHANGE_BASE_TAGS = `${EMISSION} ${BASE} Change Tags`;
export const CHANGE_BASE_EQUIVALENT_TAGS = `${EMISSION} ${BASE} Change Equivalent Tags`;

export const GET_BASE_TAGS = `${EMISSION} ${BASE} Get Base Tags`;
export const SET_BASE_TAGS = `${EMISSION} ${BASE} Set Base Tags`;
export const SELECT_BASE_TAGS = `${EMISSION} ${BASE} Select Base Tags`;

export const UPLOAD_FILE_TO_INVENTORY = `${EMISSION} ${BASE} Upload File to Inventory`;
export const DOWNLOAD_FILE_TO_INVENTORY = `${EMISSION} ${BASE} Download File From Inventory`;

export const GET_BASE_COMPANIES = `${EMISSION} ${BASE} Get Base Companies`;
export const SET_BASE_COMPANIES = `${EMISSION} ${BASE} Set Base Companies`;
export const SELECT_BASE_COMPANY = `${EMISSION} ${BASE} Select Base Company`;
export const SET_BASE_COMPANIES_LEVEL_COMPANY = `${EMISSION} ${BASE} Get Base Companies Level Company`;

export const GET_BASE_FACILITIES = `${EMISSION} ${BASE} Get Base Facilities`;
export const SET_BASE_FACILITIES = `${EMISSION} ${BASE} Set Base Facilities`;
export const SELECT_BASE_FACILITY = `${EMISSION} ${BASE} Select Base Facility`;
export const SET_BASE_FACILITY_LEVEL_FACILITY = `${EMISSION} ${BASE} Get Base Facility Level Facility`;

export const SELECT_BASE_CALCULATION_YEAR = `${EMISSION} ${BASE} Select Base Calculation Year`;

export const GET_BASE_YEARS = `${EMISSION} ${BASE} Get Base Years`;
export const CLEAR_BASE_SPECIAL_INPUTS = `${EMISSION} ${BASE} Clear Special Inputs`;
export const CLEAR_BASE_INPUTS = `${EMISSION} ${BASE} Clear Inputs`;

export const UPLOAD_EXCEL = `${EMISSION} ${BASE} Upload Excel`;
export const SET_FORM_LOADER = `${EMISSION} Set Form Loader`;

export type ISubmit = {
    onSuccess: () => void;
};

export const getBaseYears = (language: string) => ({
    type: GET_BASE_YEARS,
    payload: language,
});

export const clearBaseInputs = () => ({
    type: CLEAR_BASE_INPUTS,
});

export const getBaseTags = (value: string) => ({
    type: GET_BASE_TAGS,
    payload: value,
});

export const uploadExcel = ({ onSuccess }: ISubmit, data: { doc: any }) => ({
    type: UPLOAD_EXCEL,
    meta: {
        onSuccess,
    },
    payload: data,
});

export const uploadFileToInventory = ({ onSuccess }: ISubmit, data: { doc: any; id: number }) => ({
    type: UPLOAD_FILE_TO_INVENTORY,
    meta: {
        onSuccess,
    },
    payload: data,
});

export const downloadFileFromInventory = ({ onSuccess }: ISubmit, doc: any) => ({
    type: DOWNLOAD_FILE_TO_INVENTORY,
    meta: {
        onSuccess,
    },
    payload: doc,
});

export const setBaseTags = (value: Array<ITag>) => ({
    type: SET_BASE_TAGS,
    payload: value,
});

export const selectBaseTags = (value: ITag) => ({
    type: SELECT_BASE_TAGS,
    payload: value,
});

export const changeBaseTags = (value: string) => ({
    type: CHANGE_BASE_TAGS,
    payload: value,
});

export const changeBaseEquivalentTags = (value: string) => ({
    type: CHANGE_BASE_EQUIVALENT_TAGS,
    payload: value,
});

export const getBaseCompanies = () => ({
    type: GET_BASE_COMPANIES,
});
export const setBaseCompanies = (data: any) => ({
    type: SET_BASE_COMPANIES,
    payload: data,
});
export const selectBaseCompany = (company: any) => ({
    type: SELECT_BASE_COMPANY,
    payload: company,
});
export const setBaseCompaniesLevelCompany = (data: any) => ({
    type: SET_BASE_COMPANIES_LEVEL_COMPANY,
    payload: data,
});
export const getBaseFacilities = (company: any) => ({
    type: GET_BASE_FACILITIES,
    payload: company,
});
export const setBaseFacilities = (data: any) => ({
    type: SET_BASE_FACILITIES,
    payload: data,
});
export const selectBaseFacility = (facility: any) => ({
    type: SELECT_BASE_FACILITY,
    payload: facility,
});

export const selectBaseCalculationYear = (year?: number | string) => ({
    type: SELECT_BASE_CALCULATION_YEAR,
    payload: year,
});

export const setBaseFacilityLevelFacility = (data: any) => ({
    type: SET_BASE_FACILITY_LEVEL_FACILITY,
    payload: data,
});
export const clearBaseSpecialInputs = (list: Array<string>) => ({
    type: CLEAR_BASE_SPECIAL_INPUTS,
    payload: list,
});

export const setFormLoader = (value: boolean = false) => ({
    type: SET_FORM_LOADER,
    payload: value,
});
