import { Middleware } from 'redux';
import { API_SUCCESS, apiRequest } from '../../../actions/api.action';
import { GET_PRIVILEGE_ENUM_PERMISSION, GET_PRIVILEGE_ENUM_PRIVILEGE } from '../../../actions/system/privilege.action';

export const privilegeMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_PRIVILEGE_ENUM_PRIVILEGE: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/auth/privilege/enum/privilege',
                        feature: GET_PRIVILEGE_ENUM_PRIVILEGE,
                    })
                );
                break;
            }
            case `${GET_PRIVILEGE_ENUM_PRIVILEGE} ${API_SUCCESS}`: {
                break;
            }

            case GET_PRIVILEGE_ENUM_PERMISSION: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/auth/privilege/enum/permission',
                        feature: GET_PRIVILEGE_ENUM_PERMISSION,
                    })
                );
                break;
            }
            case `${GET_PRIVILEGE_ENUM_PERMISSION} ${API_SUCCESS}`: {
                break;
            }
        }
    };
