import { Middleware } from 'redux';
import { API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_ANALYTIC_COMPANY,
    GET_ANALYTIC_FACILITY,
    setAnalyticCompany,
    setAnalyticFacility,
} from '../../../actions/company/analytic.action';

export const analyticMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_ANALYTIC_COMPANY: {
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                    periodStart: string;
                    periodEnd: string;
                }

                const body: IBody = {
                    organizationId: state?.auth?.userInfo?.organization?.id,
                    companyId: state?.company?.facility?.facility?.id || state?.auth?.userInfo?.company?.id, // will be change here
                    facilityId: state?.auth?.userInfo?.facility?.id,
                    periodStart: state.company.company.period.start,
                    periodEnd: state.company.company.period.end,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/analytic/company',
                        feature: GET_ANALYTIC_COMPANY,
                        body,
                    })
                );
                break;
            }
            case `${GET_ANALYTIC_COMPANY} ${API_SUCCESS}`: {
                const data = action.payload.corporateEmissionAnalytic;
                next(setAnalyticCompany(data));
                break;
            }

            case GET_ANALYTIC_FACILITY: {
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                    periodStart: string;
                    periodEnd: string;
                }

                const body: IBody = {
                    organizationId: state?.auth?.userInfo?.organization?.id,
                    companyId:
                        state?.company?.facility?.facility?.companyId ||
                        state?.auth?.userInfo?.company?.id ||
                        state?.auth?.userInfo?.facility?.companyId,
                    facilityId: state?.company?.facility?.facility?.id || state?.auth?.userInfo?.facility?.id,
                    periodStart: state.company.company.period.start,
                    periodEnd: state.company.company.period.end,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/analytic/facility',
                        feature: GET_ANALYTIC_FACILITY,
                        body,
                    })
                );
                break;
            }
            case `${GET_ANALYTIC_FACILITY} ${API_SUCCESS}`: {
                const data = action.payload.corporateEmissionAnalytic;
                next(setAnalyticFacility(data));
                break;
            }
        }
    };
