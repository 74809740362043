import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import { CHECK_RESET_CODE, CHECK_RESET_EMAIL, SUBMIT_RESET_PASSWORD } from '../../../actions/auth/resetPassword.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const resetPasswordMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            // STEP 1
            case CHECK_RESET_EMAIL: {
                const body = {
                    email: action.payload,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/check-email',
                        body,
                        feature: CHECK_RESET_EMAIL,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }

            case `${CHECK_RESET_EMAIL} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.emailNotFound'),
                    })
                );
                break;
            }

            // STEP 2
            case CHECK_RESET_CODE: {
                const body = {
                    code: action.payload,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/check-code',
                        body,
                        feature: CHECK_RESET_CODE,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }

            case `${CHECK_RESET_CODE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.codeError'),
                    })
                );
                break;
            }

            // STEP 3
            case SUBMIT_RESET_PASSWORD: {
                const body = {
                    password: action.payload,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/submit-reset-password',
                        body,
                        feature: SUBMIT_RESET_PASSWORD,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }

            case `${SUBMIT_RESET_PASSWORD} ${API_SUCCESS}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'success',
                        message: data.message || i18next.t('notification.successUpdated'),
                    })
                );
                break;
            }

            case `${SUBMIT_RESET_PASSWORD} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }
        }
    };
