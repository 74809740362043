import { Middleware } from 'redux';
import { API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    GET_ORGANIZATION_ANALYTIC_DATA,
    GET_ORGANIZATION_DASHBOARD_DATA,
    GET_ORGANIZATION_IMAGE,
    GET_ORGANIZATION_RESULT,
    getOrganizationResult,
    setOrganizationAnalyticData,
    setOrganizationImage,
    setOrganizationResult,
    SUBMIT_ORGANZATION_FORM,
    UPLOAD_ORGANIZATION_IMAGE,
} from '../../../actions/company/organization.action';
import { setCompanyDashboardData } from '../../../actions/company/company.action';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const organizationMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_ORGANIZATION_DASHBOARD_DATA: {
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                    periodStart: string;
                    periodEnd: string;
                }
                const body: IBody = {
                    organizationId: state?.auth?.userInfo?.organization?.id,
                    companyId: state?.auth?.userInfo?.company?.id,
                    facilityId: state?.auth?.userInfo?.facility?.id,
                    periodStart: state.company.company.period.start,
                    periodEnd: state.company.company.period.end,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/dashboard/organization',
                        feature: GET_ORGANIZATION_DASHBOARD_DATA,
                        body,
                    })
                );
                break;
            }
            case `${GET_ORGANIZATION_DASHBOARD_DATA} ${API_SUCCESS}`: {
                const { dashboardDto } = action.payload;

                const category = dashboardDto.emissionCategoryTypeStatisticList.reduce(
                    (
                        acc: { data: Array<number>; labels: Array<string> },
                        v: { categoryTypeName: string; totalEmission: number | undefined }
                    ) => {
                        acc.data.push(v.totalEmission || 0);
                        acc.labels.push(v.categoryTypeName);
                        return acc;
                    },
                    {
                        data: [],
                        labels: [],
                    }
                );
                const monthlyCumulative = dashboardDto.monthlyStatisticList.reduce(
                    (
                        acc: {
                            monthly: { data: Array<number>; labels: Array<string> };
                            cumulative: { data: Array<number>; labels: Array<string> };
                        },
                        v: {
                            period: string;
                            totalEmission: number | undefined;
                            cumulativeTotalEmission: number | undefined;
                        }
                    ) => {
                        acc.monthly.data.push(v.totalEmission || 0);
                        acc.monthly.labels.push(v.period);
                        acc.cumulative.data.push(v.cumulativeTotalEmission || 0);
                        acc.cumulative.labels.push(v.period);
                        return acc;
                    },
                    {
                        monthly: {
                            data: [],
                            labels: [],
                        },
                        cumulative: {
                            data: [],
                            labels: [],
                        },
                    }
                );

                const scopes = dashboardDto.emissionScopeStatisticList.reduce(
                    (
                        acc: { scopes: { data: Array<number>; labels: Array<string> } },
                        v: {
                            scope: string;
                            scopeName: string;
                            totalEmission: number;
                            totalEmissionPercentage: number;
                        }
                    ) => {
                        acc.scopes.data.push(v.totalEmission || 0);
                        acc.scopes.labels.push(v.scopeName);
                        return acc;
                    },
                    {
                        scopes: {
                            data: [],
                            labels: [],
                        },
                    }
                );

                const data = {
                    ...monthlyCumulative,
                    ...scopes,
                    allTimeTotalEmission: dashboardDto.periodTotalEmission, //.allTimeTotalEmission,
                    monthlyTotal: dashboardDto.periodAverageMonthlyEmission,
                    total: dashboardDto.periodTotalEmission,
                    category: category,
                };

                dispatch(setCompanyDashboardData(data));
                break;
            }

            case GET_ORGANIZATION_ANALYTIC_DATA: {
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                    periodStart: string;
                    periodEnd: string;
                }
                const body: IBody = {
                    organizationId: state?.auth?.userInfo?.organization?.id,
                    companyId: state?.auth?.userInfo?.company?.id,
                    facilityId: state?.auth?.userInfo?.facility?.id,
                    periodStart: state.company.company.period.start,
                    periodEnd: state.company.company.period.end,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/analytic/organization',
                        feature: GET_ORGANIZATION_ANALYTIC_DATA,
                        body,
                    })
                );
                break;
            }
            case `${GET_ORGANIZATION_ANALYTIC_DATA} ${API_SUCCESS}`: {
                const data = action.payload?.corporateEmissionAnalytic;
                dispatch(setOrganizationAnalyticData(data));
                break;
            }

            case GET_ORGANIZATION_RESULT: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/organization/${state.auth.userInfo?.organization?.id}`,
                        feature: GET_ORGANIZATION_RESULT,
                    })
                );
                break;
            }
            case `${GET_ORGANIZATION_RESULT} ${API_SUCCESS}`: {
                const data = action.payload.organizationDto;
                next(setOrganizationResult(data));
                break;
            }

            case GET_ORGANIZATION_IMAGE: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/organization/logo/get',
                        feature: GET_ORGANIZATION_IMAGE,
                        responseType: 'blob',
                        query: {
                            companyId: state.auth.userInfo?.organization?.id,
                        },
                    })
                );
                break;
            }
            case `${GET_ORGANIZATION_IMAGE} ${API_SUCCESS}`: {
                const href = URL.createObjectURL(action.payload);
                dispatch(setOrganizationImage(href));
                break;
            }

            case UPLOAD_ORGANIZATION_IMAGE: {
                const organizationId = state.auth.userInfo?.organization?.id;

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/organization/logo/add',
                        feature: UPLOAD_ORGANIZATION_IMAGE,
                        onSuccess: action.meta.onSuccess,
                        query: {
                            organizationId,
                        },
                        body: action.payload,
                    })
                );
                break;
            }
            case `${UPLOAD_ORGANIZATION_IMAGE} ${API_SUCCESS}`: {
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: i18next.t('notification.successUpdated'),
                    })
                );
                window.location.reload();
                break;
            }

            case SUBMIT_ORGANZATION_FORM: {
                interface IBody {
                    organizationId: number;
                    organizationName: string;
                    email: string;
                    websiteUrl: string;
                    phone: number;
                    //countryId: number;
                    //stateId: number;
                    //address: string;
                    //industry: string;
                    //sectorId: number;
                    //companyDescription: string;
                }

                const body: IBody = {
                    organizationId: state?.auth?.userInfo?.organization?.id,
                    organizationName: state.company.company.companyName,
                    email: state.company.company.email,
                    websiteUrl: state.company.company.websiteUrl,
                    phone: state.company.company.phone,
                    //countryId: state.company.company.country?.id,
                    //stateId: state.company.company.city?.id,
                    //address: state.company.company.address,
                    //industry: state.company.company.industry?.label,
                    //sectorId: state.company.company.industry?.id,
                    //companyDescription: state.company.company.companyDescription,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/organization',
                        feature: SUBMIT_ORGANZATION_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${SUBMIT_ORGANZATION_FORM} ${API_SUCCESS}`: {
                const { message } = action.payload;
                dispatch(getOrganizationResult());
                dispatch(
                    createNotification({
                        type: 'success',
                        message: message || i18next.t('notification.successCreated'),
                        time: 3000,
                    })
                );
                action.meta.onSuccess();
                break;
            }
        }
    };
