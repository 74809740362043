import { styled } from '@mui/material/styles';
import React, { FC } from 'react';
import SideBar from '../components/SideBar/SideBar';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';

const ContainerWrapper = styled('div')(({ theme }) => ({
    marginLeft: '65px',
    marginTop: '95px',
}));

const LayoutSideBar: FC = () => {
    return (
        <>
            <SideBar />
            <Header />
            <ContainerWrapper>
                <Outlet />
            </ContainerWrapper>
        </>
    );
};

export default LayoutSideBar;
