import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import { GET_ORGANIZATIONAL_RESULT, setOrganizationalResult } from '../../../actions/company/organizational.action';
import {
    GET_QUANTIFIED_CONTENT,
    GET_QUANTIFIED_DETAIL_BY_ID,
    GET_QUANTIFIED_LIST,
    GET_QUANTIFIED_RESULT_LIST,
    setQuantifiedContent,
    setQuantifiedDetailById,
    setQuantifiedList,
    setQuantifiedResultList,
    UPDATE_QUANTIFIED_FORM,
} from '../../../actions/company/quantified.action';
import { IDetailedItem, IQuantified, IQuantifiedResult } from '../../../../entities/company/quantifiedCombustion';
import { ICorporateEmission } from '../../../../entities/company/facilityCombustion';
import { createNotification } from '../../../actions/ui.action';

export const quantifiedMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_QUANTIFIED_LIST: {
                const companyId = state.auth.userInfo?.company?.id;
                const facilityIdList =
                    state.company.organizational?.selectedFacilities?.map((item: any) => item?.id) || [];
                interface IBody {
                    companyIdList: Array<number>;
                    facilityIdList: Array<number>;
                    period: {
                        periodStart: string;
                        periodEnd: string;
                        includeYearlyValues: boolean;
                        onlyYearlyValues: boolean;
                    };
                }

                const body: IBody = {
                    companyIdList: [companyId],
                    facilityIdList,
                    period: {
                        periodStart: state.company.company.period.start,
                        periodEnd: state.company.company.period.end,
                        includeYearlyValues: true,
                        onlyYearlyValues: false,
                    },
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporateEmission/reference',
                        feature: GET_QUANTIFIED_LIST,
                        body,
                    })
                );
                break;
            }

            case `${GET_QUANTIFIED_LIST} ${API_SUCCESS}`: {
                const data: Array<IQuantified> = action.payload.corporateEmissionReferenceList;
                next(setQuantifiedList(data));
                break;
            }

            // TODO Kullanıcı Organizasyonel Sekmesini güncellemeli
            case `${GET_QUANTIFIED_LIST} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message,
                    })
                );
                break;
            }

            case GET_QUANTIFIED_RESULT_LIST: {
                const companyId = state.auth.userInfo?.company?.id;
                const facilityIdList =
                    state.company.organizational?.selectedFacilities?.map((item: any) => item?.id) || [];
                interface IBody {
                    companyId: number;
                    facilityIdList: Array<number>;
                    period: {
                        periodStart: string;
                        periodEnd: string;
                        includeYearlyValues: boolean;
                        onlyYearlyValues: boolean;
                    };
                }

                const body: IBody = {
                    companyId,
                    facilityIdList,
                    period: {
                        periodStart: state.company.company.period.start,
                        periodEnd: state.company.company.period.end,
                        includeYearlyValues: true,
                        onlyYearlyValues: false,
                    },
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporateEmission/inventory/statistic',
                        feature: GET_QUANTIFIED_RESULT_LIST,
                        body,
                    })
                );
                break;
            }

            case `${GET_QUANTIFIED_RESULT_LIST} ${API_SUCCESS}`: {
                const data: IQuantifiedResult = action.payload?.corporateEmissionInventoryStatisticDto;
                next(setQuantifiedResultList(data));
                break;
            }

            // TODO Kullanıcı Organizasyonel Sekmesini güncellemeli
            case `${GET_QUANTIFIED_RESULT_LIST} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 5000,
                        type: 'error',
                        message: data.message,
                    })
                );
                break;
            }

            case GET_QUANTIFIED_CONTENT: {
                const companyId = state.auth.userInfo?.company?.id;

                next(
                    apiRequest({
                        method: 'GET',
                        url: `/company/quantified/inventory/${companyId}`,
                        feature: GET_QUANTIFIED_CONTENT,
                    })
                );
                break;
            }

            case `${GET_QUANTIFIED_CONTENT} ${API_SUCCESS}`: {
                const data = action.payload.companyQuantifiedInventory;
                next(dispatch(setQuantifiedContent(data)));
                break;
            }

            case UPDATE_QUANTIFIED_FORM: {
                const companyId = state.auth.userInfo?.company?.id;
                interface IBody {
                    companyId: number;
                    methodologiesAndQuantification: string;
                    activityDataAndQualityManagement: string;
                    calculationMethodologies: string;
                    changesCalculationMethodology: string;
                    reviewAndImprovements: string;
                    greenhouseGasInventoryInformationManagement: string;
                    evaluationUncertainty: string;
                    recalculationGreenhouseGasInventory: string;
                    emissionsRemovals: string;
                    emissionReductionsIncreases: string;
                }

                const body: IBody = {
                    companyId,
                    methodologiesAndQuantification: state.company.quantified.methodologiesAndQuantification,
                    activityDataAndQualityManagement: state.company.quantified.activityDataAndQualityManagement,
                    calculationMethodologies: state.company.quantified.calculationMethodologies,
                    changesCalculationMethodology: state.company.quantified.changesCalculationMethodology,
                    reviewAndImprovements: state.company.quantified.reviewAndImprovements,
                    greenhouseGasInventoryInformationManagement:
                        state.company.quantified.greenhouseGasInventoryInformationManagement,
                    evaluationUncertainty: state.company.quantified.evaluationUncertainty,
                    recalculationGreenhouseGasInventory: state.company.quantified.recalculationGreenhouseGasInventory,
                    emissionsRemovals: state.company.quantified.emissionsRemovals,
                    emissionReductionsIncreases: state.company.quantified.emissionReductionsIncreases,
                };

                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/company/quantified/inventory/add',
                        feature: UPDATE_QUANTIFIED_FORM,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }

            case `${UPDATE_QUANTIFIED_FORM} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                break;
            }

            case GET_QUANTIFIED_DETAIL_BY_ID: {
                const { categoryId, facilityId, source, inventoryActivityData } = action.payload;
                const companyId = state.auth.userInfo?.company?.id;

                const body = {
                    categoryIdList: [categoryId],
                    companyIdList: [companyId],
                    facilityIdList: [facilityId],
                    period: {
                        periodStart: state.company.company.period.start,
                        periodEnd: state.company.company.period.end,
                        includeYearlyValues: true,
                        onlyYearlyValues: false,
                    },
                    inventoryActivityData,
                    tags: [source],
                    detailed: true,
                };

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporateEmission/inventory/filter',
                        feature: GET_QUANTIFIED_DETAIL_BY_ID,
                        body,
                        query: {
                            page: 0,
                            size: 50,
                            sort: 'createdAt,desc',
                        },
                    })
                );
                break;
            }
            case `${GET_QUANTIFIED_DETAIL_BY_ID} ${API_SUCCESS}`: {
                const data: Array<IDetailedItem> = action.payload.corporateEmissionContentDtoPage.content;
                next(setQuantifiedDetailById(data));
                break;
            }
        }
    };
