import { EMISSION } from './base.action';

export const EXCEL = 'EXCEL';

export const CHANGE_EXCEL_DESCRIPTION = `${EMISSION} ${EXCEL} Change Description`;
export const DOWNLOAD_EXCEL_TEMPLATE = `${EMISSION} ${EXCEL} Download Excel Template`;
export const UPLOAD_EXCEL_TEMPLATE = `${EMISSION} ${EXCEL} Upload Excel Template`;

export type ISubmit = {
    onSuccess: () => void;
};

export const changeExcelDescription = (text: string) => ({
    type: CHANGE_EXCEL_DESCRIPTION,
    payload: text,
});

export const downloadExcelTemplate = () => ({
    type: DOWNLOAD_EXCEL_TEMPLATE,
});

export const uploadExcelTemplate = (value: any) => ({
    type: UPLOAD_EXCEL_TEMPLATE,
    payload: value,
});
