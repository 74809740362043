// EN
export const translation = {
    pages: {
        login: {
            title: 'Login',
            username: 'Username',
            password: 'Password',
            login: 'Login',
            forgetPassword: 'I forget my password',
            register: "Don't have an account? Register",
        },
        resetPassword: {
            step1Text: 'Enter Email',
            step1Description: 'We will send a code to your email.',
            step1InputTitle: 'Email',
            step1InputPlaceholder: 'Email',
            step1Button: 'Send Code',
            step2Text: 'Verify Code',
            step2Description: 'Enter the code sent to your email.',
            step2InputTitle: 'Code',
            step2InputPlaceholder: 'Code',
            step2Button: 'Verify Code',
            step3Text: 'Reset Password',
            step3Description: 'Create new password',
            step3PasswordInput: 'Password',
            step3PasswordPlaceholder: 'Password',
            step3ConfirmInput: 'Confirm',
            step3ConfirmPlaceholder: 'Confirm',
            notMatch: '* Passwords do not match',
            step3Button: 'Save',
        },
        dashboard: {
            dashboard: 'Dasbboard',
            totalEmission: 'Total Emission',
            averageMonthlyEmission: 'Average Monthly Emission',
            targetValue: 'Target Value',
            emissionBreakdown: 'Emission Breakdown',
            monthBasedEmissionValues: 'Month Based Emission Values',
            cumulativeIncreasingEmissionsGraph: 'Cumulative Increasing Emissions Graph',
            scope: 'Scope',
            month: 'Month',
            year: 'Year',
        },
        analytical: {
            analytical: 'Analytical',
            totalEmission: 'Total Emission',
            averageMonthlyEmission: 'Average Monthly Emission',
            scopeBasedEmission: 'Scope Based Emission',
            mainCategoryBasedEmissionValues: 'Main Category Based Emission Values',
            mainCategoryBasedBreakdowns: 'Main Category Based Breakdowns',
            mainCategoryBasedEmissionBreakdowns: 'Main Category Based Emission Breakdowns',
            directEmissions: 'Direct Emissions',
            energyIndirectEmissions: 'Energy Indirect Emissions',
            emissionsFromTransport: 'Emissions From Transport',
            manufacturedProductEmissions: 'Manufactured Product Emissions',
            usedProductEmissions: 'Used Product Emissions',
            otherEmissions: 'Other Emissions',
            subCategoryBasedEmissionsBreakdown: 'Sub Category Based Emissions Breakdown',
            scopeEmissionDistribution: 'Scope Emission Distribution',
            year: 'Year',
            month: 'Month',
        },
        organizations: {
            organizations: 'Organizasyonlar',
            companies: 'Companies',
            facilities: 'Facilities',
            no: 'No',
            companyName: 'Company Name',
            facilityName: 'Facility Name',
            email: 'Email',
            country: 'Country',
            state: 'City',
            phone: 'Phone',
            address: 'Address',
            actions: 'Actions',
            rowCount: 'Number of Rows To Show',
            of: ' of',
            createDialog: {
                addButtonCompany: 'Add Company',
                addButtonFacility: 'Add Facility',
                createCompany: {
                    title: 'Fill in company information',
                    companyName: 'Company Name',
                    phone: 'Phone',
                    email: 'Email',
                    address: 'Address',
                    url: 'Website URL',
                    facilityLimit: 'Facility Limit',
                    country: 'Country',
                    state: 'City',
                    cancel: 'Cancel',
                    add: 'Add',
                },
                createFacility: {
                    title: 'Fill in facility information',
                    facilityName: 'Facility Name',
                    phone: 'Phone',
                    email: 'Email',
                    address: 'Address',
                    country: 'Country',
                    state: 'City',
                    cancel: 'Cancel',
                    add: 'Add',
                },
            },
            updateDialog: {
                updateCompany: {
                    title: 'Change the fields of the company that you want to update.',
                    companyName: 'Comapany Name',
                    email: 'Email',
                    country: 'Country',
                    state: 'City',
                    phone: 'Phone',
                    address: 'Address',
                    url: 'Website URL',
                    facilityLimit: 'Facility Limit',
                    cancel: 'Cancel',
                    update: 'Update',
                },
                updateFacility: {
                    title: 'Change the fields of the facility that you want to update.',
                    facilityName: 'Facility Name',
                    email: 'Email',
                    country: 'Country',
                    state: 'City',
                    phone: 'Phone',
                    address: 'Address',
                    cancel: 'Cancel',
                    update: 'Update',
                },
            },
            deleteDialog: {
                deleteCompany: {
                    title: 'Are you sure you want to delete company?',
                    description: 'Are you sure?',
                    cancel: 'Cancel',
                    delete: 'Delete',
                },
                deleteFacility: {
                    title: 'Are you sure you want to delete facility?',
                    description: 'Are you sure?',
                    cancel: 'Cancel',
                    delete: 'Delete',
                },
            },
        },
        inventoryEntry: {
            inventoryEntry: 'Inventory Entry - Category Selection',
            scope: 'Scope',
            category: 'Category',
            category1: 'Direct Greenhouse Gas Emissions and Removals',
            category2: 'Indirect Greenhouse Gas Emissions from Purchased Energy',
            category3: 'Indirect Greenhouse Gas Emissions from Transportation',
            category4: 'Indirect Greenhouse Gas Emissions from Products Used by the Organization',
            category5: "Indirect Greenhouse Gas Emissions Associated with the Use of the Organization's Products",
            category6: 'Indirect Greenhouse Gas Emissions from Other Sources',
            // Forms
            static: 'Static Combustion',
            mobile: 'Mobile Combustion',
            process: 'Process Emissions',
            leak: 'Leak Emissions',
            electric: 'Electricity Consumption',
            steam: 'Heat / Steam Consumption',
            income: 'Upward Transport',
            outcome: 'Downward Transport',
            employee: 'Employee Arrival and Departure',
            visitor: 'Visitor / Customer Transportation',
            business: 'Business Travels',
            sold: 'Item Purchased',
            bought: 'Capital Goods',
            waste: 'Solid and Liquid Waste Disposal',
            service: 'Service Usage',
            product: 'Product Usage Phase',
            lifetime: 'After Product Lifecycle',
            others: 'Others',
            lulucf: 'LULUCF',
            assets: 'Asset Usage',
            leased: 'Leased Assets',
            investment: 'Investment Emissions',
            biomass: 'Biomass Emissions',
            downstreamLeasedAssets: 'Downstream Leased Assets',
            upstreamLeasedAssets: 'Upstream Leased Assets',
            continue: 'Continue',
        },
        inventoryEntryGHG: {
            inventoryEntryGHG: 'Inventory Entry - Category Selection - GHG',
            scope: 'Scope',
            scope3Title: 'Indirect emissions in the supply chain',
            category1: 'Direct Greenhouse Gas Emissions and Removals',
            category2: 'Indirect Greenhouse Gas Emissions from Purchased Energy',
            category3: 'Upward Transport',
            category4: 'Downward Transport',
            // Forms
            static: 'Static Combustion',
            mobile: 'Mobile Combustion',
            process: 'Process Emissions',
            leak: 'Leak Emissions',
            electric: 'Electricity Consumption',
            steam: 'Heat / Steam Consumption',
            income: 'Transport and Distribution',
            outcome: 'Transport and Distribution',
            employee: 'Employee Arrival and Departure',
            visitor: 'Visitor / Customer Transportation',
            business: 'Business Travels',
            sold: 'Purchased Products and Services',
            bought: 'Capital Goods',
            waste: 'Waste',
            service: 'Service Usage',
            product: 'Use of the Sold Product',
            lifetime: 'After Product Lifecycle',
            others: 'Others',
            lulucf: 'LULUCF',
            assets: 'Asset Usage',
            leased: 'Leased Assets',
            leasedFacility: 'Rental Facilities',
            investment: 'Investments',
            biomass: 'Biomass Emissions',
            continue: 'Continue',
        },
        inventoryList: {
            inventoryList: 'Inventory List',
            period: 'Period',
            category: 'Category',
            subCategory: 'Sub Category',
            source: 'Source',
            activityData: 'Activity Data',
            consumptionData: 'Consumption Data',
            sum: 'Total CO2e',
            tier: 'Tier',
            filter: {
                filter: 'Filter',
                selectFilter: 'Select Filter',
                beginning: 'Beginning',
                finish: 'Finish',
                category: 'Category',
                subCategory: 'Sub Category',
                source: 'Source',
                cancel: 'Cancel',
                okey: 'Done',
                selectAll: 'Select All',
                select: 'Select',
                allWillBeSelected: 'All Selected Inventories Will Be Deleted.',
                sure: 'Are you sure?',
                remove: 'Delete',
                maxAllowedSize: 'The file must not be larger than 1 MB',
            },
            customTable: {
                of: ' of',
                rowCount: 'Number of Rows to Show',
                inventoryList: {
                    companyName: 'Company Name',
                    facilityName: 'Facility Name',
                    actions: 'Actions',
                    reports: 'Reports',
                    cancel: 'Cancel',
                    allWillBeSelected: 'Are you sure you want to delete the selected inventory?',
                    sure: 'Are you sure?',
                    remove: 'Delete',
                    maxAllowedSize: 'The file must not be larger than 1 MB',
                },
            },
        },
        inventoryResult: {
            seeAll: 'See All Results',
            entered: {
                entries: 'Inventory Entries',
                mainFacility: 'Main Facility',
                subFacility: 'Sub Facility',
                activityData: 'Activity Data',
                primary: 'Primary Activity Data',
                secondary: 'Secondary Activity Data',
                stageApproach: 'Stage Approach',
                database: 'Database',
                equivalentFactor: 'Equivalent Factor',
                activity: 'Activity',
                activityType: 'Activity Type',
                sourceStream: 'Source Stream',
                year: 'Year',
                unit: 'Unit',
                description: 'Description',
            },
            result: {
                results: 'Result',
                year: 'Year',
                month: 'Month',
                total: 'Total',
                addDocument: 'Add Document',
                maxAllowedSize: 'The file must not be larger than 1 MB',
            },
            // Forms
            static: 'Static Combustion',
            mobile: 'Mobile Combustion',
            process: 'Process Emissions',
            leak: 'Leak Emissions',
            electric: 'Electricity Consumption',
            steam: 'Heat / Steam Consumption',
            income: 'Upward Transport',
            outcome: 'Downward Transport',
            employee: 'Employee Arrival and Departure',
            visitor: 'Visitor / Customer Transportation',
            business: 'Business Travels',
            sold: 'Item Purchased',
            bought: 'Capital Goods',
            waste: 'Solid and Liquid Waste Disposal',
            service: 'Service Usage',
            product: 'Product Usage Phase',
            lifetime: 'After Product Lifecycle',
            others: 'Others',
            lulucf: 'LULUCF',
            assets: 'Asset Usage',
            leased: 'Leased Assets',
            investment: 'Investment Emissions',
            biomass: 'Biomass Emissions',
            upstreamLeased: 'Upstream Leased Assets',
            downstreamLeased: 'Downstream Leased Assets',
        },
        inventoryDetail: {
            title: 'Inventory Detail',
            update: 'Update',
            dialogTitle: 'Change the fields you want to update.',
            dialogCancel: 'Cancel',
            dialogUpdate: 'Update',
            inputs: 'Inputs',
            values: 'Values',
            results: 'Results',
            facilityName: 'Facility Name',
            tags: 'Tags',
            period: 'Period',
            activityData: 'Activity Data',
            database: 'Database',
            description: 'Description',
            emissionActivity: 'Emission Activity',
            emissionActivityType: 'Emission Activity Type',
            emissionCategory: 'Emission Category',
            emissionCategoryType: 'Emission Category Type',
            emissionSource: 'Emission Source',
            equivalentTags: 'Equivalent Tags',
            CH4: 'CH4',
            CO2: 'CO2',
            N2O: 'N2O',
            total: 'Total',
        },
        profile: {
            profile: 'Profile',
            update: 'Update',
            save: 'Save',
            cancel: 'Cancel',
            accountInformationStep: 'Account Info',
            corporateInformationStep: 'Corporate Info',
            accountInformation: {
                companyName: 'Company Name',
                completeProfile: 'Complete Profile',
                establishmentInformation: 'Establishment Information',
                summaryInformation: 'Summary Information',
                email: 'Email',
                website: 'Website',
                phone: 'Phone',
                numberOfLocation: 'Number of Location',
                country: 'Country',
                city: 'City',
                sector: 'Sector',
                address: 'Address',
                companyNamePlaceholder: 'Google',
                save: 'Save',
                cancel: 'Cancel',
                addImage: 'Add Image',
                maxAllowedSize: 'The file must not be larger than 1 MB',
            },
            corporateInformation: {
                email: 'Email',
                website: 'Website',
                phone: 'Phone',
            },
        },
        reporting: {
            general: {
                general: 'General Information, Purpose and Policy',
                update: 'Update',
                title: 'General Information, Purpose and Policy',
                episode1: 'Episode 1',
                intro: 'Introduction',
                purpose: 'Purpose, Scope and Goals',
                organizationDescription: 'Organization Description',
                ghgPolicy: 'GHG Management Policy, Strategies and Programs',
                responsibilities: 'Responsibilities',
                targetAudience: 'Target Audience and Dissemination Policy',
                reportingPeriod: 'Reporting Period and Reporting Frequency',
                baseYear: 'Base Year',
                reportingStandarts: 'Reporting Standards and Verification',
                footer: 'KONSORSIYUM CORPORATE GREENHOUSE GAS REPORT',
                edit: {
                    general: 'General Information, Purpose and Policy',
                    save: 'Save',
                    intro: 'Introduction',
                    introTooltip: 'Introduction',
                    purpose: 'Purpose, Scope and Goals',
                    purposeTooltip:
                        "Explain the purpose and objectives of the report in the context of the organization's GHG policies, strategies or programs and applicable GHG programs",
                    organizationDescription: 'Organization Description',
                    organizationDescriptionTooltip: 'Define the reporting organization',
                    ghgPolicy: 'GHG Management Policy, Strategies and Programs',
                    ghgPolicyTooltip:
                        'Explain greenhouse gas emission and sustainability policies and related studies, strategies and programs',
                    responsibilities: 'Responsibilities',
                    responsibilitiesTooltip:
                        'Please explain the duties and contact information of the personnel responsible for collecting the activity data required for the preparation of the Greenhouse Gas Report, coordinating the studies and controlling the prepared report',
                    targetAudience: 'Target Audience and Dissemination Policy',
                    targetAudienceTooltip:
                        "Describe the GHG Report's target audience and policies regarding diffusion methods; the target user may be the customer, the responsible party, the organization itself, the GHG program managers, regulators, the financial community, or other interested parties affected, local communities, government departments, the general public, or civil society. organizations can be",
                    reportingPeriod: 'Reporting Period and Reporting Frequency',
                    reportingPeriodTooltip: 'Specify the reporting period and reporting frequency',
                    baseYear: 'Base Year',
                    baseYearTooltip:
                        'For comparative purposes, the organization should establish a historical base year for GHG emissions and removals to meet GHG program requirements or other intended uses of the GHG inventory. Explain the chosen base year and the reasons; Usually, as the Base Year, facilities choose the Monitoring Year for which they make their carbon footprint calculations',
                    reportingStandarts: 'Reporting Standards and Verification',
                    reportingStandartsTooltip: 'Please indicate to which standard the report was prepared',
                },
            },
            organization: {
                organization: 'Organizational Boundaries',
                update: 'Update',
                title: 'Organizational Boundaries',
                episode2: 'Episode 2',
                consolidationApproach: 'Consolidation Approach',
                organizationChart: 'Organization Chart',
                table1Facilities: 'Table: 1. Facilities',
                no: 'No',
                facilityName: 'Facility Name',
                email: 'Email',
                address: 'Address',
                footer: 'KONSORSIYUM CORPORATE GREENHOUSE GAS REPORT',
                edit: {
                    organization: 'Organizational Boundaries',
                    save: 'Save',
                    selectAppraches: 'Consolidation based on control', //Inputs
                    headerDescription:
                        "Define your organizational boundaries. The organization may include one or more facilities. You should consolidate your organization's plant-level GHG emissions and removals with one of the following approaches. Identify the Consolidation approach consistent with the intended use of your GHG inventory:",
                    control: 'Control',
                    controlDescription:
                        'The organization calculates all greenhouse gas emissions and/or removals from facilities over which it has financial or operational control ... learn more',
                    equalSharing: 'Equal Sharing',
                    equalSharingDescription:
                        'The organization calculates the share of greenhouse gas emissions and/or removals from related facilities. The organization is responsible for the greenhouse gas emissions and/or removals of these facilities in proportion to its share in the relevant facilities. ...learn more',
                    organizationalBoundaries: 'Setting Organizational Boundaries',
                    purposeOfUsage:
                        'Identify the Consolidation approach consistent with the intended use of your GHG inventory:',
                    mainReason: 'Explain the main reason for your chosen approach.',
                    willBeIncluded: 'Determine the Locations to be Included in the Reporting.',
                    organizationDetail: "Explain the details of your company's organizational structure.",
                },
            },
            reporting: {
                reporting: 'Reporting Limits',
                update: 'Update',
                facilityName: 'Facility Name',
                category: 'Category',
                subCategory: 'Sub Category',
                source: 'Source',
                activityData: 'Activity Data',
                emissions: 'Emissions',
                reason: 'Reason for Exclusion',
                reportingLimits: 'Reporting Limits',
                episode3: 'Episode 3',
                categories: 'Greenhouse Gas Emission Categories',
                categoriesDescription:
                    'Sera gazı emisyon kaynakları ISO 14064-1:2018 standardına göre belirlenmiş ve gruplandırılmıştır. Bu metodoloji, altı emisyon kategorisini listeler',
                category1: 'Category 1 : Direct Greenhouse Gas Emissions and Removals',
                category2: 'Category 2 : Indirect Greenhouse Gas Emissions from Purchased Energy',
                category3: 'Category 3 : Indirect Greenhouse Gas Emissions from Transportation',
                category4: 'Category 4 : Indirect Greenhouse Gas Emissions from Products Used by the Organization',
                category5:
                    "Category 5 : Indirect Greenhouse Gas Emissions Associated with the Use of the Organization's Products",
                category6: 'Category 6 : Indirect Greenhouse Gas Emissions from Other Sources',
                materialityAssessment: 'Materiality Assessment',
                includedCalculation: 'Emission Sources Included in the Calculation',
                table2includes: 'Table 2. Emission Sources Included in the Calculation',
                excludedEmission: 'Excluded Emission Sources',
                table3Excluded: 'Table 3. Excluded Emission Sources',
                footer: 'KONSORSIYUM CORPORATE GREENHOUSE GAS REPORT',
                edit: {
                    reporting: 'Reporting Limits',
                    backToReport: 'Back to Report',
                    add: 'Add',
                    facilityName: 'Facility Name',
                    category: 'Category',
                    subCategory: 'Sub Category',
                    source: 'Source',
                    activityData: 'Activity Data',
                    emissions: 'Emissions',
                    reason: 'Reason for Exclusion',
                    reasonPlaceholder: 'Data Collection Difficulty, Size of Emissions etc.',
                    determiningTitle:
                        'Guidance for the process of identifying significant indirect greenhouse gas emissions',
                    defineCriteria:
                        'Define criteria for assessing the significance of indirect emissions consistent with the intended use of the GHG inventory',
                    definePurpose1: '1-Define the Purpose of the Greenhouse Gas Inventory',
                    definePurposeTooltip1:
                        "May include regulatory or voluntary disclosure schemes, Public commitment, Emissions trading plans, An organization's performance and progress monitoring system for reducing emissions and/or eliminations, Mitigation programs, An organization's annual report, Investors' information, Carbon risks or opportunities, and due diligence report identification",
                    definePurpose2:
                        '2- Define criteria to evaluate the importance of indirect emissions consistent with the intended use of the inventory',
                    definePurposeTooltip2:
                        "Magnitude: Indirect emissions or reductions deemed to be numerically significant. Impact level: The extent to which the organization has the ability to monitor and reduce emissions and reductions (eg energy efficiency, eco-design, customer engagement, job descriptions). Risk or opportunity: Indirect emissions or removals that contribute to the organization's exposure to risk (e.g. financial, regulatory, supply chain, climate-related risks such as product and customer, litigation, reputational risks) or business opportunities (e.g. new market, new business model) . Sector-specific guidance: GHG emissions considered significant by the business sector, as provided by the sector-specific guidance. Outsourcing: Indirect emissions and removals from outsourced activities, which are usually core business activities. Employee involvement: Indirect emissions (e.g. energy savings incentives, car-sharing, internal carbon pricing) that can motivate employees to reduce energy use or unite team spirit on climate change.",
                    definePurpose3: '3- Identify and evaluate indirect emissions',
                    definePurposeTooltip3:
                        'For each category of indirect emissions, identify and assess indirect emissions as a screening step without detailed calculations, using sources such as internal and external experts, an industry-specific greenhouse gas guide, a literature review, or a third-party database.',
                    definePurpose4: '4-Apply criteria to select significant indirect emissions',
                    definePurposeTooltip4:
                        'Organizations determine the importance of indirect emissions and reductions by applying predefined criteria. In most cases, applying the criteria to a particular source of indirect emission or removal results in a clear determination of whether the emission or removal is significant.',
                    excluded: 'Indirect Greenhouse Gas Emissions Not Included in the Calculation',
                    excludedList:
                        'List the emission sources identified but excluded from the greenhouse gas emission inventory.',
                    edit: 'Edit',
                    materialityEvaluation: 'Materiality Evaluation',
                    materialityEvaluationTooltip:
                        'Explain the assessment of materiality made and the reason for not including emission sources identified but excluded from the GHG emissions inventory.',
                    update: 'Update',
                    newEmission: 'Add new emission.',
                    removeSure: 'Are you sure you want to clear the emission?',
                    sure: 'Are you sure?',
                    cancel: 'Cancel',
                    save: 'Save',
                    remove: 'Remove',
                },
            },
            quantified: {
                quantified: 'Calculated Greenhouse Gas Emissions Inventory',
                update: 'Update',
                title: 'Calculated Greenhouse Gas Emissions Inventory',
                episode4: 'Episode 4',
                no: 'No',
                facility: 'Facility',
                category: 'Category',
                subCategory: 'Sub Category',
                deviceType: 'Device Type',
                gasType: 'Gas Type',
                fuelType: 'Fuel Type',
                kip: 'KIP',
                nkd: 'NKD',
                leakageGasRate: 'Leakage Gas Rate %',
                reference: 'Reference',
                unit: 'Unit',
                density: 'Density',
                activityData: 'Activity Data',
                formula: 'Formula',
                greenhouseGas: 'Greenhouse Gas',
                loaderText: 'Loading',
                footer: 'KONSORSIYUM CORPORATE GREENHOUSE GAS REPORT',
                title4_1: 'Greenhouse Gas Emissions Table',
                title4_1_1: 'References used in calculations',
                title4_1_2: 'Results',
                title4_2: 'Data Collection and Calculation Methodologies',
                title4_2_1: 'Activity Data Collection and Greenhouse Gas Quality Management',
                title4_2_2: 'Calculation Methodologies',
                title4_2_2_1: 'Changes to the Calculation Methodology',
                title4_2_2_2: 'Review and Improvements',
                title4_3: 'Greenhouse Gas Inventory Information Management',
                title4_4: 'Evaluation of Uncertainty',
                title4_5: 'Recalculation of Greenhouse Gas Inventory',
                title4_6: 'Emission Removals and Decreases/Increases',
                title4_6_1: 'Emission Removals',
                title4_6_2: 'Emission Reductions / Increases',
                table4Title: 'Table 4. Refrigerant Gas Leakage Rates Used in Calculations',
                table5Title: 'Table 5. Refrigerant Gas KIP Values Used in Calculations',
                table6Title: 'Table 6. NKD Values Used in Calculations',
                table7Title: 'Table 7. Density Values Used in Calculations',
                table8Title: 'Table 8. Emission Factors Used in Calculations',
                table9Title: 'Table 9. Total Emissions by Category',
                table10Title: 'Table 10. Total Emissions by Sub-Category',
                table11Title: 'Table 11. Facility Based Total Emissions',

                // 4.2.2 description
                general: 'In general calculations;',
                total: 'Total CO2e = Activity Data x Appropriate Emission Factor',
                usage: 'IPCC AR6 is taken as reference for KIPs. The following KIP values are used for CO2, CH4 and N2O.',
                carbon: 'Carbon dioxide',
                methane: 'Methane',
                nitrogen: 'Nitrogen Oxide',
                usageTier1:
                    'Alternative methods were also determined in the calculation method and the appropriate method was chosen to minimize the measurement uncertainty. International factors (Tier 1) were used when national resources were not sufficient in the selection of emission factors.',
                table4: {
                    note: 'Note',
                    total: 'Total(Ton)',
                    carbon: 'Carbon dioxide (CO2)',
                    methane: 'Methane (CH4)',
                    nitrogen: 'Nitrogen Oxide (N2O)',
                    hydrofluorocarbon: 'Hydrofluorocarbon (HFC)',
                    perfluorocarbons: 'Perfluorocarbons (PFC)',
                    sulfurHexafluoride: 'SulfurHexafluoride (SF6)',
                    nitrogenTrifluoride: 'NitrogenTrifluoride (NF3)',
                    quantitativeUncertainty: 'Quantitative Uncertainty',
                    qualitativeUncertainty: 'Qualitative Uncertainty',
                    greenhouseGas: 'GREENHOUSE GAS EMISSIONS',
                },
                categoriesTable: {
                    category1: 'Category 1: Direct GHG Emissions and Removals',
                    subCategory1_1: 'Direct emissions from stationary combustion',
                    subCategory1_2: 'Direct emissions from mobile combustion',
                    subCategory1_3: 'Direct process emissions and removals from industrial processes',
                    subCategory1_4: 'Direct fugitive emissions from greenhouse gas emissions in anthropogenic systems',
                    subCategory1_5: 'Direct emissions from Land Use, Land Use Change and Forestry',
                    subCategory1_6: 'Direct emissions from biomass',
                    category2: 'Category 2: Indirect greenhouse gas emissions from purchased energy',
                    subCategory2_1: 'Indirect emissions from purchased electricity',
                    subCategory2_2: 'Indirect emissions from purchased energy',
                    category3: 'Category 3: Indirect greenhouse gas emissions from transportation',
                    subCategory3_1: 'Emissions from upstream transport and distribution of goods',
                    subCategory3_2: 'Emissions from downstream transport and distribution of goods',
                    subCategory3_3: 'Emissions from employee transportation',
                    subCategory3_4: 'Emissions from customer and visitor transportation',
                    subCategory3_5: 'Emissions from business trips',
                    category4: 'Category 4: Indirect greenhouse gas emissions from products used by the organization',
                    subCategory4_1: 'Emissions from purchased products',
                    subCategory4_2: 'Emissions from capital goods',
                    subCategory4_3: 'Emissions from the disposal of solid and liquid waste',
                    subCategory4_4: 'Emissions from upstream leased assets',
                    subCategory4_5:
                        'Emissions from the use of services not described in the above subcategories (consulting, cleaning, maintenance, mail delivery, bank, etc.)',
                    category5:
                        'Category 5: Indirect greenhouse gas emissions from the use of products manufactured by the organization',
                    subCategory5_1: 'Emissions or removals from the use phase of the product',
                    subCategory5_2: 'Emissions from downstream leased assets',
                    subCategory5_3: 'Emissions from end-of-life of the product',
                    subCategory5_4: 'Emissions from investments',
                    category6: 'Category 6: Indirect greenhouse gas emissions from other sources',
                    subCategory6_1: 'Other',
                    removals: 'REMOVALS',
                    directRemovals: 'Direct Removals',
                    storage: 'STORAGE',
                    totalStorage: 'Total Storage',
                    financialInstruments: '',
                    otherInfos: 'CARBON FINANCIAL INSTRUMENTS',
                    details: 'Details',
                    notes: 'NOTES',
                    back: 'Back',
                },
                edit: {
                    quantified: 'Calculated GHG Emissions Inventory',
                    update: 'Update',
                    title4_2_1: 'Activity Data Collection and Greenhouse Gas Quality Management',
                    title4_2_1Tooltip: 'Activity Data Collection and Greenhouse Gas Quality Management',
                    title4_2_2: 'Calculation Methodologies',
                    title4_2_2Tooltip: 'Calculation Methodologies',
                    title4_2_3: 'Changes to the Calculation Methodology',
                    title4_2_3Tooltip: 'Changes to the Calculation Methodology',
                    title4_2_4: 'Review and Improvements',
                    title4_2_4Tooltip: 'Review and Improvements',
                    title4_3: 'Greenhouse Gas Inventory Information Management',
                    title4_3Tooltip: 'Greenhouse Gas Inventory Information Management',
                    title4_4: 'Evaluation of Uncertainty',
                    title4_4Tooltip: 'Evaluation of Uncertainty',
                    title4_5: 'Recalculation of Greenhouse Gas Inventory',
                    title4_5Tooltip: 'Recalculation of Greenhouse Gas Inventory',
                    title4_6_1: 'Emission Removals',
                    title4_6_1Tooltip: 'Emission Removals',
                    title4_6_2: 'Emission Reductions / Increases',
                    title4_6_2Tooltip: 'Emission Reductions / Increases',
                },
                detailDialog: {
                    inputs: 'Inputs',
                    database: 'Database',
                    activity: 'Activity',
                    month: 'Month',
                    year: 'Year',
                    results: 'Results',
                    addDocument: 'Add Document',
                    total: 'Total',
                    reports: 'Reports',
                    cancel: 'Cancel',
                    maxAllowedSize: 'The file must not be larger than 1 MB',
                },
            },
            internal: {
                internal: 'Inventory Report Internal Performance Tracking',
                update: 'Update',
                internalPerformance: 'Inventory Report Internal Performance Tracking',
                episode5: 'Episode 5',
                categoryBased: 'Greenhouse Gas Emissions by Categories',
                subCategoryBased: 'Greenhouse Gas Emissions by Subcategories',
                performanceEvaluation: 'Performance Evaluation',
                footer: 'KONSORSIYUM CORPORATE GREENHOUSE GAS REPORT',
                figure1: 'Figure 1. Greenhouse Gas Emissions by Categories Chart',
                figure2: 'Figure 2. Category 1 Greenhouse Gas Emissions Chart',
                figure3: 'Figure 3. Category 2 Greenhouse Gas Emissions Chart',
                figure4: 'Figure 4. Category 3 Greenhouse Gas Emissions Chart',
                figure5: 'Figure 5. Category 4 Greenhouse Gas Emissions Chart',
                figure6: 'Figure 6. Category 5 Greenhouse Gas Emissions Chart',
                figure7: 'Figure 7. Category 6 Greenhouse Gas Emissions Chart',
                edit: {
                    internal: 'Inventory Report Internal Performance Tracking',
                    backToReport: 'Back to Report',
                    performanceEvaluation: '5.3 Performance Evaluation',
                    performanceEvaluationInput: 'Performance Evaluation',
                },
            },
            appendices: {
                appendices: 'Appendices',
                update: 'Update',
                title: 'Appendices',
                reportingIndex: 'APPENDIX 1 - ISO 14064-1:2018 Reporting Index',
                isoReporting: 'ISO Reporting',
                section: 'Section in the Report',
                no: 'No',
                document: 'Document',
                documentName: 'Document Name',
                download: 'Download',
                footer: 'KONSORSIYUM CORPORATE GREENHOUSE GAS REPORT',
                edit: {
                    appendices: 'Appendices',
                    backToReport: 'Back to Report',
                    fileName: 'File Name',
                    complete: 'Complete',
                    cancel: 'Cancel',
                    addDocument: 'Add Document',
                    no: 'No',
                    document: 'Document',
                    documentName: 'Document Name',
                    action: 'Action',
                    willBeRemove: 'Are you sure you want to delete the report?',
                    sure: 'Are you sure?',
                    remove: 'Remove',
                    maxAllowedSize: 'The file must not be larger than 1 MB',
                },
            },
            module: {
                title: 'Reporting',
                description:
                    'In order to create report output in accordance with ISO 14064-1 Standards, you must be at the Company level.',
            },
        },
        kpi: {
            createProject: 'Create Project',
            addKPI: 'Create KPI',
            dashboard: {
                dashboard: 'KPI Dashboard',
                filter: 'Filter',
                addProject: 'Add Project',
                addCriterion: 'Add Criterion',
            },
            list: {
                backlog: 'Backlog',
                notStarted: 'Not Started',
                inProgress: 'In Progress',
                completed: 'Completed',
                backlogProgress: 'Backlog',
                notStartedProgress: 'Not Started',
                inProgressProgress: 'In Progress',
                completedProgress: 'Completed',
                id: 'id',
                title: 'Title',
                status: 'Status',
                module: 'Module',
                situation: 'Situation',
            },
            dropList: {
                backlogKey: 'BACKLOG',
                notStartedKey: 'NOT_STARTED',
                inProgressKey: 'IN_PROGRESS',
                completedKey: 'COMPLETED',
                backlogTitle: 'Backlog',
                notStartedTitle: 'Not Started',
                inProgressTitle: 'In Progress',
                completedTitle: 'Completed',
            },
            detailKpi: {
                detailKpi: 'KPI Detail',
                addAction: 'Add Action',
                kpiRemoveButton: 'Remove KPI',
                kpiRemove: 'You are about to delete the KPI.',
                kpiRemoveSure: 'Are you sure you want to delete the selected KPI?',
                cancel: 'Cancel',
                remove: 'Remove',
            },
            kpiDetailBase: {
                kpiName: 'KPI Name',
                kpiNamePlaceholder: 'KPI Name',
                kpiDescription: 'KPI Description',
                kpiDescriptionPlaceholder: 'KPI Description',
                startDate: 'Starting Date',
                startDatePlaaceholder: 'Starting Date',
                finishDate: 'Finish Date',
                finishDatePlaaceholder: 'Finish Date',
                startAmount: 'Starting Amount',
                startAmountPlaceholder: 'Starting Amount',
                goalAmount: 'Goal Amount',
                goalAmountPlaceholder: 'Goal Amount',
                update: 'Update',
            },
            actionList: {
                kpisAction: 'KPI Actions',
                kpiManagement: 'The part where you can manage your KPIs and set subtasks.',
                actionNotYet: 'You have not added an Action yet',
                addAction: 'Add Action',
                actionTitle: 'Action Title',
                actionDescription: 'Description',
                actionProcess: 'Process',
                actionState: 'Action Status',
                willBeRemoveAction: 'You are about to delete the action.',
                sure: 'Are you sure you want to delete the selected action?',
                cancel: 'Cancel',
                remove: 'Remove',
            },
            actionUpdateDialog: {
                updateAction: 'Update Action',
                actionName: 'Action Name',
                actionNamePlaceholder: 'Action Name',
                finishDate: 'Finish Date',
                finishDatePlaaceholder: 'Finish Date',
                actionDescription: 'Description',
                actionDescriptionPlaceholder: 'Description',
                howGoing: 'How are you progressing?',
                cancel: 'Cancel',
                update: 'Update',
            },
            actionAddDialog: {
                addAction: 'Add Action',
                actionName: 'Action Nameı',
                actionNamePlaceholder: 'Action Name',
                finishDate: 'Finish Date',
                finishDatePlaaceholder: 'Finish Date',
                actionDescription: 'Description',
                actionDescriptionPlaceholder: 'Description',
                howGoing: 'How are you progressing?',
                cancel: 'Cancel',
                add: 'Add',
            },
            kpiInputs: {
                kpiName: 'KPI Name',
                kpiNamePlaceholder: 'KPI Name',
                kpiDescription: 'KPI Description',
                kpiDescriptionPlaceholder: 'KPI Description',
                startDate: 'Starting Date',
                startDatePlaaceholder: 'Starting Date',
                finishDate: 'Finish Date',
                finishDatePlaaceholder: 'Finish Date',
                startAmount: 'Starting Amount',
                startAmountPlaceholder: 'Starting Amount',
                goalAmount: 'Goal Amount',
                goalAmountPlaceholder: 'Goal Amount',
                back: 'Back',
                create: 'Create',
            },
            detailProject: {
                backlog: 'Backlog',
                notStarted: 'Not Started',
                inProgress: 'In Progress',
                completed: 'Completed',
                projectDetail: 'Project Detail',
                status: 'Status',
                removeProject: 'Remove Project',
                addKpi: 'Add KPI',
                willBeRemove: 'You are about to delete the project.',
                sure: 'Are you sure you want to delete the selected project?',
                cancel: 'Cancel',
                remove: 'Remove',
            },
            kpiList: {
                projectsKpi: 'Project KPIs',
                projectInfo: 'You can create as many KPIs as you want by grouping your project.',
                kpiNotYet: "You haven't added a KPI yet.",
                addKpi: 'Add KPI',
                title: 'Title',
                description: 'Description',
                beginning: 'Beginning',
                target: 'Target',
                kpiStatus: 'Kpi Status',
                willBeRemove: 'You are about to delete the KPI.',
                sure: 'Are you sure you want to delete the selected KPI?',
                cancel: 'Cancel',
                remove: 'Remove',
            },
            projectBaseDetail: {
                projectName: 'Project Name',
                projectNamePlaceholder: 'Project Name',
                category: 'Category',
                categoryPlaceholder: 'Category',
                description: 'Description',
                descriptionPlaceholder: 'Description',
                baseYear: 'Base Year',
                baseYearPlaceholder: 'Base Year',
                targetYear: 'Target Year',
                targetYearPlaceholder: 'Target Year',
                update: 'Update',
            },
            selectGoals: {
                title: 'You can select the SDGs and SBTi criteria for this project to see the key developments you support. We recommend not to choose more than 2 when matching criteria.',
                projectsCriterion: 'Project Criteria',
                criterionNotYet: "You haven't added any criteria yet.",
                sdg: 'Sustainable Development Goals',
                SBTICriteria: 'SBTI Criteria',
                back: 'Back',
                create: 'Create',
                criterion: 'Criterion',
            },
            projectInputs: {
                projectName: 'Project Name',
                projectNamePlaceholder: 'Project Name',
                category: 'Category',
                categoryPlaceholder: 'Category',
                description: 'Description',
                descriptionPlaceholder: 'Project Description',
                baseYear: 'Base Year',
                baseYearPlaceholder: 'Base Year',
                targetYear: 'Target Year',
                targetYearPlaceholder: 'Target Year',
                back: 'Back',
                next: 'Next',
            },
        },
        guide: {
            guide: 'Guide',
            step1: {
                title: 'Beginning',
                subtitle: 'Explore everything you need to know on the climate journey:',
                beginning: 'Beginning',
                overview: 'Overview',
                listItem1:
                    'This section of the help content will help you to understand all carbon informations that are necessary for you to start  the climate journey.',
                listItem2: 'We will inform you about the GHG protocol and ISO 14064-1:2018 carbon management standard.',
            },
            step2: {
                title: 'Greenhouse Gas Emissions and Carbon Dioxide Equivalent',
                subtitle: 'Climate Change and Carbon Footprint short explanations:',
                equivalent: 'Greenhouse Gas Emissions and Carbon Dioxide Equivalent',
                humanActivity:
                    'Human activities lead to emissions of greenhouse gases (GHG), which trap heat in the atmosphere and contribute to climate change.',
                atmosphere:
                    'Due to the greenhouse gases accumulating in our atmosphere, the temperature of the Earth increased by 1.2°C compared to the pre-industrial period (1880-1900).',
                global: 'Global temperature will continue to rise as we continue to emit greenhouse gases. We should try to limit global warming as much as possible to minimize the negative effects of climate change.',
                globalWarming: 'Global Warming Potential',
                potent: 'Some greenhouse gases are more potent than others, meaning they are more effective in trapping heat.',
                amountOfWarming:
                    'Global Warming Potential (GWP) shows the amount of warming caused by a gas over a certain period of time (usually 100 years).',
                gwpEqual:
                    'Carbon dioxide has been chosen as the basis and therefore has a GWP of 1. The GWP of all other gases is equal to how much more potent they are than CO2.',
                co2eTitle: 'Carbon dioxide equivalent (CO2e)',
                co2eSub1:
                    'Carbon dioxide equivalent (CO2e) is used to aggregate all greenhouse gases into a single unit.',
                co2eSub2:
                    'CO2e expresses the amount of CO2 that would have the same global warming impact as that gas. The conversion is done by multiplying the gas amount by its GWP.',
                co2eSub3: 'For Example:',
                co2eSub4: 'If 1 kg of CH4 is emitted, it is equivalent to 25 kg CO2e (1 kg CH4 x 25 = 25 kg CO2e).',
                co2eSub5:
                    'Every emitted gas is converted to CO2 equivalent. These are then aggregated to give the total greenhouse gas emissions associated with an activity in CO2e.',
                footPrintTitle: 'Calculating your carbon footprint ',
                footPrintSub1:
                    'There are various methodologies to calculate a carbon footprint (UNE-ISO 14064, GHG Protocol, Bilan Carbone, etc.). They are all based on the same formula:',
                footPrintSub2: 'The sum of all activity data multiplied by the relevant emission factors.',
                activityDataTitle: 'Activity Data',
                activityDataSub1:
                    'Activity data is a quantitative measurement of an activity that emits greenhouse gases (GHG).',
                activityDataSub2:
                    'For example: The amount of gas used for heating or the distance traveled by an airplane.',
                emissionFactorTitle: 'Emission Factor',
                emissionFactorSub1: 'An emission factor is the emissions associated with each unit of activity data.',
                emissionFactorSub2: 'For example: Electricity, France average mix = 0.0791 kgCO2e/kWh',
                emissionFactorSub3: 'Learn more about emission factors',
                emissionFactorSub4:
                    'The product of activity data and an emission factor is the amount of emissions emitted by that activity.',
                methodologies: 'Methodologies',
                methodologiesSub1:
                    'The main methodology you need to know is the GHG Protocol, a global standard for carbon accounting and reporting.',
                methodologiesSub2:
                    'It separates your corporate emissions into three scopes: direct emissions from sources controlled by your company, indirect emissions from purchased energy, and upstream and downstream emissions.',
                methodologiesSub3:
                    'Scope 3 emissions are typically the largest part of your carbon footprint and also the most difficult to measure.',
            },
            step3: {
                title: 'How do we calculate ?',
                subtitle: '',
                how: 'How do we calculate ?',
                sub1: 'We calculate your greenhouse gas emissions with the following equation using Ecofoni:',
                sub2: 'Activity data x Emission factor = Emissions from the activity (in CO2e)',
                sub3: 'Activity data: The unit of activity that produces emissions. This could be something like the kilogram of material purchased or the kilowatt hour (kWh) of electricity used in an office.',
                sub4: 'Emission factor: A way to measure how much CO2e is produced by an activity or process, such as the kg of CO2e emitted for every kg of material produced. Ecofoni has a vast database of emission factors.',
                sub5: 'Learn more about emission factors',
                sub6: 'Carbon dioxide equivalent (CO2e): A standard unit used to express greenhouse gas emissions. It converts different greenhouse gases into a single equivalent using their global warming potentials (GWP).',
                sub7: 'In addition, the NKD and density values for the Stationary Combustion category are also calculated by multiplying them with this formula.',
            },
            step4: {
                title: 'Emission Factors',
                subtitle: '',
                emissionFactors: 'Emission Factors',
                measures:
                    'An emission factor is a value that measures how much greenhouse gas is released by an activity.',
                show: 'To calculate emissions, we use an emission factor and activity data. We express emissions in a standardized unit, CO2 equivalent (CO2e), which converts the warming potential of different greenhouse gases into a single number.',
                calculation: 'We calculate emissions with this equation:',
                activityXEmission: 'Activity data x Emission factor = Emissions in CO2e',
                example: 'For Example:',
                exampleContent:
                    'The emission factor for a short-haul flight is 0.27kgCO2e/passenger.km. This means that 0.27 kg CO2e is emitted per km flown per passenger. For one person on a 2000 km flight, emissions would be:',
                database: 'Ecofoni has a wide database, but you can also use your own database to make calculations.',
                emissonFactorTypes: 'Types of emission factors',
                emissonFactorTypesDescription: 'There are a few basic types of emission factors to learn:',
                emissonFactorTypes1: 'Carbon emissions',
                emissonFactorTypes2: 'Avoided emissions',
                emissonFactorTypes3: 'Negative emissions',
                carbonEmissions: 'Carbon emissions',
                carbonEmissionsSub1:
                    'Carbon emission factors are for activities that emit greenhouse gases (e.g., kgCO2e per km flown on a plane). They are likely the most common type of emission factor you use.',
                compositeEmission: 'Composite emission factors',
                compositeEmissionSub1:
                    'A composite emission factor is a carbon emission factor created by combining multiple existing carbon emission factors.',
                compositeEmissionSub2:
                    'You create a composite emission factor for the structure of a product by using emission factors for all materials used in a product.',
                avoidedEmissions: 'Avoided emissions',
                avoidedEmissionsSub1:
                    'Avoided emission factors are for products that help people outside your company reduce their emissions, such as low-carbon products.',
                avoidedEmissionsSub2:
                    'You always need to compare your new product to a baseline figure. The baseline figure is the amount of emissions from an alternative product that your new product is replacing. This is done in a simple subtraction:',
                avoidedEmissionsSub3: 'Baseline emission factor - New emission factor = Avoided emissions',
                avoidedEmissionsSub4: 'For Example:',
                avoidedEmissionsSub5:
                    "Let's say you replaced a gasoline car at the end of its life with an electric car. The electric car produces fewer emissions than the gasoline car, so you have avoided some emissions. For a rough estimate, we will use a carbon emission factor of 0.4kgCO2e/km for the gasoline car and 0.1kgCO2e/km for the electric car.",
                avoidedEmissionsSub6:
                    'So the avoided emission factor for your electric car is 0.3kgCO2e/km. This means that for every km traveled, you have avoided 0.3kgCO2e emissions compared to using the old gasoline car.',
                negativeEmissions: 'Negative emissions',
                negativeEmissionsSub1:
                    'Negative emissions are used for products or applications that remove greenhouse gases from the atmosphere. This includes technological solutions like Direct Air Capture and Storage (DACS) and developing natural carbon sinks like forests and wetlands.',
                negativeEmissionsSub2:
                    'Other common terms for negative emissions are Carbon Dioxide Removal (CDR) and Greenhouse Gas Removal (GGR).',
                physicalOrMonetary: 'Physical or monetary emission factors?',
                physicalOrMonetarySub1:
                    ' Depending on the unit you use or the data you have available for your emissions, you need to decide between physical or monetary emission factors.',
                physicalFactors: 'Physical emission factors',
                physicalFactorsSub1:
                    'Physical emission factors use activity data such as kilometers flown by plane or kWh of electricity used to calculate your emissions.',
                physicalFactorsSub2:
                    'They are generally more accurate than monetary emission factors. It is especially important to use physical emission factors for larger sources of emissions.',
                monetaryFactors: 'Monetary emission factors',
                monetaryFactorsSub1:
                    'Monetary emission factors give a rough estimate of your emissions based on expenditure (for example, the cost of purchased electricity in euros).',
                monetaryFactorsSub2:
                    'They are a good first step for your measurements, but they are often not as precise as physical emission factors and can lead to incorrect estimations. For example, your carbon footprint estimate may increase if you invest in greener equipment that is more expensive.',
            },
            step5: {
                title: 'Scope and Category Based Calculations',
                subtitle: '',
                scopeBased: 'Scope and Category Based Calculations',
                intro: 'GHG Protocol 3 and ISO 14064-1:2018 Standards calculate emissions using 22 emission categories.',
                scope1: 'Scope 1',
                scope1Sub1:
                    'Scope 1 covers your direct emissions. This applies to on-site energy production, industrial processes, land use, or if you have your own vehicles.',
                scope1Sub2:
                    'These activity data may also be relevant for your Scope 3 emissions, which include emissions from distribution losses as well as extraction, production, and transportation.',
                combustionOfStatic: 'Combustion of fuels in owned/controlled stationary sources',
                combustionOfStaticSub1:
                    'Emissions from burning organic or fossil fuels in owned or controlled sources such as boilers, furnaces, and turbines.',
                combustionOfMobile: 'Combustion of fuels in owned/controlled mobile sources',
                combustionOfMobileSub1:
                    'Emissions from burning fuels in vehicles owned or controlled by the company that are used for transporting people and materials, such as trucks, trains, ships, planes, buses, and cars.',
                combustionOfMobileSub2:
                    'This does not include purchased fuel for your vehicles, which should be addressed in Fuel and energy-related emissions (Scope 3).',
                physicalOrChemical: 'Physical or chemical processing',
                physicalOrChemicalSub1:
                    'Emissions from the construction or processing of chemicals and materials (e.g. cement, aluminum) owned and operated by your company, as well as from waste facilities.',
                equipmentLeaks: 'Equipment leaks (fugitive emissions)',
                equipmentLeaksSub1: 'Emissions resulting from intentional or unintentional releases, including:',
                equipmentLeaksSub2: 'Equipment leaks from joints, gaskets, packings, and seals',
                equipmentLeaksSub3: 'Methane emissions from coal mines and vents',
                equipmentLeaksSub4: 'Hydrofluorocarbon (HFC) emissions from cooling and air conditioning equipment',
                equipmentLeaksSub5: 'Methane leakage from gas transport',
                landUse: 'Land Use, Land-Use Change, and Forestry (LULUCF) emissions Direct emissions',
                landUseSub1:
                    'Land Use, Land-Use Change, and Forestry (LULUCF) emissions Direct emissions resulting from land use, land-use change, and forestry.',
                scope2: 'Scope 2',
                scope2Sub1:
                    "Scope 2 covers emissions from the energy your company purchases, including electricity, gas, or other energy sources that you don't produce yourself.",
                scope2Sub2:
                    'Note: These activity data will likely also apply to Scope 3 - Fuel and Energy-Related Activities emissions, because the energy you purchase causes upstream emissions from extraction, production, and transportation, as well as losses during transmission and distribution.',
                purchasedElectricity: 'Purchased electricity',
                purchasedElectricitySub1:
                    "Emissions resulting from the electricity generated to power your company's equipment.",
                purchasedElectricitySub2:
                    'Note: The Greenhouse Gas Protocol requires reporting this both on a location-based (i.e. average emission intensity of the grid) and market-based (i.e. emissions from the energy purchased) approach.',
                purchasedSteam: 'Purchased steam, heating, and cooling',
                purchasedSteamSub1:
                    ' Emissions from other energy sources (excluding electricity) used by your company for things like steam, heating, cooling, and compressed air.',
                scope3: 'Scope 3',
                scope3Sub1:
                    'Scope 3 emissions are emissions that you can influence but are outside of your direct control.',
                scope3Sub2:
                    'This includes waste disposal, upstream transportation for products you purchase, downstream transportation for products you sell, and cradle-to-gate emissions for energy, capital goods, and purchased goods and services used by your company.',
                scope3Sub3: 'You should also report emissions associated with leasing, investments, and franchises.',
                purchasedGoods: 'Purchased goods and services ',
                purchasedGoodsSub1:
                    'All upstream (i.e., cradle-to-gate) emissions from the production of the products you purchase. This includes both goods (tangible products) and services (intangible products).',
                purchasedGoodsSub2:
                    'This includes emissions from all purchased goods and services that are not included in the other Scope 3 emission categories.',
                capitalGoods: 'Capital goods',
                capitalGoodsSub1:
                    'All upstream (i.e., cradle-to-gate) emissions from the production of capital goods your company purchases. Emissions from the use of capital goods are not included (Scope 1 or 2).',
                capitalGoodsSub2:
                    'Capital goods are long-lived products used by your company to make a product, provide a service, or sell, store, and deliver goods, such as equipment, machinery, buildings, facilities, and vehicles.',
                capitalGoodsSub3:
                    'Cradle-to-gate emissions include all emissions generated throughout the life cycle of the product until it reaches the recipient (excluding emissions from sources you own or control). This includes things like extraction of raw materials, agricultural activities, manufacturing, production, processing, transportation between suppliers of materials and products, and all other activities up to delivery of the product.',
                fuelAndEnergy: 'Fuel- and energy-related emissions',
                fuelAndEnergySub1: 'Emissions from the fuel and energy you use that are not in Scope 1 or Scope 2:',
                fuelAndEnergySub2: 'Upstream emissions from purchased fuels:',
                fuelAndEnergySub3:
                    'Extraction, production, and transportation of your fuel (coal mining, refined gasoline, natural gas transmission and distribution, biofuel production, etc.)',
                fuelAndEnergySub4: 'Upstream emissions from purchased electricity:',
                fuelAndEnergySub5:
                    'Extraction, production, and transportation of the fuel used to generate your electricity, steam, heating, and cooling (coal mining, refining fuels, natural gas extraction, etc.)',
                fuelAndEnergySub6: 'Transmission and distribution losses:',
                fuelAndEnergySub7: 'Electricity, steam, heating, and cooling lost during transmission and distribution',
                fuelAndEnergySub8:
                    'For utility companies or energy retailers: The electricity, steam, heating, and cooling you purchase and sell to end-users (electricity, steam, heating, and cooling sold to someone else)',
                upstream: 'Upstream transportation and distribution',
                upstreamSub1:
                    ' Emissions from the transportation and distribution of products/services you purchase using vehicles and facilities that you do not own.',
                waste: 'Waste generated in operations',
                wasteSub1:
                    ' Emissions from solid waste generated by your company and emissions from waste treatment and disposal by other companies.',
                business: 'Business Travel',
                businessSub1:
                    'Emissions resulting from transporting employees in vehicles owned or operated by other companies, including planes, trains, buses, and passenger cars, for work-related activities. This does not include commuting to and from work.',
                employee: 'Employee commuting',
                employeeSub1:
                    'Emissions resulting from employees traveling between their homes and workplaces, typically by car, bus, train, or plane. This category may also include emissions from remote workers.',
                upstreamAssets: 'Upstream leased assets',
                upstreamAssetsSub1:
                    ' Emissions resulting from business assets leased by your company during the reporting year that are not already included in Scope 1 or Scope 2. This only applies to companies that operate leased assets (i.e. tenants).',
                downstream: 'Downstream transportation and distribution',
                downstreamSub1:
                    'Emissions resulting from the transportation and distribution of your products between your company and the end consumer using vehicles and facilities you do not own. This includes emissions from retail sales and storage.',
                downstreamSub2:
                    'Note: Any outbound transportation and distribution services you purchase are included in upstream transportation and distribution since you are purchasing the service.',
                processing: 'Processing of sold products',
                processingSub1:
                    'Emissions resulting from the processing of products you sell that were produced by another company (e.g. manufacturers). This applies to all intermediary products that need to be incorporated or processed into another product before use.',
                useOfSold: 'Use of sold products',
                useOfSoldSub1:
                    'Emissions created by consumers and commercial customers who use the goods and services you sell.',
                endOfLife: ' End-of-life sold products',
                endOfLifeSub1:
                    'Emissions resulting from the disposal and processing of products you sell at the end of their useful life. This includes all expected end-of-life emissions from all products sold during the reporting year.',
                downstreamAssets: 'Downstream leased assets',
                downstreamAssetsSub1:
                    ' Emissions resulting from assets you own and lease to other companies that are not included in your Scope 1 or Scope 2 emissions. Companies that operate the leased assets (i.e. lessees) should report under downstream leased assets.',
                franchise: 'Franchise',
                franchiseSub1:
                    "Emissions resulting from business franchises that are not included in Scope 1 or Scope 2. A franchise is a business that operates under a license to sell or distribute another company's goods or services in a specific location.",
                franchiseSub2:
                    'This applies to franchisors (i.e. companies that license their goods or services to other organizations for the purpose of selling or distributing them, typically in exchange for payments such as royalties for the use of trademarks and other services).',
                franchiseSub3:
                    'Franchisors should account for emissions resulting from operating franchises in this category (i.e. the Scope 1 and 2 emissions of franchisees). Franchisees (i.e. companies that operate a franchise and pay a fee to a franchisor) should include emissions from their controlled operations if they have not already included them in Scope 1 and 2.',
                investments: 'Investments',
                investmentsSub1:
                    " Emissions associated with your company's investments that are not already included in Scope 1 or Scope 2. This includes investments in the stock or assets of other companies and may include emissions resulting from the operations of those companies.",
                investmentsSub2:
                    'Emissions from investments should be allocated to your company based on your proportionate share of the investment in the investee. Financial investments are divided into 4 types:',
                investmentsList1: 'Capital investments,',
                investmentsList2: 'Debt investments,',
                investmentsList3: 'Project finance,',
                investmentsList4: 'Managed investments and customer service',
            },
            step6: {
                title: 'Reporting according to ISO 14064-1:2018',
                subtitle: '',
                reporting: 'Reporting according to ISO 14064-1:2018',
                reportingSub1:
                    'The Reporting module has been created for reporting in accordance with ISO 14064 standards, which will be verified and certified by verifying organizations.',
                reportingSub2:
                    'Thanks to the Reporting module, businesses can perform their operations quickly in 6 sections:',
                list1Title: '1. General Information, Purpose and Policy',
                list1Content:
                    'In this section, businesses can determine their goals, scope, policies, and strategies in terms of greenhouse gas emissions and produce solutions using ready-made templates. ',
                list2Title: '2. Organizational Boundaries:',
                list2Content:
                    'Organizations can determine their organizational boundaries by selecting a consolidation approach that is consistent with the purpose of greenhouse gas inventories.',
                list3Title: '3. Reporting Boundaries: ',
                list3Content:
                    'Users can track their greenhouse gas inventory lists and include emission sources that are outside the scope of the inventory due to exclusion reasons.',
                list4Title: '4. Calculated Greenhouse Gas Emissions Inventory:',
                list4Content:
                    'They will be able to view and declare the values used in the calculations, the references related to these values, and the detailed results of the calculations.',
                list5Title: '5. Inventory Report Internal Performance Monitoring:',
                list5Content:
                    'In this section, they will be able to see all categories in pie charts with effective visuals that are automatically generated and declare them in the report output.',
                list6Title: '6. Attachments:',
                list6Content:
                    'In the last section, businesses can add all the documents they want to see in addition to the report output.',
            },
            step7: {
                title: 'Source Documents',
                subtitle: '',
                sourcaDocuments: 'Source Documents',
                clickDownload: 'click to download the content.',
                category1: 'Category 1 Data Collection',
                category2: 'Category 2 Data Collection',
                category3: 'Category 3 Data Collection',
                category4: 'Category 4 Data Collection',
                category5: 'Category 5 Data Collection',
                category6: 'Greenhouse Gas Emission Sources Materiality Evaluation Chart',
                category7: 'Greenhouse Gas Activity Data Monitoring Plan',
            },
        },
        system: {
            system: 'System - Authorization',
            users: 'Users',
            privilegeGroups: 'Authority Groups',
            newUser: 'New User',
            privilegeGroupList: {
                system: 'System - Authorization',
                newGroup: 'New Group',
                no: 'No',
                groupId: 'Privilege Group ID',
                groupName: 'Group Name',
                isActive: 'Active',
                edit: 'Edit',
                create: {
                    system: 'System - Authorization',
                    newUser: 'New User',
                    groupInfo: 'Authority Group Information',
                    back: 'Back',
                    add: 'Add',
                    generalInfo: 'General Information',
                    groupId: 'Privilege Group ID',
                    groupIdPlaceholder: '0091999',
                    description: 'Description',
                    groupName: 'Group Name',
                    groupNamePlaceholder: 'Ex: Ecofoni',
                },
                privilege: {
                    system: 'System - Authorization',
                    permissions: 'Registration Permissions',
                    generalTab: 'General',
                    permissionsTab: 'Registration Permissions',
                    usersTab: 'Users',
                    groupId: 'Privilege Group ID',
                    description: 'Description',
                    groupName: 'Group Name',
                    userList: 'User List',
                    back: 'Back',
                    update: 'Update',
                },
                registration: {
                    system: 'System - Authorization',
                    yes: 'Yes',
                    no: 'No',
                    privileges: 'Privileges',
                    read: 'Reading',
                    create: 'Create',
                    update: 'Editing',
                    delete: 'Deletion',
                },
                deleteDialog: {
                    title: 'Are you sure you want to delete privilege group?',
                    description: 'Are you sure?',
                    cancel: 'Cancel',
                    delete: 'Delete',
                },
            },
            userList: {
                system: 'System - Authorization',
                newUser: 'New User',
                no: 'No',
                userRole: 'User Role',
                userId: 'User ID',
                username: 'Username',
                email: 'Email',
                connection: 'Connection',
                edit: 'Edit',
                warningMessage: 'Company users are not authorized to access the list.',
                create: {
                    system: 'System - Authorization',
                    newUser: 'New User',
                    userInfo: 'User Information',
                    back: 'Back',
                    add: 'Add',
                    generalInfo: 'General Information',
                    detailInfo: 'Detail Information',
                    username: 'Username',
                    usernamePlaceholder: 'Ex: Ecofoni',
                    userRole: 'User Role',
                    email: 'Email',
                    emailPlaceholder: 'ecofoni@test.com',
                    connection: 'Connection',
                    password: 'Password',
                    passwordPlaceholder: 'Password',
                    confirmPassword: 'Confirm Password',
                    confirmPasswordPlaceholder: 'Confirm Password',
                    notMatch: '*Passwords do not match',
                },
                dialog: {
                    system: 'System - Authorization',
                    userInfo: 'User Information',
                    generalTab: 'General',
                    permissionsTab: 'Registration Permissions',
                    groupsTab: 'Groups',
                    userId: 'User ID',
                    username: 'Username',
                    email: 'Email',
                    back: 'Back',
                    update: 'Update',
                    groups: 'Groups',
                    yes: 'Yes',
                    no: 'No',
                    privileges: 'Privileges',
                    read: 'Reading',
                    create: 'Create',
                    update_: 'Editing',
                    delete: 'Deletion',
                },
                deleteDialog: {
                    title: 'Are you sure you want to delete user?',
                    description: 'Are you sure?',
                    cancel: 'Cancel',
                    delete: 'Delete',
                },
            },
        },
    },
    topbar: {
        turkish: 'Turkish',
        english: 'English',
        profile: 'Profile',
        logout: 'Logout',
        system: 'System',
        addInventory: 'Add Inventory',
        allCompany: 'All Company',
        allFacility: 'All Facility',
        allYear: 'All Year',
        noNotification: 'You have no notifications',
        FACILITY: 'Facility',
        COMPANY: 'Company',
        ORGANIZATION: 'Organization',
    },
    sidebar: {
        dashboard: 'Dashboard',
        analytical: 'Analytical',
        organizations: 'Organizations',
        inventory: 'Inventory',
        inventoryEntry: 'Inventory Entry', // ISO
        inventoryEntryGHG: 'Inventory Entry GHG',
        inventoryList: 'Inventory List',
        reporting: 'Reporting',
        generalInformation: 'General Information',
        organizational: 'Organizational',
        reportingLimits: 'Reporting Limits',
        calculatedGHGEmissionsInventory: 'Calculated Greenhouse Gas Emissions Inventory',
        internalPerformanceTracking: 'Internal Performance Tracking',
        appendices: 'Appendices',
        targetKPI: 'Target & KPI',
        faq: 'FAQ',
        faqTitle: 'Frequently Asked Questions',
        guide: 'Guide',
    },
    common: {
        homePage: 'Home Page',
        unAuthorized: 'UnAuthorized',
        organization: 'Organization',
        company: 'Company',
        facility: 'Facility',
        calculationForms: {
            calculateButton: 'Calculate',
            baseFacility: 'Base Facility',
            subFacility: 'Sub Facility',
            greenhouseGasSource: 'Greenhouse Gas Source',
            emissionFactorStepSelection: 'Emission Factor Stage Selection',
            database: 'Database',
            sourceStream: 'Source Stream',
            unit: 'Unit',
            emissionFactor: 'Emission Factor',
            emissionFactorDescription: '*If you do not enter a value, it will be taken from the default database.',
            emissionSourceReference: 'Emission Source Reference',
            emissionSourceReferencePlaceholder: 'Write Your Emission Source Reference',
            emissionSourceReferenceDescription:
                '*If you do not enter a value, it will be taken from the default database.',
            nkd: 'NKD',
            nkdPlaceholder: 'NKD',
            nkdReference: 'NKD Reference',
            density: 'Density',
            densityPlaceholder: 'Density',
            densityReference: 'Density Reference',
            calculationYear: 'Calculation Year',
            activityData: 'Activity Data',
            activityDataDescription: 'Enter the activity data you will calculate.',
            activityDataPrimary: 'Primary Activity Data',
            activityDataPrimaryDescription:
                'If site-specific activity data is available, select primary activity data.',
            activityDataSecondary: 'Secondary Activity Data',
            activityDataSecondaryDescription:
                'If site specific activity data is not available and estimated activity data will be used, select secondary activity data.',
            selectMonth: 'Choose month',
            consumptionData: 'Consumption Data',
            consumptionDataDescription: 'Select the months you want to calculate and enter your activity data.',
            description: 'Description',
            loading: 'Loading..',
            optionNotFound: 'Option Not Found.',
            static: {
                greenhouseGasSourcePlaceholder: 'Ex: Boiler, Heater, Gas Turbine, Generator, LPG Cylinders etc.',
                sector: 'Sector',
                fuelType: 'Fuel Type',
                vehicleOccupancyRate: 'Vehicle Occupancy Rate',
                dimension: 'Dimension',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            mobile: {
                greenhouseGasSourcePlaceholder: 'Ex: Company Cars, Construction Machinery, Forklift, Pickup Truck etc.',
                activity: 'Activity',
                fuelType: 'Fuel Type',
                vehicleOccupancyRate: 'Vehicle Occupancy Rate',
                dimension: 'Dimension',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            process: {
                greenhouseGasSourcePlaceholder:
                    'Ex: Cement production, Ceramic production, Iron and Steel production etc.',
                sector: 'Sector',
                processType: 'Process Type',
                material: 'Material',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            fugitive: {
                greenhouseGasSourcePlaceholder: 'Ex: Refrigerators and Water Dispensers etc.',
                deviceType: 'Device Type',
                gasGroups: 'Gas Group',
                gasType: 'Gas Type',
                deviceFillStatus: 'Device Fill Status',
                numberOfDevices: 'Number of Devices',
                numberOfDevicesPlaceholder: 'Number of Devices',
                gasCapacity: 'Gas Capacity',
                amountOfGasCompressed: 'Amount of Gas Compressed',
                descriptionPlaceholder: 'Ex: Production + Warming',
                filled: 'Filled',
                notFilled: 'Not Filled',
            },
            lulucf: {
                greenhouseGasSourcePlaceholder: 'Ex: Land Use Change',
                activity: 'Activity',
                activityType: 'Activity Type',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            biomass: {
                greenhouseGasSourcePlaceholder: 'Ex: Boiler, Heater, Gas Turbine, Generator, LPG Cylinders etc.',
                sector: 'Sector',
                fuelType: 'Fuel Type',
                vehicleOccupancyRate: 'Vehicle Occupancy Rate',
                dimension: 'Dimension',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            electric: {
                activityType: 'Activity Type',
                greenhouseGasSourcePlaceholder: 'Ex: Shipping of sold products',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            steam: {
                greenhouseGasSourcePlaceholder: 'Ex: Boiler, Heater, Gas Turbine, Generator, LPG Cylinders etc.',
                sector: 'Sector',
                fuel: 'Fuel',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            upstream: {
                greenhouseGasSourcePlaceholder: 'Ex: Shipping of purchased materials',
                activity: 'Activity',
                activityType: 'Activity Type',
                fuelType: 'Fuel Type',
                vehicleOccupancyRate: 'Vehicle Occupancy Rate',
                dimension: 'Dimension',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            downstream: {
                greenhouseGasSourcePlaceholder: 'Ex: Shipping of purchased materials',
                activity: 'Activity',
                activityType: 'Activity Type',
                fuelType: 'Fuel Type',
                vehicleOccupancyRate: 'Vehicle Occupancy Rate',
                dimension: 'Dimension',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            employee: {
                greenhouseGasSourcePlaceholder: 'Ex: Transportation of personnel by personal/public transport',
                activity: 'Activity',
                activityType: 'Activity Type',
                fuelType: 'Fuel Type',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            visitor: {
                greenhouseGasSourcePlaceholder:
                    "Ex: Visitor's/Customer's personal vehicles/public transportation/air transportation",
                activity: 'Activity',
                activityType: 'Activity Type',
                fuelType: 'Fuel Type',
                flightClass: 'Flight Class',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            business: {
                greenhouseGasSourcePlaceholder:
                    "Ex: Personnel's personal vehicle/air transportation, Personnel's overnight hotel accommodation, etc.",
                activity: 'Activity',
                activityType: 'Activity Type',
                country: 'Country',
                fuelType: 'Fuel Type',
                flightClass: 'Flight Class',
                whichCountry: 'Please indicate which country you are staying in',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            soldProducts: {
                greenhouseGasSourcePlaceholder: 'Ex: Water Supply and Purchase of Construction Materials',
                activity: 'Activity',
                activityType: 'Activity Type',
                material: 'Material',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            boughtAssets: {
                greenhouseGasSourcePlaceholder: 'Ex: Construction Material capital goods and Plastic capital goods',
                activity: 'Activity',
                activityType: 'Activity Type',
                material: 'Material',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            waste: {
                greenhouseGasSourcePlaceholder: 'Ex: Construction Material disposal and Metal disposal',
                activity: 'Activity',
                activityType: 'Activity Type',
                disposalMethod: 'Disposal Method',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            productLifetime: {
                greenhouseGasSourcePlaceholder: 'Ex: Construction Material, Metal, Paper disposal',
                activity: 'Activity',
                activityType: 'Activity Type',
                disposalMethod: 'Disposal Method',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            serviceUsage: {
                greenhouseGasSourcePlaceholder: 'Ex: Shipping of purchased materials',
                activity: 'Activity',
                activityType: 'Activity Type',
                fuelType: 'Fuel Type',
                vehicleOccupancyRate: 'Vehicle Occupancy Rate',
                dimension: 'Dimension',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            upstreamLeased: {
                greenhouseGasSourcePlaceholder: 'Ex: Company Cars, Construction Machinery, Forklift, Pickup Truck etc.',
                activity: 'Activity',
                fuelType: 'Fuel Type',
                vehicleOccupancyRate: 'Vehicle Occupancy Rate',
                dimension: 'Dimension',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            downstreamLeased: {
                greenhouseGasSourcePlaceholder: 'Ex: Company Cars, Construction Machinery, Forklift, Pickup Truck etc.',
                activity: 'Activity',
                fuelType: 'Fuel Type',
                vehicleOccupancyRate: 'Vehicle Occupancy Rate',
                dimension: 'Dimension',
                descriptionPlaceholder: 'Ex: Production + Warming',
            },
            months: {
                january: 'January',
                february: 'February',
                march: 'March',
                april: 'April',
                may: 'May',
                june: 'June',
                july: 'July',
                august: 'August',
                september: 'September',
                october: 'October',
                november: 'November',
                december: 'December',
                all: 'Whole year',
            },
        },
    },
    notification: {
        welcome: 'Welcome.',
        someFieldsBlank: 'A mandatory field(s) is left empty/unselected.',
        somethingWentWrong: 'Something went wrong.',
        documentAdded: 'The document has been successfully added.',
        documentDownload: 'The document has been downloaded successfully.',
        cannotDownloaded: 'This file cannot be downloaded.',
        successCreated: 'Successfully created.',
        successUpdated: 'Successfully updated.',
        successDeleted: 'Successfully deleted.',
        pleaseMakeYourChoise: 'Please make your choice.',
        codeError: 'Invalid code.',
        emailNotFound: 'Email not found.',
    },
    sdg: {
        name1: 'END TO POVERTY',
        title1: 'Ending poverty in all its forms everywhere',
        name2: 'STOP HUNGER',
        title2: 'Ending hunger, achieving food security and good nutrition, and supporting sustainable agriculture',
        name3: 'HEALTH AND QUALITY LIFE',
        title3: 'Securing healthy and quality life at all ages',
        name4: 'QUALIFIED EDUCATION',
        title4: 'Ensuring inclusive and equitable quality education and promoting lifelong learning opportunities for all',
        name5: 'GENDER EQUALITY',
        title5: 'Achieving gender equality and empowering all women and girls.',
        name6: 'CLEAN WATER AND SANITATION',
        title6: 'Securing accessible water and wastewater services and sustainable water management for all',
        name7: 'ACCESSIBLE AND CLEAN ENERGY',
        title7: 'Ensuring access to affordable, reliable, sustainable and modern energy for all',
        name8: 'DECENT WORK AND ECONOMIC GROWTH',
        title8: 'Promoting stable, inclusive and sustainable economic growth, full and productive employment and decent work for all',
        name9: 'INDUSTRY, INNOVATION AND INFRASTRUCTURE',
        title9: 'Building resilient infrastructures, supporting inclusive and sustainable industrialization and strengthening innovation',
        name10: 'REDUCTION OF INEQUALITIES',
        title10: 'Reducing inequalities within and between countries',
        name11: 'SUSTAINABLE CITIES AND COMMUNITIES',
        title11: 'Making cities and human settlements inclusive, safe, resilient and sustainable',
        name12: 'RESPONSIBLE PRODUCTION AND CONSUMPTION',
        title12: 'Ensuring sustainable production and consumption patterns',
        name13: 'CLIMATE ACTION',
        title13: 'Taking urgent action to combat climate change and its impacts',
        name14: 'LIFE IN WATER',
        title14: 'Conserving and sustainably using oceans, seas and marine resources for sustainable development',
        name15: 'TERRESTIAL LIFE',
        title15:
            'To protect, restore and promote the sustainable use of terrestrial ecosystems; ensuring sustainable forest management; combating desertification; stopping and reversing land degradation; preventing biodiversity loss',
        name16: 'PEACE, JUSTICE AND STRONG INSTITUTIONS',
        title16:
            'Establishing peaceful and inclusive societies for sustainable development, ensuring access to justice for all and building effective, accountable and inclusive institutions at all levels',
        name17: ' PARTNERSHIPS FOR PURPOSES',
        title17:
            'Strengthening the means of implementation and revitalizing the global partnership for sustainable development',
    },
};
