import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    DELETE_USER_PRIVILEGE,
    DELETE_USER_ROLE,
    GET_USER_BY_ID,
    GET_USER_CONNECTION_ROLES,
    GET_USER_LIST,
    GET_USER_LIST_BACKOFFICE,
    GET_USER_PRIVILEGE_GROUPS,
    GET_USER_ROLE_ENUM,
    GET_USER_ROLES,
    getUserById,
    getUserConnectionRoles,
    getUserList,
    PUT_USER_BY_ID,
    PUT_USER_PRIVILEGE,
    PUT_USER_ROLE,
    setUserById,
    setUserConnectionRoles,
    setUserList,
    setUserPrivilegeGroups,
    setUserRoles,
    SUBMIT_USER_CREATE_USER,
    UPDATE_NEW_PASSWORD,
} from '../../../actions/system/user.action';
import { IConnectionRole, IPrivilegeGroup, IRole } from '../../../../entities/system/userCombustion';
import { createNotification } from '../../../actions/ui.action';
import i18next from 'i18next';

export const userMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case GET_USER_LIST_BACKOFFICE: {
                interface IBody {
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                }
                const body: IBody = {
                    organizationId: 0,
                    companyId: 0,
                    facilityId: 0,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/user/list/backoffice',
                        feature: GET_USER_LIST_BACKOFFICE,
                        body,
                    })
                );
                break;
            }
            case `${GET_USER_LIST_BACKOFFICE} ${API_SUCCESS}`: {
                break;
            }

            case GET_USER_LIST: {
                const organizationId = state.auth.userInfo?.user?.organizationId;
                const companyId = state.auth.userInfo?.user?.companyId;
                const facilityId = state.auth.userInfo?.user?.facilityId;

                interface IBody {
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                }
                const body: IBody = {
                    organizationId,
                    companyId,
                    facilityId,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/user/list',
                        feature: GET_USER_LIST,
                        body,
                    })
                );
                break;
            }
            case `${GET_USER_LIST} ${API_SUCCESS}`: {
                const users = action.payload?.userDtoSet;
                dispatch(setUserList(users));
                break;
            }

            case GET_USER_BY_ID: {
                const userId = action.payload;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `auth/userInfo?userId=${userId}`,
                        feature: GET_USER_BY_ID,
                    })
                );
                break;
            }
            case `${GET_USER_BY_ID} ${API_SUCCESS}`: {
                const data = action.payload?.userInfo;
                dispatch(setUserById(data));
                dispatch(getUserConnectionRoles(data?.user?.roleTypes[0]));
                break;
            }

            case PUT_USER_BY_ID: {
                const userId = action.payload;
                const username = state?.system?.createUser?.username;
                const companyId = state?.system?.createUser?.selectedConnectionRole?.id;
                const email = state?.system?.createUser?.email;
                //const password = state?.system?.createUser?.password;
                const role = state?.system?.createUser?.selectedUserRole?.key;
                const roles = role && [role];

                interface IBody {
                    userId: number;
                    username?: string;
                    password?: string;
                    email: string;
                    roles: Array<string>;
                    companyId?: number;
                    facilityId?: number;
                }

                const body: IBody = {
                    userId,
                    username,
                    //password,
                    email,
                    roles,
                    companyId,
                    //facilityId,
                };

                next(
                    apiRequest({
                        method: 'PUT',
                        url: `/auth/user`,
                        feature: PUT_USER_BY_ID,
                        body,
                        onSuccess: action.meta.onSuccess,
                    })
                );
                break;
            }
            case `${PUT_USER_BY_ID} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                dispatch(getUserList());
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'success',
                        message: action.payload?.message || i18next.t('notification.successUpdated'),
                    })
                );
                break;
            }

            case `${PUT_USER_BY_ID} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case PUT_USER_ROLE: {
                interface IBody {
                    userId: number;
                    roles: Array<string>;
                }
                const body: IBody = {
                    userId: 0,
                    roles: ['string'],
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/auth/user/role',
                        feature: PUT_USER_ROLE,
                        body,
                    })
                );
                break;
            }
            case `${PUT_USER_ROLE} ${API_SUCCESS}`: {
                break;
            }

            case DELETE_USER_ROLE: {
                interface IBody {
                    userId: number;
                    roles: Array<string>;
                }
                const body: IBody = {
                    userId: 0,
                    roles: ['string'],
                };
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: '/auth/user/role',
                        feature: DELETE_USER_ROLE,
                        body,
                    })
                );
                break;
            }
            case `${DELETE_USER_ROLE} ${API_SUCCESS}`: {
                break;
            }

            case PUT_USER_PRIVILEGE: {
                interface IBody {
                    privilegeList: [
                        {
                            privilegedApiType: string;
                            permissionTypeSet: Array<string>;
                        }
                    ];
                    userId: number;
                }
                const body: IBody = action.payload;
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/auth/user/privilege',
                        feature: PUT_USER_PRIVILEGE,
                        body,
                    })
                );
                break;
            }
            case `${PUT_USER_PRIVILEGE} ${API_SUCCESS}`: {
                dispatch(getUserById(action.payload?.userInfo?.user?.id));
                break;
            }

            case DELETE_USER_PRIVILEGE: {
                interface IBody {
                    privilegeList: [
                        {
                            privilegedApiType: string;
                            permissionTypeSet: Array<string>;
                        }
                    ];
                    userId: number;
                }

                const body: IBody = action.payload;
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: '/auth/user/privilege',
                        feature: DELETE_USER_PRIVILEGE,
                        body,
                    })
                );
                break;
            }
            case `${DELETE_USER_PRIVILEGE} ${API_SUCCESS}`: {
                dispatch(getUserById(action.payload?.userInfo?.user?.id));
                break;
            }

            case GET_USER_ROLE_ENUM: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/auth/user/role/enum',
                        feature: GET_USER_ROLE_ENUM,
                    })
                );
                break;
            }
            case `${GET_USER_ROLE_ENUM} ${API_SUCCESS}`: {
                break;
            }

            // CREATE USER
            case GET_USER_ROLES: {
                next(
                    apiRequest({
                        method: 'GET',
                        url: '/auth/user/role/enum',
                        feature: GET_USER_ROLES,
                    })
                );
                break;
            }
            case `${GET_USER_ROLES} ${API_SUCCESS}`: {
                const data = action.payload?.contentDtoList.reduce(
                    (
                        acc: Array<IRole>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.title,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                dispatch(setUserRoles(data));
                break;
            }

            case GET_USER_CONNECTION_ROLES: {
                const role = action.payload;
                const user = state?.auth?.userInfo?.user;
                const organizationId =
                    state?.auth?.userInfo?.organization?.id || state?.auth?.userInfo?.company?.organizationId;
                const companyId = state?.auth?.userInfo?.company?.id;

                interface IBody {
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                }
                const body: IBody = {};
                //if (role == 'ROLE_ORGANIZATION_ADMIN') body['organizationId'] = organizationId;
                //if (role == 'ROLE_ORGANIZATION_USER') body['organizationId'] = organizationId;
                if (role == 'ROLE_COMPANY_ADMIN') body['organizationId'] = organizationId;
                if (role == 'ROLE_COMPANY_USER') body['organizationId'] = organizationId;
                if (role == 'ROLE_FACILITY_ADMIN') body['companyId'] = companyId;
                if (role == 'ROLE_FACILITY_USER') body['companyId'] = companyId;

                next(
                    apiRequest({
                        method: 'POST',
                        url: '/corporation/scheme',
                        feature: GET_USER_CONNECTION_ROLES,
                        body,
                    })
                );
                break;
            }
            case `${GET_USER_CONNECTION_ROLES} ${API_SUCCESS}`: {
                const data = action.payload?.corporationPart?.subPartSet?.reduce(
                    (
                        acc: Array<IConnectionRole>,
                        v: {
                            id: number;
                            name: string;
                            type: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                            type: v.type,
                        });
                        return acc;
                    },
                    []
                );
                dispatch(setUserConnectionRoles(data));
                break;
            }

            case GET_USER_PRIVILEGE_GROUPS: {
                interface IBody {
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                }
                const body: IBody = {
                    companyId: state?.auth?.userInfo?.company?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/privilegeGroup/names',
                        feature: GET_USER_PRIVILEGE_GROUPS,
                        body,
                    })
                );
                break;
            }
            case `${GET_USER_PRIVILEGE_GROUPS} ${API_SUCCESS}`: {
                const data = action.payload?.contentDtoList.reduce(
                    (
                        acc: Array<IPrivilegeGroup>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            key: v.name,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );
                dispatch(setUserPrivilegeGroups(data));
                break;
            }

            case SUBMIT_USER_CREATE_USER:
                interface IBody {
                    username: string;
                    password: string;
                    email: string;
                    roles: Array<string>;
                    organizationId?: number;
                    companyId?: number;
                }
                const body: IBody = {
                    username: state?.system?.createUser?.username,
                    password: state?.system?.createUser?.password,
                    email: state?.system?.createUser?.email,
                    roles: [state?.system?.createUser?.selectedUserRole?.key],
                    //organizationId: state?.auth?.userInfo?.organization?.id || null,
                    companyId: state?.auth?.userInfo?.company?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/auth/createUser/company',
                        feature: SUBMIT_USER_CREATE_USER,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            case `${SUBMIT_USER_CREATE_USER} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                break;
            }
            case `${SUBMIT_USER_CREATE_USER} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 4000,
                        type: 'error',
                        message: data.error || i18next.t('notification.someFieldsBlank'),
                    })
                );
                break;
            }
            case UPDATE_NEW_PASSWORD: {
                interface IBody {
                    userId: number;
                    newPassword: string;
                }
                const body: IBody = {
                    userId: action.payload,
                    newPassword: state?.system?.createUser?.password,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/auth/user/password',
                        feature: UPDATE_NEW_PASSWORD,
                        body,
                    })
                );
                break;
            }
            case `${UPDATE_NEW_PASSWORD} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || action.payload.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }
        }
    };
