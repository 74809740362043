import {
    ICategory,
    ICorporateEmission,
    ICorporateFilter,
    ICountry,
    IFacility,
    IGlobalFacility,
    ISector,
    IState,
    ISubCategory,
    ICompany,
} from '../../../entities/company/facilityCombustion';

export const FACILITY = '[FACILITY]';

export const EXPORT_INVENTORY_LIST = `${FACILITY} Export Inventory List`;

export const SELECT_FACILITY_DASHBOARD_YEAR = `${FACILITY} Select Dashboard Year`;
export const GET_FACILITY_RESULT = `${FACILITY} Get Result`;
export const SET_FACILITY_RESULT = `${FACILITY} Set Result`;

export const GET_FACILITY_CATEGORY = `${FACILITY} Get Category`;
export const SET_FACILITY_CATEGORY = `${FACILITY} Set Category`;
export const SELECT_FACILITY_CATEGORY = `${FACILITY} SELECT Category`;

export const GET_FACILITY_SUB_CATEGORY = `${FACILITY} Get Sub Category`;
export const SET_FACILITY_SUB_CATEGORY = `${FACILITY} Set Sub Category`;
export const SELECT_FACILITY_SUB_CATEGORY = `${FACILITY} SELECT Sub Category`;

export const CHANGE_FACILITY_NAME = `${FACILITY} Change Name`;
export const CHANGE_FACILITY_EMAIL = `${FACILITY} Change Email`;
export const CHANGE_FACILITY_WEBSITE = `${FACILITY} Change Website`;
export const CHANGE_FACILITY_PHONE = `${FACILITY} Change Phone`;

export const GET_FACILITY_COUNTRY = `${FACILITY} Get Country`;
export const SET_FACILITY_COUNTRY = `${FACILITY} Set Country`;
export const SELECT_FACILITY_COUNTRY = `${FACILITY} SELECT Country`;

export const GET_FACILITY_CITY = `${FACILITY} Get City`;
export const SET_FACILITY_CITY = `${FACILITY} Set City`;
export const SELECT_FACILITY_CITY = `${FACILITY} Select City`;

export const GET_FACILITY_SECTOR = `${FACILITY} Get Sector`;
export const SET_FACILITY_SECTOR = `${FACILITY} Set Sector`;
export const SELECT_FACILITY_SECTOR = `${FACILITY} Select Sector`;

export const CHANGE_FACILITY_ADDRESS = `${FACILITY} Change Address`;
export const CHANGE_FACILITY_DESCRIPTION = `${FACILITY} Change Description`;

export const GET_FACILITY_LIST = `${FACILITY} Get Facility List`;
export const SET_FACILITY_LIST = `${FACILITY} Set Facility List`;
export const GET_COMPANY_LIST = `${FACILITY} Get Company List`;
export const SET_COMPANY_LIST = `${FACILITY} Set Company List`;

export const GET_FACILITY_CORPORATE_LIST = `${FACILITY} Get Corporate List`;
export const SET_FACILITY_CORPORATE_LIST = `${FACILITY} Set Corporate List`;

export const UPDATE_FACILITY = `${FACILITY} Update Facility`;
export const DELETE_FACILITY_INVERTORY = `${FACILITY} Delete Inventory`;
export const DELETE_FACILITY = `${FACILITY} Delete Facility`;
export const SUBMIT_FACILITY_FORM = `${FACILITY} Submit Facility Form`;

export const DELETE_FACILITY_CORPORATE = `${FACILITY} Delete Corporate`;

export const GET_FACILITY_DASHBOARD_DATA = `${FACILITY} Get Dashboard Data`;
export const SET_FACILITY_DASHBOARD_DATA = `${FACILITY} Set Dashboard Data`;

export const GET_FACILITY_DETAIL = `${FACILITY} Get Facility Detail`;
export const SET_FACILITY_DETAIL = `${FACILITY} Set Facility Detail`;

export const DELETE_FACILITY_LIST_ITEM = `${FACILITY} Delete Facility List Item`;

export const GET_FACILITY_GLOBAL_LIST = `${FACILITY} Get Global List`;
export const SET_FACILITY_GLOBAL_LIST = `${FACILITY} Set Global List`;
export const SELECT_FACILITY = `${FACILITY} Select Facility`;

export const CHANGE_FACILITY_LIMIT = `${FACILITY} Change Facilit Limit`;

export const CLEAR_FACILITY_INVENTORY_LIST_FILTER = `${FACILITY} Clear Inventory List Filter Form`;
//

/// FACILITY
export type ISubmit = {
    onSuccess: () => void;
};

export const exportInventoryList = (value: ICorporateFilter) => ({
    type: EXPORT_INVENTORY_LIST,
    payload: value,
});

export const selectFacility = (facility: any) => ({
    type: SELECT_FACILITY,
    payload: facility,
});
export const getFacilityGlobalList = (type?: string, lang?: string) => ({
    type: GET_FACILITY_GLOBAL_LIST,
    payload: { type, lang },
});

export const setFacilityGlobalList = (facility: Array<IGlobalFacility>) => ({
    type: SET_FACILITY_GLOBAL_LIST,
    payload: facility,
});

export const deleteFacilityListItem = (value: Array<number>) => ({
    type: DELETE_FACILITY_LIST_ITEM,
    payload: value,
});

export const selectFacilityDashboardYear = (value: any) => ({
    type: SELECT_FACILITY_DASHBOARD_YEAR,
    payload: value,
});

export const getFacilityResult = (value: number) => ({
    type: GET_FACILITY_RESULT,
    payload: value,
});

export const setFacilityResult = (facility: IFacility) => ({
    type: SET_FACILITY_RESULT,
    payload: facility,
});
export const changeFacilityLimit = (data: number) => ({
    type: CHANGE_FACILITY_LIMIT,
    payload: data,
});

export const updateFacility = (value: number) => ({
    type: UPDATE_FACILITY,
    payload: value,
});

export const getFacilityCategory = () => ({
    type: GET_FACILITY_CATEGORY,
});

export const setFacilityCategory = (categories: Array<ICategory>) => ({
    type: SET_FACILITY_CATEGORY,
    payload: categories,
});

export const selectFacilityCategory = (category: ICategory) => ({
    type: SELECT_FACILITY_CATEGORY,
    payload: category,
});

export const getFacilitySubCategory = (value: number) => ({
    type: GET_FACILITY_SUB_CATEGORY,
});

export const setFacilitySubCategory = (subCategories: Array<ISubCategory>) => ({
    type: SET_FACILITY_SUB_CATEGORY,
    payload: subCategories,
});

export const selectFacilitySubCategory = (subCategory: ISubCategory) => ({
    type: SELECT_FACILITY_SUB_CATEGORY,
    payload: subCategory,
});

export const changeFacilityName = (value: string) => ({
    type: CHANGE_FACILITY_NAME,
    payload: value,
});

export const changeFacilityEmail = (value: string) => ({
    type: CHANGE_FACILITY_EMAIL,
    payload: value,
});

export const changeFacilityWebsite = (value: string) => ({
    type: CHANGE_FACILITY_WEBSITE,
    payload: value,
});

export const changeFacilityPhone = (value: string) => ({
    type: CHANGE_FACILITY_PHONE,
    payload: value,
});

export const getFacilityCountry = () => ({
    type: GET_FACILITY_COUNTRY,
});

export const setFacilityCountry = (countries: Array<ICountry>) => ({
    type: SET_FACILITY_COUNTRY,
    payload: countries,
});

export const selectFacilityCountry = () => ({
    type: SELECT_FACILITY_COUNTRY,
});

export const getFacilityCity = () => ({
    type: GET_FACILITY_CITY,
});

export const setFacilityCity = (cities: Array<IState>) => ({
    type: SET_FACILITY_CITY,
    payload: cities,
});

export const selectFacilityCity = (city: IState) => ({
    type: SELECT_FACILITY_CITY,
    payload: city,
});

export const getFacilitySector = () => ({
    type: GET_FACILITY_SECTOR,
});

export const setFacilitySector = (sectors: Array<ISector>) => ({
    type: SET_FACILITY_SECTOR,
    payload: sectors,
});

export const selectFacilitySector = (sector: ISector) => ({
    type: SELECT_FACILITY_SECTOR,
    payload: sector,
});

export const changeFacilityAddress = (value: string) => ({
    type: CHANGE_FACILITY_ADDRESS,
    payload: value,
});

export const changeFacilityDescription = (value: string) => ({
    type: CHANGE_FACILITY_DESCRIPTION,
    payload: value,
});

export const getFacilityList = () => ({
    type: GET_FACILITY_LIST,
});

export const setFacilityList = (list: Array<IFacility>) => ({
    type: SET_FACILITY_LIST,
    payload: list,
});

export const getCompanyList = () => ({
    type: GET_COMPANY_LIST,
});

export const setCompanyList = (list: Array<ICompany>) => ({
    type: SET_COMPANY_LIST,
    payload: list,
});

export const getFacilityCorporateList = (value: ICorporateFilter) => ({
    type: GET_FACILITY_CORPORATE_LIST,
    payload: value,
});

export const setFacilityCorporateList = ({
    list,
    totalElements,
}: {
    list: Array<ICorporateEmission>;
    totalElements: number;
}) => ({
    type: SET_FACILITY_CORPORATE_LIST,
    payload: { list, totalElements },
});

export const deleteFacility = (value: number) => ({
    type: DELETE_FACILITY,
    payload: value,
});

export const deleteFacilityInventory = (value: number) => ({
    type: DELETE_FACILITY_INVERTORY,
    payload: value,
});

export const deleteFacilityCorporate = (value: number) => ({
    type: DELETE_FACILITY_CORPORATE,
    payload: value,
});

export const submitFacilityForm = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_FACILITY_FORM,
    meta: {
        onSuccess,
    },
});

export const getFacilityDashboardData = () => ({
    type: GET_FACILITY_DASHBOARD_DATA,
});

export const setFacilityDashboardData = (value: any) => ({
    type: SET_FACILITY_DASHBOARD_DATA,
    payload: value,
});

export const getFacilityDetail = (id: string) => ({
    type: GET_FACILITY_DETAIL,
    payload: id,
});

export const setFacilityDetail = (value: any) => ({
    type: SET_FACILITY_DETAIL,
    payload: value,
});

export const clearFacilityInventoryListFilterForm = () => ({
    type: CLEAR_FACILITY_INVENTORY_LIST_FILTER,
});
/// END FACILITY
