import { AnyAction } from 'redux';
import {
    CHANGE_STATIC_CONSUMPTION_AMOUNT,
    CHANGE_STATIC_DENSITY,
    CHANGE_STATIC_DENSITY_REF,
    CHANGE_STATIC_DESCRIPTION,
    CHANGE_STATIC_DISTANCE,
    CHANGE_STATIC_EQUIVALENT_FACTOR,
    CHANGE_STATIC_MAIN_FACILITY,
    CHANGE_STATIC_NKD,
    CHANGE_STATIC_NKD_REF,
    CHANGE_STATIC_SUB_FACILITY,
    CHANGE_STATIC_TAGS,
    CHANGE_STATIC_WEIGHT,
    CHANGE_STATIC_YEAR,
    CLEAR_STATIC_CONSUMPTION_AMOUNT,
    CLEAR_STATIC_INPUT,
    SELECT_STATIC_ACTIVITY,
    SELECT_STATIC_ACTIVITY_DATA,
    SELECT_STATIC_ACTIVITY_TYPE,
    SELECT_STATIC_DATABASE,
    SELECT_STATIC_FUEL_TYPE,
    SELECT_STATIC_LADEN_PERCENTAGE,
    SELECT_STATIC_SIZE,
    SELECT_STATIC_TIER,
    SELECT_STATIC_UNIT,
    SET_STATIC_ACTIVITIES,
    SET_STATIC_ACTIVITY_TYPES,
    SET_STATIC_ADDITIONAL_CONSTANTS,
    SET_STATIC_DATABASES,
    SET_STATIC_FUEL_TYPES,
    SET_STATIC_LADEN_PERCENTAGES,
    SET_STATIC_RESULT,
    SET_STATIC_SIZES,
    SET_STATIC_TIERS,
    SET_STATIC_UNITS,
} from '../../actions/emission/static.action';
import { CLEAR_BASE_INPUTS } from '../../actions/emission/base.action';
import { clearInput } from './base.reducer';
import { IStaticAdditional } from '../../../entities/emissionTypes/staticCombustion';

const initialState = {
    mainFacility: '',
    subFacility: '',
    tiers: [],
    tier: '',
    sectorList: [],
    sector: '',
    emissionSource: '',
    sourceList: [],
    source: '',
    databases: [],
    database: '',
    activityList: [],
    activity: '',
    activityTypeList: [],
    activityType: '',
    activityData: '',
    unitList: [],
    unit: '',
    weight: '',
    distance: '',
    tags: '',
    year: '',
    equivalentFactor: '',
    consumptions: {
        0: { tonne: null, km: null },
        1: { tonne: null, km: null },
        2: { tonne: null, km: null },
        3: { tonne: null, km: null },
        4: { tonne: null, km: null },
        5: { tonne: null, km: null },
        6: { tonne: null, km: null },
        7: { tonne: null, km: null },
        8: { tonne: null, km: null },
        9: { tonne: null, km: null },
        10: { tonne: null, km: null },
        11: { tonne: null, km: null },
        12: { tonne: null, km: null },
    },
    calculationDate: '',
    description: '',
    result: [],
    equivalentFactorUnit: '',
    nkd: { identifier: null, reference: null, unit: null, value: null } as IStaticAdditional,
    density: { identifier: null, reference: null, unit: null, value: null } as IStaticAdditional,
};

export const emissionStaticReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_STATIC_ADDITIONAL_CONSTANTS: {
            let unit = '';
            let nkd: IStaticAdditional = {} as IStaticAdditional;
            let density: IStaticAdditional = {} as IStaticAdditional;
            action.payload.map((i: IStaticAdditional) => {
                if (i.identifier === 'NKD') nkd = i;
                if (i.identifier === 'DENSITY') density = i;
                if (i.identifier === 'CO2E') unit = i.unit || '';
            });
            return {
                ...state,
                nkd,
                density,
                equivalentFactorUnit: unit,
            };
        }
        case CHANGE_STATIC_NKD:
            return {
                ...state,
                nkd: {
                    ...state.nkd,
                    value: action.payload,
                },
            };
        case CHANGE_STATIC_NKD_REF:
            return {
                ...state,
                nkd: {
                    ...state.nkd,
                    reference: action.payload,
                },
            };
        case CHANGE_STATIC_DENSITY:
            return {
                ...state,
                density: {
                    ...state.density,
                    value: action.payload,
                },
            };
        case CHANGE_STATIC_DENSITY_REF:
            return {
                ...state,
                density: {
                    ...state.density,
                    reference: action.payload,
                },
            };
        case CLEAR_STATIC_INPUT:
            const data = clearInput(action, initialState);
            return {
                ...state,
                ...data,
            };
        case CLEAR_BASE_INPUTS:
            return {
                ...initialState,
            };
        case CHANGE_STATIC_MAIN_FACILITY:
            return {
                ...state,
                mainFacility: action.payload,
            };
        case CHANGE_STATIC_SUB_FACILITY:
            return {
                ...state,
                subFacility: action.payload,
            };
        case SET_STATIC_TIERS:
            return {
                ...state,
                tiers: action.payload,
            };
        case SELECT_STATIC_TIER:
            return {
                ...state,
                tier: action.payload,
            };
        case SET_STATIC_DATABASES:
            return {
                ...state,
                databases: action.payload,
            };
        case SELECT_STATIC_DATABASE:
            return {
                ...state,
                database: action.payload,
            };
        case CHANGE_STATIC_EQUIVALENT_FACTOR:
            return {
                ...state,
                equivalentFactor: action.payload,
            };
        case SET_STATIC_ACTIVITIES:
            return {
                ...state,
                activityList: action.payload,
            };
        case SELECT_STATIC_ACTIVITY:
            return {
                ...state,
                activity: action.payload,
            };
        case SET_STATIC_FUEL_TYPES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_STATIC_FUEL_TYPE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_STATIC_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypeList: action.payload,
            };
        case SELECT_STATIC_ACTIVITY_TYPE:
            return {
                ...state,
                activityType: action.payload,
            };
        case SET_STATIC_UNITS:
            return {
                ...state,
                unitList: action.payload,
            };
        case SELECT_STATIC_UNIT:
            return {
                ...state,
                unit: action.payload,
            };
        case CHANGE_STATIC_WEIGHT:
            return {
                ...state,
                weight: action.payload,
            };
        case CHANGE_STATIC_DISTANCE:
            return {
                ...state,
                distance: action.payload,
            };
        case SET_STATIC_LADEN_PERCENTAGES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_STATIC_LADEN_PERCENTAGE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_STATIC_SIZES:
            return {
                ...state,
                sourceList: action.payload,
            };
        case SELECT_STATIC_SIZE:
            return {
                ...state,
                source: action.payload,
            };
        case SET_STATIC_RESULT:
            return {
                ...state,
                result: action.payload,
            };
        case SELECT_STATIC_ACTIVITY_DATA:
            return {
                ...state,
                activityData: action.payload,
            };
        case CHANGE_STATIC_YEAR:
            return {
                ...state,
                year: action.payload,
            };
        case CHANGE_STATIC_TAGS:
            return {
                ...state,
                tags: action.payload,
            };
        case CHANGE_STATIC_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            };

        case CHANGE_STATIC_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: {
                    ...state.consumptions,
                    [action.payload.month]: {
                        ...state.consumptions[action.payload.month as keyof typeof state.consumptions],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case CLEAR_STATIC_CONSUMPTION_AMOUNT:
            return {
                ...state,
                consumptions: initialState.consumptions,
            };
        default:
            return state;
    }
};
