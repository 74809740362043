import { IConnectionRole, IPrivilegeGroup, IRole, IUser } from '../../../entities/system/userCombustion';
import { IUserPrivilegeDelete, IUserPrivilegePut } from '../../../entities/system/privilegeGroupCombustion';

export const USER = '[USER]';

export const GET_USER_LIST_BACKOFFICE = `${USER} Get User List Backoffice`;
export const SET_USER_LIST_BACKOFFICE = `${USER} Set User List Backoffice`;
export const GET_USER_LIST = `${USER} Get User List`;
export const SET_USER_LIST = `${USER} Set User List`;
export const GET_USER_BY_ID = `${USER} Get User By Id`;
export const SET_USER_BY_ID = `${USER} Set User By Id`;
export const PUT_USER_BY_ID = `${USER} Put User By Id`;
export const CLEAR_USER_INFO = `${USER} Clear User Info`;

export const PUT_USER_ROLE = `${USER} Put User Role`;
export const DELETE_USER_ROLE = `${USER} Delete User Role`;
export const PUT_USER_PRIVILEGE = `${USER} Put User Privilege`;
export const DELETE_USER_PRIVILEGE = `${USER} Delete User Privilege`;
export const GET_USER_ROLE_ENUM = `${USER} Get User Role Enum`;
export const SET_USER_ROLE_ENUM = `${USER} Set User Role Enum`;

export const CHANGE_USER_USERNAME = `${USER} Change User Username`;
export const CHANGE_USER_EMAIL = `${USER} Change User Email`;
export const CHANGE_USER_PASSWORD = `${USER} Change User Password`;
export const CHANGE_USER_CONFIRM_PASSWORD = `${USER} Change User Confirm Password`;
export const GET_USER_ROLES = `${USER} Get User Roles`;
export const SET_USER_ROLES = `${USER} Set User Roles`;
export const SELECT_USER_ROLE = `${USER} Select User Role`;
export const GET_USER_CONNECTION_ROLES = `${USER} Get User Connection Roles`;
export const SET_USER_CONNECTION_ROLES = `${USER} Set User Connection Roles`;
export const SELECT_USER_CONNECTION_ROLE = `${USER} Select User Connection Role`;
export const GET_USER_PRIVILEGE_GROUPS = `${USER} Get User Privilege Groups`;
export const SET_USER_PRIVILEGE_GROUPS = `${USER} Set User Privilege Groups`;
export const SELECT_USER_PRIVILEGE_GROUP = `${USER} Select User Privilege Group`;
export const SUBMIT_USER_CREATE_USER = `${USER} Submit User Create User`;
export const CLEAR_USER_CREATE_USER = `${USER} Clear User Create User`;
export const UPDATE_NEW_PASSWORD = `${USER} Update New Password`;

export type ISubmit = {
    onSuccess: () => void;
};
export const getUserListBackoffice = () => ({
    type: GET_USER_LIST_BACKOFFICE,
});
export const setUserListBackoffice = () => ({
    type: SET_USER_LIST_BACKOFFICE,
});
export const getUserList = () => ({
    type: GET_USER_LIST,
});
export const setUserList = (userList: Array<IUser>) => ({
    type: SET_USER_LIST,
    payload: userList,
});
export const getUserById = (id: number) => ({
    type: GET_USER_BY_ID,
    payload: id,
});
export const setUserById = (data: any) => ({
    type: SET_USER_BY_ID,
    payload: data,
});
export const putUserById = ({ onSuccess }: ISubmit, id?: number) => ({
    type: PUT_USER_BY_ID,
    meta: {
        onSuccess,
    },
    payload: id,
});
export const clearUserInfo = () => ({
    type: CLEAR_USER_INFO,
});
export const putUserRole = () => ({
    type: PUT_USER_ROLE,
});
export const deleteUserRole = () => ({
    type: DELETE_USER_ROLE,
});
export const putUserPrivilege = (data: IUserPrivilegePut) => ({
    type: PUT_USER_PRIVILEGE,
    payload: data,
});
export const deleteUserPrivilege = (data: IUserPrivilegeDelete) => ({
    type: DELETE_USER_PRIVILEGE,
    payload: data,
});
export const getUserRoleEnum = () => ({
    type: GET_USER_ROLE_ENUM,
});
export const setUserRoleEnum = () => ({
    type: SET_USER_ROLE_ENUM,
});

export const changeUserUsername = (data: string) => ({
    type: CHANGE_USER_USERNAME,
    payload: data,
});
export const changeUserEmail = (data: string) => ({
    type: CHANGE_USER_EMAIL,
    payload: data,
});
export const changeUserPassword = (data: string) => ({
    type: CHANGE_USER_PASSWORD,
    payload: data,
});
export const changeUserConfirmPassword = (data: string) => ({
    type: CHANGE_USER_CONFIRM_PASSWORD,
    payload: data,
});
export const getUserRoles = () => ({
    type: GET_USER_ROLES,
});
export const setUserRoles = (data: Array<IRole>) => ({
    type: SET_USER_ROLES,
    payload: data,
});
export const selectUserRoles = (data: IRole) => ({
    type: SELECT_USER_ROLE,
    payload: data,
});
export const getUserConnectionRoles = (data: string) => ({
    type: GET_USER_CONNECTION_ROLES,
    payload: data,
});
export const setUserConnectionRoles = (data: Array<IConnectionRole>) => ({
    type: SET_USER_CONNECTION_ROLES,
    payload: data,
});
export const selectUserConnectionRole = (data: IConnectionRole) => ({
    type: SELECT_USER_CONNECTION_ROLE,
    payload: data,
});
export const getUserPrivilegeGroups = () => ({
    type: GET_USER_PRIVILEGE_GROUPS,
});
export const setUserPrivilegeGroups = (data: Array<IPrivilegeGroup>) => ({
    type: SET_USER_PRIVILEGE_GROUPS,
    payload: data,
});
export const selectUserPrivilegeGroup = (data: IPrivilegeGroup) => ({
    type: SELECT_USER_PRIVILEGE_GROUP,
    payload: data,
});
export const submitUserCreateUser = ({ onSuccess }: ISubmit) => ({
    type: SUBMIT_USER_CREATE_USER,
    meta: {
        onSuccess,
    },
});
export const clearUserCreateUser = () => ({
    type: CLEAR_USER_CREATE_USER,
});
export const updateNewPassword = (data: number) => ({
    type: UPDATE_NEW_PASSWORD,
    payload: data,
});
