import { Middleware } from 'redux';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/api.action';
import {
    CHANGE_KPI_PROJECT_STATUS,
    CHANGE_KPI_PROJECT_STATUS_IN_LIST,
    clearKpiProjectInputs,
    GET_KPI_PROJECT_BY_ID,
    GET_KPI_PROJECT_DASHBOARD,
    GET_KPI_PROJECT_LIST,
    GET_KPI_PROJECT_PAGE,
    getKpiProjectById,
    getKpiProjectList,
    KPI_PROJECT_CREATE,
    KPI_PROJECT_DELETE,
    KPI_PROJECT_GOALS,
    KPI_PROJECT_STATUS,
    KPI_PROJECT_UPDATE,
    setKpiProjectById,
    setKpiProjectDashboard,
    setKpiProjectList,
    setKpiProjectPage,
    setKpiProjectStatus,
} from '../../../actions/kpi/project.action';
import { createNotification } from '../../../actions/ui.action';
import { IKpiProjectDetail, IStatus } from '../../../../entities/kpi/kpiProjectCombustion';
import i18next from 'i18next';

export const projectMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        const state = getState();
        switch (action.type) {
            case KPI_PROJECT_STATUS: {
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/kpiProject/status',
                        feature: KPI_PROJECT_STATUS,
                    })
                );
                break;
            }
            case `${KPI_PROJECT_STATUS} ${API_SUCCESS}`: {
                const data = action.payload?.contentDtoList.reduce(
                    (
                        acc: Array<IStatus>,
                        v: {
                            id: number;
                            name: string;
                            title: string;
                        }
                    ) => {
                        acc.push({
                            id: v.id,
                            name: v.title,
                            label: v.name,
                        });
                        return acc;
                    },
                    []
                );

                dispatch(setKpiProjectStatus(data));
                break;
            }
            case `${KPI_PROJECT_STATUS} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_PROJECT_GOALS: {
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/kpiProject/goal',
                        feature: KPI_PROJECT_GOALS,
                    })
                );
                break;
            }
            case `${KPI_PROJECT_GOALS} ${API_SUCCESS}`: {
                break;
            }
            case `${KPI_PROJECT_GOALS} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_PROJECT_CREATE: {
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                    title: string;
                    description: string;
                    category: string;
                    baseYear: string;
                    targetYear: string;
                    sustainableDevelopmentGoalSet: Array<string>;
                }
                const body: IBody = {
                    organizationId:
                        state?.auth?.userInfo?.organization?.id ||
                        state?.auth?.userInfo?.company?.organizationId ||
                        null,
                    companyId: state?.emission?.base?.selectedCompany?.id,
                    facilityId: state?.emission?.base?.selectedFacility?.id,
                    title: state?.kpi?.project?.title,
                    description: state?.kpi?.project?.description,
                    category: state?.kpi?.project?.category,
                    baseYear: state?.kpi?.project?.baseYear,
                    targetYear: state?.kpi?.project?.targetYear,
                    sustainableDevelopmentGoalSet: state?.kpi?.project?.selectedGoals,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/kpiProject',
                        feature: KPI_PROJECT_CREATE,
                        onSuccess: action.meta.onSuccess,
                        body,
                    })
                );
                break;
            }
            case `${KPI_PROJECT_CREATE} ${API_SUCCESS}`: {
                action.meta.onSuccess();
                dispatch(clearKpiProjectInputs());
                break;
            }
            case `${KPI_PROJECT_CREATE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_PROJECT_UPDATE: {
                interface IBody {
                    id: number;
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                    title?: string;
                    description?: string;
                    category?: string;
                    baseYear?: string;
                    targetYear?: string;
                    status?: string;
                    sustainableDevelopmentGoalSet?: Array<string>;
                }
                const body: IBody = {
                    id: state?.kpi?.project?.selectedProject?.data?.id,
                    title: state?.kpi?.project?.title,
                    description: state?.kpi?.project?.description,
                    category: state?.kpi?.project?.category,
                    baseYear: state?.kpi?.project?.baseYear,
                    targetYear: state?.kpi?.project?.targetYear,
                    sustainableDevelopmentGoalSet: state?.kpi?.project?.selectedGoals,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/kpiProject',
                        feature: KPI_PROJECT_UPDATE,
                        body,
                    })
                );
                break;
            }
            case `${KPI_PROJECT_UPDATE} ${API_SUCCESS}`: {
                const id = action.payload?.kpiProjectDto?.id;
                dispatch(getKpiProjectById(id));
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'success',
                        message: action.payload?.message || i18next.t('notification.successUpdated'),
                    })
                );
                break;
            }
            case `${KPI_PROJECT_UPDATE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case KPI_PROJECT_DELETE: {
                const id = state?.kpi?.project?.selectedProject?.data?.id;
                next(
                    apiRequest({
                        method: 'DELETE',
                        url: '/kpiProject',
                        feature: KPI_PROJECT_DELETE,
                        query: {
                            id,
                        },
                    })
                );
                break;
            }
            case `${KPI_PROJECT_DELETE} ${API_SUCCESS}`: {
                dispatch(getKpiProjectList());
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'success',
                        message: action.payload?.message || i18next.t('notification.successDeleted'),
                    })
                );
                window.history.back();
                break;
            }
            case `${KPI_PROJECT_DELETE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_PROJECT_BY_ID: {
                const id = action.payload;
                next(
                    apiRequest({
                        method: 'GET',
                        url: `/kpiProject/${id}`,
                        feature: GET_KPI_PROJECT_BY_ID,
                    })
                );
                break;
            }
            case `${GET_KPI_PROJECT_BY_ID} ${API_SUCCESS}`: {
                const data = action.payload?.kpiProjectDto as IKpiProjectDetail;
                dispatch(setKpiProjectById(data));
                break;
            }
            case `${GET_KPI_PROJECT_BY_ID} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_PROJECT_PAGE: {
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                }
                interface IQuery {
                    page: number;
                    size: number;
                    sort: string;
                }
                const body: IBody = {
                    organizationId: -1,
                    companyId: -1,
                    facilityId: -1,
                };
                const query: IQuery = {
                    page: 0,
                    size: 50,
                    sort: 'createdAt,desc',
                };

                next(
                    apiRequest({
                        method: 'GET',
                        url: '/kpiProject/page',
                        feature: GET_KPI_PROJECT_PAGE,
                        body,
                        query,
                    })
                );
                break;
            }
            case `${GET_KPI_PROJECT_PAGE} ${API_SUCCESS}`: {
                const data = action.payload;
                dispatch(setKpiProjectPage(data));
                break;
            }
            case `${GET_KPI_PROJECT_PAGE} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_PROJECT_LIST: {
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId?: number;
                }
                const body: IBody = {
                    organizationId:
                        state?.auth?.userInfo?.organization?.id || state?.auth?.userInfo?.company?.organizationId,
                    companyId: state?.auth?.userInfo?.company?.id || state?.auth?.userInfo?.facility?.companyId,
                    facilityId: state?.auth?.userInfo?.facility?.id,
                };
                next(
                    apiRequest({
                        method: 'POST',
                        url: '/kpiProject/list',
                        feature: GET_KPI_PROJECT_LIST,
                        body,
                    })
                );
                break;
            }
            case `${GET_KPI_PROJECT_LIST} ${API_SUCCESS}`: {
                const data = action.payload?.kpiProjectDtoList?.sort((a: any, b: any) => a.id - b.id);
                dispatch(setKpiProjectList(data));
                break;
            }
            case `${GET_KPI_PROJECT_LIST} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case GET_KPI_PROJECT_DASHBOARD: {
                interface IBody {
                    organizationId: number;
                    companyId: number;
                    facilityId: number;
                }
                const body: IBody = {
                    organizationId: -1,
                    companyId: -1,
                    facilityId: -1,
                };

                next(
                    apiRequest({
                        method: 'GET',
                        url: '/kpiProject/dashboard',
                        feature: GET_KPI_PROJECT_DASHBOARD,
                        body,
                    })
                );
                break;
            }
            case `${GET_KPI_PROJECT_DASHBOARD} ${API_SUCCESS}`: {
                const data = action.payload;
                dispatch(setKpiProjectDashboard(data));
                break;
            }
            case `${GET_KPI_PROJECT_DASHBOARD} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case CHANGE_KPI_PROJECT_STATUS: {
                const { id, status } = action.payload;
                interface IBody {
                    id: number;
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                    status: string;
                }
                const body: IBody = {
                    id,
                    organizationId:
                        state?.auth?.userInfo?.organization?.id || state?.auth?.userInfo?.company?.organizationId,
                    companyId: state?.auth?.userInfo?.company?.id,
                    status,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/kpiProject',
                        feature: CHANGE_KPI_PROJECT_STATUS,
                        body,
                    })
                );
                break;
            }
            case `${CHANGE_KPI_PROJECT_STATUS} ${API_SUCCESS}`: {
                break;
            }
            case `${CHANGE_KPI_PROJECT_STATUS} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }

            case CHANGE_KPI_PROJECT_STATUS_IN_LIST: {
                const { id, status } = action.payload;
                interface IBody {
                    id: number;
                    organizationId?: number;
                    companyId?: number;
                    facilityId?: number;
                    status: string;
                }
                const body: IBody = {
                    id,
                    organizationId:
                        state?.auth?.userInfo?.organization?.id || state?.auth?.userInfo?.company?.organizationId,
                    companyId: state?.auth?.userInfo?.company?.id,
                    status,
                };
                next(
                    apiRequest({
                        method: 'PUT',
                        url: '/kpiProject',
                        feature: CHANGE_KPI_PROJECT_STATUS_IN_LIST,
                        body,
                    })
                );
                break;
            }
            case `${CHANGE_KPI_PROJECT_STATUS_IN_LIST} ${API_SUCCESS}`: {
                dispatch(getKpiProjectList());
                break;
            }
            case `${CHANGE_KPI_PROJECT_STATUS_IN_LIST} ${API_ERROR}`: {
                const { data } = action.payload.response;
                dispatch(
                    createNotification({
                        time: 3000,
                        type: 'error',
                        message: data.message || i18next.t('notification.somethingWentWrong'),
                    })
                );
                break;
            }
        }
    };
